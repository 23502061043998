import React from 'react'
import Loading from '../../../Commons/Loading'
import Card from 'react-bootstrap/Card';
import toaster from '../../../Toast/toaster';

export const MeetingSummary = (props) => {
  const { summary } = props 

  const handleCopy = () => {
    navigator.clipboard.writeText(summary)
      .then(() => {
        toaster.success('Summary copied to clipboard!');
      })
      .catch(err => {
        toaster.error('Failed to copy: ', err);
      });
  };

  return (
    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : <>
        <div className="tab-pane" role="tabpanel">
          <div className="d-flex align-items-center feedback-headder my-2">
            <div className="feedback-heading" style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 5px' }}>
              <h5>Summary</h5>
              <i class='bx bx-copy' style={{ fontSize: '18px', cursor: 'pointer' }} onClick={handleCopy} title="Copy" />
            </div>
          </div>
        </div>
        <Card style={{ width: '100%' }}>
          <Card.Body>
            <Card.Text>
              {summary}
            </Card.Text>
          </Card.Body>
        </Card>

      </>}
    </>)
}
