export const SETTINGS_COMPONENT_TYPE = {
  MANAGE_COE: "MANAGE_COE",
  MANAGE_COE_MEMBERS_COUNT: "MANAGE_COE_MEMBERS_COUNT",
  MANAGE_USERS: "MANAGE_USER",
  MANAGE_MOMENTS: "MANAGE_MOMENTS",
  MANAGE_REPORTS: "MANAGE_REPORTS",
  MANAGE_COE_USERS: "MANAGE_COE_USERS",
  MANAGE_TEAM: "MANAGE_TEAM",
  MANAGE_COMPETITORS: 'MANAGE_COMPETITORS',
  MANAGE_KNOWLEDGE_DOCUMENT:'MANAGE_KNOWLEDGE_DOCUMENT',
  MANAGE_KNOWLEDGE_FOLDER:'MANAGE_KNOWLEDGE_FOLDER',
  MANAGE_COACHING_SESSION:'SESSION',
};

export const SETTINGS_COMPONENT_TYPE_DATA = {
  MANAGE_COE_DELETE_DATA: "Are you sure you want to delete this COE?",
  MANAGE_COE_DELETE_DATA_MODAL_HEADING: "Confirmation",
  MANAGE_COE_ADD_USER: "Add User",

  MANAGE_COE_USER_DELETE_DATA:
    "Are you sure you want to remove the user from COE?",
  MANAGE_COE_USER_DELETE_DATA_MODAL_HEADING: "Confirmation",

  MANAGE_MOMENTS_ADD_MOMENT_BUCKET:
    "Please contact support@odioiq.com to add a new bucket.",
  MANAGE_MOMENTS_DELETE_MOMENT_BUCKET: "Delete Moment Bucket",
  MANAGE_MOMENTS_EDIT_MOMENT_BUCKET: "Edit Moment Bucket",

  MANAGE_USER_DELETE_DATA_MODAL_HEADING: "Confirmation",
  MANAGE_USER_DELETE_DATA: "Are you sure you want to delete this User?",

  MANAGE_COMPETITOR_DELETE_DATA_MODAL_HEADING: "Confirmation",
  MANAGE_COMPETITOR_DELETE_DATA: "Are you sure you want to delete this Competitor?",

  MANAGE_REPORT_DELETE_DATA_MODAL_HEADING: "Confirmation",
  MANAGE_REPORT_DELETE_DATA: "Are you sure you want to delete this Report?",

  MANAGE_KNOWLEDGE_DOCUMENT_DELETE_DATA_MODAL_HEADING: "Confirmation",
  MANAGE_KNOWLEDGE_DOCUMENT_DELETE_DATA:'Are you sure you want to delete this Document?',

  MANAGE_KNOWLEDGE_FOLDER_DELETE_DATA_MODAL_HEADING: "Confirmation",
  MANAGE_KNOWLEDGE_FOLDER_DELETE_DATA:'Are you sure you want to delete this Folder?',

  MANAGE_COACHING_SESSION_DELETE_DATA_MODAL_HEADING :'Confirmation',
  MANAGE_COACHING_SESSION_DELETE_DATA:'Are you sure you want to delete this Session?',

  MANAGE_COACHING_SESSION_STATUS_MODAL_HEADING:'Confirmation'
};
/* export const SALES_DASHBOARD_COMPONENT_NAME = {
  TOP_REPS: "TOP_REPS",
  BOTTOM_REPS: "BOTTOM_REPS",
  REPS_DISTRIBUTION: "REPS_DISTRIBUTION",
  CALL_DISTRIBUTION: "CALL_DISTRIBUTION",
  CALL_DURING_DISTRIBUTION: "CALL_DURING_DISTRIBUTION",
  CALL_QUALITY: "CALL_QUALITY",
  MOMENT_PERFORMANCE: "MOMENT_PERFORMANCE",
  SIGNAL_PERFORMANCE: "SIGNAL_PERFORMANCE",
  AGENT_WORD_CLOUD: "AGENT_WORD_CLOUD",
  CUSTOMER_WORD_CLOUD: "CUSTOMER_WORD_CLOUD",
}; */
export const SALES_DASHBOARD_COMPONENT_NAME = {
  USER_DATA_LIST: "USER_DATA_LIST",
  VERTICAL_BAR_GRAPH: "VERTICAL_BAR_GRAPH",
  HORIZONTAL_BAR_GRAPH: "HORIZONTAL_BAR_GRAPH",
  VERTICAL_BAR_GRAPH_2: "VERTICAL_BAR_GRAPH_2",
  WORD_CLOUD: "WORD_CLOUD",
  VERTICAL_BAR_GRAPH_TAB: "VERTICAL_BAR_GRAPH_TAB",
};
export const CONVERSATIONS_SALE_DASHBOARD = {
  SALES_DASHBOARD: "SALES_DASHBOARD",
  SUPPORT_CALL: "SUPPORT_CALL",
  CALL_LISTING_FILTER: "FILTER CALLS"
};
export const COACHING_COMPONENT_TYPE = {
  KNOWLEDGE_BASE: "KNOWLEDGE_BASE",
} 

export const QUESTION_OPTIONS = [
  { value: 'OBJECTIVE_WITH_OPTIONS', label: 'Objective' },
  { value: 'SUBJECTIVE_TEXT', label: 'Text Subjective' },
  { value: 'SUBJECTIVE_VOICE', label: 'Voice Subjective' },
  { value: 'CALL_SEMANTIC', label: 'Call Semantic' }
]

