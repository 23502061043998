import React, { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import ProductivityData from "./ProductivityData";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import ProductivityGraph from "./ProductivityGraph";
import insightService from "../../Services/insight.service";
import dashboardService from "../../Services/dashboard.service";
import profileService from "../../Services/profile.service";
import Loading from "../../Commons/Loading";
import { apiCall } from "../../Services/Interceptor";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@mui/styles';
import Filter from "../../Filter/Filter";
import FilterDrawer from "../../Commons/FilterDrawer";
import InsightsFilter from "../InsightsFilter";
import { onResetFilter, setDateList, setResetState, setUserOptions } from "../../../redux-container/insights/action";
import { useDispatch, useSelector } from "react-redux";
import { positionFormat } from "../../Commons/DateFormatter";


const useStyles = makeStyles({
    customInput: {
        '&': {
            marginBottom: '1rem'
        },
        '& .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
            paddingRight: '70px !important'
        },
    },
})

export default function Productivity() {
    const classes = useStyles()

    let localData = JSON.parse(localStorage.getItem("AGENT"))
    const dispatch = useDispatch()
    const [customInput, SetCustomInput] = useState(localData&&localData.selectedRange==9?true:localData&&localData.selectedRange==''?true:false);
    const [dates,setDates] = useState([])
    const crrDate = new Date()
    const [startDate, setStartDate] = useState(localData?.fromD ? new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`) : new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD ? new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`) : new Date())
    const [userList, setUserList] = useState([])
    const [defaultFilteredData, setDefaultFilteredData] = useState([])
    const [productivity, setProductivity] = useState(localData?.productivity?localData.productivity:'Call Score');
    const [dataProductivity,setDataProductivity] = useState([]) 
    const [loader, setLoader] = useState(false)
    const [def, setDef] = useState(false)
    const [count, setCount] = useState("")
    const [productivityFilter, setProductivityFilter] = useState(localData?.productivity ? localData.productivity : 'Call Score')
    const [dateChange, setDateChange] = useState(false)
    const [apply, setApply] = useState(false)
    const [reset, setReset] = useState(false)
    let chipsData = useSelector(state=>state?.insights?.chipsData)
    let filteredData = useSelector(state=>state?.insights?.filterData)
    let options = useSelector(state=>state.insights.userOptions)
    let optionsProductivity = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.productivity
  
    
    useEffect(() => {
        document.title = "Productivity  - Odio"
    }, [])
    
    const getDateAcronym = ()=>{
        dashboardService.getDateAcronym().then(res=>{
        let dateList = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
        }):'':''
        setDates(dateList);
        dispatch(setDateList(dateList))
        let dd = dateList[4]?.fromDate?.split("-")[2]
        let mm = dateList[4]?.fromDate?.split("-")[1]
        let yyyy = dateList[4]?.fromDate?.split("-")[0]
        let dateF = `${dd}-${mm}-${yyyy}`
        let dd_1 = dateList[4]?.toDate?.split("-")[2]
        let mm_1= dateList[4]?.toDate?.split("-")[1]
        let yyyy_1= dateList[4]?.toDate?.split("-")[0]
        let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
        setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,
            fromDate:dateT,
            toDate:dateF}))
        })
    }

    const getUserList = async()=>{
        const res = await apiCall.get(`${'/odio/api/user/get-user-team-list'}?fromD=${localData?.fromD?localData.fromD:null}&toD=${localData?.toD?localData.toD:null}&coeIds=${localData?.selectedCoeRange?localData.selectedCoeRange:''}`); 
          if(res?.data?.status === 0){
            setUserList(res?.data?.data?.team)
            const teamOptions = res?.data?.data?.team.map((e,i)=>
                (
                  {"label":`${e.firstName} ${e.lastName}`,"value":`${e.id}`,"id":e.id}
                ))
            const optionsWithSelectAll = [...teamOptions];
            dispatch(setUserOptions(optionsWithSelectAll))
            let userIds = res?.data?.data?.team.map(item=>{
              return item.id
            })
            setDefaultFilteredData(defaultFilteredData => ({ ...defaultFilteredData, id: userIds }))
        } else if (res.data.status === 1) {
            setLoader(false);
        }
    }

    const getProductivityData = () => {
        let localData = JSON.parse(localStorage.getItem("AGENT"))
        const values = {
            "fromD": localData?.fromD,
            "toD": localData?.toD,
            "userId": localData?.userIds ? localData?.userIds : defaultFilteredData.id,
            "coeIds": localData?.selectedCoeRange
        }
        insightService.getProductivity(values).then(res => {
            if (res) {
                setDataProductivity(res.data.data)
                // setDefaultFilteredData(res.data)
                setLoader(false)
                setApply(false)
                setReset(false)
                dispatch(setResetState(false))
            }
        })
        setDateChange(false)
    }

    useEffect(()=>{
        getDateAcronym()
        getUserList()
    }, [])


    useEffect(() => {
        setLoader(true)
        if (defaultFilteredData?.id?.length > 0) {
            getProductivityData()
            localStorage.setItem("AGENT",JSON.stringify({
                "pageSelected":localData?.pageSelected,
                "analysis":localData?.analysis,
                "productivity":localData?.productivity?localData?.productivity:productivity,
                "fromD":localData&&localData.fromD?localData.fromD:defaultFilteredData.fromDate,
                "toD":localData&&localData.toD?localData.toD:defaultFilteredData.toDate,
                "agentDetailCOE":localData?.agentDetailCOE,
                "selectedRange":localData?.selectedRange,
                "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
                // "userIds":localData?.userIds?localData?.userIds:defaultFilteredData.id,
                "userIds": localData?.userIds ? localData?.userIds : defaultFilteredData.id,
                "userNames":localData?.userNames?localData.userNames : options.map(item=>item.label),
                "empId":localData?.employeeId,
                "empName":localData?.user,
                "role":localData?.role,
                "designation":localData?.designation,
                "type":localData?.type,
                "teamCOE":localData?.teamCOE,
                "callsFrom":localData?.callsFrom,
                "callsTo":localData?.callsTo,
                "aht":localData?.aht,
                "callScore":localData?.callScore,
                "pitchScore":localData?.pitchScore,
                "customerName":localData?.customerName,
                "customerMobile":localData?.customerMobile,
                "fileName":localData?.fileName,
                "sellerROS":localData?.sellerROS,
                "sellerName":localData?.sellerName,
                "selectedSeller":localData?.selectedSeller,
                "cScore":localData?.cScore,
                "callHealth":localData?.callHealth,
                "callDuration":localData?.callDuration,
                "words":localData?.words,
                "moment":localData?.moment,
                "momentId":localData?.momentId,
                "pitch":localData?.pitch,
                "prob":localData?.prob,
                "opportunity":localData?.opp,
                "urgency":localData?.urgency,
                "coe":localData?.coe,
                "selectedCoeRange":localData?.selectedCoeRange,
                "selectedCOE":localData?.selectedCOE,
                "momentBucket":localData?.momentBucket,
                "momentBucketId":localData?.momentBucketId,
                "frequency":localData?.frequency,
                "reportType":localData?.reportType
            }))
        }else if (defaultFilteredData?.id?.length == 0){
            getProductivityData()
        }
    }, [defaultFilteredData])

    useEffect(()=>{
        if(apply){
            onSubmitHandler()
        }
        if(reset){
            onResetHandler()
            const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            dispatch(onResetFilter({"productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"selectedRange":5,"selectedDate":"This Month"}))
            dispatch(setResetState(true))
        }
    },[apply, reset])


    const onSubmitHandler = ()=>{
        // if(dateChange == true){
        //     setLoader(true)
        // }
        // setLoader(true)
        setProductivityFilter(productivity)
        localStorage.setItem("AGENT",JSON.stringify({
            "pageSelected":localData?.pageSelected,
            "analysis":localData?.analysis,
            "productivity":filteredData?.productivity?filteredData?.productivity:localData?.productivity,
            "fromD":filteredData?.fromD?filteredData.fromD:localData?.fromD,
            "toD":filteredData?.toD?filteredData?.toD:localData?.toD,
            "agentDetailCOE":localData?.agentDetailCOE,
            "selectedRange":filteredData?.selectedRange?filteredData?.selectedRange : localData?.selectedRange,
            "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
            "userIds":filteredData?.id?filteredData?.id:localData?.userIds,
            "userNames":filteredData?.names?filteredData.names:localData?.userNames,
            "empId":localData?.employeeId,
            "empName":localData?.user,
            "role":localData?.role,
            "designation":localData?.designation,
            "type":localData?.type,
            "teamCOE":localData?.teamCOE,
            "callsFrom":localData?.callsFrom,
            "callsTo":localData?.callsTo,
            "aht":localData?.aht,
            "callScore":localData?.callScore,
            "pitchScore":localData?.pitchScore,
            "customerName":localData?.customerName,
            "customerMobile":localData?.customerMobile,
            "fileName":localData?.fileName,
            "sellerROS":localData?.sellerROS,
            "sellerName":localData?.sellerName,
            "selectedSeller":localData?.selectedSeller,
            "cScore":localData?.cScore,
            "callHealth":localData?.callHealth,
            "callDuration":localData?.callDuration,
            "words":localData?.words,
            "moment":localData?.moment,
            "momentId":localData?.momentId,
            "pitch":localData?.pitch,
            "prob":localData?.prob,
            "opportunity":localData?.opp,
            "urgency":localData?.urgency,
            "coe":localData?.coe,
            "selectedCoeRange":localData?.selectedCoeRange,
            "selectedCOE":localData?.selectedCOE,
            "momentBucket":localData?.momentBucket,
            "momentBucketId":localData?.momentBucketId,
            "frequency":localData?.frequency,
            "reportType":localData?.reportType
        }))
        if (userList?.length == 0) {
            getUserList();
        }
        // if(dateChange == true){
            getProductivityData()
        // }
    }
    
    const onResetHandler = ()=>{
        const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        localStorage.setItem("AGENT",JSON.stringify({"pageSelected":localData?.pageSelected,"analysis":localData?.analysis,"productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"agentDetailCOE":localData?.agentDetailCOE,"selectedRange":5,"selectedDate":"This Month","userIds":defaultFilteredData.id,"userNames":options.map(item=>item.value),"coe":localData?.coe,"selectedCoeRange":localData?.selectedCoeRange,"selectedCOE":localData?.selectedCOE,"momentBucket":localData?.momentBucket,"momentBucketId":localData?.momentBucketId}))
        // setFilteredData([])
        setProductivityFilter("Call Score")
        getProductivityData()
        
        // getDefaultData()


    }

    const applyFilter = ()=>{
        setApply(true)
    }

    const resetFilter = ()=>{
        setReset(true)
    }

    const setLoaderTrue = ()=>{
        setLoader(true)
    }

    return(
        <>
        
        <div className="page-wrapper">
            <div className="page-content">
                {loader?
                    <div className="loader-container">
                        <Loading variant="dark"/>
                    </div>
                :
                <>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={12} className="mb-2">
                                <h2 className="dashboard-headings d-inline">Productivity</h2>
                                {Array.isArray(chipsData) && chipsData.length>0 ? 
                                chipsData.map(item=>(
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        {`${item?.name}, ${item?.id}`}
                                        <a href="javascript:;"></a>
                                    </span>
                                ))
                                :
                                <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                    All employees are selected
                                    <a href="javascript:;"></a>
                                </span>
                                }
                                {filteredData && filteredData?.dateName ? 
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                         {`DATE: ${filteredData?.dateName === "Custom Date" ? `${positionFormat(filteredData?.fromD)} to ${positionFormat(filteredData?.toD) }`: filteredData?.dateName }`}
                                        <a href="javascript:;"></a>
                                    </span>
                                : 
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        <span>DATE: </span>
                                        {`${localData?.selectedDate}`}
                                        <a href="javascript:;"></a>
                                    </span>
                                }
                                {filteredData && filteredData?.productivity ? 
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        <span>PRODUCTIVITY: </span>
                                        {filteredData?.productivity}
                                        <a href="javascript:;"></a>
                                    </span>
                                :
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        <span>PRODUCTIVITY: </span>
                                        {`${optionsProductivity[0]}`}
                                        <a href="javascript:;"></a>
                                    </span>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Body>
                                        <div className="productivity-date-section">
                                            <Tabs defaultActiveKey="heat_map" id="uncontrolled-tab-example" className="mb-3 mx-0 internal-tabs">
                                                <Tab eventKey="heat_map" title="Graph">
                                                    <ProductivityGraph loader={loader} def={def} productivity={productivityFilter} filter={dataProductivity} count={count}/>
                                                </Tab>
                                                <Tab eventKey="data" title="Data">
                                                    <ProductivityData data={dataProductivity} loader={loader} productivity={productivityFilter} def={def} />
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </>
                }
            </div>
        </div>
        <FilterDrawer filterType='INSIGHTS' componentType='PRODUCTIVITY' applyFilter={applyFilter} setLoaderTrue={setLoaderTrue} resetFilter={resetFilter}/>
        </>
    )
}