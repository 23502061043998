import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ShowToast from "../../Components/helper/ShowToast";
import ResetPassword from './ResetPassword';
import background from '../../assets/images/login-images/sign-in.png';
import odio_logo_blue from '../../assets/images/odio_logo_blue.png';
import logo from '../../assets/images/o-pic-new.png';
import './login.css'
import Loading from '../Commons/Loading';
import { connect } from 'react-redux';
import { signIn } from '../../redux-container/login/action';
import { color } from 'highcharts';

function Login(props) {
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });
    const [formError, setFormErrors] = useState({});

    const [passwordShown, setPasswordShown] = useState(false);
    const { email, password } = inputs;
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [pshow, setpshow] = useState(false)
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false)
    let btnRef = useRef();

    const errors = {};
    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
        btnRef.current.removeAttribute("disabled");
    }

    useEffect(() => {
        setLoading(props.loader);
    }, [props.loader])


    const validate = (values) => {
        if (!values.email) {
            errors.email = "Enter your login id (email)."
        }
        // if(values.email.length > 50){
        //     errors.email = "Email length should be less than 50."
        // }
        if (!values.password) {
            errors.password = "Enter your password."
        }
        return errors;
    }
    function handleFocusEmail() {
        formError.email = "";
    }
    function handleFocusPass() {
        formError.password = "";
    }
    function handleCheckEmail() {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(inputs.email)) {
            setFormErrors(errors => ({ ...errors, email: "Invalid email format." }));
        }
        if (inputs.email.length > 50) {
            setFormErrors(errors => ({ ...errors, email: "Email length should be less than 50." }))
        }
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
        setpshow(!pshow);
    };

   
    
    function handleSubmit(e) {
        e.preventDefault();
    
        validate(inputs);
        setMessage('');
        setFormErrors(errors);
    
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
    
        if (Object.keys(errors).length === 0) {
            const encodedEmail = btoa(inputs.email);
            const encodedPassword = btoa(inputs.password);
    
            const loginData = {
                email: encodedEmail,
                password: encodedPassword,
            };
    
            props.signIn(loginData);
        }
    }
    

    return (
        <>
            <div className="login-container">
                <div className="welcome-section">
                    <div className="logo-container">
                        <img src={logo} alt="logo" className="logo" />
                        <img src={odio_logo_blue} alt="Odio Logo" className="logo" />
                    </div>
                    <div className="welcome-text">
                        <p className='welcome-message'>Nice to see you again</p>
                        <h1 className='welcome-back'>WELCOME BACK</h1>
                        <div className="slash"></div>
                        <p className='welcome-tagline'>Transform Customer Interactions with our AI-Driven Conversational Intelligence.</p>
                    </div>
                    <div className="additional-links" style={{ position: "absolute", bottom: "20px", left: "20px" }}>
                        {/* <div className='link-1'> */}
                        <a href="https://www.odioiq.com" target="_blank" style={{ color: 'white', borderBottom:'1px solid currentColor'}}>Odio</a>
                        <a href="https://www.odioiq.com/blog/" target="_blank" style={{ color: 'white', borderBottom:'1px solid currentColor'}}>Blogs</a>
                        <a href="https://www.odioiq.com/privacy-policy-2/" target="_blank" style={{ color: 'white', borderBottom:'1px solid currentColor'}}>Privacy Policy</a>
                        {/* </div> */}
                        {/* <div className='link-2'> */}
                        <a href="https://www.odioiq.com/product/integrations/" target="_blank" style={{ color: 'white', borderBottom:'1px solid currentColor'}}>Integrations</a>
                        <a href="https://www.odioiq.com/product/faq-2/" target="_blank" style={{ color: 'white', borderBottom:'1px solid currentColor'}}>FAQs</a>
                        {/* </div> */}
                    </div>
                </div>
                <div className="login-section">
                    <h2 className='login'>Login Account</h2>
                    <form className="row g-4" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>
                        <div className="col-12">
                            <div className="input-group">
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onFocus={handleFocusEmail}
                                    onChange={handleChange}
                                    onBlur={handleCheckEmail}
                                    className="form-control form-control-sm"
                                    id="inputEmail"
                                    placeholder="Email ID"
                                />
                            </div>
                            <p style={{ color: "red", height: "18px" }} className="my-1 mb-0 lh-1 font-12">
                                {formError.email}
                            </p>
                        </div>
                        <div className="col-12 mt-0">
                            <div className="input-group" id="show_hide_password">
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    name="password"
                                    value={password}
                                    onFocus={handleFocusPass}
                                    onChange={handleChange}
                                    className="form-control form-control-sm border-end-0"
                                    id="inputChoosePassword"
                                    placeholder="Password"
                                />
                                <a
                                    onClick={togglePassword}
                                    tabIndex="-1"
                                    href="javascript:;"
                                    className="input-group-text bg-transparent"
                                >
                                    {pshow ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
                                </a>
                            </div>
                            <p style={{ color: "red", height: "18px" }} className="my-1 mb-0 lh-1 font-12">
                                {(message) ? message : formError.password}
                            </p>
                        </div>
                        <div className="col-md-12 text-end sf-btn mt-0">
                            <Link to="/forgot_password" style={{ color: '#479ef5' }}>Forgot Password ?</Link>
                        </div>
                        <div className="col-12">
                            <div className="btn-container">
                                <button
                                    type="submit"
                                    className="btn btn-submit"
                                    style={{ background: '#479ef5', color: 'white' }}
                                    ref={btnRef}
                                >
                                    {loading ? <Loading variant="light" /> : <> Sign in</>}   
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        authenticate: state.login.loggedIn,
        loader: state.login.loader
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (data) => { dispatch(signIn(data)) },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
