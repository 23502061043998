import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.css";
import ReactPaginate from "react-paginate";
import toaster from "../../Toast/toaster";

const Pagination = (props) => {
  const {
    gotoPage,
    totalCount,
    pageIndex,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    handlePageIndex,
    resetPagination,
    onRowClick
  } = props;
  const [copyArray, setCopyArray] = useState(false);
  const [searchPage, setSearchPage] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(pageIndex);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  useEffect(() => {
    setCurrentPageIndex(pageIndex);
  }, [pageIndex]);

  // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange.length < 1) {
  //   return null;
  // }

  const onNext = (e) => {
    nextPage();
    handlePageIndex(pageIndex + 1);
    let n = paginationRange.length;
    if (
      paginationRange[n - 2] === "..." &&
      pageIndex >= 4 &&
      pageIndex < paginationRange[n - 1] - 1
    ) {
      let i = 0;
      for (i = 0; i < 4; i++) {
        paginationRange[i] = paginationRange[i + 1];
      }
      paginationRange[i] = paginationRange[i - 1] + 1;
      if (paginationRange[i] == paginationRange[n - 1] - 2) {
        paginationRange[5] = paginationRange[n - 1] - 1;
      }
      setCopyArray(true);
    }
  };

  const onPrevious = (e) => {
    previousPage();
    handlePageIndex(pageIndex - 1);
    if (copyArray == true && pageIndex == paginationRange[6] - 2) {
      paginationRange[5] = "...";
    }
    if (pageIndex <= paginationRange[6] - 3 && paginationRange[0] != 1) {
      let i;
      for (i = 4; i > 0; i--) {
        paginationRange[i] = paginationRange[i - 1];
      }
      paginationRange[i] = paginationRange[i + 1] - 1;
    }
  };

  function onPageChange(pageNumber) {
    let check = true
    if (pageNumber === pageIndex + 1) {
      return;
    }
    gotoPage(pageNumber - 1);
    handlePageIndex(pageNumber - 1);
    setCurrentPageIndex(pageNumber - 1);
    if (totalCount === pageNumber) {
      for (let i = 6; i < 0; i--) {
        paginationRange[i] = pageNumber - 1;

      }
      check = false
    }
    if (copyArray && pageIndex >= 6 && check) {
      for (let i = 6; i > 0; i--) {
        paginationRange[i] = paginationRange[i - 1];
      }
      paginationRange[0] = paginationRange[1] - 1;
      paginationRange[5] = '...';
      paginationRange[6] = paginationRange[paginationRange.length - 1]; //+ 1;
      setCopyArray(true);
    }
  }

  const handlePageSearch = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(searchPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPageCount) {
      onPageChange(pageNumber);
      setSearchPage('')
    } else {
      toaster.error('Invalid page number');
    }
  };

  const totalPageCount = Math.ceil(totalCount / pageSize);

  return (
    <div className="d-flex align-items-baseline justify-content-between pagination-container-text">
      <form onSubmit={handlePageSearch} className="d-flex align-items-center">
        <input
          type="number"
          min="1"
          max={totalPageCount}
          value={searchPage}
          onChange={(e) => setSearchPage(e.target.value)}
          placeholder="Go to page"
          className="paginationAlter-input"
        />
        <button type="submit" className="groupCall">Go</button>
      </form>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 20,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
      }}
    >
      <ReactPaginate className={classnames("pagination-container pagination", {
        [className]: className,
      })}
        previousLabel={"Prev"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPageCount}
        selected={0}
        // forcePage={storedData?.pageSelected ? storedData?.pageSelected - 1 : 0}
        forcePage={currentPageIndex}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3} //on clicking dotspagepagepage
        onPageChange={(e) => handlePageIndex(e.selected)}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={'page-item'}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      >
      </ReactPaginate>
      </div>
      </div>
  );
};

export default Pagination;
