import React from 'react'
import { Modal, ModalDialog, DialogTitle, Input, ModalClose, FormControl, FormLabel, Stack } from '@mui/joy';
import { Button } from '@mui/material';
import "./coachingDashboard.css"

function TeamCreateModal(props) {
    const { openModal, setOpenModal } = props;

    const formSubmit = (e) => {
        e.preventDefault()
    }

  return (
    <Modal
            open={openModal}
            onClose={() => { setOpenModal(false) }}
            sx={{
                backdropFilter: 'blur(1px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '15px'
            }}
            disableEscapeKeyDown={true}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Create</DialogTitle>
                <DialogTitle className='create-session-short-title'>Fill the details to create a session.</DialogTitle>
                <div className='coaching-input-container'>
                    <form onSubmit={formSubmit}>
                        <Stack spacing={2} width="125%">
                            <FormControl>
                                <FormLabel>Session Name</FormLabel>
                                {/* <Input value={sessionName} autoFocus required onChange={(e) => setSessionName(e.target.value)} /> */}
                            </FormControl>
                            <FormControl>
                                <FormLabel>Select Coe</FormLabel>
                                {/* <Select
                                    value={selectedCoe}
                                    onChange={handleSelectChange}
                                    required
                                    sx={{ height: '38px', borderRadius: '6px !important' }}
                                >
                                    {coeList.map(item => <MenuItem value={item.coeId}>{item.coeName}</MenuItem>)}
                                </Select> */}
                            </FormControl>
                            <FormControl>
                                <FormLabel>Coaching Media Id</FormLabel>
                                {/* <Input value={mediaId} type='number' onChange={(e) => setMediaId(e.target.value)} /> */}
                            </FormControl>
                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
                </div>
            </ModalDialog>
        </Modal>
  )
}

export default TeamCreateModal