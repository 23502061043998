import React, { useEffect, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import { apiCall } from "../../Services/Interceptor";
import { HYPERLINK_SVG, IFRAME_SVG, IMAGE_SVG, PDF_SVG, TEXT_SVG, VIDEO_SVG } from "../../../Constants/constant";
import './Grapes.css';
import { createDocuments, updateDocument, uploadFile,saveToDraft } from "../../Services/knowledgeBase.service";
import Loading from "../Loading";
import { useSelector } from "react-redux";
import toaster from "../../Toast/toaster";
import { Button } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

const Grapes = ({actionType, isDraft,draftDocId, getDocumentDetalisEditCase,setTabSelect}) => {
 const [editorInstance, setEditorInstance] = useState(null);
 const [assets, setAssets] = useState([]); 
 const [isDisabled,setIsDisabled] = useState(false);
 const [isSaveSubmitDisabled, setIsSaveSubmitDisabled] = useState(false);
 const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState(false);

 const initialEditCaseData = useSelector(state => state.knowledgeBase.editDocData);
 const documentType = useSelector(state=> state.knowledgeBase.documentType)

 const initialData = {
  editorComponents:initialEditCaseData?.editorComponents,
  editorCss:initialEditCaseData?.editorCss,
  editorHtml: initialEditCaseData?.editorHtml,
  editorStyles: initialEditCaseData?.editorStyles,
 };
 // Function to upload the file using an API call
 const uploadToAPI = async (file) => {
  try {
   const formData = new FormData();
   formData.append("file", file);
   const title = file.name;
   const mimeType = file.type;
   const response = await uploadFile(formData,title,mimeType);
   if(response?.data?.status === 0){
   const { signedUrl, url, id } = response.data?.data;
   return { signedUrl, url, id };
   }else{
    console.error("Error uploading file to API:", response.data);
    toaster.error(response.data.error);
    return null;
   }
  } catch (error) {
   console.error("Error uploading file:", error);
   return null;
  }
 };

 // Function to extract images/videos from initial data
 const extractMediaComponents = (components) => {
  const mediaComponents = [];

  components.forEach((comp) => {
   if (comp.type === "image" || comp.type === "video") {
    mediaComponents.push({
      src: comp.type === "image" ? comp.attributes.src : comp.src,
      type: comp.type
    });
   }else if(comp.type === 'document'){
    mediaComponents.push({
      src: comp.src ,
      type: comp.type
    });
  }
  });

  return mediaComponents;
 };

 // Function to call API for getting signed URLs
 const getSignedUrls = async (mediaSources) => {
  const payload = mediaSources?.reduce((acc, comp, index) => {
    acc[`additionalProp${index + 1}.${comp.type}`] = comp.src;
   return acc;
  }, {});

  try {
   const response = await apiCall.post(
    "/odio/api/folder/document/content",
    {
     data: payload,
    }
   );
   return response.data?.data;
  } catch (error) {
   console.error("Error getting signed URLs:", error);
   return null;
  }
 };

 useEffect(() => {
  const editor = grapesjs.init({
   container: "#gjs",
   height: "700px",
   width: "100%",
   components: initialData.editorComponents && initialData.editorComponents,
   style: initialData.editorStyles && initialData.editorStyles,
   blockManager: {
    blocks: [
     {
      id: "text",
      label: "Text",
      category: "Basic",
      media: TEXT_SVG,
      activate: true,
      content: {
       type: "text",
       content: "Insert your text here",
       style: { padding: "10px" },
      },
     },
     {
      id: "link",
      label: "Link",
      category: "Basic",
      media: HYPERLINK_SVG,
      activate: true,
      content: {
        type: "link",
        content: "Insert your link here",
        style: { color: "#d983a6" },
        attributes: { href: "#" }, // Default href
        traits: [
          {
            type: "text",
            label: "URL",
            name: "href",
            placeholder: "https://www.example.com",
          },
        ],
      },
    },    
     {
      id: "image",
      label: "Image",
      media: IMAGE_SVG,
      // Use `image` component
      content: { type: "image" },
      // The component `image` is activatable (shows the Asset Manager).
      // We want to activate it once dropped in the canvas.
      activate: true,
     },
     {
      id: "video",
      label: "Video",
      category: "Basic",
      media: VIDEO_SVG,
      activate: true,
      content: {
       type: "video",
       tagName: "video",
       attributes: {
        controls: true,
        autoplay: false,
        loop: false,
        muted: false,
       },
       style: {
        height: "350px",
        width: "100%",
       },
      },
     },
     {
      id: "iframe-block", // Unique block ID
      label: "Iframe", // Label for the block
      category: "Basic", // Category under which it will appear in the block manager
      media: IFRAME_SVG,
      content: {
       type: "iframe",
       tagName: "iframe",
       attributes: {
        src: "https://www.example.com",
        allow:
         "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerpolicy: "strict-origin-when-cross-origin",
       },
       style: {
        height: "350px",
        width: "100%",
       },
      },
     },
     {
      id: "document-block",
      label: "Document",
      category: "Basic",
      media: PDF_SVG,
      activate: true,
      content: {
       type: "document",
       attributes: {
        src: "",
       },
       style: {
        // height: "350px",
        width: "100%",
       },
      },
     },
    ],
   },
   assetManager: {
     // Custom upload method for Asset Manager
     uploadFile: async (event) => {
       const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

       if (file) {
         // Call the API to upload the file and get the signed URL
         const assetData = await uploadToAPI(file);

         if (assetData) {
           // Once uploaded, add the asset to the editor using the signedUrl
           const { signedUrl, url, id } = assetData;
           editor.AssetManager.add({
             src: signedUrl,
             type: file.type.startsWith('image') ? 'image' : 'video',
             id,
           });

           // Add the asset details to the local state
           setAssets((prev) => [...prev, { id, type: file.type.startsWith('image') ? 'image' : 'video', src: url, signedUrl }]);
         }
       }
     },
     // Optional: Allow assets to be dropped into the canvas
     dropzone: true,
   },
   storageManager: {
    type: null, // Disable storage
   },
   // storageManager: {
   //     id: 'gjs-',
   //     type: 'local',
   //     autosave: true,
   //     storeComponents: true,
   //     storeStyles: true,
   //     storeHtml: true,
   //     storeCss: true,
   // },
   deviceManager: {
    devices: [
     {
      id: "desktop",
      name: "Desktop",
      width: "",
     },
     {
      id: "tablet",
      name: "Tablet",
      width: "768px",
      widthMedia: "992px",
     },
     {
      id: "mobilePortrait",
      name: "Mobile portrait",
      width: "320px",
      widthMedia: "575px",
     },
    ],
   },
  });
  editor.on('component:click', (component) => {
    if (component.is('link')) {
      const href = component.getAttributes().href;
      const isPreview = editor.getMode() === 'preview';
      if (isPreview && href) {
        // Open the link in a new tab
        window.open(href, '_blank');
      }
    }
  });
  editor.on('component:update:href', (component) => {
    if (component.is('link')) {
      const href = component.getAttributes().href;
      if (href) {
        component.addAttributes({ href });
      }
    }
  });
    
  editor.DomComponents.addType("iframe", {
    model: {
      defaults: {
        tagName: "iframe",
        attributes: {
          src: "", // Empty src to prompt user for input
          frameborder: "0",
          scrolling: "no",
          allowfullscreen: "true", // Set initial value to true
          width: "100%",
          height: "350px",
        },
        style: {
          height: "350px",
          width: "100%",
        },
        traits: [
          {
            type: "text", // Input type: text
            label: "Source (URL)", // Label in UI
            name: "src", // Bind to iframe 'src' attribute
            placeholder: "https://www.example.com", // Placeholder text to guide users
          },
          {
            type: "text", // Input type: text
            label: "Width", // Label in UI
            name: "width", // Bind to iframe 'width' attribute
            changeProp: true,
            default: "100%", // Set the initial default value for width
          },
          {
            type: "text", // Input type: text
            label: "Height", // Label in UI
            name: "height", // Bind to iframe 'height' attribute
            changeProp: true,
            default: "350px", // Set the initial default value for height
          },
          {
            type: "checkbox", // Input type: checkbox for fullscreen
            label: "Allow Fullscreen",
            name: "allowfullscreen", // Bind to iframe 'allowfullscreen' attribute
            valueTrue: "true", // Set to `true` when checked
            valueFalse: "false", // Set to `false` when unchecked
          },
        ],
      },
      init() {
        this.on("change:width", this.updateDimensions);
        this.on("change:height", this.updateDimensions);
        this.on("change:allowfullscreen", this.updateFullscreen);
      },
      updateDimensions() {
        const width = this.get("width") || "100%";  
        const height = this.get("height") || "350px"; 
        this.addStyle({ width, height });
      },
      updateFullscreen() {
        const allowFullscreen = this.get("allowfullscreen") === "true";
        if (allowFullscreen) {
          this.addAttributes({ allowfullscreen: "" });
        } else {
          this.removeAttributes("allowfullscreen");
        }
      },
    },
  });
  editor.DomComponents.addType("document", {
    model: {
      defaults: {
        tagName: "div",
        attributes: {
          src: "",
          "data-document-type": "",
        },
        traits: [
          {
            type: "text",
            label: "Document Source",
            name: "src",
            placeholder: "Document URL",
          },
        ],
        // Custom rendering to show document preview
        content: `
          <div class="document-preview" style="display: flex; align-items: center; justify-content: center;">
            <span>Click to Upload Document</span>
          </div>
        `,
      },
      init() {
        this.on("change:src", this.updateDocumentPreview);
      },
      // updateDocumentPreview() {
      //   const src = this.get("src");
      //   const frameEl = editor.Canvas.getFrameEl();
      //   const iframeDocument = frameEl?.contentDocument || frameEl?.contentWindow?.document;
      //   const previewEl = iframeDocument.querySelector(".document-preview");    
      //   if (previewEl && src) {
      //     ReactDOM.render(
      //       <div style={{ display: "flex", alignItems: "center" }}>
      //         <object data={src} width="800" height="400"></object>
      //       </div>,
      //       previewEl
      //     );
      //   }
      // },
      updateDocumentPreview() {
        const src = this.get("src");
        if (src) {
          // Directly update the GrapesJS component content
          this.set("content", `<object data="${src}" type="application/pdf" width="800" height="400"></object>`);
        }
      },
    },
    view: {
      events: {
        click: 'openUploadModal'
      },
      openUploadModal(e) {
        const model = this.model;
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.pdf';
        fileInput.onchange = async (event) => {
          const file = event.target.files[0];
          if (file) {
            try {
              const uploadedFile = await uploadToAPI(file);
              if (uploadedFile) {
                const { signedUrl, url, id } = uploadedFile;
                editor.AssetManager.add({
                  src: signedUrl,
                  type: 'document',
                  id,
                });
                // Add the asset details to the local state
                setAssets((prev) => [...prev, { id, type: 'document', src: url, signedUrl }]);
                model.set('src', signedUrl);
                model.addAttributes({
                  'data-document-type': file.type
                });
              }
            } catch (error) {
              console.error(error);
              toaster.error('Document upload failed');
            }
          }
        };
        fileInput.click();
      }
    }
  });  
  const customStyles = `body {
      padding: 20px;
      box-sizing: border-box;
    }
      a {
    color: #d983a6;
    text-decoration: underline;
    cursor: pointer;
  }
  a:hover {
    text-decoration: none;
  }`;
  const combinedStyles = customStyles + initialData.editorStyles ? initialData.editorStyles : '';
  initialData.editorHtml && editor.setComponents(initialData.editorHtml);
  editor.setStyle(combinedStyles);
  setEditorInstance(editor);

  // Extract media components and fetch signed URLs
  const mediaSources = initialData.editorComponents ? extractMediaComponents(
     JSON.parse(initialData.editorComponents)
  ) : null;
  if ( mediaSources && mediaSources.length > 0 ) {
   getSignedUrls(mediaSources).then((data) => {
    let editCaseAssets = [];
    if (data?.content?.length>0) {
      data?.content?.map((obj) => {
        setAssets((prev) => [...prev, { id:obj?.id, type: obj?.type?.startsWith('document') ? 'document' : obj?.type?.startsWith('image') ? 'image' : 'video', src: obj?.url, signedUrl:obj?.signedUrl }]);
        editCaseAssets.push({ 
          id: obj?.id, 
          type: obj?.type?.startsWith('document') ? 'document' : obj?.type?.startsWith('image') ? 'image' : 'video', 
          src: obj?.signedUrl, 
          signedUrl: obj?.url 
        });
      })
      const editorComponents = JSON.stringify(editor?.getComponents());
      const editorStyles = JSON.stringify(editor?.getStyle());
      const editorHtml = editor?.getHtml();

      const updatedComponents = replaceSrcInComp_Assets(JSON.parse(editorComponents), editCaseAssets);
      const updatedHtmlComponents = replaceSrcInHtml(editorHtml,updatedComponents,editCaseAssets);
     editor.setComponents(updatedHtmlComponents);
     editor.setStyle(editorStyles);
    }
   });
  }
 }, []);

 const replacesrcInDocHtml = (html,assets) =>{
 
 }
 const replaceSrcInHtml = (html, components, assets) => {
  let updatedHtml = html;

  components.forEach((comp) => {
    // Check if the component has an `src` attribute (images, videos)
    if ((comp.attributes && comp.attributes.src) || comp.src) {
      const signedUrl = comp.attributes?.src || comp.src;
      const finalUrl = assets.find((asset) => asset.signedUrl === signedUrl)?.src;
      // Replace the signedUrl with the final URL in the raw HTML
      if (finalUrl) {
        // Escape any special characters in the signedUrl to create a proper regex pattern
        const escapedSignedUrl = signedUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedSignedUrl, 'g'); // Create regex with escaped signedUrl

        // Replace all occurrences of the signedUrl with the final URL
        updatedHtml = updatedHtml.replace(regex, finalUrl);
      }
    }else if(comp.attributes && comp.attributes.data){    
      const signedUrl = comp.attributes?.data;
      const finalUrl = assets.find((asset) => asset.signedUrl === signedUrl)?.src;
      // Replace the signedUrl with the final URL in the raw HTML
      if (finalUrl) {
        // Escape any special characters in the signedUrl to create a proper regex pattern
        const escapedSignedUrl = signedUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedSignedUrl, 'g'); // Create regex with escaped signedUrl

        // Replace all occurrences of the signedUrl with the final URL
        updatedHtml = updatedHtml.replace(regex, finalUrl);
      }
    }
    // Recursively check for nested components (if any)
    if (comp.components && comp.components.length > 0) {
      updatedHtml = replaceSrcInHtml(updatedHtml, comp.components, assets);
    }
  });

  return updatedHtml;
};
const replaceSrcInComp_Assets = (components, assets) => {

  // Recursive helper function
  const replaceSrcRecursively = (comp) => {
    // Process current component
    if (comp.type === 'image' || comp.type === 'video') {
      const asset = assets.find(a => a.signedUrl === comp.attributes?.src || a.signedUrl === comp.src);
      if (asset) {
        if (comp.attributes?.src) {
          comp.attributes.src = asset.src;
        }
        if (comp.src) {
          comp.src = asset.src;
        }
      }
    } else if (comp?.attributes?.type === 'document' || comp?.attributes?.type === 'application/pdf') {
      const asset = assets.find(a => a.signedUrl === comp.attributes?.data);
      if (asset) {
        if (comp.attributes?.data) {
          comp.attributes.data = asset.src;
        }
      }
    }

    // Check for nested components (children) and apply the function recursively
    if (comp?.components && Array.isArray(comp.components)) {
      comp.components = comp.components.map(replaceSrcRecursively);
    }

    return comp;
  };

  // Apply the recursive function to all components
  return components.map(replaceSrcRecursively);
};

 const handleSaveSubmit = async (type) => {
  if (!editorInstance) return;
  if (editorInstance?.getComponents()?.length > 0) {
  // Extract data from the editor
  const editorHtml = editorInstance?.getHtml();
  const editorCss = editorInstance?.getCss();
  const editorComponents = JSON.stringify(editorInstance?.getComponents());
  const editorStyles = JSON.stringify(editorInstance?.getStyle());
  const editorAssets = JSON.stringify(editorInstance?.AssetManager.getAll().map(asset => asset.toJSON()));
  
  const updatedComponents = replaceSrcInComp_Assets(JSON.parse(editorComponents), assets);
  const updatedAssets = replaceSrcInComp_Assets(JSON.parse(editorAssets), assets);
  const updatedHtml = replaceSrcInHtml(editorHtml, JSON.parse(editorComponents), assets);

  const newEditedComp = {
    editorHtml: updatedHtml,
    editorCss,
    editorComponents: JSON.stringify(updatedComponents),
    editorStyles,
  };
   // Check if newEditedComp is the same as initialData
   if (
    actionType === "edit" && type === "saveSubmit" &&
    (JSON.stringify(newEditedComp.editorHtml) === JSON.stringify(initialData.editorHtml)&&JSON.stringify(newEditedComp.editorCss) === JSON.stringify(initialData.editorCss) && JSON.stringify(newEditedComp.editorComponents) === JSON.stringify(initialData.editorComponents)&&JSON.stringify(newEditedComp.editorStyles) === JSON.stringify(initialData.editorStyles))
  ) {
    toaster.info("No changes detected in the data.");
    return;
  }

  type === "saveSubmit"
        ? setIsSaveSubmitDisabled(true)
        : setIsSaveDraftDisabled(true);

  const requestBody = {
   editorHtml : updatedHtml,
   editorCss,
   editorComponents : JSON.stringify(updatedComponents),
   editorAssets: JSON.stringify(updatedAssets),
   editorStyles,
   description: initialEditCaseData?.description,
   featureImage: initialEditCaseData?.featureImage,
   momentIds: initialEditCaseData?.momentIds?.momentId || [2655],
   status: initialEditCaseData?.status,
   tag: initialEditCaseData?.tag?initialEditCaseData?.tag:'-',
   title: initialEditCaseData?.title,
  };

  try {
    const folderId = parseInt(localStorage.getItem('folderId'));
    const documentId = initialEditCaseData?.id || 7;

   const response = 
    type === "saveSubmit"
      ? await updateDocument(requestBody, folderId, isDraft ? draftDocId : documentId)
      : await saveToDraft(requestBody, folderId,isDraft ? draftDocId : documentId)
    
   if (response.data.status === 0){
   toaster.success(response.data.message)
   setTabSelect("basic_details")
   getDocumentDetalisEditCase()
   } else {
   toaster.error(response.data.error);
   }
  } catch (error) {
   console.error("Error creating document:", error);
  }finally {
    type === "saveSubmit"
      ? setIsSaveSubmitDisabled(false)
      : setIsSaveDraftDisabled(false);
  }
}else{
  toaster.error('Please add a Block to submit ');
}
 };
 const [showAnimation, setShowAnimation] = useState(true);
 const [fadeOut, setFadeOut] = useState(false);
 const closeAnimation = () => {
  setFadeOut(true);
  setTimeout(() => {
    setShowAnimation(false);
  }, 500); 
};

 return (
  <>
  {isDraft && showAnimation &&
  <>
    <div className={`parent-animated ${fadeOut ? 'fade-out' : ''}`}>
      <div className="animated-text-grape">{isDraft?'There are some unsaved changes in draft, click publish to save the changes':''} <CloseIcon className="close-icon-styles" onClick={closeAnimation} /></div>
    </div>
  </>}

  <div>
   <div id="gjs"></div>
   <div id="blocks"></div>
   <div className='grapesJS_button' style={{gap:'10px'}}> 
   {(actionType === 'edit' && initialEditCaseData?.status === 'PUBLISH') &&<button type="submit" className="btn px-4 lh-base update-btn mt-2" onClick={()=>handleSaveSubmit('saveToDraft')} disabled={isSaveDraftDisabled || isSaveSubmitDisabled}>{isSaveDraftDisabled ? <Loading variant="light" /> : "Save as Draft"}</button>}
   <button type="submit" className="btn px-4 lh-base update-btn mt-2" onClick={()=>handleSaveSubmit('saveSubmit')} disabled={isSaveSubmitDisabled || isSaveDraftDisabled}>{isSaveSubmitDisabled ? <Loading variant="light" /> : "Save & Submit"}</button>
   </div>
  </div>
  </>
 );
};

export default Grapes;
