import { Card, CardContent, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useState } from "react";
import './meetingDetail.css'
import ReactPlayer from "react-player";
import Carousel, { consts } from "react-elastic-carousel";
import first from '../Meeting/1.png'
import toaster from "../../Toast/toaster";


const cData = [{
  img: first,
  time: '00:01',
  point: 'Project Overview'
}, {
  img: first,
  time: '01:53',
  point: 'Python Installation skbkknkcksc'
}, {
  img: first,
  time: '02:05',
  point: 'Creating a Project'
}, {
  img: first,
  time: '07:04',
  point: 'Database Setup'
}, {
  img: first,
  time: '00:01',
  point: 'Project Overview'
}, {
  img: first,
  time: '01:53',
  point: 'Python Installation skbkknkcksc'
}, {
  img: first,
  time: '02:05',
  point: 'Creating a Project'
}, {
  img: first,
  time: '07:04',
  point: 'Database Setup'
}]

const speakerTracks = [{
  name: "Himanshu Aggarwal",
  type: "Internal",
  talkToListen: 'N/A',
  monologue: "00:00 min"
}, {
  name: "Ezeiatech Mobility",
  type: "External",
  talkToListen: 'N/A',
  monologue: "00:00 min"
},{
  name: "Himanshu Aggarwal",
  type: "Internal",
  talkToListen: 'N/A',
  monologue: "00:00 min"
}]

const summaryData = {
  user: "Himanshu Aggarwal",
  date: "11 June 2024",
  time: "02:07 PM",
  recorder: "Himanshu Aggarwal",
  client: null,
  clientId: null,
  participants: ["Himanshu Aggarwal", "Ezeiatech Mobility", "Rahul Tanwar", "Anuj Upadhyay"],
  meetingPlatform: "Google Meet",
  summary: 'The conversation is about a project that involves creating a desktop app for online and offline use. The project is built on a microservices architecture, with different modules like setting microservice and staff microservice. The speaker has worked on creating an API gateway for communication between separate microservices and has used third-party libraries for error handling and bug fixing. Real-time functionality involves sending ernails and comparing data from a large item table in the database. The conversation also includes discussions about Python installation, creating a new project, creating a user, and setting up a database.'
}

const actionData = [{
  name: "Preeti Rai",
  action: "Set up Python on the system."
}, {
  name: "Preeti Rai",
  action: "Create a new project."
}
]

function MeetingDetail() {
  const [loading, setLoading] = useState(false);
  const [speakerTabValue, setSpeakerTabValue] = useState('1');
  const [mainTabValue, setMainTabValue] = useState('1');
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, newValue) => {
    setSpeakerTabValue(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setMainTabValue(newValue)
  }

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(summaryData.summary)
      .then(() => {
        toaster.success('Summary copied to clipboard!');
      })
      .catch(err => {
        toaster.error('Failed to copy: ', err);
      });
  };

  const myArrow = ({ type, onClick }) => {
    const pointer = type === consts.PREV ? '❮' : '❯'
    return (
      <button onClick={onClick} disabled={loading} className="carousel-arrows" style={{ cursor: 'pointer' }}>
        {pointer}
      </button>
    )
  }

  return (
    <div className="page-wrapper">
      <div className="page-content" style={{ paddingBottom: '60px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <Card sx={{ flex: '2', borderRadius: '6px !important',minHeight:'900px'}}>
            <CardContent sx={{ padding: '0' }}>
              {/* player div................................................................. */}
              <div>
                <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' controls={true} width={'100%'} />
              </div>
            </CardContent>
            <CardContent>
              {/* carousel slider................................................................. */}
              <div style={{ width: '98%' }}>
                <p style={{ margin: '0', padding: '5px 5px 5px 15px', fontWeight: '500' }}>4 key moments in this meeting</p>
                <Carousel pagination={false} itemsToShow={4} itemsToScroll={3} {...(loading ? { renderArrow: myArrow } : {})}>
                  {cData.map((item, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', boxSizing: 'border-box', width: '100%' }}>
                      <div style={{ width: '125px', flexShrink: 0 }}>
                        <img src={item.img} alt="" style={{ width: '100%' }} />
                      </div>
                      <p style={{ margin: '0', paddingTop: '8px', color: '#7A42CE', fontWeight: 'bold', fontSize: '11px' }}>{`From ${item.time}`}</p>
                      <div style={{ width: '125px', overflow: 'hidden' }}>
                        <p style={{ margin: '0', fontSize: '12px', textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.point}</p>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              {/* project overview................................................................ */}
              <div style={{ border: '1px solid #f8f9fe', margin: '15px', borderRadius: '8px', backgroundColor: '#edeef1' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '6px' }}>
                  <p style={{ marginBottom: '0', padding: '5px 0px 0px 10px', fontSize: '15px', fontWeight: '600' }}>Project Overview</p>
                  <button style={{ color: 'white', backgroundColor: '#7A42CE', border: 'none', borderRadius: '5px' }}>Share Moment</button>
                </div>
                <p style={{ marginLeft: '16px' }}>Discussion about the project's offline mode, frontend and backend technologies, and microservices.</p>
              </div>
              {/* speaker track and comments......................................................... */}
              <div>
                <TabContext value={speakerTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" centered >
                      <Tab label="Speaker Tracks" value="1" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className="filter_scroll" sx={{ padding: '10px', maxHeight:'160px' }}>
                    {speakerTracks.map((item) => (
                      <>
                        <div style={{ display: 'flex', padding: '5px' }}>
                          <div style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
                            <i class='bx bxs-user-circle' style={{ fontSize: '25px', color: 'rgb(122, 66, 206)' }} />
                            <p style={{ margin: '0', padding: '4px 6px', fontWeight: '500' }}>{item.name}</p>
                            <span style={{ fontSize: '12px', padding: '4px 6px', borderRadius: '6px', backgroundColor: 'rgb(237, 238, 241)' }}>{item.type}</span>
                          </div>
                          <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ margin: '0', fontSize: '12px' }}>Talk to listen: <span style={{ fontWeight: '600' }}>{item.talkToListen}</span></p>
                            <p style={{ margin: '0', fontSize: '12px' }}>Longest Monologue: <span style={{ fontWeight: '600' }}>{item.monologue}</span></p>
                          </div>
                        </div>
                        <hr style={{ margin: '0', padding: '4px 0px 4px 0px', borderRadius: '5px' }} />
                      </>
                    ))}
                  </TabPanel>
                </TabContext>
              </div>
            </CardContent>
          </Card>
          {/* Main Tabs Summary and transcript..................................................... */}
          <Card sx={{ flex: '1', borderRadius: '6px !important', minHeight:'900px' }}>
            <CardContent className="filter_scroll_summary">
              <div>
                <TabContext value={mainTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" centered >
                      <Tab label="Summary" value="1" />
                      <Tab label="Transcript" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ padding: '5px' }}>
                    {/* Summary Details................................................... */}
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'column', padding: '18px 4px 9px 4px' }}>
                        <p style={{ margin: '0', fontWeight: '600', fontSize: '15px' }}>Meeting with {summaryData.user}</p>
                        <span style={{ fontSize: '11px', color: '#737474' }}>{summaryData.date} | {summaryData.time}</span>
                      </div>
                      <div className={`toggled-details ${expanded ? 'expanded' : 'collapsed'}`}>
                        <div style={{ padding: '9px 4px 9px 4px' }}>
                          <p style={{ margin: '0', fontSize: '13px' }}>{summaryData.participants.length} Participants:</p>
                          <span style={{ fontSize: '13px', fontWeight: '600' }}>{summaryData.participants.join(', ')}</span>
                        </div>
                        <div style={{ display: 'flex', padding: '9px 4px 9px 4px' }}>
                          <span style={{ width: '50%', fontSize: '13px' }}>Recorded By: <p style={{ margin: '0', fontWeight: '600' }}>{summaryData.recorder}</p></span>
                          <span style={{ width: '50%', fontSize: '13px' }}>Client Name<p style={{ margin: '0', fontWeight: '600' }}>{summaryData.client ? summaryData.client : "--"}</p></span>
                        </div>
                        <div style={{ display: 'flex', padding: '9px 4px 9px 4px' }}>
                          <span style={{ width: '50%', fontSize: '13px' }}>Client Id<p style={{ margin: '0', fontWeight: '600' }}>{summaryData.clientId ? summaryData.clientId : "--"}</p></span>
                          <span style={{ width: '50%', fontSize: '13px' }}>Meeting Platform<p style={{ margin: '0', fontWeight: '600' }}>{summaryData.meetingPlatform}</p></span>
                        </div>
                      </div>
                      {/* hr tag with accordian over it ................................*/}
                      <div style={{ height: '40px' }}>
                        <hr style={{ margin: '2rem 0' }} />
                        <button onClick={handleToggle} style={{ border: '1px solid rgb(115, 116, 116)', backgroundColor: 'white', borderRadius: '8px', padding: '5px 10px', position: 'relative', bottom: '48px', left: '100px', cursor: 'pointer' }}>{expanded ? <>View Less <i class='bx bx-up-arrow-alt'></i></> : <>View More <i class='bx bx-down-arrow-alt' ></i></>}</button>
                      </div>
                      {/* Summary ............................... */}
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 5px' }}>
                          <span style={{ fontWeight: '600', fontSize: '15px' }}>Summary</span>
                          <i class='bx bx-copy' style={{ fontSize: '18px', cursor: 'pointer' }} onClick={handleCopy} title="Copy" />
                        </div>
                        <p style={{ fontSize: '13px', padding: '8px 5px' }}>{summaryData.summary}</p>
                      </div>
                      {/* Actions .................................................. */}
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 5px' }}>
                          <span style={{ fontWeight: '600', fontSize: '15px' }}>Actions Items <p style={{ fontWeight: 'normal', fontSize: '12px', margin: '0' }}>4 Items</p></span>
                          <i class='bx bx-copy' style={{ fontSize: '18px', cursor: 'pointer' }} onClick={handleCopy} title="Copy" />
                        </div>
                        <ul style={{margin:'0',padding:'8px 5px 8px 20px',fontSize:'13px'}}>
                          {actionData.map((item) => {
                            return <li>{`${item.name} - ${item.action}`}</li>
                          })}
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2">hello</TabPanel>
                </TabContext>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default MeetingDetail;