import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, Stack } from "@mui/joy";
import TextField from "@mui/material/TextField";
import { Autocomplete, CircularProgress } from "@mui/material";
import ReactTable from "../../Commons/Table/ReactTable";
import ReactApexChart from "react-apexcharts";
import "../CoachingDashboard/coachingDashboard.css";
import { useForm, Controller } from "react-hook-form";
import { getAgentsListByCategoryId } from "../../Services/coaching.service";
import toaster from "../../Toast/toaster";
import NodataFound from "../../Commons/NoDataFound";


function CoachingDashboardTableStats({ list, categoryData, selectedCategoryAgentList, loader }) {

    const sessionColumn = [
    {
        Header: "S.no",
        accessor: (d, index) => index+1,
    },
    {
        Header: "Name",
        accessor: (d) => d.assignedTo,
    },
    {
        Header: "Completed Sessions",
        accessor: (d) => d.completedSessions,
    },
    {
        Header: "Assigned Sessions",
        accessor: (d) => d.assignedSessions,
    },
    {
        Header: "Score (%)",
        accessor: (d) => (d.score),
    },
    // {
    //     Header: "Points",
    //     accessor: (d) => (d.points),
    // },
    // {
    //     Header: "Badges",
    //     accessor: (d) => (d.badges),
    // },
    // {
    //     Header: "Level",
    //     accessor: (d) => (d.level),
    // },
    {
        Header: "Actions",
        accessor: "View",
    },
    ];

 // const progress = list.length > 0 ? tableData?.map(item => item.coachingProgress) : []

 // const chartOptions = {
 //     chart: {
 //         id: 'line-chart',
 //         zoom: {
 //             enabled: false
 //         },
 //     },
 //     xaxis: {
 //         categories: teamNames,
 //         labels: {
 //             style: {
 //                 fontSize: '12px',
 //             },
 //             offsetY: 5,
 //         },
 //         title: {
 //             text: 'Teams',
 //         },
 //     },
 //     stroke: {
 //         curve: 'smooth',
 //     },
 //     dataLabels: {
 //         enabled: false,
 //     },
 //     markers: {
 //         size: 5,
 //     },
 //     yaxis: {
 //         title: {
 //             text: 'Progress',
 //         },
 //         min: 0,
 //         max: 100,
 //         tickAmount: 10,
 //         labels: {
 //             formatter: (value) => `${value}%`,
 //         }
 //     },
 //     grid: {
 //         padding: {
 //             left: 40
 //         },
 //     },
 //     tooltip: {
 //         x: {
 //             show: true,
 //         },
 //     },
 // };

 // const seriesData = [
 //     {
 //         name: 'Coaching Progress',
 //         data: progress,
 //     }
 // ];

//  const handleCategoryChange = (selectedCategoryObject) => {
//   setLoader(true);
//   if(selectedCategoryObject?.id){
//   const reqBody = {
//    categoryId: selectedCategoryObject?.id,
//   };
//   getAgentsListByCategoryId(reqBody)
//    .then((response) => {
//     if (response?.data?.status === 0) {
//      setSelectedCategoryAgentList(response?.data?.data?.data);
//     } else {
//      toaster.error("Error while fetching the agents data, please try again");
//     }
//    })
//    .catch((error) => {
//     console.error("Error fetching agents data:", error);
//    })
//    .finally(() => {
//     setLoader(false);
//    });
// }else{
//     setSelectedCategoryAgentList([]);
//     setLoader(false);
// }
//  };
 const {
  handleSubmit,
  control,
  formState: { errors },
 } = useForm({
  defaultValues: {
   category: null,
  },
 });

    return (
    <>
    <div className="">
        <div className="row">
            <div className="col-12">
                <div className="heading" style={{ margin: "1.7rem 0 0 0" }}>
                    <h4 className="">Leaderboard</h4>
                </div>
            </div>

            <form>
                <Stack sx={{ flexDirection: "row", gap: "2rem" }}>
                    <FormControl sx={{ width: "30%" }}>
                    {/* <FormLabel>Select category</FormLabel> */}
                    {/* <Controller
                    name="category"
                    control={control}
                    rules={{ required: "Please select a category" }}
                    render={({ field }) => (
                    <Autocomplete
                    options={categoryData || []}
                    getOptionLabel={(option) => `${option.name || ""}`.trim()}
                    filterSelectedOptions
                    onChange={(event, value) => {
                        field.onChange(value);
                        handleCategoryChange(value);
                    }}
                    value={field.value || null} // Ensure null value is handled
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        placeholder="Select Category"
                        error={!!errors.category}
                        helperText={errors.category ? errors.category.message : ""}
                        />
                    )}
                    />
                    )}
                    /> */}
                    </FormControl>
                </Stack>
            </form>
            {loader ? (
                <div className="justify-content-center align-items-center" style={{ display: "flex", height: "45vh" }}>
                    <CircularProgress />
                </div>
            ) : (
                Object.entries(selectedCategoryAgentList).map(([categoryName, agentsData]) => (
                    <>
                    <h6>{categoryName}</h6>
                    <div className="card stats-coaching-dash">
                        <div className="card-body team">
                            <div className="table-responsive">
                                <div className="table-columns-filter-btn">
                                    <div className="table-responsive dataTable-style react-table">
                                        <ReactTable
                                        columns={sessionColumn}
                                        data={agentsData}
                                        componentType="PRODUCTIVITY"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                ))
            // : (
            // control._formValues.category ?<div>
            //  <NodataFound />
            // </div> : <div className="text-center mt-5">
            //     <h6>Please select a category to get the agents list for a category</h6>
            // </div>
            // )
            )}
        </div>
        {/* <div className="row">
                        <div className="col-12">
                            <div className="heading" style={{ margin: '1.7rem 0 0 0' }}>
                                <h4 className="">Coaching Progess</h4>
                            </div>
                        </div>
                        <div className="card stats-coaching-dash">
                            <div className="card-body team">
                                <ReactApexChart
                                    options={chartOptions}
                                    series={seriesData}
                                    type="line"
                                    height="350"
                                />
                            </div>
                        </div>
                    </div> */}
    </div>
    </>
    );
}

export default CoachingDashboardTableStats;
