import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

export default function WantToDeleteModal(props){
    const [lgShow, setLgShow] = useState(false);

    const closeButton = () => setLgShow(false);

    return(
        <Modal show={props.show} onHide={props.close}
            aria-labelledby="manager-modal"
            >
            <Modal.Header closeButton>
                <Modal.Title id="manager-modal">
                    Conformation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure want to delete?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" id="manager-close-button" onClick={props.close}>I doubt</Button>
                <Button variant="primary" onClick={props.isConform}>Yes I'm</Button>
            </Modal.Footer>
        </Modal> 
    )
}