import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { WaveSurfer, WaveForm, Region } from "wavesurfer-react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";
import toaster from "../Toast/toaster";
import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import './commons.css'
import { uploadFile } from "../Services/knowledgeBase.service";

function ReactWaveform({ audioUrl, updateAudioURL }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [regions, setRegions] = useState([]);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("00:00:00");
  const [audioUploadLoader, setAudioUploadLoader] = useState(false);

  const regionsRef = useRef([]);
  const wavesurferRef = useRef();

  const plugins = useMemo(() => {
    return [
      {
        key: "regions",
        plugin: RegionsPlugin,
      },
      {
        key: "top-timeline",
        plugin: TimelinePlugin,
        options: {
          height: 20,
          insertPosition: "beforebegin",
          style: {
            color: "#2D5B88",
          },
        },
      },
    ];
  }, []);

  const convertToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const secondsToTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600).toString().padStart(2, "0");
    const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
    const secs = Math.floor(seconds % 60).toString().padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  const regionCreatedHandler = useCallback((region) => {
    if (region.data.systemRegionId) return;
    setRegions([
      ...regionsRef.current,
      { ...region, data: { ...region.data, systemRegionId: -1 } },
    ]);
  }, []);

  const handleWSMount = useCallback(
    (waveSurfer) => {
      wavesurferRef.current = waveSurfer;

      if (wavesurferRef.current) {
        wavesurferRef.current.load(audioUrl);

        wavesurferRef.current.on("region-created", regionCreatedHandler);

        wavesurferRef.current.on("ready", () => {
          setIsLoaded(true);

          const duration = wavesurferRef.current.getDuration();
          setAudioDuration(duration);

          // Define region spanning the middle 50% of the audio
          const middleStart = duration / 4;
          const middleEnd = middleStart + duration / 2;

          setRegions([
            {
              id: "region-1",
              start: middleStart,
              end: middleEnd,
              color: "rgba(0, 0, 255, 0.1)",
            },
          ]);

          setStartTime(secondsToTime(middleStart));
          setEndTime(secondsToTime(middleEnd));
        });
      }
    },
    [audioUrl, regionCreatedHandler]
  );

  const playPauseHandler = useCallback(() => {
    if (isPlaying) {
      wavesurferRef.current.pause();
    } else {
      wavesurferRef.current.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const handleUpdateRegion = () => {
    const startInSeconds = convertToSeconds(startTime);
    const endInSeconds = convertToSeconds(endTime);

    if (startInSeconds < 0 || startInSeconds > audioDuration) {
      toaster.error(`Start time must be between 0 and ${secondsToTime(audioDuration)} seconds.`)
      return;
    }

    if (endInSeconds < 0 || endInSeconds > audioDuration) {
      toaster.error(`End time must be between 0 and ${secondsToTime(audioDuration)} seconds.`)
      return;
    }

    if (startInSeconds >= endInSeconds) {
      toaster.error("End time must be greater than start time.")
      return;
    }

    setRegions((prevRegions) =>
      prevRegions.map((region) =>
        region.id === "region-1"
          ? { ...region, start: startInSeconds, end: endInSeconds }
          : region
      )
    );
  };

  const handleRegionUpdate = useCallback((region, smth) => {
    setStartTime(secondsToTime(region?.start))
    setEndTime(secondsToTime(region?.end))
  }, []);

  const isValidTimeFormat = (time) => {
    const regex = /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/;
    return regex.test(time);
  };

  function audioBufferToWav(buffer) {
    const numOfChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const length = buffer.length * numOfChannels * 2 + 44;
    const wav = new DataView(new ArrayBuffer(length));

    let offset = 0;

    const writeString = (str) => {
      for (let i = 0; i < str.length; i++) {
        wav.setUint8(offset++, str.charCodeAt(i));
      }
    };

    const writeUint16 = (val) => {
      wav.setUint16(offset, val, true);
      offset += 2;
    };

    const writeUint32 = (val) => {
      wav.setUint32(offset, val, true);
      offset += 4;
    };

    // Write WAV headers
    writeString("RIFF");
    writeUint32(length - 8);
    writeString("WAVE");
    writeString("fmt ");
    writeUint32(16);
    writeUint16(1);
    writeUint16(numOfChannels);
    writeUint32(sampleRate);
    writeUint32(sampleRate * 4);
    writeUint16(numOfChannels * 2);
    writeUint16(16);
    writeString("data");
    writeUint32(buffer.length * numOfChannels * 2);

    // Write audio data
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, buffer.getChannelData(channel)[i]));
        wav.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }

    return wav.buffer;
  }

  const handleTrimAudio = async () => {
    const startInSeconds = convertToSeconds(startTime);
    const endInSeconds = convertToSeconds(endTime);

    if (!wavesurferRef.current) {
      console.error("WaveSurfer instance is not available.");
      return;
    }

    if (startInSeconds < 0 || endInSeconds > audioDuration || startInSeconds >= endInSeconds) {
      toaster.error("Invalid region selected.");
      return;
    }

    try {
      setAudioUploadLoader(true)
      // Fetch the audio file data directly from the Blob URL
      const response = await fetch(audioUrl);
      const arrayBuffer = await response.arrayBuffer();

      // Decode the audio data
      const audioContext = new AudioContext();
      const decodedAudioData = await audioContext.decodeAudioData(arrayBuffer);

      // Create a new buffer for the trimmed region
      const trimmedBuffer = audioContext.createBuffer(
        decodedAudioData.numberOfChannels,
        (endInSeconds - startInSeconds) * decodedAudioData.sampleRate,
        decodedAudioData.sampleRate
      );

      // Copy the selected region data into the new buffer
      for (let channel = 0; channel < decodedAudioData.numberOfChannels; channel++) {
        const originalData = decodedAudioData.getChannelData(channel);
        const trimmedData = trimmedBuffer.getChannelData(channel);
        trimmedData.set(
          originalData.slice(
            Math.floor(startInSeconds * decodedAudioData.sampleRate),
            Math.floor(endInSeconds * decodedAudioData.sampleRate)
          )
        );
      }

      // Create an OfflineAudioContext to render the buffer
      const offlineContext = new OfflineAudioContext(
        trimmedBuffer.numberOfChannels,
        trimmedBuffer.length,
        trimmedBuffer.sampleRate
      );

      const source = offlineContext.createBufferSource();
      source.buffer = trimmedBuffer;
      source.connect(offlineContext.destination);
      source.start(0);

      const renderedBuffer = await offlineContext.startRendering();

      // Convert rendered buffer to a WAV Blob
      const wavFile = audioBufferToWav(renderedBuffer);
      const wavBlob = new Blob([wavFile], { type: "audio/wav" });

      // Generate a dynamic file name
      const fileName = `trimmed-audio-${new Date().toISOString()}.wav`;

      // Create FormData to send to the server
      const formData = new FormData();
      formData.append("file", wavBlob, fileName);
      formData.append("title", fileName);
      formData.append("mimeType", "audio/wav");

      // API call to upload the file
      await uploadFile(formData, "trimmed-audio.wav", "audio/wav").then((res) => {
        setAudioUploadLoader(false)
        updateAudioURL(res?.data?.data?.url)
        toaster.success("Trimmed audio uploaded successfully!");
      })
      .catch((err) => {
        setAudioUploadLoader(false)
        toaster.error("Failed to upload trimmed audio.");
      })
    } catch (error) {
      toaster.error("Error processing audio.");
    }
  };

  return (
      <div className="react-waveform">
        <WaveSurfer
          plugins={plugins}
          onMount={handleWSMount}
          cursorColor="transparent"
          container="#waveform"
          waveColor="#71b7f1"
          progressColor="#1e5f9f"
          height={100}
        >
          <WaveForm>
            {isLoaded &&
              regions.map((regionProps) => (
                <Region
                  onUpdateEnd={handleRegionUpdate}
                  key={regionProps.id}
                  {...regionProps}
                />
              ))}
          </WaveForm>
        </WaveSurfer>
        <div className="d-flex mt-3 waveform-controls-div">
          <Button className="waveform-controls-button" onClick={playPauseHandler}>
            {isPlaying ? "Pause" : "Play"}
          </Button>
          <div className="d-flex flex-column">
            <div className="d-flex waveform-start-end">
              <FormControl>
                <FormLabel>Start</FormLabel>
                <Input
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  placeholder="HH:MM:SS"
                />
              </FormControl>
              <FormControl>
                <FormLabel>End</FormLabel>
                <Input
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  placeholder="HH:MM:SS"
                />
              </FormControl>
              <Button className="waveform-controls-button" onClick={handleUpdateRegion} disabled={audioUploadLoader || (!isValidTimeFormat(startTime) || !isValidTimeFormat(endTime))}>Update Region</Button>
            </div>
            {!isValidTimeFormat(startTime) || !isValidTimeFormat(endTime) ? (
              <span className="p-1" style={{ color: "red" }}>Invalid time format. Use hh:mm:ss.</span>
            ) : <span className="p-1" style={{ color: '#15ca20' }}>Fill the time-stamps to select the region.</span>}
          </div>
          <Button className="waveform-controls-button" onClick={handleTrimAudio} disabled={audioUploadLoader}>{audioUploadLoader ? 'Uploading Audio' : 'Trim'}</Button>
        </div>
      </div>
  );
}

export default ReactWaveform;