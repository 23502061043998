import React, {  useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from "@mui/material/Box";
import { Input, MenuItem, Select, Slider, TextField } from "@mui/material"
// import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { useSelector } from "react-redux";
// import { debounce } from "lodash";
// import { applyFilterData } from "../../redux-container/meeting/action";
import toaster from "../Toast/toaster";
import momentService from "../Services/moment.service";
import dashboardService from "../Services/dashboard.service";

const MeetingFilter = ({setFilterData, disableApply}) => {
    let userRole = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
    let moment_bucket_id = JSON.parse(localStorage.getItem("MOMENT_ID"))
    let localData = JSON.parse(localStorage.getItem("AGENT"))
    let dateFormat = JSON.parse(localStorage.getItem("TIME_FORMAT")).value
    let storeFilterData = useSelector(state => state?.meetings?.filterData)
    let resetState = useSelector(state => state?.meetings?.reset)
    const dateOptions = useSelector(state => state.meetings.dateList)
    const [dateChange, setDateChange] = useState(false)
    const [customInput, SetCustomInput] = useState(localData && localData.selectedRange == 9 ? true : localData.selectedRange == '' ? true : false);
    const [selectedChannel, setSelectedChannel] = useState(storeFilterData && storeFilterData.channelName ? storeFilterData.channelName : "google_meet");
    // const [filteredData, setFilteredData] = useState([])
    const [def, setDef] = useState(false)
    const [date, setDate] = useState(localData && localData.selectedRange ? localData.selectedRange : localData && localData.selectedRange == '' ? 9 : 5);
    const [startDate, setStartDate] = useState(localData?.fromD ? new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`) : new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD ? new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`) : new Date())
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const [meetingDuration, setMeetingDuration] = useState(storeFilterData && storeFilterData.meetingDuration ? storeFilterData.meetingDuration :[0, 100]);
    const [meetId, setMeetId] = useState(storeFilterData && storeFilterData.meetId ? storeFilterData.meetId : '')
    const [momentBucketList, setMomentBucketList] = useState()
    const [momentBucket, setMomentBucket] = useState(localData && localData.momentBucketId ? localData.momentBucketId : moment_bucket_id)
    const [selectedCOE, setSelectedCOE] = useState(localData && localData.selectedCoeRange ? localData.selectedCoeRange.length == 1 ? localData.selectedCoeRange[0] : "Select All" : "")
    const [coeList, setCoeList] = useState()
    // let dispatch = useDispatch()

    function getCoeList(val) {
        disableApply(true)
        dashboardService.getMomentCOEList(val).then(res => {
          disableApply(false)
          setCoeList(res ? res.data.data ? res.data.data : '' : '');
          let userCoe = res ? res.data.data ? res.data.data.map((item) => {
            return item.coeName
          }) : '' : ''
          let coeExternalId = res ? res.data.data ? res.data.data.map((item) => {
            return item.externalId
          }) : '' : ''
          let coeId = res ? res.data.data ? res.data.data.map(item => {
            return item.coeId
          }) : "" : ""
          setFilterData(prevData => ({ ...prevData, selectedCOE: userCoe, externalId: coeExternalId, selectedCoeRange: coeId }))
        })
      }

    function getMomentBuckets() {
        momentService.getMomentBuckets("SALES").then(res => {
          setMomentBucketList(res ? res : '');
          localStorage.setItem("MOMENT_BUCKETS", localStorage.getItem("MOMENT_BUCKETS") ? localStorage.getItem("MOMENT_BUCKETS") : res[0].momentBucketName)
          localStorage.setItem("MOMENT_ID", JSON.parse(localStorage.getItem("MOMENT_ID")) ? JSON.parse(localStorage.getItem("MOMENT_ID")) : res[0].momentBucketId)
          dashboardService.getMomentCOEList(momentBucket ? momentBucket : res[0].momentBucketId).then(res => {
            setCoeList(res ? res.data.data ? res.data.data : '' : '');
            localStorage.setItem("COE_DATA",JSON.stringify(res?.data?.data))
          })
        })
      }
  

    useEffect(() => {
        // Initialize filter data on first load
        setFilterData((prevData) => ({
          ...prevData,
          selectedChannel,
          date,
          startDate,
          endDate,
          meetingDuration,
          meetId,
        }));
      }, [selectedChannel, date, startDate, endDate, meetingDuration, meetId, setFilterData]);

    useEffect(() => {
        if (resetState) {
            let momentBucketData = JSON.parse(localStorage.getItem("MOMENT_ID"))
            setSelectedCOE("Select All")
            setMomentBucket(momentBucketData)
            setSelectedChannel("google_meet")
            setDate(5)
            setMeetingDuration([0, 100])
            setMeetId('')
            SetCustomInput(false)
        }
    }, [resetState])

    useEffect(() => {    
        if (userRole != 'AGENT') {
            getMomentBuckets()
        }
    }, [])

    const handleChannelChange = (event) => {
        setSelectedChannel(event.target.value);
        setFilterData((prevData) => ({
            ...prevData,
            // ...storeFilterData,
            channelName: event.target.value,
          }));
        // dispatch(applyFilterData({ ...storeFilterData, channelName: value }))
    }

    const handleChange = (event) => {
        setDateChange(true)
        setDate(event.target.value)
        setDef(false)
        if (event.target.value == 9) {
            const date = new Date()
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = String(date.getFullYear()).slice(-2);
            SetCustomInput(true)
            setFilterData((prevData) => ({ ...prevData, dateName: "Custom Date", selectedRange: event.target.value, fromD: localData?.fromD ? localData?.fromD : `${day}-${month}-${year}`, toD: localData?.toD ? localData?.toD : `${day}-${month}-${year}` }))
            // dispatch(applyFilterData({ ...storeFilterData, dateName: "Custom Date", selectedRange: event.target.value, fromD: localData?.fromD ? localData?.fromD : `${day}-${month}-${year}`, toD: localData?.toD ? localData?.toD : `${day}-${month}-${year}` }))
        }
        else {
            SetCustomInput(false)
            let SelectedDate = dateOptions && dateOptions.filter(function (el) {
                return el.id == event.target.value;
            })
            let dd = SelectedDate[0].fromDate.split("-")[2]
            let mm = SelectedDate[0].fromDate.split("-")[1]
            let yyyy = SelectedDate[0].fromDate.split("-")[0]

            let dd_1 = SelectedDate[0].toDate.split("-")[2]
            let mm_1 = SelectedDate[0].toDate.split("-")[1]
            let yyyy_1 = SelectedDate[0].toDate.split("-")[0]

            let dateF = `${dd}-${mm}-${yyyy}`
            let dateT = `${dd_1}-${mm_1}-${yyyy_1}`

            setFilterData((prevData) => ({ ...prevData, fromD: dateT, toD: dateF, dateName: SelectedDate[0].name, selectedRange: event.target.value  }))
            // dispatch(applyFilterData({ ...storeFilterData, fromD: dateT, toD: dateF, dateName: SelectedDate[0].name, selectedRange: event.target.value }))
        }
    }

    const handleStartDate = (e) => {
        const currentDate = new Date();
        let selectedDate = new Date(e);

        // Check if the selected date is in the future
        if (selectedDate > currentDate) {
            selectedDate = currentDate;
            toaster.error("Please select valid date range.")
        }

        // Check if the selected start date is after the end date
        if (endDate && selectedDate > new Date(endDate)) {
            selectedDate = new Date(endDate);
            toaster.error("Please select valid date range.")
        }
        setStartDate(selectedDate);
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth() + 1}-${(new Date(e)).getFullYear()}`;
        setFilterData((prevData) => ({ ...prevData, fromD: date, toD: `${(new Date(endDate)).getDate()}-${(new Date(endDate)).getMonth() + 1}-${(new Date(endDate)).getFullYear()}`, dateName: 'Custom Date', selectedRange: 9 }))
        // dispatch(applyFilterData({ ...storeFilterData, fromD: date, toD: `${(new Date(endDate)).getDate()}-${(new Date(endDate)).getMonth() + 1}-${(new Date(endDate)).getFullYear()}`, dateName: 'Custom Date', selectedRange: 9 }))
    }

    const handleEndDate = (e) => {
        const currentDate = new Date();
        let selectedDate = new Date(e);

        // Check if the selected date is in the future
        if (selectedDate > currentDate) {
            selectedDate = currentDate;
            toaster.error("Please select valid date range.")
        }

        // Check if the selected end date is before the start date
        if (startDate && selectedDate < new Date(startDate)) {
            selectedDate = new Date(startDate);
            toaster.error("Please select valid date range.")
        }
        setEndDate(selectedDate);
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth() + 1}-${(new Date(e)).getFullYear()}`;
        setFilterData((prevData) => ({ ...prevData, fromD: `${(new Date(startDate)).getDate()}-${(new Date(startDate)).getMonth() + 1}-${(new Date(startDate)).getFullYear()}`, toD: date, dateName: 'Custom Date', selectedRange: 9 }))
        // dispatch(applyFilterData({ ...storeFilterData, fromD: `${(new Date(startDate)).getDate()}-${(new Date(startDate)).getMonth() + 1}-${(new Date(startDate)).getFullYear()}`, toD: date, dateName: 'Custom Date', selectedRange: 9 }))
    }

    const meetingDurationMarks = [
        {
            value: 0,
            scaledValue: 0,
            label: "0 Min.",
        },
        {
            value: 100,
            scaledValue: 100,
            label: "100 Min.",
        },
    ]

    const handleMomentSelect = (e) => {
        setMomentBucket(e.target.value)
        getCoeList(e.target.value)
        let selectedBucket = momentBucketList.filter(item => {
          return item.momentBucketId == e.target.value
        })
        setFilterData(prevData => ({ ...prevData, momentBucket: selectedBucket[0].momentBucketName, momentBucketId: e.target.value }))
    }

    const handleSelectCoe = (e) => {
        setSelectedCOE(e.target.value)
        let coes = e.target.value == 'Select All' ? coeList : coeList?.filter(function (el) {
        return el.coeId == e.target.value
        })
        setFilterData((prevData)=>({ ...prevData, externalId: e.target.value == 'Select All' ? coes.map(item => { return item.externalId }) : coes[0].externalId, selectedCOE: e.target.value == 'Select All' ? coes.map(item => { return item.coeName }) : [coes[0].coeName], selectedCoeRange: e.target.value == 'Select All' ? coes.map(item => { return item.coeId }) : [e.target.value] }))
    }

    const meetingDurationChange = (e) => {
        setMeetingDuration(e.target.value)
        setFilterData((prevData)=>({ ...prevData, meetingDurationRange: [e.target.value[0] * 60, e.target.value[1] * 60] }))
        // dispatch(applyFilterData({ ...storeFilterData, meetingDurationRange: [e.target.value[0] * 60, e.target.value[1] * 60] }))
    }

    // const debouncedDispatch = useCallback(
    //     debounce((value) => {
    //         dispatch(applyFilterData({ ...storeFilterData, meetingId: value }));
    //     }, 500),
    //     []
    // );

    const meetingIdChange = (e) => {
        const value = e.target.value;
        setMeetId(value);
        setFilterData((prevData)=>({ ...prevData, meetingId: value }));
        // debouncedDispatch(value);
    };

    return (
        <>
            {userRole != 'AGENT' && momentBucketList?.length > 1 ?<div className="lh-1 mb-3">
                <p className="form-label me-1">Moment Bucket</p>
                <hr className="meeting-filter-hr"/>
                <Form.Select
                as="select"
                custom
                name="role"
                aria-label="Default select example"
                onChange={handleMomentSelect}
                value={momentBucket}
                className="form-control-sm py-1"
                >
                {momentBucketList ? momentBucketList.map((bucket) => {
                    return <option value={bucket.momentBucketId} key={bucket.momentBucketId}>{bucket.momentBucketName}</option>;
                }) : ''}
                </Form.Select>
            </div> : ''}
            <div className="lh-1 mb-3">
                <p className="form-label me-1">COE</p>
                <hr className="meeting-filter-hr"/>

                <Form.Select as="select" custom
                style={{ textTransform: "capitalize" }}
                name="role" aria-label="Default select example"
                onChange={handleSelectCoe}
                className="form-control-sm py-1"
                placeholder="Select COE"
                value={selectedCOE}
                >
                <option value={'Select All'}>Select All</option>
                {coeList ? coeList.map((coe) => {
                    return <option value={coe.coeId} key={coe.coeId}>{coe.coeName}</option>;
                }) : ''}
                </Form.Select>
            </div>
            <div className="align-items-baseline mb-2">
                <label class="form-label me-1">Channel</label>
                <hr className="meeting-filter-hr"/>
                <Select
                    id="inputRole"
                    value={selectedChannel}
                    onChange={handleChannelChange}
                    defaultValue={selectedChannel}
                    className="meeting-filter-input-size"
                >
                    <MenuItem value={"google_meet"} >Google Meet</MenuItem>
                    <MenuItem value={"teams"} >Teams</MenuItem>
                    <MenuItem value={"zoom"} >Zoom</MenuItem>
                </Select>
            </div>
            <div className="align-items-baseline">
                <label className="form-label me-1">Date</label>
                <hr className="meeting-filter-hr"/>
                <Select value={date} id="inputRole" className=" p025 br025 meeting-filter-input-size" name="saledateSelected" onChange={handleChange}>
                    {dateOptions && dateOptions.length > 0 && dateOptions?.map(item => { return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) })}
                </Select>
                {customInput ? <div className="call-fillter-date dasbard-comp-setting ms-1">
                    <div className="input-box form-group">
                        <label className="col-form-label mx-1">From:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                openTo="day"
                                views={['year', 'month', 'day']}
                                inputFormat={dateFormat}
                                value={startDate}
                                name="startDate"
                                onChange={handleStartDate}
                                disableOpenPicker
                                open={opendp}
                                onOpen={() => setOpendp(true)}
                                onClose={() => setOpendp(false)}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                    <TextField {...params} onClick={() => setOpendp(true)} />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="input-box form-group">
                        <label className="col-form-label mx-1">To:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                openTo="day"
                                views={['year', 'month', 'day']}
                                inputFormat={dateFormat}
                                value={endDate}
                                name="endDate"
                                onChange={handleEndDate}
                                disableOpenPicker
                                open={opendp1}
                                onOpen={() => setOpendp1(true)}
                                onClose={() => setOpendp1(false)}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                    <TextField {...params} onClick={() => setOpendp1(true)} />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div> : ''}
            </div>
            <div className="align-items-baseline justify-content-end mt-2 mb-2">
                <label className="form-label me-1">Duration</label>
                <hr className="meeting-filter-hr"/>
                <Box sx={{ width: 200, paddingLeft: '15px' }}>
                    <Slider
                        value={meetingDuration}
                        onChange={meetingDurationChange}
                        className="meetingDuration"
                        valueLabelDisplay="auto"
                        marks={meetingDurationMarks}
                        name="meetingDuration"
                    />
                </Box>
            </div>
            <div className="align-items-baseline mb-2">
                <label class="form-label me-1">Meeting ID</label>
                <hr className="meeting-filter-hr"/>
                <Input
                    id="inputMeetingId"
                    value={meetId}
                    onChange={meetingIdChange}
                    className="meeting-filter-input-size"
                />
            </div>
        </>
    )
}

export default MeetingFilter