import ReactApexChart from "react-apexcharts";
import React,{useState, useEffect} from "react"
import Loading from "../../Commons/Loading";
import Pagination from "../../Commons/Table/Pagination";
import NodataFound from "../../Commons/NoDataFound";
import { useHistory } from "react-router-dom";

export default function ProductivityGraph(props){ 
  let PageSize = 10;
  const pageCount = props?.filter?.length>0?(props.filter.length/PageSize):0
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [data, setData] = useState([])
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  let rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))
  const history = useHistory()
  let showConversion = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.conversion

  useEffect(()=>{     
      setData(props?.filter?.length>0?props.filter.slice(0,10): [])
      setPageIndex(0)
      setCurrentPages(pageCount)
  },[props.filter])

  const sendTocalls = (score, name) => {
    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    //let graphdata = categoriesVal?categoriesVal[value] :''
    let key = `0-${Math.ceil(score)}`
    let value = props?.filter.find((user)=>user?.agentName == name)?.userId
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": 1,
        "fromD": storedData?.fromD,
        "toD": storedData?.toD,
        "selectedDate": storedData?.selectedDate,
        // "selectedRange": storedData?.selectedDate === "This Year" ? "7" : '5',
        "selectedRange": storedData?.selectedRange,
        "selectedCoeRange": storedData?.selectedCoeRange,
        "selectedCOE":storedData?.selectedCOE,
        "coe":storedData?.coe
      }))
    history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?callScore=${key}&fromD=${storedData?.fromD}&toD=${storedData?.toD}` : `/sales_conversations?callScore=${key}&fromD=${storedData?.fromD}&toD=${storedData?.toD}`,{"employeeId":value})
  }

  const nextPage = () =>{
    if(pageIndex < pageCount-1){
     let a = (pageIndex+1)*PageSize;
     setData(props.filter.slice(a,a+10))
     setCanPreviousPage(true)
    }
    else{
      setCanNextPage(false);
      setPageIndex(pageIndex+1)
    }
  }

  const previousPage = ()=>{
    if(pageIndex > 0){
      let a = (pageIndex)*PageSize;
      setData(props.filter.slice(a-10,a))
      setCanNextPage(true)
      }
      else{
        setCanPreviousPage(false);
      }
  }

  const gotoPage = (val) =>{
    let a = val*PageSize;
    setPageIndex(val)
    setData(props.filter.slice(a,a+10))
  }

  const changePageIndex = (val) =>{
    let a = val * PageSize;
    setPageIndex(val);
    setData(props?.filter?.slice(a, a + 10));
  }

  let storedData = JSON.parse(localStorage.getItem("AGENT"))?.productivity

  let names = data?.map(item=>{
    return item.agentName
  })

  let callCountDef = data?.map(item=>{
    return item.totalCallVolume
  })

  let callScores = data?.map(item=>{
    return item.callScore
  })
  

  let pitchScores = data?.map(item=>{
    return item.pitchScore
  })

  let convProbs = data?.map(item=>{
    return item.conversionProbability
  })

  let opps = data?.map(item=>{
    return item.opportunity
  })
  
  let urgency = data?.map(item=>{
    return item.urgency
  })
 
  let backgroundNoise = data?.map(item=>{
    return item.backgroundNoise
  })

  let customerEngagement = data?.map(item=>{
    return item.customerEngagement
  })

  let customerSatisfaction = data?.map(item=>{
    return item.customerSatisfaction
  })

    const series= [{
        name: showConversion ? 'Conversion Count' : 'Conversation Count',
        type: 'column',
        data: callCountDef
      }, {
        name: !storedData?'Call Score':storedData==='Call Score'?'Call Score':storedData==='Pitch Score'?'Pitch Score':storedData=='opportunity'?"Opportunity":storedData=='Conversion Probability'?"Conversion Probability":storedData=='Urgency'?"Urgency":storedData=='Background Noise'?"Background Noise":storedData=='Customer Engagement'?"Customer Engagement":storedData=='Customer Satisfaction'?'Customer Satisfaction':"hbvbvuyv",
        type: 'line',
        data: !storedData?callScores:storedData==='Call Score'?callScores:(storedData==='Pitch Score')?pitchScores:storedData=='opportunity'?opps:storedData=='Conversion Probability'?convProbs:storedData=='Urgency'?urgency:storedData=='Background Noise'?backgroundNoise:storedData=='Customer Engagement'?customerEngagement:storedData=='Customer Satisfaction'?customerSatisfaction:"uygyuguyguy"
    }]

    const options= {
        chart: {
          height: 200,
          type: 'line',
          toolbar: {
            show: false
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              sendTocalls(config.w.config.series[1].data[config.dataPointIndex],config.w.globals.categoryLabels[config.dataPointIndex])
            }
          }
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                    value: 0.15,
                }
            },
        },
        stroke: {
          width: [0, 4]
        },
        plotOptions: {
            bar: {
                columnWidth: '10%',
                endingShape: 'rounded'
            }
        },
        title: {
          text: ''
        },
        colors: ["#0077B6", "#ffc107"],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: names,
        xaxis:{
          labels:{
            rotate: -40,
            rotateAlways: true,
            // style:{
            //   fontSize:'10.5px',
            //   fontWeigt:'400',
            // },
          }
        },
        // xaxis: {
        //   // title: {
        //   //   text: 'Employees',
        //   // },
        //   group: {
        //     style: {
        //       fontSize: '10px',
        //       fontWeight: 700,
        //       align: 'center',
        //       textAlign:"right"
        //     },
        //     groups: [
        //       { title: 'Employees',
        //         cols: 4
        //       }
        //     ]
        //   },
        // },
        yaxis: [{
          title: {
            text: showConversion ? 'Conversion Count' : 'Conversation Count',
          },
        
        }, {
          opposite: true,
          title: {
            text: !storedData?'Call Score':storedData==='Call Score'?'Call Score':storedData==='Pitch Score'?'Pitch Score':storedData=='opportunity'?"Opportunity":storedData=='Conversion Probability'?"Conversion Probability":storedData=='Urgency'?"Urgency":storedData=='Background Noise'?"Background Noise":storedData=='Customer Engagement'?"Customer Engagement":storedData=='Customer Satisfaction'?'Customer Satisfaction':"hbvbvuyv",
          }
        }],
    }

    return(
      <>
      {
      series[0].data.length>0&&series[1].data.length>0?
      (
        <div className="p-4">
            <ReactApexChart type={'line'} options={options} series={series} height="500"/>
            <Pagination
            className="pagination-bar mb-1"
            currentPage={currentPage}
            totalCount={props?.filter.length}
            pageSize={PageSize}
            pageIndex={pageIndex}
            currentPages={currentPages}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            handlePageIndex={changePageIndex}
          /> 
        </div>)
      :<NodataFound/>
      }
      </>
    )}