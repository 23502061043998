import { Card } from "react-bootstrap";
import React,{ useState } from "react";
import { Col,Row } from "react-bootstrap";
import { Tab, Tabs,Nav } from "react-bootstrap";
import Loading from "../../Commons/Loading";
import All_Ques from "./All_Ques";
import EmailTranscript from "../Sales/Modals/EmailTranscript";

export default function (props) {
  
  const [show, setShow] = useState(false)
  const [keyClicked, setKeyClicked] = useState("all");
  const closeButton=()=>setShow(false)
  const handleClick=()=>{
    setShow(true)
  }
 
  const setEmailFalse = ()=>{
    setShow(false)
  }

  const handleSelect = (keyClicked)=>{
    setKeyClicked(keyClicked)
  }

  return (
    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :

      <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primaryquestion">
          <div className="d-flex">
            <div className="moment-filter-tab-box">
              
            <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked?keyClicked:"all"}>
                <Row>
                  <Col sm={10}>
                    <Nav variant="pills" className="flex" onSelect={handleSelect}>
                      <Nav.Item>
                      {<Nav.Link eventKey="all" >All</Nav.Link>}
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="customer">{props.callQuestions[0]?.customerName}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="seller">{props.callQuestions[0]?.agentName}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={2} className="moments-email-btn mb-3 text-sm-right pe-0">
                    <a className="btn compose-mail-btn" onClick={handleClick}>Email Questions</a>
                  </Col>
                  <Col sm={12} className="px-0">
                    <Tab.Content>
                      {keyClicked=='all'||keyClicked=='customer'||keyClicked=='seller'?
                      <Tab.Pane eventKey={keyClicked} >
                        <All_Ques keyClicked={keyClicked?keyClicked:""} callQuestions={props.callQuestions} tabSelected={props.tabSelected} tabRedirected={props?.tabRedirected} callId={props?.callId} customerName={props?.customerName} sellerName={props?.sellerName} phone={props?.phone} agentExternalId={props.agentExternalId} likeDislikeData={props?.likeDislikeData} eventType={props?.eventType} postId={props?.postId} agentId={props?.agentId}/>
                      </Tab.Pane>
                    :""}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        <EmailTranscript show={show} closeButton={closeButton} type={props?.type} ques={props.callQuestions} tabSelected={props.tabSelected} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId}/>
      </div>
    }
    </>
  );
}
