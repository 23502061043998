import { useState, useEffect } from "react";
import CommentOnModal from "../Modals/CommentOnModal";
import ViewParameters from "../Modals/ViewParameters";
import Loading from "../../../Commons/Loading";
import * as React from 'react'
import EmailTranscript from "../Modals/EmailTranscript";
import callsService from "../../../Services/calls.service";
import notificationsService from "../../../Services/notifications.service";
import toaster from "../../../Toast/toaster";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import '../Stats/Stats.css'


export default function Stats(props) {

  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [emailButton, setEmailButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [infoShow, setInfoShow] = useState(false)
  const [idClicked, setId] = useState("")
  const [stats, setStats] = useState("")
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [emailId, setEmailId] = useState("")
  const [emailLabel, setEmailLabel] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [commentData, setCommentData] = useState([])
  const [show, setShow] = useState(false)
  const [likeDislike, setLikeDislike] = useState([])
  const [statShow,setStatShow] = useState(false)
  const [expandedStats, setExpandedStats] = useState([]);
  const history = useHistory();

  const closeButton=()=>{
    setShow(false)
    setEmailShow(false)
    setCommentShow(false)
    setInfoShow(false)
  }
  const handleClick=()=>{
    setShow(true)
  }
  const setEmailFalse = ()=>{
    setShow(false)
  }

  const showStat = ()=>{
    setStatShow(!statShow)
  }

  const toggleStatExpansion = (id,parameters) => {
    if (!parameters || Object.keys(parameters)?.length === 0) {
      return; 
    }
    setExpandedStats((prevExpanded) =>
      prevExpanded.includes(id)
        ? prevExpanded.filter((prevId) => prevId !== id)
        : [...prevExpanded, id]
    );
  };

  const getTimeVal = (val)=>{
    var minutes = Math.floor(val / 60);
    var seconds = val - minutes * 60;
    return `${minutes} Min. ${parseInt(seconds)} Sec`
  }

  const conv=(d)=>{
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : "";
    var mDisplay = m > 0 ? m : "";
    var sDisplay = s > 0 ? s : "";
    return (
      <>
        {h>0?<span>
          {hDisplay}
          <span style={{fontSize:"10px"}}>{h == 1 ? " hr " : " hrs "}</span>
        </span>:""} 
        {m>0?<span>
          {mDisplay}
          <span style={{fontSize:"10px"}}>{m == 1 ? " min " : " mins "}</span>
        </span>:""}
        {s>0?<span>
          {sDisplay}
          <span style={{fontSize:"10px"}}>{s == 1 ? " sec" : " secs"}</span>
        </span>:""}
      </>
    )
    // const totalMinutes = Math.floor(totalSeconds / 60);

    // const seconds = totalSeconds % 60;
    // const hours = Math.floor(totalMinutes / 60);
    // const minutes = totalMinutes % 60;

    // return `${hours}hrs${minutes}mins${seconds}sec`;
  }

  const decimalConv = (duration) => {
    const [minutes, decimal] = duration.split('.');
    const seconds = Math.round((`0.${decimal}`) * 60); // Convert fractional part to seconds
    return minutes > 0 
    ? `${minutes} min. ${seconds} sec.`
    : `${seconds} sec.`;
  };

  const callback = (e)=>{
    setCommentData(e)
  }

  let userDetail = props.type === "email" ? JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationEmail?.emailStats
  : JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.stats
  let chatVal =['Interactivity','Background Noise','Fatal Call','Seller ROS','Customer ROS',]
  // const emailVal = ['Background Noise','Seller ROS','Customer ROS','Total Duration', 'Customer Engagement','Longest Rep Monologue','Longest Customer Story']
  let groupVal = ['Disposition']
  // let emailVal = ['Disposition']
  let userDet = userDetail.filter(item=>{
    return props?.typeOfCall=='GROUP'?!groupVal.includes(item.displayName): props.type=='chat'?!chatVal.includes(item.displayName):true
  })

  let cardd = userDet.map(item=>{
    // let data = Object?.entries(commentData.length>0?commentData[0]:props.callStats)?.filter((d)=>{return d.includes(item?.value)})[0]
    let data;

    if (commentData?.length > 0 && commentData[0]) {
      data = Object.entries(commentData[0])
        .filter((d) => d.includes(item?.value))[0];
    } else if (props.callStats) {
      data = Object.entries(props.callStats)
        .filter((d) => d.includes(item?.value))[0];
    } else {
      data = null; // or handle the case when neither is available
    }
    // return {label:props.type=='chat'?item.displayName.replace('Patience','Average Gap').replace('Longest Rep Monologue','Longest Rep Story'):item.displayName, value:data!=undefined&&data[1]?.value!=''?data[0]=='totalDuration'?conv(data[1]?.value):data[1]?.value:0, desc:item?.description, color:data!=undefined&&data[1].color!= undefined ?data[1].color:'green', parameters: Object.hasOwn(data[1],'parameters') || Object.hasOwn(data[1], 'Moments') ? data[1].Moments || data[1].parameters : null}
    return {
      label: props?.type === 'email'
      ? item?.displayName.replace('Emaiil Score', 'Score').replace('Fatal Call', 'Fatal')
      : props?.type === 'chat'
        ? item?.displayName.replace('Patience', 'Average Gap').replace('Longest Rep Monologue', 'Longest Rep Story')
        : item?.displayName,
      
        value: (data && data?.[1] && data?.[1]?.value !== '')? 
          (data?.[0] === 'totalDuration' ? data?.[1]?.value
            : data?.[1]?.value) : 0,
      
      desc: item?.description,
      
      color: (data && data?.[1] && data?.[1]?.color !== undefined)
        ? data?.[1]?.color
        : 'green',
      
      parameters: (data && data?.[1])
        ? (Object.hasOwn(data?.[1], 'parameters') || Object.hasOwn(data?.[1], 'Moments'))
          ? data?.[1]?.Moments || data?.[1]?.parameters
          : null
        : null
    };
  })
  
  const likeButtonHandler = (id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={

      "callId": props?.callId,
      "commentType": "STATS",
      "dislike": null,
      "like": 1,
      "postId": id
    };
    callsService.addLikeDislike(value).then(res => {
      if (res) {
        callsService.getLikeDislike(props?.callId, "STATS").then(res => {
          if (res) {
            setLikeDislike(res.data.data)
            setLikeButton(false);
          }
        });
      }
    });
    let notificationBody = [{
			"callId": props?.typeOfCall == "SINGLE_CALL" ? props?.callId : null,
			"userExternalId": props?.agentExternalId,
			"groupId": props?.typeOfCall == "GROUP" ? props?.callId : null,
			"notificationEvent": {
				"eventType": "LIKE",
				"tabName": "stats",
				"tabValue": label,
				"postId": id,
        "notificationModule": props?.module
			}
		}];

    notificationsService.createNotifications(notificationBody).then(res => {
      if (res) {
        toaster.success(res.data.message);
      }
    });
  }

  const dislikeButtonHandler = (id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "STATS",
      "dislike": 0,
      "like": null,
      "postId": id

    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"STATS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.typeOfCall == "SINGLE_CALL" ? props?.callId : null,
			"userExternalId":props?.agentExternalId,
			"groupId":props?.typeOfCall == "GROUP" ? props?.callId : null,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"stats",
				"tabValue":label,
				"postId":id,
        "notificationModule":props?.module
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res.data.message)
      }
    })
  }

  
  const commentButtonHandler = (id,label)=>{
    // setCommentButton(true)
    setCommentShow(true)
    setId(id)
    setStats(label)
  }
  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }
  const mailHandler = (id,label,value)=>{
    setEmailShow(true)
    setEmailButton(true)
    setEmailId(id)
    setEmailLabel(label)
    setEmailValue(value)
  }

  const infoButtonHandler = ()=>{
    setInfoShow(true)
  }
  const popoverr =(data)=> (
    <Popover id="popover-trigger-hover-focus" title="right">
      <h3 className="popover-header" style={{fontSize:"14px",textAlign:"center"}}>{data}</h3>
    </Popover>
  )

  const iconsFunc = (item,id)=>{
    // let transNew = Object?.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.callStats?.postTypeComments?.value)?.filter(val=>{
    //   return(val[1][0].postId==id)
    // })
    let transNew = [];

    if (commentData?.length > 0 && commentData[0]?.postTypeComments) {
      transNew = Object.entries(commentData[0].postTypeComments)
        .filter(val => val[1][0].postId === id);
    } else if (props?.callStats?.postTypeComments?.value) {
      transNew = Object.entries(props.callStats.postTypeComments.value)
        .filter(val => val[1][0].postId === id);
    }
    let ids = transNew.length>0?transNew[0][1]:''
    let likeIcon = likeDislike?likeDislike.length>0?
    (likeDislike?.filter(item=>{
      return(item.postId==id)
    })):
    props?.likeDislikeData.filter(item=>{
      return(item.postId==id)
    }):""

    return(
      <>
        <div>
          {item.label=='Call Score'&&props?.type=='chat'?'Chat Score': item?.label}
          {props?.type=='chat'?'':<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(item?.desc)} rootClose>
            <button className="mb-0 ms-3" style={{border:"none",backgroundColor: `${item?.parameters && Object.keys(item.parameters)?.length !== 0 ? "#f8f9fe": 'white'}`,marginTop:"1px"}}>
              <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
            </button>
          </OverlayTrigger>}
          <div class="list-after-click-group">
          {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
          likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
          {ids&&ids[0].postId==id||commentButton&&idClicked==id?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(id,item.label)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
          </div>
        </div>
        <div className="text-end">
          <span className={`badge btn btn-outline-${item?.color === 'green' || item?.color === undefined ? 'success' : 'danger'} font-normal font-12 rounded-pill`}>
            {item?.value}
          </span>
          <div className="list-hover-input-group">
            {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(id,item.label)}>
                <span className="text-primary-blue">
                  <i className="bx bx-like"></i>
                </span>
            </button>}
            {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id? ""
            :
            <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id,item.label)}>
              <span className="text-primary-blue">
                <i className="bx bx-dislike"></i>
              </span>
            </button>}
            {ids&&ids[0].postId==id||commentButton&&idClicked==id?'':  
            <button className="btn list-comment-before switcher-btn-1" onClick={()=>commentButtonHandler(id,item.label)}
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
              <span className="text-primary-blue">
                <i className="lni lni-comments-alt"></i>
              </span>
            </button>
            }
          </div>
        </div>
      </>
      )
  }

  useEffect(()=>{
    if(props?.eventType=='COMMENT'&&props?.tabRedirected=='stats'){
      setCommentShow(true)
      setId(props?.postId)
      setStats(history.location.state.tabValue)
    }
  },[])

  return (
    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :
    <div className="tab-content py-3">
      <div className="tab-pane fade active show" role="tabpanel">
        <div className="d-flex justify-content-between ">
          <div className="stats-heading">
            <h5>
              Stats
              <a data-bs-toggle="modal">
                <i className="bx bx-git-compare ms-1 align-bottom text-dark"></i>
              </a>
            </h5>
          </div>
          <div className="moments-email-btn mb-3 text-sm-right">
            <a className="btn compose-mail-btn" onClick={handleClick}>Email Stats</a>
          </div>
        </div>
        <div className={`moment-list-group-scroll stats-list-group-scroll ${props?.type === 'email' ? "email-styles" : ""}`} id="statspage">
          <ul className="list-group">
            {
              cardd.map((item,id)=>{
                const isExpanded = expandedStats.includes(id) && item.parameters;
                return(
                <li className={`${id==props?.postId&&props?.tabRedirected=='stats'?'shadow':''} list-group-item d-flex total-duration-stats ${isExpanded ? 'ddropdown' : ''}`} style={{flexDirection:"column",backgroundColor:`${item?.parameters != null && Object.keys(item.parameters)?.length !== 0 ? '#f8f9fe' : ''}`,boxShadow:`${item?.parameters!=null && Object.keys(item.parameters)?.length !== 0?'0 0 5px rgba(0, 0, 0, 0.5)':""}`}} onClick={() => toggleStatExpansion(id,item?.parameters)}>
                  <div style={{display:"flex", justifyContent:"space-between"}}>
                  {iconsFunc(item,id)}
                  <EmailTranscript show={emailShow} setEmailShow={setEmailShow} closeButton={closeButton} tabSelected={props?.tabSelected} callStats={cardd} label={emailLabel} value={emailValue} emailButton={emailButton}/>  
                  {/* <CommentOnModal show={commentShow} setShow={setCommentShow} closeButton={closeButton} tabSelected={props?.tabSelected} callId={props?.callId} list={props?.list}/> */}
                  </div>
                  {isExpanded && (<><div className={`${id==props?.postId&&props?.tabRedirected=='stats'?'shadow':''} list-group-item d-flex justify-content-between total-duration-stats`} style={{marginTop:'7px',flexDirection:'column',boxShadow: isExpanded ? '0 0 5px rgba(0,0,0,0.5)': 'none',  backgroundColor:'#f8f9fe'}}>
                  {item.parameters !== null && Object.keys(item.parameters)?.length !== 0 && (
                  <>
                  {item.label == 'Audit Score' ? <div style={{display:'flex',justifyContent:'space-between',marginRight: '10px'}}>{Object?.entries(item.parameters)?.map((openItem)=>(<span style={{ color: '#818181', marginRight: '10px', fontSize:'13px' }}>
                          {openItem[1]} : {openItem[0]}
                          </span>))}</div> :  
                  Object.entries(item.parameters)?.map((innerItem) => (
                    <div style={{ display: 'flex' , fontSize:'13px' }}>
                      <span style={{width:'30%'}}>{innerItem[0] }</span>
                      <div style={{display:'flex',flex:'1',justifyContent:'space-between'}}>
                      
                        {Object.entries(innerItem[1])?.map((mostInner) => (<span style={{ color: '#818181', marginRight: '10px'}}>{mostInner[1]} : {mostInner[0]}</span>))}
                      
                      </div>
                  </div>
                      ))}
                  </>
                  )}
                </div></>)}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {commentShow && idClicked!=='' && <CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props?.tabRedirected=='stats'?props?.tabRedirected:props?.tabSelected} callId={props?.callId} statsId={idClicked} stats={stats} callback={callback} phone={props?.phone} typeOfCall={props?.typeOfCall} agentExternalId={props.agentExternalId} coeIds={props?.coeIds} module={props?.module}/>}
      <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} type={props?.type} tabSelected={props?.tabSelected} callStats={cardd} label={emailLabel} value={emailValue} emailButton={emailButton} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId}/>
    </div>
    }</>
  );
}
