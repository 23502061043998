import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  ModalClose,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/joy";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/joy/Button";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import conversationService from "../../../Services/conversation.service";
import toaster from "../../../Toast/toaster";
import ReactWaveform from "../../../Commons/ReactWaveform";
import { uploadDocumentTemplate } from "../../../Services/knowledgeBase.service";
import { getFolderList } from "../../../Services/knowledgeBase.service";

function AudioAssign(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      title: "",
      momentIds: [],
      status: "DRAFT",
      folderId: null,
      audioURL: props?.audioPathUrl,
      comment: ""
    },
  });

  const { openModal, setOpenModal } = props;
  const [moments, setMoments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [folderList, setFolderList] = useState([]);
  const getMomentData = async () => {
    const momentBucketId =
      parseInt(localStorage.getItem("MOMENT_ID")) || undefined;
    return await conversationService
      .getCallListingMoments("SALES", momentBucketId)
      .then((res) => {
        if (res.data?.status === 0) {
          setMoments(res?.data?.data);
          setLoading(false);
        } else {
          toaster.error("Error while fetching the moments, please try again");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getFoldersList = async () => {
    return await getFolderList({
      coeIds: JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange,
    })
      .then((res) => {
        if (res.data.status === 0) {
          setFolderList(res?.data.data);
        }
      })
      .catch((err) =>
        toaster.error("Error while fetching the folder list, please try again")
      );
  };

  const updateAudioURL = (newURL) => {
    setValue("audioURL", newURL);
  };

  const onSubmit = async (data) => {
    const formData = {
      title: data.title,
      status: data.status,
      audioURL: data.audioURL,
      momentIds: data.momentIds.map((moment) => moment.momentId),
      comment: data.comment,
    };
    return await uploadDocumentTemplate(data.folderId, formData)
      .then((res) => {
        if (res?.data?.status === 0) {
          console.log(res?.data);
          setState(res?.data.data);
          toaster.success("Uploaded Successfully.");
        }
      })
      .catch((err) => {
        console.error(err);
        toaster.error("Error while uploading, please try again");
      });
  };

  useEffect(() => {
    getMomentData();
    getFoldersList();
  }, []);
  // console.log(folderList);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        reset();
      }}
      sx={{
        backdropFilter: "blur(1px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "15px",
      }}
      disableEscapeKeyDown={true}
    >
      <ModalDialog sx={{ width: "75%", height: "90vh", overflowY: "scroll" }}>
        <ModalClose />
        <DialogTitle>Voice Info</DialogTitle>
        <div className="p-2">
          <audio
            id="audio-element"
            controls
            className="w-100 call-detail-audio-panal"
            controlsList="nodownload"
            preload="metadata"
          >
            <source src={props?.audioUrl} type="audio/wav"></source>
            Your browser does not support the audio element.
          </audio>
        </div>
        <hr style={{ margin: 0 }} />
        <div className="p-2">
          <ReactWaveform
            audioUrl={props?.audioUrl}
            updateAudioURL={updateAudioURL}
          />
        </div>
        <hr style={{ margin: 0 }} />
        <div className="upper-coaching-form">
          <div className="p-2" style={{ flex: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack sx={{ flexDirection: "row", gap: "2rem" }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Title</FormLabel>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: "Please provide a title" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Add title"
                        error={!!errors.title}
                        helperText={
                          errors.title ? errors.title.message : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Status</FormLabel>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="DRAFT"
                          control={<Radio />}
                          label="Draft"
                        />
                        <FormControlLabel
                          value="PUBLISH"
                          control={<Radio />}
                          label="Publish"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack
                sx={{ flexDirection: "row", gap: "2rem", marginTop: "1rem" }}
              >
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Select Moments</FormLabel>
                  <Controller
                    name="momentIds"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value.length > 0 || "Please select at least one moment",
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={moments}
                        getOptionLabel={(option) => option.momentName}
                        onChange={(event, value) => field.onChange(value)}
                        value={field.value}
                        isOptionEqualToValue={(option, value) =>
                          option.momentId === value.momentId
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Moments"
                            error={!!errors.momentIds}
                            helperText={
                              errors.momentIds ? errors.momentIds.message : ""
                            }
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
                {/* <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Comment</FormLabel>
                  <Controller
                    name="comment"
                    control={control}
                    rules={{ required: "Please provide a comment" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Add Comment"
                        error={!!errors.comment}
                        helperText={
                          errors.comment ? errors.comment.message : ""
                        }
                        fullWidth
                      />
                    )}
                  />
                </FormControl> */}
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Folder Id</FormLabel>
                  <Controller
                    name="folderId"
                    control={control}
                    rules={{ required: "Please select a folderId" }}
                    render={({ field }) => (
                      <Autocomplete
                        options={folderList || []}
                        getOptionLabel={(option) => `${option.name}`}
                        onChange={(event, value) =>
                          field.onChange(value ? value.id : null)
                        }
                        value={
                          folderList.find(
                            (folder) => folder.id === field.value
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Folder"
                            error={!!errors.folderId}
                            helperText={
                              errors.folderId ? errors.folderId.message : ""
                            }
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack sx={{ flexDirection: "row-reverse", marginTop: "1rem" }}>
                <Button type="submit">Submit</Button>
              </Stack>
            </form>
          </div>
        </div>
      </ModalDialog>
    </Modal>
  );
}

export default AudioAssign;
