import { Card } from "react-bootstrap"
import { useEffect, useRef, useState } from "react";
import "../Pages.css"
import play from "../../assets/images/play.png"
import pause from "../../assets/images/pause.png"
import { useHistory } from "react-router-dom";
import Loading from "../../Components/Commons/Loading";

const RedirectToTranscript = (props)=>{

    const audioInput = useRef();
    const history = useHistory()
    const [loader,setLoader] = useState(true)
    const [historyObject,setHistoryObject] = useState()

    const str_pad_left = (string,pad,length) => {
        return (new Array(length+1).join(pad)+string).slice(-length);
    }

    const timeUpdate = (event) => {
        const minutes = Math.floor(event.target.currentTime / 60);
        const seconds = Math.floor(event.target.currentTime - minutes * 60);
        const currentTime = str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);
        props.callBack(currentTime)
    }

    useEffect(()=>{
        setHistoryObject(history.location.state)
    },[])

    
    setTimeout(() => {
        setLoader(false)
    }, 2000);

    return(
        <>
            {loader?
            <div className="loader-container">
                <Loading variant="dark"/>
            </div>:
                <div className="seller-agent-trans">
                <h3>{`FileName: ${historyObject?.fileName}`}</h3>
                    <audio
                        id='audio-element'
                        controls
                        className="call-detail-audio-panal"
                        controlsList="nodownload"
                        autoPlay
                        onTimeUpdate={timeUpdate}
                        ref={audioInput}
                    >
                        <source
                        src={props?.recUrl}
                        type="audio/wav"
                        ></source>
                        <source
                        src={props?.recUrl}
                        type="audio/wav"
                        ></source>
                        Your browser does not support the audio element.
                    </audio>
                {historyObject?.trans?.map(item=>{
                    return(
                    <div className="message-container-trans">
                        <div className="message-box">
                            <div style={{textAlign:`${item.speaker==1?"":"right"}`}}>
                                <button className="button-play" style={{borderRadius:"14px",border:"none"}}>
                                    {/* <span>
                                        <i className="bi bi-play"></i>
                                    </span> */}
                                    <img src={play} style={{height:"15px",width:"16px"}}></img>
                                </button>
                                <button className="button-pause" style={{borderRadius:"14px",border:"none"}}>
                                    <img src={pause} style={{"height":"15px","width":"12px"}}></img>
                                </button>
                            </div>
                            <p className={`redirect-trans ${item.speaker==1?"":'separated-class'}`}>{item.trans}</p>
                        </div>
                    </div>)
                })}
                {/* <AudioPlayer recUrl={historyObject?.url} startTime={startTime} callTranscript={callTranscript} key={key} talkDuration={talkDuration} callTime={callTime}/> */}
            </div>}
        </>
    )
}

export default RedirectToTranscript