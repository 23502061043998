import React, { useState } from 'react'
import Loading from '../../../Commons/Loading'
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap'
import MeetingInnerTrans from './MeetingInnerTrans'

function MeetingInnerTranscript(props) {
  const [keyClicked, setKeyClicked] = useState("all");

  const handleSelect = (keyClicked) => {
    setKeyClicked(keyClicked)
  }

  return (
    <>
      {props?.loader ?
        <div className="loader-container" >
          <Loading />
        </div> :
        <div className="tab-content py-3">
          <div className="tab-pane fade active show" role="tabpanel" id="primarytranscript">
            <div className="d-flex">
              <div className="moment-filter-tab-box">
                <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked ? keyClicked : "all"}>
                  <Row>
                    <Col sm={10}>
                      <Nav variant="pills" className="flex" onSelect={handleSelect}>
                        <Nav.Item>
                          {props?.callTranscript?.sequence?.length > 0 ? <Nav.Link eventKey="all">All</Nav.Link> : ""}
                        </Nav.Item>
                        {Object.entries(props.participants).map(([key, value]) => (
                          <Nav.Item key={key}>
                            <Nav.Link eventKey={key}>{value}</Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col sm={12} className="px-0">
                      <Tab.Content>
                        {keyClicked  ?
                          <Tab.Pane eventKey={keyClicked}>
                            <MeetingInnerTrans
                              callTranscript={props.callTranscript}
                              keyClicked={keyClicked ? keyClicked : ""}
                              participants={props.participants}
                              meetingId={props.meetingDetails.meetingId}
                              userExternalId={props.meetingDetails.userExternalId}
                              tabSelected="transcript"
                              transcript={null}
                              postId={null}
                            />
                          </Tab.Pane>
                          : ""}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default MeetingInnerTranscript