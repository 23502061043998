import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import clientService from "../../Services/client.service"
import {addClient} from "../../../redux-container/settings/company_settings/action"
import "./Setting.css";
import { Dropdown } from "react-bootstrap";
import Loading from "../../Commons/Loading";
import {SIGN_IN} from "../../../redux-container/login/types"
import toast from "../../Toast/toaster"

function General(props) {
    const [inputs, setInputs] = useState({
        name: '',
        externalId: '',
        logoUrl: '',
        clientId: ''
    });
    const [formError, setFormErrors] = useState({}); 
    const [color, setColor] = useState("red");
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [updated , setUpdated] = useState({});
    const [disabled, setDisabled] = useState(true)

    const [keyName, setKeyName] = useState("")

    const errors = {};

    let val = {
        folderName:"",
        keyName:inputs.logoUrl?inputs.logoUrl:""
    }
    function handleChange(e) {
            if(e.target.name === 'logoUrl') {
                const ev = e.target.files;
                if (ev) {
                  if (ev.length === 0) {
                    return;
                  }
                }
                var img;
                img = document.createElement("img");
                var loadImg = true;
                img.onload = function () {
                    if(img.height <='100px' && img.width <='100px')
                        {
                            loadImg = false
                            toast.error('Logo should be square image with dimensions less than or equal to 100*100')
                        }   
                   }
        
               img.src = URL.createObjectURL(e.target.files[0]);

               setTimeout(() =>{
               if(loadImg == true){
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                    clientService.uploadClientLogo(formData).then((res) =>{
                        setInputs(inputs => ({ ...inputs, logoUrl: res }));
                        setKeyName(res)
                        setUpdated(updatedContact => ({...updatedContact, logoUrl: res}))
                    })     
                }
            }, 5000)
            }
          else{
         const { name, value } = e.target;
         setInputs(inputs => ({ ...inputs, [name]: value }));
         setUpdated(updatedContact => ({...updatedContact, [name]: value}))
         }
         if(e.target.value != ''){
            setDisabled(false);
         }

         else
            setDisabled(true);  
    }


    const validate = (values) => {
        if (!values.name) {
            setColor("red")
            errors.name = "Client Name is required."
        }
        // if (!values.clientLogo) {
        //     setColor("red")
        //     errors.clientLogo = "Company Logo is required."
        // }

        return errors;
    }

    useEffect(()=>{
        setInputs(inputs => ({ ...inputs, name : props.clientDetails.name, externalId: props.clientDetails.externalId, 
                         clientId: props.clientDetails.clientId}));
        if(props.clientDetails.logoUrl)
            setInputs(inputs => ({ ...inputs, logoUrl : props.clientDetails.logoUrl}));
        setUpdated(updated => ({ ...updated, clientId: props.clientDetails.clientId}));
    },[props.clientDetails])
    
    useEffect(()=>{
        if(keyName!==''){
            clientService.getLogo({"folderName":"","keyName":keyName}).then((res)=>{
                console.log(res)
            })
        }
    },[keyName])
    

    function upload() {
        document.getElementById("inputLogo").click();
      }

    function remove(e) {
        clientService.removeLogo(inputs.logoUrl).then(res =>{
            setInputs(inputs => ({ ...inputs, logoUrl: '' }));
            setUpdated(updated => ({ ...updated, logoUrl: ''}));
        })
        if(e.type == 'click'){
           setDisabled(false);
        }
        else
           setDisabled(true);
      }

    function handleSubmit(e) {
        e.preventDefault();
        validate(inputs)
        setMessage('') 
        setFormErrors(errors)
        setLoader(true)
        if(Object.keys(updated).length >1 && Object.keys(errors).length == 0) {
           clientService.updateClient(updated)
           .then(res=>{
               if(res)
               {
                   setLoader(false);
                   var details = localStorage.getItem('USER_DETAIL')
                   var userDetails = JSON.parse(details);
                   userDetails.clientLogo = inputs.logoUrl;
                   userDetails.clientName = inputs.name
                   localStorage.setItem('USER_DETAIL', JSON.stringify(userDetails));
                   const data ={
                       clientName: inputs.name,
                       clientLogo: inputs.logoUrl
                   }
                   props.onUpdateDetails(data)
               }
             }).catch(error =>{
                setLoader(false)
             })
        }
        else
            setLoader(false)
    }

    return (
        <>
           {   props.loading ? <div className="loader-container"><Loading variant="dark"/></div>:
               <div id="primaryGeneral" className="mt-2">
                <div className="view-team-card-details">
                    <form className="row g-3 mt-0">
                        <div className="d-flex align-items-center">
                            <div className="genral-settings">                             
                                {/* <label htmlFor="inputLogo" className="">Logo</label> */}
                                <Dropdown className="clintLogo-dropdown">
                                    <Dropdown.Toggle variant="" className="px-0 py-1 dropdown-toggle dropdown-toggle-nocaret"
                                        href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <Dropdown.Menu>
                                            {inputs.logoUrl?<li key='remove'>
                                                <a href="#" className="dropdown-item" onClick={remove} name='logoUrl'>
                                                    Remove Logo
                                                </a>
                                            </li>:''}
                                            <li key='upload'>
                                                <a href="#" className="dropdown-item" onClick={upload} name='logoUrl'>
                                                    Upload Logo
                                                </a>
                                            </li>
                                        </Dropdown.Menu>
                                        <div className="logo-avatat"> 
                                            <img src={inputs?.logoUrl != ''?
                                                `https://app.odioiq.com/odio/api/client/image?keyName=${inputs.logoUrl}`
                                                : require('../../../assets/images/clint_logo/clintLogoDefault.png')} class="rounded-circle"  width="110" />
                                        </div>
                                    </Dropdown.Toggle>
                                </Dropdown>
                                
                                {/* <a className="logo-avatat"><img src="" /></a> */}
                                <input type="file" className="clint-logoInput" id="inputLogo" name='logoUrl' accept="image/png, image/jpeg" onChange={handleChange}/>
                                <p style={{ color: color }}>{formError.clientLogo}</p>
                            </div>
                            <div className="w-100">
                                <label htmlFor="inputCompanyName" className="form-label">Company Name* </label>
                                <div className="input-group logo-input"> <span className="input-group-text bg-transparent py-0"><i className="fadeIn animated bx bx-buildings" /></span>
                                    <input type="text" className="form-control" name="name" id="inputCompanyName" value={inputs.name} onChange={handleChange}  placeholder="Company Name" />
                                </div>
                                <p style={{ color: color }}>{formError.name}</p>
                            </div>
                            {/* <div className="col-md-6 mt-3"> */}
                                {/* <label htmlFor="inputExId" className="form-label">External Id</label>
                                <div class="input-group">
                                    <input type="text" className="form-control" id="inputExId" placeholder="External Id" name="externalId" value={externalId} onChange={handleChange}/>
                                </div> */}
                                {/* <p class="my-1 mb-0 lh-1 font-12" style={{height:'18px', color: color}}> <span>{formError.externalId}</span></p> */}
                            {/* </div> */}
                        </div>
                        <div className="col-12 text-sm-end mt-4">
                            <button type="submit" className="btn px-5 update-btn lh-base" disabled={disabled?disabled:''} id="update-COM-profile" onClick={handleSubmit}>{loader ? <Loading variant="light"/> : <> Update</>}</button>
                            <p style={{ color: color }}>{message}</p>
                        </div>
                    </form>
                 </div>
            </div>    
            }
        </>
    );
}

const mapDispatchToProps = (dispatch) =>{
    return{
        addClient: (data) => {dispatch(addClient(data))},
        onUpdateDetails: (inputs) => dispatch({type:SIGN_IN, payload: inputs})
    }
}


export default connect(null, mapDispatchToProps)(General);