import { apiCall } from "./Interceptor";

export const getMeetingListing = async (val) => {
    const GET_MEETING_LISTING = '/odio/api/meeting/list'
    const response = await apiCall.post(GET_MEETING_LISTING, val)
    return response
}

export const getMeetingDetail = async (val) => {
    const GET_MEETING_DETAIL = `/odio/api/meeting/view-details?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_DETAIL)
    return response
}

export const getMeetingStats = async (val) => {
    const GET_MEETING_STATS = `/odio/api/meeting/stats?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_STATS)
    return response
}

export const getMeetingSpeakerTracks = async (val) => {
    const GET_MEETING_SPEAKER_TRACKS = `/odio/api/meeting/speakerTracks?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_SPEAKER_TRACKS)
    return response
}

export const adhocMeeting = async (val) => {
    const ADHOC_MEETING_LINK = '/odio/api/meeting/adhoc'
    const response = await apiCall.post(ADHOC_MEETING_LINK, val)
    return response
}

export const getMeetingMoments = async (val) => {
    const GET_MEETING_MOMENTS = `/odio/api/meeting/moments?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_MOMENTS)
    return response
}

export const getMeetingSignals = async (val) => {
    const GET_MEETING_SIGNALS = `/odio/api/meeting/signal?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_SIGNALS)
    return response
}

export const getMeetingQuestions = async (val) => {
    const GET_MEETING_QUESTIONS = `/odio/api/meeting/question?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_QUESTIONS)
    return response
}

export const getMeetingFoulLanguage = async (val) => {
    const GET_MEETING_FOULLANGUAGE = `/odio/api/meeting/foul?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_FOULLANGUAGE)
    return response
}

export const getMeetingTime = async (val) => {
    const GET_MEETING_TIME = `/odio/api/meeting/time?meetingId=${val}`
    const response = await apiCall.post(GET_MEETING_TIME)
    return response
}

export const createMeetingDetailComments = async (val) => {
    const CREATE_COMMENT = '/odio/api/comment/create'
    const response = await apiCall.post(CREATE_COMMENT, val)
    return response
}

export const getMeetingDetailComments = async (callId, type, id) => {
    const GET_COMMENT = `/odio/api/comment/get-comment?callId=${callId}&commentType=${type}&postId=${id}`
    const response = await apiCall.get(GET_COMMENT)
    return response
}

export const addLikeDislike = async (val) => {
    const response = await apiCall.post("/odio/api/comment/Likes/dislikes", val)
    return response
}
  
export const getLikeDislike = async (id, type) => {
    const response = await apiCall.get(`/odio/api/comment/get-likeAndDislike?callId=${id}&commentType=${type}`)
    return response
}