import { Card, Col, Row } from "react-bootstrap";
import React,{ useState, useEffect } from "react";
import Loading from "../Loading";
import AnyChart from "anychart-react"
import anychart from "anychart"
import callsService from "../../Services/calls.service";
import NodataFound from "../NoDataFound";

export default function AgentWordCloud(props) {
  
  const [wordCloudData, setWordCloudData] = useState([]) 
  const [loader,setLoader] = useState()

  const storedData = JSON.parse(localStorage.getItem("AGENT"))

// for filtered data values is used
  let values={
    "coeIds":props?.filteredData?.coeId?props?.filteredData?.coeId:props?.defaultFilterData?.coeIds?props?.defaultFilterData?.coeIds:[props?.agentData?.coe[0]?.id],
    "fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    "toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,
    "userId":props?.userId,
    // "userExternalId": props?.userId
  }

  const getWordCloud = ()=>{
    setLoader(true)
    // if(props?.userId.length>4){
    //   delete values.userId
    // }
    // else{
    //   delete values.userExternalId
    // }
    callsService.getTeamWordCloud(values).then(res=>{
        if(res){
            setWordCloudData(res.data.data)
            setLoader(false)
        }
    })
  }

// for default filtered data valuesDef is used
  let valuesDef = {
    "coeIds":props.defaultFilterData?.coeIds?props.defaultFilterData?.coeIds:[props?.agentData?.coe[0][0]?.id],
    "fromD":storedData&&storedData.fromD?storedData.fromD:JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    "toD":storedData&&storedData.toD?storedData.toD:JSON.parse(localStorage.getItem("AGENT"))?.toD,
    "userId":props?.userId,
    // "userExternalId":props?.userId
  }

  const getDefWordCloud = ()=>{
    setLoader(true)
    // if(props?.userId.length>4){
    //   delete valuesDef.userId
    // }
    // else{
    //   delete valuesDef.userExternalId
    // }
    if(valuesDef?.coeIds[0]){
      callsService.getTeamWordCloud(valuesDef).then(res=>{
        if(res){
            setWordCloudData(res.data.data)
            setLoader(false)
        }
    })
    }
  }

  useEffect( () => { 
    if(props?.keySelected=='wordCloud' && !props.filteredData)
      getDefWordCloud()
  }, [props.defaultFilterData]);

  useEffect( () => { 
    if(props?.keySelected=='wordCloud' && props.filteredData){
        getWordCloud()
    } 
  }, [props?.keySelected=='wordCloud',props.filteredData]);
  
  const options = {
    rotations: 2,
    rotationAngles: [-45, 0],
  };
 
 const words = wordCloudData?.map(m => {return {x : m.agentWordCloud, value:m.result}});

  return (
    <>
        {loader ? (
            <div className="loader-container">
                <Loading variant="dark" />
            </div>
            ) : wordCloudData?.length > 0 ? 
      <Col xs='150px'>
        {/* <Card className="radius-10 w-100 call-duration-chart-box">
          <Card.Body> */}
            <div>
              <AnyChart 
                height={650} 
                type="tagCloud" 
                data={words}
              />
            </div>
          {/* </Card.Body>
        </Card> */}
      </Col> : <NodataFound />
    }
    </>
  );
}