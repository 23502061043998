import React, {useEffect,useState,useRef, useLayoutEffect, useMemo} from 'react'
import { useHistory } from "react-router-dom";
import CommentOnModal from '../Sales/Modals/CommentOnModal'
import EmailTranscript from '../Sales/Modals/EmailTranscript'
import callsService from '../../Services/calls.service'
import notificationsService from '../../Services/notifications.service'
import toaster from '../../Toast/toaster'
import { Accordion, Card } from 'react-bootstrap';
import Dump from './Dump';
import EmailContent from './EmailContent';

export default function All_Email_Transcript(props) {
  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [mailButton, setMailButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false) 
  const [transId,setTransId] = useState("")
  const [trans,setTrans] = useState("")
  const [speaker,setSpeaker] = useState("")
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [commentData, setCommentData] = useState({})
  const [likeDislike, setLikeDislike] = useState([])
  const [accordions, setAccordions] = useState([]);
  
  const [loader,setLoader] = useState(false)
  const [toggle,setToggle] = useState(false)

  const highlightedElementRef = useRef(null);
  const history = useHistory()
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const type = urlParams.get("emailId")
  const typeOfCall = urlParams.get("name");
  // const [historyObject,setHistoryObject] = useState()
  let tranc_index = 0 ;
  const likeButtonHandler = (id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "TRANSCRIPT",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"TRANSCRIPT").then(res=>{
          if(res){
            setLikeDislike(res?.data?.data)
            setLikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"LIKE",
				"tabName":"transcript",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res?.data?.message)
      }
    })
  }

  const dislikeButtonHandler = (id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "TRANSCRIPT",
      "dislike": 0,
      "like": null,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"TRANSCRIPT").then(res=>{
          if(res){
            setLikeDislike(res?.data?.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"transcript",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res?.data?.message)
      }
    })
  }
  const commentButtonHandler = (id,trans,speaker)=>{
    setToggle(true)
    // setCommentButton(true)
    setCommentShow(!commentShow)
    setTransId(id)
    setTrans(trans)
    setSpeaker(speaker)
  }
  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }
  const mailHandler = ()=>{
    setMailButton(true)
    setEmailShow(true)
  }

  const closeButton = ()=>{
    setCommentShow(false)
    setEmailShow(false)
  }
  
  const callback = (e)=>{ 
    setCommentData(e)
  }

  useEffect(()=>{
    if(props?.eventType=='COMMENT'&&props?.tabRedirected=='transcript'){
      setCommentShow(true)
      setTransId(props?.postId)
      setTrans(history.location.state.tabValue)
    }
  },[])

 useEffect(()=>{

   if (highlightedElementRef.current) {
    highlightedElementRef.current.scrollIntoView({ behavior: 'smooth'});
  }
 },[])

  const repeatedCode = (subject,speaker,trans,time,index,signal,data)=>{
    // let transNew = Object.entries(Object.values(commentData).length>0?commentData?.postTypeComments:props?.callTranscript?.postTypeComments).filter(val=>{  
    //   return(val[1][0].postId==index) 
    // })
    let transNew = [];

// Check if commentData is defined, has postTypeComments, and is not an empty array
if (commentData && commentData.postTypeComments && Object.values(commentData.postTypeComments).length > 0) {
  transNew = Object.entries(commentData.postTypeComments)
    .filter(([key, value]) => value[0]?.postId === index); // Assuming `index` is defined elsewhere
} 
// If commentData conditions are not met, check props and callTranscript
else if (props && props.callTranscript && props.callTranscript.postTypeComments && Object.values(props.callTranscript.postTypeComments).length > 0) {
  transNew = Object.entries(props.callTranscript.postTypeComments)
    .filter(([key, value]) => value[0]?.postId === index); // Assuming `index` is defined elsewhere
}

// transNew now contains filtered entries based on postId matching index

    let ids = transNew.length>0?transNew[0][1]:''
    let likeIcon = likeDislike.length>0?likeDislike.filter(val=>{
      return(val.postId==index)
    }):
    props?.likeDislikeData?.filter(val=>{
      return(val.postId==index)
    })

    const getRandomColor = () => {
      const colors = ['#29cc39', '#ffc107', '#0dcaf0','#fe5d9f'];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    };

    const itemRef = props?.postId==index || props?.tabRedirected=='transcript'?{ref:highlightedElementRef}:{}
    
    return(
      <>
          <a ref={itemRef} className={`
            ${props?.postId === index && props?.tabRedirected === 'transcript' ? 'shadow list-group-item-action-trans' : ''}
            ${typeOfCall === 'SINGLE_CALL' ? 'list-group-item list-group-item-action' : 
              (data === 'customer' && props.type === 'email' && typeOfCall !== 'SINGLE_CALL' ? 'list-group-item list-group-item-action group-details mr-2' : 'list-group-item text-dark')}
          `}>
           <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
            <i class="bx bx-user-voice me-1"></i>
            {speaker=='seller'?props?.callTranscript?.sellerName:props?.callTranscript?.customerName}
            <span class="badge rounded-pill bg-light-blue text-dark f-normal">{speaker}</span>
            </h5>
            
            {props?.type =='email' ? <small class="text-muted">
            <i class="bx bx-time"></i>
            {time}
            </small> : '' }
          </div>
          {/* <p class={`${tranc_index===index?'fw-bold':''} mb-1`}> */}
          <p class="mb-1 font-15">{subject}</p>
          <p class="mb-1">
            {trans}
            {props?.type=='chat'?'': !type ?  
            <div style={{display:'flex',justifyContent:"space-between",gap:'60px'}}>
              <div>{signal?.length > 0 ? signal?.map((sig)=><span class='badge rounded-pill text-dark f-normal' style={{marginTop:'4px',marginRight:'4px',paddingTop:'5px',fontSize:'0.75rem',backgroundColor:getRandomColor()}}>{sig}</span>):<div></div>}</div>
            </div> : ''}
          </p>
         
          {speaker === "seller" && typeOfCall ?   
          <>
          <div class="row">
            <div class="col-6">
                <div class="list-after-click-group">
                  {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==index||likeButton&&likeId==index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                  likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==index||dislikeButton&&dislikeId==index?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                  {/* {mailButton?<button class="btn compose-mail-btn" onClick={mailHandler} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats"><span class="text-primary-blue"><i class="bx bx-mail-send"></i></span></button>:''} */}
                  {ids&&ids[0].postId==index||commentButton&&transId==index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(index,trans,speaker)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
                </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==index||likeButton&&likeId==index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(index,trans)}>
                    <span className="text-primary-blue">
                    <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==index||dislikeButton&&dislikeId==index? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(index,trans)}>
                <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                </span>
                </button>}
                {/* {mailButton?'':
                <button className="btn compose-mail-btn" onClick={mailHandler} 
                // onClick={() => mailHandler(id)} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                </span>
                </button>
                } */}
                {ids&&ids[0].postId==index||commentButton&&transId==index?'':  
                <button className="btn list-comment-before switcher-btn-1" onClick={()=>commentButtonHandler(index,trans,speaker)}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                </span>
                </button>
                }  
              </div>
            </div>
          </div>
        </> : "" }  
        
        </a>
        
      </>
    )
  }

  function scrolldiv(val) {
    if(val){
      let dd = document.getElementById("all_trans")
      if(val===0){
        dd.scrollTop()
      }
      let position = findPosition(document.getElementById(val));
      if(position){
        position[0] = position[0]- 600
        dd?.scrollTo(0, position);
      }
      
    }
  }

  function findPosition(obj) {
      var currenttop = 0;
      if (obj?.offsetParent) {
          do {
              currenttop += obj.offsetTop;
          } while ((obj = obj.offsetParent));
          return [currenttop];
      }
  }


  useEffect(()=>{

    let filter = props?.callTranscript?.sequence?.filter(item=>{
      return item.time===`00:${props.time}`
    })

    if(filter && filter[0]?.index>tranc_index){
      scrolldiv(filter[0].index)
      tranc_index = filter[0].index
    }
  },[props.time])

    // Memoize the splitData function to avoid recalculating unnecessarily
    const splitData = useMemo(() => {
      const split = (data) => {
        const result = [];
        let temp = [];
        let recording = false; // To track when we are in a group of messages to be recorded
    
        data.forEach(item => {
          if (item.speaker === "seller") {
            // When the speaker is "seller", finalize the previous group if it exists
            if (temp.length > 0) {
              result.push(temp);
              temp = [];
            }
            // Start a new group
            temp.push(item);
            recording = true;
          } else if (recording) {
            // If recording is true, we add messages from other speakers
            temp.push(item);
          }
        });
        // Add the last collected group if any
        if (temp.length) result.push(temp);
        return result;
      };
  
      return split;
    }, []);

  useEffect(() => {
    if (props?.callTranscript?.sequence) {
      const newAccordions = splitData(props.callTranscript.sequence);
      setAccordions(newAccordions);
    }
  }, [props?.callTranscript?.sequence, splitData]);

  const renderContent = (content, subject) => {
    // Check if the content looks like an HTML (e.g., contains < and > tags)
    const isHtml = content.includes("<") && content.includes(">");
    const isEmailContent = content.includes("\r\n");

    if (isHtml && !isEmailContent) {
      return <Dump callTranscript={props?.callTranscript ? props?.callTranscript : ''} />;
    } else  {
      return <EmailContent subject={subject} trans={content} />;
    }
  }

  return ( <>
    {(typeOfCall !== "SINGLE_CALL" || props?.keyClicked == "mail") ? 
   <Accordion>
   {accordions?.map((group, idx) => (
     <Card key={idx} className='w-100'>
       <Accordion.Item eventKey={idx.toString()}>
         <Accordion.Header eventKey={idx.toString()} >
           <div className='list-group moment-list-group-scroll-height mb-2 w-100'>
             {group?.[0]?.subject}
           </div>
         </Accordion.Header>
         <Accordion.Collapse eventKey={idx.toString()}>
           <Card.Body className='mx-1 d-flex flex-column align-items-center'>
             {/* {group.map(item => (
               <React.Fragment key={item?.index}>
                 {props?.keyClicked === 'all' || props?.keyClicked === 'mail' ? (
                   item?.speaker === "seller" ? null : repeatedCode(item?.subject,item?.speaker, item?.trans, item?.datetime, item?.index, item?.signal, "customer")
                 ) : null}
               </React.Fragment>
             ))} */}
              <div className='list-group moment-list-group-scroll moment-list-group-scroll-height mb-2 w-100'>
                {renderContent(group?.[0]?.dump_trans, group?.[0]?.subject)}
              </div>
           </Card.Body>
         </Accordion.Collapse>
       </Accordion.Item>
     </Card>
   ))}
 </Accordion>
  :
    <div class="list-group moment-list-group-scroll mb-2" id="all_trans" >
      {props?.callTranscript ? props?.callTranscript?.sequence?.map((item, idx)=>{
        return(
        <React.Fragment key={item?.index}>
        {props?.keyClicked=='seller'&&item?.speaker=='seller'?
        repeatedCode(item?.subject,item?.speaker,item?.trans,item?.datetime,item?.index,item?.signal):
        props?.keyClicked=='customer'&&item?.speaker=='customer'?
        repeatedCode(item?.subject,item?.speaker,item?.trans,item?.datetime,item?.index,item?.signal):
        props?.keyClicked=='all'?
        repeatedCode(item?.subject,item?.speaker,item?.trans,item?.datetime,item?.index,item?.signal):
        ""}
        </React.Fragment>)
      }):""}
      {commentShow && transId!=='' &&<CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props.tabRedirected=='transcript'?props?.tabRedirected:props?.tabSelected} callId={props?.callId} transId={transId} trans={trans} transSpeaker={speaker} callT={props.callT} callback={callback} phone={props?.phone} agentExternalId={props.agentExternalId}/>}
      <EmailTranscript onClick={scrolldiv()} show={emailShow} closeButton={closeButton} callTranscript={props.callTranscript?props.callTranscript:""} tabSelected={props.tabSelected} callId={props?.callId} agentId={props?.agentId}/>
    </div>
     } 
    </>
  );
}