import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import "./knowledgeBase.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Textarea from "@mui/joy/Textarea";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import toaster from "../../Toast/toaster";
import { createDocuments, uploadImage, updateDocument } from "../../Services/knowledgeBase.service";
import { useDispatch, useSelector } from "react-redux";
import { setDocEditData } from "../../../redux-container/knowledgeBase/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import _ from "lodash";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BasicDetailsDocument = ({ setTabValue, setTabSelect, isEditCase, actionType, getDocumentDetalisEditCase,isDraft,draftDocId }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
      moments: [],
      image: null,
      status: "DRAFT",
    },
  });
  const history = useHistory();
  const folderId = history.location.state?.data?.folderId || history.location.state?.folderData?.folderId;
  const [imageName, setImageName] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const initialEditCaseData = useSelector((state) => state.knowledgeBase.editDocData);
  const moments = useSelector((state) => state.knowledgeBase?.momentData);
  const documentType = useSelector((state) => state.knowledgeBase?.documentType);
  const initialFormValues = {
    title: initialEditCaseData?.title || "",
    description: initialEditCaseData?.description || "",
    moments: initialEditCaseData?.momentIds || [],
    image: { url: initialEditCaseData?.featureImage || null },
    status: initialEditCaseData?.status || "DRAFT",
  };
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const currentFormValues = watch(); // Watch all form values
  // Function to normalize form values
  const normalizeFormValues = (values) => ({
    ...values,
    moments: values?.moments?.map((moment) => moment?.momentId || moment) || [],
    image: values?.image?.url || null,
  });
  const normalizedInitialValues = useMemo(() => normalizeFormValues(initialFormValues), [initialFormValues]);
  const normalizedCurrentValues = useMemo(() => normalizeFormValues(currentFormValues), [currentFormValues]);

  useEffect(() => {
    const hasChanged = !_.isEqual(normalizedCurrentValues, normalizedInitialValues);
    setIsFormDirty(hasChanged);
  }, [normalizedCurrentValues, normalizedInitialValues]);

  const uploadedImage = watch("image");

  const uploadFeatureImage = (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData)
      .then((response) => {
        if (response?.data?.status === 0) {
          const uploadedImageUrl = response?.data?.data?.signedUrl;
          setImagePreview(uploadedImageUrl);
          setValue("image", response?.data?.data);
          setIsUploadDisabled(true);
          toaster.success("Image uploaded successfully.");
          clearErrors("image");
        } else {
          toaster.error("Sorry, their is some error.");
        }
      })
      .catch((err) => {
        console.log("Api call error: ", err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file.size <= 10 * 1024 * 1024 && (file.type === "image/jpeg" || file.type === "image/png")) {
      uploadFeatureImage(file);
      setImageName(file.name);
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setImageName(null);
      setImagePreview(null);
      toaster.error(
        file.type !== "image/jpeg" && file.type !== "image/png"
          ? "File should be of image type."
          : file.size > 10 * 1024 * 1024
          ? "Image should be of less than 10 MB."
          : "Error in image."
      );
    }
  };

  const removeImage = () => {
    setImageName(null);
    setImagePreview(null);
    setValue("image", null);
    setIsUploadDisabled(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const createDocument = async (data) => {
    let reqBody = {
      title: data?.title,
      description: data?.description,
      featureImage: data?.image?.url && data?.image?.url,
      momentIds: data?.moments?.map((moment) => moment.momentId),
      status: data?.status,
      tag: "",
    };
    if (Object.keys(initialEditCaseData)?.length > 0) {
      reqBody = { ...reqBody, id:isDraft ?draftDocId:initialEditCaseData?.id };
    }
    try {
      const response = await createDocuments(reqBody, folderId);
      if (response.data.status === 0) {
        toaster.success(response.data?.message);
        if (actionType !== "edit") {
          dispatch(setDocEditData(response?.data?.data));
        } else {
          getDocumentDetalisEditCase();
        }
        setTabValue("2");
        setTabSelect("templates");
      } else {
        toaster.error(response?.data?.error);
      }
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const onSubmit = (data) => {
    // if (!data.image) {
    //   setError('image', { type: 'manual', message: 'Please upload an image.' });
    //   return;
    // }
    createDocument(data);
  };

  useEffect(() => {
    if (Object.keys(initialEditCaseData)?.length > 0) {
      setValue("title", initialEditCaseData?.title || "", {
        shouldValidate: true,
      });
      setValue("description", initialEditCaseData?.description || "", {
        shouldValidate: true,
      });
      setValue("status", initialEditCaseData?.status || "DRAFT", {
        shouldValidate: true,
      });
      setValue("image", { url: initialEditCaseData?.featureImage }, { shouldValidate: true });
      setImagePreview(initialEditCaseData?.signedFeatureImage || initialEditCaseData?.featureSignedImage);
      setImageName(initialEditCaseData?.featureImage?.split("/").pop());
      setIsUploadDisabled(!!initialEditCaseData?.featureImage);
      const editedMoment = initialEditCaseData?.momentIds?.map((moment) => moment?.momentId || moment);
      // Pre-select moments if available in edit case
      const selectedMoments = moments.filter((moment) => editedMoment?.includes(moment?.momentId));
      setValue("moments", selectedMoments, { shouldValidate: true });
    }
  }, [initialEditCaseData]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ Width: "100%" }}>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Please provide a title" }}
            disabled={actionType == "view"}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  placeholder="Add Title"
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : ""}
                  fullWidth
                />
              );
            }}
          />
        </Box>

        <div className="document-folder-upload">
          <div className="uploading-document-folder">
            <Button
              component="label"
              variant="contained"
              disabled={isUploadDisabled || actionType == "view"}
              startIcon={<CloudUploadIcon />}
              error={!!errors.image}
            >
              Upload Image
              <VisuallyHiddenInput type="file" onChange={handleImageChange} accept="image/*" ref={fileInputRef} />
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label>Status</label>
              <div>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        {...field}
                        onClick={() => field.onChange("DRAFT")}
                        sx={{
                          px: 5,
                          mr: 2,
                          backgroundColor: field.value === "DRAFT" ? "#1976d2" : "grey",
                          cursor: actionType === "edit" ? "pointer" : "not-allowed",
                        }}
                      >
                        Draft
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => actionType === "edit" && field.onChange("PUBLISH")}
                        sx={{
                          px: 5,
                          backgroundColor: field.value === "PUBLISH" ? "#1976d2" : "grey",
                          cursor: actionType === "edit" ? "pointer" : "not-allowed",
                        }}
                        disabled={actionType !== "edit"} // Disable if actionType is not 'edit'
                      >
                        Published
                      </Button>
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              {isUploading && <LinearProgress />}

              {imagePreview && !isUploading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    border: "1px dashed",
                    padding: "10px",
                    backgroundColor: "#E5E4E2",
                    marginTop: "10px",
                  }}
                >
                  <img src={imagePreview} alt="Preview" style={{ width: "90px", height: "90px" }} />
                  <p
                    style={{
                      marginBottom: "0px",
                      overflowWrap: "break-word",
                      maxWidth: "150px",
                      whiteSpace: "normal",
                    }}
                  >
                    {imageName}
                  </p>
                  <Button onClick={removeImage} sx={{ minWidth: "30px", padding: "5px" }}>
                    <CloseIcon />
                  </Button>
                </div>
              )}
            </div>

            {errors.image && <p style={{ color: "red" }}>{errors.image.message}</p>}
          </div>
        </div>
        <div className="description-document">
          <p>
            <label>Quick Preview Description</label>
          </p>
          <Controller
            name="description"
            control={control}
            // rules={{ required: 'Please provide a description' }}
            render={({ field }) => (
              <Textarea
                {...field}
                minRows={4}
                maxRows={10}
                placeholder="Type your description here..."
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  backgroundColor: "transparent",
                  "&.Mui-focused": {
                    "--Textarea-focusedHighlight": "none",
                  },
                }}
                error={!!errors.description}
                disabled={actionType == "view"}
              />
            )}
          />
          {errors.description && <p style={{ color: "red" }}>{errors.description.message}</p>}
        </div>

        <FormControl sx={{ width: "100%" }}>
          <p style={{ marginTop: "1rem" }}>
            <label>Select Moments</label>
          </p>
          <Controller
            name="moments"
            control={control}
            rules={{
              validate: (value) => value.length > 0 || "Please select at least one tag",
            }}
            render={({ field }) => (
              <Autocomplete
                multiple
                options={moments}
                getOptionLabel={(option) => option.momentName}
                filterSelectedOptions
                disabled={actionType == "view"}
                onChange={(event, value) => field.onChange(value)}
                value={field.value}
                isOptionEqualToValue={(option, value) => option.momentId === value.momentId}
                renderInput={(params) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <TextField
                        {...params}
                        placeholder="Select Moments"
                        sx={{
                          "& .MuiInputBase-root": {
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "8px",
                          },
                          "& input": {
                            minWidth: "100px",
                            flexGrow: 1,
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null,
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: "55px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: 0,
                      }}
                    >
                      <div
                        className="MuiAutocomplete-endAdornment"
                        style={{
                          top: 0,
                          borderTop: "1px solid black",
                          borderRight: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {params.InputProps.endAdornment}
                      </div>
                    </div>
                  </div>
                )}
              />
            )}
          />
          {errors.moments && <p style={{ color: "red" }}>{errors.moments ? errors.moments.message : ""}</p>}
        </FormControl>

        {actionType == "view" ? (
          ""
        ) : (
          <div className="description-document">
            <Button variant="contained" type="submit" disabled={!isValid || !isFormDirty}>
              {Object.entries(initialEditCaseData).length === 0 ? "Save" : "Update"}
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default BasicDetailsDocument;
