import { Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Stats from "./Stats/Stats";
import Moments from "./Moments";
import Signals from "../../Conversations_Dashboard/Sales/Signals/Signals";
import Questions from "../Questions/Questions";
import Actions from "../Actions/Actions";
import Transcript from "../Transcript/Transcript";
import Feedbacks from "../../Conversations_Common/Feedbacks/Feedbacks";
import { Button } from "react-bootstrap";
import MoreInfo from "./Modals/MoreInfo";
import FoulLanguage from "../FoulLanguage/FoulLanguage";
import "./SalesCallDetail.css";
import callsService from "../../Services/calls.service";
import AudioPlayer from "../AudioPlayer";
import { apiCall } from "../../Services/Interceptor";
import clientService from "../../Services/client.service";
import Loading from "../../Commons/Loading";
import { useHistory } from "react-router-dom";
import NodataFound from "../../Commons/NoDataFound";
import { Summary } from "./Summary";
import {Keywords} from "./Keywords";
import { useDataDispatch, useDataState } from "../../Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";
import Pointers from "./Pointers";
import toaster from "../../Toast/toaster";
import MeetingPlayer from "../../Meetings/MeetingDetail/MeetingPlayer";
import { setCallScore } from "../../../redux-container/callList/actions";
import { useDispatch, useSelector } from "react-redux";

export default function SalesCallDetail(props) {
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get("callId");
  const typeOfCall = urlParams.get("name");
  const tabRedirected = urlParams.get("tabSelected");
  const tabValue = urlParams.get("tabValue");
  const notifPostId = urlParams?.get("postId");
  const eventType = urlParams?.get("eventType");
  const commentShow = urlParams?.get("commentShow");
  const [key, setKey] = useState("stats");
  const [show, setShow] = useState(false);
  const onClickHandler = () => {
    setShow(true);
  };
  const [startTime, setStartTime] = useState();
  const [loader, setLoader] = useState(false);
  const [callStats, setCallStats] = useState([]);
  const [callQuestions, setCallQuestions] = useState([]);
  const [foulLanguage, setFoulLanguage] = useState([]);
  const [callMoments, setCallMoments] = useState([]);
  const [callTranscript, setCallTranscript] = useState([]);
  const [callSummary, setCallSummary] = useState([]);
  const [pointerData, setPointerData] = useState([])
  const [keywordsCount, setKeywordsCount] = useState([]);
  const [callFeedback, setCallFeedback] = useState([]);
  const [callAction, setCallAction] = useState([]);
  const [callSignals, setCallSignals] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [time, setTime] = useState("");
  const [callTime, setCallTime] = useState([]);
  const [fileName, setFileName] = useState("");
  const [audioPathUrl, setAudioPathUrl] = useState("");
  const [talkDuration, setTalkDuration] = useState("");
  const [historyObject, setHistoryObject] = useState("");
  const [likeDislikeData, setLikeDislikeData] = useState([]);
  const [flag, setFlag] = useState(true);
  const storedCallScore = useSelector(state=>state?.callList?.callScore)
  const dispatchAction = useDispatch()
  const dispatch = useDataDispatch()
  const wordData = useDataState();

  useEffect(() => {
    const unlisten = history.listen((location) => {

      const currentRoute = location.pathname;
  
      const routesToPreserveGroupIds = ["/sales_call_detail", "/sales_conversations"];
  
      if (!routesToPreserveGroupIds.includes(currentRoute) && wordData.groupIds.length !== 0) { 
        localStorage.setItem("Recursive",false) 
        localStorage.setItem('selectedWordPage', 1)
        dispatch({ type: "CLEAR_GROUP_IDS"});
      }
    });
  
    return () => {
      unlisten();
    };
  }, [dispatch, history]);
  // const recUrl = urlParams.get('url').split("/")
  // let folderName = `${recUrl[3]}/${recUrl[4]}`
  // let url = recUrl[5]
  // const talkDuration = urlParams.get('duration')
  const date = urlParams.get("date");
  const sellerId = urlParams.get("id");
  const coeName = urlParams.get("coe");
  const coeIds = urlParams.get("coeId");
  const seller = urlParams.get("seller");
  const customer = urlParams.get("customer");
  // let audio = window.location.href.split("url")[1].split("duration")[0].replace("&&","")
  let phone = urlParams.get("phone");
  const agentExternalId = historyObject?.agentExternalId ? historyObject?.agentExternalId : callTime?.userExternalId;
  
  const storedData = JSON.parse(localStorage.getItem("AGENT"));
  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig
    ?.data?.conversationListing;
  
  let showSummary = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.summary
  let showPointers = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.pointer
  let showKeywords = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.keywords
  // const [audioData, setAudioData] = useState('')
  // const [isLoading, setIsLoading] = useState(true)

  let handleSwith = (check) => {
    let val = '';
    switch (check) {
      case "stats":
        val = "STATS";
        break;
      case "callMoments":
        val = "MOMENTS";
        break;
      case "signals":
        val = "SIGNALS";
        break;
      case "transcript":
        val = "TRANSCRIPT";
        break;
      case "summary":
        val = "SUMMARY"
        break;
      case "pointers":
        val = "pointers";
        break;
      case "actions":
        val = "ACTIONS";
        break;
      case "questions":
        val = "QUESTIONS";
        break;
      case "foulLanguage":
        val = "FOUL_LANGUAGE";
        break;
      case 'keywords':
        val = "KEYWORDS";
      default:
        val = ''
        break;
    }
    return val;
  }
  function handlePayload() {
    if (tabRedirected && flag) {
      return handleSwith(tabRedirected)
    } else return handleSwith(key)
  }

  // useEffect(()=>{
  //   const getAudio = async (data) => {
  //     try{
  //     const response = await apiCall.get(`odio/api/internal/stream?fileName=${data}`, { responseType: 'arraybuffer' })
  //     const blob = new Blob([response.data], { type: 'audio/mp3' });
  //     const dataUrl = URL.createObjectURL(blob);
  //     setAudioData(dataUrl)
  //     setIsLoading(false);
  //   }catch{
  //     setIsLoading(false);
  //     toaster.error('Unable to fetch the Audio')
  //   }}
  //   if(historyObject?.fileName !== undefined){
  //   getAudio(historyObject?.fileName)
  //   // setApiCalll(false)
  // }},[historyObject?.fileName])
  
  useEffect(() => {
    setLoader(true);
    setHistoryObject(history.location.state);
      if (key == "stats" && !tabRedirected) {
        callsService.getCallDetailStats(params, typeOfCall).then((res) => {
          if (res) {
            setCallStats(res?.data?.data);
            dispatchAction(setCallScore(res?.data?.data?.callScore?.value))
            setLoader(false);
          }
        });
        if (typeOfCall == "SINGLE_CALL") {
          callsService.getCallTime(params).then((response) => {
            if (response) {
              setCallTime(response?.data?.data);
              setFileName(response?.data?.data?.recordingUrl);
              setAudioPathUrl(response?.data?.data?.recordingObjectUrl);
              setTalkDuration(response?.data?.data?.duration);
              setLoader(false);
            }
          });
        }
  
        callsService
          .getLikeDislike(
            params,
            handlePayload()
          )
          .then((res) => {
            if (res) {
              setLikeDislikeData(res.data.data);
            }
            tabRedirected && flag && setFlag(false);
          }).catch(err => {
            tabRedirected && flag && setFlag(false);
          });
      }
  }, [key == "stats"]);

  // useEffect(async () => {
  //   if (tabRedirected == "callMoments") {
  //     setLoader(true);
  //     let response = await apiCall.post(
  //       "/odio/api/call/call-detail/moments",
  //       typeOfCall == "SINGLE_CALL"
  //         ? {
  //           callId: parseInt(params),
  //           fromD: JSON.parse(localStorage.getItem("AGENT"))?.fromD,
  //           toD: JSON.parse(localStorage.getItem("AGENT"))?.toD,
  //           coeIds: JSON.parse(localStorage.getItem("AGENT"))
  //             ?.selectedCoeRange,
  //         }
  //         : {
  //           groupId: params,
  //           fromD: JSON.parse(localStorage.getItem("AGENT"))?.fromD,
  //           toD: JSON.parse(localStorage.getItem("AGENT"))?.toD,
  //           coeIds: JSON.parse(localStorage.getItem("AGENT"))
  //           ?.selectedCoeRange,
  //         }
  //     );
  //     if (response) {
  //       setCallMoments(response.data.data);
  //       setLoader(false);
  //     }
  //   }
  //   if (tabRedirected == "signals") {
  //     setLoader(true);
  //     callsService.getCallDetailSignals(params).then((res) => {
  //       if (res) {
  //         setCallSignals(res.data.data);
  //         setLoader(false);
  //       }
  //     }).catch(()=>{
  //       setLoader(false)
  //     });
  //   }
  //   if (tabRedirected == "questions") {
  //     setLoader(true);
  //     callsService.getCallDetailQuestions(params).then((response) => {
  //       if (response) {
  //         setCallQuestions(response.data.data);
  //         setLoader(false);
  //       }
  //     }).catch(()=>{
  //       setLoader(false)
  //     });
  //   }
  //   if (tabRedirected == "foulLanguage") {
  //     setLoader(true);
  //     callsService.getCallDetailFoul(params).then((response) => {
  //       if (response) {
  //         setFoulLanguage(response.data.data);
  //         setLoader(false);
  //       }
  //     }).catch(()=>{
  //       setLoader(false)
  //     });
  //   }
  //   if (tabRedirected == "transcript") {
  //     setLoader(true);
  //     callsService.getCallDetailTranscript(params).then((response) => {
  //       if (response) {
  //         setCallTranscript(response?.data?.data);
  //         setCustomerName(response?.data?.data?.customerName);
  //         setSellerName(response?.data?.data?.sellerName);
  //         setLoader(false);
  //       }
  //     }).catch(()=>{
  //       setLoader(false)
  //     });
  //   }
  //   if (tabRedirected == "summary") {
  //     setLoader(true);
  //     callsService.getCallDetailSummary(params).then((response) => {
  //       if (response) {
  //         setCallSummary(response.data.data[0].summary.summary)
  //         setLoader(false)
  //       }
  //     }).catch(()=>{
  //       setLoader(false)
  //     })
  //   }
  //   if (tabRedirected == "actions") {
  //     setLoader(true);
  //     callsService
  //       .getCallDetailActions(
  //         params
  //       )
  //       .then((res) => {
  //         if (res) {
  //           setCallAction(res?.data?.data);
  //           setLoader(false);
  //         }
  //       }).catch(()=>{
  //         setLoader(false)
  //       });
  //     callsService.getLikeDislike(params, "ACTIONS").then((res) => {
  //       if (res) {
  //         setLikeDislikeData(res?.data?.data);
  //       }
  //     }).catch(()=>{
  //       setLoader(false)
  //     });
  //   }
  // }, [tabRedirected]);

  useEffect(() => {
    if (tabRedirected !== null && tabRedirected !== undefined) {
      handleSelect(tabRedirected)
    };
  }, [tabRedirected]);


  // useEffect(() => {
  //   const unlisten = history.listen((location) => {

  //     const currentRoute = location.pathname;
  
  //     const routesToPreserveGroupIds = ['/sales_call_detail', '/sales_conversations '];
  
  //     if (!routesToPreserveGroupIds.includes(currentRoute)) { 
  //       localStorage.setItem("Recursive", false) 
  //       localStorage.setItem('selectedWordPage', 1)
  //       dispatch({ type: "CLEAR_GROUP_IDS"});
  //     }
  //   });
  
  //   return () => {
  //     unlisten();
  //   };
  // }, [dispatch, history]);

  const handleMomentData = async (isLoader = true) => {
    isLoader && setLoader(true);
    let response = await apiCall.post(
      "/odio/api/call/call-detail/moments",
      typeOfCall == "SINGLE_CALL"
        ? {
          callId: parseInt(
            historyObject?.callId ? historyObject?.callId : params
          ),
          fromD: JSON.parse(localStorage.getItem("AGENT"))?.fromD,
          toD: JSON.parse(localStorage.getItem("AGENT"))?.toD,
          coeIds: JSON.parse(localStorage.getItem("AGENT"))
              ?.selectedCoeRange,
        }
        : {
          groupId: params
          ,
          fromD: JSON.parse(localStorage.getItem("AGENT"))?.fromD,
          toD: JSON.parse(localStorage.getItem("AGENT"))?.toD,
          coeIds: JSON.parse(localStorage.getItem("AGENT"))
          ?.selectedCoeRange,
        }
    );
    if (response) {
      setCallMoments(response.data.data);
      isLoader && setLoader(false);
    }
    callsService.getLikeDislike(params, "MOMENTS").then((res) => {
      if (res) {
        setLikeDislikeData(res.data.data);
      }
    });
  }

  const handleSelect = async (keyVal) => {
    if (key !== keyVal) {
      setKey(keyVal);
      if (keyVal == "stats" && tabRedirected) {
        callsService.getCallDetailStats(params, typeOfCall).then((res) => {
          if (res) {
            setCallStats(res?.data?.data);
            dispatchAction(setCallScore(res?.data?.data?.callScore?.value))
            setLoader(false);
          }
        });
        if (typeOfCall == "SINGLE_CALL") {
          callsService.getCallTime(params).then((response) => {
            if (response) {
              setCallTime(response?.data?.data);
              setFileName(response?.data?.data?.recordingUrl);
              setAudioPathUrl(response?.data?.data?.recordingObjectUrl);
              setTalkDuration(response?.data?.data?.duration);
              setLoader(false);
            }
          });
        }
  
        callsService
          .getLikeDislike(
            params,
            handlePayload()
          )
          .then((res) => {
            if (res) {
              setLikeDislikeData(res.data.data);
            }
            tabRedirected && flag && setFlag(false);
          }).catch(err => {
            tabRedirected && flag && setFlag(false);
          });
      }
      if (keyVal == "transcript") {
        setLoader(true);
        callsService
          .getCallDetailTranscript(
            historyObject?.callId ? historyObject?.callId : params
          )
          .then((response) => {
            if (response) {
              setCallTranscript(response.data.data);
              setCustomerName(response?.data?.data?.customerName);
              setSellerName(response?.data?.data?.sellerName);
              setLoader(false);
            }
          }).catch(()=>{
            setLoader(false)
          });
        callsService.getLikeDislike(params, "TRANSCRIPT").then((res) => {
          if (res) {
            setLikeDislikeData(res.data.data);
          }
        });
        if (typeOfCall == "SINGLE_CALL") {
          callsService.getCallTime(params).then((response) => {
            if (response) {
              setCallTime(response?.data?.data);
              setFileName(response?.data?.data?.recordingUrl);
              setAudioPathUrl(response?.data?.data?.recordingObjectUrl);
              setTalkDuration(response?.data?.data?.duration);
              setLoader(false);
            }
          });
        }
      }
      if(keyVal == "summary"){
        setLoader(true);
         callsService.getCallDetailSummary(params).then((response) => {
          if (response) {
            setCallSummary(response?.data?.data[0]?.summary?.summary)
            setLoader(false)
          }
        }).catch(()=>{
          setLoader(false)
        })
      }
      if(keyVal == "pointers"){
        setLoader(true)
        callsService.getCallDetailPointer(params).then((response)=> {
          if (response) {
            setPointerData(response.data.data[0])
            setLoader(false)
          }
        }).catch(()=>{
          setLoader(false)
        })
      }
      if(keyVal == "keywords"){
        setLoader(true);
        callsService.getCallDetailKeyword(params).then((response)=>{
          if (response) {
            setKeywordsCount(response.data.data[0])
            setLoader(false)
          }
        }).catch(()=>{
          setLoader(false)
        })
      }
      if (keyVal == "questions") {
        setLoader(true);
        callsService
          .getCallDetailQuestions(
            historyObject?.callId ? historyObject?.callId : params
          )
          .then((response) => {
            if (response) {
              setCallQuestions(response.data.data);
              setLoader(false);
            }
          }).catch(()=>{
            setLoader(false)
          });
        callsService.getLikeDislike(params, "QUESTIONS").then((res) => {
          if (res) {
            setLikeDislikeData(res.data.data);
          }
        });
      }

      if (keyVal == "foulLanguage") {
        setLoader(true);
        callsService
          .getCallDetailFoul(
            historyObject?.callId ? historyObject?.callId : params
          )
          .then((response) => {
            if (response) {
              setFoulLanguage(response.data.data);
              setLoader(false);
            }
          }).catch(()=>{
            setLoader(false)
          });
        callsService.getLikeDislike(params, "FOUL_LANGUAGE").then((res) => {
          if (res) {
            setLikeDislikeData(res.data.data);
          }
        });
      }

      if (keyVal === "callMoments" || tabRedirected === "callMoments") {
        handleMomentData();
      }

      if (keyVal == "feedback") {
        setLoader(true);
        callsService
          .getCallDetailFeedback(
            historyObject?.callId ? historyObject?.callId : params
          )
          .then((res) => {
            if (res) {
              setCallFeedback(res.data.data);
              setLoader(false);
            }
          }).catch(()=>{
            setLoader(false)
          });
      }

      if (keyVal == "actions") {
        setLoader(true);
        callsService
          .getCallDetailActions(
            historyObject?.callId ? historyObject?.callId : params
          )
          .then((res) => {
            if (res) {
              setCallAction(res.data.data);
              setLoader(false);
            }
          }).catch(()=>{
            setLoader(false)
          });
        callsService.getLikeDislike(params, "ACTIONS").then((res) => {
          if (res) {
            setLikeDislikeData(res.data.data);
          }
        });
      }

      if (keyVal == "signals") {
        setLoader(true);
        callsService
          .getCallDetailSignals(
            historyObject?.callId ? historyObject?.callId : params
          )
          .then((res) => {
            if (res) {
              setCallSignals(res.data.data);
              setLoader(false);
            }
          }).catch(()=>{
            setLoader(false)
          });
        callsService.getLikeDislike(params, "SIGNALS").then((res) => {
          if (res) {
            setLikeDislikeData(res.data.data);
          }
        });
      }
    }
  };

  const closeButton = () => setShow(false);

  const audioTime = (e) => {
  
    let totalSeconds;
      if (!e.includes(":")) {
        totalSeconds = parseInt(e.split('.')[1])       
    } else {
      let a = e.split(":");
      totalSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      
    }
    let element = document.getElementById("audio-element");
    if (element) {
      element.currentTime = totalSeconds;
      element.play();
    } else {
      console.error("Audio element not found");
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  // var time = ''
  
  const callBack = (e) => {
    setTime(e);
  };

  useEffect(() => {
    document.title = "Call Details - Odio";
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between py-3">
                  <h2 className="dashboard-headings py-2">
                    {`${typeOfCall == "SINGLE_CALL" ? "Call Id" : "Group Id"
                      } #${params}`}
                    <Button
                      className="btn-mdl py-1 ms-2"
                      onClick={onClickHandler}
                    >
                      More Info.
                    </Button>
                  </h2>
                  <div className="detail-top-head text-end" >
                      <div className="calls" style={{ display: 'flex', gap: '5px' }}>
                        {userDetail.includes("CALL SCORE") && (
                          <span className={`badge rounded-pill px-2 my-1 py-2 font-14 ${
                            callStats?.callScore?.value === 0 ? 'btn btn-outline-danger' : 'btn btn-outline-success'
                          }`}>
                            Call Score{" "}
                            {callStats
                              ? storedCallScore
                              : "0"}
                            %
                          </span>
                        )}
                        {callStats?.fatalCall?.value === 1 && userDetail.includes("CALL SCORE") && (
                          <span className={`badge rounded-pill px-2 my-1 py-2 font-14 ${
                            callStats?.fatalCall?.value === 1 ? 'btn btn-outline-danger' : 'btn btn-outline-success'
                          }`}>
                            Fatal{" "}
                            {/* {callStats
                              ? callStats?.fatalCall?.value
                              : "0"} */}
                            
                          </span>
                        )}
                      </div>
                    {userDetail.includes("PITCH SCORE") ? (
                      <span className="badge rounded-pill btn btn-outline-danger px-2 my-1 py-2 font-14">
                        Pitch Score{" "}
                        {callStats
                          ? callStats[0]
                            ? callStats[0].pitchScore
                            : "0"
                          : "0"}
                        %
                      </span>
                    ) : (
                      ""
                    )}
                    {userDetail.includes("CONVERSION PROBABILITY") ? (
                      <span className="badge rounded-pill btn btn-outline-warning px-2 my-1 py-2 font-14">
                        Conv. Probability{" "}
                        {callStats
                          ? callStats[0]
                            ? callStats[0].convProbability
                            : "0"
                          : "0"}
                        %
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {typeOfCall == "SINGLE_CALL" ? (
            <div className="col-12">
              <AudioPlayer
                recUrl={fileName}
                audioPathUrl={audioPathUrl}
                startTime={startTime}
                callBack={callBack}
                callTranscript={callTranscript}
                // key={key}
                talkDuration={talkDuration}
                callTime={callTime}
                // audioName={historyObject?.fileName}
                // audioData={audioData}
                // isLoading={isLoading}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-12">
            <Card>
              <Card.Body className="p-5">
                <div className="setting-section-nev">
                  <Tabs
                    id="controlled-tab-example"
                    activekey={key}
                    defaultActiveKey={tabRedirected && eventType != 'CALL' ? tabRedirected : "stats"}
                    onSelect={handleSelect}
                  >
                    <Tab eventKey="stats" title="STATS">
                    {
                      callStats ? (
                        Object.keys(callStats).length > 0 ? (
                          <Stats
                            callStats={callStats}
                            tabSelected={key}
                            tabRedirected={tabRedirected}
                            loader={loader}
                            callId={historyObject?.callId || params}
                            fileName={typeOfCall === "SINGLE_CALL" ? historyObject?.url : ""}
                            phone={historyObject?.phone}
                            agentExternalId={agentExternalId}
                            tabValue={tabValue}
                            likeDislikeData={likeDislikeData}
                            postId={notifPostId}
                            eventType={eventType}
                            commentShow={commentShow}
                            typeOfCall={typeOfCall}
                            agentId={historyObject?.id}
                            coeIds={history.location.state?.coeId}
                            module={typeOfCall == "SINGLE_CALL" ? "CALL" : "CALL_GROUP"}
                          />
                        ) : (
                          <div className="loader-container">
                            <Loading variant="dark" />
                          </div>
                        )
                      ) : (
                        <NodataFound />
                      )
                    }

                    </Tab>
                    {showSummary && typeOfCall == "SINGLE_CALL" ? (
                    <Tab eventKey="summary" title="SUMMARY">
                        {fileName != "" && (
                          <Summary 
                            callSummary={callSummary}
                            loader={loader}
                          />
                        )}
                    </Tab>
                    ) : (
                      ""
                    )}
                      {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="transcript" title="TRANSCRIPT">
                      {fileName != "" && (
                        <Transcript
                          callTranscript={callTranscript}
                          tabSelected={key}
                          tabRedirected={tabRedirected}
                          loader={loader}
                          callId={
                            historyObject?.callId
                              ? historyObject?.callId
                              : params
                          }
                          url={historyObject?.url}
                          audioTime={audioTime}
                          time={time}
                          fileName={fileName}
                          phone={historyObject?.phone}
                          agentExternalId={agentExternalId}
                          postId={notifPostId}
                          tabValue={tabValue}
                          likeDislikeData={likeDislikeData}
                          eventType={eventType}
                          typeOfCall={typeOfCall}
                          agentId={historyObject?.id}
                          module={typeOfCall == "SINGLE_CALL" ? "CALL" : "CALL_GROUP"}
                        />
                      )}
                    </Tab>
                    ) : (
                      ""
                    )}
                      <Tab eventKey="callMoments" title="MOMENTS">
                        {loader ? (
                          <div className="loader-container">
                            <Loading variant= "dark" />
                            </div>
                        ) : callMoments && Object.keys(callMoments).length > 0 ? (
                          <Moments
                            callMoments={callMoments}
                            tabSelected={key}
                            tabRedirected={tabRedirected}
                            loader={loader}
                            callId={
                              historyObject?.callId
                                ? historyObject?.callId
                                : params
                            }
                            fileName={fileName}
                            phone={historyObject?.phone}
                            agentExternalId={agentExternalId}
                            likeDislikeData={likeDislikeData}
                            postId={notifPostId}
                            tabValue={tabValue}
                            eventType={eventType}
                            typeOfCall={typeOfCall}
                            handleMomentData={handleMomentData}
                            agentId={historyObject?.id}
                            audioTime={audioTime}
                          /> ) : (
                            <NodataFound/>  
                           )
                        }
                    </Tab>
                               
                    {showPointers && typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="pointers" title="POINTERS">
                      {fileName != "" && (
                        <Pointers 
                          callPointer={pointerData}
                          loader={loader}
                        />
                      )}
                    </Tab>
                    ) : (
                      ""
                    )}
                    {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="questions" title="QUESTIONS">
                      {fileName != "" && (
                        <Questions
                          callQuestions={callQuestions}
                          tabSelected={key}
                          tabRedirected={tabRedirected}
                          loader={loader}
                          callId={
                            historyObject?.callId
                              ? historyObject?.callId
                              : params
                          }
                          customerName={customerName}
                          sellerName={sellerName}
                          fileName={fileName}
                          phone={historyObject?.phone}
                          agentExternalId={agentExternalId}
                          likeDislikeData={likeDislikeData}
                          postId={notifPostId}
                          eventType={eventType}
                          typeOfCall={typeOfCall}
                          agentId={historyObject?.id}
                        />
                      )}
                    </Tab>
                    ) : ("")}
                    {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="foulLanguage" title="FOUL LANGUAGE">
                      {fileName != "" && (
                        <FoulLanguage
                          foulLanguage={foulLanguage}
                          tabSelected={key}
                          tabRedirected={tabRedirected}
                          loader={loader}
                          callId={
                            historyObject?.callId
                              ? historyObject?.callId
                              : params
                          }
                          fileName={fileName}
                          phone={historyObject?.phone}
                          agentExternalId={agentExternalId}
                          likeDislikeData={likeDislikeData}
                          postId={notifPostId}
                          eventType={eventType}
                          typeOfCall={typeOfCall}
                          agentId={historyObject?.id}
                        />
                      )}
                    </Tab>
                    ) : ("")}
                    {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="actions" title="ACTIONS">
                      {fileName != "" && (
                        <Actions
                          callAction={callAction}
                          tabSelected={key}
                          tabRedirected={tabRedirected}
                          loader={loader}
                          callId={
                            historyObject?.callId
                              ? historyObject?.callId
                              : params
                          }
                          url
                          customerName={customerName}
                          sellerName={sellerName}
                          fileName={fileName}
                          typeOfCall={typeOfCall}
                          agentExternalId={agentExternalId}
                          eventType={eventType}
                          likeDislikeData={likeDislikeData}
                          agentId={historyObject?.id}
                        />
                      )}
                    </Tab>
                    ): ("")}
                    {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="signals" title="SIGNALS">
                        {fileName != "" && (
                          <Signals
                            callSignals={callSignals}
                            tabSelected={key}
                            tabRedirected={tabRedirected}
                            loader={loader}
                            callId={
                              historyObject?.callId
                                ? historyObject?.callId
                                : params
                            }
                            fileName={fileName}
                            phone={historyObject?.phone}
                            agentExternalId={agentExternalId}
                            likeDislikeData={likeDislikeData}
                            postId={notifPostId}
                            eventType={eventType}
                            typeOfCall={typeOfCall}
                            agentId={historyObject?.id}
                          />
                        )}
                      </Tab>
                    ) : ("")}
                        <Tab eventKey="feedback" title="FEEDBACK">
                      {
                        <Feedbacks
                          callFeedback={callFeedback}
                          loader={loader}
                        />
                      }
                    </Tab>
                    {showKeywords && typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="keywords"  title="KEYWORDS">
                        {fileName != "" && (
                          <Keywords
                            keywordsCount={keywordsCount}
                            loader={loader}
                          />
                        )}
                      </Tab>
                    ) : ("")}
                  
                  </Tabs>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {
        // customerName&&sellerName!==[]&&
        <MoreInfo
          show={show}
          setShow={setShow}
          closeButton={closeButton}
          customerName={historyObject ? historyObject?.customer : callTime?.more_info?.customerName}
          sellerName={historyObject ? historyObject?.seller : callTime?.more_info?.sellerName}
          talkDuration={historyObject ? historyObject?.duration : callTime?.more_info?.durationSeconds}
          date={historyObject ? historyObject?.date : callTime?.more_info?.date}
          processTime={historyObject?.processingTime ? historyObject?.processingTime : callTime?.more_info?.processingTime}
          url={historyObject?.url ? historyObject?.url : fileName}
          sellerId={historyObject ? historyObject?.id : callTime?.more_info?.callId}
          coeName={historyObject ? historyObject?.coe : storedData?.selectedCOE}
          typeOfCall={typeOfCall}
          userId={historyObject?.userId}
        />
      }
    </div>
  );
}
