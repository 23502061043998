import CoeUsersTable from './CoeUsersTable';
import React, { useEffect, useState } from 'react';
import AddNewCoe from './AddNewCoe';
import { connect } from 'react-redux';
import ReactTable from '../../Commons/Table/ReactTable';
import Loading from '../../Commons/Loading';
import './manageCoe.css';
import {
  SETTINGS_COMPONENT_TYPE,
} from '../../../Constants/Enum';
import { getCoeUsersList } from '../../../redux-container/settings/manage_coe/action';
import { USER_DETAIL } from '../../../Constants/constant';
import momentService from '../../Services/moment.service';

function ManageCoe(props) {
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [coeVisibility, setCoeVisibility] = useState(false);
  const [userData, setUserData] = useState([]);
  const [coeBucket, setCoeBucket] = useState('');
  const [loading, setLoading] = useState(false);
  const [callScoreColor, setCallScoreColor] = useState('');
  const [pitchScoreColor, setPitchScoreColor] = useState('');

  const handlePageIndex = (e) => {
    setPageIndex(e);
  };

  useEffect(() => {
    momentService
      .getMomentBuckets()
      .then((res) => {
        setCoeBucket(res);
      })
      .catch((error) => {
        console.log('abc');
      });
  }, []);

  const accType = localStorage.getItem(USER_DETAIL);
  let accountType = JSON.parse(accType);

  const salesColumn = [
    {
      Header: 'Name',
      accessor: 'coeName',
      sticky: 'left',
    },
    {
      Header: 'Moment Bucket',
      accessor: 'momentBucketName',
      sticky: 'left',
      Cell: (e) => (
        <a
          className='del-coe-btn'
          type='button'
          onClick={() => {
            props.setMomentBucket(e.row.original);
          }}
        >
          {' '}
          {e.value ? e.value : '-'}{' '}
        </a>
      ),
    },

    {
      Header: 'Users',
      accessor: (d) =>
        d.numberOfUsers > 0 ? 'View(' + d.numberOfUsers + ')' : 0,
      Cell: (e) => (
        <p className='text-center mb-0'>
          {e.value != 0 ? (
            <a
              type='button'
              className='del-coe-btn'
              value={e.row.original}
              onClick={() => displayCoeUsers(e.row.original)}
            >
              {' '}
              {e.value}{' '}
            </a>
          ) : (
            '-   '
          )}
        </p>
      ),
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
    },
    {
      Header: 'Action',
      accessor: 'Action',
      sticky: 'right',
      disableSortBy: true,
      Cell: (e) => (
        <p className='mb-0 text-center d-inline'>
          <a
            type='button'
            className='edit-Emp-member'
            onClick={() => callActions(e.row.original)}
          >
            <i className='lni lni-pencil mx-1' data-tip='Edit COE'></i>
          </a>
        </p>
      ),
    },
  ];

  const supportColumn = [
    {
      Header: 'Name',
      accessor: 'coeName',
      sticky: 'left',
    },
    {
      Header: 'Moment Bucket',
      accessor: 'momentBucketName',
      sticky: 'left',
      Cell: (e) => (
        <a
          className='del-coe-btn'
          type='button'
          onClick={() => {
            props.setMomentBucket(e.row.original);
          }}
        >
          {' '}
          {e.value ? e.value : '-'}{' '}
        </a>
      ),
    },

    {
      Header: 'Users',
      accessor: (d) =>
        d.numberOfUsers > 0 ? 'View(' + d.numberOfUsers + ')' : 0,
      Cell: (e) => (
        <p className='text-center mb-0'>
          {e.value != 0 ? (
            <a
              type='button'
              className='del-coe-btn'
              value={e.row.original}
              onClick={() => displayCoeUsers(e.row.original)}
            >
              {' '}
              {e.value}{' '}
            </a>
          ) : (
            '-   '
          )}
        </p>
      ),
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
    },
    {
      Header: 'Action',
      accessor: 'Action',
      sticky: 'right',
      disableSortBy: true,
      Cell: (e) => (
        <p className='mb-0 text-center d-inline'>
          <a
            type='button'
            className='edit-Emp-member'
            onClick={() => callActions(e.row.original)}
          >
            <i className='lni lni-pencil mx-1' data-tip='Edit COE'></i>
          </a>
        </p>
      ),
    },
  ];

  const bothColumn = [
    {
      Header: 'Name',
      accessor: 'coeName',
      sticky: 'left',
    },
    {
      Header: 'Moment Bucket',
      accessor: 'momentBucketName',
      sticky: 'left',
      Cell: (e) => (
        <a
          className='del-coe-btn'
          type='button'
          onClick={() => {
            props.setMomentBucket(e.row.original);
          }}
        >
          {' '}
          {e.value ? e.value : '-'}{' '}
        </a>
      ),
    },
    {
      Header: 'Users',
      accessor: (d) =>
        d.numberOfUsers > 0 ? 'View(' + d.numberOfUsers + ')' : 0,
      Cell: (e) => (
        <p className='text-center mb-0'>
          {e.value != 0 ? (
            <a
              type='button'
              className='del-coe-btn'
              value={e.row.original}
              onClick={() => displayCoeUsers(e.row.original)}
            >
              {' '}
              {e.value}{' '}
            </a>
          ) : (
            '-   '
          )}
        </p>
      ),
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
    },
    {
      Header: 'Action',
      accessor: 'Action',
      sticky: 'right',
      disableSortBy: true,
      Cell: (e) => (
        <p className='mb-0 text-center d-inline'>
          <a
            type='button'
            className='edit-Emp-member'
            onClick={() => callActions(e.row.original)}
          >
            <i className='lni lni-pencil mx-1' data-tip='Edit COE'></i>
          </a>
        </p>
      ),
    },
  ];

  function callActions(e) {
    setUserData(e);
    setCoeVisibility(true);
  }

  function displayCoeUsers(e) {
    if (e.numberOfUsers > 0) {
      props.getCoeUsersList(e.coeId);
      setUserData(e);
      props.setTab(e);
      //setVisible(true);
    }
  }

  function handleVisibility(val) {
    setVisible(val);
  }

  function handleShow() {
    momentService
      .getMomentBuckets()
      .then((res) => {
        setIsOpen(true);
        setCoeBucket(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <>
      {props.loader ? (
        <div className='loader-container'>
          <Loading variant='dark' />
        </div>
      ) : isOpen ? (
        <AddNewCoe
          name={'New COE'}
          coeBucket={coeBucket}
          setIsOpen={setIsOpen}
        />
      ) : coeVisibility ? (
        <AddNewCoe
          selectedRowData={userData}
          name={'Update COE'}
          coeBucket={coeBucket}
          setCoeVisibility={setCoeVisibility}
        />
      ) : visible ? (
        <CoeUsersTable
          setVisible={setVisible}
          pageIndex={pageIndex}
          handlePageIndex={handlePageIndex}
          coeData={userData}
        />
      ) : (
        <div className='manage-COE-main-table mt-3'>
          <div className='d-flex top-fixed-box'>
            <div className=' mb-2 text-sm-left myTeamLHeader'>
              <h5 className='account-name account-id'>Manage COE</h5>
            </div>
            <div className='nav-item add-coe-btn-top' role='presentation'>
              <a
                id='edit-coe-profile'
                className='nav-link btn account-edit-btn edit-account'
                onClick={() => handleShow()}
              >
                Add COE
              </a>
            </div>
          </div>
          <div className='view-team-card-details table-responsive dataTable-style react-table'>
            <ReactTable
              columns={
                accountType.accountType == 'SALE'
                  ? salesColumn
                  : accountType.accountType == 'SUPPORT'
                  ? supportColumn
                  : bothColumn
              }
              componentType={SETTINGS_COMPONENT_TYPE.MANAGE_COE}
              data={props.coesList}
              isDelete={true}
              isAdd={true}
              isExcelPdfButton={true}
              handlePageIndex={handlePageIndex}
              prePageIndex={pageIndex}
            />
            {/* <CoeUsersTable /> */}
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    coesList: state.manage_coe.coeList,
    loader: state.manage_coe.loader,
    accountType: state.login.accountType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoeUsersList: (Id) => {
      dispatch(getCoeUsersList(Id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCoe);
