import React, { useState, useEffect } from "react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import clientService from "../../Services/client.service";
import Loading from "../../Commons/Loading";
import TextField from "@mui/material/TextField";
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';  
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';  
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { conversionFormat } from '../../../Constants/constant';

export default function Calender(props) {
  //defSep =inputs.DATE_FORMAT
  const [seprator, setSeprator] = useState("/");
  const [key, setKey] = useState("general");
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const errors = {};
  const [updatedCalendarConfig, setUpdatedCalenderConfigs] = useState([]);
  const [inputs, setInputs] = useState({
     START_MONTH_OF_YEAR: '',
     START_DAY_OF_WEEK: '',
     TIME_ZONE: '',  
     DATE_FORMAT: '',
     DURATION_FORMAT: '',
     WEEK_OFF: '',
     TIME_FORMAT: '',
     WORKING_HRS_FROM: '',
     WORKING_HOURS_TO: ''
    });
  const handleStartMonth = (e)=>{
    setInputs(inputs => ({ ...inputs, START_MONTH_OF_YEAR: e.target.textContent }));
    setDisabled(false);
    var obj = updatedCalendarConfig.findIndex(
      (o) => o.key === "START_MONTH_OF_YEAR"
    );
    if (obj === -1) {
      var p = { key: "START_MONTH_OF_YEAR", value: e.target.textContent };
      updatedCalendarConfig.push(p);
    } else {
      updatedCalendarConfig[obj].value = e.target.textContent;
    }
    setUpdatedCalenderConfigs(updatedCalendarConfig);
  };

  const handleDateFormat = (e, option) => {
    //setDateFormatval(option);
    setInputs((inputs) => ({ ...inputs, DATE_FORMAT: option.name }));
    setDisabled(false);
    var obj = updatedCalendarConfig.findIndex((o) => o.key === 'DATE_FORMAT')  
    if(obj === -1)
    {
      var p = {'key': 'DATE_FORMAT', 'value': option.name }
      updatedCalendarConfig.push(p);
    } else {
      updatedCalendarConfig[obj].value = option.name;
    }
    setUpdatedCalenderConfigs(updatedCalendarConfig);
  };

  const handleDurationFormat = (e, option)=>{
    setDurationFormate(option)
    setInputs(inputs => ({ ...inputs, DURATION_FORMAT: option.name}));
    setDisabled(false);
    var obj = updatedCalendarConfig.findIndex(
      (o) => o.key === "DURATION_FORMAT"
    );
    if (obj === -1) {
      var p = { key: "DURATION_FORMAT", value: option.name };
      updatedCalendarConfig.push(p);
    } else {
      updatedCalendarConfig[obj].value = option.name;
    }
    setUpdatedCalenderConfigs(updatedCalendarConfig);
  }

  // const handleTimeZone = (e, option)=>{
  //   setTimeZoneVal(option);
  //   // vals= option;
  //   setInputs(inputs => ({ ...inputs, TIME_ZONE: option.code }));
  //   setDisabled(false);
  //   var obj = updatedCalendarConfig.findIndex((o) => o.key === 'TIME_ZONE')  
  //   if(obj === -1)
  //   {
  //     var p = { 'key': 'TIME_ZONE', 'value': option.code }
  //     updatedCalendarConfig.push(p);
  //   }
  //   else{
  //     updatedCalendarConfig[obj].value = option.code
  //   }
  //   setUpdatedCalenderConfigs(updatedCalendarConfig);
  // }

  const handleTimeFormat = (e, option)=>{
    setInputs(inputs => ({ ...inputs, TIME_FORMAT: option.name}));
    setDisabled(false);
    var obj = updatedCalendarConfig.findIndex((o) => o.key === 'TIME_FORMAT')  
    if(obj === -1)
    {
      var p = {'key': 'TIME_FORMAT', 'value': option.name }
      updatedCalendarConfig.push(p);
    }
    else{
      updatedCalendarConfig[obj].value = option.name
    }
    setUpdatedCalenderConfigs(updatedCalendarConfig);
  }

  const handleWorkingHrsFrom = (e) => {
    inputs.WORKING_HRS_FROM = e;
    var hours = e.getHours();
    var minutes = e.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    setInputs((inputs) => ({ ...inputs, WORKING_HRS_FROM: strTime }));
    setDisabled(false);
    var obj = updatedCalendarConfig.findIndex(
      (o) => o.key === "WORKING_HRS_FROM"
    );
    if (obj === -1) {
      var p = { key: "WORKING_HRS_FROM", value: strTime };
      updatedCalendarConfig.push(p);
    } else {
      updatedCalendarConfig[obj].value = strTime;
    }
    setUpdatedCalenderConfigs(updatedCalendarConfig);
  };

  const handleWorkingHrsTo = (e)=>{
      inputs.WORKING_HOURS_TO = e;
      var hours = e.getHours();
      var minutes = e.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      setInputs(inputs => ({ ...inputs, WORKING_HOURS_TO: strTime }));
      setDisabled(false);
      var obj = updatedCalendarConfig.findIndex((o) => o.key === 'WORKING_HOURS_TO')  
      if(obj === -1)
      {
        var p = {'key': 'WORKING_HOURS_TO', 'value': strTime }
        updatedCalendarConfig.push(p);
      }
      else{
        updatedCalendarConfig[obj].value = strTime
      }
      setUpdatedCalenderConfigs(updatedCalendarConfig);
    
  }

  const handleSelect  =  e  => {
    if(!e.type){
      setInputs(inputs => ({ ...inputs, WEEK_OFF: e }));
      var obj = updatedCalendarConfig.findIndex((o) => o.key === 'WEEK_OFF')  
        if(obj === -1)
        {
          var p = {'key': 'WEEK_OFF', 'value': e }
          updatedCalendarConfig.push(p);
          setDisabled(false)
        }
        else{
          updatedCalendarConfig[obj].value = e
          setDisabled(false)
        }
        setUpdatedCalenderConfigs(updatedCalendarConfig);
        //  if(e.target.value != ''){
        //   setDisabled(false);
        //  }
        // else
        //   setDisabled(true); 
    }
    else
    {
       setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
       var obj = updatedCalendarConfig.findIndex((o) => o.key === e.target.name)  
       if(obj === -1)
       {
         var p = {'key': e.target.name, 'value': e.target.value }
         updatedCalendarConfig.push(p);
       }
       else{
         updatedCalendarConfig[obj].value = e.target.value
       }
       setUpdatedCalenderConfigs(updatedCalendarConfig);

      if (e.target.value != "") {
        setDisabled(false);
      } else setDisabled(true);
    }
  };
  const dayOptions = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  var today = new Date();
  const hours = today.getHours();
  const minutes = today.getHours();
  const sec = today.getSeconds();
  var hoursTo = today.getHours();
  hoursTo = hours % 12;
  hoursTo = hours ? hours : 12;
  var day = today.getDate();
  day = day < 10 ? "0" + day : day;
  var month = today.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  const year = today.getFullYear();
  const shortyear = year.toString().slice(-2);
  const monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayShortNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthName = monthNames[today.getMonth()];
  const monthShortName = monthShortNames[today.getMonth()];
  const dayName = dayNames[today.getDay()];
  const dayShortName = dayShortNames[today.getDay()];

  function formatDate(date, format) {
    const map = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    };
    return format.replace(/mm|dd|yy|yyy/gi, (matched) => map[matched]);
  }

  const dateFormat =[
    {name: `dd${seprator}MM${seprator}yy`, label:`[${day}${seprator}${month}${seprator}${shortyear}]`, group: 'short'},
    {name: `MM${seprator}dd${seprator}yy`, label: `[${month}${seprator}${day}${seprator}${shortyear}]`, group: 'short'},
    {name: `yy${seprator}MM${seprator}dd`, label: `[${shortyear}${seprator}${month}${seprator}${day}]`, group: 'short'},
    {name: `dd${seprator}MM${seprator}yyyy`, label: `[${day}${seprator}${month}${seprator}${year}]`, group: 'medium'},
    {name: `MM${seprator}dd${seprator}yyyy`, label: `[${month}${seprator}${day}${seprator}${year}]`, group: 'medium'},
    {name: `yyyy${seprator}MM${seprator}dd`, label: `[${year}${seprator}${month}${seprator}${day}]`, group: 'medium'},
    {name: 'dd MMM yyyy', label: `[${day} ${monthShortName} ${year}]`, group: 'Long'},
    {name: 'dd MMMM yyyy', label: `[${day} ${monthName} ${year}]`, group: 'Long'},
    {name: 'MMMM dd, yyyy', label: `[${monthName} ${day}, ${year}]`, group: 'Long'},
    {name: 'EEE, MMMM dd, yyyy', label: `[${dayShortName}, ${monthName} ${day}, ${year}]`, group: 'Long'},
    {name: 'EEEE, MMMM dd, yyyy', label:`[${dayName}, ${monthName} ${day}, ${year}]`, group: 'Long'},
    {name: 'MMM dd, yyyy', label:`[${monthShortName} ${day}, ${year}]`, group: 'Long'},
    {name: 'yyyy MM dd', label: `[${year} ${month} ${day}]`, group: 'Long'}
  ]
  useEffect(() =>{
    inputs.DATE_FORMAT = inputs.DATE_FORMAT.replace(/[.\-\/\\]/gi, seprator);
  }, [seprator]);

  const handleSeprator = (e) => {
    setSeprator(e.target.textContent);
    setDisabled(false);
    inputs.DATE_FORMAT = inputs.DATE_FORMAT.replace(
      /[.\-\/\\]/gi,
      e.target.textContent
    );
    handleDateFormat(e, { name: `${inputs.DATE_FORMAT}` });
  };

  const timeFormat = [
    { name: "HH:mm:ss", label: `[${hours}:${minutes}:${sec}]` },
    { name: "HH:mm", label: `[${hours}:${minutes}]` },
    {
      name: "hh:mm:ss a",
      label: `[${hoursTo}:${minutes}:${sec} ${hours >= 12 ? "pm" : "am"}]`,
    },
    {
      name: "hh:mm a",
      label: `[${hoursTo}:${minutes} ${hours >= 12 ? "pm" : "am"}]`,
    },
  ];

  const durationFormat = [
    { name: "H M S", label: `[${hours} Hr ${minutes} Min ${sec} Sec]` },
    { name: "Decimal", label: `[${hours}.${minutes} Min]` },
  ];

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(()=>{
    var opt = props.calenderConfigs[5].value.split(','); 
    setInputs(inputs => (
      { ...inputs,
        START_MONTH_OF_YEAR: props.calenderConfigs[0].value,
        START_DAY_OF_WEEK: props.calenderConfigs[1].value,
        TIME_ZONE: props.calenderConfigs[2].value,
        DATE_FORMAT: props.calenderConfigs[3].value,
        DURATION_FORMAT: props.calenderConfigs[4].value,
        WEEK_OFF: opt, 
        TIME_FORMAT: props.calenderConfigs[6].value,
        WORKING_HRS_FROM: props.calenderConfigs[8].value,
        WORKING_HOURS_TO: props.calenderConfigs[7].value}));
  },[props])

     var TZone = props.timeZones.filter(function (el) {
      return el.id == props.calenderConfigs[2].value;
    });
  
  // useEffect(() => {
  //   var TZone = props.timeZones.filter(function (el) {
  //     return el.id == props.calenderConfigs[2].value;
  //   });
   
  //   var vals = TZone[0] ? {
  //         id: TZone[0].id,
  //         code: TZone[0].code,
  //         name: TZone[0].name,
  //         gmtOffset: TZone[0].gmtOffset,
  //         utcOffset: TZone[0].utcOffset,
  //       } : { id: "", code: "", name: "", gmtOffset: "", utcOffset: "" };
  //   setTimeZoneVal(vals);
  // }, [props.timeZones]);

    var vals=TZone;
    
    const [timeZoneVal, setTimeZoneVal] = useState("");

    const handleTimeZone=(event,newValue)=>{
      if (newValue && newValue.code){
        setTimeZoneVal(newValue.code);
      }
    };

    useEffect(() => {
      if (
        props.calenderConfigs &&
        props.calenderConfigs.length > 2 &&
        props.timeZones
      ) {
        const timezoneValueFromAPI = props.calenderConfigs.find(
          (config) => config.key === "TIME_ZONE"
        )?.value;
  
        if (timezoneValueFromAPI) {
          const matchingTimeZone = props.timeZones.find(
            (tz) => tz.code === timezoneValueFromAPI
          );
  
          if (matchingTimeZone) {
            setTimeZoneVal(matchingTimeZone.code);
          }
        }
      }
    }, [props.calenderConfigs, props.timeZones]);
  
    
    const [durationFormate, setDurationFormate] = useState({name: '-', label: inputs.DURATION_FORMAT});

  const onSubmit = () =>{
         setLoader(true)
        if(updatedCalendarConfig.length > 0){
         clientService.updateCalendarConfig(updatedCalendarConfig)        
         .then( res =>{
            if(updatedCalendarConfig.filter((val)=>val.key == "DATE_FORMAT").length > 0){
              let timeFormat = updatedCalendarConfig.filter((val)=>val.key == "DATE_FORMAT")[0].value
              localStorage.setItem("TIME_FORMAT", JSON.stringify(timeFormat ? conversionFormat[timeFormat] : { format: 'dd/MM/yy', value: "DD-MM-YYYY" }))
            }
            if(res)
            {
              clientService.getCalenderConfigs(updatedCalendarConfig)
              setLoader(false)
            } 
            }).catch(error=>{
              setLoader(false)
          })

        }else
        {
          setLoader(false)
        }
  }

  return(<>
            { props.loading ? <div className="loader-container"><Loading variant="dark"/></div>:
              <div id="primaryCalender" className="mt-2">
              <div>
                <div className="row mt-0 g-3">
                  <div className="col-md-6 mt-3">
                    <label htmlFor="reportsType" className="form-label">Start Month of Year*</label>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={monthOptions}
                        value={inputs.START_MONTH_OF_YEAR}
                        onChange={handleStartMonth}
                        renderInput={(params) => <TextField {...params}  value={inputs.START_MONTH_OF_YEAR} name='START_MONTH_OF_YEAR'  placeholder='Select Month' label=''/>}
                      />  
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="weeklyOff" className="form-label">Weekly Off*</label>
                    <div className="app">
                     <MultiSelect onChange={handleSelect} options={dayOptions} name='WEEK_OFF' value={inputs.WEEK_OFF} />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3"> 
                    <label htmlFor="inputEmail" className="form-label text-nowrap">Working Hours From*</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                          <MobileTimePicker
                            label={null}
                            value={(new Date(`2018-01-01 ${inputs.WORKING_HRS_FROM}`))}
                            onChange={handleWorkingHrsFrom}
                            renderInput={(params) => <TextField name='WORKING_HRS_FROM' {...params} />}
                          />
                          </Stack>
                    </LocalizationProvider>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="inputEmail" className="form-label text-nowrap">Working Hours To*</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                          <MobileTimePicker
                            label={null}
                            value={(new Date(`2018-01-01 ${inputs.WORKING_HOURS_TO}`))}
                            onChange={handleWorkingHrsTo}
                            renderInput={(params) => <TextField name='WORKING_HRS_TO' {...params} />}
                          />
                          </Stack>
                    </LocalizationProvider>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="timeZone" class="form-label">Time Zone*</label>
                    <Autocomplete
                        disableClearable
                        options={props.timeZones || []}
                        id="combo-box-demo"
                        // getOptionLabel={(option) => `(${option.gmtOffset}) ${option.name} (${option.code})`} 
                        getOptionLabel={(option)=>option.code}
                        onChange={handleTimeZone}
                        // defaultValue={timeZoneVal}
                        value={props.timeZones.find(tz => tz.code === timeZoneVal) || null}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        renderOption={(props, option) => (
                            <Box  component="li" {...props}>
                              {`(${option.gmtOffset}) ${option.name} (${option.code})`}
                            </Box>
                          )}
                        renderInput={(params) => <TextField {...params}   name='TIME_ZONE'  placeholder='Select Time Zone' value={timeZoneVal} 
                        // label=''
                        />}
                      />
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label for="dateFormat" className="form-label">Date Format*</label>
                    <div className='row'>
                      <div className='col-10'>
                        <Autocomplete
                          disablePortal
                          disableClearable
                          id="combo-box-demo"
                          options={dateFormat}
                          value={{name:`${inputs.DATE_FORMAT}`, label: ''}}
                          groupBy={(option) => String(option.group)}
                          getOptionLabel={(option) => `${option.name} ${option.label}`}
                          onChange={handleDateFormat}
                          renderInput={(params) => <TextField {...params} name='DATE_FORMAT'  placeholder='Select Date' label=''/>}
                        />
                      </div> 
                      <div className='col-2 ps-0'>
                        <Autocomplete
                          disablePortal
                          disableClearable
                          id="combo-box-demo"
                          options={['-', '/', '.']}
                          value={seprator}
                          onChange={handleSeprator}
                          renderInput={(params) => <TextField name="role" {...params}  value=''  label=''/>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label for="timeFormat" className="form-label">Time Format*</label>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={timeFormat}
                        value ={{name:`${inputs.TIME_FORMAT}`, label: ''}}
                        getOptionLabel={(option) => (option ? `${option.name} ${option.label}` : "")}
                        onChange={handleTimeFormat}
                        renderInput={(params) => <TextField {...params}  value={inputs.TIME_FORMAT} name='TIME_FORMAT'  placeholder='Select Time' label=''/>}
                      /> 
                    
                  </div>
                  <div className="col-md-6 mt-3">
                    <label for="durationFormat" className="form-label">Duration Format*</label>
                    <Autocomplete 
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={durationFormat}
                        defaultValue={{name:`${inputs.DURATION_FORMAT}`, label: ''}}
                        value={{name:`${inputs.DURATION_FORMAT}`, label: ''}}
                        getOptionLabel={(option) => (option ? `${option.name} ${option.label}` : "")}
                        onChange={handleDurationFormat}
                        renderInput={(params) => <TextField {...params}  name='DURATION_FORMAT'  placeholder='Select Duration' label=''/>}
                      />
                  </div>
                  <div className="col-12 text-sm-end">
                    <button type="submit" className="btn px-5 update-btn lh-base" disabled={disabled?disabled:''} id="update-profile" onClick={onSubmit} activeKey={key} onSelect={handleSelect}>{loader ? <Loading variant="light"/> : <> Update</>}</button>
                  </div>
                </div>
              </div> 
            </div>
          }   
     </>)
}
