import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import MicNoneIcon from '@mui/icons-material/MicNone';
import DoneIcon from '@mui/icons-material/Done';
import { color } from 'highcharts'
import { Button } from 'react-bootstrap'
import CoachingAssessmentUserStats from '../CoachingAssessmentUserStats'
import { getCoachingSessionDetail, submitCoachingAssessment } from "../../Services/coaching.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../Commons/Loading";
import toaster from "../../Toast/toaster";
import SummarizeIcon from "@mui/icons-material/Summarize";


const CoachingUserStatsDetail = ({getCoachingSessionDetail, activeCardId, sessionDetail})=>{
    const [expanded, setExpanded] = useState(null);
    const [loader, setLoader] = useState(false)
    const history = useHistory()

    const handlePanelChange = (panelId) => (event, isExpanded) => {
        setExpanded(isExpanded ? panelId : null);
    };

    const setLoaderTrue = ()=>{
        setLoader(true) 
    }

    const setLoaderFalse = ()=>{
        setLoader(false)
    }

    const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;
    const isAgent = role === "AGENT";
    return (
        loader ? 
        <div className="loader-container">
            <Loading variant='dark'/>
        </div>
        :
        <div className='card-body'>
            <p class="h4">{sessionDetail?.name}</p>
            <p className=''><small>1 Media | 00.05</small></p>
            <div>
                {sessionDetail.modules && sessionDetail?.modules.map((mod,id)=>(
                <Accordion key={id} expanded={expanded == id} onChange={handlePanelChange(id)}>
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ flexShrink: 0 }}>
                            <p className='content_summary'>{mod?.name}</p>
                        </Typography>
                        <div className="div-coaching scoreCommonStyle callScore"><span className="badge" style={{ background: (2 <= activeCardId && activeCardId <= 4) ? 'green' : 'red' }}>{23}</span></div>
                    </AccordionSummary>
                    {mod.documents && mod.documents.map(doc=>(
                        <AccordionDetails>
                            <SummarizeIcon sx={{ color: '#1976d2' }}/> 
                            <span style={{cursor:'pointer',pointerEvents: !isAgent ? 'none' : 'auto',}} onClick={!isAgent ? undefined : ()=>{history.push('/document_view',{document: doc, redirect:true, redirectFrom : 'coachingUserStats', moduleId : mod?.id, sessionId : sessionDetail?.id});}}>l{doc?.title}</span>
                        </AccordionDetails>
                    ))}
                    {mod.questions && 
                    <AccordionDetails>
                        <CoachingAssessmentUserStats isAgent={isAgent} questionList={mod?.questions} moduleId={mod?.id} sessionId={activeCardId} activeCardId={activeCardId} setLoaderFalse={setLoaderFalse} setLoaderTrue={setLoaderTrue} getCoachingSessionDetail={getCoachingSessionDetail}/>
                    </AccordionDetails>}
                </Accordion>))}
                {/* {sessionDetail?.modules && sessionDetail?.modules.length>0 && <Button className='mt-4' style={{backgroundColor: '#1976d2'}} type="submit">Submit</Button>} */}
            </div>
        </div>
    )
}

export default CoachingUserStatsDetail