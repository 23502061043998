// export const themePrimary = '#082546'

// export const themePrimary = '#00BEC2'
// export const themePrimary = '#99e5e6'
// export const themePrimary = '#ccf2f2'
// export const themePrimary = '#e5f8f8'

// export const themePrimary = '#ADEDF2'
// export const themePrimary = '#def7f9'

// export const themePrimary = '#BEFCFF'
// export const themePrimary = '#e5fdff'


export const dashboardTheme = '#9ee9f9'
// export const themePrimary = '#0f74f7'
// export const themePrimary = '#1374f7'

// export const themePrimary = '#9ee9f9'
// export const themePrimary = '#cef4fc'
// export const themePrimary = '#def4f9'
export const themePrimary = '#e6f9fd'
// export const themePrimary = '#dcf5fc'






// export const themePrimary = '#113355'
// export const themePrimary = '#194064'
// export const themePrimary = 'rgba(25, 64, 100, 0.9)'
// export const themePrimary = '#204d7a'



// export const themeButtonPrimary = '#0dcaf0'
// export const themeButtonPrimary = '#4accec'
export const themeButtonPrimary = '#0077b6'

// export const themeButtonPrimary = '#77ddfe'

// export const themeButtonPrimary = '#00C6B9'
// export const themeButtonPrimary = '#0097FA'
// export const themeButtonPrimary = '#00B3FF'



// export const themeButtonPrimary = '#0775B1'
// export const themeButtonPrimary = '#4772B9'
// export const themeButtonPrimary = '#5DA9E9'
// export const themeButtonPrimary = '#D6F4FF'