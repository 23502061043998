import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Loading from "../../Commons/Loading";
import NodataFound from "../../Commons/NoDataFound";
import Pagination from "../../Commons/Table/Pagination";
import { fill } from "lodash";
import { colors } from "@material-ui/core";
import { Opacity } from "@material-ui/icons";

export default function MomentsVsDurationGraph(props) {
    const { loader, analysis } = props;
    const PageSize = 12;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [data, setData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [canNextPage, setCanNextPage] = useState(true);
    const [canPreviousPage, setCanPreviousPage] = useState(false);
    const [chartType, setChartType] = useState("line");
    // const barChartIconSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-line" viewBox="0 0 16 16">
    // <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1zm1 12h2V2h-2zm-3 0V7H7v7zm-5 0v-3H2v3z"/>
    // </svg>`; 

    // const multilineChartIconSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
    // <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"/>
    // </svg>`;

    useEffect(() => {
        if (props?.data?.length > 0) {
            setData(props.data);
            setPaginatedData(props.data.slice(0, PageSize));
            setPageIndex(0);
            setCurrentPage(1);
        }else {
            setData([]);
            setPaginatedData([]);
        }
    }, [props?.data, chartType]);

    const nextPage = () => {
        if (pageIndex < Math.ceil(data.length / PageSize) - 1) {
            let newIndex = pageIndex + 1;
            let newData = data.slice(newIndex * PageSize, (newIndex + 1) * PageSize);
            setPaginatedData(newData);
            setPageIndex(newIndex);
            setCanPreviousPage(true);
            if (newIndex >= Math.ceil(data.length / PageSize) - 1) {
                setCanNextPage(false);
            }
        }
    };

    const previousPage = () => {
        if (pageIndex > 0) {
            let newIndex = pageIndex - 1;
            let newData = data.slice(newIndex * PageSize, (newIndex + 1) * PageSize);
            setPaginatedData(newData);
            setPageIndex(newIndex);
            setCanNextPage(true);
            if (newIndex <= 0) {
                setCanPreviousPage(false);
            }
        }
    };

    const gotoPage = (val) => {
        let newIndex = val;
        let newData = data.slice(newIndex * PageSize, (newIndex + 1) * PageSize);
        setPaginatedData(newData);
        setPageIndex(newIndex);
        setCanNextPage(newIndex < Math.ceil(data.length / PageSize) - 1);
        setCanPreviousPage(newIndex > 0);
        setCurrentPage(val + 1);
    };

    const barSeries = [{
        name: 'Moment vs Duration',
        data: paginatedData.length > 0 ? paginatedData.map(x => x.statVal) : [0]
        // data: data.map(x => x.statVal)
    }];

    const barIcon= 'bx bx-bar-chart';
    const lineIcon= 'bx bx-line-chart';

    const barOption = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                        customIcons: [
                        {
                            icon: `<i class="${barIcon}"></i>`,
                            index: 0,
                            title: "Bar Chart",
                            class: `custom-bar-icon ${chartType=='bar'?'select':''}`,
                            click: () => setChartType("bar"),
                        },
                        {
                            icon: `<i class="${lineIcon}"></i>`,
                            index: 1,
                            title: "Line Chart",
                            class: `custom-line-icon ${chartType=='line'?'select':''}`,
                            click: () => setChartType("line"),
                        },
                    ],
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "10px",
            },
        },
        xaxis: {
            categories: paginatedData.length > 0 ? paginatedData.map(x => x.durationBucket) : ["No Data"],
            title: {
                text: "Duration",
                offsetY: -10,
                align: "left",
            }
        },
        yaxis: {
            title: { text: "Moment" }
        },
        colors: ['#0077b6'],
        fill: {
            opacity: 1,
            type: 'solid'
        },
        tooltip: {
            shared: true,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const data = paginatedData[dataPointIndex];
                return `
                    <div class="tooltip-custom"> 
                        Moment: ${analysis}<br/>
                        Moment vs Days: ${series[seriesIndex][dataPointIndex]}<br />
                        Total Calls: ${data?.totalCalls} calls
                    </div>
                `;
            }
        },
    };

    const lineSeries = [{
        name: 'Moment vs Duration',
        data: data.length  > 0 ? data.map(x => x.statVal) : [0]
    }];

    const lineOptions = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [
                        {
                            icon: `<i class="${barIcon}"></i>`,
                            index: 0,
                            title: "Bar Chart",
                            class: `custom-bar-icon ${chartType=='bar'?'select':''}`,
                            click: () => setChartType("bar"),
                        },
                        {
                            icon: `<i class="${lineIcon}"></i>`,
                            index: 1,
                            title: "Line Chart",
                            class: `custom-line-icon ${chartType=='line'?'select':''}`,
                            click: () => setChartType("line"),
                        },
                    ],
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: data.length > 0 ? data.map(x => x.durationBucket): ["No Data"],
            title: {
                text: "Duration",
                offsetY: -10,
                align: "left",
            },
            labels: {
                style: {
                    fontSize: "10px",
                    fontWeight: "400",
                },
            },
            formatter: function (value, timestamp, index) {
                // Show only 5 labels
                const totalLabels = data?.length || 0;
                const step = Math.floor(totalLabels / 5);  // We use 5 to get 6 labels (start, 3 in between, end)
                if (index === 0 || index === totalLabels - 1 || index % step === 0) {
                    return value;
                }
                return '';
            },
            tickAmount: 6,
        },
        yaxis: {
            title: { text: "Moment" }
        },
        colors: ['#0077b6'],
        markers: {
            size: 4,
            colors: ['#0077b6'],
            strokeColors: '#0077b6',
            strokeWidth: 2,
            hover: { size: 7 }
        },
        stroke: { curve: 'straight' },
        fill: {
            // colors: ['#F72C4E'],
            type: 'gradient',
        },
        tooltip: {
            shared: true,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const da = data[dataPointIndex];
                return `
                    <div class="tooltip-custom"> 
                        Moment: ${analysis}<br/>
                        Moment vs Days: ${series[seriesIndex][dataPointIndex]}<br />
                        Total Calls: ${da?.totalCalls ?? 0 } calls
                    </div>
                `;
            }
        },
    };

    return (
        <>
            {loader ?(
                <div className="loader-container">
                    <Loading variant="dark" />
                </div>) :
                // barSeries[0].data && barSeries[0].data.length > 0 ?
                    (<div className="p-4">
                        <ReactApexChart type={chartType} options={chartType === 'bar' ? barOption : lineOptions} series={chartType === 'bar' ? barSeries : lineSeries} height="350" />
                        {paginatedData.length === 0 && data.length === 0 ? (<div>No Data Available</div>):(
                            (chartType === 'bar' && (
                                <Pagination
                                    className="pagination-bar mb-1"
                                    currentPage={currentPage}
                                    totalCount={data.length}
                                    pageSize={PageSize}
                                    pageIndex={pageIndex}
                                    currentPages={Math.ceil(data.length / PageSize)}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    gotoPage={gotoPage}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    handlePageIndex={gotoPage}
                                />
                            )
                            //  : null
                            )
                        ) }
                    </div>) 
                    // :
                    // <NodataFound />
            }
        </>
    );
}
