import React, { Fragment, useEffect, useState } from "react";
import { Route , BrowserRouter as Router, Switch} from "react-router-dom";
import Routing from "./Components/Commons/Routing";
import { AUTHENTICATED, conversionFormat } from "./Constants/constant";
import ToastContainer from './Components/Toast/components/ToastContainer';
import './Components/Toast/dist/ReactToastify.css';
import ForgotPassword from "./Components/Login/ForgotPassword";
import { connect } from "react-redux";
import {setUserDetails, setUserPermissions} from "./redux-container/login/action";
import {USER_DETAIL, USER_PERMISSIONS} from "./Constants/constant"
import GLIDUpload from "./Separate-pages/GLIDUpload";
import RecordingSubmit from "./Separate-pages/RecordingSubmit";
import Question from "./Components/Questions/question";
import { DataProvider } from "./Components/Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";
import BalicOnboard from "./Separate-pages/BalicOnboard";
import BalicOnBoardDetails from "./Separate-pages/BalicOnboardDetails";
import ForbiddenAccess from "./Separate-pages/ForbiddenAccess";
import { apiCall } from "./Components/Services/Interceptor";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";




const routeTitles = {
  "/sales_dashboard": "Dashboard",
  "/voice": "Voice",
  "/chat": "Chat",
  "/email": "Email",
  "/voice":"Voice",
  "/voice_detail": "Voice Detail",
  "/chat_detail":"Chat Detail",
  "/email_detail":"Email Detail",
  "/meeting_view_detail":"Meeting Detail",
  "/coaching_dashboard":"Coaching",
  "/knowledge_base":"Knowledge Base",
  "/activity_log":"/Activity Log",
  "/productivity":"Productivity",
  "/comparative_analysis":"Comparative Analysis",
  "/duration_metrics":"Duration Metrics",
  "/conversion_metrics":"Conversion Metrics",
  "/team":"Teams",
  "/reports":"Reports",
  "/knowledge_ai":"Knowledge Ai",
  "/live_assist":"Live Assist",
  "/account":"Account",
  "/setting":"Settings",
  "/upload_GLID":"Upload GLID",
  "/login":"Login",
  "/forgot_password":"Forgor Password",
  "/403": "Access Denied",
  "/": "Dashboard"
};

const UpdateTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;
    let newTitle = routeTitles[pathname] || "ODIO";
    const params = new URLSearchParams(search);
    const name = params.get("name");

    if (pathname === "/voice_detail") {
      newTitle = "Voice Detail";
    } else if (pathname === "/email_detail") {
      newTitle = "Email Detail";
    } else if (pathname === "/chat_detail") {
      newTitle = "Chat Detail";
    }

    if (name) {
      newTitle += ` - ${name}`;
    }

    document.title = newTitle;

  }, [location]);

  return null;
};

function App(props) { 

  let auth = localStorage.getItem(AUTHENTICATED);
  
  useEffect(() => {
    const storedFormat = JSON.parse(localStorage.getItem("TIME_FORMAT"));
    const fetchData = async () => {
        try {
          var timeFormat = await apiCall.get('odio/api/client/get-calendar-config').then((res)=>{ return res.data.data.filter((val)=>val.key == "DATE_FORMAT")[0].value})
          localStorage.setItem("TIME_FORMAT", JSON.stringify(timeFormat && conversionFormat[timeFormat]));
        } catch (error) {
          console.error(error);
          localStorage.setItem("TIME_FORMAT", JSON.stringify({ format: 'dd-MM-yyyy', value: "DD-MM-YYYY" }));
        }
    };

    if ((!storedFormat || !storedFormat.format || typeof(storedFormat) == "string") && auth) {
    fetchData();
    }
    
    if(auth)
      {
        let details = localStorage.getItem(USER_DETAIL);
        let permissions = localStorage.getItem(USER_PERMISSIONS);
        props.setUserDetails(details);
        props.setUserPermission(permissions);
      }
       if(!navigator.onLine){
        setTimeout(() => {
          window.location.reload(false)
        }, 30000);  
       }

  },[])
  
  return ( 
    <Fragment>
      <Router>
      <UpdateTitle />
        <DataProvider>
          <Switch>
            <Route exact path="/forgot_password">
              <ForgotPassword/> 
            </Route>
            <Route exact path="/question">
              <Question/>
            </Route>
            <Route exact path="/balic_onboard">
              <BalicOnboard/>
            </Route>
            <Route exact path="/onboarding_details">
              <BalicOnBoardDetails/>
            </Route>
            <Route exact path="/403">
              <ForbiddenAccess/>
            </Route>
            <Route path="/">         
              <Routing/>
            </Route>
          </Switch>  
          </DataProvider>
      </Router>
     <ToastContainer />
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) =>{
  return{
      setUserDetails: (details) =>{dispatch(setUserDetails(details))},
      setUserPermission: (permissions)=>{dispatch(setUserPermissions(permissions))},
  }
}

export default connect(null, mapDispatchToProps) (App);
