import React, { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap";
import { ListGroup } from 'react-bootstrap';
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { useSelector } from "react-redux";
import nudgeIcon from '../../../assets/images/mdi--hand-double-tap.png'

const NudgesMobileView = ()=>{

    const sidebarRef = useRef(null);
    const [filterLoader, setFilterLoader] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    let nudges = useSelector(state=>state?.live_assist?.liveCallData?.alert)
    const nudgesData = nudges?.reduce((acc, item) => {
        acc[item.prompt] = item.exists ? 1 : 0;
        return acc;
    }, {});

    const toggleClick = ()=>{
        if (sidebarRef.current) {
            sidebarRef.current.toggle();
            setIsSidebarOpen(!isSidebarOpen);
        }
    }
    const onCreate = ()=> {
        if(sidebarRef.element){
            sidebarRef.element.style.visibility = "";
        }
    }


    useEffect(() => {
        if (isSidebarOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isSidebarOpen]);

    return(
        <div>
            <SidebarComponent
                enableGestures={false}
                id="default1"
                ref={sidebarRef}
                width="280px"
                type='Push'
                position="Right"
                created={onCreate}
                style={{ visibility: "hidden" }}
            />
            <div id="nudges" className="switcher-wrapper call-filters side-fillters-scroler">
                <div className="switcher-btn" onClick={toggleClick}>
                {" "}
                <img src={nudgeIcon} style={{width:'27px'}}/>
                </div>
                <div className='switcher-body'>
                <ListGroup style={{marginTop:'15px', height: '85vh', overflowY: 'scroll'}}>
                  {
                  
                  nudgesData &&
                    Object.entries(nudgesData).map(
                      ([key, value]) => (
                        <ListGroup.Item
                          key={key}
                          action
                          variant={value === 1 ? 'success' : 'danger'}
                        >
                          {key}
                        </ListGroup.Item>
                      )
                    )}
                </ListGroup>
                </div>
            </div>
        </div>
    )
}

export default NudgesMobileView