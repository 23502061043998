import React, { useEffect, useState } from "react";
import {
 Modal,
 ModalDialog,
 DialogTitle,
 ModalClose,
 FormControl,
 FormLabel,
 Stack,
} from "@mui/joy";
import Button from "@mui/joy/Button";
import "../CoachingDashboard/coachingDashboard.css";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
 Autocomplete,
 CircularProgress,
 FormControlLabel,
 Radio,
 RadioGroup,
} from "@mui/material";
import toaster from "../../Toast/toaster";
import {
 getCategoryList,
 updateSessionStatus,
} from "../../Services/coaching.service";

const UpdateSessionStatusModal = ({
 openUpdateSessionStatusModal,
 setOpenUpdateSessionStatusModal,
 rowData,
 getAllCoachingSessionsList
}) => {
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
 const {
  handleSubmit,
  control,
  formState: { errors },
 } = useForm({
  defaultValues: {
   category: rowData?.categoryResponse ? rowData.categoryResponse : null,
   status: rowData?.status,
  },
 });

 const getSessionCategoryList = () => {
  setLoading(true);
  getCategoryList()
   .then((res) => {
    if (res?.data?.status === 0) {
     setCategoryList(res.data?.data);
    } else {
     toaster.error(
      "No Category list available, please create category in settings "
     );
    }
   })
   .catch((error) => {
    console.error(error, "Api call Error:");
   })
   .finally(() => {
    setLoading(false);
   });
 };

 useEffect(() => {
  getSessionCategoryList();
 }, []);

 const onSubmit = (data) => {
  const reqBody = {
   categoryId: data?.category?.id,
   status: data?.status,
  };
  updateSessionStatus(rowData?.id, reqBody)
   .then((response) => {
    if (response?.data?.status === 0) {
     toaster.success("Session status updated successfully");
     setOpenUpdateSessionStatusModal(false);
     getAllCoachingSessionsList();
    } else {
     toaster.error("Failed to update session status");
    }
   })
   .catch((error) => {
    console.error(error, "Api call Error:");
   });
 };

 return (
  <Modal
   open={openUpdateSessionStatusModal}
   onClose={() => {
    setOpenUpdateSessionStatusModal(false);
   }}
   sx={{
    backdropFilter: "blur(1px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "15px",
   }}
   disableEscapeKeyDown={true}
  >
   <ModalDialog sx={{ width: "50%", height: "50vh" }}>
    <ModalClose />
    <DialogTitle>Update Session Status</DialogTitle>
    <hr style={{ margin: 0 }} />
    {loading ? (
     <div
      className="justify-content-center align-items-center"
      style={{ display: "flex", height: "45vh" }}
     >
      <CircularProgress />
     </div>
    ) : (
     <div className="upper-coaching-form">
      <div className="p-2" style={{ flex: 1 }}>
       <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ flexDirection: "row", gap: "2rem" }}>
         <FormControl sx={{ width: "50%" }}>
          <FormLabel>Select category</FormLabel>
          <Controller
  name="category"
  control={control}
  rules={{ required: "Please select a category" }}
  render={({ field }) => (
    <Autocomplete
      options={categoryList || []}
      getOptionLabel={(option) => `${option.name || ""}`.trim()}
      filterSelectedOptions
      onChange={(event, value) => field.onChange(value)}
      value={field.value || null} // Ensure null value is handled
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Category"
          error={!!errors.category}
          helperText={errors.category ? errors.category.message : ""}
        />
      )}
    />
  )}
/>
         </FormControl>
         <FormControl sx={{ width: "50%" }}>
          <FormLabel>Status</FormLabel>
          <Controller
           name="status"
           control={control}
           render={({ field }) => (
            <RadioGroup {...field} row>
             <FormControlLabel
              value="DRAFT"
              control={<Radio />}
              label="Draft"
             />
             <FormControlLabel
              value="PUBLISH"
              control={<Radio />}
              label="Publish"
             />
            </RadioGroup>
           )}
          />
         </FormControl>
        </Stack>
        <Stack sx={{ flexDirection: "row", marginTop: "1rem" }}>
         <Button type="submit">Update Session Status</Button>
        </Stack>
       </form>
      </div>
     </div>
    )}
   </ModalDialog>
  </Modal>
 );
};

export default UpdateSessionStatusModal;
