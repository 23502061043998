import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  ModalClose,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/joy";
import Button from "@mui/joy/Button";
import "../CoachingDashboard/coachingDashboard.css";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import conversationService from "../../Services/conversation.service";
import { Autocomplete, LinearProgress } from "@mui/material";
import toaster from "../../Toast/toaster";
import profileService from "../../Services/profile.service";
import sessionService from "../../Services/session.service";
import CreateModules from "./CreateModules";

function SessionCreateModal(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      sessionName: "",
      userId: null,
      momentIds: [],
      comment: "",
    },
  });

  const { openModal, setOpenModal, setHasModalClosed, setSessionCreatedOrUpdated } = props;
  const [moments, setMoments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [selectedMomentIds, setSelectedMomentIds] = useState(null);
  const localData = JSON.parse(localStorage.getItem("AGENT"));
  const localUserData = JSON.parse(localStorage.getItem("USER_DETAIL"));

  const getUsersList = async () => {
    const reqBody = {
      coeIds: localData?.selectedCoeRange,
      fromD: localData?.fromD,
      toD: localData?.toD,
      userId: localUserData?.userId,
    };
    return await profileService
      .getCoachingAgents(reqBody)
      .then((response) => {
        if (response?.data?.status === 0) {
          const filterTeam = response.data?.data?.team?.filter(
            (team) => team?.role === "AGENT"
          );
          setUsersList(filterTeam);
        } else {
          toaster.error(
            "Error while fetching the Users data, please try again"
          );
        }
      })
      .catch((err) => {
        console.log("Api call error: ", err);
      });
  };

  const getMomentData = async () => {
    const momentBucketId =
      parseInt(localStorage.getItem("MOMENT_ID")) || undefined;
    return await conversationService
      .getCallListingMoments("SALES", momentBucketId)
      .then((res) => {
        if (res.data?.status === 0) {
          setMoments(res?.data?.data);
          setLoading(false);
        } else {
          toaster.error("Error while fetching the moments, please try again");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmit = async (data) => {
    const formData = {
      sessionName: data.sessionName,
      userId: data.userId,
      momentIds: data.momentIds.map((moment) => moment.momentId), // Extract only momentId values
      comment: data.comment,
    };
    setSelectedMomentIds(data.momentIds.map((moment) => moment.momentId))
    return await sessionService
      .createSession(formData)
      .then((res) => {
        if (res?.data?.status === 0) {
          setSessionDetails(res?.data.data);
          setSessionCreatedOrUpdated(true);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getMomentData();
    getUsersList();
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        setHasModalClosed(true);
      }}
      sx={{
        backdropFilter: "blur(1px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "15px",
      }}
      disableEscapeKeyDown={true}
    >
      <ModalDialog sx={{ width: "75%", height: "90vh", overflowY: "scroll" }}>
        <ModalClose />
        <DialogTitle>Create Coaching</DialogTitle>
        <hr style={{ margin: 0 }} />
        <div className="upper-coaching-form">
          <div className="p-2" style={{ flex: 1 }}>
            <DialogTitle className="create-session-short-title">
              Fill the details to assign a session.
            </DialogTitle>
            <hr style={{ margin: "1rem 0" }} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack sx={{ flexDirection: "row", gap: "2rem" }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Session Name</FormLabel>
                  <Controller
                    name="sessionName"
                    control={control}
                    rules={{ required: "Please provide a Session Name" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Add session name"
                        error={!!errors.sessionName}
                        helperText={
                          errors.sessionName ? errors.sessionName.message : ""
                        }
                        disabled={sessionDetails}
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Applicable Representative</FormLabel>
                  <Controller
                    name="userId"
                    control={control}
                    rules={{ required: "Please select a user" }}
                    render={({ field }) => (
                      <Autocomplete
                        options={usersList || []}
                        getOptionLabel={(option) =>
                          `${option.firstName || ""} ${
                            option.lastName || ""
                          }`.trim()
                        }
                        onChange={(event, value) =>
                          field.onChange(value ? value.id : null)
                        }
                        value={
                          usersList.find((user) => user.id === field.value) ||
                          null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.userId === value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select user"
                            error={!!errors.userId}
                            helperText={
                              errors.userId ? errors.userId.message : ""
                            }
                            disabled={sessionDetails}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack sx={{ flexDirection: "row", gap: "2rem", marginTop : '1rem' }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Select Moments</FormLabel>
                  <Controller
                    name="momentIds"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value.length > 0 || "Please select at least one moment",
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={moments}
                        getOptionLabel={(option) => option.momentName}
                        onChange={(event, value) => field.onChange(value)}
                        value={field.value}
                        isOptionEqualToValue={(option, value) =>
                          option.momentId === value.momentId
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Moments"
                            error={!!errors.momentIds}
                            helperText={
                              errors.momentIds ? errors.momentIds.message : ""
                            }
                            disabled={sessionDetails}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Comment</FormLabel>
                  <Controller
                    name="comment"
                    control={control}
                    rules={{ required: "Please provide a comment" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Add Comment"
                        error={!!errors.comment}
                        helperText={
                          errors.comment ? errors.comment.message : ""
                        }
                        fullWidth
                        disabled={sessionDetails}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack sx={{ flexDirection: "row-reverse", marginTop: "0.5rem" }}>
                <Button disabled={sessionDetails} type="submit">
                  Submit session details
                </Button>
              </Stack>
            </form>
          </div>
        </div>
        <hr
          style={{ margin: "0", border: "1px solid #636b74", opacity: "0.1" }}
        />
        {sessionDetails && <CreateModules momentIds={selectedMomentIds} session={sessionDetails} />}
      </ModalDialog>
    </Modal>
  );
}

export default SessionCreateModal;
