import React, { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap";
import Loading from "./Loading";
import InsightsFilter from "../Insights/InsightsFilter";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import "rc-slider/assets/index.css";
import "../Filter/Filter.css";
import "rc-tooltip/assets/bootstrap.css";
import MeetingFilter from "../Meetings/MeetingFilter";
import { useDispatch, useSelector } from "react-redux";
import { setChips } from "../../redux-container/insights/action";
import { applyFilterData } from "../../redux-container/meeting/action";

const FilterDrawer = ({filterType, componentType, applyFilter, setLoaderTrue, resetFilter, tabIndex})=>{

    const sidebarRef = useRef(null);
    const dispatch  = useDispatch()
    const [filterLoader, setFilterLoader] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const employeesSelected = useSelector(state=>state?.insights?.filterData?.id)
    const employeeNames = useSelector(state=>state?.insights?.filterData?.names)
    const options  = useSelector(state=>state?.insights?.userOptions)
    const isDisabled = employeesSelected && employeesSelected.length==0 ? true : false
    const [filterData, setFilterData] = useState({})
    const [disableApply, setDisableApply] = useState(false)

    const toggleClick = ()=>{
        if (sidebarRef.current) {
            sidebarRef.current.toggle();
            setIsSidebarOpen(!isSidebarOpen);
        }
    }
    const onCreate = ()=> {
        if(sidebarRef.element){
            sidebarRef.element.style.visibility = "";
        }
    }

    const disableApplyButton = (val) => {
        setDisableApply(val)
    }

    const displayFilter = ()=>{
        switch(filterType) {
            case 'INSIGHTS':{
                return (
                    <InsightsFilter tabIndex={tabIndex} setFilterData={setFilterData} disableApply={disableApplyButton}/>
                )
            }
            case 'MEETINGS':{
                return (
                    <MeetingFilter setFilterData={setFilterData} disableApply={disableApplyButton}/>
                )
            }

        }
    }

    const handleSubmit = (e)=>{
        toggleClick()
        setFilterLoader(true)
        e.preventDefault()
        setLoaderTrue()
        applyFilter()
        dispatch(applyFilterData( filterData )); 
        dispatch(setChips(employeeNames && employeeNames?.length!=options?.length && employeeNames?.map((name,index)=>{return {id:employeesSelected[index], name:name}})))
        let localData = JSON.parse(localStorage.getItem("AGENT"))
        if(filterData?.momentBucketId){
            localStorage.setItem("MOMENT_ID",filterData?.momentBucketId)
            localStorage.setItem("MOMENT_BUCKETS",filterData?.momentBucket)
        }
        localStorage.setItem("AGENT", JSON.stringify({
            "pageSelected": localData?.pageSelected,
            "analysis": filterData?.analysis ?? localData?.analysis,
            "productivity": filterData?.productivity ?? localData?.productivity,
            "fromD": filterData?.fromD ?? localData?.fromD,
            "toD": filterData?.toD ?? localData?.toD,
            "coe": filterData.externalId && (filterData?.externalId?.length > 1 || typeof (filterData.externalId) == 'object') ? filterData.externalId : filterData.externalId && (!filterData?.externalId?.length > 1 || typeof (filterData.externalId) != 'object') ? [filterData.externalId] : localData?.coe,
            "agentDetailCOE": localData?.agentDetailCOE,
            "selectedCOE": filterData?.selectedCOE ?? localData.selectedCOE,
            "selectedCoeRange": filterData?.selectedCoeRange ?? localData.selectedCoeRange,
            "userIds": filterData?.id ?? localData?.userIds,
            "userNames": localData?.userNames,
            "empId": localData?.employeeId,
            "empName": localData?.user,
            "role": localData?.role,
            "designation": localData?.designation,
            "type": localData?.type,
            "teamCOE": localData?.teamCOE,
            "callsFrom": localData?.callsFrom,
            "callsTo": localData?.callsTo,
            "aht": localData?.aht,
            "callScore": localData?.callScore,
            "pitchScore": localData?.pitchScore,
            "customerName": localData?.customerName,
            "customerMobile": localData?.customerMobile,
            "fileName": localData?.fileName,
            "sellerROS": localData?.sellerROS,
            "sellerName": localData?.sellerName,
            "selectedSeller": localData?.selectedSeller,
            "cScore": localData?.cScore,
            "callHealth": localData?.callHealth,
            "callDuration": localData?.callDuration,
            "words": localData?.words,
            "moment": localData?.moment,
            "momentId": localData?.momentId,
            "pitch": localData?.pitch,
            "prob": localData?.prob,
            "opportunity": localData?.opp,
            "urgency": localData?.urgency,
            "selectedDate": filterData?.selectedDate ?? localData.selectedDate,
            "selectedRange": filterData?.selectedRange ?? localData.selectedRange,
            "momentBucket": filterData?.momentBucket ?? localData.momentBucket,
            "momentBucketId": filterData?.momentBucketId ?? localData.momentBucketId,
            "frequency": filterData?.frequency ?? localData?.frequency,
            "reportType": filterData?.reportType ?? localData?.reportType
        }))
        setTimeout(() => {
            setFilterLoader(false)
        }, 2000);
    }

    const onResetClick = (e)=>{
        toggleClick();
        setFilterLoader(true)
        e.preventDefault()
        setLoaderTrue()
        resetFilter()
        dispatch(setChips(''))
        // localStorage.setItem("AGENT", JSON.stringify({ "fromD": filterData?.fromD, "toD": filterData?.toD, "momentBucket": localStorage.getItem("MOMENT_BUCKETS"), "momentBucketId": localStorage.getItem("MOMENT_ID") , "coe" : this?.storedData?.coe }))
        setTimeout(() => {
            setFilterLoader(false)
        }, 2000);
    }

    //Commented Use Effect due to not scrolling while filter is opened 
    // useEffect(() => {
    //     if (isSidebarOpen) {
    //         document.body.style.overflow = 'hidden';
    //     } else {
    //         document.body.style.overflow = 'auto';
    //     }

    //     return () => {
    //         document.body.style.overflow = 'auto';
    //     };
    // }, [isSidebarOpen]);

    return(
        <div>
            <SidebarComponent
                enableGestures={false}
                id="default1"
                ref={sidebarRef}
                width="280px"
                type='Push'
                position="Right"
                created={onCreate}
                style={{ visibility: "hidden" }}
            />
            <div className="switcher-wrapper call-filters side-fillters-scroler">
                <div className="switcher-btn" onClick={toggleClick}>
                {" "}
                <i className="bx bx-filter-alt"></i>
                </div>
                <div className='switcher-body'>
                    <Form>
                        <div className="d-flex top-heading align-items-center">
                            <div>
                                <h5 className="mb-0 text-uppercase font-14">
                                    {componentType}
                                </h5>
                            </div>
                            <button
                                type="button"
                                onClick={toggleClick}
                                className="btn-close ms-auto close-switcher"
                                aria-label="Close"
                            />
                        </div>
                        <hr className="my-2" />
                        <div className="filter_scroll px-1">{displayFilter()}</div>
                        <div className="filters_btn bg-white py-2">
                            <button
                                type="button"
                                className="btn px-4 btn-light me-2 lh-base cancel-btn"
                                onClick={onResetClick}
                            >
                                Reset
                            </button>
                            <button
                                type="submit"
                                className="btn px-4 lh-base update-btn"
                                onClick={handleSubmit}
                                disabled={isDisabled ? isDisabled : disableApply}
                            >
                                {filterLoader ? (
                                <Loading variant="light" />
                                ) : (
                                <>Apply</>
                                )}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default FilterDrawer