import React from 'react'
import { useState } from 'react';
import "./sessions.css"
import teaching from "../../../assets/images/teaching.png"
import { useHistory } from 'react-router-dom';

function SessionInfoPage(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const history = useHistory()
    const info = history?.location.state

    const toggleViewMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div className="page-wrapper">
            <div className="page-content">
                {!info?.info ?
                    <>
                        <div className='session-info-header'>
                            <div className='session-banner'>
                                <div className='session-post-1'>
                                    <div className='session-subheading'>
                                        <div className="session-subheading-blockquote">
                                            <div><span className="category"> Multiple Choice Questions</span></div>
                                        </div>
                                        <div className="additional-info-container">
                                            <div className="restrictions-wrapper">
                                                <div className="info-img">
                                                    <div className="restriction-item">
                                                        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" height="32" className="restriction-item-icon"><path d="M18.5 12v2h-5v-2a2.5 2.5 0 115 0zm1.5 2v-2a4 4 0 10-8 0v2h-1c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-6c0-1.103-.897-2-2-2h-1zm1.5 2v6c0 .275-.225.5-.5.5H11a.501.501 0 01-.5-.5v-6c0-.275.225-.5.5-.5h10c.275 0 .5.225.5.5z" fill="currentColor"></path></svg>
                                                        <span className="text-information">
                                                            <div className="text-container">
                                                                <span> To get this course you need to assign it.</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='session-post-2'>
                                    <img src={teaching} />
                                    <div className="sessions-direct-course">
                                        <div>
                                            <button className="start-button disabled solid" disabled="">
                                                <span className="btn-text">Get this course</span>
                                            </button>
                                        </div>
                                    </div>
                                    <button className="solid icon-before">
                                        <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" height="30" className="icon" data-testid="prefix-icon"><path d="M8.5 16a8 8 0 1116 0 8 8 0 01-16 0zm5.884-3.403a.749.749 0 00-.384.653v5.5a.75.75 0 001.144.637l4.5-2.75a.754.754 0 00.36-.64.754.754 0 00-.36-.64l-4.5-2.75a.758.758 0 00-.76-.016v.006z" fill="currentColor"></path></svg>
                                        <span className="btn-text">Intro video</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='session-description'>
                            <b>1) These agents make judgements based on current perceptions while ignoring previous perceptions.
                                These agents only succeed in creating a fully visible world. </b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Goal-Based Agent</li>
                                <li>b) Utility-Based Agent</li>
                                <li>c) Simple-Based Agent</li>
                                <li>d) Knowledge-Based Agent</li>
                            </ul>
                            <p><b>Ans-c</b></p>

                            <b>2) Which of the following is not a benefit of Expert Systems?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Time</li>
                                <li>b) Speed</li>
                                <li>c) Knowledge </li>
                                <li>d) Availability</li>
                            </ul>
                            <p><b>Ans-a</b></p>

                            <b>3)  What is the function of an Artificial Intelligence “Agent”? </b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Mapping of goal sequence to an action</li>
                                <li>b) Mapping of precept sequence to an action</li>
                                <li>c) Work without the direct interference of the people </li>
                                <li>d) Mapping of environment sequence to an action</li>
                            </ul>
                            <p><b>Ans-b</b></p>
                            <b>4) An agent can improve its performance by </b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Learning</li>
                                <li>b) Responding</li>
                                <li>c) Observing </li>
                                <li>d) Perceiving</li>
                            </ul>
                            <p><b>Ans-a</b></p>
                            <b>5. Which of the following is the most important in customer service?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Speed of service</li>
                                <li>b) Empathy towards the customer</li>
                                <li>c) Product knowledge </li>
                                <li>d) All of the above</li>
                            </ul>
                            <p><b>Correct Answer: d) All of the above</b></p>

                            <b>6. When dealing with an upset customer, what is the first step you should take?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Immediately escalate the issue to a supervisor</li>
                                <li>b) Apologize and show empathy</li>
                                <li>c) Offer a discount </li>
                                <li>d) Provide a solution right away</li>
                            </ul>
                            <p><b>Correct Answer: b) Apologize and show empathy</b></p>
                            <b>7. What is the best way to handle a situation where you do not know the answer to a customer's question?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Guess the answer</li>
                                <li>b) Tell the customer you don't know</li>
                                <li>c) Assure the customer you will find out and follow up with accurate information </li>
                                <li>d) Redirect the customer to someone else immediately</li>
                            </ul>
                            <p><b>Correct Answer: c) Assure the customer you will find out and follow up with accurate information</b></p>
                            <b>8. Which of the following is an example of active listening?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Interrupting the customer to offer a solution quickly</li>
                                <li>b) Letting the customer talk without interjecting</li>
                                <li>c) Paraphrasing the customer's concerns to ensure understanding </li>
                                <li>d) Offering your own opinion</li>
                            </ul>
                            <p><b>Correct Answer: c) Paraphrasing the customer's concerns to ensure understanding</b></p>

                            <b>9. What should you do if a customer asks for a refund that is not in line with company policy?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Deny the request immediately</li>
                                <li>b) Escalate the issue to a manager</li>
                                <li>c) Politely explain the policy and offer alternative solutions </li>
                                <li>d) Agree to the refund to keep the customer happy</li>
                            </ul>
                            <p><b>Correct Answer: c) Politely explain the policy and offer alternative solutions</b></p>
                            <b>10. Which of the following is a key indicator of customer satisfaction?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) The number of complaints received</li>
                                <li>b) Time spent on each call</li>
                                <li>c) Customer feedback and ratings </li>
                                <li>d) Number of products sold</li>
                            </ul>
                            <p><b>Correct Answer: c) Customer feedback and ratings</b></p>
                            <b>11. How should you respond if a customer is angry and raises their voice?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Raise your voice in response to assert authority</li>
                                <li>b) Stay calm and maintain a professional tone</li>
                                <li>c) End the conversation if the customer is unreasonable </li>
                                <li>d) Tell the customer to calm down</li>
                            </ul>
                            <p><b>Correct Answer: b) Stay calm and maintain a professional tone</b></p>
                            <b>12. What does KPI stand for in customer service?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Key Performance Index</li>
                                <li>b) Key Product Indicator</li>
                                <li>c) Knowledge Process Indicator </li>
                                <li>d) Key Performance Indicator</li>
                            </ul>
                            <p><b>Correct Answer: d) Key Performance Indicator</b></p>
                            <b>13. In Amazon Connect, what is the purpose of user creation?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) To track customer issues</li>
                                <li>b) To assign roles and permissions to agents</li>
                                <li>c) To manage sales records </li>
                                <li>d) To handle technical escalations</li>
                            </ul>
                            <p><b>Correct Answer: b) To assign roles and permissions to agents</b></p>
                            <b>14. Which of the following is essential for delivering exceptional service?</b>
                            <ul style={{ listStyle: 'none' }}>
                                <li>a) Focusing only on solving the issue</li>
                                <li>b) Building rapport with the customer</li>
                                <li>c) Keeping calls as short as possible </li>
                                <li>d) Avoiding interaction unless absolutely necessary</li>
                            </ul>
                            <p><b>Correct Answer: b) Building rapport with the customer</b></p>
                        </div>
                    </> :
                    <>
                        <div className='session-info-header'>
                            <div className='session-banner'>
                                <div className='session-post-1'>
                                    <div className='session-subheading'>
                                        <div className="session-subheading-blockquote">
                                            <div><span className="category"> What is dialer and how to use it?</span></div>
                                        </div>
                                        <div className="additional-info-container">
                                            <div className="restrictions-wrapper">
                                                <div className="info-img">
                                                    <div className="restriction-item">
                                                        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" height="32" className="restriction-item-icon"><path d="M18.5 12v2h-5v-2a2.5 2.5 0 115 0zm1.5 2v-2a4 4 0 10-8 0v2h-1c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-6c0-1.103-.897-2-2-2h-1zm1.5 2v6c0 .275-.225.5-.5.5H11a.501.501 0 01-.5-.5v-6c0-.275.225-.5.5-.5h10c.275 0 .5.225.5.5z" fill="currentColor"></path></svg>
                                                        <span className="text-information">
                                                            <div className="text-container">
                                                                <span> To get this course you need to assign it.</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='session-post-2'>
                                    <img src={teaching} />
                                    <div className="sessions-direct-course">
                                        <div>
                                            <button className="start-button disabled solid" disabled="">
                                                <span className="btn-text">Get this course</span>
                                            </button>
                                        </div>
                                    </div>
                                    <button className="solid icon-before">
                                        <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" height="30" className="icon" data-testid="prefix-icon"><path d="M8.5 16a8 8 0 1116 0 8 8 0 01-16 0zm5.884-3.403a.749.749 0 00-.384.653v5.5a.75.75 0 001.144.637l4.5-2.75a.754.754 0 00.36-.64.754.754 0 00-.36-.64l-4.5-2.75a.758.758 0 00-.76-.016v.006z" fill="currentColor"></path></svg>
                                        <span className="btn-text">Intro video</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='session-description'>
                            <div className={`description ${!isExpanded ? 'expanded' : ''}`}>
                                <span>
                                    A <b>dialer</b> is a tool or system used in call centers to automate the process of dialing phone numbers. It's primarily used by agents to increase efficiency, ensuring that they spend more time interacting with customers rather than manually dialing numbers. Dialers are common in sales, customer service, and support environments. <br />  <br />
                                    <b>Types of Dialers:</b> <br />  <br />
                                    <b>1. Manual Dialer: </b>
                                    <ul>
                                        <li>The simplest form, where agents manually dial each phone number themselves.</li>
                                        <li> Used when agents need more control over the dialing process.</li>
                                    </ul>
                                    <b>2. Preview Dialer:</b>
                                    <ul>
                                        <li>The system presents the agent with information about the next customer before placing the call.</li>
                                        <li> The agent decides when to dial, allowing for preparation based on customer details.</li>
                                    </ul>
                                    <b>3. Progressive Dialer:</b>
                                    <ul>
                                        <li>Automatically dials the next number when an agent becomes available.</li>
                                        <li> Ensures a steady flow of calls but doesn't dial numbers when agents are busy, avoiding long hold times for customers.</li>
                                    </ul>
                                    <b>4. Predictive Dialer:</b>
                                    <ul>
                                        <li>The system automatically dials multiple numbers at once and connects answered calls to available agents.</li>
                                        <li>It "predicts" when agents will be free and ensures that calls are ready for them, reducing idle time.</li>
                                        <li>Used in high-volume outbound campaigns, though it risks dropped calls if no agent is available.</li>
                                    </ul>
                                    <b>5. Power Dialer:</b>
                                    <ul>
                                        <li>Dials one call per available agent. It doesn't wait for the agent to become available but starts dialing as soon as the call is ended.</li>
                                        <li>Optimized for speed, but less aggressive than a predictive dialer.</li>
                                    </ul>
                                    <b>6. Auto Dialer:</b>
                                    <ul>
                                        <li>A broad term for any system that automatically dials numbers from a list.</li>
                                        <li>Auto dialers are used in both inbound and outbound environments and can include additional features like prerecorded messages.</li>
                                    </ul> <br />
                                    <b>How Do We Use a Dialer?</b> <br />
                                    <b>Integration with CRM:</b>
                                    <ul>
                                        <li>Dialers often integrate with Customer Relationship Management (CRM) software to pull contact lists, customer data, and call history, ensuring that agents have all the necessary information about a customer when the call is initiated.</li>
                                    </ul>
                                    <b>List Upload or Import:</b>
                                    <ul>
                                        <li>Dialers typically allow you to upload or import contact lists (phone numbers) that need to be dialed. This can be based on campaign goals, such as sales or customer service follow-ups.</li>
                                    </ul>
                                    <b>Automated Call Handling:</b>
                                    <ul>
                                        <li> When using automatic or predictive dialers, the system manages call connections, automatically placing calls and routing them to available agents. Agents are notified once a customer answers, improving their workflow efficiency.</li>
                                    </ul>
                                    <b>Call Monitoring and Reporting:</b>
                                    <ul>
                                        <li>Most dialer systems provide detailed reporting on call outcomes, such as the number of successful connections, the duration of each call, and agent performance metrics. This data helps in analyzing the effectiveness of campaigns and making necessary adjustments.</li>
                                    </ul>
                                    <b>Compliance Features:</b>
                                    <ul>
                                        <li>Dialers often include compliance features to follow regulations, such as managing call frequency, observing "Do Not Call" lists, and limiting the hours during which calls are placed.</li>
                                    </ul>
                                    <b>Callbacks and Voicemails:</b>
                                    <ul>
                                        <li> Dialers can manage callbacks if customers are unavailable, and some systems can leave prerecorded messages on answering machines, ensuring that agents don't waste time on unanswered calls.</li>
                                    </ul> <br /> <br />
                                    <b>Advantages of Using a Dialer:</b>
                                    <ul>
                                        <li>Increases Efficiency: Agents spend less time dialing and more time on calls.</li>
                                        <li>Reduces Idle Time: By queuing calls, dialers ensure agents have a steady flow of conversations.</li>
                                        <li>Improves Call Quality: By using data from CRM integrations, agents have access to customer info before the call.</li>
                                        <li>Maximizes Campaigns: Automated dialing ensures that large volumes of calls are made in the shortest amount of time, maximizing the reach of outbound campaigns.</li>
                                    </ul> <br />

                                    <b>Where is it Used?</b>
                                    <ul>
                                        <li>Outbound Sales Campaigns: To connect with prospects and generate leads.</li>
                                        <li>Customer Service: To follow up with customers or manage outbound service inquiries.</li>
                                        <li>Debt Collection: Dialers are commonly used in debt collection to quickly contact debtors.
                                            Surveys and Polling: Organizations use dialers to automate calls for surveys or public opinion polling.</li>
                                    </ul> <br /> <br />
                                    <b>In Amazon Connect:</b>
                                    <ul>
                                        <li>If you are using Amazon Connect as your contact center, dialers can be integrated with it to automate outbound calling campaigns. Some third-party dialers can also be linked with Amazon Connect to enhance its capabilities, helping agents manage higher call volumes and improve efficiency in customer interactions.</li>
                                        <li>Let me know if you'd like details on how specific dialers work with Amazon Connect!</li>
                                    </ul>
                                </span>
                            </div>
                            <button className="linkButton" onClick={toggleViewMore}>
                                <span className="btn-text">{isExpanded ? 'View less' : 'View more'}</span>
                            </button>
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default SessionInfoPage