import React, { useMemo } from "react";
import conversationService from "../../Services/conversation.service";
import { CONVERSATIONS_SALE_DASHBOARD } from "../../../Constants/Enum";
import { useEffect } from "react";
import Loading from "../../Commons/Loading";
import { useState } from "react";
import Filter from "../../Filter/Filter";
import "./SalesCallDetail.css"
import { Card, OverlayTrigger } from "react-bootstrap";
import PaginationAlter from "../../Commons/Table/PaginationAlter";
import phone from "../../../assets/images/callList-images/phone.svg"
import calender from "../../../assets/images/callList-images/calendar.svg"
import time from "../../../assets/images/callList-images/timer.svg"
import alarm from "../../../assets/images/callList-images/alarm.svg"
import bulb from "../../../assets/images/callList-images/bulb.svg"
import moment from "../../../assets/images/callList-images/moment.svg"
import seller from "../../../assets/images/callList-images/seller.svg"
import user from "../../../assets/images/callList-images/user.svg"
import { useHistory } from "react-router-dom";
import { Popover } from "react-bootstrap";
import NodataFound from "../../Commons/NoDataFound";
import BubbleLoader from "../../Commons/BubbleLoader";
import { useDataDispatch, useDataState } from "../../Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";
import dashboardService from '../../Services/dashboard.service';
import { clearCallList, clearCallScore } from "../../../redux-container/callList/actions";
import { useDispatch } from "react-redux";

export default function SalesColumn(props) {
  const [callLists, setCallLists] = useState([])
  const [availablePages, setAvailablePages] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [showCard, setShowCard] = useState(false)
  const [cardId, setCardId] = useState("")
  const [detail, setDetail] = useState([])
  const [detailLoader, setDetailLoader] = useState(false);
  const [historyObject, setHistoryObject] = useState("")
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get('name')
  const history = useHistory()
  const wordData = useDataState()
  const dispatch = useDataDispatch()
  const dispatchAction = useDispatch()
  

  let callRange = urlParams.get('callScore')
  let durationRange = urlParams.get('callDuration')
  let redirected = urlParams.get('redirected')
  let fromDate = urlParams.get("fromDate")
  let toDate = urlParams.get("toDate")
  let tabRedirected = urlParams.get("tabRedirected")
  const storedData = JSON.parse(localStorage.getItem("AGENT"))
  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing

  useEffect(() => {
    setHistoryObject(history.location.state);
  }, [])

  let role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
  const callBack = (e) => {
    setCurrentPage(e)
    props?.forPageWhenFilter && props?.setForPageWhenFilter(false);
    setDetail([]);
    setShowCard(false);
  }

  let coeData;
  function getCoesAdmin() {
    return new Promise((resolve, reject) => {
      dashboardService.getUserCOEList().then(res => {
        let coe = res.data.data;
        localStorage.setItem('COE_DATA', JSON.stringify(coe));
        coeData = role === 'AGENT' ? JSON.parse(localStorage.getItem('COE_DATA')) : undefined;
        resolve(); // Resolve the promise when getCoesAdmin is done
      }).catch(error => {
        reject(error); // Reject the promise if there's an error
      });
    });
  }


  function callList() {
    props?.filterData?.loader ? props.loaderTrue() : props.loaderTrue()
    let val = {
      // "page":parseInt(localStorage.getItem("selectedPage")) ? parseInt(localStorage.getItem("selectedPage")) : 1,
      "page": wordData.groupIds.length > 0 ? parseInt(localStorage.getItem("selectedWordPage") || 1) : ((durationRange || callRange || redirected) && props?.flag) ? 1 : parseInt(localStorage.getItem("selectedPage")) || 1,
      "pageSize": 5,
      "callType": "SALES",
      "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
      "fromD": fromDate ? fromDate : storedData && storedData.fromD ? storedData.fromD : props?.defaultData.from,
      "toD": toDate ? toDate : storedData && storedData.toD ? storedData.toD : props?.filterData.to,
      // "groupIds":[1812959,1822657],
      "groupIds": wordData.groupIds.length > 0 ? wordData.groupIds : undefined,
      // "coeExternalIds":storedData?.coe,
      "sort": props.sortedField ? props.sortedField : "",
      "order":props.orderId?props.orderId:"",
      "customerName": storedData?.customerName ? storedData?.customerName : props?.filterData?.customerName,
      "customerMobiles": props?.historyObject?.agentPhone ? [props?.historyObject?.agentPhone] : storedData?.customerMobile ? storedData?.customerMobile : props?.filterData?.customerMobile && !fromDate ? props?.filterData?.customerMobile : undefined,
      "userIds": storedData?.sellerName?.length > 0 ? storedData?.sellerName : history.location?.state?.employeeId ? [history.location?.state?.employeeId] : props?.filterData?.sellerName?.length > 0 ? props?.filterData?.sellerName : undefined,
      "fileName": storedData?.fileName ? storedData?.fileName : props?.filterData?.fileName,
      "sellerROS": storedData?.sellerROS ? storedData?.sellerROS : props?.filterData?.sellerROS,
      "callScoreRange": storedData?.cScore ? storedData?.cScore : callRange ? callRange.split("-").map(Number) : props?.filterData?.callScore,
      // "fatalCall": storedData?.callHealth == 0 ? storedData?.callHealth : storedData?.callHealth == 1 ? storedData?.callHealth : null,
      "callDurationRange": storedData?.callDuration ? storedData?.callDuration : durationRange ? durationRange.split("-").map(Number) : props?.filterData.callDuration && [props?.filterData?.callDuration[0] * 60, props?.filterData?.callDuration[1] * 60],
      "words": storedData?.words ? storedData?.words : props?.filterData?.words,
      "momentId": storedData?.momentId ? storedData?.momentId : props?.filterData?.moment,
      // "coeName": coeData ? [coeData?.[0]?.coeName] : props?.filterData?.coeName ? props?.filterData?.coeName : storedData?.selectedCOE,
      "coeName": props?.filterData?.coeName ? props?.filterData?.coeName : storedData?.selectedCOE.length > 0 ? storedData?.selectedCOE : [coeData?.[0]?.coeName],
      "coeExternalIds": role == "AGENT" ? storedData?.coe ? storedData?.coe : [JSON.parse(localStorage.getItem("COE_DATA"))[0].externalId] : props?.filterData?.coe ? props?.filterData?.coe : storedData?.coe,
      "pitchRange": storedData?.pitch ? storedData?.pitch : props?.filterData?.pitch,
      "convProRange": storedData?.prob ? storedData?.prob : props?.filterData?.prob,
      "opportunityRange": storedData?.opp ? storedData?.opp : props?.filterData?.opportunity,
      "urgencyRange": storedData?.urgency ? storedData?.urgency : props?.filterData?.urgency,
    }
    if (storedData?.callHealth === 0 || storedData?.callHealth === 1) {
      val.fatalCall = storedData?.callHealth;
    }

    if (role === 'AGENT_ADMIN')
      val.coeExternalId = storedData?.coe[0]
    let newVal = tabRedirected == "reports" && props?.historyObject ? {...val, ...props?.historyObject} : val
    conversationService.getCallListing(newVal).then(response => {

      setCallLists(response ? response.data.data.response : [])
      setAvailablePages(response?.data.data.availablePages)
      props.loaderFalse();
      props?.flag && props?.setFlag(false)
    }).catch(error => {
      setCallLists([]);
      props.loaderFalse();
      props?.flag && props?.setFlag(false)
    });
    // let data = response.data.response.map(item=>{return

    //     item.callId:item.audioRecordingUrl}

    // })
  }

  // useEffect(() => {
  //   return () => {
  //     // Clear the groupIds in the context when the component unmounts
  //     dispatch({ type: "CLEAR_GROUP_IDS", payload: [] });
  //   };
  // }, [dispatch]);

  useMemo(() => {
    const unlisten = history.listen((location) => {

      const currentRoute = location.pathname;
  
      const routesToPreserveGroupIds = ['/sales_call_detail', '/sales_conversations'];
  
      if (!routesToPreserveGroupIds.includes(currentRoute) && wordData.groupIds.length !== 0) { 
        localStorage.setItem("Recursive", false) 
        localStorage.setItem('selectedWordPage', 1)
        dispatch({ type: "CLEAR_GROUP_IDS"});
      }
    });
  
    return () => {
      unlisten();
    };
  }, [history]);


  useEffect(()=>{
    if(window.location.pathname==='/sales_conversations' && fromDate){
      storedData.customerMobile = []
      storedData.fileName = undefined
      storedData.sellerName = []
      storedData.cScore = undefined
      storedData.sellerROS = undefined
      storedData.callDuration = undefined
      storedData.words = undefined
      storedData.momentId = undefined
      storedData.moment = ''
      localStorage.setItem('AGENT',JSON.stringify(storedData))
    }
  },[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(role === 'AGENT'){ 
        await getCoesAdmin();
        }
        callList();
      } catch (error) {
        console.error("Error fetching COE data", error);
      }
    };

    setShowCard(false);

    // Conditions to trigger fetchData
    const shouldFetch = 
      currentPage != null && 
      (
        history.location.state === null ||
        wordData.groupIds.length >= 0 || 
        window.location.search !== ''
      );
  
    if (shouldFetch) {
      fetchData();
    }
  }, [currentPage, props?.filterData, window.location.search]);

  useEffect(() => {
    // return () => {
      dispatchAction(clearCallScore());
    // };
  }, [dispatchAction]);


  useEffect(() => {
    setShowCard(false);
    if (props.sortedField || props.orderId) {
      if (currentPage !== 1) {
        !props?.forPageWhenFilter && props?.setForPageWhenFilter(true);
        localStorage.setItem("selectedPage", 1);
        setCurrentPage(1);
      } else if (currentPage === 1) {
        callList();
      }
    }
  }, [props.sortedField, props.orderId]);
  

  useEffect(() => {
    props.selectedData(props?.filterData)
    props.defaultDataa(props?.defaultData)
    props?.callListData(callLists)
  }, [props?.filterData, props?.defaultData, callLists])

  
  const conversion = (duration) => {

    if(duration.includes(":")){
    let hh = duration?.split(":")[0]
    let mm = duration?.split(":")[1]
    let ss = duration?.split(":")[2]
    
    let text = ""
    if (hh > 0) {
      text = `${hh} Hrs. ${mm} Min. ${ss} Sec.`
    }
    else if (mm > 0) {
      text = text + `${mm} Min. ${ss} Sec.`
    }
    else if (ss > 0) {
      text = text + `${ss} Sec.`
    }
    return text
    
    }else{
      let text = `${duration} mins`
      return text
    }
  }


  const clickHandler = (id, rec, duration, date, prostime ,empId, seller, customer, coeId, coe, phone, externalId, userId, name, 
    // filename
  ) => {
    localStorage.setItem("URL", rec)
    history.push(`sales_call_detail?callId=${id}&name=${name}`, {
      callId: id,
      url: rec,
      duration: duration,
      date: date,
      processingTime: prostime,
      id: empId,
      seller: seller,
      customer: customer,
      coeId: coeId,
      coe: coe,
      phone: phone,
      agentExternalId: externalId,
      userId:userId,
      callName: name,
      // fileName: filename
    })
  }

  const onClickCard = (id, groupId,item) => {
    if (detailLoader) {
      return  // to prevent multiple API calls while the previous one is still in progress
    }
    else {
      if (showCard && cardId === id) {
        setShowCard(false); // Collapse the card if it is already open
      }
      else {
        setDetailLoader(true)
        setShowCard(true);
        setCardId(id);
        let val = {
          "page": parseInt(localStorage.getItem("selectedPage")) ? parseInt(localStorage.getItem("selectedPage")) : 1,
          "pageSize": 5,
          "callType": "SALES",
          "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
          "fromD": fromDate ? fromDate : storedData && storedData.fromD ? storedData.fromD : props?.defaultData.from,
          "toD": toDate ? toDate : fromDate ? fromDate : storedData && storedData.toD ? storedData.toD : props?.defaultData.to,
          "groupId": groupId,
          "coeExternalIds":  item?.coeExternalId ? [item?.coeExternalId] : (role == "AGENT" ? (storedData?.coe ? storedData?.coe : [JSON.parse(localStorage.getItem("COE_DATA"))[0].externalId]) : storedData && storedData.coe),
          "userIds": storedData?.sellerName?.length > 0 ? storedData?.sellerName : history.location?.state?.employeeId ? [history.location?.state?.employeeId] : props?.filterData?.sellerName?.length > 0 ? props?.filterData?.sellerName : undefined,
          "pagination": false,
          // "coeName": props?.filterData?.coeName ? props?.filterData?.coeName : storedData?.selectedCOE,
        }
        let newVal = tabRedirected == "reports" && props?.historyObject? {...val, "fromD": props?.historyObject.fromD, "toD": props?.historyObject.toD, "coeExternalIds": props?.historyObject.coeExternalIds} : val
        conversationService.getCalls(newVal).then(res => {
          if (res?.status == 200) {
            setDetail(res?.data?.data?.details)
          }
        })
        .catch((err)=>{
          setShowCard(false)
        })
        .finally(()=>{
          setDetailLoader(false)
        })
      }
    }
  }

  const popoverr = (alarms, name, rec, moment) => (
    <Popover id="popover-trigger-hover-focus" title="right" style={{ "padding": "4px" }}>
      <h3 className="popover-header">{name}</h3>
      <div className="popover-body">
        <div className="popover-body-call">
          {
            name === 'Alarms' ? alarms.map(val => {
              return (
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1" />
                  {val}
                </p>
              )
            }) :
              name === 'Recommendations' ? rec.map(val => {
                return (
                  <p className="mb-0">
                    <i className="bx bx-stats font-16 align-middle me-1" />
                    {val}
                  </p>
                )
              }) :
                name === 'Moments' ? moment.map(val => {
                  return (
                    <p className="mb-0">
                      <i className="bx bx-stats font-16 align-middle me-1" />
                      {val}
                    </p>
                  )
                })
                  :
                  ""
          }
        </div>
      </div>
    </Popover>
  )

  return (

    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <>
          {callLists != '' && callLists.length > 0 ? callLists.map((item, id) => {
            return (
              <>
               <div className="card-container" onClick={() => onClickCard(id, item?.groupId, item)}>
                <Card style={detail.length > 0 && showCard && id == cardId ? { cursor: "pointer", marginBottom: "0.5rem" } : { cursor: "pointer" }}>
                  <Card.Body className="salesCallCard">
                    <div className="user">
                      <div className="caller" style={{width: '33%'}}>
                        <div className="name">
                          <span>
                            <img src={user} alt="" />
                          </span>
                          <span className="text">{item?.customerName}</span>
                        </div>
                        <div className="rank" style={{ cursor: "pointer" }}>{`#${item.phone} ${`(${item?.totalCalls})`}`}</div>
                      </div>
                      <div className="callCount">
                        <div className="box">
                          {item.callAlarms.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(item?.callAlarms, "Alarms", "")} rootClose>
                            <button style={{ "border": "none", "backgroundColor": "white" }}>
                              <img src={alarm} alt="" />
                              <span>{item?.callAlarms?.length}</span>
                            </button>
                          </OverlayTrigger> :
                            <>
                              <img src={alarm} alt="" />
                              <span>{item?.callAlarms?.length}</span>
                            </>}

                        </div>
                        <div className="box">
                          {item.callRec.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("", "Recommendations", item?.callRec)} rootClose>
                            <button style={{ "border": "none", "backgroundColor": "white" }}>
                              <img src={bulb} alt="" />
                              <span>{item?.callRec?.length}</span>
                            </button>
                          </OverlayTrigger> :
                            <>
                              <img src={bulb} alt="" />
                              <span>{item?.callRec?.length}</span>
                            </>}
                        </div>
                        <div className="box">
                          {item.moments.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("", "Moments", "", item?.moments)} rootClose>
                            <button style={{ "border": "none", "backgroundColor": "white" }}>
                              <img src={moment} alt="" />
                              <span>{item?.moments?.length}</span>
                            </button>
                          </OverlayTrigger> :
                            <>
                              <img src={moment} alt="" />
                              <span>{item?.moments?.length}</span>
                            </>}
                        </div>
                      </div>
                      <div className="score" style={{width: '33%'}}>
                        {userDetail.includes("CALL SCORE") ? <div className="scoreCommonStyle callScore">
                          <span className="text">CALL<br />SCORE</span>
                          <span className="badge" style={{ background: item.callColour == 'Amber' ? "#FFBF00" : item.callColour == null ? "Grey" : item.callColour }}>{item.callScore}%</span>
                        </div> : ""}
                        {userDetail.includes("PITCH SCORE") ? <div className="scoreCommonStyle pitchScore">
                          <span className="text">PITCH<br />SCORE</span>
                          <span className="badge" style={{ background: item.pitchColour == 'Amber' ? "#FFBF00" : item.pitchColour == null ? "Grey" : item.pitchColour }}>{item.pitchScore}%</span>
                        </div> : ""}
                      </div>
                    </div>
                    <hr />
                    <div className="seller" style={{display: 'flex' , justifyContent: 'space-between' }}>
                      <div className="name" style={{width : '20%'}}>
                        <span className="icon">
                          <img src={seller} alt="" />
                        </span>
                        {`${item.sellerName} (${item.employeeId})`}
                      </div>
                      <div className="number"style={{width : '20%', textAlign: 'center'}}>
                        <span className="icon">
                          <img src={phone} alt="" />
                        </span>
                        {item.phone}
                      </div>
                      <div className="date" style={{width : '20%', textAlign: 'center'}}>
                        <span className="icon">
                          <img src={calender} alt="" />
                        </span>
                        {item.date}
                      </div>
                      <div className="time" style={{width : '20%' , paddingLeft: '6%'}}>
                        <span className="icon">
                          <img src={time} alt="" />
                        </span>
                        {conversion(item?.talkDuration)}
                      </div>
                      <div style={{width : '20%' , textAlign: 'right'}}>
                        <button className="groupCall" onClick={(e) => {e.stopPropagation(); clickHandler(item.groupId, item.audioRecordingUrl, item.talkDuration, item.date, item.processingTime ,item.employeeId, item.sellerName, item.customerName, item.coeIds, item.coeName, item?.phone, item.userExternalId,item.userId, "GROUP") }}>
                          View Group
                        </button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className={`card-arrow arrow-down`}>
        <i className={`bx bxs-chevron-${showCard&&id==cardId ? 'up' : 'down'} arrow-icon`} style={{fontSize:'26px'}}></i>
      </div>
                </div>

                {detailLoader && cardId == id ?
                  <div className="calls-container">
                    <BubbleLoader />
                  </div> :
                  detail.length > 0 && detail.map((d, val) => {
                    return (showCard && cardId == id ?
                      <Card className={`${val == detail.length - 1 ? 'group-detail-noMargin' : 'group-details'} second-card`}>
                        <Card.Body style={{ cursor: "default" }} className="salesCallCard">
                          <div className="user">
                            <div className="caller" style={{width: '33%'}}>
                              <div className="name">
                                <span>
                                  <img src={user} alt="" />
                                </span>
                                <span className="text">{d?.customerName}</span>
                              </div>
                              <div className="rank" style={{ cursor: "default" }}>{`#${d.callId}`}</div>
                            </div>
                            <div className="callCount" style={{display: 'flex', textAlign: 'left'}}>
                              <div className="box">
                                {d.callAlarms.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(d?.callAlarms, "Alarms", "")} rootClose>
                                  <button style={{ "border": "none", "backgroundColor": "white" }}>
                                    <img src={alarm} alt="" />
                                    <span>{d?.callAlarms?.length}</span>
                                  </button>
                                </OverlayTrigger> :
                                  <>
                                    <img src={alarm} alt="" />
                                    <span>{d?.callAlarms?.length}</span>
                                  </>}

                              </div>
                              <div className="box">
                                {d.callRec.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("", "Recommendations", d?.callRec)} rootClose>
                                  <button style={{ "border": "none", "backgroundColor": "white" }}>
                                    <img src={bulb} alt="" />
                                    <span>{d?.callRec?.length}</span>
                                  </button>
                                </OverlayTrigger> :
                                  <>
                                    <img src={bulb} alt="" />
                                    <span>{d?.callRec?.length}</span>
                                  </>}
                              </div>
                              <div className="box">
                                {d.moments.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("", "Moments", "", d?.moments)} rootClose>
                                  <button style={{ "border": "none", "backgroundColor": "white" }}>
                                    <img src={moment} alt="" />
                                    <span>{d?.moments?.length}</span>
                                  </button>
                                </OverlayTrigger> :
                                  <>
                                    <img src={moment} alt="" />
                                    <span>{d?.moments?.length}</span>
                                  </>}
                              </div>
                            </div>
                            <div className="score" style={{width: '33%', textAlign: 'right'}} >
                              {userDetail.includes("CALL SCORE") ? <div className="scoreCommonStyle callScore" style={{width: '33%'}}>
                                <span className="text">CALL<br />SCORE</span>
                                <span className="badge" style={{ background: d.callColour == 'Amber' ? "#FFBF00" : d.callColour == null ? "Grey" : d.callColour }}>{d.callScore}%</span>
                              </div> : ""}
                              {userDetail.includes("PITCH SCORE") ? <div className="scoreCommonStyle pitchScore">
                                <span className="text">PITCH<br />SCORE</span>
                                <span className="badge" style={{ background: d.pitchColour == 'Amber' ? "#FFBF00" : d.pitchColour == null ? "Grey" : d.pitchColour }}>{d.pitchScore}%</span>
                              </div> : ""}
                            </div>
                          </div>
                          <hr />
                          <div className="seller">
  <div className="name" style={{ width: '20%' }}>
    <span className="icon">
      <img src={seller} alt="" />
    </span>
    {`${d.sellerName}`}
  </div>
  <div className="number" style={{ width: '20%', textAlign: 'center'}}>
    <span className="icon">
      <img src={phone} alt="" />
    </span>
    {d.phone}
  </div>
  <div className="date" style={{ width: '20%', textAlign: 'center' }}>
    <span className="icon">
      <img src={calender} alt="" />
    </span>
    {d.date}
  </div>
  <div className="time" style={{ width: '20%' , paddingLeft: '6%' }}>
    <span className="icon">
      <img src={time} alt="" />
    </span>
    {conversion(d?.talkDuration)}
  </div>
  <div style={{ width: '20%', textAlign: 'right' }}>
    <button className="groupCall" onClick={() => { clickHandler(d.callId, d.audioRecordingUrl, d.talkDuration, d.date, d.processingTime ,d.employeeId, item.sellerName, item.customerName, d.coeIds, d.coeName, d?.phone, d.userExternalId,d.userId, "SINGLE_CALL") }}>
      View Call
    </button>
  </div>
</div>

                        </Card.Body>
                      </Card> : "")
                  })
                }
              </>
            )
          }) : <NodataFound id="salesColumn" />
          }

        </>
      )}
      {callLists.length > 0 && availablePages > 0 ?
        <PaginationAlter
          callBack={callBack}
          availablePages={availablePages}
          loaderTrue={props.loaderTrue}
          loaderFalse={props.loaderFalse}
          customPageNumber={((durationRange || callRange || redirected) && props?.flag) ? 1 : ''}
          forPageWhenFilter={props?.forPageWhenFilter}
        /> : ""
      }
    </>
  );
}
