import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiCall } from '../../Services/Interceptor';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function ViewOnlyDocument() {
    const history = useHistory();
        const iframeRef = useRef(null);
        const [updatedHtml, setUpdatedHtml] = useState("");
        let data = history?.location?.state
        let storedData = useSelector(state => state.knowledgeBase.editDocData);
        const mock = data?.redirect ? data?.document : storedData
    
        const { editorHtml, editorCss, editorAssets } = mock;
    
        const getSignedUrls = async (mediaSources) => {
            const payload = mediaSources.reduce((acc, comp, index) => {
                acc[`additionalProp${index + 1}.${comp.type}`] = comp.src;
                return acc;
            }, {});
    
            try {
                const response = await apiCall.post(
                    "/odio/api/folder/document/content",
                    { data: payload }
                );
                return response.data?.data?.content || [];
            } catch (error) {
                console.error("Error getting signed URLs:", error);
                return [];
            }
        };
    
        const extractMediaAssets = () => {
            const assets = JSON.parse(editorAssets || "[]");
            return assets.filter(asset => asset.type === "image" || asset.type === "video")
                         .map(asset => ({ src: asset.src, type: asset.type }));
        };
    
        const replaceSrcInComp_Assets = (components, assets) => {
            return components.map((comp) => {
                if (comp.type === 'image' || comp.type === 'video') {
                    const asset = assets.find(
                        (a) => a.url === comp.attributes?.src || a.signedUrl === comp.src
                    );
                    if (asset) {
                        if (comp.attributes?.src) {
                            comp.attributes.src = asset.signedUrl;
                        }
                        if (comp.src) {
                            comp.src = asset.signedUrl;
                        }
                    }
                }
    
                if (comp.components && comp.components.length > 0) {
                    comp.components = replaceSrcInComp_Assets(comp.components, assets);
                }
                return comp;
            });
        };
    
        const replaceSrcInHtml = (html, components, assets) => {
            let updatedHtml = html;
    
            components.forEach((comp) => {
                if ((comp.attributes && comp.attributes.src) || comp.src) {
                    const signedUrl = comp.attributes?.src || comp.src;
                    const finalUrl = assets.find((asset) => asset.url === signedUrl)?.signedUrl;
                    if (finalUrl) {
                        const escapedSignedUrl = signedUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                        const regex = new RegExp(escapedSignedUrl, 'g');
                        updatedHtml = updatedHtml.replace(regex, finalUrl);
                    }
                }
                if (comp.components && comp.components.length > 0) {
                    updatedHtml = replaceSrcInHtml(updatedHtml, comp.components, assets);
                }
            });
    
            return updatedHtml;
        };
    
    
        const replacePathsWithSignedUrls = async () => {
            const mediaAssets = extractMediaAssets();
            if (mediaAssets.length === 0) {
                setUpdatedHtml(editorHtml);
                return;
            }
    
            const signedAssets = await getSignedUrls(mediaAssets);
    
            if (signedAssets.length > 0) {
                const components = JSON.parse(editorAssets || "[]");
                const updatedComponents = replaceSrcInComp_Assets(components, signedAssets);
                const updatedHtmlContent = replaceSrcInHtml(editorHtml, updatedComponents, signedAssets);
                setUpdatedHtml(updatedHtmlContent);
            } else {
                setUpdatedHtml(editorHtml);
            }
        };
    
        useEffect(() => {
            replacePathsWithSignedUrls();
        }, []);
    
        useEffect(() => {
            const iframe = iframeRef.current;
            if (iframe && updatedHtml) {
                const iframeContent = `
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <style>${editorCss}</style>
                    </head>
                    <body>${updatedHtml}</body>
                    </html>
                `;
                iframe.srcdoc = iframeContent;
            }
        }, [updatedHtml, editorCss]);
    
        return (
            <div className="page-wrapper">
                <div className="page-content">
                    <iframe
                        ref={iframeRef}
                        title="GrapesJS Document Preview"
                        style={{
                            width: '100%',
                            height: '80vh',
                            border: 'none',
                            overflow: 'hidden',
                        }}
                    />
                </div>
            </div>
        );
}

export default ViewOnlyDocument