import { Card } from "react-bootstrap";
import React,{ useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Stats from "../Sales/Stats/Stats";
import Moments from "../Sales/Moments";
import Signals from "../../Conversations_Dashboard/Sales/Signals/Signals"
import Questions from "../Questions/Questions";
import Actions from "../Actions/Actions";
import Transcript from "../Transcript/Transcript";
import Feedbacks from "../../Conversations_Common/Feedbacks/Feedbacks";
import { Button } from "react-bootstrap";
import MoreInfo from "../Sales/Modals/MoreInfo";
import FoulLanguage from "../FoulLanguage/FoulLanguage";
import "../Sales/SalesCallDetail.css"
import callsService from "../../Services/calls.service";
import { apiCall } from "../../Services/Interceptor";
import Loading from "../../Commons/Loading";
import { useHistory } from "react-router-dom";
import { chat } from "./Chats";
import NodataFound from "../../Commons/NoDataFound";
import Chats from "../Voice/chats/Chats";
import conversationService from "../../Services/conversation.service";
import Calls from "../Sales/Calls/Calls";
import { setCallScore } from "../../../redux-container/callList/actions";
import { useDispatch, useSelector } from "react-redux";

const ChatDetail = ()=>{

    const [key, setKey] = useState("stats");
    const [show, setShow]= useState(false)
    const [loader, setLoader] = useState(false)
    const [callStats, setCallStats] = useState([])
    const [callQuestions, setCallQuestions] = useState([])
    const [foulLanguage, setFoulLanguage] = useState([])
    const [callMoments, setCallMoments] = useState([])
    const [callTranscript, setCallTranscript]= useState([])
    const [callFeedback, setCallFeedback] = useState([])
    const [callAction, setCallAction] = useState([])
    const [callSignals, setCallSignals] = useState([])
    const [customerName,setCustomerName] = useState("")
    const [sellerName, setSellerName] = useState("")
    const history =  useHistory()
    const [historyObject, setHistoryObject] = useState("")
    const [likeDislikeData, setLikeDislikeData] = useState([])
    const [fileName, setFileName] = useState("")
    const [defaultData, setDefaultData] = useState([]);
    const [callDetail, setCallDetail] = useState([]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = urlParams.get('callId')
    const typeOfCall = urlParams.get('name')
    const talkDuration = urlParams.get('duration')
    let fromDate = urlParams.get("fromDate");
    let toDate = urlParams.get("toDate");
    const recUrl = []
    let url = recUrl
    const date = urlParams.get('date')
    const sellerId = urlParams.get('id')
    const coeName = urlParams.get('coe')
    const coeIds = historyObject?.coeId
    const seller = urlParams.get('seller')
    const customer = urlParams.get('customer')
    const dispatch = useDispatch()
    const storedCallScore = useSelector(state=>state?.callList?.callScore)
    const storedData = JSON.parse(localStorage.getItem("AGENT"));
    let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing

    const onClickHandler=()=>{
        setShow(true)
    }
    const closeButton=()=>setShow(false)

    useEffect(() => {
        setLoader(true)
        setHistoryObject(history.location.state)
        if (key === "chats") {
          let val = {
            page: parseInt(localStorage.getItem("selectedPage"))
              ? parseInt(localStorage.getItem("selectedPage"))
              : 1,
            pageSize: 5,
            callType: "SALES",
            clientExternalId: JSON.parse(localStorage.getItem("USER_DETAIL"))
              ?.externalId,
            fromD: fromDate
              ? fromDate
              : storedData && storedData.fromD
              ? storedData.fromD
              : defaultData.from,
            toD: toDate
              ? toDate
              : storedData && storedData.toD
              ? storedData.toD
              : defaultData.to,
            groupId: params,
            coeExternalIds: storedData && storedData.coe,
          };
          conversationService.getCalls(val).then((res) => {
            setLoader(true);
            if (res?.status == 200) {
              setLoader(false);
              setCallDetail(res?.data?.data?.details);
            }
          });
        }
        if(key=='stats'){
          let coeIds = JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange
          callsService.getCallDetailStats(params, typeOfCall).then((res) => {
            setLoader(true)
            if(res.status==200)
            {
              setCallStats(res.data.data)
              dispatch(setCallScore(res?.data?.data?.callScore?.value))
              setLoader(false)
              callsService.getLikeDislike(params,"STATS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          })
        }
    },[key=='stats', key=="chats"])

    const handleMomentData = async (isLoader = true) => {
      isLoader && setLoader(true)
      let response = await apiCall.post("/odio/api/call/call-detail/moments",{[`${typeOfCall=='SINGLE_CALL'?"callId":"groupId"}`]:parseInt(params),"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,"coeIds":coeIds})
      if(response.status==200){
          setCallMoments(response.data.data)
          isLoader && setLoader(false)
          callsService.getLikeDislike(params,"MOMENTS").then(res=>{
            if(res){
              setLikeDislikeData(res.data.data)
            }
          })
      }
    }

    const handleSelect = async (keyVal) => {
      if(key !== keyVal){
        setKey(keyVal);
        if(keyVal=='transcript'){
          setLoader(true)
          callsService.getCallDetailTranscript(params).then(response=>{
            // if(response.status==200){
              // let res = chat.filter(ele=>{
                // return ele.data.customerName == response?.data?.customerName || ele.data.sellerName == response?.data?.sellerName
              // })
              // if(res.length==0){
              //   res.push(chat[0])
              // }
              // res[0].data.customerName=response?.data?.customerName
              // res[0].data.sellerName = response?.data?.sellerName
              // setCallTranscript(res[0])
              setCallTranscript(response.data.data)
              setCustomerName(response?.data?.customerName)
              setSellerName(response?.data?.sellerName)
              setLoader(false)
              callsService.getLikeDislike(params,"TRANSCRIPT").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            // }
          })
        }
        if(keyVal=='questions'){
          setLoader(true)
          callsService.getCallDetailQuestions(params).then(response=>{
            if(response.status==200){
              setCallQuestions(response.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"QUESTIONS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          })
        }
    
        if(keyVal=='foulLanguage'){
          setLoader(true)
          callsService.getCallDetailFoul(params).then(response=>{
            if(response.status==200){
              setFoulLanguage(response.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"FOUL_LANGUAGE").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          })
        }
    
        if(keyVal === 'callMoments')
        {
          handleMomentData();
        }
    
        if(keyVal=='feedback'){
          setLoader(true)
          callsService.getCallDetailFeedback(params).then(res=>{
            if(res){
              setCallFeedback(res.data.data)
              setLoader(false)
            }
          })
        }
    
        if(keyVal=='actions'){
          setLoader(true)
          callsService.getCallDetailActions(params).then(res=>{
            if(res){
              setCallAction(res.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"ACTIONS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          })
        }
    
        if(keyVal=='signals'){
          setLoader(true)
          callsService.getCallDetailSignals(params).then(res=>{
            if(res){
              setCallSignals(res.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"SIGNALS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          })      
        }
      }
    }

    useEffect(() => {
        document.title = "Voice Details - Odio"
    }, []); 

    return(
        <div className="page-wrapper">
            <div className="page-content">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between py-3">
                                    <h2 className="dashboard-headings py-2">
                                        {`${typeOfCall=='SINGLE_CALL'?'Chat Id':'Group Id'} #${params}`}
                                        <Button
                                        
                                        className="btn-mdl py-1 ms-2"
                                        onClick={onClickHandler}
                                        >
                                        More Info.
                                        </Button>
                                    </h2>
                                    <div className="detail-top-head text-end">
                                        {userDetail.includes("CALL SCORE")?<span className="badge rounded-pill btn btn-outline-success px-2 my-1 py-2 font-14">
                                        Chat Score {callStats? storedCallScore: "0"}%
                                        </span>:""}
                                        {userDetail.includes("PITCH SCORE")?<span className="badge rounded-pill btn btn-outline-danger px-2 my-1 py-2 font-14">
                                        Pitch Score {callStats ? callStats?.pitchScore?.value : '0'}%
                                        </span>:""}
                                        {userDetail.includes("CONVERSION PROBABILITY")?<span className="badge rounded-pill btn btn-outline-warning px-2 my-1 py-2 font-14">
                                        Conv. Probability {callStats? callStats?.convProbability?.value : '0'}%
                                        </span>:""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Card>
                            <Card.Body className="p-5">
                                <div className="setting-section-nev">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activekey={key}
                                        onSelect={handleSelect}
                                    >
                                        <Tab eventKey="stats" title="STATS">
                                        {callStats?Object.keys(callStats)?.length > 0?<Stats type='chat' callStats ={callStats} tabSelected={key} loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall} module="CHAT"/>:<div className="loader-container"><Loading variant="dark"/></div>:<NodataFound/>}
                                        </Tab>
                                        {typeOfCall=='SINGLE_CALL'?<Tab eventKey="transcript" title="CHAT">
                                        {<Transcript type={'chat'}callTranscript={callTranscript} tabSelected={key} loader={loader} callId={params} url={url} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>}
                                        </Tab>:""} 
                                        <Tab eventKey="callMoments" title="MOMENTS">
                                        {<Moments type='chat' handleMomentData={handleMomentData} callMoments= {callMoments} tabSelected={key}  loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>}
                                        </Tab>
                                        {typeOfCall=='SINGLE_CALL'?<Tab eventKey="questions" title="QUESTIONS">
                                        {<Questions callQuestions={callQuestions} tabSelected={key}  loader={loader} callId={params} customerName={customerName} sellerName={sellerName} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>}
                                        </Tab>:""}
                                        {typeOfCall=='SINGLE_CALL'?<Tab eventKey="foulLanguage" title="FOUL LANGUAGE"> 
                                        {<FoulLanguage foulLanguage={foulLanguage} tabSelected={key}  loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>}
                                        </Tab>:""}                                      
                                        {typeOfCall=='SINGLE_CALL'?<Tab eventKey="actions" title="ACTIONS">
                                        {<Actions callAction={callAction} tabSelected={key} loader={loader} callId={params} customerName={customerName} sellerName={sellerName} fileName={fileName} agentExternalId={historyObject?.agentExternalId} typeOfCall={typeOfCall} likeDislikeData={likeDislikeData}/>}
                                        </Tab>:""}
                                         {/* {typeOfCall=='SINGLE_CALL'?<Tab eventKey="signals" title="SIGNALS">
                                        {callSignals!==''&&<Signals  type='chat'callSignals={callSignals} tabSelected={key} customerName={customerName} sellerName={sellerName} loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>}
                                        </Tab>:""} */}
                                        <Tab eventKey="feedback" title="FEEDBACK">
                                        {<Feedbacks type='chat' callFeedback={callFeedback} loader={loader} agentExternalId={historyObject?.agentExternalId} typeOfCall={typeOfCall}/>}
                                        </Tab>
                                        {typeOfCall == "GROUP" || typeOfCall == "GROUP_CALL" ? (
                                        <Tab eventKey="chats" title="CHATS">
                                          <Chats callDetail={callDetail} loader={loader} setKey={setKey}/>
                                        </Tab>):("")}
                                    </Tabs>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        {
          // customerName&&sellerName!==[]&&
            <MoreInfo show={show} setShow={setShow} closeButton={closeButton} customerName={historyObject?.customer} sellerName={historyObject?.seller} talkDuration={historyObject?.duration} date={historyObject?. date} url={historyObject?.url} sellerId={historyObject?.id} coeName={historyObject?.coe}/>}
        </div>
    )
}

export default ChatDetail