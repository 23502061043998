import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import MyTeam from "./MyTeam";
import profileService from "../Services/profile.service";
import axios from "axios";
import './profile.css';
import UserDetailModal from "../Commons/Modal/UserDetailsModal";
import EditProfile from "./EditProfile";
import toast from '../Toast/toaster'
import { history } from "../../redux-container/store";
import Loading from '../Commons/Loading';

function Account(props) {
    const [lgShow, setLgShow] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [callerBtn, setCaller] = useState(false);
    const closeButton = () => setLgShow(false);
    const [managerData, setManagerData]=useState( {
        id:'',
    firstName:'',
    lastName:'',
    email:'',
    userPhoneNumbers:[],
    secEmail:[],
    managerId:'',
    manager:'',
    designation:'',
    role:'',
    caller : '',
    coe:[],
    status:''
});

    const getManagerData = (managerIndex) => {      
        profileService.managerProfile(props.profileData.managerId[managerIndex]).then((response) => {
            setLgShow(true);           
            let res=response.data.data;
            setManagerData({
              id:res.id,
              firstName:response.data.data.firstName,
              lastName:res.lastName,
              email:res.email,
              userPhoneNumbers:[res.userPhoneNumbers],
              secEmail:[res.secondaryEmails],
              managerId:res.managerId,    
              manager:res.managerName,
              designation:res.designation,
              role:res.role,
              caller : res.caller,
              coe:[res.clientCoes],
              status:response.data.status
  
            })
           
        }).catch((error) => {
            toast.error(error);
        });
        
    }
 
    return (
        <>
         {props.loading ? <div className="loader-container"><Loading variant="dark"/></div> :
        <div id="myprofiledetails" className="mt-3">
            {
                editProfile ? <EditProfile setEditProfile={setEditProfile} profileData={props.profileData} setProfileData ={props.setProfileData}/> :
                <>
                <div className="profile-container" id="my-profile-content">
                    <div className="d-flex top-fixed-box">
                        <div className=" mb-3 text-sm-left">
                            <h5 className="account-name account-id m-0" >{props.profileData.firstName} {props.profileData.lastName}</h5>
                            <div className="d-flex align-items-center lh-1">
                                <div>
                                    <p className="font-12 mb-0 account-emp-id text-primary-gray text-start">Employee Id: {props.profileData.id}</p>
                                </div>
                                {
                                    props.profileData.caller ?   
                                     <span className="caller-badge btn-warning position-relative ms-lg-1"> 
                                    <i className="bx bx-phone-call text-dark align-middle"></i>Caller
                                    </span> : null
                                }
                             
                            </div>	
                        </div>
                   
                        <div className="moments-email-btn mb-3 text-sm-right" role="presentation">
                            <button id="edit-profile" className="btn account-edit-btn edit-account" data-bs-toggle="tab" type="button"
                                role="tab" aria-selected="false" onClick={() => { setEditProfile(true) }}>Update Profile </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="d-flex flex-column align-items-center text-center">
                            <div className="profile-letter">
                                
                                <h3>{(props.profileData.firstName).charAt(0)}{(props.profileData.lastName).charAt(0)}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-secondary mb-1 account-email-id">{props.profileData.email}</p>
                            </div>
                        </div>
                        <div className="row w-100 m-0">
                            <div className="col-6 ps-0">
                                <ul className="list-group list-group-flush account-list my-2">
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='123'>
                                        <h6 className="mb-0 "><i className="lni lni-mobile"></i>Mobile Number</h6>
                                        <span className="text-secondary">
                                        {
                                             props.profileData.userPhoneNumbers.map((num)=>(  
                                                num.map(numList=>(
                                                   
                                                       numList.type== "MOBILE" ?  numList.mobileNumber:null 
                                                   
                                                )) 
                                             )) 
                                        }
                                         </span> 
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='124'>
                                        <h6 className="mb-0"><i className="lni lni-phone-set"></i>Phone Number</h6>
                                        <span className="text-secondary">
                                        {
                                            props.profileData.userPhoneNumbers.map((pNum)=>(  
                                                   pNum.map(pNumList=>(
                                                     
                                                      pNumList.type== "LANDLINE" ?  pNumList.mobileNumber:null 
                                                     
                                                   )) 
                                                )) 
                                           
                                        }
                                          </span> 
                                       
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='3'>
                                        <h6 className="mb-0"><i className="lni lni-envelope"></i>Secondary Email </h6>
                                        {
                                             props.profileData.secEmail.map((emailList)=>(
                                                 emailList.map(secList=>(
                                                     <span className="text-secondary"> {secList.email} </span>
                                                 ))
                                                
                                             ))
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 pe-0">
                                <ul className="list-group list-group-flush account-list my-2">
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='4'>
                                        <h6 className="mb-0 "><i className="fadeIn animated bx bx-user-plus"></i>Manager</h6>
                                        <span className="text-primary-blue">
                                            {props.profileData.manager && props.profileData.manager?.map( (manager, index) => 
                                                <p style={{margin:'0px'}}><a type="button" onClick={()=> getManagerData(index)}>{manager}</a></p>
                                            )}
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='5'>
                                        <h6 className="mb-0"><i className="lni lni-briefcase"></i>Designation</h6>
                                        <span className="text-secondary">{props.profileData.designation?props.profileData.designation:"NA"}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='6'>
                                        <h6 className="mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-key key-role"><path
                                                d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                            </svg>
                                            Role
                                        </h6>
                                        <span className="text-secondary">{props.profileData.role?props.profileData.role:"NA"}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 pe-0">
                                <ul className="list-group list-group-flush account-list my-2 w-50 mx-auto">
                                {
                                             props.profileData.coe.map((coeList)=>(
                                                coeList.map(coesList=>(
                                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key={coesList.name}>
                                                    <h6 className="mb-0"><i className="lni lni-users"></i>COE </h6>
                                                     <span className="text-secondary">{coesList.name}</span>
                                                      </li>
                                                 ))
                                                
                                             ))
                                        }
                                   
                                </ul>
                            </div>
                       
                        </div>
                    </div>
                </div>
                <UserDetailModal lgShow={lgShow} closeButton={closeButton} setLgShow={setLgShow} managerRecords={managerData} 
                  /> 
                </>

            }
          
        </div>
         }
        </>
    );
}
export default Account;