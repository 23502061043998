import { apiCall } from "./Interceptor";

const CREATE_FOLDER = "odio/api/folder/create";
const GET_FOLDER_LIST = "odio/api/folder/get"
const GET_DOCUMENT_LIST ='odio/api/folder/document/list'
const CREATE_DOCUMENT = 'odio/api/folder/document/create'
const DELETE_DOCUMENT ='/odio/api/folder/document/delete'
const UPLOAD_FILE ='/odio/api/folder/document/content/upload'
const FOLDER_UPDATE ='odio/api/folder/update/'
const DELETE_FOLDER ='odio/api/folder/delete/'
const UPLOAD_IMAGE = '/odio/api/folder/document/upload/feature/image'
const UPDATE_DOCUMENT = '/odio/api/folder/document/update';
const SAVE_TO_DRAFT_DOCUMENT ='/odio/api/folder/document/saveToDraft';
const GET_DOCUMENT_DETAILS_BY_FOLDERID_DOCID = '/odio/api/folder/document';
const UPLOAD_DOCUMENT_TEAMPLATE = '/odio/api/folder/document/create/template';
const GET_FOLDER_SUGGESTION_LIST = "odio/api/folder/search";
const GET_QUESTION_FOLDER_SUGGESTION_LIST = "/odio/api/questionbank/search";


export const getFolderList = (reqBody,currentPage, pageSize) => {
    if(!currentPage && !pageSize){
        const response = apiCall.post(`${GET_FOLDER_LIST}`, reqBody)
        return response
    }else{
        const response = apiCall.post(`${GET_FOLDER_LIST}?page=${currentPage}&size=${pageSize}`, reqBody)
        return response
    }
}
export const getFolderSuggestionList = (reqBody, tab) => {
        const response = apiCall.post(`${tab == "qbank" ? GET_QUESTION_FOLDER_SUGGESTION_LIST : GET_FOLDER_SUGGESTION_LIST}`, reqBody)
        return response;
}

export const createFolder = (reqBody) => {
    const response = apiCall.post(CREATE_FOLDER, reqBody)
    return response
}

export const updateFolder =(reqBody,id)=>{
    const response = apiCall.put(FOLDER_UPDATE+id, reqBody)
    return response
}

export const getDocumentList = (reqBody)=>{
    const response = apiCall.post(`${GET_DOCUMENT_LIST}`, reqBody)
    return response
}

export const createDocuments = (reqBody,folderId) => {
    const response = apiCall.post(`${CREATE_DOCUMENT}?folderId=${folderId}`, reqBody)
    return response
}

export const deleteDocument =(id,folderId)=>{
    const response = apiCall.delete(DELETE_DOCUMENT+'/'+folderId +'/'+id)
    return response
}

export const deleteFolder =(id)=>{
    const response = apiCall.delete(DELETE_FOLDER+id)
    return response
}

export const uploadFile =(reqBody,title,mimeType)=>{
    const response = apiCall.post(`${UPLOAD_FILE}?content_title=${title}&mime-type=${mimeType}`,reqBody,{
        headers: {
          'Content-Type': 'multipart/form-data',  
        }
    })
    return response
}

export const uploadImage = (reqBody) => {
    const response = apiCall.post(UPLOAD_IMAGE, reqBody)
    return response
}

export const updateDocument = (reqBody,folderId,docId) => {
    const response = apiCall.put(`${UPDATE_DOCUMENT}/${docId}?folderId=${folderId}`, reqBody)
    return response
}

export const saveToDraft = (reqBody,folderId,docId)=>{
    const response = apiCall.put(`${SAVE_TO_DRAFT_DOCUMENT}/${docId}?folderId=${folderId}`, reqBody)
    return response
}

export const getDocumentDetailsByFolderIdDocId = (folderId,docId) => {
    const response = apiCall.get(`${GET_DOCUMENT_DETAILS_BY_FOLDERID_DOCID}/${folderId}/${docId}`)
    return response
}

export const uploadDocumentTemplate = (folderId, reqBody) => {
    const response = apiCall.post(`${UPLOAD_DOCUMENT_TEAMPLATE}?folderId=${folderId}`, reqBody)
    return response
}