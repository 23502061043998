import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  FormControl,
  Stack,
  Checkbox,
  Typography,
} from "@mui/joy";
import { Box, Tab, InputAdornment } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Button from "@mui/joy/Button";
import "../CoachingDashboard/coachingDashboard.css";
import SearchIcon from "@mui/icons-material/Search";
import EditableAccordion from "./EditableAccordian";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QuizIcon from "@mui/icons-material/Quiz";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import sessionService from "../../Services/session.service";

// const sessions = [
//   { id: 1, name: "Dailer", type: "docx", date: "10 Aug 2024" },
//   { id: 2, name: "Call Opening", type: "docx", date: "10 Aug 2024" },
//   { id: 3, name: "Objection Handling", type: "docx", date: "10 Aug 2024" },
//   { id: 4, name: "Assertiveness", type: "docx", date: "10 Aug 2024" },
//   { id: 5, name: "Rebuttal", type: "docx", date: "10 Aug 2024" },
// ];
// const questionset = [
//   {
//     id: 1,
//     name: "Question 1",
//     type: "mcq",
//     date: "12 Aug 2024",
//     multiple: true,
//     question: `Which of the following is not a benefit of Expert Systems?`,
//   },
//   {
//     id: 2,
//     name: "Question 2",
//     type: "mcq",
//     date: "12 Aug 2024",
//     multiple: true,
//     question: `How should you respond if a customer is angry and raises their voice?`,
//   },
//   {
//     id: 3,
//     name: "Question 3",
//     type: "mcq",
//     date: "12 Aug 2024",
//     multiple: true,
//     question: `How should you handle conflicting opinions assertively?`,
//   },
//   {
//     id: 4,
//     name: "Question set 4",
//     type: "mcq",
//     date: "12 Aug 2024",
//     multiple: true,
//     question: `What are some phrases that reflect assertiveness in a conversation?`,
//   },
// ];

function CreateModules({ momentIds, session }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [expandedSession, setExpandedSession] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [accordions, setAccordions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [tabValue, setTabValue] = React.useState("1");
  const [checkboxDisabled, setCheckboxDisabled] = useState(true); //Make checkbox disabled or enabled
  const [sessions, setSessions] = useState(null);
  const [questionset, setQuestionset] = useState(null);
  const addAccordion = () => {
    setAccordions([
      ...accordions,
      {
        name: `Module ${accordions?.length + 1}`,
        documents: [],
        questions: [],
      },
    ]);
  };
  const getDocumentList = async () => {
    return await sessionService.getDocumentList(momentIds).then((res) => {
      if (res?.data.status === 0) {
        setSessions(res?.data.data);
      }
    });
  };
  const getQuestionList = async () => {
    return await sessionService.getQuestionList(momentIds).then((res) => {
      if (res?.data.status === 0) {
        setQuestionset(res?.data.data);
      }
    });
  };
  useEffect(() => {
    getDocumentList();
    getQuestionList();
  }, []);

  const updateAccordionTitle = (index, newTitle) => {
    const updatedAccordions = [...accordions];
    updatedAccordions[index].name = newTitle;
    setAccordions(updatedAccordions);
  };
  useEffect(() => {
    if (expandedAccordion !== null) {
      setCheckboxDisabled(false);
      const obj = accordions?.[expandedAccordion];
      setSelectedSessions(obj?.documents);
      setSelectedQuestions(obj?.questions);
    } else {
      setCheckboxDisabled(true);
    }
  }, [expandedAccordion]);

  const handleAccordionToggle = (index) => {
    setExpandedAccordion(expandedAccordion === index ? null : index);
  };

  const handleQuestionToggle = (questionId) => {
    setSelectedQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((q) => q !== questionId);
      } else {
        return [...prev, questionId];
      }
    });
  };

  useEffect(() => {
    if (accordions && expandedAccordion !== null) {
      const updatedAccordion = accordions.map((accordion, index) =>
        index === expandedAccordion
          ? { ...accordion, documents: selectedSessions }
          : accordion
      );
      setAccordions(updatedAccordion);
    }
  }, [selectedSessions]);

  useEffect(() => {
    if (accordions && expandedAccordion !== null) {
      const updatedAccordion = accordions.map((accordion, index) =>
        index === expandedAccordion
          ? { ...accordion, questions: selectedQuestions }
          : accordion
      );
      setAccordions(updatedAccordion);
    }
  }, [selectedQuestions]);

  const handleSessionToggle = (id, multiple) => {
    if (selectedSessions.includes(id)) {
      setSelectedSessions(
        selectedSessions.filter((sessionId) => sessionId !== id)
      );
      if (multiple) setExpandedSession(null);
    } else {
      setSelectedSessions([...selectedSessions, id]);
      if (multiple) setExpandedSession(id);
    }

    // if (expandedAccordion !== null) {
    //   // Update the active accordion's session data
    //   const selectedSession = sessions.find((session) => session.id === id);
    //   const updatedAccordions = [...accordions];
    //   updatedAccordions[expandedAccordion].documents.push(selectedSession);
    //   if (selectedSession.type == "mcq") {
    //     updatedAccordions[expandedAccordion].questions = selectedQuestions;
    //   }
    //   setAccordions(updatedAccordions);
    // }
  };

  const filteredSessions = sessions?.filter(session => session.status === 'PUBLISH')?.filter((session) =>
    session.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredQuestions = questionset?.filter((question) =>
    question.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  return (
    <>
      <div className="coaching-input-container">
        <div className="filter_scroll p-4" style={{ flex: 3 }}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box
                className="d-flex justify-content-between"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <TabList
                  aria-label="lab API tabs example"
                  onChange={handleChange}
                >
                  <Tab label="Document" value="1" />
                  <Tab label="Assignment" value="2" />
                </TabList>
                <TextField
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{
                    ml: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "2.3rem",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <TabPanel value="1">
                <div>
                  <h5>Choose a document</h5>
                  <h6 style={{ color: "#9e9e9e" }}>
                    {selectedSessions?.length} selected
                  </h6>
                  <hr />
                </div>
                <FormControl className="d-flex" style={{ gap: "10px" }}>
                  {filteredSessions?.map((ses) => (
                    <div key={ses.id}>
                      <Checkbox
                        key={ses.id}
                        checked={selectedSessions.includes(ses.id)}
                        disabled={checkboxDisabled}
                        onChange={() =>
                          handleSessionToggle(ses.id, ses.multiple)
                        }
                        sx={{ height: "2rem", marginLeft: "10px" }}
                        label={
                          <Box display="flex" alignItems="center">
                            <SummarizeIcon
                              sx={{
                                color: "#1976d2",
                                marginRight: "0.2rem",
                                marginLeft: "0.5rem",
                              }}
                            />
                            <Typography sx={{ fontWeight: "md" }}>
                              {ses.title} |{" "}
                              <Typography className="due-session">
                                {ses.date}
                              </Typography>
                            </Typography>
                          </Box>
                        }
                      />
                    </div>
                  ))}
                </FormControl>
              </TabPanel>
              <TabPanel value="2">
                <div>
                  <h5>Choose a questions</h5>
                  <h6 style={{ color: "#9e9e9e" }}>
                    {selectedQuestions.length} selected
                  </h6>
                  <hr />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <Box
                    sx={{
                      ml: 4,
                      marginLeft: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {filteredQuestions?.map((question, index) => (
                      <Checkbox
                        key={question.id}
                        disabled={checkboxDisabled}
                        checked={selectedQuestions.includes(question.id)}
                        onChange={() => handleQuestionToggle(question.id)}
                        label={<Typography>{question.question}</Typography>}
                        sx={{
                          "& .MuiCheckbox-checkbox": { marginTop: "0rem" },
                          height: "3rem",
                        }}
                      />
                    ))}
                  </Box>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        <div className="filter_scroll p-4" style={{ flex: 1.5 }}>
          <DialogTitle>Modules</DialogTitle>
          <hr style={{ margin: "1rem 0" }} />
          <Stack spacing={2} width="320px">
            {accordions.map((accordion, index) => (
              <EditableAccordion
                key={index}
                initialTitle={accordion.name}
                isExpanded={expandedAccordion === index} // Control expansion
                onAccordionToggle={() => handleAccordionToggle(index)} // Handle toggle
                updateTitle={(newTitle) =>
                  updateAccordionTitle(index, newTitle)
                }
                accordions={accordions}
                index={index}
                session={session}
                setSelectedQuestions={setSelectedQuestions}
                filteredQuestions={filteredQuestions}
                setSelectedSessions={setSelectedSessions}
                filteredSessions={filteredSessions}
                selectedQuestions={selectedQuestions}
                selectedSessions={selectedSessions}
                setAccordions={setAccordions}
              />
            ))}
          </Stack>
          <Button onClick={addAccordion} style={{ marginTop: "1rem" }}>
            Add Module
          </Button>
        </div>
      </div>
      <hr
        style={{ margin: "0", border: "1px solid #636b74", opacity: "0.1" }}
      />
    </>
  );
}

export default CreateModules;
