import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Button } from 'react-bootstrap';
import background from '../../assets/images/login-images/reset-password.png';
import odio_logo_blue from '../../assets/images/odio_logo_blue.png';
import logo from '../../assets/images/logoIcon.png';
import './login.css';

export default function ResetPassword() {
    const history = useHistory();
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [inputs, setInputs] = useState({ newPassword: '', confirmPassword: '' });
    const [formError, setFormErrors] = useState({});
    const [message, setMessage] = useState('');
    const { newPassword, confirmPassword } = inputs;
    const errors = {};

    useEffect(() => {
        document.title = "Reset Password - Odio";
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const validate = (values) => {
        const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

        if (!values.newPassword) {
            errors.newPassword = "Enter your new password.";
        } else if (!regex.test(values.newPassword)) {
            errors.newPassword = "This is not a valid password format.";
        } else if (values.newPassword.length < 8) {
            errors.newPassword = "Password must be more than 8 characters.";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "Renter your new password.";
        } else if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = "Password and confirm password are not the same.";
        }

        return errors;
    }

    const location = useLocation();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [pshow, setpshow] = useState(false);
    const [cpshow, setcpshow] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const tokenFromParam = queryParams.get('token');
        const emailFromParam = queryParams.get('email');
        const setIsFirstLoginFromParam = queryParams.get('isFirstLogin');

        setEmail(emailFromParam);
        setToken(tokenFromParam);
        setIsFirstLogin(setIsFirstLoginFromParam);
    }, [location]);

    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
        setpshow(!pshow);
    };

    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
        setcpshow(!cpshow);
    };

    function handleSubmit(e) {
        e.preventDefault();
        setFormErrors(validate(inputs));

        if (Object.keys(errors).length === 0) {
            if (isFirstLogin) {
                // Add your API call for save password
            } else {
                // Add your API call for reset password
            }
        }
    }

    function backToLogin() {
        history.push("./login");
    }

    function handleFocusPass() {
        formError.newPassword = "";
    }

    function handleFocusCPass() {
        formError.confirmPassword = "";
    }

    return (
        <div className="login-container">
            <div className="welcome-section">
                <div className="logo-container">
                    <img src={logo} alt="log" className="logo" />
                    <img src={odio_logo_blue} alt="Odio Logo" className="logo" />
                </div>
                <div className="welcome-text">
                    <p className='welcome-message'>Nice to see you again</p>
                    <h1 className='welcome-back'>WELCOME BACK</h1>
                    <div className="slash"></div>
                    <p className='welcome-tagline'>Transform Customer Interactions with our AI-Driven Conversational Intelligence.</p>
                </div>
            </div>
            <div className="login-section">
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type={newPasswordShown ? "text" : "password"}
                            name="newPassword"
                            value={newPassword}
                            onFocus={handleFocusPass}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter New Password"
                            required
                            style={{ height: '50px' }}
                        />
                        <button type="button" onClick={toggleNewPassword} className="toggle-password-btn">
                            {pshow ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
                        </button>
                        <p style={{ color: "red", height: "18px" }} className='error-message'>
                            {formError.newPassword}
                        </p>
                    </div>
                    <div className="input-group">
                        <input
                            type={confirmPasswordShown ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onFocus={handleFocusCPass}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Confirm Password"
                            required
                        />
                        <button type="button" onClick={toggleConfirmPassword} className="toggle-password-btn">
                            {cpshow ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
                        </button>
                        <p style={{ color: "red", height: "18px" }} className='error-message'>
                            {formError.confirmPassword}
                        </p>
                    </div>
                    <button type="submit" className="btn btn-submit">Change Password</button>
                    <button className="btn btn-light btn-lg" onClick={backToLogin} style={{ color: '#0a58ca' }}>
                        <i className="bx bx-arrow-back me-1" /> Back to Login
                    </button>
                </form>
            </div>
            <footer className="bg-white shadow-sm border-top p-2 text-center fixed-bottom">
                <p className="mb-0">Copyright © 2021 - VoiceIO Artificial Intelligence Pvt Ltd</p>
            </footer>
        </div>
    );
}
