import React,{useEffect,useState} from 'react'
import ReactApexChart from "react-apexcharts"
import Loading from '../../Commons/Loading'
import NodataFound from '../../Commons/NoDataFound';
import Pagination from '../../Commons/Table/Pagination';
import '../Insight.css'
import { useHistory } from "react-router-dom";

export default function ConversationMetricsGraph(props){
  let PageSize = 10;
  const pageCount = props.convData?(props.convData?.length/PageSize):0
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [data, setData] = useState([])
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  let rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))
  const history = useHistory()

  useEffect(()=>{     
      setData(props.convData?props.convData.slice(0,10): [])
      setPageIndex(0)
      setCurrentPages(pageCount)
  },[props.convData])

  const sendTocalls = (data, name) => {
    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    let poorCallRange = props?.convData.find((user)=> user.agentName == name)?.poorCallRange
    let meaningCallRange = props?.convData.find((user)=> user.agentName == name)?.meaningfulCallsRange
    let qualityCallRange = props?.convData.find((user)=> user.agentName == name)?.qualityCallsRange
    let key = data ==  "Poor Calls" ? `${poorCallRange.start}-${poorCallRange.end}` : data == "Meaningful Calls" ? `${meaningCallRange.start}-${meaningCallRange.end}` : data == "Quality Calls" ? `${qualityCallRange.start}-${qualityCallRange.end}` : `0-100`
    let value = props?.convData.find((user)=>user.agentName == name)?.userId
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": 1,
        "fromD": storedData?.fromD,
        "toD": storedData?.toD,
        "selectedDate": storedData?.selectedDate,
        // "selectedRange": storedData?.selectedDate === "This Year" ? "7" : '5',
        "selectedRange": storedData?.selectedRange,
        "selectedCoeRange": storedData?.selectedCoeRange,
        "selectedCOE":storedData?.selectedCOE,
        "coe":storedData?.coe
      }))
    history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?callScore=${key}&fromD=${storedData?.fromD}&toD=${storedData?.toD}` : `/sales_conversations?callScore=${key}&fromD=${storedData?.fromD}&toD=${storedData?.toD}`,{"employeeId":value})
  }

  const nextPage = () =>{
    if(pageIndex < pageCount-1){
     let a = (pageIndex+1)*PageSize;
     setData(props.convData.slice(a,a+10))
     setCanPreviousPage(true)
    }
    else{
      setCanNextPage(false);
      setPageIndex(pageIndex+1)
    }
  }

  const previousPage = ()=>{
    if(pageIndex > 0){
      let a = (pageIndex)*PageSize;
      setData(props.convData.slice(a-10,a))
      setCanNextPage(true)
      }
      else{
        setCanPreviousPage(false);
      }
  }

  const gotoPage = (val) =>{
    let a = val*PageSize;
    setPageIndex(val)
    setData(props.convData.slice(a,a+10))
  }

  const changePageIndex = (val) =>{
    let a = val*PageSize;
    setPageIndex(val)
    setData(props.convData.slice(a,a+10))
  }

  let names = data?.map(item=>{
    return item.agentName
  })
  let totalCall = data?.map(item=>{
    return item.totalCalls
  })
  let poorCall = data?.map(item=>{
    return item.poorCalls
  })
  let meaningfulCall = data?.map(item=>{
    return item.meaningfulCalls
  })
  let qualityCall = data?.map(item=>{
    return item.qualityCalls
  })

  const series = [{
      name: 'Total Call',
      data: totalCall,
    },
    {
      name: 'Poor Calls',
      data: poorCall,
    },
    {
      name: 'Meaningful Calls',
      data: meaningfulCall,
    },
    {
      name: 'Quality Calls',
      data: qualityCall,
    }
    ]

    const options = {
      chart: {
        height: 200,
        type: 'heatmap',
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            sendTocalls(config.w.config.series[config.seriesIndex].name,config.w.config.labels[config.dataPointIndex])
          }
        }
      },
      plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: true, 
          }
        },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000'],
          font: '12px'
        }
      },
      labels: names,
          
      colors: ["#ffc107"],
      xaxis: {
          type: 'categories',
          tickPlacement: 'between',
        },
      title: {
        text: ''
      },
    }

    return(
      <>
      {series[0]?.data?.length>0||series[1]?.data?.length>0||series[2]?.data?.length>0||series[3]?.data?.length>0?
      (<div className="p-4">
        <ReactApexChart type={'heatmap'} options={options} series={series} height="500"/>
        <Pagination
          className="pagination-bar mb-1"
          currentPage={currentPage}
          totalCount={props?.convData?.length}
          pageSize={PageSize}
          pageIndex={pageIndex}
          currentPages={currentPages}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          handlePageIndex={changePageIndex}
        /> 
      </div>):<NodataFound/>
      }
      </>
    )}