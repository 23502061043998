import * as React from 'react'
import { useState,useEffect } from "react";
import CommentOnModal from "../../Conversations_Dashboard/Sales/Modals/CommentOnModal";
import ViewParameters from "../../Conversations_Dashboard/Sales/Modals/ViewParameters";
import Loading from "../Loading";
import EmailTranscript from "../../Conversations_Dashboard/Sales/Modals/EmailTranscript";
import { apiCall } from "../../Services/Interceptor";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import toaster from "../../../Components/Toast/toaster";
import notificationsService from '../../Services/notifications.service'
import NodataFound from '../NoDataFound';

export default function TeamStats(props) {
  const [show, setShow] = useState(false)
  const [loader,setLoader] = useState()
  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [infoShow, setInfoShow] = useState(false)
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [idClicked, setId] = useState("")
  const [stats, setStats] = useState("")
  const [commentData, setCommentData] = useState([])
  const [likeDislike, setLikeDislike] = useState([])
  
  const closeButton=()=>{
    setShow(false)
    setEmailShow(false)
    setCommentShow(false)
    setInfoShow(false)
  }
  const handleClick=()=>{
    setShow(true)
  }

  const conv=(d)=>{
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : "";
    var mDisplay = m > 0 ? m : "";
    var sDisplay = s > 0 ? s : "";
    return (
        <>
        {h>0?<span>
          {hDisplay}
          <span style={{fontSize:"10px"}}>{h == 1 ? " hr " : " hrs "}</span>
        </span>:""} 
        {m>0?<span>
          {mDisplay}
          <span style={{fontSize:"10px"}}>{m == 1 ? " min " : " mins "}</span>
        </span>:""}
        {s>0?<span>
          {sDisplay}
          <span style={{fontSize:"10px"}}>{s == 1 ? " sec" : " secs"}</span>
        </span>:""}
        </>
    )
    // const totalMinutes = Math.floor(totalSeconds / 60);

    // const seconds = totalSeconds % 60;
    // const hours = Math.floor(totalMinutes / 60);
    // const minutes = totalMinutes % 60;

    // return `${hours}hrs${minutes}mins${seconds}sec`;
}


  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.team?.agentDetails?.stats

  const callback = (e)=>{
    setCommentData(e)
  }

  let cardd = commentData.length>0?userDetail.map(item=>{
    let data = Object.entries(commentData[0]).filter((d)=>{return d.includes(item.value)})[0]
    return {label:item.displayName, value:data!=undefined&&data[1]!=null?data[1]:0,desc:item?.description}
  }):
  props.teamStats[0]&&userDetail.map(item=>{
    let data = Object.entries(props.teamStats[0]).filter((d)=>{return d.includes(item.value)})[0]
    return {label:item.displayName, value:data!=undefined&&data[1]!=null?data[0]=='totalDuration'?conv(data[1]):data[1]:0, desc:item?.description}
  })
 
  const likeButtonHandler = async(id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={
      "callId": props?.userId,
      "commentType": "STATS",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    const response = await apiCall.post("/odio/api/comment/Likes/dislikes",value)
    if(response)
    {
      const response = await apiCall.get(`/odio/api/comment/get-likeAndDislike?callId=${props?.userId}&commentType=STATS`)
      setLikeDislike(response.data.data)
      setLikeButton(false)
    }
    let notificationBody = [{
      "callId":null,
      "userExternalId":props?.externalId,
      "mobileNumber":props?.phone,
      "notificationEvent":{
        "eventType":"LIKE",
				"tabName":"teamStats",
				"tabValue":label,
				"postId":id,
      }
    }]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res.data.message)
      }
    })
  }

  const dislikeButtonHandler = async(id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
        "callId": props?.userId,
        "commentType": "STATS",
        "dislike": 0,
        "like": null,
        "postId": id
    }
    const response = await apiCall.post("/odio/api/comment/Likes/dislikes",value)
    if(response)
    {
      const response = await apiCall.get(`/odio/api/comment/get-likeAndDislike?callId=${props?.userId}&commentType=STATS`)
      setLikeDislike(response.data.data)
      setDislikeButton(false)
    }

    let notificationBody = [{
			"callId":null,
			"userExternalId":props?.externalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"teamStats",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res.data.message)
      }
    })
    
  }
  const commentButtonHandler = (id,label)=>{
    // setCommentButton(true)
    setCommentShow(true)
    setId(id)
    setStats(label)
  }
  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }

  const setEmailFalse = ()=>{
    setShow(false)
  }  

  const popoverr =(desc)=> (
    <Popover id="popover-trigger-hover-focus" title="right">
      <h3 className="popover-header" style={{fontSize:"14px",textAlign:"center"}}>{desc}</h3>
    </Popover>
  )

  const iconsFunc = (item,id)=>{
    let transNew = Object.entries(commentData[0]?commentData[0]?.postTypeComments: props?.teamStats[0]?.postTypeComments).filter(val=>{
      return(val[1][0].postId==id)
    })
    let ids = transNew.length>0?transNew[0][1]:''
  
    let likeIcon = likeDislike.filter(item=>{
      return(item.postId==id)
    })
    
    return(
      <>
        <div>
          {item.label}
          <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverr(item?.desc)} rootClose>
            <button className="mb-0 ms-0" style={{border:"none",backgroundColor:"white",marginTop:"1px"}}>
              <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
            </button>
          </OverlayTrigger>
          <div class="list-after-click-group">
          {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
          likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
          {ids&&ids[0].postId==id||commentButton&&idClicked==id?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(id,item.label)} ><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
          </div>
        </div>
        <div className="text-end">
          <span className="badge btn btn-outline-success font-normal font-12 rounded-pill">
            {item.value}
          </span>
          <div className="list-hover-input-group">
            {likeIcon.length>0&&likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id||dislikeButton&&dislikeId==id? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(id,item?.label)}>
                <span className="text-primary-blue">
                  <i className="bx bx-like"></i>
                </span>
            </button>}
            {likeIcon.length>0&&likeIcon[0].dislike!==null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id||likeButton&&likeId==id ? ""
            :
            <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id,item.label)}>
              <span className="text-primary-blue">
                <i className="bx bx-dislike"></i>
              </span>
            </button>}
            {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
              <span className="text-primary-blue">
                <i className="bx bx-mail-send"></i>
              </span>
            </button> */}
            {ids&&ids[0].postId==id||commentButton&&idClicked==id?'':  
            <button className="btn list-comment-before switcher-btn-1" onClick={()=>commentButtonHandler(id,item.label)}
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
              <span className="text-primary-blue">
                <i className="lni lni-comments-alt"></i>
              </span>
            </button>
            }
          </div>
        </div>
      </>
    )
  }

  useEffect(async()=>{
    if(props?.keySelected=='teamStats'||props?.tabRedirected=='teamStats'){
      const response = await apiCall.get(`/odio/api/comment/get-likeAndDislike?callId=${props?.userId}&commentType=STATS`)
      setLikeDislike(response.data.data)
    }
  },[props?.keySelected=='teamStats',props?.tabRedirected=='teamStats'])

  useEffect(()=>{
    if(props?.eventType=='COMMENT'&&props?.tabRedirected=='teamStats'){
      setCommentShow(true)
      setId(props?.postId)
    }
  },[])

  return (
    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) : props.teamStats.length > 0 ?
    <div className="tab-content py-3">
      <div className="tab-pane fade active show" role="tabpanel">
        <div className="d-flex justify-content-between ">
          <div className="stats-heading">
            <h5>
              Stats
              <a data-bs-toggle="modal">
                <i className="bx bx-git-compare ms-1 align-bottom text-dark"></i>
              </a>
            </h5>
          </div>
          <div className="moments-email-btn mb-3 text-sm-right">
            <a className="btn compose-mail-btn" onClick={handleClick}>Email Stats</a>
          </div>
        </div>
        <div className="moment-list-group-scroll stats-list-group-scroll" id="statspage">
          <ul className="list-group">
            {
              cardd.map((item,id)=>{
                return(
                <li className={`${id==props?.postId&&props?.tabRedirected=='teamStats'?'shadow':''} list-group-item d-flex justify-content-between total-duration-stats`}>

                  <>
                  {iconsFunc(item,id)}
                  <EmailTranscript emailShow={emailShow} setEmailShow={setEmailShow} closeButton={closeButton}/>  
                  {/* <CommentOnModal show={commentShow} setShow={setCommentShow} closeButton={closeButton}/> */}
                </>

                </li>
              )
            })}
            <hr/>
          </ul>
        </div>
      </div>
      {commentShow && idClicked!=='' && < CommentOnModal updateState={updateCommentState} show={commentShow} setShow={setCommentShow} closeButton={closeButton} tabSelected={props?.tabRedirected=='teamStats'?props?.tabRedirected:props.keySelected} statsId={idClicked} teamStats={stats} callId={props?.userId} callback={callback} agentExternalId={props?.externalId} phone={props?.phone}/>}
      <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} tabSelected={props.keySelected} callStats={cardd} userId={props?.userId} setEmailFalse={setEmailFalse} defaultFilterData={props.defaultFilterData} filteredData={props.filteredData}/>
    </div> : <NodataFound/>
    }</>
  );
}