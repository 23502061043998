import React from 'react'
import Loading from '../../Commons/Loading'
import Card from 'react-bootstrap/Card';
import NodataFound from '../../Commons/NoDataFound';

export const Summary = (props) => {
  let { callSummary, loader } = props;
  return (
    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (callSummary.length > 0 ?
        <>
          <div className="tab-pane" role="tabpanel">
            <div className="d-flex align-items-center feedback-headder my-2">
              <div className="feedback-heading">
                <h5>Summary</h5>
              </div>

            </div>
          </div>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <Card.Text>
                {callSummary}
              </Card.Text>
            </Card.Body>
          </Card>
        </> : <NodataFound />)
      }
    </>)
}
