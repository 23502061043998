import React, { useState } from "react";
//import SimpleTable from '../../Commons/Table/SimpleTable'
import ReactTable from "../../Commons/Table/ReactTable";
import { SETTINGS_COMPONENT_TYPE } from "../../../Constants/Enum";
import UserDetailModal from "../../Commons/Modal/UserDetailsModal";
import userService from "../../Services/user.service";
import { connect } from "react-redux";

function CoeUsersTable(props) {
  const [lgShow, setLgShow] = useState(false);
  const closeButton = () => setLgShow(false);
  const [managerData, setManagerData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    userPhoneNumbers: [],
    secEmail: [],
    managerId: "",
    manager: "",
    designation: "",
    role: "",
    caller: "",
    coe: [],
    status: "",
  });

  // const [callScoreColor, setCallScoreColor] = useState('');
  // const [pitchScoreColor, setPitchScoreColor] = useState('');

  const column = [
    {
      Header: "Employee Id ",
      accessor: "employeeId",
      sticky: "left",
    },
    {
      Header: "User",
      accessor: (d) => `${d.firstName} ${d.lastName}`,
      sticky: "left",
      Cell: (e) => (
        <a href="#" className="" onClick={() => getManagerData(e)}>
          {" "}
          {e.value}{" "}
        </a>
      ),
    },
    {
      Header: "Designation",
      accessor: (d) => `${d.email ? "-" : ""}`,
      width: 200,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Mobile",
      accessor: (d) =>
        `${
          d.userPhoneNumbers
            ? d.userPhoneNumbers.length > 0
              ? d.userPhoneNumbers[1]
                ? d.userPhoneNumbers[1].mobileNumber
                : "-"
              : "-"
            : "-"
        }`,
      width: 200,
    },
    {
      Header: "Caller",
      accessor: "caller",
      headerClassName: "text-center",
      Cell: (e) => (
        <p className="text-center mb-0">
          <i
            className={e.value ? "lni lni-checkmark text-primary-blue" : "-"}
          ></i>
        </p>
      ),
    },
    {
      Header: "Action",
      accessor: "Action",
      sticky: "right",
      disableSortBy: true,
    },
  ];

  const data = [
    {
      employee_id: "1234",
      user: "Mukesh Sharma",
      email: "example@gmail.com",
      caller_name: "lni lni-checkmark text-primary-blue",
      callscore: "80%",
      pitchscore: "80%",
    },
    {
      employee_id: "1234",
      user: "Mukesh Sharma",
      email: "example@gmail.com",
      caller_name: "",
      callscore: "80%",
      pitchscore: "80%",
    },
    {
      employee_id: "1234",
      user: "Mukesh Sharma",
      email: "example@gmail.com",
      caller_name: "lni lni-checkmark text-primary-blue",
      callscore: "80%",
      pitchscore: "80%",
    },
    {
      employee_id: "1234",
      user: "Mukesh Sharma",
      email: "example@gmail.com",
      caller_name: "",
      callscore: "80%",
      pitchscore: "80%",
    },
  ];

  const getManagerData = (e) => {
    setLgShow(true);
    let managerId = e.cell?.row?.original?.id;
    userService
      .getUsersProfile(managerId)
      .then((response) => {
        let res = response.data.data;
        setManagerData({
          id: res.id,
          firstName: response.data.data.firstName,
          lastName: res.lastName,
          email: res.email,
          userPhoneNumbers: [res.userPhoneNumbers],
          secEmail: [res.secondaryEmails],
          managerId: res.managerId,
          manager: res.managerName,
          designation: res.designation,
          role: res.role,
          caller: res.caller,
          coe: [res.clientCoes],
          status: response.data.status,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="table-responsive dataTable-style react-table">
      <ReactTable
        columns={column}
        componentType={SETTINGS_COMPONENT_TYPE.MANAGE_COE_USERS}
        data={props.coeUsersList}
        isDelete={true}
        isExcelPdfButton={true}
        coeId={props.id}
        pageIndex={props.pageIndex}
      />
      <UserDetailModal
        lgShow={lgShow}
        closeButton={closeButton}
        setLgShow={setLgShow}
        managerRecords={managerData}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coeUsersList: state.manage_coe.coeUsersList,
  };
};

export default connect(mapStateToProps)(CoeUsersTable);
