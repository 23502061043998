import React, { useEffect, useRef, useState } from "react";
import "./knowledgeBase.css";
import { Typography, IconButton, Tooltip } from "@mui/material";
import { Button, Input } from '@mui/joy';
import { useHistory } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import CommonSwitchCase from "../../Commons/CommonSwitchCase";
import { SETTINGS_COMPONENT_TYPE } from "../../../Constants/Enum";
import Loading from "../../Commons/Loading";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InfiniteScroll from 'react-infinite-scroll-component';
import BubbleLoader from '../../Commons/BubbleLoader';
import { USER_DETAIL } from "../../../Constants/constant";
import DeleteConfirmationModal from '../../Commons/DeleteConfirmationModal';
import toaster from "../../Toast/toaster";

const FolderAccordianList = ({
    accordions,
    handleCreateFolder,
    handleNameChange,
    handleSaveClick,
    handleDeleteClick,
    setAccordions,
    editedFolderName,
    setEditedFolderName,
    isOpen,
    setIsOpen,
    buttonValue,
    selectedRowData,
    resourceLoading,
    moreData,
    hasMoreData,
    scroll,
    setResetPage,
    setFolderDeleted,
    tabValue,
    handleCloseDeleteModal,
    setDelFolder
}) => {
    const history = useHistory();
    const inputRef = useRef(null);
    const [editingFolderId, setEditingFolderId] = useState(null);
    const { canRead, canDelete, canWrite, canUpdate } = JSON.parse(localStorage.getItem(USER_DETAIL))?.userPermissions;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                !event.target.closest('.tick-button') &&
                !event.target.closest('.cross-button')
            ) {
                if (editingFolderId !== null) {
                    setAccordions(
                        accordions.map((folder) => ({
                            ...folder,
                            isEditing: folder.id === editingFolderId ? false : folder.isEditing,
                        }))
                    );
                    setEditedFolderName('');
                    setEditingFolderId(null);
                }
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);
        return () => document.removeEventListener("pointerdown", handleClickOutside);
    }, [accordions, editingFolderId, setAccordions, setEditedFolderName]);

    const isTickButtonDisabled = (id) => {
        // return !editedFolderName[id] || editedFolderName[id].trim() === '';
        const folderName = editedFolderName[id]?.trim();
        return !folderName || folderName.length > 100;
    };

    const closeOpenEditBox = () => {
        setAccordions(
            accordions.map((folder) => ({
                ...folder,
                isEditing: false,
            }))
        );
        setEditedFolderName('');
        setEditingFolderId(null);
    };

    const handleEditClickWrapper = (id, originalName) => {
        closeOpenEditBox();

        setAccordions(
            accordions.map((folder) => ({
                ...folder,
                isEditing: folder.id === id,
            }))
        );

        setEditedFolderName({ [id]: originalName });
        setEditingFolderId(id);
    };


    const handleSaveClickWrapper = (id, e, type) => {
        if (type === 'tick' || type === 'blur') {
            const newFolderName = editedFolderName[id]?.trim();

            // Check for duplicate folder names
            const isDuplicateName = accordions.some(
                (folder) => folder.name.toLowerCase() === newFolderName.toLowerCase() && folder.id !== id
            );

            if (isDuplicateName) {
                toaster.error("Folder name already exists. Please use a unique name.");
                return; // Do not proceed if the name is duplicate
            }
            try {
                handleSaveClick(id, e);
                setEditedFolderName('');
                setAccordions(
                    accordions.map((folder) =>
                        folder.id === id ? { ...folder, isEditing: false } : folder
                    )
                );
                setEditingFolderId(null);
                toaster.success("Folder name updated successfully.");
            }
            catch (error) {
                toaster.error("Failed to folder name. Please try again.");
            }
        } else if (type === 'cross') {
            e.stopPropagation();
            setEditedFolderName('');
            setAccordions(
                accordions.map((folder) =>
                    folder.id === id ? { ...folder, isEditing: false } : folder
                )
            );
            setEditingFolderId(null);
        }
    };

    const deleteFolder = () => {
        setFolderDeleted(true)
        setResetPage(true);
    }

    const documentListRedirection = (folderId, folder) => {
        const folderData = { ...folder }
        history.push('/document_list', { folderData: folderData, tabValue });
        localStorage.setItem("folderId", folderId);
    }

    const getTimeAgo = (dateString) => {
        const inputDate = new Date(dateString);
        const now = new Date();

        const diffInMs = now - inputDate;
        const diffInSeconds = Math.floor(diffInMs / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInMonths / 12);

        if (diffInYears > 0) {
            return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
        } else if (diffInMonths > 0) {
            return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
        } else if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
        } else {
            return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
        }
    }

    return (
        <>
            {/* {isOpen
                ? CommonSwitchCase.switchCase(
                    setIsOpen,
                    SETTINGS_COMPONENT_TYPE.MANAGE_KNOWLEDGE_FOLDER,
                    buttonValue,
                    selectedRowData,
                    deleteFolder,
                )
                : ''} */}
            {isOpen && (
                <DeleteConfirmationModal
                    openDeleteModal={isOpen}
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    title='Delete Resource'
                    delBody='this resource'
                    setDelState={(val) =>
                        setDelFolder(true)
                    }
                    className={'delQBank'}
                />
            )}
            {resourceLoading ? <div className="loader-container"><Loading variant="dark" /></div> :
                <div className="folder-list">
                    <div className="search-input">
                        {canWrite && <Button onClick={handleCreateFolder}>Create Folder</Button>
                        }
                    </div>
                    <InfiniteScroll
                        dataLength={accordions.length}
                        next={moreData}
                        hasMore={hasMoreData}
                        loader={<div className="search-more" style={{ marginTop: '10px' }}>
                            {scroll && <BubbleLoader />}
                        </div>}
                    >
                        <div className="container" style={{ marginTop: '20px' }}>
                            <div className="resource-grid question-grid">
                                {accordions?.map((folder) => (
                                    <div className="resource-child question-child" key={folder.id}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="question-content" onClick={() => documentListRedirection(folder?.id, folder)}>
                                            <FolderOpenIcon sx={{ color: '#0b6bcb', fontSize: '60px' }} />
                                            {editingFolderId === folder.id ? (
                                                <div className="d-flex align-items-center" ref={inputRef} style={{ gap: '4px' }}>
                                                    <Input
                                                        type="text"
                                                        value={editedFolderName[folder?.id]}
                                                        onChange={(e) => handleNameChange(folder?.id, e.target.value)}
                                                        placeholder={folder.name}
                                                        onClick={(e) => e.stopPropagation()}
                                                    />
                                                    <IconButton onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleSaveClickWrapper(folder.id, e, 'tick')
                                                    }}
                                                        disabled={isTickButtonDisabled(folder.id)}
                                                        style={{
                                                            display: accordions?.find((folder, ind) => folder.id === editingFolderId)?.name === editedFolderName[folder?.id]
                                                                || isTickButtonDisabled(folder.id)
                                                                ? 'none' : 'block'
                                                        }}
                                                    >
                                                        <DoneIcon />
                                                    </IconButton>
                                                    <IconButton onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleSaveClickWrapper(folder.id, e, 'cross')
                                                    }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <>
                                                    <Typography>{folder.name}</Typography>
                                                    <Typography>{`${folder.lastModified != null ? `Updated ${getTimeAgo(folder?.lastModified)}` : ''}`}</Typography>
                                                    {/* <Typography>
                                                    {folder.lastModified ? `Updated ${getTimeAgo(folder.lastModified)}`: `Created ${getTimeAgo(folder.createdAt)}`}
                                                </Typography> */}
                                                </>
                                            )}
                                        </div>
                                        {!editingFolderId && (
                                            <div className="resource-child-edit-div icon-container">
                                                {canUpdate && <Tooltip title="Edit Folder Name">
                                                    <IconButton onClick={() => handleEditClickWrapper(folder.id, folder.name)}>
                                                        <EditIcon sx={{ color: '#0B6BCB' }} />
                                                    </IconButton>
                                                </Tooltip>}
                                                {canDelete && <Tooltip title="Delete Folder">
                                                    <IconButton onClick={(e) => handleDeleteClick(folder?.id, e, folder, 'DELETE')}>
                                                        <DeleteIcon sx={{ color: '#0B6BCB' }} />
                                                    </IconButton>
                                                </Tooltip>}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            }
        </>
    );
};

export default FolderAccordianList;
