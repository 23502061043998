import React, { useEffect, useState } from 'react'
import { Modal, ModalDialog, DialogTitle, Input, ModalClose, FormControl, FormLabel, Stack } from '@mui/joy';
import { MenuItem, Select } from '@mui/material';
import "../CoachingDashboard/coachingDashboard.css"
import conversationService from '../../Services/conversation.service';
import toaster from '../../Toast/toaster';
import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';
import { uploadCoachingMedia } from '../../Services/coaching.service';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function SessionUploadModal(props) {
    const { openModal, setOpenModal } = props;
    const [selectedFile, setSelectedFile] = useState(null)
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    const [loader, setLoader] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        event.target.value = null;
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                toaster.error("File size exceeds 10MB limit");
                event.target.value = null;
            } else {
                setSelectedFile(file);
            }
        }
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const uploadHandler = async (e) => {
        setLoader(true)
        e.preventDefault()
        if (!selectedFile) {
            toaster.error("Please select a file to upload");
            setLoader(false)
            return;
        }
        if (selectedFile.size > MAX_FILE_SIZE) {
            toaster.error("File size exceeds 10MB limit");
            setLoader(false)
            return;
        }

        const formData = new FormData();
        await formData.append('file', selectedFile);

        await uploadCoachingMedia(formData).then((res) => {
            if(res.data.status === 0){
                toaster.success("File uploaded successfully.")
            }
            setSelectedFile(null)
            setLoader(false)
        }).catch((err) => {
            toaster.error("Unable to upload file. Please try again.")
            setSelectedFile(null)
            setLoader(false)
        })
    }

    return (
        <Modal
            open={openModal}
            onClose={() => {
                setOpenModal(false)
                setSelectedFile(null)
            }}
            sx={{
                backdropFilter: 'blur(1px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '15px'
            }}
            disableEscapeKeyDown={true}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Upload</DialogTitle>
                <hr />
                <div className='coaching-input-container'>
                    <form onSubmit={uploadHandler}>
                        <Stack spacing={2}>
                            <FormControl>
                                <div className="form-parent">
                                <p className='parent-p'> <CloudUploadIcon  sx={{fontSize: '50px', color: '#6990F2;'}}/></p>
                                    <p className='sub-head'>Drop here or <span>Choose file</span> to upload </p>
                                    <p className="file-uploadd">Max.File Size : 200mb</p>
                                    <p className="file-format">(ALLOWED DOC FORMATS: DOCX, DOC, PNG, JPG, JPEG, MPEG, XLS, XLSX, CSV, PDF)</p>
                                    <p className="file-format ss">(ALLOWED AUDIO FORMATS: MP3, WAV, OGG, WMA, MP4, WEBM, ACC, AC3, AIFF, 3GPP, SMF, AMR-WB, X-AMR-WB)</p>
                                </div>
                            </FormControl>
                            <div className='btn-parent'>
                            <Button onClick={() => setOpenModal(false)} sx={{marginRight: '20px'}}>Cancel</Button>
                            <Button type="submit" disabled={loader}>Submit</Button>
                            </div>
                            
                        </Stack>
                    </form>
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default SessionUploadModal