import { Upload } from "@mui/icons-material";
import React, {useState} from "react"
import {
    Modal,
    Button,
    DropdownButton,
    Dropdown,
    Form,
    ModalBody,
    Tabs,
    Tab,
  } from "react-bootstrap";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import "./ConversationsDashboard.css"
  import { apiCall } from "../Services/Interceptor";
  import toast from "../Toast/toaster"
import { Backdrop, CircularProgress } from "@mui/material";

  export default function CallUploadModal(props) {
 
    const [fileUpload, setFileUpload] = useState(true);
    const [uploadedFile, setUploadedFile] = useState('');
    const [openBackDrop,setOpenBackDrop] = useState(false)
  
    const handleUploadTab = (e)=>{
        if(e.target.textContent == "File Upload"){
            setFileUpload(true)
        }else{
            setFileUpload(false)
        }  
    }
   

    const handelFileUpload = async (e)=>{
        setUploadedFile(e.target.files[0])
       
    }

    const downloadCSV = async() =>{
      const FileDownload = require('js-file-download');
      let DOWNLOAD = '/odio/api/reports/downloadFile'
      await apiCall.get(DOWNLOAD).then(res => {
        FileDownload(res.data, 'file.csv');
      });
    }


    const onSubmitHandler = async()=>{
      setOpenBackDrop(true);
      let UPLOAD_FILE = '/odio/api/reports/uploadFile'
      let formData = new FormData();
      formData.append("file", uploadedFile);
      await apiCall.post(UPLOAD_FILE, formData).then(res=>{
        if(res.data.status!=0){
          setOpenBackDrop(false);
          toast.error(res.data.message)
        }
        else{
          toast.success("Uploaded correctly")
          setOpenBackDrop(false);
          props.closeButton()
        }
      })


      setUploadedFile('')
    }

    const onCloseHandler = ()=>{
      props.closeButton()
      setUploadedFile('')
    }
    return (
      <>
      {openBackDrop && <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
        <Modal className="callUpload" show={props.show} onHide={props.closeButton} >
          <Modal.Header closeButton>
            <h5>Call Upload</h5>
          </Modal.Header>
          <Modal.Body className="call-upload-modal">
                {/* <div>
                    <p className="mb-0">Call Title</p>
                    <input name="call_title" placeholder="Call Title" className="form-control form-control-sm mb-3 py-1" type="text" />
                </div>
                <div>
                    <p className="mb-0">External Attendees</p>
                    <input name="call_title" placeholder="prospect@mydomain.com, prospect@micro.com" className="form-control form-control-sm mb-3 py-1" type="text" />
                </div>
                <div>
                    <p className="mb-0">Reps</p>
                    <input name="call_title" placeholder="Select Reps" className="form-control form-control-sm mb-3 py-1" type="text" />
                </div>
                <div>
                    <p class="mb-0">Call Date</p>
                    <DatePicker
                    className="form-control form-control-sm py-1"
                    //selected={endDate}
                    //onChange={handleEndDate}
                    name="startDate"
                    dateFormat="MM/dd/yyyy"
                    />
                </div> */}
                {/* <div className="file-url-btns d-flex mt-3"> */}
                <div className="buttonWrap">
                    {/* <p className={`w-50 mb-0 text-center ${fileUpload?"active":''}`} onClick={handelFileUpload}><i className="lni lni-upload"></i> File Upload</p>
                    <p className={`w-50 mb-0 text-center ${fileUpload?'':"active"}`} onClick={handleUploadTab}>{/*<i className="bx bx-link"></i>Download CSV</p> */}
                    <Button className="buttons" onClick={downloadCSV}>Download CSV File</Button>
                </div>
                {//fileUpload?
                <>
                <div className="mx-1 mt-4 file-upload-content">
                <fieldset>
                    <label for="fileselect">Drop your file here or Browse
                        <br/>Supported formats: .csv</label>
                    <input type="file" id="fileselect" name="fileselect[]" multiple="multiple"  accept=".csv" onChange={handelFileUpload}/>
                    {/* <div id="filedrag">or drop files here</div> */}
                </fieldset>
                    {uploadedFile?<div className="uploaded-file">
                        <p className="mb-0"><b>File to Upload:</b>{uploadedFile.name}</p>
                    </div>:''}
                </div>
                
                </>
                //:
                // <div className="mx-1 mt-4 file-upload-content">
                //     <p className="mb-0">Video/Audio URL: (Supported formats: xlsx)</p>
                //     <input name="call_title" placeholder="http://www.mydomain.com/video.mp4" className="form-control form-control-sm mb-3 py-1" type="text" />
                // </div>
              }
          </Modal.Body>
          <div>
            <p style={{textAlign:"center"}}><b>Note:</b> The result will take upto 1-2 hour</p>
          </div>
          <Modal.Footer className="modalFooter">
            <Button variant="primary" onClick={onSubmitHandler} disabled={!uploadedFile || openBackDrop}>
              Submit
            </Button>
            <Button variant="secondary" onClick={onCloseHandler}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
