import { useState, useCallback, useEffect } from "react";
import { apiCall } from "../Services/Interceptor";
import { useHistory } from "react-router-dom";
import BubbleLoader from '../Commons/BubbleLoader';

export default function SearchBar(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const history = useHistory()
  const [noData, setNoData] = useState(false)

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const fetchSearchResults = async (term, page = 1) => {
    if (!term) {
      setHasMore(false)
      setNoData(false)
      setSearchResults([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await apiCall.get(`/odio/api/search?input=${term}&page=${page}&size=${10}`);
      setNoData(response?.data?.data?.teams.length == 0 && response?.data?.data?.customer.length == 0 && response?.data?.data?.trans.length == 0)
      setHasMore(response?.data?.data?.teams.length > 0 || response?.data?.data?.customer.length > 0 || response?.data?.data?.trans.length > 0);
        let result = Object.entries(response?.data?.data)?.flatMap(([key, items]) => 
        items?.map(item => ({ key, ...item }))
      )
      setSearchResults(prevResults => page === 1 ? result : [...prevResults, ...result]);
    } catch (error) {
      console.log('Error fetching search results:', error);
    }
    setIsLoading(false);
  };

  const debouncedFetchSearchResults = useCallback(debounce(fetchSearchResults, 900), []);

  const handleInputChange = (event) => {
    const term = event.target.value;
    const filteredValue = term.replace(/[^\w\s]/gi, "");
    setNoData(false)
    setSearchTerm(filteredValue);
    setPageNo(1);
    debouncedFetchSearchResults(term, 1);
  };

  const handleShowMore = () => {
    const nextPage = pageNo + 1;
    setPageNo(nextPage);
    fetchSearchResults(searchTerm, nextPage);
  };

  const redirectionToPage = (result) =>{
    const permission = JSON.parse(localStorage.getItem("USER_PERMISSIONS"))
    if(result.key == "teams"){
        history.push(`/team_member_detail?id=${result.userId}`)
    }else if(result.key == "trans"){ 
        if(window.location.pathname == '/voice_detail' || window.location.pathname == '/sales_call_detail'){
          window.location.reload();
        }
        permission.some(item => item.api === '/voice_detail') ? history.push(`voice_detail?callId=${result.callId}&tabSelected=transcript&name=SINGLE_CALL`) : history.push(`sales_call_detail?callId=${result.callId}&tabSelected=transcript&name=SINGLE_CALL`)
    }else if(result.key == "customer"){
        if(window.location.pathname == '/voice' || window.location.pathname == '/sales_conversations'){
          window.location.reload();
        }
        permission.some(item => item.api === '/voice_detail') ? history.push('/voice',{agentPhone : result?.customerMobileNumber }) : history.push(`/sales_conversations`,{agentPhone : result?.customerMobileNumber })
    }
    props?.closeButton()
  }

  return (
    <div className="search-bar full-search-bar">
      <div className="position-relative search-bar-box">
        <input
        type="text"
          className="input form-control search-control"
          placeholder="Type to search"
          value={searchTerm}
          onChange={handleInputChange}
        />
        <span className="position-absolute top-50 search-show translate-middle-y">
          <i className="bx bx-search" />
        </span>
        <span className="position-absolute top-50 search-close translate-middle-y" onClick={props?.closeButton}>
          <i className="bx bx-x" />
        </span>
        <div className="search-dropdown search-scroller" style={{maxHeight: '50vh', overflowY: 'auto'}}>
          <div className="wrap-search-btn matchedResult">
            <div className="search-result-lines">
            {searchResults?.map((result)=>{
            let modified = result?.trans?.length > 0 ? result?.trans[0]?.length > 90 ? result?.trans[0]?.slice(0, 90) + "..." : result?.trans[0] : ''
                return <div style={{display:'flex'}} onClick={()=>redirectionToPage(result)}>
                    <p className="search-result-lines" key={result?.key == 'teams' ? result?.userId : result?.key == 'customer' ? result?.customerMobileNumber : result?.callId} style={{marginBottom:'0'}}>{result?.key == 'teams' ? result?.agentName : result?.key == 'customer' ? result?.customerMobileNumber : modified}</p>
                    <b style={{marginBottom:'0', alignContent:'center'}}>&nbsp; - &nbsp;{result?.key == "teams" ? "Teams" : result?.key == 'customer' ? 'Customer' : "Transcript"}</b></div>
                })}
            </div>
            {isLoading && 
            <div className="search-more" style={{marginTop:'10px'}}><BubbleLoader/></div>}
            {/* <button className="clear-results px-2">Clear</button> */}
          </div>
          {hasMore && !isLoading && (
          <div className="search-more">
            <button onClick={handleShowMore}>Show More Results</button>
         </div>
          )}
          {noData && <p style={{textAlign:'center',marginTop:'10px'}}>Data not available. Please enter another keyword.</p>}
        </div>
      </div>
    </div>
  );
}