import { Card, Modal } from "react-bootstrap"
import { Button } from "react-bootstrap"
import React,{useEffect, useState} from 'react'
import callsService from "../../../Services/calls.service"
import ReactDOMServer from 'react-dom/server';
import toaster from "../../../Toast/toaster";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";

export default function EmailTranscript(props){

    const [sendTo,setSendTo] = useState("") 
    const [mailMessage,setMailMessage] = useState(props?.emailTrailMessage ? `${props?.emailTrailMessage}` : `${props?.callId?`${props?.typeOfCall=='SINGLE_CALL'?'CallId':'GroupId'}: ${props?.callId}`:props?.userId?`UserId: ${props?.userId}`:""} <br />${props?.fileName? `FileName: ${props?.fileName}<br />` :""}${props?.agentId?`Employee Id: ${props?.agentId}<br />`:""}${props?.tabSelected=='transcript'?"TRANSCRIPT":props?.tabSelected=='stats'?"STATS":props?.tabSelected=='callMoments'||props?.tabSelected=='moments'?"MOMENTS":props?.tabSelected=='signals'?"SIGNALS":props?.tabSelected=='questions'?"QUESTIONS":props?.tabSelected=='actions'?'ACTIONS':props?.tabSelected=='foulLanguage'?"FOUL_LANGUAGE":""}`)
    const [mailSubject, setMailSubject] = useState("")
    const history = useHistory();
    const [historyObject, setHistoryObject] = useState("");
    const uniqueId = historyObject?.typeId;
    const coeExternalId = JSON.parse(localStorage.getItem("AGENT"))?.coe; 

    useEffect(() => {  
      setHistoryObject(history.location.state)},
    [])

    const handleChange = (e)=>{
      if(e.target.name=='to')
        setSendTo(e.target.value.split(","))
      if(e.target.name=='subject')
        setMailSubject(e.target.value)
      if(e.target.name=='message')
        setMailMessage(e.target.value)
    }


    const questionCustomer = props?.ques?.customer

    const getTimeVal = (val)=>{
        var p = (val/60).toFixed(2).split('.')
        return `${p[0]} Min. ${p[1]} Sec`
    }

    const transcriptList= (speaker,time,trans)=>{
        return(
        <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1 font-16">
                    <i class="bx bx-user-voice me-1"></i>
                    {speaker=='seller'?props?.callTranscript?.sellerName:props?.callTranscript?.customerName}
                    <span class="badge rounded-pill bg-light-blue text-dark f-normal">
                    {speaker}
                    </span>
                </h5>
                <small class="text-muted">
                    <i class="bx bx-time"></i>{time}
                </small>
            </div>
            <p class="mb-1">
                {trans}
            </p>
        </a>
        )
    }

    const statsList = ()=>{
      return(
        <div id="statspage">
          <ul className="list-group">
            {
              props?.callStats?.map((item,id)=>{
                return(
                <li className="list-group-item d-flex justify-content-between total-duration-stats">

                  <>
                  <div>
                    {item?.label}
                  </div>
                  <div className="text-end">
                    <span className="badge btn btn-outline-success font-normal font-12 rounded-pill">
                      {item?.value}
                    </span>
                  </div>
                </>
                </li>
              )
            })}
            <hr/>
          </ul>
        </div>)
    }

    const statsIndividualList = (label,value)=>{
      return(
        <li className="list-group-item d-flex justify-content-between" style={{marginTop:"14px",marginBottom:"84px",borderRadius:"6px"}}>
          {label}
          <div className="text-end">
            <span className="badge btn btn-outline-info font-normal font-12 rounded-pill">
              {value}
            </span>
          </div>
        </li>
      )
    }

    const momentList = (moments)=>{
        return(
            <table className="table mb-0">
              <thead className="fixed-header-table">
                <tr>
                  <th>Name</th>
                  <th className="text-center">Observed</th>
                  <th className="text-center">Weightage</th>
                  <th className="text-center">Global Avg.</th>
                </tr>
              </thead>
              <tbody className="fixed-header-table">
                { moments? Object.entries(moments).map((group,id) =>(
                  <>
                  {group[0]=='postTypeComments'?"":
                    <tr>
                      <th
                        colspan="4"
                        className="moment-table-hover-icon bg-light-blue"
                      >
                        <div className="d-flex table-group-header justify-content-between">
                          <div className="table-group-heading">
                            <p className="mb-0">{group[0]}</p>
                          </div>
                        </div>
                      </th>
                    </tr>}
                    {group[0]!="postTypeComments"&& group[1].length>0?group[1].map(g => (
                         <tr>
                          <td>
                            <a
                              className="font-weight-bold mb-0"
                              data-bs-toggle="modal"
                              data-bs-target="#momentTabonName"
                              type="button"
                            >
                              {g.momentName}
                            </a>
                          </td>
                          <td className="text-center">
                            {g.observed == '1' ? <i className="lni lni-checkmark"></i> : g.observed==0?<i className="lni lni-close"></i>:g.observed==null?'NA':""}
                          </td>
                          <td className="text-center">{g.weightage}</td>
                          <td className="text-center">
                            <span className="badge rounded-pill btn btn-outline-danger font-normal">
                              {g.globalAverage}%
                            </span>
                          </td>
                        </tr>
                      )):''
                    }
                  </>  
                )) : '' }
              </tbody>
            </table>
        )
    }

    const teamMomentList = (moments)=>{
      return(
        <table className="table mb-0">
          <thead className="fixed-header-table">
            <tr>
              <th>Name</th>
              <th className="text-center">Averages</th>
              <th className="text-center">Global Averages</th>
            </tr>
          </thead>
          {moments?Object.entries(moments).map(item=>{
            return(
            <tbody className="fixed-header-table">
                {item[0]=='postTypeComments'?"":
                <>
                  <tr>
                    <th
                      colspan="4"
                      className="moment-table-hover-icon bg-light-blue"
                    >
                      <div className="d-flex table-group-header">
                        <div className="table-group-heading">
                          <p className="mb-0">{item[0]}</p>
                        </div>
                      </div>
                    </th>
                  </tr>
                  {item?item[1].map(val=>{
                    return(
                    <tr>
                        <td>
                          <a className="font-weight-bold mb-0"
                            data-bs-toggle="modal"
                            data-bs-target="#momentTabonName"
                            type="button"
                          >
                            {val.momentName}
                          </a>
                        </td>
                        <td class="text-center">
                          <span className="badge rounded-pill fw-normal btn btn-outline-danger">
                            {val.average?`${val.average}%`:"0%"}
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="badge rounded-pill btn btn-outline-danger font-normal">
                            {val.globalAverage?`${val.globalAverage}%`:"0%"}
                          </span>
                        </td>
                    </tr>
                  )
                }):""}
                </>}  
            </tbody>
          )
          }):""}
        </table>
      )
    }

    const signalsList = (signals)=>{
        return(
            <Accordion defaultActiveKey="0">
            {Object.entries(signals.signals).map((item,id)=>{
              return(
                <>
                <Accordion.Item eventKey={id}>
                    <Accordion.Header>
                        <div className="accordion-button-stats">
                            <h6 style={{"textTransform":"capitalize"}}>
                            {item[0]}
                            </h6>
                        </div>
                    </Accordion.Header>
                    {item[1].map(value=>{  
                        return(
                        <Accordion.Body>
                            {/* {value.map(data=>{
                            return( */}
                            <div className="accordion-body">
                                <div className="list-group-item">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-0 font-16">
                                        <i className="bx bx-user-voice me-1" />
                                        {value.speaker==='0'?props.customer:value.speaker==='1'?props?.seller:""}
                                        <span className="badge rounded-pill bg-light-blue text-dark f-normal">{`${value.speaker==='0'?"customer":"seller"}`}</span>
                                        </h5>
                                        <small className="text-muted">
                                          {`${signals?.date}, ${value.startTime}`}
                                        </small>
                                    </div>
                                    <p className="mb-1">
                                        {value.trans}
                                    </p>
                                </div>
                            </div>
                        </Accordion.Body>
                    )})}
                </Accordion.Item>
                </>
              )})
            }
          </Accordion>
        )
    }

    const questionList = (questions)=>{
      return(
        <>
        {questions[0].questions.agent.map((item)=>{
          return(
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 font-16">
                <i class="bx bx-user-voice me-1"></i>
                {props.ques[0].sellerName}
                <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"seller"}</span>
              </h5>
              <small class="text-muted">
                <i class="bx bx-time"></i>
                {/* {time} */}
              </small>
            </div>
              <p class="mb-1">
                {item.trans}        
              </p>
          </a>
        )})}
        {questions[0].questions.customer.map((item)=>{
          return(
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 font-16">
                <i class="bx bx-user-voice me-1"></i>
                {props.ques[0].customerName}
                <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"customer"}</span>
              </h5>
              <small class="text-muted">
                <i class="bx bx-time"></i>
                {/* {time} */}
              </small>
            </div>
              <p class="mb-1">
                {item.trans}        
              </p>
          </a>
          )
        })}
        </>
      )
    }

    const actionList = (trans,speaker)=>{
      return(
        <>
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1 font-16">
                <i class="bx bx-user-voice me-1"></i>
                {speaker=='seller'?props?.sellerName:props?.customerName}
                <span class="badge rounded-pill bg-light-blue text-dark f-normal">
                  {speaker==='seller'?"seller":"customer"}
                </span>
                </h5>
                <small class="text-muted">
                <i class="bx bx-time"></i>
                {/* {time} */}
                </small>
            </div>
            <p class="mb-1">
                {trans}       
            </p>
          </a>
          
        </>
      )
    }

    const foulLangList = (data)=>{
      return(
        <>
        {data[0].abusiveLanguage?.agent?.map(item=>(
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 font-16">
                <i class="bx bx-user-voice me-1"></i>
                {data[0]?.sellerName}
                <span class="badge rounded-pill bg-light-blue text-dark f-normal">seller</span>
              </h5>
              <small class="text-muted">
                <i class="bx bx-time"></i>
                {item.startTime}
              </small>
            </div>
            <p class="mb-1">
              {item.trans}
            </p>
          </a>
        ))}
        {data[0]?.abusiveLanguage?.customer?.map(item=>(
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 font-16">
                <i class="bx bx-user-voice me-1"></i>
                {data[0]?.customerName}
                <span class="badge rounded-pill bg-light-blue text-dark f-normal">customer</span>
              </h5>
              <small class="text-muted">
                <i class="bx bx-time"></i>
                {item.startTime}
              </small>
            </div>
            <p class="mb-1">
              {item.trans}
            </p>
          </a>
        ))}
        </>
      )
    }

    const clickHandler = ()=>{
        setSendTo("")
        setMailSubject("")
        setMailMessage(`${props?.callId?`${props?.typeOfCall=='SINGLE_CALL'?'CallId':'GroupId'}: ${props.callId}`:props.userId?`UserId: ${props.userId}`:""} <br />${props.fileName? `FileName: ${props.fileName}<br />` :""}AgentId: 2322132 <br />${props.tabSelected=='transcript'?"TRANSCRIPT":props.tabSelected=='stats'?"STATS":props.tabSelected=='callMoments'||props.tabSelected=='moments'?"MOMENTS":props.tabSelected=='signals'?"SIGNALS":props.tabSelected=='questions'?"QUESTIONS":props.tabSelected=='actions'?'ACTIONS':props.tabSelected=='foulLanguage'?"FOUL_LANGUAGE":""}`)
        let val = {
          ...(props?.type === 'email' ? {
            [props?.typeOfCall === 'SINGLE_CALL' ? 'uniqueId' : 'groupId']: uniqueId,
            "coeExternalIds": coeExternalId,
            "listOfTo":sendTo,
          } : {
            [props?.typeOfCall === 'SINGLE_CALL' ? 'callId' : 'groupId']: props?.callId,
            "listOfto": sendTo
          }),
          "enumCommentType":props.tabSelected=='transcript'?"TRANSCRIPT":props.tabSelected=='stats'||props.tabSelected=='teamStats'?"STATS":props.tabSelected=='callMoments'||props.tabSelected=='teamMoments'?"MOMENTS":props.tabSelected=='signals'?"SIGNALS":props.tabSelected=='questions'?"QUESTIONS":props.tabSelected=='actions'?'ACTIONS':props.tabSelected=='foulLanguage'?"FOUL_LANGUAGE":"",
          "subject":mailSubject,
          "htmlText":mailMessage,
        }
        callsService.getEmailTranscript(props.tabSelected=='stats'?Object.assign(val,{"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD})
          :props.tabSelected=='teamMoments'?Object.assign(val,{"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,"coeIds":props?.filteredData?props.filteredData.coeId?props.filteredData.coeId:props.defaultFilterData?.coeIds:props.defaultFilterData?.coeIds,"userId":props?.userId})
          :props.tabSelected=='teamStats'?Object.assign(val,{"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,"coeIds":props?.filteredData?props.filteredData.coeId?props.filteredData.coeId:props.defaultFilterData?.coeIds:props.defaultFilterData?.coeIds,"userIds":[props?.userId]})
          :props.tabSelected=='callMoments'?Object.assign(val,{"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,"coeIds":JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange}):val , props?.type).then(response=>{
          if(response.data.status == 0){ 
            toaster.success(response.data.message)
          }
          else{
            toaster.error("Error")
          }
        })
        props.setEmailFalse()
    }

    return(
        <>
        {props.show ?<div className="compose-mail-popup">
        <Card>
            <Card.Header className="bg-dark-blue text-white py-2 cursor pointer ">
                <div className="d-flex align-items-center">
                    <div className="compose-mail-title">
                        New Message
                    </div>
                    <div className="compose-mail-close ms-auto" onClick={props.closeButton}>
                        x
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="email-form">
                    <div className="mb-3">
                        <input type="text" name="to" className="form-control input-lg" placeholder="To" onChange={handleChange} value={sendTo}></input>
                    </div>
                    <div className="mb-3">
                        <input type="text" name="subject" className="form-control input-lg" placeholder="Subject" onChange={handleChange} value={mailSubject}></input>
                    </div>
                    <div className="mb-3">
                      <pre>
                        <textarea className="form-control" name="message" placeholder="Message" rows="3" cols="10" onChange={handleChange} value={mailMessage.replace(/\<br +\/\>/g, '\n')}/>
                      </pre>
                        <div id="copy-data-in-mail">
                          <div className="moment-list-group-scroll stats-list-group-scroll" id="all-action-data">
                            {props?.tabSelected=='transcript' ? props?.callTranscript?.sequence.map(item=>{
                              return(
                                transcriptList(item.speaker,item.time,item.trans)
                              )
                            }) : props?.tabSelected=='stats'||props?.tabSelected=='teamStats'&&props?.emailButton!=true?
                                statsList()
                            :props?.tabSelected=='stats'&&props?.emailButton==true?
                              statsIndividualList (props?.label, props?.value)
                            :props?.tabSelected=='callMoments'?
                                momentList(props?.callMoments)
                            :props?.tabSelected=="teamMoments"?
                                teamMomentList(props?.teamMoments)
                            :props?.tabSelected=='signals'?
                                signalsList(props?.callSignals)
                            :props?.tabSelected=='questions'&&props?.ques!==undefined?
                                questionList(props.ques)
                            :props?.tabSelected=='actions'?props?.callAction?.postTextData?.map(item=>{
                              return(
                                actionList(item.postText,item.speaker)
                              )
                            })
                               
                            :props.tabSelected=='foulLanguage'?
                              foulLangList(props.foulLanguage)
                            // props.foulLanguage.data&&props.foulLanguage?.data[0]?.abusiveLanguage.map(item=>{
                            //   return( 
                            //     foulLangList(item.speaker,item.time,item.trans)
                            //   )
                            // })
                            :""}
                          </div> 
                        </div>
                    </div>
                    <div className="mb-0">
                      <div className="d-flex align-items-center">
                          <div>
                              <div className="btn-group">
                                  <Button className="btn bg-medium-blue text-light" onClick={clickHandler}>
                                      Send
                                  </Button>
                              </div>
                          </div>
                          {/* <div className="ms-2">
                              <button className="btn border-0 btn-sm btn-white">
                                  <i className="bx bx-link-alt"/>
                              </button>
                          </div>
                          <div className="ms-auto">
                              <button className="btn border-0 btn-sm btn-white">
                                  <i className="lni lni-trash"/>
                              </button>
                          </div> */}
                      </div>
                  </div>
                </div>
            </Card.Body>
        </Card>
        </div>
        :""}
        </>
    )
}