import * as React from 'react'
import { Card } from "react-bootstrap";
// import {GetTokens} from "./Firebase"
import { useState } from 'react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
// import {onMessageListener } from './Firebase';
import { useHistory } from "react-router-dom";
import Loading from '../Commons/Loading';
import { usePreviousValue } from './customHook/usePreviousValue';
import notificationsService from '../Services/notifications.service';
import conversationService from "../Services/conversation.service";
import NodataFound from '../Commons/NoDataFound';
import PaginationAlter from '../Commons/Table/PaginationAlter';
import CallIcon from '@mui/icons-material/Call';
import GroupsIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';

export default function NotificationDetail(props) {
    const [isTokenFound, setTokenFound] = useState(false);
    const [token, setToken] = useState("")
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [currentPage, setCurrentPage] = useState(1)
    const [loader, setLoader] = useState(false)
    const [notificationData, setNotificationData] = useState([])

    const prevValueOfToken = usePreviousValue(token)
    const prevValueOfNotif = usePreviousValue(notification)
    const [availablePages, setAvailablePages] = useState(0)
    const rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))


    const notify = () => toast(<ToastDisplay />);
    function ToastDisplay() {
        return (
            <div>
                <p><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>
        );
    };

    useEffect(() => {
        if (document.getElementById('notification-List')) {
            document.getElementById('notification-List').style.display = 'none';
        }
    }, [])

    const callBack = (e) => {
        setCurrentPage(e)
    }

    const getNotification = () => {
        setLoader(true)
        notificationsService.getAllNotifications(currentPage).then(response => {
            if (response) {
                setLoader(false)
                setAvailablePages(response?.data?.data?.availablePages)
                setNotificationData(response?.data?.data?.notificationResponseList)
            }
        })
    }

    const history = useHistory()
    const clickHandler = async (id, notificationId, tabName, tabValue, item, userId) => {
        let val = {
            "pageSize": 5,
            "callType": JSON.parse(localStorage.getItem("USER_DETAIL"))?.accountType,
            "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
            "fromD": storedData && storedData.fromD,
            "toD": storedData && storedData.toD,
        }
        // if(!localStorage.getItem('URLS')){
        //     await conversationService.getCallListing(val)
        // }

        notificationsService.readNotifications([notificationId]).then(res => {
            if (res) {
                // let redirectedUrl = ''
                // let urls = Object.entries(JSON.parse(localStorage.getItem("URLS")))
                // let filteredUrl =  urls.filter(item=>{
                //     if(item[0]!=id&& item[1].detail.length>0){
                //         item[1].detail.forEach(element => {
                //             if(element.callId === id){
                //                 redirectedUrl = element.url
                //                 return element;
                //             }
                //         });  
                //     }
                //     else if(item[0]==id&&item[1].detail.length>0){
                //         redirectedUrl = item[1].url
                //         return item
                //     }
                //     else if(item[0]==id&&item[1].detail==''){
                //         redirectedUrl = item[1].url
                //         return item
                //     }
                // })
                localStorage.setItem("TeamTabValue", tabName);
                const currentDate = new Date();
                const day = String(currentDate.getDate()).padStart(2, '0');
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const year = currentDate.getFullYear();
                const formattedToDate = `${day}-${month}-${year}`;
                let requestBody = {
                    "callId": item?.notification?.callId,
                    "groupId": item?.notification?.groupId,
                    "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
                    "fromD": "01-01-2020",
                    "toD": formattedToDate,
                }

                item?.notification?.notificationEvent?.tabName == 'teamStats' || item?.notification?.notificationEvent?.tabName == 'teamMoments' ?
                    history.push(
                        `team_member_detail?id=${userId}&tabSelected=${tabName}&postId=${item?.notification?.notificationEvent?.postId}&eventType=${item?.notification?.notificationEvent?.eventType}&commentShow=${item?.notification?.notificationEvent?.eventType == 'COMMENT' ? true : false}&agentExternalId=${item?.notification?.userExternalId}`,
                        {
                            tabSelected: tabName,
                            tabValue: tabValue,
                            externalId: item?.notification?.userExternalId,
                            phone: item?.notification?.mobile,
                            postId: item?.notification?.notificationEvent?.postId,
                            eventType: item?.notification?.notificationEvent?.eventType,
                            // url:redirectedUrl,
                            commentShow: item?.notification?.notificationEvent?.eventType == 'COMMENT' ? true : false
                        }) :
                    id && notificationsService.getRedirectedData(requestBody).then(val => {
                        history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `voice_detail?callId=${id}&tabSelected=${tabName}&postId=${item?.notification?.notificationEvent?.postId}&eventType=${item?.notification?.notificationEvent?.eventType}&commentShow=${item?.notification?.notificationEvent?.eventType == 'COMMENT' ? true : false}&name=${item?.notification?.callId !== null ? 'SINGLE_CALL' : 'GROUP'}` : `sales_call_detail?callId=${id}&tabSelected=${tabName}&postId=${item?.notification?.notificationEvent?.postId}&eventType=${item?.notification?.notificationEvent?.eventType}&commentShow=${item?.notification?.notificationEvent?.eventType == 'COMMENT' ? true : false}&name=${item?.notification?.callId !== null ? 'SINGLE_CALL' : 'GROUP'}`, {
                            agentExternalId: item?.notification?.userExternalId,
                            tabSelected: tabName,
                            tabValue: tabValue,
                            externalId: item?.notification?.userExternalId,
                            phone: item?.notification?.mobile,
                            postId: item?.notification?.notificationEvent?.postId,
                            eventType: item?.notification?.notificationEvent?.eventType,
                            url: val?.data?.data?.encoderRecordingUrl,
                            commentShow: item?.notification?.notificationEvent?.eventType == 'COMMENT' ? true : false,
                            callId: item?.notification?.callId,
                            id: val?.data?.data?.agentId,
                            coe: val?.data?.data?.coeName,
                            customer: val?.data?.data?.customerName,
                            seller: val?.data?.data?.agentName,
                            duration: val?.data?.data?.totalDuration,
                            date: `${val?.data?.data?.date} ${val?.data?.data?.time}`
                        })
                    })
            }
        })
    }

    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    useEffect(() => {
        getNotification()
    }, [currentPage])

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    const todayDate = today.split("/")
    const d = todayDate[0]

    const dateFunc = (d1) => {
        let newDate = d1.split("T")
        let datee = `${newDate[0].split("-")[1]}-${newDate[0].split("-")[2]}-${newDate[0].split("-")[0]}`
        let convDate = new Date(datee)
        let notifDate = convDate.getDate()
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        let date = ""
        // if((notifDate)-1==d){
        //     date = "Tomorrow"   
        //  }
        if ((notifDate) + 1 == d) {
            date = "Yesterday"
        }
        else if (notifDate == d) {
            date = "Today"
        }
        else if ((notifDate) + 2 == d) {
            date = "2 Days ago"
        }
        else if ((notifDate) + 3 == d) {
            date = "3 Days ago"
        }
        else if ((notifDate) + 4 == d) {
            date = "4 Days ago"
        }
        else {
            date = `${monthNames[convDate.getMonth()]} ${notifDate}, ${convDate.getFullYear()}`
        }
        return date
    }

    const eventIcons = {
        'CALL_GROUP': <GroupsIcon sx={{ color: '#0077b6' }} />,
        'CALL': <CallIcon sx={{ color: '#0077b6' }} />,
        'EMAIL': <EmailIcon sx={{ color: '#0077b6' }} />,
        'EMAIL_GROUP': <MarkAsUnreadIcon sx={{ color: '#0077b6' }} />,
        'MEETING': <Diversity2Icon sx={{ color: '#0077b6' }} />,
        'CHAT': <ChatIcon sx={{ color: '#0077b6' }} />,
    };

    return (
        <>
            {loader ? (
                <div className="loader-container">
                    <Loading variant="dark" />
                </div>
            ) : (
                <div className="list-group">
                    <>
                        {notificationData.length > 0 ? notificationData?.map(val => {
                            const eventType = val?.notification?.notificationEvent?.notificationModule?.toUpperCase();
                            const eventIcon = eventIcons[eventType] || <i className='bx bx-bell me-1' />;
                            return (
                                <div className="list-group-item list-group-item-action" style={{ backgroundColor: `${val.notificationReadStatus.readStatus == false ? 'aliceblue' : ""}` }}>
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1 font-16">
                                            {eventIcon}
                                        <a onClick={() => { clickHandler(val.notification.callId !== null? val.notification.callId : val.notification.groupId, val?.notificationId, val?.notification?.notificationEvent?.tabName, val?.notification?.notificationEvent?.tabValue, val, val?.notification?.userId) }} style={{textDecoration: 'underline', marginLeft: '5px'}} aria-current="true">
                                            {val.notification.callId !== null? val.notification.callId : val.notification.groupId}
                                        </a> | {val.notification.notificationEvent.eventType.toLowerCase()}
                                        </h5>
                                        <small>{dateFunc(val.notification.notificationEvent.createdAt)}</small>
                                    </div>
                                    <small>{val?.notification?.notificationMessage}</small>
                                </div>
                            )
                        }) : <NodataFound />
                            // )})
                        }
                    </>
                </div>
            )}
            {notificationData.length > 0 && availablePages > 0 ?
                <PaginationAlter callBack={callBack} availablePages={availablePages} loaderTrue={props.loaderTrue} loaderFalse={props.loaderFalse} /> : ""}
        </>
    )
}