import toast from "../Toast/toaster";
import { apiCall } from "./Interceptor";

const GET_CLIENT = "/odio/api/client/get";
const UPDATE_CLIENT = "/odio/api/client/update-client";
const UPLOAD_PROFILE = "/odio/api/client/upload";
const GET_CALENDER_CONFIG = "/odio/api/client/get-calendar-config";
const UPDATE_CALENDAR_CONFIG = "/odio/api/client/update-calendar-config";
const UPDATE_CLIENT_LOGO = "/odio/api/client/upload";
const GET_CONTACT = "/odio/api/client/get-contact";
const UPDATE_CONTACT = "/odio/api/client/update-contact";
const GET_TIMEZONES = "/odio/api/master/timezones";
const GET_LOGO = "/odio/api/client/image"
const GET_TEAM_EXCEL = '/odio/api/user/team-excel'

const getClient = async () => {
  const response = await apiCall.get(GET_CLIENT);
  return response.data.data;
}

const updateClient = async (data) => {
    const response = await apiCall.put(UPDATE_CLIENT, data);
    toast.success(response.data.message);
    return response;
}

const setProfilePic = async (data) => {
    const response = await apiCall.post(UPLOAD_PROFILE, data);
    return response;
}

const getContact = async (id) => {
    const response = await apiCall.get(GET_CONTACT);
    return response.data.data;
}

const updateContact = async (data) => {
    const response = await apiCall.put(UPDATE_CONTACT, data);
    toast.success(response.data.message);
    return response;
}

const getCalenderConfigs = async () => {
    const response = await apiCall.get(GET_CALENDER_CONFIG);
    return response.data.data;
}

const getTimezones = async () => {
    const response = await apiCall.get(GET_TIMEZONES);
    return response.data.data;
}

const updateCalendarConfig = async (data) => {
    const response = await apiCall.put(UPDATE_CALENDAR_CONFIG, data);
    toast.success(response.data.message);
    return response;
}

const uploadClientLogo = async (data) => {
    const response = await apiCall.post(UPDATE_CLIENT_LOGO, data);
    toast.success(response.data.message);
    return response.data.data;
}

const getExcelFile = async (type, filteredData, localData) => {
  let GET_EXCEL = "";
  if (type === "USER" && type != "COE_USERS") {
    GET_EXCEL = `/odio/api/client/user-excel?${
      filteredData
        ? "search=" + filteredData.slice(0, filteredData.length - 1)
        : ""
    }`;
  }
  if (type === "COE" && type != "COE_USERS") {  
    GET_EXCEL = `/odio/api/client/coe-excel?${filteredData.length>1?filteredData.map(item=>{return `coeIds=${item?.coeId}`}).join("&"):`coeIds=${filteredData[0].coeId}`}`;
  }
  if (type === "MyTEAM") {
    GET_EXCEL = `/odio/api/user/myTeam-excel?search=${filteredData.slice(
      0,
      filteredData.length - 1
    )}&coeIds=${localData.selectedCoeRange}&fromD=${localData.fromD}&toD=${localData.toD}`;
  }
  if (type === "TEAM") {

    // ?${
    //   filteredData.length>0&&order==''?
    //   "coeIds="+coe+"&fromD="+from+"&search="+filteredData.slice(0,filteredData.length - 1)+"&toD="+to
    // :filteredData.length==0&&order==''&&teamStats&&callScore&&callScore[0]!=0&&callScore[1]!=100||filteredData.length==0&&order==''&&callScore&&callScore[0]==0&&callScore[1]!=100||filteredData.length==0&&order==''&&callScore&&callScore[0]!=0&&callScore[1]==100?"fromD="+from+"&toD="+to+teamStats
    // :filteredData.length==0&&order==''&&teamStats&&pitchScore&&pitchScore[0]!=0&&pitchScore[1]!=100||filteredData.length==0&&order==''&&pitchScore&&pitchScore[0]==0&&pitchScore[1]!=100||filteredData.length==0&&order==''&&pitchScore&&pitchScore[0]!=0&&pitchScore[1]==100?"fromD="+from+"&toD="+to+teamStats
    // :filteredData.length==0&&order==''&&teamStats&&aht&&aht[0]!=0&&aht[1]!=100||filteredData.length==0&&order==''&&aht&&aht[0]==0&&aht[1]!=100||filteredData.length==0&&order==''&&aht&&aht[0]!=0&&aht[1]==100?"fromD="+from+"&toD="+to+teamStats
    // :filteredData.length==0&&teamStats&&callsFrom&&callsTo?"fromD="+from+"&toD="+to+teamStats
    // :"coeIds="+coe+"&fromD="+from+"&toD="+to+"&sortBy="+order
    // }
  }
  if (type === "COE_USERS") {
    GET_EXCEL = `/odio/api/coe/coeUser-excel?coeId=${filteredData}`;
  }

  if(type === 'PRODUCTIVITY')
  { 
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_EXCEL = `/odio/api/call/insights/productivity/excel?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }

  if(type === 'DURATION_METRICS')
  {
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_EXCEL = `/odio/api/call/insights/durationMatrics/excel?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }

  if(type === 'CONVERSION_METRICS')
  {
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_EXCEL = `/odio/api/call/insights/conversionMatrics/excel?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }
  if(type === 'COMPARATIVE_ANALYSIS')
    {
      let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
      let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
      let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
      GET_EXCEL = `/odio/api/call/insights/comparativeAnalysis/excel?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
    }

  const response = await apiCall.get(GET_EXCEL, {
      responseType: "blob",
  })
  let filename =
    response.headers["content-disposition"].split("filename=")[1];
  const fileBlob = response.data;
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

const getTeamExcel = async(type, filteredData,teamStats,from,to,coe,callScore, pitchScore, aht, callsFrom, callsTo, order)=>{
  let GET_TEAM_EXCEL = '/odio/api/user/team-excel'
    let body={
      "aht": aht?aht[0]==0&&aht[1]==300?[]:[aht[0]*60,aht[1]*60]:[],
      "callScoreRange": callScore?callScore[0]==0&&callScore[1]==100?[]:callScore:[],
      "callsCount": callsFrom==''&&callsTo==''?[]:[parseInt(callsFrom),parseInt(callsTo)],
      "coeIds": coe,
      "fromD": from,
      "toD": to,
      "pitchRange": pitchScore?pitchScore[0]==0&&pitchScore[1]==100?[]:pitchScore:[],
      "search": filteredData.length>0&&order==''?filteredData.slice(0,filteredData.length - 1):'',
      "sortBy": order,
    }
  const response = await apiCall.post(GET_TEAM_EXCEL,body, {
    responseType: "blob"
  })
  let filename = response.headers["content-disposition"].split("filename=")[1];
  const fileBlob = response.data;
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

const getPdfFile = async (type, filteredData, localData) => {
  let GET_PDF = "";
  if (type === "USER" && type != "COE_USERS") {
    GET_PDF = `/odio/api/client/user-pdf?search=${filteredData}`;
  }
  if (type === "COE" && type != "COE_USERS") {
    GET_PDF = `/odio/api/client/coe-pdf?${filteredData.length>1?filteredData.map(item=>{return `coeIds=${item?.coeId}`}).join("&"):`coeIds=${filteredData[0].coeId}`}`;
  }
  if (type === "MyTEAM") {
    GET_PDF = `/odio/api/user/myTeam-pdf?search=${filteredData.slice(
      0,
      filteredData.length - 1
    )}&coeIds=${localData.selectedCoeRange}&fromD=${localData.fromD}&toD=${localData.toD}`;
  }
  if (type === "TEAM") {
    // GET_PDF = `/odio/api/user/Team-pdf?${
    //   filteredData.length>0&&order==''?
    //   "fromD="+from+"&search="+filteredData.slice(0,filteredData.length - 1)+"&toD="+to
    //   :filteredData.length==0&&order==''&&teamStats&&callScore&&callScore[0]!=0&&callScore[1]!=100||filteredData.length==0&&order==''&&callScore&&callScore[0]==0&&callScore[1]!=100||filteredData.length==0&&order==''&&callScore&&callScore[0]!=0&&callScore[1]==100?"fromD="+from+"&toD="+to+teamStats
    // :filteredData.length==0&&order==''&&teamStats&&pitchScore&&pitchScore[0]!=0&&pitchScore[1]!=100||filteredData.length==0&&order==''&&pitchScore&&pitchScore[0]==0&&pitchScore[1]!=100||filteredData.length==0&&order==''&&pitchScore&&pitchScore[0]!=0&&pitchScore[1]==100?"fromD="+from+"&toD="+to+teamStats
    // :filteredData.length==0&&order==''&&teamStats&&aht&&aht[0]!=0&&aht[1]!=100||filteredData.length==0&&order==''&&aht&&aht[0]==0&&aht[1]!=100||filteredData.length==0&&order==''&&aht&&aht[0]!=0&&aht[1]==100?"fromD="+from+"&toD="+to+teamStats
    // :filteredData.length==0&&order==''&&teamStats&&callsFrom&&callsTo?"fromD="+from+"&toD="+to+teamStats
    // :"fromD="+from+"&toD="+to+"&sortBy="+order}`;
  }
  if (type === "COE_USERS") {
    GET_PDF = `/odio/api/coe/coeUser-pdf?coeId=${filteredData}`;
  }
  if(type === 'PRODUCTIVITY')
  { 
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_PDF = `/odio/api/call/insights/Productivity-pdf?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }
  if(type === 'DURATION_METRICS')
  {
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_PDF = `/odio/api/call/insights/DurationMatrics-pdf?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }

  if(type === 'CONVERSION_METRICS')
  {
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_PDF = `/odio/api/call/insights/ConversionMatrics-pdf?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }
  if(type === 'COMPARATIVE_ANALYSIS')
  {
    let fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    let toD = JSON.parse(localStorage.getItem("AGENT"))?.toD
    let userIds = JSON.parse(localStorage.getItem("AGENT"))?.userIds
    GET_PDF = `/odio/api/call/insights/ComparativeAnalysis-pdf?fromD=${fromD}&toD=${toD}&userIds=${userIds}`
  }
    const response = await apiCall.get(GET_PDF, {
      responseType: "blob",
    });
    let filename =
      response.headers["content-disposition"].split("filename=")[1];
    const fileBlob = response.data;
    const url = window.URL.createObjectURL(new Blob([fileBlob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

const getTeamPdf = async(type,filteredData,teamStats,from,to,coe, callScore, pitchScore, aht, callsFrom, callsTo, order)=>{
  let GET_TEAM_PDF = '/odio/api/user/Team-pdf'
  let body={
    "aht": aht?aht[0]==0&&aht[1]==300?[]:[aht[0]*60,aht[1]*60]:[],
    "callScoreRange": callScore?callScore[0]==0&&callScore[1]==100?[]:callScore:[],
    "callsCount": callsFrom==''&&callsTo==''?[]:[parseInt(callsFrom),parseInt(callsTo)],
    "coeIds": coe,
    "fromD": from,
    "toD": to,
    "pitchRange": pitchScore?pitchScore[0]==0&&pitchScore[1]==100?[]:pitchScore:[],
    "search": filteredData.length>0&&order==''?filteredData.slice(0,filteredData.length - 1):'',
    "sortBy": order,
  }
  const response = await apiCall.post(GET_TEAM_PDF,body, {
    responseType: "blob",
  });
  let filename = response.headers["content-disposition"].split("filename=")[1];
  const fileBlob = response.data;
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

const getLogo = async(data)=>{
    const GET_LOGO = `/odio/api/client/image?keyName=${data.keyName}`
    const response = await apiCall.get(GET_LOGO)
    return response.data.data
}

const removeLogo = async (data) => {
    const REMOVE_LOGO = `/odio/api/client/remove-logo?fileName=${data}`;
    const response = await apiCall.put(REMOVE_LOGO);
    toast.success(response.data.message, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    return response.data.data;
}

const getCompetitors =  async (data) => {
    const GET_COMPETITOR = "/odio/api/competitor/list"
    const response =  await apiCall.get(GET_COMPETITOR);
    return response
}

const createCompetitor = async (data) => {
  const CREATE_COMPETITOR = "/odio/api/competitor/create"
  const response = await apiCall.post(CREATE_COMPETITOR, data)
  return response
}

const updateCompetitor = async (data) => {
  const UPDATE_COMPETITOR = "/odio/api/competitor/update"
  const response = await apiCall.put(UPDATE_COMPETITOR, data)
  return response
}

const deleteCompetitor = async (id) => {
    const DELETE_COMPETITOR = `/odio/api/competitor/delete?id=${id}`
    const response = await apiCall.delete(DELETE_COMPETITOR)
    return response
}

export default {
  getClient,
  getContact,
  updateClient,
  setProfilePic,
  updateContact,
  getCalenderConfigs,
  updateCalendarConfig,
  uploadClientLogo,
  getExcelFile,
  getTeamExcel,
  getPdfFile,
  getTeamPdf,
  getTimezones,
  getLogo,
  removeLogo,
  getCompetitors,
  createCompetitor,
  updateCompetitor,
  deleteCompetitor
};
