import React, { useEffect, useState } from "react";
import "./knowledgeBase.css";
import Box from "@mui/material/Box";
import QuestionBank from "./QuestionBank";
import FolderAccordianList from "./FolderAccordianList";
import { Modal, Button } from "react-bootstrap";
import { Input } from "@mui/joy";
import { createFolder, getFolderList, getDocumentList, updateFolder, deleteFolder } from "../../Services/knowledgeBase.service";
import { toast } from "react-toastify";
import KnowledgeBaseBanner from "./KnowledgeBaseBanner";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import toaster from '../../Toast/toaster';
import { Typography } from "@mui/material";

function CoachingKnowledgeBase() {
  const history = useHistory()
  const [folder, setFolder] = useState(history.location.state?.tabValue || 'resource')
  const [selectedRowData, setSelectedRowData] = useState('');
  const [resourceLoading, setResourceLoading] = useState(true)
  const [accordions, setAccordions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [folderNameInput, setFolderNameInput] = useState("");
  const [editedFolderName, setEditedFolderName] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [buttonValue, setButtonValue] = useState('');
  const [totalCOunt, setTotalCOunt] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [type, setType] = useState('')
  const [hasMoreResources, setHasMoreResources] = useState(true)
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [folderCreated, setFolderCreated] = useState(false)
  const [folderUpdated, setFolderUpdated] = useState(false)
  const [folderDeleted, setFolderDeleted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [delFolder, setDelFolder] = useState(false);
  const [delFolderId, setDelFolderId] = useState('');
  const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;
  const [maxLimitReached, setMaxLimitReached] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [folderDeleted, folderCreated, folderUpdated]);

  useEffect(() => {
    document.title = "Knowledge Base - Odio";
  }, []);

  const handleTabSelect = (key) => {
    setFolder(key);
  }

  const handleCreateFolder = () => {
    setOpenModal(true);
  };

  const handleSaveClick = (id, e, type) => {
    const reqBody = {
      "name": editedFolderName[id],
      "clientCoeIds": JSON.parse(localStorage.getItem("AGENT")) && JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange,
    }
    updateFolder(reqBody, id).then(response => {
      if (response?.data?.status === 0) {
        setEditedFolderName('')
        setType('')
        setFolderUpdated(true)
        setResetPage(true);
      }
    }).catch((err) => { console.log("Api call error: ", err) })
  };

  const handleNameChange = (id, value) => {
    // setEditedFolderName(value)
    setEditedFolderName((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleDeleteClick = (id, e, folder, buttonValue) => {
    e.stopPropagation();
    setDelFolderId(id)
    setSelectedRowData(folder)
    setIsOpen(true);
    setButtonValue(buttonValue);
  };
  const handleSubmitCreateFolder = () => {
    const reqBody = {
      "name": folderNameInput,
      "clientCoeIds": JSON.parse(localStorage.getItem("AGENT")) && JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange,
    }
    createFolder(reqBody).then((response) => {
      if (response?.data?.status === 0) {
        setOpenModal(false);
        setFolderNameInput("");
        setFolderCreated(true)
        setResetPage(true);
        toaster.success("Folder created successfully");
      }
      else {
        toaster.error("Folder Name must be unique");
      }
    }).catch((err) => { console.log("Api call error: ", err) })
  }

  const getFoldersList = () => {
    setIsScrollLoading(currentPage > 1);
    const reqBody = {
      "coeIds": role === 'AGENT' ? [JSON.parse(localStorage.getItem('coeId'))] : JSON.parse(localStorage.getItem("AGENT")) && JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange,
      "input": searchQuery.length > 0 ? searchQuery : undefined,
    }
    getFolderList(reqBody, currentPage, 9).then((response) => {
      if (response?.data?.status === 0) {
        setAccordions((prev) => [...prev, ...response.data.data.data]);
        setHasMoreResources(currentPage < response.data.data.totalPages);
        setTotalCOunt(response?.data?.data?.totalPages)
        setResourceLoading(false)
      } else {
        setResourceLoading(false)
        setIsScrollLoading(false)
      }
    }).
      catch((err) => {
        setIsScrollLoading(false)
      })
  }

  useEffect(() => {
    if (folder === 'resource') {
      if (searchQuery !== "") {
        setSearchQuery("");
      } else {
        setResetPage(true);
      }
    }
  }, [folder]);

  useEffect(() => {
    if (delFolder) {
      deleteFolder(delFolderId)
        .then((res) => {
          // toaster.success(res.data.message)
          toaster.success("Folder deleted successfully");
          setDelFolder(false)
          setResetPage(true);
          setDelFolderId('')
        })
        .catch((error) => {
          toaster.error("Unable to delete Folder. Please try again.")
          setDelFolder(false)
          setDelFolderId('')
        })
    }
  }, [delFolder])


  const loadMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setShouldFetch(true);
  };

  const resetFetchFlags = () => {
    setFolderCreated(false);
    setFolderUpdated(false);
    setFolderDeleted(false);
    setDelFolder(false)
    setDelFolderId('')
  };

  const handleInputChange = (e) => {
    const value = e.target?.value || "";
    if (value.length <= 100) {
      setFolderNameInput(value);
      setMaxLimitReached(false);
    } else {
      setMaxLimitReached(true);
    }
  };

  useEffect(() => {
    if (folder == 'resource') {
      if (searchQuery.trim().length > 0) {
        const debounceTimeout = setTimeout(() => {
          setResetPage(true);
        }, 800);

        return () => clearTimeout(debounceTimeout);
      } else if (searchQuery.trim().length === 0) {
        setResetPage(true);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    if (folder === 'resource') {
      if (resetPage) {
        setCurrentPage(1);
        setAccordions([]);
        setShouldFetch(true);
        setResetPage(false);
      } else if (currentPage > 1) {
        setShouldFetch(true);
      }
      resetFetchFlags();
    }
  }, [currentPage, resetPage]);

  useEffect(() => {
    if (shouldFetch && (folder == "resource")) {
      setResourceLoading(currentPage === 1);
      getFoldersList();
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSearchQuery(searchQuery);
  };

  const handleSearchClose = () => {
    setSearchQuery('');
  };

  const handleCloseDeleteModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      {openModal && <Modal
        size="lg"
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby="manager-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="manager-modal">Create folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "30ch" } }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{display:'flex'}}>
            <Input
              id="outlined-basic"
              value={folderNameInput}
              placeholder="Enter Folder Name"
              onChange={(e) => handleInputChange(e)}
            />
            <Typography
              sx={{
                color: folderNameInput.length === 100 ? "red" : "gray",
                fontSize: "12px",              
                marginLeft: "10px",
                display:'flex',
                alignItems: 'end',
              }}
            >
              {folderNameInput.length}/100
            </Typography>
            </Box>
            {/* {maxLimitReached && (
              <Typography sx={{ color: "red", fontSize: "0.875rem" }}>
                Maximum character limit reached
              </Typography>
            )} */}
            <Button
              variant={folderNameInput.trim() ? "primary" : "secondary"}
              className="custom-create-folder-btn"
              // id="manager-close-button" 
              onClick={handleSubmitCreateFolder}
              disabled={!folderNameInput.trim()}>
              Submit
            </Button>
          </Box>
        </Modal.Body>
      </Modal>}
      <div className="page-wrapper">
        <div className="page-content">
          <KnowledgeBaseBanner folder={folder} handleTabSelect={handleTabSelect} searchQuery={searchQuery} handleSearchChange={handleSearchChange} handleSearchClose={handleSearchClose} />
          <div className='container pt-4'>
            {folder == "resource" ?
              <FolderAccordianList
                accordions={accordions}
                handleCreateFolder={handleCreateFolder}
                handleSaveClick={handleSaveClick}
                handleNameChange={handleNameChange}
                handleDeleteClick={handleDeleteClick}
                setAccordions={setAccordions}
                editedFolderName={editedFolderName}
                setEditedFolderName={setEditedFolderName}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                buttonValue={buttonValue}
                selectedRowData={selectedRowData}
                resourceLoading={resourceLoading}
                moreData={loadMoreData}
                hasMoreData={hasMoreResources}
                scroll={isScrollLoading}
                setResetPage={setResetPage}
                setFolderDeleted={setFolderDeleted}
                folderCreated={folderCreated}
                folderUpdated={folderUpdated}
                folderDeleted={folderDeleted}
                tabValue={folder}
                handleCloseDeleteModal={handleCloseDeleteModal}
                setDelFolder={setDelFolder}
              /> :
              <QuestionBank tabValue={folder} searchQuery={searchQuery} handleSearchClose={handleSearchClose} />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachingKnowledgeBase;