import {GET_SINGLE_CALL_DATA, SET_NON_LIVE_DATA} from './type'

const initialState = {
    singleCallData:[],
    nonLiveAssist:{}
}

const mergeData = (existingData, newData) => {
    const updatedData = { ...existingData };
    Object.keys(newData).forEach(key => {
        if (Array.isArray(newData[key])) {
            updatedData[key] = mergeArray(existingData[key] || [], newData[key]);
        } else {
            updatedData[key] = newData[key];
        }
    });
    return updatedData;
    
};

// Helper function to merge arrays based on a unique key (e.g., `moment`)
const mergeArray = (oldArray = [], newArray = []) => {
    const map = new Map();
    oldArray.concat(newArray).forEach(item => map.set(item.moment, item));
    return Array.from(map.values());
};

const nonLiveReducer = (state=initialState, action)=>{
    switch(action.type){
        case GET_SINGLE_CALL_DATA : {
            return{
                ...state,
                singleCallData : action.payload  //data required to get socket_id, phnNumber, streamid is being stored here
            }
        }
        case SET_NON_LIVE_DATA: {
            const nonLiveData = action.payload?.nudges;
            return {
                ...state, 
                nonLiveAssist: nonLiveData,
                assistedUserDetail: action?.payload?.userDetail
            };
        }
        default: {
            return state;
        }
    }
}

export default nonLiveReducer