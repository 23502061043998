import { apiCall } from "./Interceptor";

const GET_COACHING_SESSIONS = "/odio/api/coaching/session/list"
const CREATE_COACHING_SESSION = "/odio/api/coaching/session/create"
const GET_CATEGORY_LIST = '/odio/api/coaching/category/list'
const UPDATE_SESSION_STATUS = '/odio/api/coaching/session/update_status/';
const GET_AGENTS_LIST_BY_CATEGORY_ID = '/odio/api/coaching/session/category/data';
const SUBMIT_COACHING_ASSESSMENT = '/odio/api/coaching/session/assignment/submit'
const MARK_DOC_COMPLETE = '/odio/api/coaching/session/document/submit'

export const getAgentsListByCategoryId= (reqBody) => {
    const response = apiCall.post(GET_AGENTS_LIST_BY_CATEGORY_ID, reqBody);
    return response
}
export const getCoachingSessions = (id,currentPage,pageSize) => {
    const url = id ? `${GET_COACHING_SESSIONS}?agentId=${id}&page=${currentPage}&size=${pageSize}` : GET_COACHING_SESSIONS;
    const response = apiCall.get(url)
    return response
}  


export const updateSessionStatus = (coachingSessionId, reqBody) => {
    const response = apiCall.put(UPDATE_SESSION_STATUS + coachingSessionId, reqBody);
    return response
}

export const getCategoryList = () => {
    const response = apiCall.get(GET_CATEGORY_LIST)
    return response
}

export const createCoachingSession = (val) => {
    const response = apiCall.post(CREATE_COACHING_SESSION, val)
    return response
}

export const getCoachingTeamStats = (id, name, userId) => {
    const response = apiCall.get(userId ? `/odio/api/coaching/session/details?coachingSessionId=${id}&name=${name}&userId${userId}` : `/odio/api/coaching/session/details?coachingSessionId=${id}&name=${name}`)
    return response
}

export const getCoachingUserStats = (userId) => {
    const response = apiCall.get(`/odio/api/coaching/session/agent/details?userId=${userId}`)
    return response
}

export const uploadCoachingMedia = (moment,data) => {
    const response = apiCall.post(`/odio/api/coaching/session/upload/media/${moment}`, data)
    return response
}

export const getCoachingSessionDetail = (id)=>{
    const response = apiCall.get(`odio/api/coaching/session/${id}`)
    return response
}

export const submitCoachingAssessment = (reqBody)=>{
    const response = apiCall.post(SUBMIT_COACHING_ASSESSMENT, reqBody)
    return response
}

export const markDocCompleted = (reqBody) =>{
    const response = apiCall.put(MARK_DOC_COMPLETE, reqBody)
    return response
}