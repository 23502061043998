import { apiCall } from "./Interceptor"

const GET_QUESTION_BANK_LIST = '/odio/api/questionbank/'
const CREATE_QUESTION_BANK = '/odio/api/questionbank/create'
const DELETE_QUESTION_BANK = '/odio/api/questionbank/delete/'
const UPDATE_QUESTION_BANK = '/odio/api/questionbank/update/'
const GET_EDIT_QUES_LIST = '/odio/api/questionbank/questions'
const GET_QUESTIONS_LIST = '/odio/api/questionbank/questions'
const CREATE_QUESTION = '/odio/api/questionbank/questions/create'
const DELETE_QUESTION = '/odio/api/questionbank/questions/delete'

export const getQuestionBankList = (reqBody,page,pageSize)=>{
  if(!page && !pageSize){
    const response = apiCall.post(`${GET_QUESTION_BANK_LIST}`, reqBody)
    return response;
  }
  else{
    const response = apiCall.post(`${GET_QUESTION_BANK_LIST}?page=${page}&size=${pageSize}`, reqBody)
    return response;
  }
}

export const createQuestionBank = (reqBody)=>{
  const response = apiCall.post(`${CREATE_QUESTION_BANK}`,reqBody)
  return response
}

export const deleteQuestionBank = (id)=>{
  const response = apiCall.delete(`${DELETE_QUESTION_BANK}${id}`)
  return response
}

export const updateQuestionBank = (id, reqBody)=>{
  const response = apiCall.put(`${UPDATE_QUESTION_BANK}${id}`, reqBody)
  return response
}

export const getEditedQueList = (qBankId, qId)=>{
  const response = apiCall.get(`${GET_EDIT_QUES_LIST}/${qBankId}/${qId}`)
  return response
}

export const getQuestionsList = (questionBankId)=>{
  const response = apiCall.get(`${GET_QUESTIONS_LIST}/${questionBankId}`)
  return response
}

export const createQuestions = (qBankId, reqBody)=>{
  const response = apiCall.post(`${CREATE_QUESTION}?questionBankId=${qBankId}`, reqBody)
  return response
}

export const updateQuestions = (qBankId, questionId, reqBody) => {
  const response = apiCall.put(`/odio/api/questionbank/questions/update/${qBankId}/${questionId}`, reqBody)
  return response
}

export const deleteQuestions = (qBankId, questionId)=>{
  const response = apiCall.delete(`${DELETE_QUESTION}/${qBankId}/${questionId}`)
  return response
}