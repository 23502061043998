import React, { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import insightService from "../../Services/insight.service";
import dashboardService from "../../Services/dashboard.service";
import Loading from "../../Commons/Loading";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { apiCall } from "../../Services/Interceptor";
import QuestionsGraph from "./QuestionsGraph";
import QuestionsData from "./QuestionsData";
import FilterDrawer from "../../Commons/FilterDrawer";
import { onResetFilter, setDateList, setResetState, setUserOptions } from "../../../redux-container/insights/action";
import { useDispatch, useSelector } from "react-redux";


export default function QuestionsTab(){

    let localData = JSON.parse(localStorage.getItem("AGENT"))

    const [customInput, SetCustomInput] = useState(localData&&localData.selectedRange==9?true:localData&&localData.selectedRange==''?true:false);
    const [date, setDate] = useState(localData&&localData.selectedRange?localData.selectedRange:localData&&localData.selectedRange==''?9:5);
    const [dates,setDates] = useState([])
    const [startDate, setStartDate] = useState(localData?.fromD?new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`):new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD?new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`):new Date())
    const [userList, setUserList] = useState([])
    const [defaultFilteredData, setDefaultFilteredData] = useState([])
    const [dataQuestion,setDataQuestion] = useState([]) 
    const [loader, setLoader] = useState(false)
    const [def, setDef] = useState(false)
    const [apply, setApply] = useState(false)
    const [reset, setReset] = useState(false)
    const dispatch = useDispatch()
    let filteredData = useSelector(state=>state?.insights?.filterData)
    let options = useSelector(state=>state.insights.userOptions)
  
    
    useEffect(() => {
        document.title = "Questions  - Odio"
      }, [])
    
    const getDateAcronym = ()=>{
        dashboardService.getDateAcronym().then(res=>{
        let dateList = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
        }):'':''
        setDates(dateList);
        dispatch(setDateList(dateList))
        let dd = dateList[4].fromDate.split("-")[2]
        let mm = dateList[4].fromDate.split("-")[1]
        let yyyy = dateList[4].fromDate.split("-")[0]
        let dateF = `${dd}-${mm}-${yyyy}`
        let dd_1 = dateList[4].toDate.split("-")[2]
        let mm_1= dateList[4].toDate.split("-")[1]
        let yyyy_1= dateList[4].toDate.split("-")[0]
        let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
        setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,
            fromDate:dateT,
            toDate:dateF}))
        })
    }

    const getUserList = async()=>{
        const res = await apiCall.get(`${'/odio/api/user/get-user-team'}?fromD=${localData?.fromD?localData.fromD:null}&toD=${localData?.toD?localData.toD:null}&coeIds=${localData?.selectedCoeRange?localData.selectedCoeRange:''}`); 
          if(res?.data?.status === 0){
            setUserList(res?.data?.data?.team)
            let userIds = res?.data?.data?.team.map(item=>{
              return item.id
            })
            const teamOptions = res?.data?.data?.team.map((e,i)=>
                (
                  {"label":`${e.firstName} ${e.lastName}`,"value":`${e.id}`,"id":e.id}
                ))
            const optionsWithSelectAll = [...teamOptions];
            dispatch(setUserOptions(optionsWithSelectAll))
            setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,id:userIds}))
          }else if(res.data.status === 1){
            setLoader(false);
          }
    }

    const getQuestionData = ()=>{
        let localData = JSON.parse(localStorage.getItem("AGENT"))
        const values= {
            "fromD":localData?.fromD,
            "toD":localData?.toD,
            "userIds":localData?.userIds ? localData.userIds : defaultFilteredData.id
        }
        insightService.getQuestion(values).then(res=>{
            if(res){
                setDataQuestion(res.data.data)
                setLoader(false)
                setApply(false)
                setReset(false)
                dispatch(setResetState(false))
            }
        })
    }

    useEffect(()=>{
        getDateAcronym()
        getUserList()
    },[])


    useEffect(()=>{
        setLoader(true)
        if(defaultFilteredData?.id?.length>0){
            getQuestionData()
            localStorage.setItem("AGENT",JSON.stringify({
                "pageSelected":localData?.pageSelected,
                "analysis":localData?.analysis,
                "fromD":localData&&localData.fromD?localData.fromD:defaultFilteredData.fromDate,
                "toD":localData&&localData.toD?localData.toD:defaultFilteredData.toDate,
                "agentDetailCOE":localData?.agentDetailCOE,
                "selectedRange":date?date:localData?.selectedRange,
                "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
                "userIds":localData?.userIds?localData?.userIds:defaultFilteredData.id,
                "userNames":localData?.userNames?localData.userNames:[{label:"Select All",value:"Select All"}],
                "empId":localData?.employeeId,
                "empName":localData?.user,
                "role":localData?.role,
                "designation":localData?.designation,
                "type":localData?.type,
                "teamCOE":localData?.teamCOE,
                "callsFrom":localData?.callsFrom,
                "callsTo":localData?.callsTo,
                "aht":localData?.aht,
                "callScore":localData?.callScore,
                "pitchScore":localData?.pitchScore,
                "customerName":localData?.customerName,
                "customerMobile":localData?.customerMobile,
                "fileName":localData?.fileName,
                "sellerROS":localData?.sellerROS,
                "sellerName":localData?.sellerName,
                "selectedSeller":localData?.selectedSeller,
                "cScore":localData?.cScore,
                "callHealth":localData?.callHealth,
                "callDuration":localData?.callDuration,
                "words":localData?.words,
                "moment":localData?.moment,
                "momentId":localData?.momentId,
                "pitch":localData?.pitch,
                "prob":localData?.prob,
                "opportunity":localData?.opp,
                "urgency":localData?.urgency,
                "coe":localData?.coe,
                "selectedCoeRange":localData?.selectedCoeRange,
                "selectedCOE":localData?.selectedCOE,
                "momentBucket":localData?.momentBucket,
                "momentBucketId":localData?.momentBucketId,
                "frequency":localData?.frequency,
                "reportType":localData?.reportType
            }))
        }
    },[defaultFilteredData])

    useEffect(()=>{
        if(apply){
            onSubmitHandler()
        }
        if(reset){
            onResetHandler()
            const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            dispatch(onResetFilter({"productivity":"Call Score", "fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"selectedRange":5,"selectedDate":"This Month"}))
            dispatch(setResetState(true))
        }
    },[apply, reset])

    // const handleAgentChange = (event,values)=>{
    //     let ids = []
    //     let arr = []
    //     let names = []
    //     setSelectedAgent(values)
    //     if(values.length>1){
    //         arr = values.map(item=>{return item.value})
    //     }
    //     else if(values.length==0){
    //         arr = defaultFilteredData.id
    //     }
    //     else if(values[0].label=='Select All'){
    //         arr = defaultFilteredData.id
    //     }
    //     else{
    //         arr.push(values[0].value)
    //     }
    //     let numberArray = arr.map(Number);
    //     let selectedAgents = []
    //     selectedAgents = userList.filter(item=>{
    //         arr.includes(item.id)
    //     })
    //     setFilteredData({...filteredData,id:arr,userNames:values})
    // }

    // const handleChange = (event) =>{
    //     setDate(event.target.value)
    //     setDef(false)
    //     if(event.target.value == 9){
    //         SetCustomInput(true)
    //         setFilteredData({...filteredData,dateName:"Custom Date"})
    //     }
    //     else{
    //         SetCustomInput(false)
    //         let SelectedDate = dates.filter(function (el) {
    //             return el.id == event.target.value;
    //         })
    //         let dd = SelectedDate[0].fromDate.split("-")[2]
    //         let mm = SelectedDate[0].fromDate.split("-")[1]
    //         let yyyy = SelectedDate[0].fromDate.split("-")[0]
        
    //         let dd_1 = SelectedDate[0].toDate.split("-")[2]
    //         let mm_1= SelectedDate[0].toDate.split("-")[1]
    //         let yyyy_1= SelectedDate[0].toDate.split("-")[0]
        
    //         let dateF = `${dd}-${mm}-${yyyy}`
    //         let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
    
    //         setFilteredData({...filteredData,fromDate: dateT,toDate:dateF,dateName:SelectedDate[0].name})
    //     }
    // }

    // const handleStartDate = (e)=>{
    //     setStartDate(e)
    //     let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
    //     setFilteredData({...filteredData,fromDate: date})
    // }
    
    // const handleEndDate = (e)=>{
    //     setEndDate(e)
    //     let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
    //     setFilteredData({...filteredData,toDate: date})
    // }

    const onSubmitHandler = ()=>{
        localStorage.setItem("AGENT",JSON.stringify({
            "pageSelected":localData?.pageSelected,
            "analysis":localData?.analysis,
            "fromD":filteredData?.fromD?filteredData.fromD:localData?.fromD,
            "toD":filteredData?.toD?filteredData?.toD:localData?.toD,
            "agentDetailCOE":localData?.agentDetailCOE,
            "selectedRange":filteredData?.selectedRange?filteredData?.selectedRange : localData?.selectedRange,
            "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
            "userIds":filteredData?.id?filteredData?.id:localData?.userIds,
            "userNames":filteredData?.names?filteredData.names:localData?.userNames,
            "empId":localData?.employeeId,
            "empName":localData?.user,
            "role":localData?.role,
            "designation":localData?.designation,
            "type":localData?.type,
            "teamCOE":localData?.teamCOE,
            "callsFrom":localData?.callsFrom,
            "callsTo":localData?.callsTo,
            "aht":localData?.aht,
            "callScore":localData?.callScore,
            "pitchScore":localData?.pitchScore,
            "customerName":localData?.customerName,
            "customerMobile":localData?.customerMobile,
            "fileName":localData?.fileName,
            "sellerROS":localData?.sellerROS,
            "sellerName":localData?.sellerName,
            "selectedSeller":localData?.selectedSeller,
            "cScore":localData?.cScore,
            "callHealth":localData?.callHealth,
            "callDuration":localData?.callDuration,
            "words":localData?.words,
            "moment":localData?.moment,
            "momentId":localData?.momentId,
            "pitch":localData?.pitch,
            "prob":localData?.prob,
            "opportunity":localData?.opp,
            "urgency":localData?.urgency,
            "coe":localData?.coe,
            "selectedCoeRange":localData?.selectedCoeRange,
            "selectedCOE":localData?.selectedCOE,
            "momentBucket":localData?.momentBucket,
            "momentBucketId":localData?.momentBucketId,
            "frequency":localData?.frequency,
            "reportType":localData?.reportType
        }))
        if(userList?.length == 0){
            getUserList();
        }
        getQuestionData()
    }
    
    const onResetHandler = ()=>{
        const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        localStorage.setItem("AGENT",JSON.stringify({"pageSelected":localData?.pageSelected,"analysis":localData?.analysis,"productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"agentDetailCOE":localData?.agentDetailCOE,"selectedRange":5,"selectedDate":"This Month","userIds":defaultFilteredData.id,"userNames":options.map(item=>item.value),"coe":localData?.coe,"selectedCoeRange":localData?.selectedCoeRange,"selectedCOE":localData?.selectedCOE,"momentBucket":localData?.momentBucket,"momentBucketId":localData?.momentBucketId}))
        getQuestionData()        
    }

    const applyFilter = ()=>{
        setApply(true)
    }

    const resetFilter = ()=>{
        setReset(true)
    }

    const setLoaderTrue = ()=>{
        setLoader(true)
    }

    return(
        <>
        
        <div className="page-wrapper">
            <div className="page-content">
                {loader?
                    <div className="loader-container">
                        <Loading variant="dark"/>
                    </div>
                :
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={12} className="mb-2">
                                <h2 className="dashboard-headings d-inline">Questions</h2>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Body>
                                        {/* <div class="mb-3">
                                            <label class="form-label ">Employee</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                filterSelectedOptions
                                                isOptionEqualToValue={(option, value) => option.label===value.label}
                                                onChange={handleAgentChange}
                                                getOptionDisabled = {option=>{
                                                    if(selectedAgent?.some(opt=>opt.label==='Select All')){
                                                        return true
                                                    }else if(selectedAgent && selectedAgent.length > 0 && option.label === 'Select All'){
                                                        return true
                                                    }
                                                }}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.id}>
                                                        {option.label}
                                                        </li>
                                                    );
                                                }}
                                                options={userOptions||[]}
                                                getOptionLabel={(option) => option?.label}
                                                defaultValue={selectedValues}
                                                renderInput={(params) => (
                                                    <TextField
                                                    {...params}
                                                    placeholder="Employees"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="ActiveLog-Date mb-3 d-flex flex-xl-fill">
                                            <div className="d-flex align-items-baseline">
                                                <label className="form-label me-1">Date</label>
                                                <Select value={date} id="inputRole" className=" p025 br025" name="saledateSelected" onChange={handleChange} sx={{width:200}}>
                                                    {dates?dates.map(item=>{return(<MenuItem  key={item.id} value={item.id}>{item.name}</MenuItem>)}):''}
                                                </Select>
                                                {customInput?<div className="call-fillter-date dasbard-comp-setting ms-1">
                                                    <div className="input-box form-group">
                                                        <label className="col-form-label mx-1">From:</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            inputFormat="DD-MM-YYYY"
                                                            value={startDate}
                                                            name="startDate"
                                                            onChange={handleStartDate}
                                                            disableOpenPicker
                                                            open={opendp}
                                                            onOpen={() => setOpendp(true)}
                                                            onClose={() => setOpendp(false)}
                                                            maxDate={new Date()}
                                                            renderInput={(params) => (
                                                                <TextField {...params} onClick={()=> setOpendp(true)} />
                                                            )}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className="input-box form-group">
                                                        <label className="col-form-label mx-1">To:</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            inputFormat="DD-MM-YYYY"
                                                            value={endDate}
                                                            name="endDate"
                                                            onChange={handleEndDate}
                                                            disableOpenPicker
                                                            open={opendp1}
                                                            onOpen={() => setOpendp1(true)}
                                                            onClose={() => setOpendp1(false)}
                                                            maxDate={new Date()}
                                                            renderInput={(params) => (
                                                                <TextField {...params} onClick={()=> setOpendp1(true)} />
                                                            )}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>: ''}
                                            </div>
                                            <button type="submit" className="btn px-4 lh-base update-btn insight-button" onClick={onSubmitHandler}>Apply</button>
                                            <button type="submit" className="btn px-4 lh-base update-btn" onClick={onResetHandler}>Reset</button>
                                        </div> */}
                                        <div className="productivity-date-section">
                                            <Tabs defaultActiveKey="heat_map" id="uncontrolled-tab-example" className="mb-3 mx-0 internal-tabs">
                                                <Tab eventKey="heat_map" title="Graph">
                                                    <QuestionsGraph loader={loader} def={def} filter={dataQuestion} />
                                                </Tab>
                                                <Tab eventKey="data" title="Data">
                                                    <QuestionsData loader={loader} def={def} data={dataQuestion} />
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            </div>
        </div>
        <FilterDrawer filterType='INSIGHTS' componentType='PRODUCTIVITY' applyFilter={applyFilter} setLoaderTrue={setLoaderTrue} resetFilter={resetFilter}/>
        </>
    )
}