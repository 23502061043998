import React, { useState, useEffect, useRef, useCallback } from "react";
import "./knowledgeBase.css";
import { Breadcrumb } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useHistory } from "react-router-dom";
import Loading from "../../Commons/Loading";
import { getFolderSuggestionList } from "../../Services/knowledgeBase.service";
import { debounce } from "lodash";

function KnowledgeBaseBanner(props) {
  const history = useHistory();
  const pathName = history.location.pathname.slice(1);
  const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;
  const isRedirectedFromCoaching = props?.redirectFrom === "coachingUserStats";
  const [currentTab, setCurrentTab] = useState(props?.folder)
  const [searchQuery, setSearchQuery] = useState("");
  const [folderSuggestions, setFolderSuggestions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Debounced fetch function
  const debouncedFetchFolderSuggestions = useCallback(
    debounce(async (query) => {
      setIsLoading(true);
      try {
        const reqBody = {
          coeIds:
            role === "AGENT"
              ? [JSON.parse(localStorage.getItem("coeId"))]
              : JSON.parse(localStorage.getItem("AGENT")) &&
                JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange,
          input: query,
        };
        const res = await getFolderSuggestionList(reqBody, currentTab);
        setFolderSuggestions(res?.data.data || []);
      } catch (error) {
        console.error("Error fetching folder suggestions:", error);
        setFolderSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    }, 400),
    [role, currentTab]
  );

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length >= 3) {
      debouncedFetchFolderSuggestions(query);
      setIsDropdownOpen(true);
    } else {
      setFolderSuggestions([]);
      setIsDropdownOpen(false);
    }
  };

  const handleSelectFolder = (folder) => {
    setSearchQuery(folder.name);
    setIsDropdownOpen(false);
    documentListRedirection(folder.id, folder);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current !== event.target
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const documentListRedirection = (folderId, folder) => {
    if(currentTab == "resource"){
    const folderData = { ...folder };
    history.push("/document_list", { folderData: folderData, tabValue: 'resource' });
    localStorage.setItem("folderId", folderId);
    }
    if(currentTab == "qbank"){
      history.push(`/knowledge_base/questions?qbankId=${folderId}`, { title: folder?.name, qbankId: folderId, tabValue: currentTab })
    }
  };

  const searchCleanUp = () => {
    setSearchQuery("");
    setFolderSuggestions([]);
    setIsDropdownOpen(false);
  }

  return (
    <>
      <div className="header-banner">
        <div className="container">
          {pathName !== "knowledge_base" && (
            <Breadcrumb className="custom-breadcrumb">
              <Breadcrumb.Item
                onClick={() =>
                  isRedirectedFromCoaching
                    ? history.push("/coaching_user_stats")
                    : history.push("/knowledge_base")
                }
              >
                {isRedirectedFromCoaching ? "Coaching" : "Knowledge Base"}
              </Breadcrumb.Item>
              {!isRedirectedFromCoaching && (
                <Breadcrumb.Item
                  onClick={() =>
                    history.push("/document_list", {
                      folderData: { name: props?.folderName, id: props?.folderId },
                      tabValue: "resource",
                    })
                  }
                >
                  {props?.folderName}
                </Breadcrumb.Item>
              )}
              <Breadcrumb.Item style={{ color: "white" }} active>
                {props?.componentName}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
          <div className="text-layout">
            <div
              className="column-left d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <h1 className="category-name">
                {pathName === "knowledge_base"
                  ? props?.folder === "resource"
                    ? "Knowledge Base"
                    : "Question Bank"
                  : props.componentName}
              </h1>
              {pathName === "knowledge_base" && (
                <div className="knowledge-base-banner-s-container">
                  <input
                    id="search"
                    className="knowledge-base-banner-search"
                    type="text"
                    placeholder="Please enter at least 3 letters..."
                    autoComplete="off"
                    value={searchQuery}
                    onChange={handleInputChange}
                    onFocus={() => folderSuggestions.length > 0 && setIsDropdownOpen(true)}
                    ref={inputRef}
                  />
                  <i className="bx bx-search search"></i>
                  <i
                    className="bx bx-x close"
                    onClick={() => searchCleanUp()}
                  ></i>
                  {isDropdownOpen && (
                    <ul className="dropdown-kb" ref={dropdownRef}>
                      {isLoading ? (
                        <center>
                          <li className="dropdown-item">
                            <Loading variant="dark" />
                          </li>
                        </center>
                      ) : folderSuggestions.length > 0 ? (
                        folderSuggestions.map((folder) => (
                          <li
                            key={folder.id}
                            onClick={() => handleSelectFolder(folder)}
                            className="dropdown-item"
                          >
                            {folder.name}
                          </li>
                        ))
                      ) : (
                        <li className="dropdown-item">No folders found</li>
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex document-banner-tab-container">
            <div
              className="navigation-list"
              style={{ flex: "0 1 calc(100% - 80px)" }}
            >
              <Tabs
                activeKey={
                  pathName === "knowledge_base"
                    ? props?.folder
                    : props?.tabSelect
                }
                id="uncontrolled-tab-example"
                className=""
                onSelect={(selectedKey) => {
                  props?.handleTabSelect(selectedKey)
                  setCurrentTab(selectedKey)
                  searchCleanUp()
                }}
              >
               {role === 'AGENT' ? ("") : ( <Tab
                  eventKey={
                    pathName === "knowledge_base" ? "resource" : "basic_details"
                  }
                  title={
                    pathName === "knowledge_base" ? "Resource" : "BASIC DETAILS"
                  }
                  key={
                    pathName === "knowledge_base" ? "resource" : "basic_details"
                  }
                />)}
                {role === "AGENT" ? (
                  ""
                ) : (
                  <Tab
                    eventKey={
                      pathName === "knowledge_base" ? "qbank" : "templates"
                    }
                    title={ 
                      pathName === "knowledge_base" ? "Q Bank" : "TEMPLATES"
                    }
                    key={pathName === "knowledge_base" ? "qbank" : "templates"}
                    disabled={
                      pathName === "knowledge_base" ? false : props.isDisabled
                    }
                  />
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KnowledgeBaseBanner;
