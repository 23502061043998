import React, { useState, useRef, useEffect } from "react";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Button } from "react-bootstrap";
import "./CallSemantic.css";
import sessionService from "../../../Services/session.service";
import { USER_DETAIL } from "../../../../Constants/constant";

function CallSemantic({ moduleId, sessionId, questionId }) {
  const isAgent = JSON.parse(localStorage.getItem(USER_DETAIL))?.userRole !== "AGENT"
 const [isRecording, setIsRecording] = useState(false);
 const [isReceivingData, setIsReceivingData] = useState(false);
 const [isLoading, setIsLoading] = useState(false);
 const mediaRecorderRef = useRef(null);
 const audioRef = useRef(null);
 const mediaSourceRef = useRef(null);
 const sourceBufferRef = useRef(null);
 const webSocketRef = useRef(null);
 const pendingBuffers = useRef([]);
 const streamRef = useRef(null);
 let dataTimeoutRef = useRef(null);

 const handleSubmitSocketId = (socketId) => {
  const reqbody = {
   callTrainingId: socketId,
   moduleId: moduleId,
   questionId: questionId,
   sessionId: sessionId,
  };
  sessionService
   .submitCallTrainingId(reqbody)
   .then((response) => {
    if (response?.data?.status === 0) {
     console.log("succesfully submitted socket id: " + response.data);
    }
   })
   .catch((error) => {
    console.log("Api call error of submitting socket id: " + error);
   });
 };

 const initializeMediaSource = async () => {
  const audioElement = audioRef.current;
  const mediaSource = new MediaSource();
  mediaSourceRef.current = mediaSource;
  audioElement.src = URL.createObjectURL(mediaSource);

  sourceBufferRef.current = await new Promise((resolve, reject) => {
   const createSourceBuffer = () => {
    try {
     const sourceBuffer = mediaSource.addSourceBuffer(
      'audio/webm; codecs="opus"'
     );
     resolve(sourceBuffer);
    } catch (e) {
     reject(e);
    }
   };

   if (mediaSource.readyState === "open") {
    createSourceBuffer();
   } else {
    mediaSource.addEventListener("sourceopen", createSourceBuffer);
   }
  });
 };

 const initializeWebSocket = async () => {
  const webSocket = new WebSocket("ws://10.242.3.22:5500/media");

  webSocket.onopen = (event) => {
    console.log("WebSocket connection established.",event);
    // handleSubmitSocketId("abcd1234") // Send the socket id to the server
  };

  webSocket.onmessage = async (event) => {
   // const buffer = await event.data.arrayBuffer();
   setIsReceivingData(true);
   clearTimeout(dataTimeoutRef.current);

   // Stop the animation if no data is received for a while
   dataTimeoutRef.current = setTimeout(() => {
    setIsReceivingData(false);
   }, 3000); // Adjust as needed for your data rate

   try {
    const base64Data = event.data; // Assuming event.data contains the base64 string
    const binaryString = atob(base64Data); // Decode base64 into a binary string
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
     bytes[i] = binaryString.charCodeAt(i); // Convert binary string to Uint8Array
    }

    // Create a Blob from the binary data
    const newBlob = new Blob([bytes], { type: "audio/webm;codecs=opus" });
    console.log(newBlob, "newBlob");
    // Convert Blob to ArrayBuffer for SourceBuffer
    const buffer = await newBlob.arrayBuffer();
    const sourceBuffer = sourceBufferRef.current;

    if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
     if (!sourceBuffer.updating) {
      try {
       sourceBuffer.appendBuffer(buffer);
       audioRef.current.play().catch((error) => {
        console.log("error playing media");
       });
      } catch (error) {
       console.error("Error appending buffer directly:", error);
       // Only queue the buffer if the error isn't about removed SourceBuffer
       if (!error.message.includes("removed from the parent media source")) {
        setTimeout(() => {
         pendingBuffers.current.push(buffer);
        }, 100); // Simulate a 500ms gap
       }
      }
     } else {
      pendingBuffers.current.push(buffer);
     }
    }
   } catch (error) {
    console.error("Error processing WebSocket message:", error);
   }
  };

  webSocket.onclose = () => {
   console.log("WebSocket connection closed.");
   setIsLoading(false);
   setIsReceivingData(false);
  };
  webSocket.onerror = (error) => {
   console.error("WebSocket error:", error);
   setIsLoading(false);
   setIsReceivingData(false);
  };
  webSocketRef.current = webSocket;
 };

 const cleanup = () => {
  // Stop and clean up MediaRecorder
  if (mediaRecorderRef.current) {
   mediaRecorderRef.current.stop();
   mediaRecorderRef.current = null;
  }

  // Stop all audio tracks
  if (streamRef.current) {
   streamRef.current.getTracks().forEach((track) => track.stop());
   streamRef.current = null;
  }

  // Close WebSocket
  if (webSocketRef.current) {
   webSocketRef.current.close();
   webSocketRef.current = null;
  }

  // Clean up MediaSource
  if (mediaSourceRef.current && mediaSourceRef.current.readyState === "open") {
   try {
    if (sourceBufferRef.current) {
     mediaSourceRef.current.removeSourceBuffer(sourceBufferRef.current);
    }
    mediaSourceRef.current.endOfStream();
   } catch (error) {
    console.error("Error cleaning up MediaSource:", error);
   }
  }

  // Reset audio element
  if (audioRef.current) {
   URL.revokeObjectURL(audioRef.current.src);
   audioRef.current.src = "";
  }

  // Clear refs
  mediaSourceRef.current = null;
  sourceBufferRef.current = null;
  pendingBuffers.current = [];
 };

 useEffect(() => {
  return cleanup;
 }, []);

 const handleStartRecording = async () => {
  if (isRecording) return;

  // Clean up previous session
  cleanup();

  // Reinitialize with proper async handling
  try {
   await initializeMediaSource();
   initializeWebSocket();

   const stream = await navigator.mediaDevices.getUserMedia({
    audio: {
     echoCancellation: true,
     noiseSuppression: true,
     autoGainControl: true,
    },
   });
   streamRef.current = stream;
   const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
   mediaRecorderRef.current = mediaRecorder;
   setIsRecording(true);

   mediaRecorder.ondataavailable = (event) => {
    if (
     event.data.size > 0 &&
     webSocketRef.current?.readyState === WebSocket.OPEN
    ) {
     const reader = new FileReader();

     reader.onload = () => {
      const base64Data = reader.result.split(",")[1]; // Extract Base64 string without the metadata
      // const reqBody = {
      //   event: "audio_data",
      //   data: base64Data,
      // };

      const reqBody = {
       event: "audio_data",
       data:
        "Q7CBInSA+4P+D/8PQ5t2JpD3PjJVmajRXS5X7fHISZatcYC+zeWxk/i1COtJ29Xo0WW+DIiPXi/1jvNwwBj6U2MxjeFU2pFq7fcZLOgLNqZFuHkDAq2drF196nJu+tyWJnA4x9vcUA14essAQZ8WsLDDGLH6UZkupHKRsbYdtOU+j4zAUD9hp+RpvNoKQ/jzIqwS0grXIBp6eUAZui2PAdQQ/hhwm/wimj5T4U6Q/nPlh7my1tAWrobN9t3JNkxNLV9j9ikpzHTfNVUAMezCsAUefWgsrvxKMSJVsot9OwcB/QXczPeh1a20FoCMuwFXgELnDsawgMNX+8JndHUTKikgRlQk8kWVKGyBd1fOXDwtwmUenS9npYpn32LgK+sYgsOYcLokObbz4aQ39mUp+7WsdUHINjW3j9ZErJLtgq5CMYV/HmYG8+TomU6NTUKtzj+nvk8X/5hb+IZAvtGfGixeJYn+uYRAhgpxAySO05b8vGJIW+K2QjXaUqCMvAN8IQOMpx89CB+x2B7CClz+vJmzvmXmDF/a2LGIBo79x/ImmCvjNOriQNhgXlkphIFoDSbt2QgTNmQNiLZvTKB/qEwaX+wJOuFLNE61ST6EeNrIiBGTz4ZZynjEK0NIZWO4dM7T/Y9vla9FIekcvAL74JDzBr8RTkCJiVvgxlDCR2qMlt9H63/4vcGE0Tcb8TWvPkK/XQMecVSU/gBz6Kqopj4bgOGMUc7Dr9ymt4v0pLYLVt2NBtFmbynFpVO8xatPQSyi1IFc0LwxsQBm173C7nHwDV6czLPALHmzXIKT6wMBvG2/0Tx7rKEgYf1+ceQKZHZgNIH7DTNo1u7So8kHoFQckqrd50r15rGNlMob6EI2FvxqwSTQtrRbT49ZC5LEzroD0T9Z1jTiopCcSFM2y2tWMXwfxK1xpMAPQ09BBOYQVG9506qbKFJZAY5PEl7cmCOU4RW7gbrRGe91X/15Iv6BeDtnRRIaA5cjExslX+9Lrm45Tp6uB0fkm9bHwpAUfLsn1nt3oSKbyOs7pPS0QD0hWQuhLAFJ4KqiIDVEu2nIl5xG8lF9U6kmeJ1cfnuh5GnsI/wezVgihAx4yXirZeV5sMMx/OEBvG4+ceip8wwDmiubcJJuRr2EQ0jG23YpSc1N/a5jC9DFhRsRAAKf4oex/VXdy0Fznkg3sjh9/+Cg9Wfqr5kWl2+87ZoDG9Df6RPEMDUR3jm9iGmDyB1oWmVV82dO20fp5q6rYz0QYGb2b6M=",
      };

      webSocketRef.current.send(JSON.stringify(reqBody));
      mediaRecorderRef.current.stop();      
      return;
     };

     reader.readAsDataURL(event.data);
    }
   };

   mediaRecorder.start(50); // Record in chunks of 50ms
  } catch (error) {
   console.error("Error starting recording:", error);
   setIsRecording(false);
   cleanup();
  }
 };

 const handleStopRecording = async () => {
  setIsLoading(true);
  cleanup();
  setIsRecording(false);
 };

  return (
    <div>
      <div className="semantic_box">
        <div>
          <Button
            disabled={isLoading || isAgent}
            style={{ backgroundColor: isRecording ? "red" : "#1976d2" }}
            onClick={isRecording ? handleStopRecording : handleStartRecording}
          >
            {isRecording ? "End Call" : "Start Call"}
            {isRecording ? <CallEndIcon /> : <CallIcon />}
          </Button>
          <audio ref={audioRef} />
        </div>
        <div className="container_semantic">
          <div className="parent">
            <div className='phone'>
              <span className="material-icons">
                <MicIcon />
              </span>
            </div>
            <div className={`para circle1 ${isRecording ? 'para_animation' : ''}`}></div>
            <div className={`para circle2 ${isReceivingData ? 'para_animation' : ''}`}></div>
          </div>
          <div className="parent1">
            <div className='phone'>
              <span className="material-icons">
                <VolumeUpIcon />
              </span>
            </div>
            <div className="para circle1"></div>
            <div className="para circle2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallSemantic;
