import React from 'react'
import Loading from '../../../Commons/Loading'
import { Card } from 'react-bootstrap'

function MeetingTrack({ speakerTracks, participants, talkDuration, audioTime, loader }) {

  const playAudio = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    let formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ].join(':')

    audioTime(formattedTime)
  }


  let activeMoment = null;
  const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF"];
  const totalDuration = talkDuration;

  let speakerDurations = {};
  let lastTime = 0;

  speakerTracks?.sequence?.forEach((item) => {
    if (!speakerDurations[item.speakerName]) {
      speakerDurations[item.speakerName] = [];
    }
  });

  speakerTracks?.sequence?.forEach((item) => {

    if (item.start_time > lastTime) {
      Object.keys(speakerDurations).forEach((speakerName) => {
        speakerDurations[speakerName].push({
          isSpeaking: false,
          value: ((item.start_time - lastTime) / totalDuration) * 100
        });
      });
    }

    speakerDurations[item.speakerName].push({
      isSpeaking: true,
      value: ((item.till_time - item.start_time) / totalDuration) * 100,
      startTime: item.start_time,
      momentTime: Object.keys(item.moments).length > 0
        ? Object.entries(item.moments).map(([name, values]) => ({
          name,
          value: values
        }))
        : [],
      statTime: Object.keys(item.stats).length > 0
        ? Object.entries(item.stats).map(([name, values]) => ({
          name,
          value: values
        }))
        : []
    });

    lastTime = item.till_time;
  });

  if (lastTime < totalDuration) {
    Object.keys(speakerDurations).forEach((speakerName) => {
      speakerDurations[speakerName].push({
        isSpeaking: false,
        value: ((totalDuration - lastTime) / totalDuration) * 100
      });
    });
  }

  const onMouseEnter = (val) => {
    const nodes = document.getElementsByClassName("moments");
    let momentToMove = null;

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id !== val) {
        nodes[i].parentElement.style.display = "none";
        nodes[i].style.display = "none";
      } else {
        momentToMove = nodes[i];
      }
    }

    if (momentToMove) {
      momentToMove.parentElement.style.display = "block";
      momentToMove.style.display = "block";

      const cardRect = momentToMove.closest(".card").getBoundingClientRect();
      const momentRect = momentToMove.getBoundingClientRect();

      if (momentRect.right >= cardRect.right) {
        const xOffset = momentRect.right - cardRect.right;
        momentToMove.style.transform = `translate(-${xOffset}px, -50%)`;
      }
    }

    activeMoment = val;
  };

  const onMouseLeave = (val) => {
    const nodes = document.getElementsByClassName("moments");

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id !== val) {
        nodes[i].parentElement.style.display = "block";
        nodes[i].style.display = "block";
      } else {
        nodes[i].style.transform = "none";
      }
    }
  };

  const renderSpeakerTrack = (speakerName, durations) => (
    <div key={speakerName} className="mt-4">
      <div className="d-flex w-100 justify-content-between" style={{ marginTop: "15px" }}>
        <h5 className="mb-1 font-16">
          <span className="badge rounded-pill bg-light-blue text-dark f-normal">{speakerName}</span>
        </h5>
        <small className="text-muted"></small>
      </div>
      <div className="details-audio w-100 my-1 details-audio-graph">
        <div className="progress" style={{ marginTop: "23px" }}>
          {durations.map((item, id) => (
            item.isSpeaking ? (
              <>
                {item.momentTime && item.momentTime.length > 0 && (
                  <div className="t-tip">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item.momentTime.map((mom, i) => (
                        <div
                          key={i + mom.name}
                          className="moments"
                          id={i + mom.name}
                          style={{
                            marginTop: `${20 - i * 20}px`,
                            background: colors[Math.floor(Math.random() * colors.length)]
                          }}
                          onMouseOver={() => onMouseEnter(i + mom.name)}
                          onMouseOut={() => onMouseLeave(i + mom.name)}
                        >
                          {mom.name}
                        </div>
                      ))}
                    </div>
                    <div className="drop-tooltip">
                      <span className="drop-text">{item.momentTime.length}</span>
                    </div>
                  </div>
                )}
                <div
                  role="progressbar"
                  className="progress-bar"
                  key={id}
                  onClick={() => playAudio(item.startTime)}
                  aria-valuenow={item.value}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ background: "#0dcaf0", width: `${item.value}%` }}
                ></div>
              </>
            ) : (
              <div
                role="progressbar"
                className="progress-bar"
                key={id}
                aria-valuenow={item.value}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ background: "#e9ecef", width: `${item.value}%` }}
              ></div>
            )
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : <>
        <div className="tab-pane" role="tabpanel">
          <div className="d-flex align-items-center feedback-headder my-2">
            <div className="feedback-heading">
              <h5>Speaker Tracks</h5>
            </div>
          </div>
        </div>
        <Card style={{ width: '100%' }}>
          <Card.Body className="filter_scroll" style={{ margin: '15px 20px', height: 'auto', padding: '0.5rem 1rem' }}>
            {Object.entries(speakerDurations).map(([speakerName, durations]) =>
              renderSpeakerTrack(speakerName, durations)
            )}
          </Card.Body>
        </Card>
      </>
      }</>)
}

export default MeetingTrack