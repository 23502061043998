import React, { useEffect, useRef, useState } from "react";
import {
  MenuItem,
  Select,
  CircularProgress,
  Box,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { Row, Col, Card, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import "../Insight.css";
import dashboardService from "../../Services/dashboard.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { apiCall } from "../../Services/Interceptor";
import Autocomplete from "@mui/material/Autocomplete";
import ActiveLogGraph from "./ActivityLogGraph";
import { de } from "date-fns/locale";
import { dataMoment, dataMomentGroup, onResetFilter, setDateList, setResetState, setUserOptions } from "../../../redux-container/insights/action";
import { useDispatch, useSelector } from "react-redux";
import FilterDrawer from "../../Commons/FilterDrawer";
import { positionFormat } from "../../Commons/DateFormatter";

const cardStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  borderRadius: '8px',
  transition: 'box-shadow 0.3s ease, transform 0.3s ease',
  marginBottom: '16px',
};

export default function ActiveLog() {
  const localData = JSON.parse(localStorage.getItem("AGENT"));
  let defaultAnalysis = JSON.parse(localStorage.getItem("USER_DETAIL"))
    ?.clientConfig?.data?.comparativeAnalysis;
  const [tabIndex, setTabIndex] = useState(0);
  const [defaultFilteredData, setDefaultFilteredData] = useState({
    fromDate:'',
    toDate:'',
    id:[]
  });
  const [loader, setLoader] = useState(false);
  const [totalCallsLoading, setTotalCallLoading] = useState(true)
  const [refreshKey, setRefreshKey] = useState(0);
  const [apply, setApply] = useState(false)
  const [reset, setReset] = useState(false)
  const dispatch = useDispatch()
  const filteredData = useSelector(state=>state?.insights?.filterData)
  let options = useSelector(state=>state.insights.userOptions)
  let momentGroupData = useSelector(state=>state?.insights?.momentGroupList)
  let momentData = useSelector(state=>state?.insights?.momentList)
  let chipsData = useSelector(state=>state?.insights?.chipsData)
  const [activitiesStatsList, setActivitiesStatsList] = useState([]);
  const [momentsGroupList, setMomentsGroupList] = useState([]);
  const [momentsList, setMomentsList] = useState([]);
  const [wordList, setWordList] = useState([]);
  const [viewItems, setViewItems] = useState({});
  const [totalClientCallsSum, setTotalClientCallsSum] = useState(null);

  const getTabContent = () => {
    switch (tabIndex) {
      case 0:
        return activitiesStatsList;
      case 1:
        return momentsGroupList;
      case 2:
        return momentsList;
      case 3:
        return wordList;
      default:
        return [];
    }
  };

  const tabContent = getTabContent();
  const isOdd = tabContent.length % 2 !== 0;

  useEffect(() => {
    document.title = "Activity Log  - Odio";
    getUserList();
    getDateAcronym();
    getActivityStats();
  }, []);

  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function (...args) {
      if (!lastRan) {
        func.apply(this, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(this, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  const handleScroll = throttle(() => {
    let ids = (tabContent || []).map((_, i) => `GRAPH-${i}`).filter(Boolean);
    ids.forEach((id, index) => {
      let element = document.getElementById(id);
      if (element) {
        let elementPosition = element.getBoundingClientRect().bottom;
        if (elementPosition < window.innerHeight) {
          setViewItems((state) => {
            if (!state[id]) {
              const nextId = ids[index + 1];
              let newState = { ...state, [id]: true };
              if (nextId) {
                newState[nextId] = true;
              }
              return newState;
            }
            return state;
          });
        }
      }
    });
  }, 200);

  useEffect(() => {
    let initialViewItems = {};
    (getTabContent(tabIndex) || []).slice(0, 4).forEach((_, i) => {
      initialViewItems[`GRAPH-${i}`] = true;
    });
    setViewItems(initialViewItems);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tabIndex, tabContent]);

  const getDateAcronym = () => {
    dashboardService.getDateAcronym().then((res) => {
      let dateList = res
        ? res.data
          ? res.data.data.filter((e) => {
            return e.type == "CURRENT" || e.type == "GLOBAL";
          })
          : ""
        : "";
      dispatch(setDateList(dateList))
      let dd = dateList[4]?.fromDate?.split("-")[2];
      let mm = dateList[4]?.fromDate?.split("-")[1];
      let yyyy = dateList[4]?.fromDate?.split("-")[0];
      let dateF = `${dd}-${mm}-${yyyy}`;
      let dd_1 = dateList[4]?.toDate?.split("-")[2];
      let mm_1 = dateList[4]?.toDate?.split("-")[1];
      let yyyy_1 = dateList[4]?.toDate?.split("-")[0];
      let dateT = `${dd_1}-${mm_1}-${yyyy_1}`;
      setDefaultFilteredData((defaultFilteredData) => ({
        ...defaultFilteredData,
        fromDate: dateT,
        toDate: dateF,
      }));
    });
  };

  const getActivityStats = async () => {
    const res = await apiCall.get("/odio/api/call/stats")
    if (res?.data?.status === 0) {
      setActivitiesStatsList(res?.data?.data?.activitiesStatsList);
      setMomentsGroupList(res?.data?.data?.momentsGroupList);
      setMomentsList(res?.data?.data?.momentsList);
      setWordList(res?.data?.data?.wordList);
    } else if (res?.data?.status === 1) {
      setLoader(false);
    }
  };

  const getUserList = async () => {
    const res = await apiCall.get(
      `${"/odio/api/user/get-user-team-list"}?fromD=${localData?.fromD ? localData.fromD : null
      }&toD=${localData?.toD ? localData.toD : null}&coeIds=${localData?.selectedCoeRange ? localData.selectedCoeRange : ""
      }`
    );
    if (res?.data?.status === 0) {
      const teamOptions = res?.data?.data?.team.map((e,i)=>
        (
          {"label":`${e.firstName} ${e.lastName}`,"value":`${e.id}`,"id":e.id}
        ))
      const optionsWithSelectAll = [...teamOptions];
      dispatch(setUserOptions(optionsWithSelectAll))
      let userIds = res?.data?.data?.team.map((item) => {
        return item.id;
      });
      setDefaultFilteredData((defaultFilteredData) => ({
        ...defaultFilteredData,
        id: userIds,
      }));
    } else if (res?.data?.status === 1) {
      setLoader(false);
    }
  };

  useEffect(()=>{
    if(apply){
      handleSubmit()
    }
    if(reset){
      handleReset()
      const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      dispatch(onResetFilter({"productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"selectedRange":5,"selectedDate":"This Month"}))
      dispatch(setResetState(true))
    }
  },[apply, reset])

  const handleSubmit = () => {
    localStorage.setItem(
      "AGENT",
      JSON.stringify({
        ...localData,
        pageSelected: localData?.pageSelected,
        analysis: localData?.analysis,
        productivity:filteredData?.productivity?filteredData?.productivity:localData?.productivity,
        fromD: filteredData?.fromD ? filteredData.fromD : localData?.fromD,
        toD: filteredData?.toD ? filteredData.toD : localData?.toD,
        agentDetailCOE: localData?.agentDetailCOE,
        selectedRange: filteredData?.selectedRange ? filteredData?.selectedRange : localData?.selectedRange,
        selectedDate: filteredData?.dateName
          ? filteredData?.dateName
          : localData?.selectedDate,
        userIds: filteredData?.id ? filteredData?.id : localData?.userIds,
        userNames: filteredData?.names
          ? filteredData?.names
          : localData?.userNames,
        momentIds: filteredData?.momentIds ? filteredData?.momentIds : localData?.momentIds,
        momentGroupIds: filteredData?.momentGroupIds ? filteredData?.momentGroupIds : localData?.momentGroupIds,
        empId:localData?.employeeId,
        empName:localData?.user,
        role:localData?.role,
        designation:localData?.designation,
        type:localData?.type,
        teamCOE:localData?.teamCOE,
        callsFrom:localData?.callsFrom,
        callsTo:localData?.callsTo,
        aht:localData?.aht,
        callScore:localData?.callScore,
        pitchScore:localData?.pitchScore,
        customerName:localData?.customerName,
        customerMobile:localData?.customerMobile,
        fileName:localData?.fileName,
        sellerROS:localData?.sellerROS,
        sellerName:localData?.sellerName,
        selectedSeller:localData?.selectedSeller,
        cScore:localData?.cScore,
        callHealth:localData?.callHealth,
        callDuration:localData?.callDuration,
        words:localData?.words,
        moment:localData?.moment,
        momentId:localData?.momentId,
        pitch:localData?.pitch,
        prob:localData?.prob,
        opportunity:localData?.opp,
        urgency:localData?.urgency,
        coe:localData?.coe,
        selectedCoeRange:localData?.selectedCoeRange,
        selectedCOE:localData?.selectedCOE,
        momentBucket:localData?.momentBucket,
        momentBucketId:localData?.momentBucketId,
        frequency:localData?.frequency,
        reportType:localData?.reportType
      })
    );
    // fetchData();
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const handleReset = () => {
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    localStorage.setItem(
      "AGENT",
      JSON.stringify({
        ...localData,
        fromD: `${firstDay.getDate()}-${firstDay.getMonth() + 1}-${firstDay.getFullYear()}`,
        toD: `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
        selectedRange: 5,
        selectedDate: "This Month",
        userIds: defaultFilteredData.id,
        userNames: options.map(item=>item.value)
      })
    );
    // fetchData();
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const applyFilter = ()=>{
    setApply(true)
  }

  const setApplyFalse = ()=>{
    setApply(false)
  }

  const resetFilter = ()=>{
    setReset(true)
  }

  const setResetFalse = ()=>{
    setReset(false)
  }

  const setLoaderTrue = ()=>{
    setLoader(true)
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    let initialViewItems = {};
    (getTabContent(newValue) || []).slice(0, 4).forEach((_, i) => {
      initialViewItems[`GRAPH-${i}`] = true;
    });
    setViewItems(initialViewItems);
  };

  const popoverr = (des) => (
    <Popover id="popover-trigger-click-root-close" title="right" style={{ "marginLeft": "13px" }}>
        <h3 className="popover-header" style={{ background: 'white', color: 'black' }}>
            {des}
        </h3>
    </Popover>
  )

  const handleCallSumUpdate = (value) => {
    setTotalClientCallsSum(value); 
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Typography variant="h5" gutterBottom>
              Activity Log
            </Typography>
            {Array.isArray(chipsData) && chipsData.length>0 ? chipsData.map(item=>(
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                {`${item?.name}, ${item?.id}`}
                <a href="javascript:;"></a>
              </span>))
            :
            <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
              All employees are selected
              <a href="javascript:;"></a>
            </span>
            }
            {filteredData && filteredData?.dateName ? 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                {`DATE: ${filteredData?.dateName === "Custom Date" ? `${positionFormat(filteredData?.fromD)} to ${positionFormat(filteredData?.toD) }`: filteredData?.dateName }`}
                <a href="javascript:;"></a>
              </span>
            : 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>DATE: </span>
                {`${localData?.selectedDate}`} 
                <a href="javascript:;"></a>
              </span>
            }
            {totalCallsLoading ? <Spinner animation="border" variant="secondary"  size="sm" className="me-2"/> :
                <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>Total Calls: </span>
                 {totalClientCallsSum ? totalClientCallsSum : 0 }
                <a href="javascript:;"></a>
              </span>
            }
            {tabIndex===1 ? filteredData && filteredData?.momentGroupIds && filteredData?.momentGroupIds.length>0 ? 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>MOMENT GROUP: </span>
                {`${momentGroupData.find(item=>(item.value==filteredData?.momentGroupIds[0]))?.label}`}
                <a href="javascript:;"></a>
              </span>
            :
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>MOMENT GROUP: </span>
                {`${momentGroupData[0]?.label}`}
                <a href="javascript:;"></a>
              </span>
            :''}
            {tabIndex===2 ? filteredData && filteredData?.momentIds && filteredData?.momentIds.length>0 ? 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>MOMENT: </span>
                {`${momentData.find(item=>(item.value==filteredData?.momentIds[0]))?.label}`}
                <a href="javascript:;"></a>
              </span>
            :
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                 <span>MOMENT: </span>
                {`${momentData[0]?.label}`}
                <a href="javascript:;"></a>
              </span>
            :''}
            {tabIndex===3 ? filteredData && filteredData?.word ? 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>WORD: </span>
                {filteredData?.word}
                <a href="javascript:;"></a>
              </span>
            :'' : ''}
          </Col>
          <Col lg={12} className="mt-4">
            <Tabs
              value={tabIndex}
              onChange={(event, newValue) => handleTabChange(event,newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Conversational Intelligence" />
              <Tab label="Discussed Moment Group" />
              <Tab label="Discussed Moment" />
              <Tab label="Word" />
            </Tabs>
            <Card>
              <Card.Body>
                <Row style={{ minHeight: '350px' }}>
                  {(getTabContent(tabIndex) || []).map((stat, index) => {
                    const id = `GRAPH-${index}`;
                    return (
                      <Col
                        md={isOdd && index === tabContent.length - 1 ? 12 : 6}
                        key={stat}
                        id={id} 
                      >
                        {viewItems[id] && (
                          <div style={cardStyle}>
                            <Typography variant="h6" gutterBottom sx={{fontSize: '1rem'}}>
                              {stat?.handling}                      
                              <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverr(stat?.info)} rootClose>
                                <i className="fadeIn animated bx bx-info-circle text-primary-blue" style={{ fontSize: "1rem", marginLeft: '10px' }}></i>
                              </OverlayTrigger>
                            </Typography>

                            <ActiveLogGraph
                              key={`${stat}-${index}-${refreshKey}`}
                              statName={stat}
                              handling={stat?.handling}
                              measurement={stat?.measurement}
                              recommended={stat?.recommended_value}
                              xFieldLabel={stat?.x_label}
                              yFieldLabel={stat?.y_label}
                              tabIndex={tabIndex}
                              defaultFilteredData={defaultFilteredData}
                              apply={apply}
                              setResetFalse={setResetFalse}
                              setApplyFalse={setApplyFalse}
                              isVisible={viewItems[id]}
                              onCallSumUpdate={handleCallSumUpdate} 
                              setTotalCallLoading={setTotalCallLoading}
                            />
                          </div>
                        )}
                      </Col>
                    );
                  })}
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <FilterDrawer filterType='INSIGHTS' componentType='ACTIVITY LOG' tabIndex={tabIndex} applyFilter={applyFilter} setLoaderTrue={setLoaderTrue} resetFilter={resetFilter}/>
    </div>
  );
}
