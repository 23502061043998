import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// import LiveAssistSidebar from './LiveAssistSidebar';
import { apiCall } from '../../Services/Interceptor';
import Loading from '../../Commons/Loading';
import './liveAssistMobileView.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Card } from 'react-bootstrap';
import seller from '../../../assets/images/callList-images/seller.svg';
import phone from '../../../assets/images/callList-images/phone.svg';
import calender from '../../../assets/images/callList-images/calendar.svg';
import notes from '../../../assets/images/callList-images/note.png'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Badge,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import time from '../../../assets/images/callList-images/timer.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredPrompts, setInitialState, setLiveData } from '../../../redux-container/liveAssist/action';
// import conversationService from '../Services/conversation.service';
import exclamation from '../../../assets/images/exclamation.svg'
import Nudges from './NudgesMobileView';
import { dummyLiveData } from './dummydata';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px #ecf0f3`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));


const LiveAssistCallDetailMobileView = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const socketRef = useRef(null)
    const divRef = useRef(null);
    let storedLiveData = useSelector(state => state?.live_assist?.liveCallData)
    let storedCallData = useSelector(state => state?.live_assist?.callData)
    let prompts = useSelector(state => state?.live_assist?.prompts)
    let callData = useSelector(state => state?.live_assist?.liveCallDetail)
    const queryParams = new URLSearchParams(window.location.search)
    //   let typeOfCall = queryParams.get('callType')
    let liveData = queryParams.get('livedata')
    const [callAssitData, setCallAssitData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [height, setHeight] = useState(300);
    const [div2Height, setDiv2Height] = useState(0);
    const [shouldScroll, setShouldScroll] = useState(false);

    const liveAssistSidebarRef = useRef(null);
    const storedData = JSON.parse(localStorage.getItem('AGENT'));
    const containerRef = useRef(null);
    const [div1Height, setDiv1Height] = useState(317);
    const [isDragging, setIsDragging] = useState(false);
    const [socket, setSocket] = useState(null);
    const [momentList, setMomentList] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentId, setCurrentId] = useState(0)
    const [progress, setProgress] = useState(0);
    const [traversedPromptIds, setTraversedPromptIds] = useState([]);
    const [hiddenGroups, setHiddenGroups] = useState([]);
    const [startConnection, setStartConnection] = useState(false)
    const [currentTime, setCurrentTime] = useState('')
    const element = document.getElementById("audio-element-live");
    const audioRef = useRef(null);

    const decodedString = atob(liveData);
    const jsonObject = JSON.parse(decodedString);


    const getMoments = () => {
        setMomentList(dummyLiveData.momentGroups)
        setLoader(false)
    }


    useEffect(() => {
        if (startConnection && !socket) {
            // Create a new WebSocket instance
            socketRef.current = new WebSocket('wss://livesttsocket.odioiq.com/mobileserve');
            let mediaInterval
            if (window.location.pathname === '/live_call') {
                if (!socket) {
                    socketRef.current.onopen = () => {

                        // Send "connect" event
                        socketRef.current.send(JSON.stringify("eyJldmVudCI6ICJjb25uZWN0In0="))

                        // Send "start" event
                        socketRef?.current.send(JSON.stringify(`${liveData}`));
                    };


                    socketRef.current.onmessage = (s) => {
                        const data = JSON.parse(s.data);
                        if (data) {
                            dispatch(setLiveData(data))
                            if (data?.alert?.length > 0) {
                                let newData = data?.alert.find(item => (
                                    momentList.map(moment => (moment?.moments?.find(dat => dat?.momentId == item?.moment && item?.exists == true)))
                                ))
                                newData && dispatch(setFilteredPrompts(newData ? { id: newData?.moment, prompt: newData?.prompt }  : ''))

                            }
                        }
                    };

                    socketRef.current.onerror = (error) => {
                        console.error('WebSocket error:', error);
                    };

                    setSocket(socketRef?.current);
                }
            }
            else {
                if (socketRef?.current) {
                    // newSocket.close();
                    setSocket(null);
                }
            }

            // Cleanup function to run on component unmount
            return () => {
                if (socketRef?.current) {
                    socketRef?.current.close();
                    clearInterval(mediaInterval); // Clear interval on unmount
                    setSocket(null);
                }
            };
        }
    }, [startConnection == true]);

    useEffect(() => {
        let intervalId;
        let timeoutId;

        const PROGRESS_DURATION = 2000; // Total duration for the progress bar to reach 100% (2 seconds)
        const PROGRESS_INCREMENT_INTERVAL = 100; // Interval for progress increment (100ms)
        const PROGRESS_INCREMENT_STEP = 100 / (PROGRESS_DURATION / PROGRESS_INCREMENT_INTERVAL); // Progress increment step to complete in 3 seconds

        const showNextPrompt = () => {
            setCurrentIndex(prevIndex => {
                const nextIndex = prevIndex + 1;
                if (nextIndex < prompts.length) {
                    setProgress(0);
                    setCurrentId(prompts[nextIndex]?.id);
                    return nextIndex;
                } else {
                    clearInterval(intervalId); // Stop the interval on the last prompt
                    return prevIndex; // Stay on the last prompt
                }
            });
        };

        const startInterval = () => {
            if (intervalId) clearInterval(intervalId);
            if (timeoutId) clearTimeout(timeoutId);

            intervalId = setInterval(() => {
                setProgress(prev => {
                    if (prev >= 100) {
                        clearInterval(intervalId); // Stop the interval when progress reaches 100%
                        return 100;
                    }
                    return prev + PROGRESS_INCREMENT_STEP; // Increment progress
                });
            }, PROGRESS_INCREMENT_INTERVAL);
        };

        const handlePromptChange = () => {
            showNextPrompt();
            setTimeout(() => {
                startInterval();
            }, 2000);
        };

        if (prompts.length > 0) {
            if (currentIndex === 0) {
                handlePromptTraversal(0);
                setCurrentId(prompts[0]?.id);
            }

            startInterval();

            const checkProgressCompletion = () => {
                if (progress >= 100) {
                    handlePromptChange();
                }
            };

            const progressMonitorInterval = setInterval(() => {
                checkProgressCompletion();
            }, PROGRESS_INCREMENT_INTERVAL);

            return () => {
                if (intervalId) clearInterval(intervalId);
                if (progressMonitorInterval) clearInterval(progressMonitorInterval);
                if (timeoutId) clearTimeout(timeoutId);
            };
        }
    }, [prompts, currentIndex, progress]);


    const handlePromptTraversal = (index) => {

        const currentPromptId = prompts[index]?.id;
        if (currentPromptId && !traversedPromptIds.includes(currentPromptId)) {
            setTraversedPromptIds((prev) => [...prev, currentPromptId]);
        }
    };

    // Call handlePromptTraversal when the current prompt changes
    useEffect(() => {
        handlePromptTraversal(currentIndex);
    }, [currentIndex]);

    useEffect(() => {
        setLoader(true)
        getMoments()

        const now = new Date();
        const options = {
            timeZone: 'Asia/Kolkata', // Specify IST timezone
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const formattedDateTime = now.toLocaleString(options);
        setCurrentTime(formattedDateTime)
    }, [])

    useEffect(() => {
        const newlyHiddenGroups = momentList.reduce((acc, group) => {
            const allMomentsTraversed = group.moments.every((moment) =>
                traversedPromptIds.includes(moment.momentId)
            );

            if (allMomentsTraversed && !hiddenGroups.includes(group.momentGroupId)) {
                acc.push(group.momentGroupId);
            }

            return acc;
        }, []);

        if (newlyHiddenGroups.length > 0) {
            setTimeout(() => {
                setHiddenGroups((prev) => [...prev, ...newlyHiddenGroups]);
            }, 2000); // Adjust the delay as needed (200ms is a starting point)
        }
    }, [traversedPromptIds, momentList]);

    const [isPlaying, setIsPlaying] = useState(false);
    const [preventSeek, setPreventSeek] = useState(false);
    const [lastTime, setLastTime] = useState(0);
    const [key, setKey] = useState(0);
    const handleAudioPlay = () => {
        if (audioRef.current) {
            audioRef.current.play().then(() => {
                setStartConnection(true)
                setIsPlaying(true);
            }).catch((error) => {
                console.error('Failed to play audio:', error);
            });
        }
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        const handlePause = () => {
            if (!audioElement.ended) {
                audioElement.play().catch((error) => {
                    console.error('Failed to resume audio:', error);
                });
            }
        };

        const handleSeeking = () => {
            audioElement.currentTime = lastTime; // Revert to the last known time
        };

        const handleTimeUpdate = () => {
            setLastTime(audioElement.currentTime);
        };

        const handleEnded = () => {
            // setInitialState()
            setTimeout(() => {
                setKey(prevKey => prevKey + 1);
                setStartConnection(false);
                dispatch(setInitialState())
                setMomentList(dummyLiveData.momentGroups)
                setHiddenGroups([]);
                setCurrentIndex(0);
                setCurrentId(0);
                setTraversedPromptIds([]);
                setProgress(0);
                setIsPlaying(false);
                setLastTime(0); // Reset time for replay
                audioElement.currentTime = 0; // Reset the audio to the start
            }, 30000)
        };

        audioElement.addEventListener('pause', handlePause);
        audioElement.addEventListener('seeking', handleSeeking);
        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('ended', handleEnded);

        // Cleanup event listeners when the component is unmounted
        return () => {
            audioElement.removeEventListener('pause', handlePause);
            audioElement.removeEventListener('seeking', handleSeeking);
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [lastTime]);

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const containerRect = containerRef.current.getBoundingClientRect();
        const newDiv1Height = e.clientY - containerRect.top;

        if (newDiv1Height > 0 && newDiv1Height < containerRect.height - 10) {
            setDiv1Height(newDiv1Height);
        }
    };

    return (
        <>
            <div className="live-wrapper">
                <div className="page-content">
                    <div className="call-details-live">
                        <div className="call-details-live-main-content">
                            <div className="">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="">
                                        Live Assist
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Live Assist Call Details
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <h2 className="dashboard-headings d-inline">
                                    Live Assist Call Details
                                </h2>
                                <div style={{ marginBottom: '1rem' }}>
                                    <div className="salesCallCard">
                                        <div className="user">
                                            <div className="caller">
                                                <div className="name" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                    <StyledBadge
                                                        overlap="circular"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        variant="dot"
                                                    >
                                                        <Avatar sx={{ height: '28px', width: '28px' }} />
                                                    </StyledBadge>
                                                    <span>Avinash Sharma</span>
                                                </div>
                                            </div>
                                            <div className="callCount"></div>
                                        </div>
                                        <div className="seller">
                                            <div className="name">
                                                <span className="icon">
                                                    <img src={seller} alt="" />
                                                </span>
                                                Saurabh Pathak
                                            </div>
                                            <div className="number">
                                                <span className="icon">
                                                    <img src={phone} alt="" />
                                                </span>
                                                {jsonObject.phoneNumber}
                                            </div>
                                            <div className="date">
                                                <span className="icon">
                                                    <img src={calender} alt="" />
                                                </span>
                                                {currentTime}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div>
                                <audio
                                    key={key}
                                    id="audio-element-live"
                                    controls
                                    className="w-100 call-detail-audio-panal mt-3"
                                    controlsList="nodownload"
                                    ref={audioRef}
                                    onPlay={() => handleAudioPlay()}
                                >
                                    <source src="https://demo-odio-test.s3.ap-south-1.amazonaws.com/9816762_2_test.wav" type="audio/wav"></source>
                                </audio>
                            </div>
                        </div>
                        <div className="call-details-live-secondary-content" ref={containerRef} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                            <div className='detail-heading'>
                                <h5>{'Call Details'}</h5>
                            </div>
                            <hr className="my-2" style={{ marginTop: '15px' }} />
                            {loader ? (
                                <div className="loader-container">
                                    <Loading variant="dark" />
                                </div>) : (
                                <>
                                    <div className="div1 live-assist-scroll" style={{ height: `${div1Height}px`, overflowY: 'auto' }}>
                                        <>
                                            {(prompts?.length === currentIndex + 1 && progress > 99 ? '' :
                                                prompts?.length > 0 && prompts?.length !== currentIndex && <div className="section" style={{ backgroundColor: storedLiveData?.alert && storedLiveData?.alert?.[currentIndex]?.exists == true ? '#e8ffe8' : '#ffcccc' }}>
                                                    <div className='moment-highlight' style={{ backgroundColor: storedLiveData?.alert && storedLiveData?.alert?.[currentIndex]?.exists == true ? '#9cd794' : '#ff8080' }}>
                                                        {/* <span className='moment-desc'>Security Needs</span> */}
                                                    </div>
                                                    <div className="progress-bar" style={{ width: '100%', height: '3px', backgroundColor: 'white' }}>
                                                        <div style={{ width: `${progress}%`, height: '100%', borderRadius: '5px', backgroundColor: `${storedLiveData?.alert && storedLiveData?.alert[currentIndex]?.exists == true ? 'green' : '#fc8f8f'}`, transition: 'width 0.1s linear' }} />
                                                    </div>
                                                    <div className='moment-section'>
                                                        <p>{prompts?.[currentIndex]?.prompt}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="moment-detail">
                                                {momentList?.map(group => {

                                                    let momentsToRender = [];


                                                    if (hiddenGroups.includes(group.momentGroupId)) {
                                                        return null;
                                                    }
                                                    // Get all moment IDs that have already appeared in prompts
                                                    const traversedMomentIds = prompts && Array.isArray(prompts) && prompts?.slice(0, currentIndex + 1).map(p => p.id);

                                                    // Determine the last struck moment based on the available alerts
                                                    const lastStruckMomentId = storedLiveData?.alert?.slice(-1)[0]?.moment;

                                                    // Filter moments to include only those starting from the current prompt onward
                                                    momentsToRender = group.moments.filter((moment, index) => {
                                                        const matchedItem = storedLiveData?.alert?.find(alert => alert.moment === moment?.momentId);
                                                        const isCurrentMoment = moment.momentId === currentId;
                                                        // Include moments if they match the current prompt index or haven't been traversed yet
                                                        return isCurrentMoment || Array.isArray(traversedMomentIds) && !traversedMomentIds?.includes(moment.momentId) || !matchedItem
                                                    });

                                                    // If no moments match the criteria, skip rendering this group
                                                    if (momentsToRender.length === 0) {
                                                        return null;
                                                    }

                                                    // else {
                                                    //     momentsToRender = group.moments;
                                                    // }
                                                    return (
                                                        <div key={group.momentGroupId}>
                                                            <>
                                                                <div style={{ fontSize: '16px', fontWeight: '500' }}>
                                                                    <span>
                                                                        {group?.momentGroupName}
                                                                        <i class="bi bi-exclamation"></i>
                                                                    </span>
                                                                </div>
                                                                {momentsToRender.map((moment, index) => {
                                                                    const matchedItem = storedLiveData?.alert?.find(alert => alert?.moment === moment?.momentId);
                                                                    const isCurrentMoment = matchedItem?.moment == currentId;
                                                                    const momentExists = storedLiveData?.alert?.some(alert => alert?.moment === moment?.momentId)
                                                                    return (
                                                                        <>
                                                                            <div key={moment.momentId}>
                                                                                <ul>
                                                                                    {
                                                                                        <li style={matchedItem?.exists && isCurrentMoment ? { textDecoration: 'line-through', color: 'green' } : {}}>
                                                                                            {moment?.momentName}
                                                                                            {!matchedItem?.exists && isCurrentMoment ? <img src={exclamation} className='exclamation-img' /> : ''}
                                                                                        </li>

                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    </div>
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Nudges />
        </>
    );
};

export default LiveAssistCallDetailMobileView;