import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import './meetingPlayer.css';
import { Card } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const MeetingPlayer = forwardRef(({ url, onReady, onTimeUpdate }, ref) => {
  const playerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    play: () => {
      playerRef.current.getInternalPlayer().play();
    },
    pause: () => {
      playerRef.current.getInternalPlayer().pause();
    },
    seekTo: (seconds) => {
      playerRef.current.seekTo(seconds);
    },
    getCurrentTime: () => {
      return playerRef.current.getCurrentTime();
    }
  }));

  const handleProgress = (state) => {
    if (onTimeUpdate) {
      onTimeUpdate(state.playedSeconds);
    }
  };

  return (
    <Card>
      <Card.Body style={{ overflow: "hidden" }}>
        <div>
          <ReactPlayer
            ref={playerRef}
            url={url}
            controls={true}
            width={'100%'}
            style={{ minHeight: '375px' }}
            id="video-element"
            onReady={onReady}
            onProgress={handleProgress}
            progressInterval={1000}
          />
        </div>
      </Card.Body>
    </Card>
  );
});

export default MeetingPlayer;