import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Box from "@mui/material/Box";
import { Input } from '@mui/joy';
import { createFolder } from '../../Services/knowledgeBase.service';
import toaster from '../../Toast/toaster';
import { Typography } from '@mui/material';

function CreateFolderModal(props) {
    const [folderNameInput, setFolderNameInput] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [maxLimitReached, setMaxLimitReached] = useState(false);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmitCreateFolder();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value || "";
        if (value.length <= 25) {
            setFolderNameInput(value);
            setMaxLimitReached(false);
        } else {
            setMaxLimitReached(true);
        }
    };

    const handleSubmitCreateFolder = () => {
        const reqBody = {
            "name": folderNameInput,
            "clientCoeIds": JSON.parse(localStorage.getItem("AGENT")) && JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange,
            "parentFolderId": props?.parentFolderId
        }
        setIsSubmitting(true);
        createFolder(reqBody).then((response) => {
            if (response?.data?.status === 0) {
                props?.setShowCreateFolder(false);
                setFolderNameInput("");
                props?.getDocumentLists(props?.parentFolderId)
                toaster.success("Folder created successfully");
            } else {
                toaster.error("Failed to create folder");
            }
        })
            .catch((err) => { console.log("Api call error: ", err) })
            .finally(() => { setIsSubmitting(false) });
    }

    return (
        <>
            <Modal
                size="lg"
                show={props?.showCreateFolder}
                onHide={() => props?.setShowCreateFolder(false)}
                aria-labelledby="manager-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="manager-modal">Create folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box
                        component="form"
                        sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
                        noValidate
                        autoComplete="off"
                        onKeyPress={handleKeyPress}
                    >
                        <Input
                            id="outlined-basic"
                            value={folderNameInput}
                            placeholder="Enter Folder Name"
                            onChange={(e) => handleInputChange(e)}
                        />
                        {maxLimitReached && (
                            <Typography sx={{ color: "red", fontSize: "0.875rem" }}>
                                Maximum character limit reached
                            </Typography>
                        )}
                        <Button
                            // variant="secondary"
                            // id="manager-close-button"
                            variant={folderNameInput.trim() ? "primary" : "secondary"}
                            className="custom-create-folder-btn"
                            onClick={handleSubmitCreateFolder}
                            disabled={!folderNameInput.trim() || isSubmitting}>
                            {isSubmitting ? "Creating..." : "Submit"}
                        </Button>
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateFolderModal