import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { uploadFile } from "../../Services/knowledgeBase.service";
import toaster from "../../Toast/toaster";
import BubbleLoader from "../../Commons/BubbleLoader";

function RecordAnswer({ setSelectedAnswers, id, type}) {
    const [audioUploadLoader, setAudioUploadLoader] = useState(false)

    const addAudioElement = async (blob) => {
        const fileName = `recorded-audio-${new Date().toISOString()}.wav`;

        const formData = new FormData();
        formData.append("file", blob, fileName);
        formData.append("title", fileName);
        formData.append("mimeType", "audio/wav");

        await uploadFile(formData, fileName, "audio/wav").then((res) => {
            setAudioUploadLoader(true)
            setSelectedAnswers((prevAnswers) => ({
                ...prevAnswers,
                answers: [
                    ...prevAnswers.answers,
                    {
                        questionId: id,
                        questionType: type,
                        answer: res?.data?.data?.url,
                    },
                ],
            }));            
            toaster.success("Answer uploaded successfully!");
        })
        .catch((err) => {
            setAudioUploadLoader(false)
            toaster.error("Failed to upload answer!");
        })
        .finally(() => {
            setAudioUploadLoader(false)
        })
    };

    return (
        <div>
            {audioUploadLoader ? <div className="mt-4" style={{display:'flex', justifyContent:'space-between', alignItems:'end',flexDirection:'row-reverse'}}>
                <h6>Please wait.. Audio is uploading.</h6>
                <BubbleLoader />
            </div> :
                <div className="mt-4" style={{display:'flex', justifyContent:'space-between', alignItems:'end',flexDirection:'row-reverse'}}>
                    <h6>Tap to record your answer</h6>
                    <AudioRecorder
                        onRecordingComplete={addAudioElement}
                        audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                        }}
                        showVisualizer={true}
                    />
                </div>}
        </div>
    );
}

export default RecordAnswer;