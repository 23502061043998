import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import MeetingPlayer from './MeetingPlayer'
import { Card, Tab, Tabs, Button } from 'react-bootstrap';
import { MeetingSummary } from '../MeetingData/MeetingSummary/MeetingSummary';
import MeetingTrack from '../MeetingData/MeetingTrack/MeetingTrack';
// import MeetingStat from '../MeetingData/MeetingStat/MeetingStat';
// import MeetingMoment from '../MeetingData/MeetingMoment/MeetingMoment';
import MeetingTranscript from '../MeetingData/MeetingTranscript/MeetingTranscript';
import { getMeetingDetail, getMeetingFoulLanguage, getMeetingMoments, getMeetingQuestions, getMeetingSignals, getMeetingSpeakerTracks, getMeetingStats, getMeetingTime } from '../../Services/meeting.service';
import toaster from '../../Toast/toaster';
import MeetingInnerTranscript from '../MeetingData/MeetingInnerTrans/MeetingInnerTranscript';
// import MeetingSignal from '../MeetingData/MeetingSignals/MeetingSignal';
import MeetingQuestion from '../MeetingData/MeetingQuestion/MeetingQuestion';
import MeetingFoulLanguage from '../MeetingData/MeetingFoulLanguage/MeetingFoulLanguage';
import MeetingParticipants from '../MeetingData/MeetingParticipant/MeetingParticipant';
import MoreInfo from '../../Conversations_Dashboard/Sales/Modals/MoreInfo';

function MeetingCallDetail() {
    const history = useHistory();
    const playerRef = useRef(null);
    const [loader, setLoader] = useState(false)
    const [viewDetailsLoader, setViewDetailsLoader] = useState(false)
    const [key, setKey] = useState("stats");
    const [statsApiCall, setStatsApiCall] = useState(false)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const meetingId = urlParams.get("meetingId");
    const tabRedirected = urlParams.get("tabSelected");
    const [time, setTime] = useState("");
    const [meetingDetails, setMeetingDetails] = useState([])
    const [speakerTracks, setSpeakerTracks] = useState([])
    const [meetingStats, setMeetingStats] = useState([])
    const [meetingMoments, setMeetingMoments] = useState([])
    const [meetingSignals, setMeetingSignals] = useState([])
    const [meetingQuestions, setMeetingQuestions] = useState([])
    const [meetingFoulLanguage, setMeetingFoulLanguage] = useState([])
    const [summary, setSummary] = useState('')
    const [meetingTrans, setMeetingTrans] = useState([])
    const [videoUrl, setVideoUrl] = useState('')
    const [historyObject, setHistoryObject] = useState(history.location.state)
    const [show, setShow] = useState(false)
    const [meetingLink, setMeetingLink] = useState("")
    const USER_DETAIL=JSON.parse(localStorage.getItem("USER_DETAIL"))

    useEffect(() => {
        document.title = "Meeting Details - Odio";
    }, []);

    useEffect(() => {
        setHistoryObject(historyObject)
    }, [history.location.state])

    useEffect(() => {
        if (key == "stats" && !statsApiCall) {
            setViewDetailsLoader(true);
            getMeetingDetail(meetingId).then((res) => {
                if (res) {
                    setMeetingDetails(res.data.data);
                    setViewDetailsLoader(false);
                }
            }).catch((err) => {
                toaster.error(err)
                setViewDetailsLoader(false)
            });
            // setLoader(true);
            // getMeetingStats(meetingId).then((response) => {
            //     if (response) {
            //         setMeetingStats(response.data.data);
            //         setLoader(false);
            //     }
            // }
            // ).catch(() => {
            //     setLoader(false)
            // });
        }
    }, [key == "stats"]);

    useEffect(() => {
        setMeetingLink(meetingDetails?.meetingLink?.split("/")?.at(-1))
        setSummary(meetingDetails.summary)
        setMeetingTrans(meetingDetails.transcripts)
        setVideoUrl(meetingDetails.meetingUrl)
        setStatsApiCall(true);
    }, [meetingDetails])
  
   const handleMomentData = async (isLoader = true) => {
        isLoader && setLoader(true);
        getMeetingMoments(meetingId).then((response) => {
            if (response) {
                setMeetingMoments(response.data.data);
            }
        })
    }

    const handleSelect = async (keyVal) => {
        if (key !== keyVal) {
            setKey(keyVal);           
            if (keyVal == "stats") {
                setLoader(true);
                getMeetingStats(meetingId).then((response) => {
                    if (response) {
                        setMeetingStats(response.data.data);
                        setLoader(false);
                    }
                }
                ).catch(() => {
                    setLoader(false)
                });
            }
            if (keyVal == "moments") {
                setLoader(true);
                getMeetingMoments(meetingId).then((response) => {
                    if (response) {
                        setMeetingMoments(response.data.data);
                        setLoader(false);
                    }
                }
                ).catch(() => {
                    setLoader(false)
                });
            }
            if (keyVal == "signals") {
                setLoader(true);
                getMeetingSignals(meetingId).then((response) => {
                    if (response) {
                        setMeetingSignals(response.data.data);
                        setLoader(false);
                    }
                }
                ).catch(() => {
                    setLoader(false)
                });
            }
            if (keyVal == "questions") {
                setLoader(true);
                getMeetingQuestions(meetingId).then((response) => {
                    if (response) {
                        setMeetingQuestions(response.data.data);
                        setLoader(false);
                    }
                }
                ).catch(() => {
                    setLoader(false)
                });
            }
            if (keyVal == "foul_language") {
                setLoader(true);
                getMeetingFoulLanguage(meetingId).then((response) => {
                    if (response) {
                        setMeetingFoulLanguage(response.data.data);
                        setLoader(false);
                    }
                }
                ).catch(() => {
                    setLoader(false)
                });
            }
            if (keyVal == "speakerTrack") {
                setLoader(true);
                getMeetingTime(meetingId).then((res) => {
                    if (res) {
                        setSpeakerTracks(res.data.data)
                        setLoader(false)
                    }
                }).catch(() => {
                    setLoader(false)
                })
            }
        }
    }

    const handleTimeUpdate = (seconds) => {
        const formattedTime = formatTime(seconds);
        setTime(formattedTime);
    };

    const formatTime = (seconds) => {
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours().toString().padStart(2, "0");
        const mm = date.getUTCMinutes().toString().padStart(2, "0");
        const ss = date.getUTCSeconds().toString().padStart(2, "0");
        return `${hh}:${mm}:${ss}`;
    };

    const audioTime = (e) => {
        let a = e.split(":");
        let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        playerRef.current.seekTo(seconds);
        playerRef.current.play();
        setTime(e);
    };

    const onClickHandler = () => {
        setShow(true);
    };
    const closeButton = () => setShow(false);

    return (
        <>
            {Object.keys(meetingDetails)?.length > 0 && <div className="page-wrapper">
                <div className="page-content">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between py-3">
                                <h2 className="dashboard-headings py-2">
                                Meeting Id #{meetingLink? meetingLink : meetingId}
                                <Button
                                className="btn-mdl py-1 ms-2"
                                onClick={onClickHandler}
                                >
                                More Info.
                                </Button>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                <div className='d-flex' style={{ gap: '25px' }}>
                    <div className="" style={{ flex: '3' }}>
                        <MeetingPlayer
                            ref={playerRef}
                            url={videoUrl}
                            onReady={() => console.log('Player is ready')}
                            onTimeUpdate={handleTimeUpdate}
                        />
                    </div>
                    <div className="" style={{ flex: '2' }}>
                        <MeetingTranscript
                            callTranscript={meetingTrans}
                            audioTime={audioTime}
                            time={time}
                            playerRef={playerRef}
                            loader={viewDetailsLoader}
                        />
                    </div>
                </div>
                </div>
                <div className="col-12">
                    <Card>
                        <div className="feedback-heading" style={{ margin: '10px 0px 0px 10px' }}>
                            <h5 className="mb-0">Keywords</h5>
                        </div>
                        <Card.Body>
                        {meetingDetails?.keywordValue?.map((keyword, index) => <span key={index}>{keyword},  </span>)}
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-12">
                    <Card>
                        <Card.Body className="p-5">
                            <div className="setting-section-nev">
                                <Tabs
                                    id="controlled-tab-example"
                                    activekey={key}
                                    defaultActiveKey="summary"
                                    onSelect={handleSelect}
                                >
                                    {/* <Tab eventKey="stats" title="STATS">
                                        <MeetingStat
                                            callStats={meetingStats}
                                            loader={loader}
                                        />
                                    </Tab> */}
                                    <Tab eventKey="summary" title="SUMMARY">
                                        <MeetingSummary
                                            summary={summary}
                                            loader={viewDetailsLoader}
                                        />
                                    </Tab>
                                    <Tab eventKey="transcript" title="TRANSCRIPT">
                                        <MeetingInnerTranscript
                                            callTranscript={meetingTrans}
                                            participants={historyObject.participants}
                                            meetingDetails={meetingDetails}
                                        />
                                    </ Tab>
                                    {/* <Tab eventKey="moments" title="MOMENTS">
                                        <MeetingMoment
                                            callMoments={meetingMoments}
                                            handleMomentData={handleMomentData}
                                            audioTime={audioTime}
                                            typeOfCall={"SINGLE_CALL"}
                                            likeDislikeData={[]}
                                            loader={loader}
                                        />
                                    </Tab> */}
                                    {/* <Tab eventKey="pointers" title="POINTERS">
                                    <MeetingPointer
                                        meetingPointers={meetingPointers[0]}
                                    />
                                </Tab> */}
                                    <Tab eventKey="questions" title="QUESTIONS">
                                        <MeetingQuestion
                                            callQuestions={meetingQuestions}
                                            likeDislikeData={[]}
                                            typeOfCall={"SINGLE_CALL"}
                                            participants={historyObject.participants}
                                            loader={loader}
                                        />
                                    </Tab>
                                    <Tab eventKey="foul_language" title="FOUL LANGUAGE">
                                        <MeetingFoulLanguage
                                            foulLanguage={meetingFoulLanguage}
                                            likeDislikeData={[]}
                                            typeOfCall={"SINGLE_CALL"}
                                            participants={historyObject.participants}
                                            loader={loader}
                                        />
                                    </Tab>
                                    {/* <Tab eventKey="signals" title="SIGNALS">
                                        <MeetingSignal
                                            callSignals={meetingSignals}
                                            likeDislikeData={[]}
                                            typeOfCall={"SINGLE_CALL"}
                                            participants={historyObject.participants}
                                            loader={loader}
                                        />
                                    </Tab> */}
                                    <Tab eventKey="speakerTrack" title="SPEAKER TRACKS">
                                        <MeetingTrack
                                            speakerTracks={speakerTracks}
                                            participants={historyObject.participants}
                                            talkDuration={speakerTracks.duration}
                                            audioTime={audioTime}
                                            loader={loader}
                                        />
                                    </Tab>
                                    <Tab eventKey="participants" title="PARTICIPANTS">
                                        <MeetingParticipants
                                            participants={historyObject.participants}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                </div>
                </div>
            </div>}
        {
            <MoreInfo
            show={show}
            setShow={setShow}
            closeButton={closeButton}
            // customerName={historyObject ? historyObject?.customer : callTime?.more_info?.customerName}
            sellerName={meetingDetails?.meetingId && meetingDetails?.clientName}
            talkDuration={meetingDetails && meetingDetails?.duration}
            date={meetingDetails && meetingDetails?.date}
            processTime={meetingDetails && meetingDetails?.processingTime}
            url={meetingDetails && meetingDetails?.meetingUrl}
            // sellerId={historyObject ? historyObject?.id : callTime?.more_info?.callId}
            coeName={meetingDetails && meetingDetails?.coeName}
            // typeOfCall={typeOfCall}
            // userId={historyObject?.userId}
            // groupId={typeOfCall == "GROUP"? params : callTime?.more_info?.groupId}
            externalId={USER_DETAIL?.externalId}
            participants={meetingDetails?.participants}
            module="MEETING"
            />
        }
        </>
    )
}

export default MeetingCallDetail