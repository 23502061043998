import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from '@mui/joy';
import { createQuestionBank, getQuestionBankList } from "../../Services/questionBank.service";
import Box from "@mui/material/Box";
import { Input } from "@mui/joy";
import { toast } from "react-toastify";
import toaster from '../../Toast/toaster';
import { Typography } from "@mui/material";

const CreateQuestionBankModal = ({ openQueModal, setOpenQueModalFalse, questionSetCreated }) => {

  const [questionFolder, setQuestionFolder] = useState('')
  const [maxLimitReached, setMaxLimitReached] = useState(false);
  const [existingQuestionSets, setExistingQuestionSets] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target?.value || "";
    if (value.length <= 100) {
      setQuestionFolder(value);
      setMaxLimitReached(false);
    } else {
      setMaxLimitReached(true);
    }
  };

  const handleSubmitCreateFolder = () => {
    if (existingQuestionSets.includes(questionFolder.toLowerCase())) {
      toaster.error("Question set name must be unique");
      return;
    }

    const reqBody = {
      "name": questionFolder,
      "coeIds": JSON.parse(localStorage.getItem("AGENT")) && JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange,
    }
    createQuestionBank(reqBody).then((response) => {
      if (response?.data?.status === 0) {
        setOpenQueModalFalse()
        setQuestionFolder("");
        questionSetCreated()
        toaster.success("Question Created Successfully");
      }
      else {
        toaster.error("Failed to create question set");
      }
    }).catch((err) => { console.log("Api call error: ", err) })
  }

  useEffect(() => {
    // Fetch existing question sets to validate uniqueness
    getQuestionBankList(
      {
        coeIds: JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange || [],
      },
      1, // Fetch the first page
      1000 // Assume a large number to fetch all sets
    )
      .then((response) => {
        if (response?.status === 200) {
          setExistingQuestionSets(
            response?.data?.data?.data.map((q) => q.name.toLowerCase())
          );
        }
      })
      .catch((err) => console.error("Error fetching question sets:", err));
  }, []);
  return (
    <Modal
      size="lg"
      show={openQueModal}
      onHide={() => setOpenQueModalFalse()}
      dialogClassName="create-qBank-modal"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="manager-modal">
          <h5>Create Question Set</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "30ch" } }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{display:"flex"}}>
            <Input
              id="outlined-basic"
              value={questionFolder}
              placeholder="Enter Question"
              onChange={(e) => handleInputChange(e)}
            />
            <Typography
              sx={{
                color: questionFolder.length === 100 ? "red" : "grey",
                fontSize: "12px",
                marginLeft: "10px",
                display: 'flex',
                alignItems: 'end',
              }}
            >
              {questionFolder.length}/100
            </Typography>
            {/* {maxLimitReached && (
            <Typography sx={{ color: "red", fontSize: "0.875rem" }}>
              Maximum character limit reached
            </Typography>
          )} */}

          </Box>
          <div className="search-input">
            <Button
              // variant={questionFolder.trim() ? "primary" : "secondary"}
              // className="custom-create-folder-btn"
              onClick={handleSubmitCreateFolder}
              disabled={questionFolder === ''}>
              Submit
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  )
}

export default CreateQuestionBankModal