import toast from "../Toast/toaster";
import { apiCall } from "../../Components/Services/Interceptor";
const CREATE_SESSION = "/odio/api/coaching/session/create";
const DELETE_SESSION = "/odio/api/coaching/session/delete/";
const GET_DOCUMENT_LIST = "/odio/api/auto-coaching/get/documents";
const GET_QUESTION_LIST = "/odio/api/auto-coaching/get/questions";
const CREATE_MODULE = "/odio/api/coaching/session/module/create/";
const GET_SESSION_DATA = "/odio/api/coaching/session/";
const UPDATE_SESSION_DATA = "/odio/api/coaching/session/update/";
const UPDATE_MODULE_DATA = "/odio/api/coaching/session/module/update/";
const DELETE_MODULE = "/odio/api/coaching/session/module/delete/";
const CALL_TRAINING_ID_SUBMIT = "/odio/api/coaching/session/call_training_id/submit"

// const getDocumentList = async () => {
//     return await apiCall.get(GET_DOCUMENT_LIST);
//   };

// const managerProfile = async (id) => {
//   return await apiCall.get(PROFILE_GET + "?userId=" + id);
// };

// const agentProfile = async(id)=>{
//   return await apiCall.get(`${PROFILE_GET}?userId=${id}`)
// }

// const updateProfile = async (data) => {
//   await apiCall
//     .put(PROFILE_UPDATE, data)
//     .then((response) => {
//       if (response) {
//         if (response.data.status === 200) {
//           toast.success("Successfull");
//         }
//       }
//       return response;
// })
// }

// const localData = JSON.parse(localStorage.getItem("AGENT"))

// const myTeam = async () => {
//     const localData = JSON.parse(localStorage.getItem("AGENT"))
//     const response = await apiCall.get(`${MY_TEAM}?fromD=${localData?.fromD?localData.fromD:null}&"toD"=${localData?.toD?localData.toD:null}&coeIds=${localData?.selectedCoeRange?localData?.selectedCoeRange:null}`);
//     return response;
// }

const getDocumentList = async (data) => {
  const response = await apiCall.post(GET_DOCUMENT_LIST, data);
  return response;
};
const getQuestionList = async (data) => {
  const response = await apiCall.post(GET_QUESTION_LIST, data);
  return response;
};
const getSessionData = async (sessionId) => {
  const response = await apiCall.get(GET_SESSION_DATA + sessionId);
  return response;
};
const updateSession = async (sessionId, data) => {
  const response = await apiCall.put(UPDATE_SESSION_DATA + sessionId, data);
  return response;
};

// const getMyTeamCaller = async (data) => {
//   const response = await apiCall.post(GET_MY_TEAM_CALLER,{"coeIds":data.coeIds,"fromD":data.fromD,"toD":data.toD});
//   return response;
// }
const createSession = async (data) => {
  const response = await apiCall.post(CREATE_SESSION, {
    name: data.sessionName,
    userId: data.userId,
    comment: data.comment,
    momentIds: data.momentIds,
  });
  return response;
};
const deleteSession = async (sessionId) => {
  const response = await apiCall.delete(DELETE_SESSION + sessionId);
  return response;
};

const createModule = async (sessionData, data) => {
  const response = await apiCall.post(CREATE_MODULE + sessionData?.id, {
    name: data?.name,
    docId: data?.documents,
    questionId: data?.questions,
    questionScores: data?.questionScores
  });
  return response;
};

const updateModule = async (sessionData, data) => {
  const response = await apiCall.put(
    UPDATE_MODULE_DATA + sessionData?.id + "/" + data?.id,
    {
      name: data?.name,
      docId: data?.documents,
      questionId: data?.questions,
      questionScores: data?.questionScores
    }
  );
  return response;
};
const deleteModule = async (sessionData, data) => {
  const response = await apiCall.delete(
    DELETE_MODULE + sessionData?.id + "/" + data?.id
  );
  return response;
};
const submitCallTrainingId = async (reqBody) => {
  const response = await apiCall.post(CALL_TRAINING_ID_SUBMIT, reqBody);
  return response;
}
export default {
  createSession,
  deleteSession,
  getDocumentList,
  getQuestionList,
  createModule,
  getSessionData,
  updateSession,
  updateModule,
  deleteModule,
  submitCallTrainingId,
};
