import * as React from 'react'
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import { useState,useEffect } from 'react';
import insightService from '../../Services/insight.service';
import ConversationMetricsData from "./ConversationMetricsData";
import ConversationMetricsGraph from "./ConversationMetricsGraph";
import dashboardService from '../../Services/dashboard.service';
import profileService from '../../Services/profile.service';
import Select from '@mui/material/Select';
import Loading from '../../Commons/Loading';
import { apiCall } from '../../Services/Interceptor';
import FilterDrawer from '../../Commons/FilterDrawer';
import { onResetFilter, setDateList, setResetState, setUserOptions } from '../../../redux-container/insights/action';
import { useDispatch, useSelector } from 'react-redux';
import { positionFormat } from '../../Commons/DateFormatter';

export default function ConversationMetrics(){

    let localData = JSON.parse(localStorage.getItem("AGENT"))
    const [customInput, SetCustomInput] = useState(localData&&localData.selectedRange==9?true:localData&&localData.selectedRange==''?true:false);
    const [date, setDate] = useState(localData&&localData.selectedRange?localData.selectedRange:localData&&localData.selectedRange==''?9:5);
    const [startDate, setStartDate] = useState(localData?.fromD?new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`):new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD?new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`):new Date())
    const [dates,setDates] = useState([])
    const [userList, setUserList] = useState([])
    const [defaultFilteredData, setDefaultFilteredData] = useState([])
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [apply, setApply] = useState(false)
    const [reset, setReset] = useState(false)
    const dispatch = useDispatch()
    let chipsData = useSelector(state=>state?.insights?.chipsData)
    let filteredData = useSelector(state=>state?.insights?.filterData)
    let options = useSelector(state=>state.insights.userOptions)

    useEffect(() => {
        document.title = "Conversation Metrics  - Odio"
      }, [])

    const getDateAcronym = ()=>{
        dashboardService.getDateAcronym().then(res=>{
          let dateList = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
          }):'':''
          setDates(dateList);
          dispatch(setDateList(dateList))
          let dd = dateList[4].fromDate.split("-")[2]
          let mm = dateList[4].fromDate.split("-")[1]
          let yyyy = dateList[4].fromDate.split("-")[0]
          let dateF = `${dd}-${mm}-${yyyy}`
          let dd_1 = dateList[4].toDate.split("-")[2]
          let mm_1= dateList[4].toDate.split("-")[1]
          let yyyy_1= dateList[4].toDate.split("-")[0]
          let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
          setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,
            fromDate:dateT,
            toDate:dateF
          }))
        })
    }

    const getUserList = async()=>{
        const res = await apiCall.get(`${'/odio/api/user/get-user-team-list'}?fromD=${localData?.fromD?localData.fromD:null}&toD=${localData?.toD?localData.toD:null}&coeIds=${localData?.selectedCoeRange?localData.selectedCoeRange:''}`); 
          if(res.data.status === 0){
            setUserList(res?.data?.data?.team)
            let userIds = res?.data?.data?.team.map(item=>{
              return item.id
            })
            const teamOptions = res?.data?.data?.team.map((e,i)=>
                (
                  {"label":`${e.firstName} ${e.lastName}`,"value":`${e.id}`,"id":e.id}
                ))
            const optionsWithSelectAll = [...teamOptions];
            dispatch(setUserOptions(optionsWithSelectAll))
            setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,id:userIds}))
          }else if(res.data.status === 1){
            setLoader(false);
          }
    }

    const getConversionData = ()=>{
        let localData = JSON.parse(localStorage.getItem("AGENT"))
        let values = {
            "fromD":localData?.fromD?localData.fromD:defaultFilteredData.fromDate,
            "toD":localData?.toD?localData.toD:defaultFilteredData.toDate,
            "userIds":localData?.userIds?localData.userIds:defaultFilteredData.id,
            "coeIds":localData?.selectedCoeRange
        }
        insightService.getConversionMetrics(values).then(res=>{
            if(res){
                setData(res.data.data)
                setLoader(false)
                setApply(false)
                setReset(false)
                dispatch(setResetState(false))
            }
        })
    }

    useEffect(()=>{
        getDateAcronym()
        getUserList()
    },[])

    useEffect(()=>{
        setLoader(true)
        if(defaultFilteredData?.id?.length>0){
            getConversionData()
            localStorage.setItem("AGENT",JSON.stringify({
                "pageSelected":localData?.pageSelected,
                "analysis":localData?.analysis,
                "productivity":localData?.productivity,
                "fromD":localData&&localData.fromD?localData.fromD:defaultFilteredData.fromDate,
                "toD":localData&&localData.toD?localData.toD:defaultFilteredData.toDate,
                "agentDetailCOE":localData?.agentDetailCOE,
                "selectedRange":localData?.selectedRange,
                "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
                "userIds":localData?.userIds?localData?.userIds:defaultFilteredData.id,
                "userNames":localData?.userNames?localData.userNames:[{label:"Select All",value:"Select All"}],
                "empId":localData?.employeeId,
                "empName":localData?.user,
                "role":localData?.role,
                "designation":localData?.designation,
                "type":localData?.type,
                "teamCOE":localData?.teamCOE,
                "callsFrom":localData?.callsFrom,
                "callsTo":localData?.callsTo,
                "aht":localData?.aht,
                "callScore":localData?.callScore,
                "pitchScore":localData?.pitchScore,
                "customerName":localData?.customerName,
                "customerMobile":localData?.customerMobile,
                "fileName":localData?.fileName,
                "sellerROS":localData?.sellerROS,
                "sellerName":localData?.sellerName,
                "selectedSeller":localData?.selectedSeller,
                "cScore":localData?.cScore,
                "callHealth":localData?.callHealth,
                "callDuration":localData?.callDuration,
                "words":localData?.words,
                "moment":localData?.moment,
                "momentId":localData?.momentId,
                "pitch":localData?.pitch,
                "prob":localData?.prob,
                "opportunity":localData?.opp,
                "urgency":localData?.urgency,
                "coe":localData?.coe,
                "selectedCoeRange":localData?.selectedCoeRange,
                "selectedCOE":localData?.selectedCOE,
                "momentBucket":localData?.momentBucket,
                "momentBucketId":localData?.momentBucketId,
                "frequency":localData?.frequency,
                "reportType":localData?.reportType
            }))
        }else if (defaultFilteredData?.id?.length == 0){
            getConversionData()
        }
    },[defaultFilteredData])

    useEffect(()=>{
        if(apply){
            onSubmitHandler()
        }
        if(reset){
            onResetHandler()
            const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            dispatch(onResetFilter({"productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"selectedRange":5,"selectedDate":"This Month"}))
            dispatch(setResetState(true))
        }
    },[apply, reset])

    const onSubmitHandler = ()=>{
        localStorage.setItem("AGENT",JSON.stringify({
            "pageSelected":localData?.pageSelected,
            "analysis":localData?.analysis,
            "productivity":localData?.productivity,
            "fromD":filteredData?.fromD?filteredData?.fromD:localData?.fromD,
            "toD":filteredData?.toD?filteredData?.toD:localData?.toD,
            "agentDetailCOE":localData?.agentDetailCOE,
            "selectedRange":filteredData?.selectedRange?filteredData?.selectedRange :localData?.selectedRange,
            "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
            "userIds":filteredData?.id?filteredData?.id:localData?.userIds,
            "userNames":filteredData?.names?filteredData.names:localData?.userNames,
            "empId":localData?.employeeId,
            "empName":localData?.user,
            "role":localData?.role,
            "designation":localData?.designation,
            "teamCOE":localData?.teamCOE,
            "type":localData?.type,
            "callsFrom":localData?.callsFrom,
            "callsTo":localData?.callsTo,
            "aht":localData?.aht,
            "callScore":localData?.callScore,
            "pitchScore":localData?.pitchScore,
            "customerName":localData?.customerName,
            "customerMobile":localData?.customerMobile,
            "fileName":localData?.fileName,
            "sellerROS":localData?.sellerROS,
            "sellerName":localData?.sellerName,
            "selectedSeller":localData?.selectedSeller,
            "cScore":localData?.cScore,
            "callHealth":localData?.callHealth,
            "callDuration":localData?.callDuration,
            "words":localData?.words,
            "moment":localData?.moment,
            "momentId":localData?.momentId,
            "pitch":localData?.pitch,
            "prob":localData?.prob,
            "opportunity":localData?.opp,
            "urgency":localData?.urgency,
            "coe":localData?.coe,
            "selectedCoeRange":localData?.selectedCoeRange,
            "selectedCOE":localData?.selectedCOE,
            "momentBucket":localData?.momentBucket,
            "momentBucketId":localData?.momentBucketId,
            "frequency":localData?.frequency,
            "reportType":localData?.reportType
        }))
        getConversionData()
    }

    const onResetHandler = ()=>{
        const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        localStorage.setItem("AGENT",JSON.stringify({"pageSelected":localData?.pageSelected,"analysis":localData?.analysis,"productivity":localData?.productivity,"fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"agentDetailCOE":localData?.agentDetailCOE,"selectedRange":5,"selectedDate":"This Month","userIds":defaultFilteredData.id,"userNames":options.map(item=>item.value),"coe":localData?.coe,"selectedCoeRange":localData?.selectedCoeRange,"selectedCOE":localData?.selectedCOE,"momentBucket":localData?.momentBucket,"momentBucketId":localData?.momentBucketId}))
        // getDateAcronym()
        // getUserList()
        getConversionData()
    }

    const applyFilter = ()=>{
        setApply(true)
    }

    const resetFilter = ()=>{
        setReset(true)
    }

    const setLoaderTrue = ()=>{
        setLoader(true)
    }

    return(
        <>
        <div className="page-wrapper">
            <div className="page-content">
                {loader?
                    <div className='loader-container'>
                        <Loading/>
                    </div>:
                    <Row>
                        <Col lg={12}>
                            <Row>
                                <Col lg={12} className="mb-2">
                                    <h2 className="dashboard-headings d-inline">Conversion Metrics</h2>
                                    {Array.isArray(chipsData) && chipsData.length>0 ? 
                                    chipsData.map(item=>(
                                        <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                            {`${item?.name}, ${item?.id}`}
                                            <a href="javascript:;"></a>
                                        </span>
                                    ))
                                    :
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        All employees are selected
                                        <a href="javascript:;"></a>
                                    </span>
                                    }
                                    {filteredData && filteredData?.dateName ? 
                                        <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                             {`DATE: ${filteredData?.dateName === "Custom Date" ? `${positionFormat(filteredData?.fromD)} to ${(filteredData?.toD) }`: filteredData?.dateName }`}
                                            <a href="javascript:;"></a>
                                        </span>
                                    : 
                                        <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                            <span>DATE: </span>
                                            {`${localData?.selectedDate}`}
                                            <a href="javascript:;"></a>
                                        </span>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <Card.Body>
                                            <div className="productivity-date-section">
                                                <Tabs defaultActiveKey="heat_map" id="uncontrolled-tab-example" className="mb-3 mx-0 internal-tabs">
                                                    <Tab eventKey="heat_map" title="Heat Map">
                                                        <ConversationMetricsGraph convData={data} loader={loader}/>
                                                    </Tab>
                                                    <Tab eventKey="data" title="Data">
                                                        <ConversationMetricsData convData={data} loader={loader}/>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </div>
        </div>
        <FilterDrawer filterType='INSIGHTS' componentType='CONVERSION METRICS' applyFilter={applyFilter} setLoaderTrue={setLoaderTrue} resetFilter={resetFilter}/>
        </>
    )
}