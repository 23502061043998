import React from 'react'
import { Modal, ModalDialog, DialogTitle, Stack, FormControl, FormLabel, Input, ModalClose } from '@mui/joy';
import { Button, MenuItem, Select } from '@mui/material';

function UpdateMeetingDetailModal(props) {
    const { openModal, setOpenModal, setOptionsCard, selectedOption, handleSelectChange} = props;
    return (
        <Modal
            open={openModal}
            onClose={() => {
                setOpenModal(false)
                setOptionsCard(null)
            }}
            sx={{
                backdropFilter: 'blur(1px)', // Adjust blur amount here
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '15px' // Adjust background color and opacity
            }}
            disableEscapeKeyDown={true}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Edit Meeting Details</DialogTitle>
                {/* <DialogContent>Fill in the information of the project.</DialogContent> */}
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        setOpenModal(false);
                        setOptionsCard(null);
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Meeting Title</FormLabel>
                            <Input autoFocus required />
                            {/* <TextField id="outlined-basic" label="Meeting Title" variant="outlined" autoFocus required /> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>Client Email</FormLabel>
                            <Input required />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Client Name</FormLabel>
                            <Input required />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Who can access the recording</FormLabel>
                            <Select
                                value={selectedOption}
                                onChange={handleSelectChange}
                                required
                                sx={{ height: '38px', borderRadius: '6px !important' }}
                            >
                                <MenuItem value='test1'>Entire Workplace</MenuItem>
                                <MenuItem value='test2'>Only You</MenuItem>
                                <MenuItem value='test3'>No One</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit">Submit</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}

export default UpdateMeetingDetailModal