import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Overlay, PopoverBody } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactTooltip from "react-tooltip";
import callsService from "../Services/calls.service";
import "./ConversationsDashboard.css";
import { color } from "highcharts";
import AudioAssign from "./Sales/Modals/AudioAssign";
// import { apiCall } from "../Services/Interceptor";
// import BubbleLoader from "../Commons/BubbleLoader";
// import toaster from "../Toast/toaster";

export default function AudioPlayer(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [audioType, setAudioType] = useState("audio");
  // const [audioData, setAudioData] = useState('')
  // const [isLoading, setIsLoading] = useState(true)
  // 1 line below
  // const [audioLoaded, setAudioLoaded] = useState(false);
  // const [userInteracted, setUserInteracted] = useState(false);
  let activeMoment = "";
  // const [activeMoment, setActiveMoment] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get("callId");
  const colors = ["#A6CEE3", "#e7f7e1", "#D8D8F6", "#cffaf6", "#e6c8e1"];
  const element = document.getElementById("audio-element");
  const audioInput = useRef();
  const timeUpdate = (event) => {
    const minutes = Math.floor(event.target.currentTime / 60);
    const seconds = Math.floor(event.target.currentTime - minutes * 60);
    const currentTime =
      str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
    props.callBack(currentTime);
  };
  const [voiceInfoModal, setVoiceInfoModal] = useState(false);

  useEffect(() => {
    if (audioInput.current) {
      audioInput.current.load();
    }
  }, [props?.recUrl]);

  // useEffect(()=>{
  //   setAudioData(props?.audioData)
  //   setIsLoading(props?.isLoading)
  // },[props?.audioData, props?.isLoading])
  // useEffect(()=>{
  //     const getAudio = async (data) => {
  //     try{
  //     const response = await apiCall.get(`odio/api/internal/stream?fileName=${data}`, { responseType: 'arraybuffer' })
  //     const blob = new Blob([response.data], { type: 'audio/mp3' });
  //     const dataUrl = URL.createObjectURL(blob);
  //     setAudioData(dataUrl)
  //     setIsLoading(false);
  //   }catch{
  //     setIsLoading(false);
  //     toaster.error('Unable to fetch the Audio')
  //   }}
  //   if(props?.audioName !== undefined && apiCalll){
  //   getAudio(props?.audioName)
  //   setApiCalll(false)
  // }

  // },[props?.audioName])

  // put inside useEffect (if any prob)
  // const handleInteraction = () => {
  //   setUserInteracted(true);
  // };

  // // 1 func below
  // const handleAudioLoad = () => {
  //   setIsLoading(false);
  //   setAudioLoaded(true);
  // };

  // useEffect(() => {
  
  //   window.addEventListener('click', handleInteraction);
  
  //   return () => {
  //     window.removeEventListener('click', handleInteraction);
  //   };
  // }, []);

  const str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };
  const seekPause = () => {
    let element = document.getElementById("audio-element");
    element.pause();
  };

  const playAudio = (time) => {
    if (typeof time != "number") {
      let a = time.split(":");
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    } else {
      var seconds = time;
    }

    if (element) {
      element.currentTime = seconds;
      element.play();
    }
  };
  /* useEffect(() => {
        if(element){
        }
    }, [element?.duration]); */

  let agent_durations = [];
  let v = 0;
  let agent_moments = 0;
  props?.callTime?.sequence_agent?.map((item) => {
    // let total = (+props.talkDuration?.split(":")[0]) * 60 * 60 + (+props?.talkDuration?.split(":")[1]) * 60 + (+props?.talkDuration?.split(":")[2])
    let total = props?.talkDuration;
    if (Object.keys(item.moments).length > agent_moments)
      agent_moments = Object.keys(item.moments).length;

    if (item.time == v) {
      agent_durations.push({
        isSpeaking: true,
        value: ((item.till_time - item.start_time) / total) * 100,
        startTime: item.start_time,
        momentTime:
          Object.keys(item.moments).length > 0
            ? Object.entries(item.moments).map((group) => {
                return {
                  name: group[0],
                  value: group[1].map((val) => {
                    return val;
                  }),
                };
              })
            : "",
          statTime:
            Object.keys(item.stats).length > 0
              ? Object.entries(item.stats).map((group) => {
                  return {
                    name: group[0],
                    value: group[1].map((val) => {
                      return val;
                    }),
                  };
                })
              : '',    
      });
    } else if (v == 0) {
      agent_durations.push(
        { isSpeaking: false, value: ((item.start_time - 0) / total) * 100 },
        {
          isSpeaking: true,
          value: ((item.till_time - item.start_time) / total) * 100,
          startTime: item.start_time,
          momentTime:
            Object.keys(item.moments).length > 0
              ? Object.entries(item.moments).map((group) => {
                  return {
                    name: group[0],
                    value: group[1].map((val) => {
                      return val;
                    }),
                  };
                })
              : "",
          statTime:
              Object.keys(item.stats).length > 0
                ? Object.entries(item.stats).map((group) => {
                    return {
                      name: group[0],
                      value: group[1].map((val) => {
                        return val;
                      }),
                    };
                  })
                : '',        
        }
      );
    } else {
      agent_durations.push(
        { isSpeaking: false, value: ((item.start_time - v) / total) * 100 },
        {
          isSpeaking: true,
          value: ((item.till_time - item.start_time) / total) * 100,
          startTime: item.start_time,
          momentTime:
            Object.keys(item.moments).length > 0
              ? Object.entries(item.moments).map((group) => {
                  return {
                    name: group[0],
                    value: group[1].map((val) => {
                      return val;
                    }),
                  };
                })
              : "",
            statTime:
              Object.keys(item.stats).length > 0
                ? Object.entries(item.stats).map((group) => {
                    return {
                      name: group[0],
                      value: group[1].map((val) => {
                        return val;
                      }),
                    };
                  })
                : '',       
              
        }
      );
    }
    v = item.till_time;
  });

  let customer_durations = [];
  var le = 0;
  let customer_moments;
  props?.callTime?.sequence_customer?.map((item) => {
    // let total  = (+props?.talkDuration?.split(":")[0]) * 60 * 60 + (+props?.talkDuration?.split(":")[1]) * 60 + (+props?.talkDuration?.split(":")[2])
    let total = props?.talkDuration;
    if (Object.keys(item.moments).length > customer_moments)
      customer_moments = Object.keys(item.moments).length;
    if (item.start_time == le) {
      customer_durations.push({
        isSpeaking: true,
        value: ((item.till_time - item.start_time) / total) * 100,
        startTime: item.start_time,
        momentTime:
          Object.keys(item.moments).length > 0
            ? Object.entries(item.moments).map((group) => {
                return {
                  name: group[0],
                  value: group[1].map((val) => {
                    return val;
                  }),
                };
              })
            : "", 
      });
    } else if (le == 0) {
      customer_durations.push(
        { isSpeaking: false, value: ((item.start_time - 0) / total) * 100 },
        {
          isSpeaking: true,
          value: ((item.till_time - item.start_time) / total) * 100,
          startTime: item.start_time,
          momentTime:
            Object.keys(item.moments).length > 0
              ? Object.entries(item.moments).map((group) => {
                  return {
                    name: group[0],
                    value: group[1].map((val) => {
                      return val;
                    }),
                  };
                })[0]
              : "",
             
        }
      );
    } else {
      customer_durations.push(
        { isSpeaking: false, value: ((item.start_time - le) / total) * 100 },
        {
          isSpeaking: true,
          value: ((item.till_time - item.start_time) / total) * 100,
          startTime: item.start_time,
          momentTime:
            Object.keys(item.moments).length > 0
              ? Object.entries(item.moments).map((group) => {
                  return {
                    name: group[0],
                    value: group[1].map((val) => {
                      return val;
                    }),
                  };
                })
              : "",
        }
      );
    }
    le = item.till_time;
  });

  const onMouseEnter = (val) => {
    const nodes = document.getElementsByClassName("moments");
    let momentToMove = null;

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id !== val) {
        // nodes[i].parentElement.style.zIndex = -1;
        // nodes[i].parentElement.style.zIndex = nodes[i].parentElement.childElementCount >= 3 ? 1: -1
        nodes[i].parentElement.style.display = "none";
        nodes[i].style.display = "none";
      } else {
        // nodes[i].parentElement.style.zIndex = 1;
        //nodes[i].style.display='block';

        momentToMove = nodes[i];
      }
    }

    if (momentToMove) {
      momentToMove.parentElement.style.display = "block";
      momentToMove.style.display = "block";

      const cardRect = momentToMove.closest(".card").getBoundingClientRect();
      const momentRect = momentToMove.getBoundingClientRect();

      if (momentRect.right >= cardRect.right) {
        const xOffset = momentRect.right - cardRect.right;
        momentToMove.style.transform = `translate(-${xOffset}px, -50%)`;
      }
    }

    activeMoment = val;
  };

  const onMouseLeave = (val) => {
    const nodes = document.getElementsByClassName("moments");

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id !== val) {
        // nodes[i].parentElement.style.zIndex = 0;
        nodes[i].parentElement.style.display = "block";
        nodes[i].style.display = "block";
      } else {
        nodes[i].style.transform = "none";
      }
    }
  };

  return (<>
    <Card>
      <Card.Body style={{ overflow: "hidden" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {audioType === "audio" ? (
        // {audioType === "audio" ? isLoading ? <div className="calls-container">
        //             <BubbleLoader />
        //           </div> :(
          <audio
            id="audio-element"
            controls
            className="w-100 call-detail-audio-panal"
            controlsList="nodownload"
            // autoPlay
            onTimeUpdate={timeUpdate}
            ref={audioInput}
            // // 2 lines below
            // onClick={() => userInteracted && playAudio(0)}
            //     onLoadedData={handleAudioLoad}
                preload="metadata"
          >
            <source src={props?.recUrl} type="audio/wav"></source>
            {/* <source src={audioData} type="audio/wav"></source> */}
            Your browser does not support the audio element.
          </audio>
        ) : (
          <video width="320" height="240" id="video-element" controls>
            <source src={props?.recUrl} type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        )}
        <div className="detail-top-head text-end" onClick={() => setVoiceInfoModal(true)}>
          <span className="badge rounded-pill btn btn-outline-primary px-2 my-1 py-2 font-14">
            Create
          </span>
        </div>
       </div>
        <div className="mt-2">
          <div
            className="d-flex w-100 justify-content-between"
            style={{ marginTop: "15px" }}
          >
            <h5 className="mb-1 font-16">
              <span className="badge rounded-pill bg-light-blue text-dark f-normal">
                Customer
              </span>
            </h5>
            <small className="text-muted"></small>
          </div>
          <div className="details-audio w-100 my-1 details-audio-graph">
            <div className="progress" style={{ marginTop: "23px" }}>
              {customer_durations?.map((item, id) => {
                if (item.isSpeaking) {
                  return (
                    <>
                      {" "}
                      {item.momentTime ? (
                        item.momentTime.length > 0 ? (
                          <div className="t-tip">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item.momentTime.map((mom, i) => (
                                <div
                                  className="moments"
                                  id={i+mom.name}
                                  style={{
                                    marginTop: `${20 - i * 20}px`,
                                    background: `${
                                      colors[
                                        Math.floor(
                                          Math.random() * colors.length
                                        )
                                      ]
                                    }`,
                                  }}
                                  onMouseOver={() => onMouseEnter(i+mom.name)}
                                  onMouseOut={() => onMouseLeave(i+mom.name)}
                                >
                                  {mom.name}
                                </div>
                              ))}
                            </div>
                            <div class="drop-tooltip">
                              <span className="drop-text">
                                {item.momentTime ? item.momentTime.length : ""}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}                      
                      <div
                        role="progressbar"
                        className="progress-bar"
                        key={id}
                        onClick={() => playAudio(item.startTime)}
                        // onClick={() =>userInteracted && playAudio(item.startTime)}
                        aria-valuenow={item.value}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          background: "#0dcaf0",
                          width: `${item.value}%`,
                        }}
                      ></div>
                    </>
                  );
                } else {
                  return (
                    <div
                      role="progressbar"
                      className="progress-bar"
                      key={id}
                      aria-valuenow={item.value}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ background: "#e9ecef", width: `${item.value}%` }}
                    ></div>
                  );
                }
              })}
            </div>
          </div>
          <div
            className="d-flex w-100 justify-content-between"
            style={{ marginTop: "20px" }}
            >
            <h5 className="mb-1 font-16">
              <span className="badge rounded-pill bg-light-blue text-dark f-normal">
                Agent
              </span>
            </h5>
            </div>
           <div className="details-audio w-100 my-1 details-audio-graph">
            <div className="progress" style={{ marginTop: "40px" }}>
              {agent_durations?.map((item, i) => {
                if (item.isSpeaking) {
                  return (
                    <>
                      {item.momentTime ? (
                        item.momentTime.length > 0 ? (
                          <div className="t-tip">
                            {/* <div> */}
                            {item.momentTime.map((mom, index) => (
                              <div
                                className="moments"
                                id={i + '-' + mom.name}               
                                key={mom.name}
                                style={{
                                  marginTop: `${20 - index * 20}px`,
                                  background: `${
                                    colors[
                                      Math.floor(Math.random() * colors.length)
                                    ]
                                  }`,
                                  borderColor: `${
                                    colors[
                                      Math.floor(Math.random() * colors.length)
                                    ]
                                  }`,
                                }}
                                onMouseEnter={(e) => {onMouseEnter(i + '-' + mom.name)}}
                                onMouseLeave={(e) => {onMouseLeave(i + '-' + mom.name)}}
                              >
                                {mom.name}
                              </div>
                            ))}
                            {/* </div> */}
                            <div class="drop-tooltip" style={{ color: "red" }}>
                              <span className="drop-text">
                                {item.momentTime ? item.momentTime.length : ""}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {item.statTime ? (
                        item.statTime.length > 0 ? (
                          <div className="t-tip">
                            {/* <div> */}
                            {item.statTime.map((mom, index) => (
                              <div
                                className="moments"
                                id={i + '-' + mom.name}               
                                key={mom.name}
                                style={{
                                  marginTop: `${20 - index * 20}px`,
                                  background: `${
                                    colors[
                                      Math.floor(Math.random() * colors.length)
                                    ]
                                  }`,
                                  borderColor: `${
                                    colors[
                                      Math.floor(Math.random() * colors.length)
                                    ]
                                  }`,
                                }}
                                onMouseEnter={(e) => {onMouseEnter(i + '-' + mom.name)}}
                                onMouseLeave={(e) => {onMouseLeave(i + '-' + mom.name)}}
                              >
                                {mom.name}
                              </div>
                            ))}
                            {/* </div> */}
                            <div class="drop-tooltip" style={{ color: "red" }}>
                              <span className="drop-text">
                                {item.statTime ? item.statTime.length : ""}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <div
                        role="progressbar"
                        className="progress-bar"
                        key={i}
                        onClick={() => playAudio(item.startTime)}
                        // onClick={() =>userInteracted && playAudio(item.startTime)}
                        aria-valuenow={item.value}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ background: "blue", width: `${item.value}%` }}
                      ></div>
                    </>
                  );
                } else {
                  return (
                    <div
                      role="progressbar"
                      className="progress-bar"
                      key={i}
                      aria-valuenow={item.value}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ background: "#e9ecef", width: `${item.value}%` }}
                    ></div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Card.Body>
      {/* <ReactTooltip place="bottom" type="light" effect="solid" /> */}
    </Card>
      {props?.callTime?.keywordValue?.length > 0 &&
        <div className="col-12">
          <Card>
              <div className="feedback-heading" style={{ margin: '10px 0px 0px 10px' }}>
                  <h5 className="mb-0">Keywords</h5>
              </div>
              <Card.Body>
              {props?.callTime?.keywordValue?.map((keyword, index) => <span key={index}>{keyword},  </span>)}
              </Card.Body>
          </Card>
        </div>
      }
      <AudioAssign openModal={voiceInfoModal} setOpenModal={setVoiceInfoModal} audioUrl={props?.recUrl} audioPathUrl={props?.audioPathUrl}/>
  </> 
  );
}
