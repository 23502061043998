import React from "react";
import Loading from "../../Commons/Loading";
import Card from "react-bootstrap/Card";
import NodataFound from "../../Commons/NoDataFound";
import EmailContent from "../../Conversations_Dashboard/Transcript/EmailContent";

const Summary = (props) => {
  let { emailSummary, loader } = props;

  const renderContent = (content) => {
    // Check if the content looks like having \n or \n\r 
    const isContent = content.includes("\n");
    if (isContent) {
      return <EmailContent trans={content} />;
    } else  {
      return <Card.Text>{content}</Card.Text>;
    }
  }

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : emailSummary?.length > 0 ? (
        <>
          <div className="tab-pane" role="tabpanel">
            <div className="d-flex align-items-center feedback-headder my-2">
              <div className="feedback-heading">
                <h5>Summary</h5>
              </div>
            </div>
          </div>
          <Card style={{ width: "100%" }}>
            <Card.Body>
              {renderContent(emailSummary?.[0]?.summary)} 
            </Card.Body>
          </Card>
        </>
      ) : (
        <NodataFound />
      )}
    </>
  );
};

export default Summary;
