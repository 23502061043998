import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { SIGN_OUT } from '../redux-container/login/types';
import odio_logo_blue from "../assets/images/odio_logo_blue.png";
import { apiCall } from '../Components/Services/Interceptor';
import toaster from '../Components/Toast/toaster';
import Loading from '../Components/Commons/Loading';
import Select from 'react-select';

function BalicOnBoardDetails() {
    const dispatch = useDispatch()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const name = urlParams.get("name");
    const id = urlParams.get("id");
    const location = urlParams.get("location");
    const email = urlParams.get("email");
    const role = urlParams.get("role");

    const [formData, setFormData] = useState({
        firstName: name,
        employeeId: id,
        email: email,
        designation: role,
        coeExternalId: '',
        managerId: [],
        roleId: "2",
        mobileNumberCountryCode: "91",
        mobileNumber: '',
        live: true,
        status: true,
    });

    const [coeData, setCoeData] = useState([])
    const [leadData, setLeadData] = useState([])
    const [disable, setDisable] = useState('')
    const [currentCoeId, setCurrentCoeId] = useState('')
    const [show,setShow] = useState(false)
    const [hovered, setHovered] = useState(false);
    const [loading,setLoading] = useState(false)

    useEffect( async ()=>{
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: SIGN_OUT, payload: false})
        document.title = "OnBoarding Details"

        const fetchData = async () => {
          try {
            const fetchCoe = await apiCall.get("/odio/balic_onboard/coe")
            setCoeData(fetchCoe?.data?.data)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }

        fetchData()

        function generateRandomMobileNumber() {
          const min = 1000000000;
          const max = 9999999999;
          const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
          return String(randomNumber);
      }
  
        setFormData(prevFormData => ({
            ...prevFormData,
            mobileNumber: generateRandomMobileNumber(),
        }));

    },[])
    useEffect(() => {
      // Clear managerId field when currentCoeId changes
      if (formData.managerId.length > 0) {
          setFormData(prevFormData => ({ ...prevFormData, managerId: [] }));
      }
  }, [currentCoeId]);
    useEffect(()=>{
      if(formData.coeExternalId !== ""){
        const getLead = async () => {
          try {
            const fetchLead = await apiCall.get(`/odio/balic_onboard/get-coe-user/${currentCoeId}`)
            setLeadData(fetchLead?.data?.data)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        getLead()
      }
    },[currentCoeId])

    useEffect(()=>{
        setDisable(() => {
            for (const key in formData) {
                if (formData[key] == null || formData[key] == "") {
                    return true;
                }
            }
            return false
        })
    },[formData])

    const handleChange = (selectedOptions, actionMeta) => {
      if (actionMeta.name === "managerId") {
          const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
          setFormData(prevFormData => ({ ...prevFormData, [actionMeta.name]: selectedIds }));
      } else if (actionMeta.name === "coeExternalId") {
          setCurrentCoeId(selectedOptions ? selectedOptions.id : '');
          setFormData(prevFormData => ({ ...prevFormData, [actionMeta.name]: selectedOptions ? selectedOptions.value : '', managerId: [] }));
          // setLeadData([])
      }
  };

    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        await apiCall.post('/odio/balic_onboard/create_user',{...formData, lastName:''}).then(res=>{
          if(res.data.status===0){
            // toaster.success("User Created Successfully")
            setShow(true)
            setLoading(false)
          }else if(res.data.status==1){
            toaster.error(res.data.message)
            setLoading(false)
          }
        })
        .catch(error=>{
          setLoading(false)
        })
    }

  return (
    show == false ?
    <>
      <div className="wrapper forget-content">
        <div className="section-authentication-signin d-flex justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="p-4 rounded">
                      <div className="text-center mb-4 p-0">
                        <a className="navbar-brand" href="#">
                          <img src={odio_logo_blue} width={140} alt="" />
                        </a>
                      </div>
                        <div className="form-body">
                          <form className="row g-3">
                            <h4 className=" font-weight-bold">
                              Balic OnBoarding
                            </h4>
                            <p className="text-muted m-0">
                              Enter your Details
                            </p>
                            <div className='d-flex flex-column gap-2 mb-4'>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-user-circle'/>
                                </span>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="form-control form-controlm"
                                  id="inputName"
                                  placeholder="Enter Agent Name"
                                  readOnly
                                  value={formData.firstName}
                                />
                              </div>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bxs-user-detail'/>
                                </span>
                                <input
                                  type="text"
                                  name="employeeId"
                                  className="form-control form-control-m"
                                  id="inputId"
                                  placeholder="Enter Agent Id"
                                  readOnly
                                  value={formData.employeeId}
                                />
                              </div>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-current-location' />
                                </span>
                                <input
                                  type="text"
                                  name="location"
                                  className="form-control form-control-m"
                                  id="inputLocation"
                                  placeholder="Enter Agent Location"
                                  readOnly
                                  value={location}
                                />
                              </div>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-envelope'/>
                                </span>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control form-control-m"
                                  id="inputEmail"
                                  placeholder="Enter Agent Email"
                                  readOnly
                                  value={formData.email}
                                />
                              </div>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-briefcase'/>
                                </span>
                                <input
                                  type="text"
                                  name="designation"
                                  className="form-control form-control-m"
                                  id="inputDesignation"
                                  placeholder="Enter Designation"
                                  readOnly
                                  value={formData.designation}
                                />
                              </div>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-briefcase'/>
                                </span>
                                <input
                                  type="text"
                                  name="role"
                                  className="form-control form-control-m"
                                  id="inputRole"
                                  placeholder="Enter Role"
                                  readOnly
                                  value={formData.designation}
                                />
                              </div>
                            <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-list-ul'/>
                                </span>
                                <Select
                                    className="form-control p-0"
                                    name='coeExternalId'
                                    id='inputCoe'
                                    options={coeData.map(item => ({ value: item.externalId, label: item.coeName, id: item.coeId }))}
                                    onChange={handleChange}
                                    placeholder="Select COE"
                                />
                            </div>
                            <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i class='bx bx-list-ul'/>
                                </span>
                                <Select
                                    isMulti
                                    className="form-control p-0"
                                    name='managerId'
                                    id='inputLead'
                                    value={leadData.filter(option => formData.managerId.includes(option.id)).map(option => ({ value: option.id, label: option.firstName }))}
                                    options={leadData.map(item => ({ value: item.id, label: item.firstName }))}
                                    onChange={handleChange}
                                    placeholder="Select Team Lead"
                                />
                            </div>
                            </div>
                            <div className="d-grid gap-2 mt-0">
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-lg f-send-btn"
                                disabled={disable}
                              >
                                {loading ? (<Loading variant="light" />) : (<>Submit</>)} 
                              </button>
                            </div>
                          </form>
                        </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </> :
    <>
      <div className="wrapper forget-content">
        <div className="section-authentication-signin d-flex justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="p-4 rounded">
                      <div className="text-center mb-4 p-0">
                        <a className="navbar-brand" href="#">
                          <img src={odio_logo_blue} width={140} alt="" />
                        </a>
                      </div>
                      <div className="d-grid gap-2 mt-0">
                        <div className ='d-flex align-items-center justify-content-center' style={{height:"50px",marginBottom: "12px"}}>
                          <p class="font-weight-bold" style={{marginBottom:'0px',fontSize:'larger'}}>User Created Successfully !</p>
                        </div>
                        <a href="/balic_onboard" className={`btn ${hovered ? 'btn-light' : ''} btn-lg`} style={{color:'#0a58ca'}} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                          <i className="bx bx-arrow-back me-1" />
                          Back to OnBoarding
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BalicOnBoardDetails