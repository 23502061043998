import { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import callsService from "../../../Services/calls.service"
import Loading from "../../../Commons/Loading"
import { apiCall } from "../../../Services/Interceptor"
import notificationsService from "../../../Services/notifications.service"
import toaster from "../../../Toast/toaster"
import { createMeetingDetailComments, getMeetingDetailComments } from "../../../../Components/Services/meeting.service"

export default function CommentOnModal(props) {

	const [transComment, setTransComment] = useState("")
	const [statsComment, setStatsComment] = useState("")
	const [momentComment, setMomentComment] = useState("")
	const [foulComment, setFoulComment] = useState("")
	const [signalComment, setSignalComment] = useState("")
	const [quesComment, setQuesComment] = useState("")
	const [transList, setTransList] = useState()
	const [clicked, setClicked] = useState(true)
	const [loader, setLoader] = useState(false)
	const [toastSuccess, setToastSuccess] = useState(false);

	const [filterVisible, setFilterVisible] = useState(true);

	const closeFilter = () => {
		setFilterVisible(false);
	};
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const params = urlParams.get('callId')
	const typeOfCall = urlParams.get("name");

	const handleChange = (event) => {
		if (event.target.name == 'text' && props.tabSelected == 'transcript') {
			setTransComment(event.target.value)
		}
		if (event.target.name == 'text' && props.tabSelected == 'stats' || event.target.name == 'text' && props.tabSelected == 'teamStats') {
			setStatsComment(event.target.value)
		}
		if (event.target.name == 'text' && (props.tabSelected == 'callMoments' || props.tabSelected == 'teamMoments')) {
			setMomentComment(event.target.value)
		}
		if (event.target.name == 'text' && props.tabSelected == 'signals') {
			setSignalComment(event.target.value)
		}
		if (event.target.name == 'text' && props.tabSelected == 'questions') {
			setQuesComment(event.target.value)
		}
		if (event.target.name == 'text' && props.tabSelected == 'foulLanguage') {
			setFoulComment(event.target.value)
		}
	}
	const addCommentHandler = () => {
		setTransComment("")
		setStatsComment("")
		setMomentComment("")
		setSignalComment("")
		setQuesComment("")
		setFoulComment("")
		let val = {
			"callId": props?.callId,
			"commentType": props?.tabSelected == 'transcript' ? "TRANSCRIPT" : props?.tabSelected == 'stats' ? "STATS" : props?.tabSelected == 'callMoments' ? "MOMENTS" : props?.tabSelected == 'signals' ? "SIGNALS" : props?.tabSelected == 'questions' ? "QUESTIONS" : props?.tabSelected == 'foulLanguage' ? "FOUL_LANGUAGE" : "",
			"name": props?.tabSelected == 'transcript' ? transComment : props?.tabSelected == 'stats' ? statsComment : props?.tabSelected == 'callMoments' ? momentComment : props?.tabSelected == 'signals' ? signalComment : props.tabSelected == 'questions' ? quesComment : props.tabSelected == 'foulLanguage' ? foulComment : '',
			"postId": props?.tabSelected == 'transcript' ? props?.transId : props?.tabSelected == 'stats' ? props?.statsId : props?.tabSelected == 'callMoments' ? props?.momentId : props?.tabSelected == 'signals' ? props?.signalId : props.tabSelected == 'questions' ? props?.quesId : props?.tabSelected == 'foulLanguage' ? props?.foulId : "",
			"postText": props?.tabSelected == 'transcript' ? props?.trans : props?.tabSelected == 'stats' ? props?.stats : props?.tabSelected == 'callMoments' ? props?.moment : props?.tabSelected == 'signals' ? props?.signals : props.tabSelected == 'questions' ? props?.questions : props.tabSelected == 'foulLanguage' ? props?.foul : "",
			"speaker": props?.tabSelected == 'transcript' ? props?.transSpeaker : props?.tabSelected == 'signals' ? props?.signalSpeaker : props?.tabSelected == 'questions' ? props?.quesSpeaker : props.tabSelected == 'foulLanguage' ? props?.foulSpeaker : ""
		}
		let notificationBody = [{
			"callId": props?.typeOfCall == "SINGLE_CALL" || props?.module === "MEETING" ? props?.callId : null,
			"userExternalId": props?.agentExternalId,
			"groupId": props?.typeOfCall == "GROUP" ? props?.callId : null,
			"notificationEvent": {
				"eventType": "COMMENT",
				"value": props?.tabSelected == 'transcript' ? transComment : props?.tabSelected == 'stats' ? statsComment : props?.tabSelected == 'callMoments' ? momentComment : props?.tabSelected == 'signals' ? signalComment : props.tabSelected == 'questions' ? quesComment : props.tabSelected == 'foulLanguage' ? foulComment : '',
				"tabName": props?.tabSelected,
				"tabValue": props?.tabSelected == 'transcript' ? props?.trans : props?.tabSelected == 'stats' ? props?.stats : props?.tabSelected == 'callMoments' ? props?.moment : props?.tabSelected == 'signals' ? props?.signals : props.tabSelected == 'questions' ? props?.questions : props.tabSelected == 'foulLanguage' ? props?.foul : "",
				"postId": props?.tabSelected == 'transcript' ? props?.transId : props?.tabSelected == 'stats' ? props?.statsId : props?.tabSelected == 'callMoments' ? props?.momentId : props?.tabSelected == 'signals' ? props?.signalId : props.tabSelected == 'questions' ? props?.quesId : props?.tabSelected == 'foulLanguage' ? props?.foulId : "",
				"notificationModule": props?.module
			}
		}]
		//getEmailDetailStats
		if (props?.tabSelected != 'teamStats' && props.tabSelected != 'teamMoments') {
			const createDetailComments = props?.module === "MEETING"? createMeetingDetailComments : callsService.createCallDetailComments;
			const getDetailComments = props?.module === "MEETING"? getMeetingDetailComments : callsService.getCallDetailComments;

			createDetailComments(val).then(response => {
				if (response) {
					getDetailComments(props?.callId,
						props?.tabSelected == 'transcript' ? "TRANSCRIPT" : props?.tabSelected == 'stats' ? "STATS" : props?.tabSelected == 'callMoments' ? "MOMENTS" : props?.tabSelected == 'signals' ? "SIGNALS" : props?.tabSelected == 'questions' ? "QUESTIONS" : props?.tabSelected == 'foulLanguage' ? "FOUL_LANGUAGE" : "",
						props?.tabSelected == 'transcript' ? props?.transId : props?.tabSelected == 'stats' ? props?.statsId : props?.tabSelected == 'callMoments' ? props?.momentId : props?.tabSelected == 'signals' ? props?.signalId : props.tabSelected == 'questions' ? props?.quesId : props?.tabSelected == 'foulLanguage' ? props?.foulId : "")
						.then(response => {
							setLoader(true)
							if (response) {
								setLoader(false)
								setTransList(response.data.data)
							}
						})
					if (props?.tabSelected == 'stats') {
						let coeIds = JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange
      					callsService.getCallDetailStats(props?.callId, typeOfCall).then((res) => {
							if (res) {
								props.callback(res.data.data)
							}
						})
					}
					else if (props?.tabSelected == 'callMoments') {
						callsService.getCallDetailMoments(props?.callId, props?.typeOfCall, JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange).then(response => {
							if (response) {
								props.callback(response.data.data)
							}
						})
					}
					else if (props?.tabSelected == 'signals') {
						callsService.getCallDetailSignals(props?.callId).then(res => {
							if (res) {
								props.callback(res.data.data)
							}
						})
					}
					else if (props?.tabSelected == 'questions') {
						callsService.getCallDetailQuestions(props?.callId).then(res => {
							if (res) {
								props.callback(res.data.data)
							}
						})
					}
					else if (props?.tabSelected == 'foulLanguage') {
						callsService.getCallDetailFoul(props?.callId).then(response => {
							if (response) {
								props?.callback(response.data.data)
							}
						})
					}
					else if (props?.tabSelected == 'transcript') {
						callsService.getCallDetailTranscript(props?.callId).then(res => {
							if (res) {
								props.callback(res.data.data)
							}
						})
					}
				}
				props.closeButton()
			})
			notificationsService.createNotifications(notificationBody).then(res => {
				if (res) {
					toaster.success(res.data.message)
					setToastSuccess(true);
					props.closeButton()
				}
			})
			props.updateState()
			closeFilter()

		}
		else {
			let teamNotificationBody = [{
				"callId": null,
				"userExternalId": props?.agentExternalId,
				"mobileNumber": props?.phone,
				"notificationEvent": {
					"eventType": "COMMENT",
					"value": props?.tabSelected == 'teamStats' ? statsComment : props?.tabSelected == 'teamMoments' ? momentComment : "",
					"tabName": props?.tabSelected,
					"postId": props?.tabSelected == 'teamStats' ? props?.statsId : props?.tabSelected == 'teamMoments' ? props?.momentId : "",
					"tabValue": props?.tabSelected == 'teamStats' ? props?.teamStats : props?.tabSelected == 'teamMoments' ? props?.moment : "",
				}
			}]
			let teamVal = {
				"commentType": props?.tabSelected == 'teamStats' ? "STATS" : props?.tabSelected == 'teamMoments' ? "MOMENTS" : "",
				"name": props?.tabSelected == 'teamStats' ? statsComment : props?.tabSelected == 'teamMoments' ? momentComment : "",
				"postId": props?.tabSelected == 'teamStats' ? props?.statsId : props?.tabSelected == 'teamMoments' ? props?.momentId : "",
				"postText": props?.tabSelected == 'teamStats' ? props?.teamStats : props?.tabSelected == 'teamMoments' ? props?.moment : "",
				"userId": props?.callId
			}
			let valuesStats = {
				"coeIds": JSON.parse(localStorage.getItem("AGENT"))?.agentDetailCOE,
				"fromD": JSON.parse(localStorage.getItem("AGENT"))?.fromD,
				"toD": JSON.parse(localStorage.getItem("AGENT"))?.toD,
				"userId": [params],
				// "userExternalId":[props?.agentExternalId]
			}
			let valuesMoments = {
				"coeId": JSON.parse(localStorage.getItem("AGENT"))?.agentDetailCOE,
				"fromD": JSON.parse(localStorage.getItem("AGENT"))?.fromD,
				"toD": JSON.parse(localStorage.getItem("AGENT"))?.toD,
				"userId": params,
				// "userExternalId":[props?.agentExternalId]
			}
			callsService.getTeamComment(teamVal).then(res => {
				if (res) {
					callsService.getCallDetailComments(props?.callId, props?.tabSelected == 'teamStats' ? "STATS" : props?.tabSelected == 'teamMoments' ? "MOMENTS" : "", props?.tabSelected == 'teamStats' ? props?.statsId : props?.tabSelected == 'teamMoments' ? props?.momentId : "").then(res => {
						setLoader(true)
						if (res) {
							setLoader(false)
							setTransList(res.data.data)
						}
					})
					if (props?.tabSelected == 'teamStats') {
						// if(params){
						// 	delete(valuesStats.userExternalId)
						// }
						// else{
						// 	delete valuesStats.userId
						// }
						callsService.getTeamMemberStats(valuesStats).then((res) => {
							if (res) {
								props.callback(res.data.data)
								setLoader(false)
							}
						})
					}
					else if (props?.tabSelected == 'teamMoments') {
						// if(params){
						// 	delete(valuesMoments.userExternalId)
						// }
						// else{
						// 	delete valuesMoments.userId
						// }
						callsService.getTeamMemberMoments(valuesMoments).then(res => {
							if (res) {
								props.callback(res.data.data)
								setLoader(false)
							}
						})
					}
				}
			})
			notificationsService.createNotifications(teamNotificationBody).then(res => {
				if (res) {
					toaster.success(res.data.message)
					props.closeButton()
				}
			})
			props.updateState()
		}
		// closeFilter();

	}

	useEffect(() => {
		if (toastSuccess) {
			props.closeButton();
		}
	}, [toastSuccess]);

	const getComments = () => {
		const getDetailComments = props?.module === "MEETING"? getMeetingDetailComments	: callsService.getCallDetailComments;

		getDetailComments(props?.callId,
			props?.tabSelected == 'transcript' ? "TRANSCRIPT" : props?.tabSelected == 'stats' || props?.tabSelected == 'teamStats' ? "STATS" : props?.tabSelected == 'callMoments' || props?.tabSelected == 'teamMoments' ? "MOMENTS" : props?.tabSelected == 'signals' ? "SIGNALS" : props?.tabSelected == 'questions' ? "QUESTIONS" : props?.tabSelected == 'foulLanguage' ? "FOUL_LANGUAGE" : "",
			props?.tabSelected == 'transcript' ? props?.transId : props?.tabSelected == 'stats' || props?.tabSelected == 'teamStats' ? props?.statsId : props?.tabSelected == 'callMoments' || props?.tabSelected == 'teamMoments' ? props?.momentId : props?.tabSelected == 'signals' ? props?.signalId : props.tabSelected == 'questions' ? props?.quesId : props?.tabSelected == 'foulLanguage' ? props?.foulId : "")
			.then(response => {
				setLoader(true)
				if (response) {
					setLoader(false)
					setTransList(response.data.data)
				}
			})
	}

	useEffect(() => {
		if ((props.transId !== undefined && props?.tabSelected == 'transcript') || (props?.statsId !== undefined && props?.tabSelected == 'stats') || (props?.statsId !== undefined && props?.tabSelected == 'teamStats') || (props?.momentId !== undefined && props?.tabSelected == 'callMoments') || (props?.momentId !== undefined && props?.tabSelected == 'teamMoments') || (props?.signalId !== undefined && props?.tabSelected == 'signals') || (props.quesId !== undefined && props?.tabSelected == 'questions') || (props?.foulId !== undefined && props?.tabSelected == 'foulLanguage')) {
			getComments()
		}
	}, [props.commentShow == true])

	let dateAndTime = transList?.[0]?.createdAt.split("T")
	let date = dateAndTime?.[0]
	let time = dateAndTime?.[1]?.split(".")[0]

	const isButtonDisabled = () => {
		return !(transComment || statsComment || momentComment || foulComment || signalComment || quesComment);
	};

	return (
		<>
			{props.show || props.commentShow ?
				<div className="side-fillters-scroler call-fillters switcher-wrapper switcher-toggled">
					<div className="switcher-body">
						<div className="d-flex top-heading align-items-center">
							<h5 className="mb-0  text-uppercase">COMMENTS</h5>
							<button type="button" class="btn-close ms-auto close-switcher" aria-label="Close" onClick={props.closeButton}></button>
						</div>
						<hr />
						{props.tabSelected !== 'actions' ? <div className="">
							<form className="row g-3">
								<div className="col-12">
									<label for="inputAddress2" className="form-label" >ADD COMMENT</label>
									<textarea className="form-control" name="text" onChange={handleChange}
										value={props?.tabSelected == 'transcript' ? transComment : props?.tabSelected == 'stats' || props?.tabSelected == 'teamStats' ? statsComment : props?.tabSelected == 'callMoments' || props?.tabSelected == 'teamMoments' ? momentComment : props?.tabSelected == 'signals' ? signalComment : props.tabSelected == 'questions' ? quesComment : props.tabSelected == 'foulLanguage' ? foulComment : ''}
										id="inputAddress2" placeholder=" " rows="3" />
								</div>
								<div className="col-12">
									<Button className="btn bg-medium-blue px-5 text-light w-100" onClick={addCommentHandler} disabled={isButtonDisabled()}>ADD COMMENT</Button>
								</div>
							</form>
						</div> : ""}
						<hr />
						{props.tabSelected == 'actions' ? props?.comment?.map(item => {
							return (
								<div className="list-group-item list-group-item-action">
									<div className="w-100 justify-content-between">
										<h5 className="mb-0 font-16">
											<i class="bx bx-user-voice me-1" />
											{props?.speaker == 'seller' ? props?.sellerName : props?.customerName}
											<span className="badge rounded-pill bg-light-blue text-dark f-normal">
												{props?.speaker === 'seller' ? "seller" : "customer"}
											</span>
										</h5>
										{/* <small className="text-muted">{`${date} ${time}`}</small> */}
									</div>
									<p className="mb-1">{item}</p>
								</div>
							)
						})
							:
							transList ? transList.map((item) => {
								return (
									<div className="list-group-item list-group-item-action ">
										<div className="w-100 justify-content-between">
											<h5 className="mb-0 font-16"><i class="bx bx-user-voice me-1"></i>{`${item?.firstName} ${item?.lastName}`}</h5>
											<small className="text-muted">{item?.createdAt}</small>
										</div>
										<p className="mb-1">{item.name}</p>
									</div>
								)
							})
								:
								<div className="loader-container">
									<Loading variant="dark" />
								</div>
						}
					</div>
				</div> : ""}
		</>
	)
}