import { useState } from "react";
import * as React from 'react'
import "./signals.css";
import "./select.min.css";
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Col,Row } from "react-bootstrap";
import All from "../../Questions/All_Ques";
import { Tab, Tabs,Nav } from "react-bootstrap";
import All_Signals from "./All_Signals";
import Loading from "../../../Commons/Loading";
import EmailTranscript from "../Modals/EmailTranscript";

export default function Signals(props) {
  const [key, setKey] = useState("All");
  const [show, setShow] = useState(false)
  const [value, setValue] = useState()
  const [keyClicked, setKeyClicked] = useState("all")

  let emp = props.callSignals? Object.entries(props?.callSignals).map(item=>{
    return {"label":item[0],"value":item[0]}
  }):""

  const closeButton=()=>setShow(false)

  const handleClick=()=>{
    setShow(true)
  }
  const setEmailFalse = ()=>{
    setShow(false)
  }

  // const handleSignalChange = (val,option)=>{
  //   setSignal(option.label)
  // }

  const handleSelect = (keyClick)=>{
    setKeyClicked(keyClick)
  }
  
  return (
    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :
      <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primarysignals">
          <div className="d-flex">
            <div className="moment-filter-tab-box">
              <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked?keyClicked:"all"}>
                <Row>
                  <Col sm={10}>
                    <Nav variant="pills" className="flex" onSelect={handleSelect}>
                      <Nav.Item>
                        <Nav.Link eventKey="all">All</Nav.Link> 
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="seller">{props?.callSignals?.agentName}Seller</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="customer">{props?.callSignals?.customerName}Customer</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={2} className="moments-email-btn mb-3 text-sm-right pe-0">
                    <a className="btn compose-mail-btn" onClick={handleClick}>Email Signals</a>
                  </Col>
                  {/* <Col sm={12}className="px-0">
                    <div class="input-group signals-multiselect-box mb-3">
                      <span class="input-group-text">
                        <i class="bx bx-filter-alt"></i>
                      </span>
                      <Autocomplete
                        id="combo-box-demo"
                        options = {emp?emp.sort((a, b) => -b.label.localeCompare(a.label)):""}
                        sx={{ width: "96%" }}
                        autoHighlight
                        disablePortal
                        onChange={handleSignalChange}
                        value={signal}
                        renderInput={(params)=><TextField {...params} label={null} placeholder="Filter By Signal"/>}
                      >

                      </Autocomplete>
                    </div>
                  </Col> */}
                  <Col sm={12}  className="px-0">
                    <Tab.Content>
                    {keyClicked=='all'||keyClicked=='customer'||keyClicked=='seller'?
                      <Tab.Pane eventKey={keyClicked}>
                        <All_Signals type={props.type?props.type:''} callSignals={props.callSignals} seller={props?.callSignals?.agentName} customer={props?.callSignals?.customerName} keyClicked={keyClicked} callId={props?.callId} tabSelected={props?.tabSelected} tabRedirected={props?.tabRedirected} phone={props?.phone} agentExternalId={props?.agentExternalId} likeDislikeData={props?.likeDislikeData} postId={props?.postId} eventType={props?.eventType} agentId={props?.agentId} transAgentSpeakerId={props?.callSignals?.transcriptionAgentSpeakerId}/>
                      </Tab.Pane>
                    :""}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            {/* <div className="moments-email-btn">
              <a className="btn compose-mail-btn" onClick={handleClick}>Email Signals</a>
            </div> */}
          </div>
        </div>
        <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} type={props?.type} callSignals={props?.callSignals} tabSelected={props?.tabSelected} seller={props?.callSignals?.agentName} customer={props?.callSignals?.customerName} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId}/>
      </div>
    }
    </>
  );
}
