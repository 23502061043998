import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import Loading from '../../Commons/Loading'
import NodataFound from '../../Commons/NoDataFound'

function EmailTodo({ loader, todos }) {
    return (
        <>
            {loader ? (
                <div className="loader-container">
                    <Loading variant="dark" />
                </div>) :
                (todos.length > 0 ?
                    (<>
                        <div className="tab-pane" role="tabpanel">
                            <div className="d-flex align-items-center feedback-headder my-2">
                                <div className="feedback-heading">
                                    <h5>TO-DO</h5>
                                </div>
                            </div>
                        </div>
                        <Card style={{ width: '95%', marginLeft: '3px', marginTop: '10px' }}>
                            <Card.Body style={{ paddingTop: '1rem' }}>
                                <Card.Text>
                                    <ListGroup as="ol" numbered>
                                        {todos && todos?.map((item, index) => {
                                            const [heading, description] = item.split(" - ");
                                            return (
                                                <ListGroup.Item as="li" key={index}>
                                                    <strong>{heading}:</strong>
                                                    <div style={{ marginLeft: '1rem' }}>{description}</div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </Card.Text>
                            </Card.Body>
                        </Card></>)
                    : <NodataFound />)
            }
        </>
    )
}

export default EmailTodo