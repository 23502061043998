import React from "react";
import Loading from "../../../Commons/Loading";
import time from "../../../../assets/images/callList-images/timer.svg";
import "../Stats/Stats.css";
import { useHistory } from "react-router-dom";

const Calls = (props) => {
  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig
    ?.data?.conversationListing;
  const history = useHistory();

  const conversion = (duration) => {
    let hh = duration?.split(":")[0];
    let mm = duration?.split(":")[1];
    let ss = duration?.split(":")[2];
    let text = "";
    if (hh > 0) {
      text = `${hh} Hrs. ${mm} Min. ${ss} Sec.`;
    } else if (mm > 0) {
      text = text + `${mm} Min. ${ss} Sec.`;
    } else if (ss > 0) {
      text = text + `${ss} Sec.`;
    }
    return text;
  };
  const clickHandler = (
    id,
    rec,
    duration,
    date,
    empId,
    seller,
    customer,
    coeId,
    coe,
    phone,
    externalId,
    name,
    userId,
    filename
  ) => {
    localStorage.setItem("URL", rec);
    history.push(`voice_detail?callId=${id}&name=${name}`, {
      callId: id,
      url: rec,
      duration: duration,
      date: date,
      id: empId,
      seller: seller,
      customer: customer,
      coeId: coeId,
      coe: coe,
      phone: phone,
      agentExternalId: externalId,
      userId: userId,
      callName: name,
      fileName: filename,
    });
    props.setKey("stats")
  };

  return (
    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <div className="tab-content py-3">
          <div className="tab-pane fade active show" role="tabpanel">
            <div className="d-flex justify-content-between ">
              <div className="stats-heading">
                <h5>
                  Calls
                  <a data-bs-toggle="modal">
                    <i className="bx bx-git-compare ms-1 align-bottom text-dark"></i>
                  </a>
                </h5>
              </div>
            </div>
            <div
              className="moment-list-group-scroll stats-list-group-scroll"
              id="statspage"
            >
              {props.callDetail?.length > 0 && (
                <ul className="list-group">
                  {props?.callDetail?.map((item, index) => (
                    <React.Fragment key={index}>
                      <li
                        className="list-group-item"
                        onClick={()=>{clickHandler(item?.callId,item?.audioRecordingUrl,item?.talkDuration,item?.date,item?.employeeId,item?.sellerName,item?.customerName,item?.coeIds,item?.coeName,item?.phone,item?.userExternalId,"SINGLE_CALL")}}
                      >
                        <div className="seller">
                          <div className="name">
                            <b>
                              <span className="text" style={{ color: "black" }}>
                                {item?.customerName}
                              </span>
                            </b>
                            <div
                              className="rank"
                              style={{ cursor: "pointer" }}
                            >{`#${item?.callId}`}</div>
                          </div>

                          <div className="time">
                            <span className="icon">
                              <img src={time} alt="" />
                            </span>
                            {conversion(item?.talkDuration)}
                          </div>
                          <div className="score">
                            {userDetail.includes("CALL SCORE") ? (
                              <div className="scoreCommonStyle callScore">
                                <span className="text">
                                  CALL
                                  <br />
                                  SCORE
                                </span>
                                <span
                                  className="badge"
                                  style={{
                                    background:
                                      item.callColour == "Amber"
                                        ? "#FFBF00"
                                        : item.callColour == null
                                        ? "Grey"
                                        : item.callColour,
                                  }}
                                >
                                  {item.callScore}%
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {userDetail.includes("PITCH SCORE") ? (
                              <div className="scoreCommonStyle pitchScore">
                                <span className="text">
                                  PITCH
                                  <br />
                                  SCORE
                                </span>
                                <span
                                  className="badge"
                                  style={{
                                    background:
                                      item.pitchColour == "Amber"
                                        ? "#FFBF00"
                                        : item.pitchColour == null
                                        ? "Grey"
                                        : item.pitchColour,
                                  }}
                                >
                                  {item.pitchScore}%
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Calls;
