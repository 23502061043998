import React, { useState, useEffect, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Modal, Form, Accordion} from "react-bootstrap";
import Loading from "../../Commons/Loading";
import NodataFound from "../../Commons/NoActivityDataFound";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import "react-virtualized/styles.css";
import insightService from "../../Services/insight.service";
import { setResetState } from "../../../redux-container/insights/action";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './ActivityLogGraph.css';
import { Autocomplete, Checkbox, FormControl,ListItemText, MenuItem, TextField } from "@mui/material";

const ActiveLogGraph = ({ title, statName, xFieldLabel, yFieldLabel, handling, measurement, recommended, tabIndex, defaultFilteredData, apply, setResetFalse, setApplyFalse, onCallSumUpdate, setTotalCallLoading }) => {
  const [chartType, setChartType] = useState("bar");
  const [showModal, setShowModal] = useState(false);
  const [dataLoader, setDataLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [limitedData, setLimitedData] = useState([]);
  const [modalChartType, setModalChartType] = useState(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch()
  const filterData = useSelector(state=>state?.insights?.filterData)
  let momentIds = useSelector(state=>state?.insights?.filterData?.momentIds)
  let momentIdList = useSelector(state=>state?.insights?.momentList)
  let momentGroupIds = useSelector(state=>state?.insights?.filterData?.momentGroupIds)
  let momentGroupIdList = useSelector(state=>state?.insights?.momentGroupList)
  let word = useSelector(state => state?.insights?.filterData?.word)
  const [isSelectAll,setIsSelectAll]=useState(false);

  let xFieldLabelData = tabIndex === 1 ? filterData && filterData.momentGroupIds && filterData.momentGroupIds.length > 0 
  ? `${momentGroupIdList.find(item => (item.value == filterData?.momentGroupIds[0]))?.label}(%)`
  : `${momentGroupIdList[0]?.label}(%)` : 
  tabIndex === 2 ? filterData && filterData?.momentIds && filterData?.momentIds.length>0 ? 
  `${momentIdList.find(item=>(item.value==filterData?.momentIds[0]))?.label}(%)` :
 `${momentIdList[0]?.label}(%)` :
   tabIndex===3 ? filterData && filterData?.word ? `${filterData.word}(%)` : "" :"";

   const mockData = [
    {
      agentAlltimeAvg: [],
      dayAgentAvg: [],
      agentDayList: [],
      totalClientCallsSum: [],
    },
  ];

  const getActivityData = async (activityStat) => {
    setIsLoading(true);
    setTotalCallLoading(true);
    let userDetails = JSON.parse(localStorage.getItem("USER_DETAIL"));
    let localData = JSON.parse(localStorage.getItem("AGENT"));
    let values = {
      coeIds: localData?.selectedCoeRange,
      userId: localData?.userIds ? localData?.userIds : defaultFilteredData?.id,
      ...(tabIndex === 1 || tabIndex === 2 ? {momentIds: tabIndex === 1 ? (momentGroupIds && momentGroupIds.length>0 ? momentGroupIds : momentGroupIdList && [momentGroupIdList[0].value]) : tabIndex === 2 ? momentIds && momentIds.length>0 ? momentIds : momentIdList && [momentIdList[0].value] : null} : {}) ,
      clientExternalId: userDetails?.userExternalId,
      activitiesStats: activityStat?.activitiesStats,
      ...(tabIndex === 3 ? { word: word } : {}),
      fromD: localData?.fromD,
      toD: localData?.toD,
      callType: "SALES",
    };
    try{
      const response = await insightService.getActivityStats(values);
      if (response?.data?.status === 0 && response?.data?.data?.length > 0){
        setData(response?.data?.data);
        setResetFalse()
        setApplyFalse()
        dispatch(setResetState(false))
      }else {
        console.warn("Empty data from API, falling back to mock data.");
        setData(mockData);
      }
    }catch (error){
      console.error("Error fetching activity data:", error);
      setData(mockData); // Fallback to mock data on error
    }finally {
      setIsLoading(false);
      setTotalCallLoading(false);
    }
    // insightService.getActivityStats(values)
    // .then((response) => {
    //   if (response?.data?.status === 0) {
    //     setData(response?.data?.data);
    //   setIsLoading(false);
    //   setTotalCallLoading(false)
    //   setResetFalse()
    //   setApplyFalse()
    //   dispatch(setResetState(false))
    //   } else {
    //   setIsLoading(false);
    //   setTotalCallLoading(false)
    //   }
    // })
    // .catch((err) => {
    //   console.error(err);
    //   setIsLoading(false);
    //   setTotalCallLoading(false)
    // });
  };

  useEffect(() => {
    if (defaultFilteredData && defaultFilteredData?.id.length) {
      getActivityData(statName);
    }
  }, [statName, defaultFilteredData]);

  useEffect(() => {
    if (data && data.length) {
      try {
        setLimitedData(
          data.map((d) => ({
            ...d,
            agentAlltimeAvg: d.agentAlltimeAvg.slice(0, 10),
            dayAgentAvg: d.dayAgentAvg.slice(0, 10),
            agentDayList: d.agentDayList.slice(0, 10).map((agent) => ({
            ...agent,
            statList: agent.statList.slice(0, 10),
            })),
          }))
        );
        setIsLoading(false);
        setTotalCallLoading(false)
      } catch (err) {
        console.error("Error processing data:", err);
        setIsLoading(false);
        setTotalCallLoading(false)
      }
    }
  }, [data]);

  const names = (limitedData[0]?.agentAlltimeAvg || []).map(
    (item) => item?.agentName
  );

  const values = (limitedData[0]?.agentAlltimeAvg || []).map(
    (item) => item?.statVal
  );
  const maxWordsSec = Math.max(...values);

  const calls = (limitedData[0]?.agentAlltimeAvg|| []).map(
    (item) => item?.totalCalls
  );

  const callSum = limitedData[0]?.totalClientCallsSum?.[0]?.totalClientCallsSum;

  useEffect(() => {
      onCallSumUpdate(callSum); 
  }, [callSum]);

  const avgLineData = (data[0]?.dayAgentAvg || []).map((item) => ({
    x: item.date,
    y: item.statVal,
  }));

  const agentLinesData = (data[0]?.agentDayList || []).map((agent) => ({
    name: agent.agentName,
    data: (agent.statList || []).map((stat) => ({
      x: new Date(stat.date),
      y: stat.statVal,
    })),
  }));

    // Function to convert Apex-style data structure to Highcharts-friendly data
    const convertToHighchartsSeries = (avgLineData, agentLinesData) => {
      const avgSeries = {
        name: "Average",
        data: Array.isArray(avgLineData)
          ? avgLineData.map((item) => item?.y ?? 0.0)
          : [],
        dashStyle: "Dash",
      };
    
      const agentSeries = Array.isArray(agentLinesData)
        ? agentLinesData.slice(0, 30).map((agent) => ({
            name: agent?.name,
            data: Array.isArray(agent?.data)
              ? agent.data.map((stat) => stat?.y ?? 0)
              : [],
          }))
        : [];
    
      return [avgSeries, ...agentSeries];
    };

  const barSeries = [
    {
      name: handling,
      data: values,
    },
  ];

  const lineSeries = [
  {
    name: "Average",
    data: Array.isArray(avgLineData)
      ? avgLineData.map((item) => item?.y ?? 0.0)
      : [],
  },
  ...(Array.isArray(agentLinesData)
    ? agentLinesData?.slice(0, 30).map((agent) => ({
      name: agent?.name,
      data: Array.isArray(agent?.data)
      ? agent.data.map((stat) => stat?.y ?? 0)
      : [],
     }))
   : []),
  ];

  useEffect(()=>{
    if (chartType === "bar") {
      if (barSeries[0]?.data?.length > 0) {
        setTimeout(() => setDataLoader(false), 300);
      } else if (barSeries[0]?.data?.length === 0) {
        setDataLoader(false);
      }
    }
    if(chartType==='line'){
      if(lineSeries?.length > 0){
        setTimeout(() => setDataLoader(false), 300);
      }
      else if(lineSeries?.length === 0){
        setDataLoader(false)
      }
    }
  },[chartType, barSeries])

  // const getMaxValue = (data) => {
  //   // Find the maximum value from avgLineData
  //   const maxDataValue = Math.max(...data.map(entry => entry.y));
    
  //   // Round up to the nearest multiple of 10
  //   return Math.ceil(maxDataValue / 10) * 10;
  // }
  
  const viewIcon= 'bx bx-show';
  const barIcon= 'bx bx-bar-chart';
  const lineIcon= 'bx bx-line-chart';
  const barOptions = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: false,
        customIcons: [
          {
            icon: `<i class="${viewIcon}"></i>`,
            index: 2,
            title: "View All",
            class: "custom-view-icon",
            click: () => {
              setShowModal(true);
              setModalChartType(chartType);
            },
          },
          {
            icon: `<i class="${barIcon}"></i>`,
            index: 0,
            title: "Bar Chart",
            class: `custom-bar-icon ${chartType=='bar'?'select':''}`,
            click: () => {
              setDataLoader(true)
              setChartType("bar")
            }
          },
          {
            icon: `<i class="${lineIcon}"></i>`,
            index: 1,
            title: "Line Chart",
            class: `custom-line-icon ${chartType=='line'?'select':''}`,
            click: () => {
              setDataLoader(true)
              setChartType("line")
            }
          },
        ],
        },
      },
    },
    plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "25%",
    },
    }, 
    annotations: {
      xaxis: [
        {
          x: recommended.length > 0 ? recommended[0] : 0,
          x2: recommended.length > 0 ? recommended[1] : 0,
          fillColor: '#6ef09a',
          borderColor: '#6ef09a',
        },
      ],
    },
    xaxis: {
      min: handling === "Agent VS High ROS" ? 4.5 : undefined, 
      max: handling === "Agent VS High ROS" ? maxWordsSec + 1 :  (xFieldLabel === "Satisfaction(%)" || xFieldLabel === "Engagement(%)" || xFieldLabel ==="Agent Properly Transfer(%)" || tabIndex === 3) ? 100 : undefined,
      categories: names,
      title: {
        text: xFieldLabelData ? xFieldLabelData : tabIndex !== 3 ? xFieldLabel : '',
        offsetY: 10,
        align: "left",
      },
      labels: {
        rotate: -40,
        rotateAlways: true,
        style: {
        fontSize: "10px",
        fontWeight: "400",
        },
      },
    },
    yaxis: {
      title: {
        text: yFieldLabel,
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return `${val + ' ' + measurement}`; 
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const callValue = calls[dataPointIndex]
        return `<div class="tooltip-custom">${
          xFieldLabelData ? `${xFieldLabelData} - ${value}` : `${xFieldLabel === "%" ? "Call Score" : xFieldLabel} - ${value}`
        } ${measurement || ''} <br>
         ${xFieldLabel !== 'Calls(count)' ? `No. of Calls - ${callValue} calls` : ''} 
        </div>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderDash: [5],
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      top: 0,
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    colors: ["#0d6efd"],
  };

  const lineOptions = {
    chart: {
      type: "line",
      height: 350,
      events: {
        load: function () {
          const chart = this;
          const seriesCount = chart.series.length;
          const initiallyVisibleCount = 5;
  
          // Hide series beyond initially visible count
          for (let i = initiallyVisibleCount; i < seriesCount; i++) {
            chart.series[i].hide();
          }

          // Get x-axis label's bounding box
          // const xAxisLabel = chart.xAxis[0].labelGroup.getBBox();
          const chartDimension = chart.legend.chart;
          const labelDimension = chart.legend.group.getBBox();
          const buttonX = chartDimension.chartWidth - (chartDimension.chartWidth-labelDimension.width)*0.9;
          const buttonY = chartDimension.chartHeight - (labelDimension.height/1.2);
  
          // Add custom "Select All" button using Highcharts renderer
          const button = chart.renderer.button('Select All', buttonX, buttonY, function () {
            let allVisible = true;
  
            // Check if any series is hidden
            chart.series.forEach(function (series) {
              if (!series.visible) {
                allVisible = false;
              }
            });
  
            // Toggle all series visibility
            chart.series.forEach(function (series) {
              if (allVisible) {
                series.hide();
              } else {
                series.show();
              }
            });
  
            // Update button text based on current visibility state
            this.attr({
              text: allVisible ? 'Select All' : 'Deselect All'
            });
  
          }, {
            zIndex: 3,
            height: 6,    // Height of the button
            padding: 6,
            r: 5,          // Border radius
          })
          .attr({
            fill: '#fff',  // Button background color
            stroke: '#007bff',  // Button border color
            'stroke-width': 1,
            style: {
              color: '#007bff', // Button text color
              cursor: 'pointer',
              fontSize: '12px',
            }
          })
          .css({
            cursor: 'pointer',
          })
          .add();
          // Function to update the button label based on series visibility
          const updateButtonLabel = () => {
            const allVisible = chart.series.every(series => series.visible);
            button.attr({
              text: allVisible ? "Deselect All" : "Select All"
            });
          };
  
          // Listen for when user clicks on legend items (to toggle individual series)
          chart.series.forEach(function (series) {
            series.update({
              events: {
                legendItemClick: function () {
                  // Update button label when any series is manually selected/deselected
                  setTimeout(updateButtonLabel, 0); // Slight delay to ensure visibility state is updated
  
                  return true; 
                }
              }
            });
          });
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: [...new Set(avgLineData?.map((entry) => entry.x))],
      // title: {
      //   text: xFieldLabelData ? xFieldLabelData : tabIndex !== 3 && xFieldLabel,
      // },
      title: {
        // text: yFieldLabel,
        text:'DATES'
      },
      labels: {
        rotation: -40,
        formatter: function () {
          return this.value;
        },
      },
      tickAmount: 8,
      tickInterval: Math.ceil((avgLineData?.length) / 8),
      alignTicks: false,
      tickPosition: 'on', 
      tickmarkPlacement: 'on', 
      min: 0,            
      max: avgLineData?.length - 1, 
    },
    yAxis: {
      // title: {
      //   text: yFieldLabel,
      // },
      title: {
        text: xFieldLabelData ? xFieldLabelData : tabIndex !== 3 && xFieldLabel,
      },
      labels: {
        formatter: function () {
          return `${this.value}`;
        },
      },
      tickAmount: 8,
      alignTicks: false,
      tickPosition: 'on', 
      tickmarkPlacement: 'on', 
      min: 0,       
      max: avgLineData?.length - 1,
      // max:239,
      // max:getMaxValue(avgLineData),
    },
    series: convertToHighchartsSeries(avgLineData, agentLinesData).map((series) => ({
      ...series,
      dashStyle: "Solid", 
      marker: {
        enabled: false,
      },
    })),
    legend: {
      align: "left",
      x: 0,
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 5,
      itemStyle: {
        color: "#333",
        fontSize: '12px',
        fontWeight: '400',
      },
      useHTML: true,
      labelFormatter: function () {
        return `<span style="display: inline-block; border-radius: 50%; width: 10px; height: 10px; background-color: ${this.color}; margin-right: 5px;"></span> ${this.name}`;
      },
      layout: 'horizontal',
      maxHeight: 70,
      navigation: {
        enabled: true,
        style: {
        },
      },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
        showInLegend: true,
        enableMouseTracking: true,
        connectNulls: true, 
      },
    },
    credits: {
      enabled: false, 
    },
  };
 
  useEffect(() => {
      const totalAgents = data[0]?.agentDayList.slice(0, 30).map(agent => agent.agentName) || [];
      if (selectedAgent?.length === totalAgents?.length) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
  }, [selectedAgent, data]);

  const filteredData = selectedAgent.includes("Select All") || selectedAgent.length === 0
  ? data[0]?.agentDayList
  : data[0]?.agentDayList.filter((agent) => selectedAgent.includes(agent.agentName));

  const [searchQuery, setSearchQuery] = useState("");

  const filteredAgents = searchQuery
      ? data[0]?.agentDayList.filter((agent) =>
          agent.agentName.toLowerCase().includes(searchQuery.toLowerCase())
        )
  : data[0]?.agentDayList;


  const [modalData, setModalData] = useState([]);

  const onModalEnter = () => {
    setModalData(data[0]?.agentAlltimeAvg || []);
    setActiveKey(null);
  };

  const modalBarOptions = {
    chart: {
      height: modalData.length * 30 + 100, // 30 pixels per bar plus some padding
      type: "bar",
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "25%", // Adjust if needed
      },
    },
    annotations: {
      xaxis: [
        {
          x: recommended.length > 0 ? recommended[0] : 0,
          x2: recommended.length > 0 ? recommended[1] : 0,
          fillColor: '#6ef09a',
          borderColor: '#6ef09a',
        },
      ],
    },
    xaxis: {
      min: handling === "Agent VS High ROS" ? 4.5 : undefined, 
      max: handling === "Agent VS High ROS" ? maxWordsSec + 1 :  (xFieldLabel === "Satisfaction(%)" || xFieldLabel === "Engagement(%)" || xFieldLabel ==="Agent Properly Transfer(%)" || tabIndex === 3) ? 100 : undefined,
      categories: modalData.map((item) => item?.agentName),
      title: {
        text: xFieldLabelData ? xFieldLabelData : tabIndex !== 3 ? xFieldLabel : '',
        offsetY: 10,
        align: "left",
      },
      labels: {
        rotate: -40,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          fontWeight: "400",
        },
      },
    },
    yaxis: {
      title: {
        text: yFieldLabel,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderDash: [5],
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      top: 0,
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    colors: ["#0d6efd"],
  };

  //<...............Modal Data pagination..............>

  const renderBarChartModal = () => (
    <div className="chart-container">
      <div id="chart" style={{ position: 'relative' }}>
        <ReactApexChart
        options={modalBarOptions}
        series={[{ name: title, data: modalData.map((agent) => agent?.statVal) }]}
        type="bar"
        height={modalBarOptions.chart.height}  // Dynamic height based on data count
       />
      </div>

      {/* Custom Recommended Label */}
      {recommended.length > 0 && (
        <div className="recommended-label" style={{top: '-10px'}}>
          Recommended: {recommended[0]} - {recommended[1]}
        </div>
      )}
    </div>
  );

 const [activeKey, setActiveKey] = useState(null);

 const handleAccordionClick = (eventKey) => {
   setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey)); // Toggle the active key
 };

 const renderTable = useCallback(() => {
  return (
    <Accordion activeKey={activeKey}>
      {filteredData.map((agent, agentIndex) => {
        const rowCount = agent.statList.length;

        const getRowData = ({ index }) => {
          const stat = agent.statList[index];
          return {
            agentName: agent.agentName,
            date: stat.date,
            statVal: stat.statVal,
            totalCalls: stat.totalCalls,
          };
        };

        const Row = ({ index, style }) => {
          const row = getRowData({ index });
          return (
            <div
              style={{
                ...style,
                display: "flex",
                padding: "5px",
                justifyContent: "space-around",
                borderBottom: "1px solid #ccc",
              }}
              className="table-row"
            >
               <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{row.agentName}</div>
              <div className="table-cell">{row.date}</div>
                 {/* <div className="table-cell" style={{ flex: "1", padding: "5px", marginLeft: '50px' }}>
                      {row.statVal}
                    </div> */}
              <div className="table-cell">{row.statVal}</div>
              <div className="table-cell">{row.totalCalls}</div>
            </div>
          );
        };

        return (
            <div key={agentIndex} style={{ display: "flex", alignItems: "center", marginBottom: "8px" }} className="accordion-header-index">
            <Accordion.Item eventKey={agentIndex.toString()} className="mb-2 accordion-item" style={{borderTop:"0px",borderLeft:"0px",borderRight:"0px",borderBottom:"1px solid lightgray"}}>
            <Accordion.Header className="accordion-header"
              onClick={() => handleAccordionClick(agentIndex.toString())} >
            <div style={{ marginRight: "10px" }}>{agentIndex + 1}.</div>
              <span>{agent.agentName} ({agent.employeeId})</span>
            </Accordion.Header>
            <Accordion.Body >
              <div
                className="table-header">
                <div className="table-cell">S.No</div>
                <div className="table-cell">Agent Name</div>
                <div className="table-cell">Date</div>
                {/* <div className="table-cell" style={{ flex: "1", padding: "5px" }}>
                        Stat Value
                    </div> */}
                <div className="table-cell">{xFieldLabelData? xFieldLabelData : xFieldLabel}</div>
                <div className="table-cell">Total calls</div>
              </div>
              <AutoSizer disableHeight>
                {({ width }) => (
                    <List
                    height={rowCount * 35} // Adjust height dynamically based on rows
                    itemCount={rowCount} 
                    itemSize={35}
                    width={width}
                    className="table-cell" >
                        {({index,style})=><Row index={index} style={style} className="list-row"/>}
                  </List> 
                 )} 
              </AutoSizer>
            </Accordion.Body>
           </Accordion.Item>
          </div>
        );
      })}
    </Accordion>
  );
 } , [filteredData,xFieldLabel,xFieldLabelData,activeKey]);

 const renderBarChart = () => {
  if (isLoading) {
   return (
    <div
       className="loader-container dashboard-loder sellers-loader"
       style={{
         minHeight: "365px",
       }}
    >
     <Loading variant="dark" />
    </div>
   );
  }
  // if (chartType === "bar" && barSeries[0].data.length === 0) {
  //   return <div style={{minHeight:"350px"}}>
  //    <NodataFound/>;
  //   </div>
  // }
  return (
   <>
    {/* {barSeries && ( */}
    <div className="chart-container">
      <div id="chart" style={{ position: 'relative' }}>
        <ReactApexChart
        key={`chart-${chartType}`}
        options={barOptions}
        series={barSeries.length > 0 ? barSeries:[{ name:"Mock Data", data:[0]}]}
        type={chartType}
        height="350"
       />
      </div>

      {/* Custom Recommended Label */}
      {recommended.length > 0 && (
        <div className="recommended-label">
          Recommended: {recommended[0]} - {recommended[1]}
        </div>
      )}
    </div>
    {/* // )} */}
   </>
  );
 };

  const renderLineChart = () => {
    if (isLoading) {
      return (
        <div className="loader-container dashboard-loder sellers-loader"
          style={{
            minHeight: "365px",
          }}
        >
          <Loading variant="dark" />
        </div>
      );
    }
    // if (chartType === "line" && lineSeries?.length === 0) {
    //   return <NodataFound
    //     style={{
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       maxHeight: "350",
    //     }}
    //   />;
    // }

    return (
    <>
      <div style={{ position: 'relative' }}>
          <div
            className="chart-icons"
            style={{
              position: 'absolute',
              top: '-57px',
              right: '21px',
              transform: 'scale(1.5)'
            }}       
          >
            <span
              className={`custom-bar-icon ${chartType === 'bar' ? 'select' : ''}`}
              title="Bar Chart"
              onClick={() => {
                setChartType('bar');
              }}
              style={{marginRight:'5px'}} 
            >
              <i class='bx bx-bar-chart'></i>
            </span>
            <span
              className={`custom-line-icon ${chartType === 'line' ? 'select' : ''}`}
              title="Line Chart"
              onClick={() => {
                setChartType('line');
              }}
              style={{marginRight:'5px'}} 
            >
              <i class='bx bx-line-chart'></i>
            </span>
            <span
              className="custom-view-icon"
              title="View All"
              onClick={() => {
                setShowModal(true);
                setModalChartType(chartType);
              }}
            >
              <i class='bx bx-show'></i>
            </span>
          </div>
              
          {/* {lineSeries && (
            <HighchartsReact highcharts={Highcharts} options={lineOptions}/>
          )} */}
           <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...lineOptions,
        series: lineSeries.length > 0 ? lineSeries : [{ name: "Mock Data", data: [0] }],
      }}
    />
        </div>
    </>
    );
  };
  return (
    <>
    <div className="p-4">
      {chartType === "bar" ? renderBarChart() : renderLineChart()}
    </div>
    {showModal && (
      <Modal
      show={showModal}
      onEnter={onModalEnter}
      onHide={() => {setShowModal(false); setSelectedAgent([]);setActiveKey(null);setIsSelectAll(false);}}
      size="xl"
      >
        <div>
          <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>{handling}</Modal.Title>
              {modalChartType==='line' &&(
                <div className="modal-form-control-container">
                {data[0]?.agentDayList && data[0].agentDayList.length > 0 && (
                  <FormControl sx={{ width: 300}}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={[
                        { label: "Select All", value: "Select All" }, // "Select All" option
                        ...filteredAgents.slice(0, 30) // Limit results to 30 items
                          .map((agent) => ({
                            label: `${agent.agentName} (${agent.employeeId})`,
                            value: agent.agentName,
                          })), // Map agents to Autocomplete options
                      ]}
                      value={selectedAgent.map((agentName) =>{
                        if(agentName==="Select All"){
                          return { label: "Select All", value: "Select All" };
                        }
                        const agent = data[0].agentDayList.find((a) => a.agentName === agentName);
                        return agent
                          ? {
                              label: `${agent.agentName} (${agent.employeeId})`,
                              value: agent.agentName,
                            }
                          : { label: agentName, value: agentName }; // Fallback for unmatched data
                      })}        
                      disableCloseOnSelect
                      onChange={(event, newValue) => {
                        const selectedValues= newValue.map((item) => item.value);

                        if (selectedValues.includes("Select All")) {
                          if (!isSelectAll) {
                            setSelectedAgent(
                              data[0].agentDayList.slice(0, 30).map((agent) => agent.agentName)
                            );
                            setIsSelectAll(true);
                          } else {
                            setSelectedAgent([]);
                            setIsSelectAll(false);
                            setActiveKey(null);
                          }
                        } else {
                          setSelectedAgent(selectedValues);
                          const allAgents = data[0].agentDayList
                            .slice(0, 30)
                            .map((agent) => agent.agentName);
                          setIsSelectAll(selectedValues.length === allAgents.length);
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <MenuItem
                          {...props}
                          key={`menuItem-${option.value}`}
                          value={option.value}
                        >
                          <Checkbox
                            checked={
                              option.value === "Select All"
                                ?  isSelectAll 
                                : selectedAgent.includes(option.value)
                            }
                            sx={{
                              color: "#aee3ff", 
                              "&.Mui-checked": {
                                color: "#aee3ff",
                              },
                            }}
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select All Agents"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          }}
                        />
                      )}
                      renderTags={(selected) => {
                        const selectedLabels = selected
                          ?.map((val) => val.label)
                          .join(", ");
                        return (
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {selectedLabels}
                          </span>
                        );
                      }}
                    />
                  </FormControl>
                )}
              </div>
              )}
          </Modal.Header>
        </div>
      <Modal.Body>
            {modalChartType === "bar" ?
              renderBarChartModal()
              : (
        renderTable()
        )}
      </Modal.Body>
      </Modal>
    )}
    </>
  );
};

export default ActiveLogGraph;
