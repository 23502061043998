import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import emailService from "../../../Services/mails.service";
import callsService from "../../../Services/calls.service";
import Email_Transcript from "../../Transcript/Email_Transcript";

export default function EmailInfo(props) {
  const [key, setKey] = useState("stats");
  const [loader, setLoader] = useState(false);
  const [callTranscript, setCallTranscript] = useState([]);
  const [likeDislikeData, setLikeDislikeData] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get("emailId");
  const typeOfEmail = urlParams.get("name");

  useEffect(() => {
    setLoader(true);
    emailService
      .getEmailDetailTranscript(props?.uniqueId, typeOfEmail)
      .then((response) => {
        if (response.status == 200) {
          setCallTranscript(response?.data?.data);
          setLoader(false);
          callsService.getLikeDislike(params, "TRANSCRIPT").then((res) => {
            if (res) {
              setLikeDislikeData(res?.data?.data);
            }
          });
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }, [props?.uniqueId]);

  return (
    <Modal show={props.show} size="lg">
      <Modal.Header>
        <h5>Email Trail</h5>
        <button className="btn-close" onClick={props.closeButton}></button>
      </Modal.Header>
      <Modal.Body>
        {callTranscript && (
          <Email_Transcript
            type={'email'}
            callTranscript={callTranscript}
            tabSelected={key}
            loader={loader}
            likeDislikeData={likeDislikeData}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
