import React, { useEffect } from "react";
import { useState } from 'react';
import RatingGroup from "./RatingGroup";
import "../ManageMoments/manageMoments.css";
import "./Rating.css";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ratingService from "../../Services/rating.service";
import ReactTooltip from 'react-tooltip';
import WantToDeleteModal from '../../Commons/Modal/WantToDeleteModal'


function RatingsGroup(props) {
    const [isDelete, setIsDelete] = useState(false)
    const [formError, setFormErrors] = useState({});
    const [message, setMessage] = useState('')
    const errors = {};
    
      //var paras = props.ratingData?{id: para[0].id, code: para[0].code, name: para[0].name, type: para[0].type, dataType: para[0].dataType, category: para[0].category, description: para[0].description }:{id: '', code: '', name: '', type: '', dataType: '', category: '', description: ''};
      
    
    /* var EntityType = props.entityType;
    EntityType= EntityType.toUpperCase(); */
    const [inputs, setInputs] = useState({
        color : props.ratingData?props.ratingData.color:'',
        parameterId : props.ratingData?props.ratingData.parameterId:'' ,
        end : props.ratingData?props.ratingData.end:'',
        entityName : props.ratingData?props.ratingData.entityName: '' ,
        entityType : props.entityType,
        start : props.ratingData?props.ratingData.start:'',
        ratingId: props.ratingData?props.ratingData.id:''
        
    });
    var updateInput = {ratingId: inputs.ratingId, color: inputs.color, entityName: inputs.entityName};
    var paraid = props.setRatingDetails?'':props.ratingData.paramenterId;
    var para  = props.parameters.filter(function (el) {
        return el.id == paraid;
      });
      function toObject(arr) {
        var rv = {};
        for (var i = 0; i < arr.length; ++i)
          if (arr[i] !== undefined) rv[i] = arr[i];
        return rv;
      }
      //para = toObject(para)
      para = para[0]

    const[categoryVal, setCategoryVal] = useState(para)
    const [value, setValue] = useState([inputs.start, inputs.end]);
    const [colors, setColors] = useState(inputs.color);
    const handelcatogery = (e, option) =>{
        formError.parameterId= '';
        setCategoryVal(option);
        setInputs(inputs => ({ ...inputs, parameterId: option.id }));
    }
    const handelColor = (e, option) =>{
        formError.color= '';
        setColors(option);
        setInputs(inputs => ({ ...inputs, color: option }));
    }
    const handleRange = (e, newValue) =>{
        formError.start= '';
        setValue(newValue);
        setInputs(inputs => ({ ...inputs, start: newValue[0] }));
        setInputs(inputs => ({ ...inputs, end: newValue[1] }));
    }
    function handleChange(e) {
        formError.entityName= '';
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, entityName: value }));
    }
    const deleteRating = ()=>{
        setIsDelete(true)
        /* ratingService.deleteRating(props.ratingData.id).then(res => {
            //props.setLoader(true);
            
        }) */
    }
    const deleteRatings = ()=>{
        setIsDelete(false)
        props.deleteRating(props.ratingData.id);
    }

    const updateRating = () =>{
        //validate(inputs);
        //setMessage('')
        setFormErrors(errors)
        if (Object.keys(errors).length == 0) {
            ratingService.updateRating(updateInput).then(res =>{
                console.log('updated', res);
            })
        }
    }

    const validate = (values) => {

        if (!values.color) {
            errors.color = "Please select color."
        }
        if (!values.parameterId) {
            errors.parameterId = "Please select category."
        }
        if (!values.entityName) {
            errors.entityName = "Please enter name."
        }
        if (!values.entityType) {
            errors.entityType = "Please enter type."
        }
        if (values.start==='') {
            errors.start = "Please enter range."
        }
        return errors;
    }

    const removeRating = () =>{
        props.setAddRatings(false)
    }
    const createRating = () =>{
        validate(inputs);
        setMessage('')
        setFormErrors(errors)
        if (Object.keys(errors).length == 0) {
            ratingService.createRating(inputs).then(res =>{
                
                if(res.data.status === 200)
                {
                    ratingService.getRatingsData(inputs.entityType).then(res => {
                        props.setLoader(true);
                        props.setRatingDetails(res);
                        props.setLoader(false);
                    })
                }
            })
        }
    }

    const closeButton = ()=>{
        setIsDelete(false)
    }

    return(
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-around">
                    <div className="w-20">
                        <label for="Applies-To">Category</label>
                        <div className="small-dropdown rating-dropdown">
                            <Autocomplete
                                disablePortal
                                disableClearable
                                disabled={props.ratingData?'disabled':''}
                                id="combo-box-demo"
                                options={props.parameters}
                                getOptionLabel={(option) => option.name}
                                value={categoryVal}
                                onChange={handelcatogery}
                                renderInput={(params) => <TextField name="role" {...params} className="commonFont2" placeholder='select Category'  value=''  label=''/>}
                            />

                        </div>
                        <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red",height:'18px' }}><span>{formError.parameterId}</span></p>
                    </div>
                    <div className="w-30">
                        <label>Rating ({inputs.start?inputs.start:'0'} - {inputs.end?inputs.end:'0'}) %</label>
                        <div className="w-100 ps-2 pe-3">
                            <Box >
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    value={value}
                                    onChange={handleRange}
                                    disabled={props.ratingData?'disabled':''}
                                    valueLabelDisplay="100"
                                    getAriaValueText={() => value}
                                    marks = {[{value: 0, label: '0 %'}, {value: 100, label: '100 %'}]}
                                    name='range'
                                />
                            </Box>
                        </div>
                        <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red",height:'18px' }}><span>{formError.start}</span></p>
                    </div>
                    <div className="w-18 mb-2 moment-group-input">
                        <label for="Applies-To">Name</label>
                        <input className="input-field input-field-bt-border w-100 font-16" type="text" id="moment-group-name" placeholder="Rating Name" value={inputs.entityName} onChange={handleChange}/>
                        <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red",height:'18px' }}><span>{formError.entityName}</span></p>
                    </div>
                    <div className="w-18">
                        <label>Color</label>
                        <div className="small-dropdown rating-dropdown">
                            <Autocomplete
                                className="colors"
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={['Red', 'Green', 'Amber']}
                                value={colors}
                                onChange={handelColor}
                                renderInput={(params) => <TextField name="role" {...params} className="commonFont2" placeholder='select Color'  value=''  label=''/>}
                            />
                        </div>
                        <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red",height:'18px' }}><span>{formError.color}</span></p>
                    </div>
                    <div className="w-5 del-btn align-items-center ms-4">
                            {props.entityType?<div className="d-flex"><button type="button" className="btn creat-rating del-moment-group p-0" onClick={createRating}>
                                <i className="lni lni-checkmark text-primary-blue me-0" data-tip="Save Rating" ></i>
                            </button>
                            <button type="button" className="btn creat-rating del-moment-group p-0" onClick={removeRating}>
                                <i className="lni lni-archive text-primary-blue me-0" data-tip="Remove form" ></i>
                            </button></div>
                            :
                            <div className="d-flex"><button type="button" className="btn creat-rating del-moment-group p-0" onClick={updateRating}>
                                <i className="lni lni-checkmark text-primary-blue me-0" data-tip="Update Rating" ></i>
                            </button>
                            <button type="button" className="btn del-rating del-moment-group p-0 " onClick={deleteRating}>
                                <i className="lni lni-archive text-primary-blue me-0" data-tip="Delete Rating" ></i>
                            </button>
                            </div>}
                        </div>
                </div>
            </div>
            <ReactTooltip place="bottom" type="dark" effect="solid"/>
            <WantToDeleteModal show={isDelete} close={closeButton} isConform={deleteRatings} />
        </div>
    )
}

export default RatingsGroup;