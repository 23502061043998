import React, { useEffect, useState } from 'react'

function MeetingAllFoulLanguage(props) {

    const renderFoulLanguage = (item) => {
      return(
        <a className='list-group-item list-group-item-action' key={item.index}>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.participants[item.speaker]}
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item.startTime}
            </small>
          </div>
            <p class="mb-1" style={{color:'black'}}>
              {item.trans}        
            </p>
        </a>
      )
    }

    const filteredFoulLanguage = props?.foulLanguage[0]?.abusiveLanguage?.filter(item => {
      if (props.keyClicked === 'all') return true;
      return item.speaker == props.keyClicked;
    });
  
    return (
      <div class="list-group moment-list-group-scroll mb-2">
        {props?.foulLanguage && filteredFoulLanguage?.map((item) => 
          renderFoulLanguage(item)
        )}
      </div>
      );
}

export default MeetingAllFoulLanguage