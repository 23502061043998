import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiCall } from '../Services/Interceptor';
import Loading from '../Commons/Loading';
import './liveAssist.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import seller from '../../assets/images/callList-images/seller.svg';
import phone from '../../assets/images/callList-images/phone.svg';
import calender from '../../assets/images/callList-images/calendar.svg';
import notes from '../../assets/images/callList-images/note.png'
import {
  Avatar,
  Badge,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import time from '../../assets/images/callList-images/timer.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredPrompts, setLiveData } from '../../redux-container/liveAssist/action';
import exclamation from '../../assets/images/exclamation.svg'
import Nudges from './Nudges';
import {setNonLiveData} from '../../redux-container/nonLiveCall/action';
import store from '../../redux-container/store';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px #ecf0f3`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const LiveAssistCallDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const socketRef = useRef(null)
  let nonLiveData = useSelector(state=>state?.non_live?.nonLiveAssist)
  let liveData = useSelector(state=>state?.live_assist?.liveCallData)
  let dataToHitSocket = useSelector(state=>state?.non_live?.singleCallData)
  let userDetails = useSelector(state=>state?.non_live?.assistedUserDetail)
  let prompts = useSelector(state=>state?.live_assist?.prompts)
  const queryParams = new URLSearchParams(window.location.search)
  let typeOfCall = queryParams.get('callType')
  const [loader, setLoader] = useState(false);
  const storedData = JSON.parse(localStorage.getItem('AGENT'));
  const containerRef = useRef(null);
  const [div1Height, setDiv1Height] = useState(317);
  const [isDragging, setIsDragging] = useState(false);
  const [socket, setSocket] = useState(null);
  const [momentList, setMomentList] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentId, setCurrentId] = useState(0)
  const [progress, setProgress] = useState(0);
  const [traversedPromptIds, setTraversedPromptIds] = useState([]);
  const [hiddenGroups, setHiddenGroups] = useState([]);

  const getLiveDetail = async()=>{
    await apiCall.get(`/odio/api/live/assist/calls?phoneNumber=${dataToHitSocket?.phone}&socketId=${dataToHitSocket?.socketId}&streamId=${dataToHitSocket?.streamId}`).then(res=>{
      if(res?.data?.status == 0){
        getMoments(res?.data?.data?.details?.momentBucketId,res?.data?.data?.nudges)
        if (res?.data?.data?.nudges) {
          const transformData = (data) => {
            const result = {
              transcript:[],
              alert: [],
              objection: [],
              reburttal: [],
              notes: [],
              prompts: []
            };
            // Iterate over each index
            data.forEach((item, index) => {
          
              // Extract speaker and trans from the first item (if needed)
              if (item?.trans!='' && item?.speaker!== undefined ) {
                result.transcript.push({
                  speaker: item.speaker,
                  trans: item.trans
                });
              }
          
              // Combine arrays
              // Object.keys(result).forEach(key => {
              //   if (key !== 'transcript' &&key!='alert' && Array.isArray(item[key]) && item[key].length > 0) {
              //     result[key] = [...result[key], ...item[key]];
              //   }
              // });
              if (Array.isArray(item.alert) && item.alert.length > 0) {
                result.alert = result.alert.concat(item.alert);
              }
              if (Array.isArray(item.notes) && item.notes.length > 0) {
                result.notes = result.notes.concat(item.notes);
              }
              if (Array.isArray(item.objection) && item.objection.length > 0) {
                result.objection = result.objection.concat(item.objection);
              }
              // if (Array.isArray(item.prompts) && item.prompts.length > 0) {
              //   result.prompts = result.prompts.concat(item.prompts);
              // }
              if (Array.isArray(item.reburttal) && item.reburttal.length > 0) {
                result.reburttal = result.reburttal.concat(item.reburttal);
              }
            });
          
            return result;
          };
          
          // Use the transform function
          const nudges = transformData(res?.data?.data?.nudges);
          dispatch(setNonLiveData(nudges, res?.data?.data?.details))
          // Flatten the data array and collect all alert items
          const allAlerts = res?.data?.data?.nudges.flatMap(item => item.alert || []);
          // Filter alerts based on the new structure and momentList
          const newData = allAlerts.filter(alertItem => 
            momentList.some(momentGroup =>
              momentGroup?.moments?.some(momentItem =>
                momentItem?.momentId == alertItem?.moment_id && alertItem?.exists === true
              )
            )
          );
          // Dispatch filtered prompts
          //dispatch(setFilteredPrompts(newData.length > 0 ? newData?.map(item=>{return {id:item?.moment ,prompt:item?.prompt}}) : ''));
        }
      }
    }).catch((error) => {
      console.log('Error:', error);
    })
  }

  const getMoments = (momentBucketId,nudges)=>{
    setLoader(true);
    apiCall.get(`/odio/api/moment-bucket/get-moments?mentions=true&momentBucketId=${momentBucketId}`).then(res=>{
    if(res?.data?.status == 0){
        let filteredMomentGroups = res?.data?.data?.momentGroups || [];
      // Get the alerts from nudges
      const allAlerts = nudges?.flatMap(item => item.alert || []);
      if (typeOfCall === 'LIVE') {
        // Filter out moments from momentGroups that match the momentId in alerts
        filteredMomentGroups = filteredMomentGroups?.map(momentGroup => {
          const filteredMoments = momentGroup?.moments?.filter(momentItem =>
            !allAlerts?.some(alertItem =>
              momentItem?.momentId == alertItem?.moment_id
            )
          );
          // Return momentGroup with filtered moments
          return { ...momentGroup, moments: filteredMoments };
        });
        // After filtering, set the moment list
        setMomentList(filteredMomentGroups);
      } else {
        // For non-live calls, just set the moment groups directly
        setMomentList(res?.data?.data?.momentGroups);
      }
      setLoader(false)
    }else{
      setLoader(false);
    }
  }).catch((err)=>{
      setLoader(false);
    })
  }

  useEffect(() => {
    // Create a new WebSocket instance
    socketRef.current = new WebSocket('wss://livesttsocket.odioiq.com/serve');
    let mediaInterval
    if (window.location.pathname === '/live_assist_call_details' && typeOfCall=='LIVE') {
      if (!socket) {
        socketRef.current.onopen = () => {

          // Send "connect" event
          socketRef.current.send(JSON.stringify({ event: 'connect' }));

          // Send "start" event
          socketRef?.current.send(JSON.stringify({ 
            "event": "start",
            "data_id": parseInt(dataToHitSocket?.socketId),
            "streamSid": dataToHitSocket?.streamId,
            "phoneNumber": dataToHitSocket?.phone
          }));
        };

        socketRef.current.onmessage = (s) => {
          const data = JSON.parse(s.data);
          if (data) {
            dispatch(setLiveData(data))
            const latestNonLiveData = store.getState().non_live.nonLiveAssist;
            const transformData = (data) => {
              const result = {
                transcript: [...(latestNonLiveData?.transcript || [])],
                alert: [...(latestNonLiveData?.alert || [])],
                objection: [...(latestNonLiveData?.objection || [])],
                reburttal: [...(latestNonLiveData?.reburttal || [])],
                notes: [...(latestNonLiveData?.notes || [])],
                prompts: [...(latestNonLiveData?.prompts || [])]
              };
                if (data?.trans!='' && data?.speaker!== undefined ) {
                  result.transcript.push({
                    speaker: data.speaker,
                    trans: data.trans
                  });
                }
                return result
              }
              const nudges = transformData(data);
              dispatch(setNonLiveData(nudges, userDetails));
            if (data?.alert?.length > 0) {
              let newData = data?.alert.find(item => (
                momentList.map(moment => (moment?.moments?.find(dat => dat?.momentId == item?.moment_id && item?.exists == true)))
              ))
              newData && dispatch(setFilteredPrompts(newData ? { id: newData?.moment, prompt: newData?.prompt }  : ''))
            }
          }
        };

        socketRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
        };

        setSocket(socketRef?.current);
      }
    } 
    else {
      if (socketRef?.current) {
        // newSocket.close();
          setSocket(null);
      }
    }

    // Cleanup function to run on component unmount
    return () => {
      if (socketRef?.current) {
        socketRef?.current.close();
        clearInterval(mediaInterval); // Clear interval on unmount
        setSocket(null);
      }
    };
  }, []);

  useEffect(() => {
    let intervalId;
    let timeoutId;

    const PROGRESS_DURATION = 2000; // Total duration for the progress bar to reach 100% (2 seconds)
    const PROGRESS_INCREMENT_INTERVAL = 100; // Interval for progress increment (100ms)
    const PROGRESS_INCREMENT_STEP = 100 / (PROGRESS_DURATION / PROGRESS_INCREMENT_INTERVAL); // Progress increment step to complete in 3 seconds

    if (typeOfCall==='LIVE') {
      const showNextPrompt = () => {
        setCurrentIndex(prevIndex => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < prompts.length) {
            setProgress(0);
            setCurrentId(prompts[nextIndex]?.id);
            return nextIndex;
          } else {
            clearInterval(intervalId); // Stop the interval on the last prompt
            return prevIndex; // Stay on the last prompt
          }
        });
      };

      const startInterval = () => {
        if (intervalId) clearInterval(intervalId);
        if (timeoutId) clearTimeout(timeoutId);

        intervalId = setInterval(() => {
          setProgress(prev => {
            if (prev >= 100) {
              clearInterval(intervalId);// Stop the interval when progress reaches 100%
              // setNextPromptArrived(false) 
              return 100;
            }
            return prev + PROGRESS_INCREMENT_STEP; // Increment progress
          });
        }, PROGRESS_INCREMENT_INTERVAL);
      };

      const handlePromptChange = () => {
        showNextPrompt();
        setTimeout(() => {
          startInterval();
        }, 2000);
      };

      if (prompts.length > 0) {
        if (currentIndex === 0) {
          handlePromptTraversal(0);
          setCurrentId(prompts[0]?.id);
        }

        startInterval();

        const checkProgressCompletion = () => {
          if (progress >= 100) {
            handlePromptChange();
          }
        };

        const progressMonitorInterval = setInterval(() => {
          checkProgressCompletion();
        }, PROGRESS_INCREMENT_INTERVAL);

        return () => {
          if (intervalId) clearInterval(intervalId);
          if (progressMonitorInterval) clearInterval(progressMonitorInterval);
          if (timeoutId) clearTimeout(timeoutId);
        };
      }
    }
}, [prompts, currentIndex, progress]);

  const handlePromptTraversal = (index) => {
    const currentPromptId = prompts[index]?.id;
    if (currentPromptId && !traversedPromptIds.includes(currentPromptId)) {
      setTraversedPromptIds((prev) => [...prev, currentPromptId]);
    }
  };

  // Call handlePromptTraversal when the current prompt changes
  useEffect(() => {
    if(typeOfCall==='LIVE'){
      handlePromptTraversal(currentIndex);
    }
  }, [currentIndex]);
  

  useEffect(() => {
    if (prompts.length > 0 && typeOfCall==='LIVE') {
      const progressInterval = setInterval(() => {
        setProgress(prev => (prev < 100 ? prev + 2 : 100)); // Update the progress every 100ms
      }, 100);

      return () => clearInterval(progressInterval); // Cleanup
    }
  }, [currentIndex, prompts]);

  useEffect(()=>{
    if(dataToHitSocket?.streamId && dataToHitSocket?.socketId){
      getLiveDetail()
    }
  },[])

  useEffect(() => {
    if(typeOfCall=='LIVE'){
      const newlyHiddenGroups = momentList?.reduce((acc, group) => {
        const allMomentsTraversed = group?.moments?.every((moment) =>
          traversedPromptIds.includes(moment.momentId)
        );
    
        if (allMomentsTraversed && !hiddenGroups.includes(group.momentGroupId)) {
          acc.push(group.momentGroupId);
        }
    
        return acc;
      }, []);
  
      if (newlyHiddenGroups?.length > 0) {
        setTimeout(() => {
          setHiddenGroups((prev) => [...prev, ...newlyHiddenGroups]);
        }, 5000); // Adjust the delay as needed (200ms is a starting point)
      }
    }
  }, [traversedPromptIds, momentList]);


  const goToCallHandler = (
    id,
    rec,
    duration,
    date,
    empId,
    seller,
    customer,
    coeId,
    coe,
    phone,
    externalId,
    name,
    filename
  ) => {
    localStorage.setItem('URL', rec);
    history.push(`voice_detail?callId=${id}&name=${name}`, {
      callId: id,
      url: rec,
      duration: duration,
      date: date,
      id: empId,
      seller: seller,
      customer: customer,
      coeId: coeId,
      coe: coe,
      phone: phone,
      agentExternalId: externalId,
      callName: name,
      fileName: filename,
    });
  };

  const conversion = (duration) => {
    let hh = duration?.split(':')[0];
    let mm = duration?.split(':')[1];
    let ss = duration?.split(':')[2];
    let text = '';
    if (hh > 0) {
      text = `${hh} Hrs. ${mm} Min. ${ss} Sec.`;
    } else if (mm > 0) {
      text = text + `${mm} Min. ${ss} Sec.`;
    } else if (ss > 0) {
      text = text + `${ss} Sec.`;
    }
    // let text  = minutes + sec
    return text;
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    e.preventDefault();  // Prevent text selection
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const newDiv1Height = e.clientY - containerRect.top;

    if (newDiv1Height > 0 && newDiv1Height < containerRect.height - 10) {
      setDiv1Height(newDiv1Height);
    }
  };

  return (
    <>
    <div className="live-wrapper">
      <div className="page-content">
        <div className="call-details-live">
          <div className="call-details-live-main-content">
            <div className="">
              <Breadcrumb>
                <Breadcrumb.Item href="/live_assist">
                  Live Assist
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Live Assist Call Details
                </Breadcrumb.Item>
              </Breadcrumb>
              <h2 className="dashboard-headings d-inline">
                Live Assist Call Details
              </h2>
              <div  style={{ marginBottom: '1rem' }}>
                <div className="salesCallCard">
                  <div className="user">
                    <div className="caller">
                      <div className="name" style={{marginTop:'20px', marginBottom:'20px'}}>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <Avatar sx={{ height: '28px', width: '28px' }} />
                        </StyledBadge>
                        <span>{userDetails?.customerName}</span>
                      </div>
                    </div>
                    <div className="callCount"></div>
                  </div>
                  <div className="seller">
                    <div className="name">
                      <span className="icon">
                        <img src={seller} alt="" />
                      </span>
                      {`${userDetails?.sellerName} (${userDetails?.userExternalId})`}
                    </div>
                    <div className="number">
                      <span className="icon">
                        <img src={phone} alt="" />
                      </span>
                      {userDetails?.phone}
                    </div>
                    <div className="date">
                      <span className="icon">
                        <img src={calender} alt="" />
                      </span>
                      {userDetails?.date}
                    </div>
                    <div className="time">
                      <span className="icon">
                        <img src={time} alt="" />
                      </span>
                      {userDetails?.talkDuration}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-2" />
            <div className='trans-container live-assist-scroll-view'>
              <h5>{'Transcript'}</h5>
              {nonLiveData?.transcript?.length > 0 && <div className='chat-container'>
              {nonLiveData?.transcript.map(data=>{
                let key = userDetails?.speakers[data?.speaker]
                return(
                <div className='message' style={{alignSelf:data?.speaker == 0 ?'' : 'flex-end'}}>
                  <h5 class="font-16">
                      <i class="bx bx-user-voice"></i>
                      {userDetails?.[key]}
                      <span class="badge rounded-pill bg-light-blue text-dark f-normal">{}</span>
                    </h5>
                    <p>{data?.trans}</p>
                </div>
              )}
              )}
              </div>}
              {liveData?.transcript && liveData?.transcript.map(data=>{
                let key = userDetails?.speakers[data?.speaker]
                return(
                <div style={{margin:'20px', border:'2px solid lightgray', padding:'10px'}}>
                  <div  style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                    <h5 class="font-16" style={{display:'flex',alignItems:'center', gap:'4px'}}>
                      <i class="bx bx-user-voice"></i>
                      {userDetails?.[key]}
                      <span class="badge rounded-pill bg-light-blue text-dark f-normal">{}</span>
                    </h5>
                    {/* <small class="text-muted"  style={{display:'flex',alignItems:'center', gap:'4px'}}>
                      <i class="bx bx-time"></i>
                      00:00:00
                    </small> */}
                  </div>
                  <p>{data?.trans}</p>
                </div>
              )}
              )}
            </div>
          </div>
          <div className="call-details-live-secondary-content" ref={containerRef} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
            <div className='detail-heading'>
              <h5>{'Call Details'}</h5>
            </div>
            <hr className="my-2" style={{marginTop:'15px'}}/>
              {loader ? (
                <div className="loader-container">
                  <Loading variant="dark" />
                </div> ) : (
                <>
                  <div className="div1 live-assist-scroll" style={{ height: `${div1Height}px`, overflowY: 'auto', overflowX:'hidden'}}>
                    <>
                      {typeOfCall === 'LIVE' && 
                      // (prompts.length===currentIndex+1 && progress>99 ? '' : 
                        <div className="section" style={{backgroundColor:liveData?.alert && liveData?.alert[currentIndex]?.exists==true ? '#e8ffe8' : '#ffcccc'}}>
                          <div className='moment-highlight' style={{backgroundColor: liveData?.alert && liveData?.alert[currentIndex]?.exists==true ? '#9cd794' : '#ff8080'}}>
                            {/* <span className='moment-desc'>Security Needs</span> */}
                          </div>
                          <div className="progress-bar" style={{ width: '100%', height: '3px', backgroundColor: 'white'}}>
                            <div style={{ width: `${progress}%`, height: '100%', borderRadius:'5px', backgroundColor: `${liveData?.alert && liveData?.alert[currentIndex]?.exists==true ? 'green' : '#fc8f8f'}`}} />
                          </div>
                          <div className='moment-section'>
                            <p>{prompts?.[currentIndex]?.prompt}</p>
                          </div>
                        </div>
                      }
                      <div className="moment-detail">
                        {momentList?.map((group,groupIndex) =>{

                          let momentsToRender = [];

                          if (typeOfCall === 'LIVE') {

                            if (hiddenGroups.includes(group.momentGroupId)) {
                              return null;
                            }
                            // Get all moment IDs that have already appeared in prompts
                            const traversedMomentIds = prompts && prompts?.slice(0, currentIndex + 1).map(p => p.id);
                            const filteredMomentIds = prompts.slice(0, currentIndex).map(p => p.id);

                            // Determine the last struck moment based on the available alerts
                            const lastStruckMomentId = liveData?.alert?.slice(-1)[0]?.moment;

                            // Filter moments to include only those starting from the current prompt onward
                            momentsToRender = group?.moments?.filter((moment, index) => {
                              const matchedItem = liveData?.alert?.find(alert => alert.moment_id == moment?.momentId);
                              const isCurrentMoment = moment.momentName == currentId;
                              // Include moments if they match the current prompt index or haven't been traversed yet
                              return isCurrentMoment || !traversedMomentIds.includes(moment.momentName) || !matchedItem
                            });

                            // If no moments match the criteria, skip rendering this group
                            if (momentsToRender?.length === 0) {
                              return null;
                            }
                          }
                          else{
                            momentsToRender = group.moments;
                          }
                          const currentMomentIndex = momentsToRender?.findIndex(moment => moment.momentName == currentId);
                          return (
                            <div key={group.momentGroupId}>
                              <>
                                <div style={{fontSize:'14px'}}> 
                                  <span style={{fontWeight:'bold', fontFamily:'sans-serif'}}>
                                    {group?.momentGroupName}
                                    <i class="bi bi-exclamation"></i>
                                  </span>
                                </div>
                                {momentsToRender?.map((moment,index)=>{
                                  const matchedItem = (typeOfCall=='LIVE' ? liveData : nonLiveData)?.alert?.find(alert => alert?.moment_id == moment?.momentId);
                                  const isCurrentMoment = matchedItem?.moment == currentId;
        
                                  const isNextMoment = index == currentMomentIndex + 1;
                                  const isNextMomentExists = momentsToRender?.findIndex(moment => moment.momentName == currentId);
                                  const momentExists =(typeOfCall=='LIVE' ? liveData : nonLiveData)?.alert?.some(alert=>alert?.moment_id == moment?.momentId)

                                  return( 
                                    <> 
                                      <div key={moment.momentId}>
                                        <ul>
                                          {typeOfCall=='LIVE' ? 
                                            <li style={matchedItem?.exists && isCurrentMoment ? {textDecoration:'line-through',color:'green'} : {}} className={(progress>=12 &&  isCurrentMoment) ? 'liclass' : ''}>
                                              {moment?.momentName}
                                              {!matchedItem?.exists && isCurrentMoment ? <img src={exclamation} className='exclamation-img'/> : ''}
                                            </li>
                                            :
                                            <li style={matchedItem?.exists && momentExists? {textDecoration:'line-through',color:'green'} : {}}>
                                               {moment?.momentName}
                                              {!matchedItem?.exists && momentExists ? <img src={exclamation} className='exclamation-img'/> : ''}
                                            </li>
                                          }
                                        </ul>
                                      </div>
                                    </>
                                  )
                                })}
                              </> 
                            </div>
                          )
                        })}
                      </div>
                    </>
                  </div>
                  <div 
                    className="divider-web"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    >
                    <div className="grabber"></div>
                  </div>
                  <div className="div2 call-notes live-assist-scroll-view" style={{ height:  `${600 - div1Height - 10}px` }}>
                    <div className='d-flex mb-3'>
                      <img src={notes} className='notes-img'/>
                      <h5>Call Notes</h5>
                    </div>
                    {nonLiveData && nonLiveData?.notes && nonLiveData?.notes?.map(note=>(
                      <p>{note?.prompt}</p>
                    ))}
                    <textarea placeholder="Add your note here..." className='textArea-liveAssist'></textarea>
                  </div>
                </>)
              }
            {/* <div>
              {loader ? (
                <div className="loader-container">
                  <Loading variant="dark" />
                </div>
              ) : (
                <ListGroup style={{marginTop:'15px'}}>
                  {callAssitData &&
                    callAssitData.length > 0 &&
                    Object.entries(callAssitData[0].audit_keys[0]).map(
                      ([key, value]) => (
                        <ListGroup.Item
                          key={key}
                          action
                          variant={value === 1 ? 'success' : 'danger'}
                        >
                          {key}
                        </ListGroup.Item>
                      )
                    )}
                </ListGroup>
              )}
            </div> */}
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'20px', paddingBottom:'10px'}}>
              <button
                type="submit"
                className="btn px-4 lh-base update-btn"
                style={{ width: '160px' }}
                onClick={() => {
                  goToCallHandler(
                    userDetails.callId,
                    userDetails.recordingUrl,
                    userDetails.talkDuration,
                    userDetails.date,
                    userDetails.employeeId,
                    userDetails.sellerName,
                    userDetails.customerName,
                    storedData?.selectedCoeRange,
                    storedData?.selectedCOE,
                    userDetails.phone,
                    userDetails.userExternalId,
                    'SINGLE_CALL',
                    `${userDetails.filename}.wav`
                  );
                }}
              >
                View Call
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Nudges />
    </>
  );
};

export default LiveAssistCallDetails;
