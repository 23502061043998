import React, { useEffect, useState, useCallback } from "react";
import "../KnowledgeBase/knowledgeBase.css";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import KnowledgeBaseBanner from "../KnowledgeBase/KnowledgeBaseBanner";
import ViewOnlyDocument from "./ViewOnlyDocument";
import { getDocumentDetailsByFolderIdDocId } from "../../Services/knowledgeBase.service";
import conversationService from "../../Services/conversation.service";
import { setDocEditData, setMomemtData, clearDocEditData, clearMomemtData } from "../../../redux-container/knowledgeBase/action";
import Loading from "../../Commons/Loading";
import toaster from "../../Toast/toaster";
import { markDocCompleted } from "../../Services/coaching.service";

const ViewDocument = () => {
  const [tabValue, setTabValue] = useState("1");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, folderData, folderId, redirectFrom, document, moduleId, sessionId } = history.location.state || {};
  const folderName = data?.folderName || folderData?.folderName;
  const documentName = data?.title || document?.title;
  const isEditCase = !!data;
  const [progressStatus, setProgressStatus] = useState(null);
  useEffect(() => {setProgressStatus(document?.progressStatus)}, [document])
  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      try {
        if (isEditCase) {
          const folderId = data.folderId || parseInt(localStorage.getItem("folderId"));
          const documentResponse = await getDocumentDetailsByFolderIdDocId(folderId, data.id);
          if (documentResponse.data?.status === 0) {
            dispatch(setDocEditData(documentResponse.data.data));
          } else {
            toaster.error("Error while fetching document details");
          }
        }

        const momentBucketId = parseInt(localStorage.getItem("MOMENT_ID")) || undefined;
        const momentsResponse = await conversationService.getCallListingMoments("SALES", momentBucketId);
        if (momentsResponse.data?.status === 0) {
          dispatch(setMomemtData(momentsResponse.data.data));
        } else {
          toaster.error("Error while fetching moments");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchData();

    return () => {
      dispatch(clearDocEditData());
      dispatch(clearMomemtData());
    };
  }, [dispatch, isEditCase, data]);

  const updateProgressStatus = useCallback(
    async (status) => {
      const reqBody = {
        coachingSessionProgressStatus: status,
        documentId: document?.id,
        moduleId,
        sessionId,
      };
      try {
        const response = await markDocCompleted(reqBody);
        if (response?.status === 200) {
          setProgressStatus(status);
          toaster.success("Document status updated successfully");
        } else {
          toaster.error("Failed to update document status");
        }
      } catch (error) {
        console.error("Error updating progress status:", error);
        toaster.error("An error occurred while updating document status");
      }
    },
    [document?.id, moduleId, sessionId]
  );

  const handleCheck = () => {
    if (progressStatus !== "COMPLETED") {
      updateProgressStatus("COMPLETED");
    }
  };

  useEffect(() => {
    if (progressStatus === "NOT_STARTED" && document && moduleId && sessionId) {
      updateProgressStatus("IN_PROGRESS");
    }
  }, [progressStatus, document, moduleId, sessionId, updateProgressStatus]);

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <KnowledgeBaseBanner componentName={documentName} folderName={folderName || data?.folderName} folderId={folderId || Number(data?.parentFolderId)} redirectFrom={redirectFrom} />
        {loader ? (
          <div className="loader-container">
            <Loading variant="dark" />
          </div>
        ) : (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <TabList onChange={(e, newValue) => setTabValue(newValue)}>
                <Tab label="View Only" value="1" />
              </TabList>
              <TabPanel value="1">
                <ViewOnlyDocument />
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </div>
     {redirectFrom === 'coachingUserStats' &&  <div className="check-button">
        <label style={{cursor : 'pointer'}} >
          <input
            type="checkbox"
            name="completedCheckBox"
            checked={progressStatus === 'COMPLETED'}
            // value={option?.option}
            onChange={handleCheck}
            style={{cursor : 'pointer'}}
          />
          {"Mark as Completed"}
        </label>
      </div>}
    </div>
  );
};

export default ViewDocument;
