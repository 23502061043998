import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import userService from "../Services/user.service";
import { SETTINGS_COMPONENT_TYPE } from "../../Constants/Enum";
import { connect } from "react-redux";
import {
  deleteCOE,
  removeCoeUser,
  getclientcoeList,
} from "../../redux-container/settings/manage_coe/action";
import {
  getUserList,
  deleteUser,
} from "../../redux-container/settings/manage_users/action";
import { deleteMoment } from "../../redux-container/settings/manage_moments/action";
import profileService from "../Services/profile.service";
import toast from "../Toast/toaster";
import clientService from "../Services/client.service";
import {deleteDocument, deleteFolder} from "../Services/knowledgeBase.service"
import toaster from "../Toast/toaster";
import sessionService from "../Services/session.service";

function DeleteData(props) {
  const [show, setShow] = useState(true);
  const [inputs, setInputs] = useState({
    userId: props.selectedRowData.id,
    status: false,
  });

  const handleClose = () => {
    setShow(false);
    props.setIsOpen(false);
  };
  function deleteItem() {
    setShow(false);
    props.setIsOpen(false);

    switch (props.componentName) {
      case SETTINGS_COMPONENT_TYPE.MANAGE_COE: {
        if (props.selectedRowData.numberOfUsers > 0) {
          toast.error("Users exist in current COE");
        } else {
          props.deleteCOE(props.selectedRowData.coeId);
          // this.setState({ coesList: props.manage_coe.coeList });
          // if(response.status === 200){
          props.getclientCoeList();
          // }
        }
        break;
      }
      case SETTINGS_COMPONENT_TYPE.MANAGE_USERS: {
        profileService.updateProfile(inputs);
        //return props.deleteUser()
        // setInputs({ userId: "", status: false });
        setTimeout(()=>{
          props.getUserList();
        },1000)
        break;
      }
      case SETTINGS_COMPONENT_TYPE.MANAGE_COMPETITORS: {
        clientService.deleteCompetitor(props?.selectedRowData.id)
        .then((res) => {
          toaster.success(res.data.message)
          props.getCompetitorsList()
        })
        .catch((error) => {
          toaster.error("Unable to delete competitor. Please try again.")
        })

        break;
      }
      case SETTINGS_COMPONENT_TYPE.MANAGE_KNOWLEDGE_DOCUMENT: {
        deleteDocument(props?.selectedRowData?.id, props.folderId)
        .then((res) => {
          toaster.success(res.data.message)
          props?.getFoldersList(props.folderId)
        })
        .catch((error) => {
          toaster.error("Unable to delete competitor. Please try again.")
        })

        break;
      }
      case SETTINGS_COMPONENT_TYPE.MANAGE_KNOWLEDGE_FOLDER: {
        deleteFolder(props?.selectedRowData.id)
        .then((res) => {
          toaster.success(res.data.message)
          props?.getFoldersList()
        })
        .catch((error) => {
          toaster.error("Unable to delete Folder. Please try again.")
        })

        break;
      }


      // case SETTINGS_COMPONENT_TYPE.MANAGE_REPORTS: {
      //   profileService.updateProfile(inputs);
      //   //return props.deleteUser()
      //   // setInputs({ userId: "", status: false });
      //   props.getUserList();

      //   break;
      // }

      case SETTINGS_COMPONENT_TYPE.MANAGE_MOMENTS: {
        return props.deleteBucket(props.selectedRowData);
      }

      case SETTINGS_COMPONENT_TYPE.MANAGE_COE_USERS: {
        var userData = {
          coeId: props.selectedRowData.coeId,
          userId: props.selectedRowData.id,
        };
        return props.removeCoeUser(userData);
        //return userData;
      }
      case SETTINGS_COMPONENT_TYPE.MANAGE_COACHING_SESSION : {
        sessionService.deleteSession(props.selectedRowData.id).then(res => {if(res?.data.status === 0){
          props?.getAllCoachingSessionsList();
          toaster.success('Session successfully deleted !');
        }}).catch(err => toaster.error('Error in deleting session!'))
        break;
      };
      default:
        break;
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.modalBodyData}</Modal.Body>
        <Modal.Footer>
          {props.isClose ? (
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                I doubt
              </Button>
              <Button variant="primary" onClick={deleteItem}>
                Yes, I'm
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCOE: (Id) => {
      dispatch(deleteCOE(Id));
    },
    deleteMoment: (data) => {
      dispatch(deleteMoment(data));
    },
    deleteUser: () => {
      dispatch(deleteUser());
    },
    removeCoeUser: (data) => {
      dispatch(removeCoeUser(data));
    },
    getclientCoeList: () => {
      dispatch(getclientcoeList());
    },
    getUserList: () => {
      dispatch(getUserList());
    },
  };
};

export default connect(null, mapDispatchToProps)(DeleteData);
