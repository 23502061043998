export const dummyLiveData = {
    liveAssistData : {
        "callId": 0,
        "callScore": 0,
        "customerName": "Tim Spencer",
        "date": "22/08/2024 11:05:38",
        "phone": "9876543210",
        "pitchScore": 0,
        "sellerName": "Bolivar Araya",
        "talkDuration": "00:18:30",
        "userExternalId": 83862713,
        "employeeId": "9176323",
        "coeExternalId": 68715092,
        "liveStatus": true,
        "streamId": "MFyhg398e21d321ad48e1e6d730bv61f78",
        "socketId": "140532256549091"
    },
    momentGroups : [
        {
            "momentGroupId": 1,
            "momentGroupName": "Need Analysis",
            "moments": [
                {
                    "momentId": 1,
                    "momentName": "Smoking Habits",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T10:00:26.000+00:00",
                    "remark": {
                        "remarkId": 891,
                        "positiveRemarks": "No Transfer To Supervisor Dic",
                        "negativeRemarks": "Transfer To Supervisor Dic"
                    }
                },
                {
                    "momentId": 2,
                    "momentName": "Customer Smoking Habits",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [
                        {
                            "mentionId": 214,
                            "mentionName": "i am from cascade ",
                            "mentionMatchType": "SEMANTIC",
                            "mentionWeightage": 0.8,
                            "updatedAt": "2024-03-12T12:09:27.000+00:00"
                        }
                    ],
                    "updatedAt": "2024-03-19T10:00:42.000+00:00",
                    "remark": {
                        "remarkId": 900,
                        "positiveRemarks": "Bound Script Not Found",
                        "negativeRemarks": "Bound Script  Found"
                    }
                },
                {
                    "momentId": 3,
                    "momentName": "Education",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T10:00:55.000+00:00",
                    "remark": {
                        "remarkId": 902,
                        "positiveRemarks": "Agent Assist Caller",
                        "negativeRemarks": "Agent Doesn't Assist Caller"
                    }
                },
                {
                    "momentId": 4,
                    "momentName": "Customer Education",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T10:01:01.000+00:00",
                    "remark": {
                        "remarkId": 903,
                        "positiveRemarks": "Agent Leave Leave Proper VM",
                        "negativeRemarks": "Agent Doesn't Leave Leave Proper VM"
                    }
                },
            ],
            "updatedAt": "2023-05-01T07:45:08.000+00:00"
        },
        {
            "momentGroupId": 2,
            "momentGroupName": "Plan Benefits Usp",
            "moments": [
                {
                    "momentId": 5,
                    "momentName": "Plan Pitching",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T09:55:54.000+00:00",
                    "remark": {
                        "remarkId": 881,
                        "positiveRemarks": "Use Correct Verbiage When Disclosing APR",
                        "negativeRemarks": " No Use Correct Verbiage When Disclosing APR"
                    }
                },
                {
                    "momentId": 6,
                    "momentName": "Usp",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T09:53:56.000+00:00",
                    "remark": {
                        "remarkId": 904,
                        "positiveRemarks": "Agent Verify Consumers Account",
                        "negativeRemarks": "Agent Doesn't Verify Consumers Account"
                    }
                },
                {
                    "momentId": 7,
                    "momentName": "Benefits",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T09:55:54.000+00:00",
                    "remark": {
                        "remarkId": 881,
                        "positiveRemarks": "Use Correct Verbiage When Disclosing APR",
                        "negativeRemarks": " No Use Correct Verbiage When Disclosing APR"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:16:24.000+00:00"
        },
        {
            "momentGroupId": 3,
            "momentGroupName": "Income Eligibilty",
            "moments": [
                {
                    "momentId": 8,
                    "momentName": "Income",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2023-05-02T02:26:36.000+00:00",
                    "remark": {
                        "remarkId": 911,
                        "positiveRemarks": "Third Party Verification Done",
                        "negativeRemarks": "Third Party Verification Not Done"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:25:14.000+00:00"
        },
        {
            "momentGroupId": 4,
            "momentGroupName": "Upsell Plan",
            "moments": [
                {
                    "momentId": 9,
                    "momentName": "Upsell",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2023-05-02T02:31:15.000+00:00",
                    "remark": {
                        "remarkId": 916,
                        "positiveRemarks": "Opening Dead Air Not Found",
                        "negativeRemarks": "Opening Dead Air Found"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:30:28.000+00:00"
        },
        {
            "momentGroupId": 5,
            "momentGroupName": "Creating Sale Urgency",
            "moments": [
                {
                    "momentId": 10,
                    "momentName": "Creating Urgency",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2023-05-02T02:31:15.000+00:00",
                    "remark": {
                        "remarkId": 916,
                        "positiveRemarks": "Opening Dead Air Not Found",
                        "negativeRemarks": "Opening Dead Air Found"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:30:28.000+00:00"
        },
        {
            "momentGroupId": 6,
            "momentGroupName": "Medical Checkup Test",
            "moments": [
                {
                    "momentId": 11,
                    "momentName": "Medical Checkup",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2023-05-02T02:31:15.000+00:00",
                    "remark": {
                        "remarkId": 916,
                        "positiveRemarks": "Opening Dead Air Not Found",
                        "negativeRemarks": "Opening Dead Air Found"
                    }
                },
                {
                    "momentId": 12,
                    "momentName": "Medical Test",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T10:01:39.000+00:00",
                    "remark": {
                        "remarkId": 917,
                        "positiveRemarks": "No High ROS",
                        "negativeRemarks": "High ROS"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:30:28.000+00:00"
        },
        {
            "momentGroupId": 7,
            "momentGroupName": "Sale Rebuttal",
            "moments": [
                {
                    "momentId": 13,
                    "momentName": "Rebuttal",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2023-05-02T02:31:15.000+00:00",
                    "remark": {
                        "remarkId": 916,
                        "positiveRemarks": "Opening Dead Air Not Found",
                        "negativeRemarks": "Opening Dead Air Found"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:30:28.000+00:00"
        },
        {
            "momentGroupId": 8,
            "momentGroupName": "Confirmation and Premium",
            "moments": [
                {
                    "momentId": 14,
                    "momentName": "Agent asked Acknowledge",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2023-05-02T02:31:15.000+00:00",
                    "remark": {
                        "remarkId": 916,
                        "positiveRemarks": "Opening Dead Air Not Found",
                        "negativeRemarks": "Opening Dead Air Found"
                    }
                },
                {
                    "momentId": 15,
                    "momentName": "Customer Acknowledge",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T10:01:39.000+00:00",
                    "remark": {
                        "remarkId": 917,
                        "positiveRemarks": "No High ROS",
                        "negativeRemarks": "High ROS"
                    }
                },
                {
                    "momentId": 16,
                    "momentName": "Premium",
                    "momentWeightage": 1,
                    "momentApplicableOn": "ALL",
                    "mentions": [],
                    "updatedAt": "2024-03-19T10:01:39.000+00:00",
                    "remark": {
                        "remarkId": 917,
                        "positiveRemarks": "No High ROS",
                        "negativeRemarks": "High ROS"
                    }
                },
            ],
            "updatedAt": "2023-05-02T02:30:28.000+00:00"
        },
    ],
    socketData : {
        "details": {
            "coeExternalId": 68715092,
            "customerName": "Tim Spencer",
            "date": "Thu, 22 Aug 2024 11:05:38 GMT",
            "liveStatus": true,
            "phone": "9876543210",
            "sellerName": "Bolivar Araya",
            "socketId": 140532256549091,
            "speakers": {
                "0": "sellerName",
                "1": "customerName"
            },
            "streamId": "MFyhg398e21d321ad48e1e6d730bv61f78",
            "talkDuration": 1110,
            "userExternalId": 83862713
        },
        "nudges": [
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2655,
                        "prompt": "Agent properly transfer the call to supervisor",
                        "val": null
                    }
                ],
                "notes": [
                    {
                        "exists": true,
                        "moment": null,
                        "prompt": "Call is recorded, please have a look at nudges for support",
                        "val": null
                    },
                    {
                        "exists": true,
                        "moment": null,
                        "prompt": "Call opening AI notes are generated",
                        "val": null
                    }
                ],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2664,
                        "prompt": "Inbound/Outbound script not found",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2666,
                        "prompt": "Agent assist caller properly",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2667,
                        "prompt": "Agent leave proper voice mail",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2645,
                        "prompt": "Agent use correct verbiage disclose annual percentage rate",
                        "val": null
                    }
                ],
                "notes": [
                    {
                        "exists": true,
                        "moment": null,
                        "prompt": "Required actions AI notes are generated",
                        "val": null
                    }
                ],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2668,
                        "prompt": "Agent verified consumer account by social security and date of birth",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2670,
                        "prompt": "Agent explained payment options",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2671,
                        "prompt": "Agent asked for customer phone number",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2672,
                        "prompt": "Agent setup payment correctly",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2673,
                        "prompt": "Agent does not ask for missing information on application",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2674,
                        "prompt": "Agent breakdown payemnt schedule",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2675,
                        "prompt": "Agent completed third party verification",
                        "val": null
                    }
                ],
                "notes": [
                    {
                        "exists": true,
                        "moment": null,
                        "prompt": "Third party disclosure AI notes are generated",
                        "val": null
                    }
                ],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2676,
                        "prompt": "Agent did not find no breach confidentiality agreement",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2677,
                        "prompt": "Agent found accurate notes as per the conversation",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2678,
                        "prompt": "Agent took consent of recording the call",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2679,
                        "prompt": "Agent doesn't act professional",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2680,
                        "prompt": "Opening dead air found",
                        "val": null
                    }
                ],
                "notes": [
                    {
                        "exists": true,
                        "moment": null,
                        "prompt": "Soft skills AI notes are generated",
                        "val": null
                    }
                ],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2681,
                        "prompt": "Agent is speaking at high ROS",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2682,
                        "prompt": "Dead air not found",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": true,
                        "moment": 2683,
                        "prompt": "No fumbling from agent side",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            },
            {
                "alert": [
                    {
                        "exists": false,
                        "moment": 2684,
                        "prompt": "Assertiveness Found",
                        "val": null
                    }
                ],
                "notes": [],
                "objection": [],
                "prompts": [],
                "reburttal": [],
                "speaker": 0,
                "trans": "dummy trans Outbound"
            }
        ]
    }
} 