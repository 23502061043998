import React from "react";
import { Card } from "react-bootstrap";
import MeetingTrans from "./MeetingTrans";
import Loading from "../../../Commons/Loading";

function MeetingTranscript(props) {
  return (
    <>
      {props?.loader ?
        <div className="loader-container" >
          <Loading />
        </div> :
        <Card>
          <div className="feedback-heading" style={{ margin: '10px 0px 0px 10px' }}>
            <h5 className="mb-0">Transcript</h5>
          </div>
          <Card.Body style={{ padding: '0.5rem 1rem' }}>
            <div className="d-flex">
              <div className="moment-filter-tab-box">
                <MeetingTrans
                  playerRef={props.playerRef}
                  audioTime={props.audioTime}
                  callTranscript={props.callTranscript}
                  time={props.time}
                />
              </div>
            </div>
          </Card.Body>
        </Card>}
    </>
  );
}

export default MeetingTranscript;
