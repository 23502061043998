import React, {useEffect,useState} from 'react'
import CommentOnModal from '../Sales/Modals/CommentOnModal'
import EmailTranscript from '../Sales/Modals/EmailTranscript'
import callsService from '../../Services/calls.service'
import notificationsService from '../../Services/notifications.service'
import toaster from '../../Toast/toaster'

export default function All_action(props) {

  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [mailButton, setMailButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [speaker, setSpeaker] = useState("")
  const [comment, setComment] = useState("")
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [actionId, setActionId] = useState("")
  const [likeDislike, setLikeDislike] = useState([])
  const [loader,setLoader] = useState(false)
  const [toggle,setToggle] = useState(false)

  const likeButtonHandler = (id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "ACTIONS",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"ACTIONS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setLikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"LIKE",
				"tabName":"actions",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res.data.message)
      }
    })
  }
  const dislikeButtonHandler = (id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "ACTIONS",
      "dislike": 0,
      "like": null,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"ACTIONS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"actions",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        toaster.success(res.data.message)
      }
    })
  }
  const mailHandler = ()=>{
    setMailButton(true)
    setEmailShow(true)
  }

  const closeButton = ()=>{
    setCommentShow(false)
    setEmailShow(false)
  }
  
  const commentButtonHandler = (id,comment,speaker)=>{
    // setCommentButton(true)
    setSpeaker(speaker)
    setComment(comment)
    setActionId(id)
    setCommentShow(!commentShow)
  }
  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }

  const repeatedCode = (trans,speaker,comment,id)=>{
    let transNew = Object.entries(props?.callAction?.postTypeComments).map(val=>{
      return(val[1][0].postId===id)
    })
    let ids = transNew.length>0?transNew[0][1]:''
    let likeIcon = likeDislike&&likeDislike.length>0?likeDislike?.filter(val=>{
      return(val.postId==id)
    }):
    props?.likeDislikeData?.filter(val=>{
      return(val.postId==id)
    })

    return(
      <>
        <a className={`${props?.tabSelected=='stats'?'shadow list-group-item-action-trans':""} list-group-item list-group-item-action`}>
          <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1 font-16">
              <i className="bx bx-user-voice me-1"></i>
              {speaker=='seller'?props?.sellerName:props?.customerName}
              <span className="badge rounded-pill bg-light-blue text-dark f-normal">
                {speaker==='seller'?"seller":"customer"}
              </span>
              </h5>
              <small className="text-muted">
              <i className="bx bx-time"></i>
              {/* {time} */}
              </small>
          </div>
          <p className="mb-1">
              {trans}       
          </p>
          <div className="row">
            <div className="col-6">
              <div className="list-after-click-group">
                {likeIcon&&likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button className="btn list-like-after" ><span className=" text-primary-blue"> <i className="bx bx-like"></i></span></button>:
                likeIcon&&likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button className="btn list-dislike-after"><span className=" text-primary-blue"> <i className="bx bx-dislike"></i></span></button>:''}
                {<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(id,comment,speaker)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>}
              </div>
            </div>
            <div className="col-6">
              <div className="list-hover-input-group">
                {likeIcon&&likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id||dislikeButton&&dislikeId==id? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(id,trans)}>
                    <span className="text-primary-blue">
                    <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon&&likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id||likeButton&&likeId==id ? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id,trans)}>
                <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                </span>
                </button>} 
                {/* {ids&&ids[0].postId==id||commentButton&&actionId==id?'':  
                <button className="btn list-comment-before switcher-btn-1" onClick={()=>commentButtonHandler(id,comment,speaker)}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                </span>
                </button>
                }   */}
              </div>
              
            </div>
          </div>
        </a>
        
      </>
    )
  }


  return (
    <div className="list-group moment-list-group-scroll mb-2">
      {props.callAction?props.callAction.postTextData?props.callAction.postTextData.map((item,index)=>{
        return(

        <>
        {props.keyClicked=='seller'&&item.speaker=='seller'?
        repeatedCode(item.postText,item.speaker,item.comments,item.postId[0]):
        props.keyClicked=='customer'&&item.speaker=='customer'?
        repeatedCode(item.postText,item.speaker,item.comments,item.postId[0]):
        props.keyClicked=='all'?
        repeatedCode(item.postText,item.speaker,item.comments,item.postId[0])
        :""}
        </> 
                  
        )
      }):"":""}
        {commentShow && comment!=='' &&<CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props?.tabSelected} comment={comment} speaker={speaker} customerName={props?.customerName} sellerName={props?.sellerName} agentExternalId={props.userExternalId}/>}
        <EmailTranscript show={emailShow} closeButton={closeButton} callAction={props.callAction?props.callAction:""} tabSelected={props.tabSelected} customerName={props?.customerName} sellerName={props?.sellerName} callId={props?.callId} agentId={props?.agentId}/>
    </div>
  );
}