import React, { useEffect, useLayoutEffect, useState } from "react";
import { SETTINGS_COMPONENT_TYPE } from "../../Constants/Enum";
import ReactTable from "../Commons/Table/ReactTable";
import reportConfigService from "../Services/reportConfig.service";
import coeService from "../Services/coe.service";
import dashboardService from "../Services/dashboard.service";
import Filter from "../Filter/Filter";
import PaginationAlter from "../Commons/Table/PaginationAlter";
import Loading from "../Commons/Loading";
import NodataFound from "../Commons/NoDataFound";
import { useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { dateFormat, positionFormat } from "../Commons/DateFormatter";

export default function ManageReportColumn(props){
    const [reportList, setReportList] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [defaultFilteredData, setDefaultFilteredData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [availablePages, setAvailablePages] = useState("")
    const [loader, setLoader] = useState(false)
    const [coeList, setCoeList] = useState([])
    const [dateList, setDateList] = useState([])
    const storedData = JSON.parse(localStorage?.getItem("AGENT"))
    const history = useHistory()
    
    const convertDate = (date,time) => {
        let newDate = date.split(" ")
        let formattedDate = positionFormat(dateFormat(newDate[0]))
            const [hours, minutes, seconds] = newDate[1].split(':');
            const period = +hours < 12 ? 'AM' : 'PM';
            const adjustedHours = (+hours % 12 || 12).toString().padStart(2, '0');
            return `${newDate[0].split("-")[0]} / ${adjustedHours}:${minutes}:${seconds} ${period}`
    }

    const convertFilter = (filter) => {
        const filters = JSON.parse(filter)
        let filteredArray = [[ "From", filters.fromD ],[ "To", filters.toD ],[ "Coe Name", filters.coeName ]];
        return filteredArray;
    }

    const popoverr = (filter,convFilters) =>{
        const filters = JSON.parse(filter)
        let filteredArray = [[ "From Date", filters.fromD ],[ "To Date", filters.toD ],[ "Coe Name", filters.coeName ],[ "CoeExternal Id", filters.coeExternalIds ]];
    
        convFilters.forEach(convFilter => {
            if (filters.hasOwnProperty(convFilter.return_value)) {
                filteredArray.push([ [convFilter.name], filters[convFilter.return_value] ]);
            }
        });

        return (<Popover id="popover-trigger-hover-focus" title="right" style={{"padding":"4px"}}>
            <h3 className="popover-header">Filters Applied</h3>
            <div className="popover-body">
              <div className="popover-body-call">
                {
                  filteredArray.map(filter=>{
                    return(
                      <p className="mb-0">
                        <i className="bx bx-stats font-16 align-middle me-1"/>
                        {`${filter[0]}: ${Array.isArray(filter[1]) ? `[${filter[1]}]` : filter[1]}`}
                      </p>
                    )
                  })}
              </div>
            </div>
          </Popover>)
    }

    const redirectToCalls = (filter, reportType) => {
        const filters = JSON.parse(filter)
    
        const updatedData = {
            ...storedData,  
            "fromD": filters?.fromD ? positionFormat(dateFormat(filters.fromD)) : undefined,
            "toD": filters?.toD ? positionFormat(dateFormat(filters.toD)) : undefined,
            "selectedCOE": filters?.coeName
        };  

        localStorage.setItem("AGENT", JSON.stringify(updatedData));
        const permission = JSON.parse(localStorage.getItem("USER_PERMISSIONS"))
        reportType=="Agent Detail Report" ? history.push('/team') : permission.some(item => item.api === '/voice_detail') ? history.push(`voice?tabRedirected=reports`) : history.push(`sales_conversations?tabRedirected=reports`)
    }

    const column = [
        {
            Header: 'Report Type',
            accessor: 'reportType',

        },
        {
            Header: 'Filters Applied',
            accessor: 'filters',
            Cell:(d)=>
                { 
                    let arr = convertFilter(d.cell.row.original.filters, props.convFilters)
                    return <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(d.cell.row.original.filters, props.convFilters)} rootClose>
                                <div style={{maxWidth:'637px',overflow:'hidden'}}>
                                    {arr.map((filter)=>{
                                        return <span style={{backgroundColor:'rgb(198,197,248)',display:'inline-block',maxWidth:'380px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',verticalAlign:'middle'}} className="badge rounded-pill bg-gradient text-dark active-filter-on-table">{`${filter[0]}: ${Array.isArray(filter[1]) ? `[${filter[1]}]` : filter[1]}`}<a href="javascript:;"></a></span>
                                    })}
                                </div>
                            </OverlayTrigger>
                
            }
        },
        {
            Header: 'Created Date and Time',
            accessor: (d)=>`${d.createdOn}`,
            Cell:(d)=>(
                <div>
                    {d.cell.row.original.createdOn}
                    <span className="mx-2">
                        <a href={d.cell.row.original.reportUrl} title="Download Excel">
                            <i className="bx bx-download font-18 ms-1" style={{color:'#0077B6'}}/>
                        </a>
                    </span>
                    <span onClick={()=>redirectToCalls(d.cell.row.original.filters, d.cell.row.original.reportType)} style={{margin:'0px 3px 0px 3px', cursor:'pointer'}}>
                        <i class='fadeIn animated bx bx-share text-primary-blue' style={{fontSize:'16px'}} title={d.cell.row.original.reportType === 'Agent Detail Report' ? 'View Agent Details' : 'View Calls'}/>
                    </span>
                </div>
            )
        },
    ]

    const dateConvert = (d) => {
        let finalDate = `${d?.split("-")[2]}-${d?.split("-")[1]}-${d?.split("-")[0]}`
        return finalDate
    }

    const callBack = (e)=>{
        setCurrentPage(e)
    }

    const filterData = (e) => {
        setFilteredData(e)
    }

    const defaultReportData = (e) => {
        setDefaultFilteredData(e)
    }

    const getCoes = () => {
        coeService.getCOEList().then(res =>{
            setCoeList(res.data.data)
        })
    }

    const getCoesAdmin = () => {
        dashboardService.getUserCOEList().then(res => {
            setCoeList(res.data.data)
        })
    }

    const getDateList = () => {
        dashboardService.getDateAcronym().then(res => {
          let date = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
          }):'':''
          setDateList(date);
        })
    }

    const getReport = ()=>{
        reportConfigService.getReportList(storedData?.coe?storedData?.coe:coeList?.map(item=>{return item?.externalId}),storedData?.frequency?storedData?.frequency:"MONTHLY",storedData?.fromD?storedData?.fromD:dateConvert(dateList[4]?.toDate),currentPage,storedData?.reportType?storedData?.reportType:"COE Detail Report",storedData?.toD?storedData?.toD:dateConvert(dateList[4]?.fromDate)).then(res=>{
            if(res){
                setLoader(false)
                setReportList(res.data.data?res.data.data?.reportDataResponseList:[])
                setAvailablePages(res?.data?.data?.availablePages)
            }
        })
    }

    useEffect(()=>{
        setLoader(true)
        props?.selectedData(filteredData)
        getReport()
        // if(!storedData?.coe&&!storedData?.fromD&&!storedData?.toD&&JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole=='ADMIN'){
        //     getCoes()
        //     getDateList()
        // }
        // else if(!storedData?.coe&&!storedData?.fromD&&!storedData?.toD&&JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole=='AGENT'){
        //     getCoesAdmin()
        //     getDateList()
        // }
        // else{
        //     getReport()
        // }
        // if(coeList.length>=0){
        //     getDateList()
        //     getReport()
        // }
    },[filteredData,currentPage])

    return (
        <>
            {loader?
                <div className="loader-container">
                    <Loading variant="dark"/>
                </div>:
                reportList.length>0?<div className="view-team-card-details reports">
                    <div className="table-responsive dataTable-style react-table">
                        <ReactTable columns={column} data={reportList} componentType={SETTINGS_COMPONENT_TYPE.MANAGE_REPORTS}/>
                    </div>
                </div>:<NodataFound/>
            }
            <Filter componentType="REPORTS" filterData={filterData} defaultReportData={defaultReportData} name={props.name}/>
            {reportList.length>0&&availablePages>0?<PaginationAlter callBack={callBack} availablePages={availablePages}/>:""}
        </>
    )
}
