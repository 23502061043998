export const BASE_URL = "http://65.108.148.94/odio/";
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
export const AUTHENTICATED = "AUTHENTICATED";
export const USER_DETAIL = "USER_DETAIL";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USER_PERMISSIONS = "USER_PERMISSIONS";
export const COE_LIST = "COE_LIST";
export const MOMENT_BUCKETS = "MOMENT_BUCKETS";

export const conversionFormat = {
 "dd/MM/yy": { format: "dd/MM/yy", value: "DD-MM-YYYY" },
 "MM/dd/yy": { format: "MM/dd/yy", value: "MM-DD-YYYY" },
 "yy/MM/dd": { format: "yy/MM/dd", value: "YYYY-MM-DD" },

 "dd/MM/yyyy": { format: "dd/MM/yyyy", value: "DD-MM-YYYY" },
 "MM/dd/yyyy": { format: "MM/dd/yyyy", value: "MM-DD-YYYY" },
 "yyyy/MM/dd": { format: "yyyy/MM/dd", value: "YYYY-MM-DD" },

 "dd-MM-yy": { format: "dd-MM-yy", value: "DD-MM-YYYY" },
 "MM-dd-yy": { format: "MM-dd-yy", value: "MM-DD-YYYY" },
 "yy-MM-dd": { format: "yy-MM-dd", value: "YYYY-MM-DD" },

 "dd-MM-yyyy": { format: "dd-MM-yyyy", value: "DD-MM-YYYY" },
 "MM-dd-yyyy": { format: "MM-dd-yyyy", value: "MM-DD-YYYY" },
 "yyyy-MM-dd": { format: "yyyy-MM-dd", value: "YYYY-MM-DD" },

 "dd.MM.yy": { format: "dd.MM.yy", value: "DD-MM-YYYY" },
 "MM.dd.yy": { format: "MM.dd.yy", value: "MM-DD-YYYY" },
 "yy.MM.dd": { format: "yy.MM.dd", value: "YYYY-MM-DD" },

 "dd.MM.yyyy": { format: "dd.MM.yyyy", value: "DD-MM-YYYY" },
 "MM.dd.yyyy": { format: "MM.dd.yyyy", value: "MM-DD-YYYY" },
 "yyyy.MM.dd": { format: "yyyy.MM.dd", value: "YYYY-MM-DD" },

 "dd MMM yyyy": { format: "dd MMM yyyy", value: "DD-MM-YYYY" },
 "dd MMMM yyyy": { format: "dd MMMM yyyy", value: "DD-MM-YYYY" },
 "MMMM dd, yyyy": { format: "MMMM dd, yyyy", value: "MM-DD-YYYY" },
 "EEE, MMMM dd, yyyy": { format: "EEE, MMMM dd, yyyy", value: "MM-DD-YYYY" },
 "EEEE, MMMM dd, yyyy": { format: "EEEE, MMMM dd, yyyy", value: "MM-DD-YYYY" },
 "MMM dd, yyyy": { format: "MMM dd, yyyy", value: "MM-DD-YYYY" },
 "yyyy MM dd": { format: "yyyy MM dd", value: "YYYY-MM-DD" },
};

export const TEXT_SVG = `<svg style="width:48px;height:48px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" />
              </svg>`;
export const HYPERLINK_SVG = `<svg style="width:48px;height:48px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
              </svg>`;
export const IMAGE_SVG = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
            </svg>`;
export const VIDEO_SVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path d="M21 7l-6 4.5V7H3v10h12v-4.5l6 4.5V7z" fill="#000"/>
            </svg>`;
export const IFRAME_SVG = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M2 3v18h20V3H2m18 16H4V5h16v14z"/>
              </svg>`;
export const PDF_SVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
<path fill="currentColor" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-9.5 8.5c0 .83-.67 1.5-1.5 1.5H7v2H5.5V9H8c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V9H13c.83 0 1.5.67 1.5 1.5v3zm4-3H17v1h1.5V13H17v2h-1.5V9h3v1.5zM7 10.5h1v1H7v-1zm5 0h1v2h-1v-2z"/>
</svg>`;
export const PPT_SVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
<path fill="currentColor" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8 13H9.5v-2h-2v2H6V9h3c.83 0 1.5.67 1.5 1.5v1.5c0 .83-.67 1.5-1.5 1.5v2.5zm5-4H14v4h-1.5V9H14v1.5h2V15zm4-1.5h-1.5v2H17v-2h1.5V9H16v1.5h4v1.5zM7.5 10.5h1v1h-1z"/>
</svg>`;
