import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import Textarea from "@mui/joy/Textarea";
import { submitCoachingAssessment } from '../Services/coaching.service';
import toaster from '../Toast/toaster';
import { USER_DETAIL } from '../../Constants/constant';
import RecordAnswer from './KnowledgeBase/RecordAnswer';
import CallSemantic from './KnowledgeBase/CallSemantic/CallSemantic';

const CoachingAssessmentUserStats = ({isAgent, questionList, moduleId, sessionId, getCoachingSessionDetail, setLoaderFalse, setLoaderTrue, activeCardId})=> {
    const [selectedAnswers, setSelectedAnswers] = useState({
        moduleId: moduleId,
        sessionId: sessionId,
        answers: []
    });
    const [subjectiveInput, setSubjectiveInput] = useState('');
    const { canRead, canDelete, canWrite, canUpdate } = JSON.parse(localStorage.getItem(USER_DETAIL))?.userPermissions;

    const handleOptionChange = (questionId, option) => {
        setSelectedAnswers((prevAnswers) => {
            const existingAnswer = prevAnswers.answers.find((ans) => ans.questionId === questionId);
    
            if (existingAnswer) {
                const updatedOptions = existingAnswer.optionsSelected.includes(option.id)
                    ? existingAnswer.optionsSelected.filter((id) => id !== option.id)
                    : [...existingAnswer.optionsSelected, option.id];

                if (updatedOptions.length === 0) {
                    return { ...prevAnswers, answers: prevAnswers.answers.filter((ans) => ans.questionId !== questionId) };
                }
    
                return {
                    ...prevAnswers,
                    answers: prevAnswers.answers.map((ans) => ans.questionId === questionId ? 
                        { ...ans, optionsSelected: updatedOptions } : ans ),
                };
            } else {
                return {
                    ...prevAnswers,
                    answers: [
                        ...prevAnswers.answers,
                        {
                            questionId: questionId,
                            questionType: 'OBJECTIVE_WITH_OPTIONS',
                            optionsSelected: [option.id],
                        },
                    ],
                };
            }
        });
    };    
    

    const handleSubjectiveAnswer = (questionId, e, quesType) => {
        const inputValue = e.target.value;
        setSubjectiveInput(inputValue);
    
        setSelectedAnswers((prevAnswers) => {
            const existingAnswer = prevAnswers.answers.find((ans) => ans.questionId === questionId);
    
            if (inputValue.trim() === "") {
                return { ...prevAnswers, answers: prevAnswers.answers.filter((ans) => ans.questionId !== questionId) };
            }
    
            if (existingAnswer) {
                return {
                    ...prevAnswers,
                    answers: prevAnswers.answers.map((ans) => ans.questionId === questionId ? 
                        { ...ans, answer: inputValue } : ans ),
                };
            }
    
            return {
                ...prevAnswers,
                answers: [
                    ...prevAnswers.answers,
                    {
                        questionId: questionId,
                        questionType: quesType,
                        answer: inputValue,
                    },
                ],
            };
        });
    };    

    const handleSubmit = (e) => {
        setLoaderTrue()
        e.preventDefault();
        submitCoachingAssessment(selectedAnswers).then(res=>{
            if(res && res?.status==200){
                toaster.success(res?.data?.message)
                getCoachingSessionDetail(activeCardId)
                setLoaderFalse()
            }
            else{
                toaster.error(res?.message)
                setLoaderFalse()
            }
        })
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {questionList.map((que,queIndex) => (
                    <Card key={queIndex}>
                        <Card.Body>
                            <div key={que.id} className="question-block">
                                <h6>{`Q${que.id}: ${que?.question?.question}`}</h6>
                                {que?.progressStatus === 'COMPLETED' ?
                                    (que?.question?.questionType === 'OBJECTIVE_WITH_OPTIONS' ? 
                                    <div className="options-coaching">
                                        {que?.question?.options?.map((option, index) => (
                                            <label key={index}>
                                                <input 
                                                    type='checkbox'
                                                    name={`question-${que.id}`}
                                                    disabled={true}
                                                    value={option?.option}
                                                    checked={option?.isSelected || false}
                                                />
                                                {option?.option}
                                            </label>
                                        ))}
                                    </div> 
                                    :
                                    <div>
                                        <span className='d-flex'><h6>Ans. </h6>{que?.question?.submittedAnswer}</span>
                                        {que?.question?.questionType === 'SUBJECTIVE_VOICE' && <audio style={{height:'2rem', margin:'0.5rem 1.5rem'}} src={que?.question?.voiceAnswerSignedUrl} controls/>}
                                    </div>)
                                    :
                                    que?.question?.questionType==='OBJECTIVE_WITH_OPTIONS' ? 
                                    <div className="options-coaching">
                                        {que?.question.options.length>0 && que?.question?.options?.map((option, index) => (
                                            <label key={index}>
                                                <input
                                                    type='checkbox'
                                                    // multiple
                                                    disabled={!isAgent}
                                                    name={`question-${que.id}`}
                                                    value={option?.option}
                                                    // checked={selectedAnswers?.optionsSelected?.includes(option?.id)}
                                                    checked={
                                                        selectedAnswers?.answers.find((ans) => ans.questionId === que.id)?.optionsSelected?.includes(option.id) || false
                                                    }
                                                    onChange={() => handleOptionChange(que.id, option)}
                                                />
                                                {option?.option}
                                            </label>
                                        ))}
                                    </div>
                                    :
                                    que?.question?.questionType === 'SUBJECTIVE_VOICE' ? 
                                        <RecordAnswer setSelectedAnswers={setSelectedAnswers} id={que?.id} type={que?.question?.questionType} />
                                    :
                                    que?.question?.questionType === 'CALL_SEMANTIC' ?
                                        <CallSemantic questionId={que?.id} moduleId={moduleId} sessionId={sessionId}  />
                                    :
                                    <div className="description-document">
                                        <Textarea
                                            // {...field}
                                            disabled={!isAgent}
                                            minRows={4}
                                            maxRows={10}
                                            placeholder="Type your answer here..."
                                            sx={{
                                                width: "100%",
                                                fontSize: "16px",
                                            }}
                                            value={ selectedAnswers.answers.find((ans) => ans.questionId === que.id)?.answer || "" }
                                            onChange={(e)=>handleSubjectiveAnswer(que?.id, e, que?.question?.questionType)}
                                        />
                                    </div>
                                    }
                            </div>
                        </Card.Body>
                    </Card>
                ))}
                {questionList?.length > 0 && <Button className='mt-4' style={{backgroundColor: '#1976d2'}} disabled={!isAgent} type="submit">Submit</Button>}
            </form>
            {/* <CallSemantic  moduleId={moduleId} sessionId={sessionId}/> */}
        </div>

    );
}

export default CoachingAssessmentUserStats