import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import TextField from '@mui/material/TextField';
import {Modal, Tooltip } from '@mui/material';
import Select from "react-select";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/joy';
import addQuestionIcon from '../../../assets/images/add-question-icon.png'
import { createQuestions, getEditedQueList, getQuestionsList, updateQuestions } from "../../Services/questionBank.service";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Textarea from "@mui/joy/Textarea";
import toaster from "../../Toast/toaster";
import { apiCall } from "../../Services/Interceptor";
import closeBtn from '../../../assets/images/close_btn.png'
import CloseIcon from '@mui/icons-material/Close';
import { QUESTION_OPTIONS } from "../../../Constants/Enum";
import Loading from '../../Commons/Loading';

const EditQuestionModal = ({openEditQues, type, closeEditQues, qBankId, questionId, qCreatedTrue, fetchQuestionsList, setLoaderTrue})=>{

    const [questionInput, setQuestionInput] = useState('')
    const [subjectiveInput, setSubjectiveInput] = useState('')
    const [quesType, setQuesType] = useState('')
    const [updQuestions, setUpdQuestions] = useState({});
    const [momentList, setMomentList] = useState([])
    const [options, setOptions] = useState([])
    const [momentInput, setMomentInput] = useState([])
    const [loader,setLoader]= useState(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '55%',
        maxHeight: '80vh', // Restrict the height
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        apiCall.get(`/odio/api/moment/moments-list?BucketId=${localStorage.getItem('MOMENT_ID')}&type=SALES`).then(res=>{
            if(res && res?.status==200){
                setMomentList(res?.data?.data)
            }
        })
        if (type === 'Create') {
            setUpdQuestions({
                question: "",
                questionType: 'OBJECTIVE_WITH_OPTIONS',
                options: [],
                subjectiveAnswer: null
            })
            setQuesType({value: 'OBJECTIVE_WITH_OPTIONS', label: 'Objective'})
        }
    }, [type]);

    useEffect(()=>{
        if(openEditQues==true && type=='Edit'){
            setLoader(true)
            getEditedQueList(qBankId, questionId).then(res=>{
                if(res && res.status==200){
                    const originalData = res?.data?.data;
                
                    // Safely map through the options array to exclude 'id'
                    const updatedOptions = originalData?.options?.map(({ id, ...rest }) => rest) || [];
                    // Remove `id` from `options` array inside `updQuestions` itself, if it exists
                    const updatedUpdQuestions = {
                        ...originalData,
                        momentIds: originalData?.momentIds?.map((moment=>moment?.momentId)) || [],
                    };
                    
                    setUpdQuestions(updatedUpdQuestions)
                    setOptions(updatedOptions);
                    setQuesType(res?.data?.data?.questionType == 'SUBJECTIVE_TEXT' ? {value: 'SUBJECTIVE_TEXT', label: 'Text Subjective'} : res?.data?.data?.questionType=='SUBJECTIVE_VOICE' ? {value: 'SUBJECTIVE_VOICE', label: 'Voice Subjective'} : res?.data?.data?.questionType == 'SUBJECTIVE_TEXT' ? {value: 'CALL_SEMANTIC', label: 'Call Semantic'} : {value: 'OBJECTIVE_WITH_OPTIONS', label: 'Objective'})
                    setSubjectiveInput(res?.data?.data?.answer)
                    setMomentInput(res?.data?.data?.momentIds)
                    setLoader(false)
                }
            })
        }
    },[])


    const handleChange = (e) => {
        setQuestionInput(e.target.value);
        setUpdQuestions({...updQuestions, question:e.target.value})
    };

    const handleSubjectiveAnswer = (e) => {
        setSubjectiveInput(e.target.value);
        setUpdQuestions({...updQuestions, answer:e.target.value})
    };

    const handleTypeChange = (value)=>{
        setQuesType(value)
        setSubjectiveInput('')
        setUpdQuestions({...updQuestions, questionType: value?.value})
    }

    const onOptionsEdit = (value, index)=>{
        let newEditedOptions = [...options];
        newEditedOptions[index] = {
            ...newEditedOptions[index],
            option: value // Update the 'name' field
        };

        // Update the local state
        setOptions(newEditedOptions);
        setUpdQuestions({...updQuestions, options:newEditedOptions})
    }

    const removeOption = (indexToRemove)=>{
        // Create a new array for data where the item at indexToRemove is updated
        const updatedOptions = options?.filter((_, index) => index !== indexToRemove);
        setUpdQuestions({
            ...updQuestions,
            options: updatedOptions
        });
        setOptions(updatedOptions)
    }
    
    const addOptions = () => {
        if (options?.filter(opt => opt?.option?.trim()).length >= 5) {
          return; // Prevent adding more than 5 options
        }
      
        const newOption = {
          option: '', // Default new option
          isCorrect: false, // Default correctness flag
        };
      
        const updatedOptions = [...options, newOption];
      
        setUpdQuestions({
          ...updQuestions,
          options: updatedOptions,
        });
        setOptions(updatedOptions);
      };
        

    const handleCorrectOptionChange = (selectedOptions)=>{
        const updatedOptions = options.map(opt => ({
            ...opt,
            isCorrect: selectedOptions.includes(opt.option), // Mark selected options as correct
        }));
        setUpdQuestions(prevState => ({
            ...prevState,
            options: updatedOptions,
        }));
        setOptions(updatedOptions)
    }

    const handleMomentChange = (value)=>{
        setMomentInput(value)
        setUpdQuestions(prevState => ({
            ...prevState,
            momentIds: value?.map(mom=>mom?.momentId),
        }));
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        if(updQuestions?.question=='' || options.some(opt=>opt.option==='') || options.some(opt=>opt.isCorrect==null)){
            toaster.error(`Please fill ${updQuestions?.question==='' ? 'question': options.some(opt=>opt.option==='') ?"option" : "correct option"} field`)
            return
        }
        if(questionId){
            delete updQuestions?.id
            updateQuestions(qBankId, questionId, updQuestions).then(res=>{
                if(res && res.status==200){
                    setLoaderTrue()
                    toast.success('Question updated successfully')
                    closeEditQues()
                    fetchQuestionsList()
                }
            })
        }
        else{
            createQuestions(qBankId, updQuestions).then(res=>{
                if(res && res.status==200){
                    toast.success(res?.data?.message)
                    setOptions([])
                    closeEditQues()
                    qCreatedTrue()
                    fetchQuestionsList()
                }
            })
        }
    }
    
    return(
        <Modal
            open={openEditQues}
            // onClose={closeEditQues}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography>
                    <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>
                        <h4>
                            {`${type} Question`}
                        </h4>
                        <span>
                            <CloseIcon onClick={closeEditQues} style={{cursor:'pointer'}}/>
                        </span>
                    </div>
                    <div className='' style={{marginBottom:'15px', marginLeft:'15px', width:'200px'}}>
                        {type!=='Edit'&&<Select 
                            options ={QUESTION_OPTIONS}
                            name="questionType"
                            placeholder="Question Type"
                            type="text"
                            value={quesType}
                            onChange={handleTypeChange} 
                        /> }
                    </div>
                    <hr/>
                   {loader ? 
                    <div className="loader-container">
                        <Loading variant="dark" />
                    </div>
                :  <Fragment>
                    {quesType?.label === 'Text Subjective' || quesType?.label==='Voice Subjective' ? (
                        <Fragment>
                            <div className='knowledge-editable-question'>
                                <div className='first-editable'> 
                                    <span>{`1.`}</span>  
                                    <input 
                                        name="question"
                                        placeholder="Write Question here*"
                                        type="text"
                                        value={updQuestions.question || ''}
                                        onChange={handleChange} 
                                    /> 
                                    {/* <i onClick={() => removeQuestion()}> <DeleteIcon/></i> */}
                                </div>
                            </div>
                            <div className="description-document">
                                <Textarea
                                    // {...field}
                                    minRows={4}
                                    maxRows={10}
                                    placeholder="Type your answer here..."
                                    sx={{
                                        width: "100%",
                                        fontSize: "16px",
                                    }}
                                    value={subjectiveInput}
                                    onChange={handleSubjectiveAnswer}
                                />
                            </div>
                        </Fragment>

                    ) : quesType?.label === 'Call Semantic' ? (
                        <Fragment>
                            <div className='knowledge-editable-question'>
                                <div className='first-editable'> 
                                    <span>{`1.`}</span>  
                                    <input 
                                        name="question"
                                        placeholder="Write Question here*"
                                        type="text"
                                        value={updQuestions.question || ''}
                                        onChange={handleChange} 
                                    /> 
                                    {/* <i onClick={() => removeQuestion()}> <DeleteIcon/></i> */}
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className='knowledge-editable-question'>
                                <div className='first-editable'> 
                                    <span>{`1.`}</span>  
                                    <input 
                                        name="question"
                                        placeholder="Write question here*"
                                        type="text"
                                        value={type==='Create' ? questionInput : updQuestions?.question || ''}
                                        onChange={handleChange} 
                                    /> 
                                    {/* <i onClick={() => removeQuestion()}> <DeleteIcon/></i> */}
                                </div>
                            </div>
                                <div className='first-input-div'>
                                {options && options.map((opt,id)=>(
                                    <div className='second-input-child'>
                                        <input 
                                            name="option"
                                            placeholder="Write Option here*"
                                            type="text"
                                            value={opt.option || ''}
                                            onChange={(e)=>onOptionsEdit(e.target.value, id)} 
                                        />
                                        {options?.length>2 && <i onClick={() => removeOption(id)}> <Tooltip title="Delete Folder Name"><DeleteIcon/></Tooltip></i>}
                                    </div>   
                                ))}
                                </div>
                            {/* <span className="add-option-span" onClick={() => addOptions()}>Add Option</span> */}
                            <span className="add-option-span" 
                            onClick={addOptions} 
                            style={{ cursor: options?.length >= 5 ? 'not-allowed' : 'pointer', color: options?.length >= 5 ? 'gray' : 'blue',}} 
                            disabled={options?.length >= 5} // Disable interaction when reaching max 
                            >
                                Add Option
                            </span>
                        </Fragment>
                    )}
                    {/* {props.type=='create' ? <span className="add-question-span" onClick={() => addQuestion()}><img src={addQuestionIcon} alt="Add Question" width="3%" />&nbsp;Add Question</span> : null} */}
                    <div style={{display:'flex'}}>
                        {quesType?.label === 'Objective' && (
                            <div className="text-area">
                                <p className="mb-0">
                                    <label style={{fontSize:'13px'}}>Select Correct Option</label>
                                </p>
                                <Autocomplete
                                    multiple
                                    options={options?.map((opt) => opt.option) || []}
                                    getOptionLabel={(option) => option}
                                    filterSelectedOptions
                                    value={options?.filter(opt => opt.isCorrect).map(opt => opt.option) || []}
                                    onChange={(event, value) => handleCorrectOptionChange(value)}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Tags"
                                        // error={!!errors.tags}
                                        // helperText={errors.tags ? errors.tags.message : ""}
                                    />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div style={{marginTop:'20px'}}>
                            <p className="mb-0">
                                <label style={{fontSize:'13px'}}>Select Moments</label>
                            </p>
                            <Autocomplete
                                multiple
                                options={momentList || []}
                                getOptionLabel={(option) => option.momentName}
                                filterSelectedOptions
                                // disabled={actionType=='view'}
                                onChange={(event, value) => handleMomentChange(value)}
                                value={momentInput || []}
                                isOptionEqualToValue={(option, value) =>
                                    option.momentId === value.momentId
                                }
                                renderInput={(params) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <TextField
                                                {...params}
                                                placeholder="Select Moments"
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                        gap: '8px',
                                                    },
                                                    '& input': {
                                                        // minWidth: '40px',
                                                        flexGrow: 1,
                                                    },
                                                }}
                                                InputProps={{
                                                ...params.InputProps,
                                                endAdornment: null, 
                                            }}
                                            />
                                        </div>
                                        
                                        <div style={{ 
                                            width: '55px', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            position: 'relative',
                                            top: 0 
                                        }}>
                                            <div className="MuiAutocomplete-endAdornment" style={{ top: 0, borderTop: '1px solid black',borderRight: '1px solid black',borderBottom: '1px solid black', }}>
                                                {params.InputProps.endAdornment}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    <hr />
                    <div>
                        <button 
                        className="btn px-4 lh-base update-btn" 
                        onClick={handleSubmit} 
                        disabled={
                            !updQuestions?.question?.trim() || // Check if the question exists and is non-empty
                            (quesType?.label === 'Text Subjective' || quesType?.label === 'Voice Subjective') && !subjectiveInput?.trim() || // Validate subjective input
                            (quesType?.label === 'Objective' && (
                                (options?.filter(opt => opt?.option?.trim()).length < 2 || // Ensure at least 2 valid options
                                options?.filter(opt => opt?.option?.trim()).length > 5) // Ensure no more than 5 options
                                )
                            )}>
                            Save
                        </button>
                    </div>
                    </Fragment>}
                </Typography>
            </Box>
        </Modal>
    )
}

export default EditQuestionModal