import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css'
import './assets/css/bootstrap-extended.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, {history, persistor} from './redux-container/store'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router';
import './assets/css/app.css'
import './assets/css/icons.css'
import { PersistGate } from 'redux-persist/integration/react';

const version = process.env.REACT_APP_VERSION || '1';

ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);


reportWebVitals();

// ssh -i odio_server root@65.108.148.94
 //sh build.sh
 //rm -rf package-lock.json
