import React, { useEffect, useState, useRef } from 'react';
import '../../Meeting/meeting.css'
import { Width } from 'devextreme-react/chart';

function MeetingTrans({ callTranscript, audioTime, time, playerRef }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const transcriptRef = useRef(null);
  const activeItemRef = useRef(null);

  const handlePlay = (index, time) => {
    setActiveIndex(index);
    audioTime(time);
    playerRef.current.play();
  };

  const handlePause = () => {
    playerRef.current.pause();
  };

  const scrollToActiveItem = () => {
    if (transcriptRef.current) {
      const container = transcriptRef.current;
      const activeItem = container.children[activeIndex];
      if (activeItem) {
        const containerHeight = container.clientHeight;
        const itemTop = activeItem.offsetTop;
        const itemHeight = activeItem.clientHeight;

        // Calculate the scroll position to center the active item
        const scrollPosition = itemTop - (containerHeight / 2) + (itemHeight / 2);

        container.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  useEffect(() => {
    scrollToActiveItem();
  }, [activeIndex]);

  useEffect(() => {
    if (time) {
      const currentTime = parseTimeToSeconds(time);
      const newActiveIndex = callTranscript.sequence.findIndex((item, index) => {
        const itemTime = parseTimeToSeconds(item.time);
        const nextItemTime = index < callTranscript.sequence.length - 1
          ? parseTimeToSeconds(callTranscript.sequence[index + 1].time)
          : Infinity;
        return itemTime <= currentTime && currentTime < nextItemTime;
      });
      if (newActiveIndex !== -1 && newActiveIndex !== activeIndex) {
        setActiveIndex(newActiveIndex);
      }
    }
  }, [time, callTranscript]);

  const parseTimeToSeconds = (timeString) => {
    return timeString.split(':').reduce((acc, time) => (60 * acc) + parseInt(time), 0);
  };

  const TranscriptItem = ({ item, index }) => {
    
    return (

      <a
        className={`list-group-item list-group-item-action ${index === activeIndex ? 'list-group-video-trans' : ''}`}
        ref={index === activeIndex ? activeItemRef : null}
      >
        <div className="d-flex w-100 justify-content-between">
          <div style={{width : "85%"}}>
            <h5 className="mb-1 font-16">
              <i className="bx bx-user-voice me-1"></i>
              {/* {item.speaker === 'seller' ? callTranscript.sellerName : callTranscript.customerName} */}
              {item.speakerName}
              {/* <span className="badge rounded-pill bg-light-blue text-dark f-normal">{item.speaker}</span> */}
            </h5>
            <p className="mb-1">
            {item.trans}
            </p>
          </div>
          <small className="text-muted" style={{width : "14%"}}>
            <i className="bx bx-time"></i>
            {item.time}
            <span className='d-flex justify-content-end'>
            <button className='meeting-play-pause-button' title='Play' onClick={() => handlePlay(index, item.time)}>
              <i class='bx bx-play meeting-play-pause-icon'></i>
            </button>
            <button className='meeting-play-pause-button' title='Pause' onClick={handlePause}>
            <i class='bx bx-pause meeting-play-pause-icon'></i>
            </button>
            </span>
          </small>
        </div>
      </a>
    )
  };

  return (
    <div className="list-group moment-list-group-scroll mb-2" style={{ height: '350px', overflowY: 'auto' }} ref={transcriptRef}>
      {callTranscript?.sequence?.map((item, index) => (
        <TranscriptItem key={item.index} item={item} index={index} />
      ))}
    </div>
  );
}

export default MeetingTrans;