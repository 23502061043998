import "./Pages.css"
import { useEffect, useState } from "react"
import { useSelector,useDispatch} from "react-redux"
import { useHistory } from "react-router-dom"
import logoIcon from "../assets/images/logoIcon.png"
import odio_logo_blue from "../assets/images/odio_logo_blue.png"
import { apiCall } from "../Components/Services/Interceptor"
import toast from "../Components/Toast/toaster"
import Loading from "../Components/Commons/Loading"
import { header } from "../redux-container/login/action"

const GLIDUpload = ()=>{

    const [uploadedFile, setUploadedFile] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const handelFileUpload = async(e)=>{
        const selectedFile = e.target.files[0];
        setUploadedFile(selectedFile)
        const fileNameParts = selectedFile.name.split('.');
        if (fileNameParts.length > 2&&fileNameParts[fileNameParts.length-1]||selectedFile.type!='text/csv') {
            setErrorMessage("Upload csv files only!")
            return;
        }
        else{
            setErrorMessage('')
        }
    }

    const handleClick = (e)=>{
        e.target.value=null
    } 

    const onSubmitHandler = async()=>{
        setLoading(true)
        let UPLOAD_FILE = '/odio/api/reports/uploadFile/glid'
        let formData = new FormData();
        formData.append("file", uploadedFile);
        await apiCall.post(UPLOAD_FILE, formData).then(res=>{
            if(res.data.status!=0){
                toast.error(res.data.message)
                setLoading(false)
            }
            else{
                if(uploadedFile!=''){
                    setLoading(false)
                    toast.success("Uploaded correctly")
                    setUploadedFile('')
                }
                else{
                    toast.error("No file selected")
                    setLoading(false)
                }
            }
        });
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1000);
    }

    useEffect(()=>{
        document.title = 'Odio'
    },[])
    

    // window.addEventListener("popstate", e => {
    //     // Nope, go back to your page
        
    //     // this.props.history.go(1);
    // });

    return(
        <>
        <div className='wrapper forget-content'>
            <div>
                <div className='odio-header'>
                    <div>
                        <img src={logoIcon} className="logo-icon-page" alt="logo icon"/>
                    </div>
                    <div>
                        <h4 className='odio-logo-text'>
                            <img src={odio_logo_blue} className='logo-imgg' alt='logo-icon'/>
                        </h4>
                    </div>
                </div>
                <div className="page-header" id="upload">
                    <h6 style={{color:"#272727"}}>UPLOAD GLID CSV</h6>    
                </div>
                <div className='call-upload' id="upload-glid">
                    <div className="bg-image">
                        <div className="table-heading">
                            Example CSV
                            <table className="center-table table-bordered" >
                                <thead>
                                    <tr>
                                        <th>GLID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1000125</td>
                                    </tr>
                                    <tr>
                                        <td>1000122</td>
                                    </tr>
                                    <tr>
                                        <td>1000123</td>
                                    </tr>
                                    <tr>
                                        <td>1000121</td>
                                    </tr>
                                    <tr>
                                        <td>1000122</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mx-1 file-upload-content">
                            <fieldset>
                                <label for="fileselect">Drop your file here or Browse
                                    <br/>Supported formats: .csv</label>
                                <input type="file" id="fileselect" name="fileselect" multiple="multiple" accept=".csv" onChange={handelFileUpload} onClick={handleClick}/>
                            </fieldset>
                                {uploadedFile!=''?<div className="uploaded-file">
                                    <p className="mb-0"><b>File to Upload:</b>
                                        {uploadedFile.name}
                                        <span style={{color:'red',marginLeft:"12px",fontWeight:"bold"}}>{errorMessage}</span>
                                    </p>
                                    
                                </div>:''
                                }
                            {/* <div style={{color:'red'}}>{errorMessage}</div> */}
                        </div>
                    </div>
                </div>
                <div className="page-footer-odio" id="uploadGLID">
                    <button type="submit" className="btn btn-lg sign-btn" onClick={onSubmitHandler} disabled={errorMessage!=''}>
                        {loading?<Loading variant="light"/>: <>Submit</>}
                    </button>
                    {/* <button type="button" className="btn btn-secondary">
                        Close
                    </button> */}
                </div>
            </div>
        </div>
        </>
    )
}

// const mapStateToProps = (state) => {
//     return {
//       authenticate: state.login.loggedIn,
//       userPermission: state.login.userPermission,
//     };
//   };

// const mapDispatchToProps = (dispatch) => {
//     return {
//       headerShow:()=>dispatch(header())
//     };
// };

export default GLIDUpload