import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import { Card } from 'react-bootstrap'

function MeetingParticipants({ participants }) {
  
  return (
    <>
      <div className="tab-pane" role="tabpanel">
        <div className="d-flex align-items-center feedback-headder my-2">
          <div className="feedback-heading">
            <h5>Participants</h5>
          </div>
        </div>
      </div>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {participants && Object.values(participants).map((participant, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={participant} />
                  </ListItemAvatar>
                  <ListItemText
                      primary={participant}
                      sx={{ marginTop: '0.8rem' }}
                      primaryTypographyProps={{ style: { color: '#32393f' } }}
                  />

                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
        </Card.Body>
      </Card>
    </>
  )
}

export default MeetingParticipants