import { Form, Accordion } from "react-bootstrap";
import userService from "../../Services/user.service";
import { ListGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import React from "react";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import Select from 'react-select';
import { USER_DETAIL } from "../../../Constants/constant"
import dashboardService from "../../Services/dashboard.service";
import conversationService from "../../Services/conversation.service";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import callsService from "../../Services/calls.service";
import { useLocation } from "react-router-dom";
import toaster from "../../Toast/toaster";
import momentService from "../../Services/moment.service";

export default function ConvFilter(props){
    let userRole = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    let moment_bucket_id = JSON.parse(localStorage.getItem("MOMENT_ID"))
    
    const [agentExternalId, setAgentExternalId] = useState("")
    const[coeList, setCoeList] = useState()
    const [momentList, setMomentList] = useState()
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const [momentBucketList, setMomentBucketList] = useState()
    const [moments,setMoments] = useState(storedData?.moment?{label:storedData?.moment.length==1?storedData?.moment[0]:"Select All",value:storedData?.moment.length==1?storedData?.moment[0]:"Select All"} :"")
    const [selectedCOE, setSelectedCOE] = useState(storedData?.selectedCOE?storedData?.selectedCOE.length==1?storedData?.selectedCOE[0]:"Select All":'')

    const [sellerROSState, setSellerROSState] = useState(storedData?.sellerROS?storedData?.sellerROS:[0,10])
    const [momentBucket, setMomentBucket] = useState(storedData && storedData.momentBucketId ? storedData.momentBucketId : moment_bucket_id)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let fromDate = urlParams.get("fromDate")
    let callRange = urlParams.get('callScore')
    let durationRange = urlParams.get('callDuration')
    let momentId = parseInt(localStorage.getItem("MOMENT_ID")) || undefined
    let dateFormat = JSON.parse(localStorage.getItem("TIME_FORMAT")).value
    // let filterChange = JSON.parse(localStorage.getItem('USER_PERMISSIONS')).some(item => item.name === "Questions");
    const location = useLocation();
    
    const [callScoreState, setCallScoreState] = useState(storedData?.cScore?storedData?.cScore:callRange?callRange.split('-'):[0,100])
    const [callDurationState, setCallDurationState] = useState(storedData?.callDuration?storedData?.callDuration:durationRange?[(durationRange.split('-')[0]/60).toFixed(2),(durationRange.split('-')[1]/60).toFixed(2)]:[0,100])
    const [pitchScoreState, setpitchScoreState] = useState([0,100])
    const [convProbState, setConvProbState] = useState([0,100])
    const [oppState, setOppState] = useState([0,100])
    const [urgencyState, setUrgencyState] = useState([0,100])

    const [customerName, setCustomerName] = useState(storedData?.customerName?storedData?.customerName: [])
    const [customerMobile, setCustomerMobile]  =useState(props?.historyObject?.agentPhone?props?.historyObject?.agentPhone:storedData?.customerMobile?storedData?.customerMobile:[])
    const [callHealth, setCallHealth] = useState(storedData?.callHealth?[{label:storedData?.callHealth==1?"Fatal":"Non-Fatal",value:storedData?.callHealth}]:"")
    const crrDate = new Date()
    let firstDay = new Date(crrDate.getFullYear(), crrDate.getMonth(), 1);  
   
    const [from,setFrom] = useState(storedData&&storedData.fromD?new Date(`${storedData?.fromD.split("-")[1]}-${storedData?.fromD.split("-")[0]}-${storedData?.fromD.split("-")[2]}`):firstDay)
    const [to,setTo] = useState(storedData&&storedData.toD?new Date(`${storedData?.toD.split("-")[1]}-${storedData?.toD.split("-")[0]}-${storedData?.toD.split("-")[2]}`):crrDate)
    const [words,setWords] = useState(storedData?.words?storedData?.words:"")
    const [filteredData, setFilteredData] = useState([])
    let role =JSON.parse(localStorage.getItem(USER_DETAIL))?.userRole
    const [value, setValue] = useState(storedData?.selectedSeller?storedData?.selectedSeller:'');
    const [suggestions, setSuggestions] = useState([]);
    const [customerVal, setCustomerValue] = useState(storedData?.customerName?storedData?.customerName[0]:'')
    const [customerSuggestions, setCustomerSuggestions] = useState([])
    const [dateList, setDateList] = useState("")
    const [fileName, setfileName] = useState(storedData?.fileName?storedData?.fileName:"")
    const [email, setEmail] = useState(storedData?.email?storedData?.email:"")
  
    const inputProps = {
    //   placeholder: "Select Manager",
      value,
      style: { padding: '0 5px', boxSizing: 'border-box' },
      onChange: (_, { newValue, method }) => {
        setValue(newValue);
        let newData = newValue.length>3?newValue.split("("):""
        setFilteredData((filteredData)=>({...filteredData,selectedSeller:newValue}))
        // setFilteredData({...filteredData,agentName:value})
      },
    };
  
    const getSuggestions = (value) => {
        if(value.length < 3){
            setSuggestions([]);
        }
        else {
            var data = {
            val: value,
            status: "ACTIVE",
            coeId: null,
            };
            props?.disableAgentApply(true)
            let promise = userService.getUserSuggestions(data);
            promise.then((res) => {
                if(res.length==0){
                    setFilteredData((filteredData)=>({...filteredData,agentName:[]}))
                    props?.disableAgentApply(false)
                }
            setSuggestions(res);
            //var newList = res.filter((item) => item.id != inputs.userId);
            //setSuggestions(newList);
            })
            .finally(()=>{
                props?.disableAgentApply(false)
            });
        }
    };
  
    const getSuggestionValue = (suggestion) => {
        location.pathname !== '/email' ? 
        setFilteredData((filteredData)=>({...filteredData,agentName:[suggestion.id],agentExId:[suggestion.userExternalId],emailAgentName : suggestion.firstName})) : 
        setFilteredData((filteredData)=> ({...filteredData, emailAgentName : suggestion.firstName ,agentName:[suggestion.id], agentExId:[suggestion.userExternalId] }))
        // setAgentExternalId(suggestion.userExternalId)
        // setFilteredData({...filteredData,agentName:suggestion.userExternalId})
      return `${suggestion.firstName}(${suggestion.employeeId})`;
    };
  
    const renderSuggestion = (suggestion) => (
      <ListGroup>
        <ListGroup.Item key={suggestion.id}>
          {`${suggestion.firstName}(${suggestion.employeeId})`}
        </ListGroup.Item>
      </ListGroup>
    );
  
    const onSuggestionsFetchRequested = ({ value }) => {
        const extractedName = value.split('(')[0].trim();
    
        getSuggestions(extractedName);
    };
    
  
    const onSuggestionsClearRequested = () => {
        if(value==''){
            setSuggestions([]);
            setFilteredData((filteredData)=>({...filteredData,agentName:[],agentExId:[]}))
        }
    };



    const inputCustomerProps = {
        value:customerVal,
        onChange: (_, { newValue, method }) => {
            setCustomerValue(newValue);
        setFilteredData((filteredData)=>({...filteredData,customerName:[newValue]}))
        },
    };

    const getCustomerSuggestionValue = (suggestion) => {
      return `${suggestion}`;
    };
   
    const renderCustomerSuggestion = (suggestion) => (
      <ListGroup>
        <ListGroup.Item>
          {`${suggestion}`}
        </ListGroup.Item>
      </ListGroup>
    );

    const getCustomerSuggestion = (value)=>{
        if(value.length < 3){
            setCustomerSuggestions([]);
        }
        else {
            let promise = callsService.getCustomerSuggestions(value);
            promise.then((res) => {
                if(res.length==0){
                    setFilteredData((filteredData)=>({...filteredData,customerName:[value]}))
                }
                if(res.data.status === 0)
            setCustomerSuggestions(res?.data?.data?.customerName );
            });
        }
    }

    const onSuggestionsFetchRequestedCustomer = ({value})=>{
        getCustomerSuggestion(value)
    }

    const onSuggestionsClearRequestedCustomer = ()=>{
        if(customerVal==''){
            setCustomerSuggestions([]);
            setFilteredData((filteredData)=>({...filteredData,customerName:[]}))
        }
    }



    const sellerROS = [
        {
          value: 0,
          scaledValue: 0,
          label: "0",
        },
        {
          value: 10,
          scaledValue: 10,
          label: "10 WPS",
        },
      ];

    const callScore = [
        {
          value: 0,
          scaledValue: 0,
          label: "0%",
        },
        {
          value: 100,
          scaledValue: 100,
          label: "100%",
        },
      ];

    const callDuration = [
        {
            value: 0,
            scaledValue: 0,
            label: "0 Min.",
          },
          {
            value: 100,
            scaledValue: 100,
            label: "100 Min.",
          },
    ]

    const pitchScore = [
        {
          value: 0,
          scaledValue: 0,
          label: "0%",
        },
        {
          value: 100,
          scaledValue: 100,
          label: "100%",
        },
      ];

    const convProb = [
        {
          value: 0,
          scaledValue: 0,
          label: "0%",
        },
        {
          value: 100,
          scaledValue: 100,
          label: "100%",
        },
      ];

    const opportunity = [
        {
          value: 0,
          scaledValue: 0,
          label: "0%",
        },
        {
          value: 100,
          scaledValue: 100,
          label: "100%",
        },
      ];

    const urgency = [
        {
          value: 0,
          scaledValue: 0,
          label: "0%",
        },
        {
          value: 100,
          scaledValue: 100, 
          label: "100%",
        },
    ];

    useEffect(()=>{
        props.callBackFunc(filteredData)
    },[filteredData])
    
    function getMomentBuckets() {
        momentService.getMomentBuckets(props?.name).then(res => {
          setMomentBucketList(res ? res : '');
          localStorage.setItem("MOMENT_BUCKETS", localStorage.getItem("MOMENT_BUCKETS") ? localStorage.getItem("MOMENT_BUCKETS") : res[0].momentBucketName)
          localStorage.setItem("MOMENT_ID", JSON.parse(localStorage.getItem("MOMENT_ID")) ? JSON.parse(localStorage.getItem("MOMENT_ID")) : res[0].momentBucketId)
          dashboardService.getMomentCOEList(JSON.parse(localStorage.getItem("AGENT"))?.momentBucketId ? JSON.parse(localStorage.getItem("AGENT"))?.momentBucketId : res[0].momentBucketId).then(res => {
            setCoeList(res ? res.data.data ? res.data.data : '' : '');
            localStorage.setItem("COE_DATA",JSON.stringify(res?.data?.data))
          })
        })
      }

    function getDateList () {
        dashboardService.getDateAcronym().then(res => {
          let date = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
          }):'':''
          
          setDateList(date);
          let dd = date[4]?.fromDate.split("-")[2]
          let mm = date[4]?.fromDate.split("-")[1]
          let yyyy = date[4]?.fromDate.split("-")[0]
          let dateF = `${dd}-${mm}-${yyyy}`
          let dd_1 = date[4]?.toDate.split("-")[2]
          let mm_1= date[4]?.toDate.split("-")[1]
          let yyyy_1= date?.[4]?.toDate.split("-")[0]
          let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
          
          let arr = ['Jan','Feb','March','April'] 
          let names = [], ids = [], externalId= [];
         if(role=== 'ADMIN') 
            {   names = coeList?.map(item=>{return item.coeName})
                ids = coeList?.map(item=>{return item.coeId})
                externalId = coeList?.map(item=>{return item.externalId})
            }
          
          localStorage.setItem("AGENT",JSON.stringify( 
            {
            "pageSelected":storedData?.pageSelected,
            "analysis":storedData?.analysis,
            "productivity":storedData?.productivity,
            "fromD":storedData&&storedData.fromD?storedData.fromD:dateT,
            "toD":storedData&&storedData.toD?storedData.toD:dateF,
            "agentDetailCOE":storedData?.agentDetailCOE,
            "selectedRange":storedData?.selectedRange,
            "selectedDate":storedData?.selectedDate, 
            "userIds":storedData?.userIds,
            "userNames":storedData?.userNames, 
            "coe":storedData?.coe,
            "selectedCoeRange":storedData?.selectedCoeRange?storedData?.selectedCoeRange:ids,
            "selectedCOE":storedData?.selectedCOE?storedData?.selectedCOE:names,
            "momentBucket":storedData?.momentBucket,
            "momentBucketId":storedData?.momentBucketId,
            "customerName":storedData?.customerName,
            "customerMobile":storedData?.customerMobile,
            "fileName":storedData?.fileName,
            "sellerROS":storedData?.sellerROS,
            "sellerName":storedData?.sellerName,
            "selectedSeller":storedData?.selectedSeller,
            "cScore":storedData?.cScore,
            "callHealth":storedData?.callHealth,
            "callDuration":storedData?.callDuration,
            "words":storedData?.words,
            "moment":storedData?.moment,
            "momentId":storedData?.momentId,
            // "coeName":this.state.coe,
            "pitch":storedData?.pitch,
            "prob":storedData?.prob,
            "opportunity":storedData?.opp,
            "urgency":storedData?.urgency,
            "frequency":storedData?.frequency,
            "reportType":storedData?.reportType,
            "email":storedData?.email,
            "emailAgentName":storedData?.emailAgentName
        }))
          props.defaultCallFilter({fromDate:storedData&&storedData.fromD?storedData.fromD:dateT,toDate:storedData&&storedData.toD?storedData.toD:dateF,selectedCOE:storedData?.selectedCOE?storedData?.selectedCOE:names,selectedCoeRange: storedData?.selectedCoeRange?storedData?.selectedCoeRange: ids})

       
        })
    }
    useEffect(()=>{
        getDateList()
    },[])

    const sellerROSChange = (e) => {
        setSellerROSState(e.target.value)
        setFilteredData({...filteredData,sellerROS:e.target.value})
    };


    const callScoreChange = (e)=>{
        setCallScoreState(e.target.value)
        setFilteredData({...filteredData,callScoreRange:e.target.value})
    }
    const handleFromDate = (e)=>{
        const currentDate = new Date();
        let selectedDate = new Date(e);

        // Check if the selected date is in the future
        if (selectedDate > currentDate) {
            selectedDate = currentDate;
            toaster.error("Please select valid date range.")
        }

        // Check if the selected start date is after the end date
        if (to && selectedDate > new Date(to)) {
            selectedDate = new Date(to);
            toaster.error("Please select valid date range.")
        }
        setFrom(selectedDate)
        let date = `${((new Date(e)).getDate())<10?'0'+(new Date(e)).getDate():(new Date(e)).getDate()}-${((new Date(e)).getMonth()+1)<10?'0'+((new Date(e)).getMonth()+1):((new Date(e)).getMonth()+1)}-${(new Date(e)).getFullYear()}`;
        setFilteredData({...filteredData,from:date,selectedFrom:date,selectedDate:"Custom Date",selectedRange:9})
    }
    const handleToDate = (e)=>{
        const currentDate = new Date();
        let selectedDate = new Date(e);

        // Check if the selected date is in the future
        if (selectedDate > currentDate) {
            selectedDate = currentDate;
            toaster.error("Please select valid date range.")
        }

        // Check if the selected end date is before the start date
        if (from && selectedDate < new Date(from)) {
            selectedDate = new Date(from);
            toaster.error("Please select valid date range.")
        }
        setTo(selectedDate)
        let date = `${((new Date(e)).getDate())<10?'0'+(new Date(e)).getDate():(new Date(e)).getDate()}-${((new Date(e)).getMonth()+1)<10?'0'+((new Date(e)).getMonth()+1):((new Date(e)).getMonth()+1)}-${(new Date(e)).getFullYear()}`;
        setFilteredData({...filteredData,to:date,selectedTo:date,selectedDate:"Custom Date",selectedRange:9})
    }
    const callDurationChange = (e)=>{
        setCallDurationState(e.target.value)
        setFilteredData({...filteredData,callDurationRange:[e.target.value[0]*60,e.target.value[1]*60]})
    }
    const handleWordChange = (e)=>{
        setWords(e.target.value)
        setFilteredData({...filteredData,words: e.target.value== "" ? undefined : e.target.value})
    }

    const pitchScoreChange = (e)=>{
        setpitchScoreState(e.target.value)
        setFilteredData({...filteredData,pitch:e.target.value})

    }

    const convProbChange = (e)=>{
        setConvProbState(e.target.value)
        setFilteredData({...filteredData,prob:e.target.value})

    }

    const opportunityChange = (e)=>{
        setOppState(e.target.value)
        setFilteredData({...filteredData,opp:e.target.value})

    }

    const urgencyChange = (e)=>{
        setUrgencyState(e.target.value)
        setFilteredData({...filteredData,urgency:e.target.value})
    }

    const emailChange = (e)=>{
        setEmail(e.target.value)
        setFilteredData({...filteredData,email:e.target.value})
    }

    const handleCustomer = (event)=>{
        let { value } = event.target;
        if(event.target.name==="mobile")
        {
            // value = value.replace(/[^0-9]/g, "")
            let numbers = []
            if (value.includes(",")) {
                const numbers = value.split(",").map(num => num.trim());
                const filNum = numbers.filter(num=> num.length > 0)
                setCustomerMobile(numbers);
                setFilteredData({...filteredData,customerMobile:filNum})
            }
            else{
                setCustomerMobile(event.target.value)
                setFilteredData({...filteredData,customerMobile: event.target.value.length > 0 ? [event.target.value]: ''})
                // numbers.push(event.target.value)
            }
            // setFilteredData({...filteredData,customerMobile:event.target.value}) 
        }
        if(event.target.name=="customerName")
        {
            setCustomerName(value)
            setFilteredData({...filteredData,customerName:event.target.value==''?[]:[event.target.value]})
        }
        if(event.target.name=='file'){
            setfileName(event.target.value)
            setFilteredData({...filteredData,fileName: event.target.value.length > 0 ? [event.target.value]: null})
        }
    }

    let arr = momentList?momentList.map((item) => {
        return {label:item.momentName,value:item.momentId};
    }):''
    arr&&arr.unshift({label:"Select All",value:"Select All"})

    let coeOptions = coeList?coeList.map(item=>{
        return {label:item.coeName,value:item.coeName}
    }):""
    
    let newData = []
    for(let i=1;i<arr.length;i++)
        newData.push(arr[i].value)
    let momentName = []
    for(let i=1;i<arr.length;i++)
        momentName.push(arr[i].label)

    const handleMoments = (e)=>{
        setMoments(e)
        if(e.value=='Select All'){
            setFilteredData({...filteredData,moment:momentName,momentId:newData})
        }
        else{
            setFilteredData({...filteredData,moment:[e.label],momentId:[e.value]})
        }
    }

    
    const handleSelectCoe = (e)=>{
        setSelectedCOE(e.target.value)
        let coeId = JSON.parse(localStorage.getItem("COE_DATA"))?.filter(item=>{return item.coeName==e.target.value})
        let names = JSON.parse(localStorage.getItem("COE_DATA"))?.map(item=>{return item.coeName})
        let ids = JSON.parse(localStorage.getItem("COE_DATA"))?.map(item=>{return item.coeId})
        let externalId = JSON.parse(localStorage.getItem("COE_DATA"))?.map(item=>{return item.externalId})
        setFilteredData({...filteredData,coeName:e.target.value=='Select All'?names:[e.target.value],selectedCoeRange:e.target.value=='Select All'?ids:[coeId?.[0]?.coeId],externalId:e.target.value=='Select All'?externalId:[coeId?.[0]?.externalId]})
    }

    const optionss = [
        {label:"None", value:""},
        {label:"Fatal",value:1},
        {label:"Non-Fatal",value:0}
    ]
    const callHealthChange = (e)=>{
        setCallHealth(e)
        setFilteredData({...filteredData,callHealth:e.value})
    }

    function getMoments(){
        conversationService.getCallListingMoments(props?.name,momentId).then(res=>{
            setMomentList(res?res.data.data?res.data.data:'':'')
        })
    }

    function getCoes(){
        let id = JSON.parse(localStorage.getItem('MOMENT_ID'))
        dashboardService.getMomentCOEList(id).then(res =>{
            setCoeList(res.data.data)
        })
    }

    // function getCoesAdmin(){
    //     dashboardService.getUserCOEList().then(res => {
    //         setCoeList(res.data.data)
    //     })
    // }

    useEffect(()=>{
        getMoments()
        if(role!='AGENT')
            getMomentBuckets()
            // getCoes()
        // else
        //     getCoesAdmin()
    },[])

    useEffect(()=>{
        if(window.location.pathname==='/sales_conversations' && fromDate){
          storedData.customerMobile = []
          storedData.fileName = undefined
          storedData.sellerName = []
          storedData.cScore = undefined
          storedData.sellerROS = undefined
          storedData.callDuration = undefined
          storedData.words = undefined
          storedData.momentId = undefined
          storedData.moment = ''
          localStorage.setItem('AGENT',JSON.stringify(storedData))
        }
    },[])


    useEffect(()=>{
        if(props.reset){
            setFilteredData([])
            setCustomerName("")
            setCustomerValue("")
            setCustomerMobile("")
            setfileName("")
            setValue("")
            setSellerROSState([0,10])

            setCallScoreState([0,100])
            setCallHealth("")
            setFrom(firstDay)
            setTo(crrDate)
            setCallDurationState([0,100])
            setWords("")

            setMoments("")
            setSelectedCOE("")

            setpitchScoreState([30,60])
            setConvProbState([30,60])
            setOppState([30,60])
            setUrgencyState([30,60])
            setEmail("")
            // getDateList()
        }
        props.resetFormState()
    },[props.reset])

    function getCoeList(val) {
        props.disableApply(true)
        dashboardService.getMomentCOEList(val).then(res => {
          props.disableApply(false)
          setCoeList(res ? res.data.data ? res.data.data : '' : '');
          let userCoe = res ? res.data.data ? res.data.data.map((item) => {
            return item.coeName
          }) : '' : ''
          let coeExternalId = res ? res.data.data ? res.data.data.map((item) => {
            return item.externalId
          }) : '' : ''
          let coeId = res ? res.data.data ? res.data.data.map(item => {
            return item.coeId
          }) : "" : ""
          setFilteredData(filteredData => ({ ...filteredData, coeName: userCoe, externalId: coeExternalId, selectedCoeRange: coeId }))
        })
      }

    const handleMomentSelect = (e) => {
        setMomentBucket(e.target.value)
        getCoeList(e.target.value)
        let selectedBucket = momentBucketList.filter(item => {
          return item.momentBucketId == e.target.value
        })
        setFilteredData(filteredData => ({ ...filteredData, momentBucket: selectedBucket[0].momentBucketName, momentBucketId: e.target.value }))
        conversationService.getCallListingMoments(props?.name,e.target.value).then(res=>{
            setMomentList(res?res.data.data?res.data.data:'':'')
        })
      }

    return(
    <>
    <Accordion className="rate-speech accordion-flush" id="conversation">
    <Accordion.Item>
        <Accordion.Header>
        {`Moment Bucket ${userRole!='AGENT'?'& COE':""}`}
        </Accordion.Header>
        <div className="customer-collapse">
            <Accordion.Body>
            {userRole != 'AGENT' && momentBucketList?.length > 1 ?
                <div className="lh-1 mb-3">
                    <p className="mb-1 call-fillter-heading">Moment Bucket</p>
                    <Form.Select
                    as="select"
                    custom
                    name="role"
                    aria-label="Default select example"
                    onChange={handleMomentSelect}
                    value={momentBucket}
                    className="basic-multi-select"
                    >
                    {momentBucketList ? momentBucketList.map((bucket) => {
                        return <option value={bucket.momentBucketId} key={bucket.momentBucketId}>{bucket.momentBucketName}</option>;
                    }) : ''}
                    </Form.Select>
                </div> : ''}
            {JSON.parse(localStorage.getItem("COE_DATA"))?.length > 1 || JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole !== "AGENT" ? 
               <div>
                    <p className="mb-1 call-fillter-heading">
                        Center of Excellence
                    </p>
                    <Form.Select
                        style={{textTransform:"capitalize"}}
                        name="coe" 
                        onChange={handleSelectCoe}
                        className="basic-multi-select"
                        placeholder="Select COE"    
                        value={selectedCOE}
                        
                        // options = {coeOptions}
                        // label={(options)=> {return options.coeList}}
                        >
                        <option value={'Select All'}>Select All</option>
                        {JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole !== "AGENT" ? coeList?coeList.map((coe) => {
                            return <option key={coe.coeId} value={coe.coeName}>{coe.coeName}</option>;
                        }):'': JSON.parse(localStorage.getItem("COE_DATA"))?.map((coe) => {
                            return <option key={coe.coeId} value={coe.coeName}>{coe.coeName}</option>;
                        })}
                    </Form.Select>
                </div>:''} 

            </Accordion.Body>
        </div>
    </Accordion.Item>    
    <Accordion.Item>
        <Accordion.Header>LEADS</Accordion.Header> 
        <div className="customer-collapse">
            <Accordion.Body>
                {/* {filterChange == false ? <><p className="mb-1 call-fillter-heading">Customer Name</p>
                <div className="input-group signals-multiselect-box mb-3">
                    <Autosuggest
                    suggestions={customerSuggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequestedCustomer}
                    onSuggestionsClearRequested={onSuggestionsClearRequestedCustomer}
                    onSuggestionSelected={(_, { suggestionValue }) => {
                        // setFilteredData({...filteredData,agentName:[suggestionValue]})
                    }}
                    getSuggestionValue={getCustomerSuggestionValue}
                    renderSuggestion={renderCustomerSuggestion}
                    inputProps={inputCustomerProps}
                    />
                </div></> : ""} */}
                {/* <input className="form-control form-control-sm mb-3 py-1" type="text" name="customerName"
                onChange={handleCustomer} value={customerName}/> */}
                <p className="mb-1 call-fillter-heading">{`Group/${props.isEmail || props.isChat ? '' : 'Call/'}Lead ID`}</p>
                <input className="form-control form-control-sm mb-3 py-1" type="tel" name="mobile" 
                onChange={handleCustomer} value={customerMobile}/>
                <p className="mb-1 call-fillter-heading">FileName</p>
                <input className="form-control form-control-sm mb-3 py-1" type="text" name="file" 
                onChange={handleCustomer} value={fileName}/>
            </Accordion.Body>
        </div>
    </Accordion.Item>
    </Accordion>

    {JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole!='AGENT'?<Accordion className="rate-speech accordion-flush" id="conversation">
    <Accordion.Item>
        <Accordion.Header>
            AGENT
        </Accordion.Header>
        <div className="customer-collapse">
            <Accordion.Body>
                
                    <>
                    <p className="mb-1 call-fillter-heading">AGENT Name</p>
                    <div className="signals-multiselect-box mb-3">
                        <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={(_, { suggestionValue }) => {
                            console.log("Selected: " + suggestionValue);
                            // setFilteredData({...filteredData,agentName:[suggestionValue]})
                        }}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        />
                    </div></>
                    { location.pathname !== '/email' && 
                    <div className="mb-3">
                        <p className="mb-0 call-fillter-heading">
                        AGENT ROS
                            {`(${sellerROSState[0]} - ${sellerROSState[1]}) WPS  `}
                        </p>
                        <div className="my-1 px-4">
                            <Box>
                                <Slider
                                value={sellerROSState}
                                onChange={sellerROSChange}
                                // defaultValue={20}
                                className="ros"
                                valueLabelDisplay="auto"
                                marks={sellerROS}
                                min={0}
                                max={10}
                                step={0.1}
                                name="ros"
                                />
                            </Box>
                        </div>
                    </div>
                    }  
            </Accordion.Body>
        </div>
    </Accordion.Item>
    </Accordion>:""}
    <Accordion className="rate-speech accordion-flush" id="conversation">
    <Accordion.Item>
        <Accordion.Header>
        { location.pathname !== '/email' ? 'CALL': 'EMAIL'}
        </Accordion.Header>
        <div className="customer-collapse">
            <Accordion.Body>
                <p className="mb-1 call-fillter-heading">
                    { location.pathname !== '/email' ? 'Call Score ': 'Email Score'}
                    {/* {`(${inputs.sellerROS[0]} - ${inputs.sellerROS[1]}) min.  `} */}
                </p>
                <div className="call-fillter-call-score my-0 px-3">
                    {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                    <Box>
                    <Slider
                        value={callScoreState}
                        onChange={callScoreChange}
                        className="callscore"
                        valueLabelDisplay="auto"
                        marks={callScore}
                        name="callScore"
                    />
                    </Box>
                </div>
                {location.pathname === '/email' && 
                <div className="mb-3">
                    <p className="mb-1 call-fillter-heading">EMAIL ID</p>
                    <input className="form-control form-control-sm mb-3 py-1" type="email" name="email" 
                    onChange={emailChange} value={email}/>
                </div>}
                <p className="mb-1 call-fillter-heading">
                     { location.pathname !== '/email' ? 'Call Health': 'Email Health'}
                </p>
                <div className="mb-3">
                    <Select
                        // defaultValue={[colourOptions[2], colourOptions[3]]}
                        // isMulti
                        name="health"
                        options={optionss}
                        className="basic-multi-select"
                        value={callHealth}
                        onChange={callHealthChange}
                        // value={selectedOption}
                        classNamePrefix="select"
                    />
                </div>
                <p className="mb-1 call-fillter-heading">
                    { location.pathname !== '/email' ? 'Call Date': 'Email Date'}
                </p>
                <div className="call-fillter-date dasbard-comp-setting mb-3">
                    <div className="input-box form-group">
                        <label className="col-form-label">From:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            openTo="day"
                            views={['year', 'month', 'day']}
                            inputFormat={dateFormat}
                            value={from}
                            name="from"
                            onChange={handleFromDate} 
                            disableOpenPicker
                            open={opendp}
                            onOpen={() => setOpendp(true)}
                            onClose={() => setOpendp(false)}
                            maxDate={to}
                            renderInput={(params) => (
                                <TextField {...params} onClick={(e) => setOpendp(true)} />
                            )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="input-box form-group ms-1">
                        <label className="col-form-label">To:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            openTo="day"
                            views={['year', 'month', 'day']}
                            inputFormat={dateFormat}
                            value={to}
                            name="to"
                            onChange={handleToDate}
                            disableOpenPicker
                            open={opendp1}
                            onOpen={() => setOpendp1(true)}
                            onClose={() => setOpendp1(false)}
                            minDate={from}
                            maxDate={new Date()}
                            renderInput={(params) => (
                                <TextField {...params} onClick={(e) => setOpendp1(true)} />
                            )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                { location.pathname !== '/email' && <>
                    <p className="mb-1 call-fillter-heading">
                        CALL DURATION
                    </p>
                    <div className="call-fillter-duration px-4">
                        {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                        <Box>
                        <Slider
                            value={callDurationState}
                            onChange={callDurationChange}
                            className="callDuration"
                            valueLabelDisplay="auto"
                            marks={callDuration}
                            name="callDuration"
                        />
                        </Box>
                    </div></>
                }
                <p className="mb-1 call-fillter-heading">
                    Words{" "}
                </p>
                <div >
                <input type="text" className="form-control form-control-sm mb-3 py-1" 
                value={words} onChange={handleWordChange}/>
                </div>
            </Accordion.Body>
            
        </div>
    </Accordion.Item>
    </Accordion>
    <Accordion className="rate-speech accordion-flush" id="conversation">
    <Accordion.Item>
        <Accordion.Header>Moments</Accordion.Header>
        <div className="customer-collapse">
            <Accordion.Body>
                <p className="mb-1 call-fillter-heading">
                    Moments{" "}
                </p>
                <div className="mb-3">
                    <Select 
                        style={{textTransform:"capitalize"}}
                        name="role" 
                        onChange={handleMoments}
                        className="basic-multi-select"
                        // className="form-control form-control-sm mb-3 py-1"
                        placeholder="Select Moments"
                        value={moments}
                        options = {arr || []}
                        classNamePrefix="select"
                        isSearchable={false}
                        >
                    </Select>
                </div>
               {/* {JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole !== "AGENT" &&   */}

               
            </Accordion.Body>
            
        </div>
    </Accordion.Item>
    </Accordion>
    {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.stats.some(e=>e.value==="pitchScore"||e.value==="urgency"||e.value==="opportunity"||e.value==="conversionProbability")?<Accordion className="rate-speech accordion-flush" id="conversation">
        <Accordion.Item>
            <Accordion.Header>
            Sales Kpi
            </Accordion.Header>
            <div className="customer-collapse">
                <Accordion.Body>
                    {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.stats.some(e=>e.value=='pitchScore')?
                        <>
                            <p className="mb-1 call-fillter-heading">
                                Pitch Score{" "}
                                {/* {`(${inputs.sellerROS[0]} - ${inputs.sellerROS[1]}) min.  `} */}
                            </p>
                            <div className="call-fillter-call-score my-0 px-3">
                                {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                                <Box>
                                <Slider
                                    value={pitchScoreState}
                                    onChange={pitchScoreChange}
                                    // defaultValue={20}
                                    className="pitchScore"
                                    valueLabelDisplay="auto"
                                    marks={pitchScore}
                                    // min={30}
                                    // max={60}
                                    name="pitchScore"
                                />
                                </Box>
                            </div>
                        </>
                        :
                        JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.stats.some(e=>e.value=="conversionProbability")?
                            <>
                                <p className="mb-1 call-fillter-heading">
                                    Conversion Probability{" "}
                                </p>
                                <div className="call-fillter-duration px-3">
                                    {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                                    <Box>
                                    <Slider
                                        value={convProbState}
                                        onChange={convProbChange}
                                        // defaultValue={20}
                                        className="conversionProb"
                                        valueLabelDisplay="auto"
                                        marks={convProb}
                                        // min={30}
                                        // max={60}
                                        name="conversionProb"
                                    />
                                    </Box>
                                </div>
                            </>
                        :
                        JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.stats.some(e=>e.value=="opportunity")?
                        <>
                            <p className="mb-1 call-fillter-heading">
                                opportunity{" "}
                            </p>
                            <div className="call-fillter-duration px-3">
                                {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                                <Box>
                                <Slider
                                    value={oppState}
                                    onChange={opportunityChange}
                                    // defaultValue={20}
                                    className="opportunity"
                                    valueLabelDisplay="auto"
                                    marks={opportunity}
                                    // min={30}
                                    // max={60}
                                    name="opportunity"
                                />
                                </Box>
                            </div>
                        </>
                        :
                        JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.stats.some(e=>e.value=="urgency")?
                        <>
                            <p className="mb-1 call-fillter-heading">
                                urgency{" "}
                            </p>
                            <div className="call-fillter-duration px-3">
                                {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                                <Box>
                                <Slider
                                    value={urgencyState}
                                    onChange={urgencyChange}
                                    // defaultValue={20}
                                    className="urgency"
                                    valueLabelDisplay="auto"
                                    marks={urgency}
                                    // min={30}
                                    // max={60}
                                    name="urgency"
                                />
                                </Box>
                            </div>
                        </>
                        :""
                    }
                </Accordion.Body>
                
            </div>
        </Accordion.Item>
    </Accordion>:""}
       </>
    )
    }