import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import {Autocomplete, Box, Chip, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@mui/material"
import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { useDispatch, useSelector } from "react-redux";
import { applyFilterData, dataMoment, dataMomentGroup, setMoments, setUserOptions } from "../../redux-container/insights/action";
import { apiCall } from "../Services/Interceptor";
import toaster from "../Toast/toaster";
import dashboardService from "../Services/dashboard.service";
import momentService from "../Services/moment.service";

const InsightsFilter = ({tabIndex, setFilterData, disableApply})=>{
    let userRole = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
    let moment_bucket_id = JSON.parse(localStorage.getItem("MOMENT_ID"))
    let localData = JSON.parse(localStorage.getItem("AGENT"))
    let optionsProductivity = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.productivity
    let dateFormat = JSON.parse(localStorage.getItem("TIME_FORMAT")).value
    const options = useSelector(state=>state.insights.userOptions)
    const selectAllOption = { value: 'Select All', label: 'Select All' };
    const optionsWithSelectAll = [selectAllOption, ...options]; 
    let momentGroupData = useSelector(state=>state?.insights?.momentGroupList)
    let momentData = useSelector(state=>state?.insights?.momentList)
    const dateOptions = useSelector(state=>state.insights.dateList)
    const filteredOptions = useSelector(state=>state?.insights?.filterData)
    let defaultAnalysis = useSelector(state=>state?.insights?.moments)
    const [dateChange, setDateChange] = useState(false)
    const [customInput, SetCustomInput] = useState(localData&&localData.selectedRange==9?true:localData&&localData.selectedRange==''?true:false);
    const [selectedAgent, setSelectedAgent] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    const [def, setDef] = useState(false)
    const [productivity, setProductivity] = useState(localData?.productivity?localData.productivity:'Call Score');
    const [analysis, setAnalysis] = useState(localData?.analysis?localData.analysis:defaultAnalysis[0]?.value);
    const [date, setDate] = useState(localData&&localData.selectedRange?localData.selectedRange:localData&&localData.selectedRange==''?9:5);
    const [startDate, setStartDate] = useState(localData?.fromD?new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`):new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD?new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`):new Date())
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const [isSelectAll, setIsSelectAll] = useState(localData?.userNames && localData?.userIds && localData?.userNames.length === options.length)
    const [moment, setMoment] = useState([]);
    const [momentGroup, setMomentGroup] = useState([])
    const [isSelectAllGroup, setIsSelectAllGroup] = useState(false)
    const [isSelectAllMoment, setIsSelectAllMoment] = useState(false)
    const [word, setWord] = useState(filteredOptions?.word)
    const [momentBucketList, setMomentBucketList] = useState()
    const [momentBucket, setMomentBucket] = useState(localData && localData.momentBucketId ? localData.momentBucketId : moment_bucket_id)
    const [selectedCOE, setSelectedCOE] = useState(localData && localData.selectedCoeRange ? localData.selectedCoeRange.length == 1 ? localData.selectedCoeRange[0] : "Select All" : "")
    const [coeList, setCoeList] = useState()
    let dispatch = useDispatch()
    let storeFilterData = useSelector(state=>state?.insights?.filterData)
    let resetState = useSelector(state=>state?.insights?.reset)

    useEffect(()=>{
        if(localData?.userIds){
            setSelectedAgent(localData.userIds.map(id => id?.toString()))
        }
        else{
            setSelectedAgent(options?.map(item=>item.value))
        }
        
    },[options])

    useEffect(()=>{
        if(localData?.momentIds){
            setMoment(localData?.momentIds.map(id => id.toString()))
        }
        else{
            setMoment(momentData&&filteredOptions?.momentIds &&
                filteredOptions?.momentIds?.length > 0 ? 
                momentData.find(item => (item.value == filteredOptions.momentIds[0]))?.id : momentData[0]?.value)
        }  
    },[momentData])

    useEffect(()=>{
        if(localData?.momentGroupIds){
            setMomentGroup(localData?.momentGroupIds.map(id => id.toString()))
        }
        else{
            setMomentGroup(momentGroupData&&filteredOptions?.momentGroupIds &&
                filteredOptions?.momentGroupIds?.length > 0 ? 
                momentGroupData.find(item => (item.value == filteredOptions.momentGroupIds[0]))?.id : momentGroupData[0]?.value)
        }
    },[momentGroupData])

    useEffect(()=>{
        const analysisValues = defaultAnalysis.map(item => item.value);
        if (!analysisValues.includes(localData?.analysis)) {
            setAnalysis(defaultAnalysis[0]?.value);
        }else{
            setAnalysis(localData?.analysis)
        }
    },[defaultAnalysis])

    useEffect(()=>{
        setWord(filteredOptions?.word)
    },[filteredOptions])

    useEffect(() => {
        setIsSelectAll(selectedAgent.length === options.length);
    }, [selectedAgent]);
    
    useEffect(()=>{
        if(resetState){
            setSelectedAgent(options.map(item=>item.value))
            setMoment(momentData&&momentData[0].value)
            setMomentGroup(momentGroupData&&momentGroupData[0]?.value)
            setIsSelectAll(true)
            setDate(5)
            SetCustomInput(false)
            setProductivity('Call Score')
            setWord('');
            setFilterData({})
        }
    },[resetState])

    useEffect(()=>{
        getMomentGroupData(moment_bucket_id)
        if (userRole != 'AGENT') {
            getMomentBuckets()
        }
    },[])


    const getMomentGroupData = async (id) => {
        try {
            const res = await apiCall.get(`/odio/api/moment-bucket/get-moments?mentions=true&momentBucketId=${id}`);
    
          if (res?.data?.status === 0) {
            dispatch(dataMomentGroup(res?.data?.data?.momentGroups?.map((item) => {
              return {label: item.momentGroupName, value: `${item.momentGroupId}`, id:item.momentGroupId}
            })))
            dispatch(dataMoment(res?.data?.data?.momentGroups?.flatMap((item) => {
              return item?.moments?.map((moment) => {
                return {label: moment.momentName, value: `${moment.momentId}`, id: moment.momentId }
              })
            })))
          }
        } catch (error) {
          console.error('Error fetching moment group data', error);
        }
    }
    
    const getCoeList = (val) => {
        // disableApply(true)
        dashboardService.getMomentCOEList(val).then(res => {
        //   disableApply(false)
          setCoeList(res ? res.data.data ? res.data.data : '' : '');
          let userCoe = res ? res.data.data ? res.data.data.map((item) => {
            return item.coeName
          }) : '' : ''
          let coeExternalId = res ? res.data.data ? res.data.data.map((item) => {
            return item.externalId
          }) : '' : ''
          let coeId = res ? res.data.data ? res.data.data.map(item => {
            return item.coeId
          }) : "" : ""
          getUserList(coeId)
          setFilterData(prevData => ({ ...prevData, selectedCOE: userCoe, externalId: coeExternalId, selectedCoeRange: coeId }))
        })
        dispatch(setMoments(val));
        getMomentGroupData(val);
    }

    const getMomentBuckets = () => {
        momentService.getMomentBuckets("SALES").then(res => {
          setMomentBucketList(res ? res : '');
          localStorage.setItem("MOMENT_BUCKETS", localStorage.getItem("MOMENT_BUCKETS") ? localStorage.getItem("MOMENT_BUCKETS") : res[0].momentBucketName)
          localStorage.setItem("MOMENT_ID", JSON.parse(localStorage.getItem("MOMENT_ID")) ? JSON.parse(localStorage.getItem("MOMENT_ID")) : res[0].momentBucketId)
          dashboardService.getMomentCOEList(momentBucket ? momentBucket : res[0].momentBucketId).then(res => {
            setCoeList(res ? res.data.data ? res.data.data : '' : '');
            localStorage.setItem("COE_DATA",JSON.stringify(res?.data?.data))
          })
        })
    }  

    const handleAgentChange = (event,values)=>{
        const {
            target: { value },
        } = event;
        if (values.some(option => option.value === 'Select All')) {
            handleSelectAll();
        } 
        else {
            if(isSelectAll){
                // let newSelectedAgents = typeof value === 'string' ? value.split(',') : value;
                const deselectedAgent = selectedAgent.find(agent => !values.some(val => val === agent));
                const newSelectedAgents = selectedAgent.filter(agent => agent !== deselectedAgent && agent !== 'Select All');
                setSelectedAgent(newSelectedAgents);

                let selectedNames = newSelectedAgents.map(val => options.find(opt => opt.value === val)?.label);
                setFilterData(prevData=>({...prevData, ...storeFilterData, id:newSelectedAgents, userNames:selectedNames, names:selectedNames}))
                dispatch(applyFilterData({...storeFilterData, id:newSelectedAgents, names:selectedNames}))
            }
            else{
                const newSelectedValues = values.map(item =>
                    typeof item === 'object' && item !== null ? item.value : item
                );
                const filteredAgents = newSelectedValues.filter(val => val.value !== 'Select All'); //this is to filter Select all which was appended by default when selecting first checkbox
                setSelectedAgent(filteredAgents);
                let selectedNames = filteredAgents.map(val => options.find(opt => opt?.value == val)?.label)

                setFilterData(prevData=>({...prevData, ...storeFilterData, id:filteredAgents, userNames:selectedNames, names:selectedNames}))
                dispatch(applyFilterData({...storeFilterData, id:filteredAgents, names:selectedNames}))
            }
            
            // Update Select All status
            if (values.length === options.length) {
                setIsSelectAll(true);
            } else {
                setIsSelectAll(false);
            }
        }   
        let ids = []
    }

    const handleSelectAll = ()=>{
        if (isSelectAll) {
            setSelectedAgent([]);
            setFilterData(prevData=>({...prevData, ...storeFilterData, id:[], userNames:[], names:[]}))
            dispatch(applyFilterData({...storeFilterData, id:[], names:[]}))
        } else {
            setSelectedAgent(options.map(option => option.value));
            let names = options.map(option => option.label)
            let selectedIds = options.map(option => option.value)
            setFilterData(prevData=>({...prevData, ...storeFilterData, id:selectedIds, userNames:names, names:names}))
            dispatch(applyFilterData({...storeFilterData, id:selectedIds, names:names}))
        }
        setIsSelectAll(!isSelectAll);
    }

    const handleProdChange = (event)=>{
        setDef(false)
        // setDefaultData([])
        setProductivity(event.target.value)
        setFilterData(filteredData=>({...filteredData, productivity:event.target.value}))
        dispatch(applyFilterData({...storeFilterData, productivity:event.target.value}))
    }

    const handleAnalysis = (event)=>{
        setAnalysis(event.target.value)
        setFilterData(filteredData=>({...filteredData, analysis:event.target.value}))
        // dispatch(applyFilterData({...storeFilterData, analysis:event.target.value}))
    }

    const getUserList = async (val) => {
        disableApply(true);
        const apiUrl = `${'/odio/api/user/get-user-team-list'}?fromD=${localData?.fromD || null}&toD=${localData?.toD || null}&coeIds=${val}`;
            const res = await apiCall.get(apiUrl);
            if (res?.data?.status === 0) {
                disableApply(false);
                const teamOptions = res?.data?.data?.team.map((e) => ({
                    label: `${e.firstName} ${e.lastName}`,
                    value: e.id,
                    id: e.id,
                }));
    
                const optionsWithSelectAll = [...teamOptions];
                dispatch(setUserOptions(optionsWithSelectAll));
                setIsSelectAll(true);
                setSelectedAgent(teamOptions.map(item=>item.value))         
                setFilterData(filteredData=>({...filteredData, id:teamOptions.map(option => option.id)}))
            }
    };

    const handleMomentSelect = (e) => {
        setMomentBucket(e.target.value)
        getCoeList(e.target.value)
        let selectedBucket = momentBucketList.filter(item => {
          return item.momentBucketId == e.target.value
        })
        setFilterData(prevData => ({ ...prevData, momentBucket: selectedBucket[0].momentBucketName, momentBucketId: e.target.value }))
    }

    const handleSelectCoe = (e) => {
        setSelectedCOE(e.target.value)
        let coes = e.target.value == 'Select All' ? coeList : coeList?.filter(function (el) {
        return el.coeId == e.target.value
        })
        setFilterData((prevData)=>({ ...prevData, externalId: e.target.value == 'Select All' ? coes.map(item => { return item.externalId }) : coes[0].externalId, selectedCOE: e.target.value == 'Select All' ? coes.map(item => { return item.coeName }) : [coes[0].coeName], selectedCoeRange: e.target.value == 'Select All' ? coes.map(item => { return item.coeId }) : [e.target.value] }))
    }

    const handleChange = (event) =>{
        setDateChange(true)
        setDate(event.target.value)
        setDef(false)
        if(event.target.value == 9){
            const date = new Date()
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = String(date.getFullYear()).slice(-2); 
            SetCustomInput(true)
            // setFilterData({...filteredData,dateName:"Custom Date"})
            setFilterData((prevData) => ({...prevData,...storeFilterData, dateName:"Custom Date", selectedRange:event.target.value, fromD:localData?.fromD ? localData?.fromD : `${day}-${month}-${year}`, toD:localData?.toD ? localData?.toD : `${day}-${month}-${year}`}));
            // dispatch(applyFilterData({...storeFilterData, dateName:"Custom Date", selectedRange:event.target.value, fromD:localData?.fromD ? localData?.fromD : `${day}-${month}-${year}`, toD:localData?.toD ? localData?.toD : `${day}-${month}-${year}`}))
        }
        else{
            SetCustomInput(false)
            let SelectedDate = dateOptions && dateOptions.filter(function (el) {
                return el.id == event.target.value;
            })
            let dd = SelectedDate[0].fromDate.split("-")[2]
            let mm = SelectedDate[0].fromDate.split("-")[1]
            let yyyy = SelectedDate[0].fromDate.split("-")[0]
        
            let dd_1 = SelectedDate[0].toDate.split("-")[2]
            let mm_1= SelectedDate[0].toDate.split("-")[1]
            let yyyy_1= SelectedDate[0].toDate.split("-")[0]
        
            let dateF = `${dd}-${mm}-${yyyy}`
            let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
    
            setFilterData((prevData)=>({...prevData,...storeFilterData,fromDate: dateT,toDate:dateF, fromD: dateT, toD:dateF, dateName:SelectedDate[0].name, selectedRange: event.target.value}))
            // dispatch(applyFilterData({...storeFilterData, fromD: dateT, toD:dateF, dateName:SelectedDate[0].name, selectedRange: event.target.value}))
        }
    }

    const handleStartDate = (e)=>{
        const currentDate = new Date();
        let selectedDate = new Date(e);

        // Check if the selected date is in the future
        if (selectedDate > currentDate) {
            selectedDate = currentDate;
            toaster.error("Please select valid date range.")
        }

        // Check if the selected start date is after the end date
        if (endDate && selectedDate > new Date(endDate)) {
            selectedDate = new Date(endDate);
            toaster.error("Please select valid date range.")
        }
        setStartDate(selectedDate);
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
        setFilterData((prevData)=>({...prevData,...storeFilterData, fromDate: date, fromD: date, toD: `${(new Date(endDate)).getDate()}-${(new Date(endDate)).getMonth()+1}-${(new Date(endDate)).getFullYear()}`, dateName:'Custom Date', selectedRange:9}))
        // dispatch(applyFilterData({...storeFilterData, fromD: date, toD: `${(new Date(endDate)).getDate()}-${(new Date(endDate)).getMonth()+1}-${(new Date(endDate)).getFullYear()}`, dateName:'Custom Date', selectedRange:9}))
    }
    
    const handleEndDate = (e)=>{
        const currentDate = new Date();
        let selectedDate = new Date(e);

        // Check if the selected date is in the future
        if (selectedDate > currentDate) {
            selectedDate = currentDate;
            toaster.error("Please select valid date range.")
        }

        // Check if the selected end date is before the start date
        if (startDate && selectedDate < new Date(startDate)) {
            selectedDate = new Date(startDate);
            toaster.error("Please select valid date range.")
        }
        setEndDate(selectedDate);
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
        setFilterData((prevData)=>({...prevData,...storeFilterData,toDate: date,fromD:`${(new Date(startDate)).getDate()}-${(new Date(startDate)).getMonth()+1}-${(new Date(startDate)).getFullYear()}`, toD: date, dateName:'Custom Date', selectedRange:9}))
        // dispatch(applyFilterData({...storeFilterData, fromD:`${(new Date(startDate)).getDate()}-${(new Date(startDate)).getMonth()+1}-${(new Date(startDate)).getFullYear()}`, toD: date, dateName:'Custom Date', selectedRange:9}))
    }

    const selectAllMomentGroup = ()=>{
        if (isSelectAllGroup) {
            setMomentGroup([]);
            setFilterData(prevData=>({...prevData, ...storeFilterData, momentGroupIds:[]}))
            // dispatch(applyFilterData({...storeFilterData, momentGroupIds:[]}))
        } else {
            setMomentGroup(momentGroupData.map(option => option.value));
            let groups = momentGroupData.map(option => option.label)
            let selectedIds = momentGroupData.map(option => option.value)
            setFilterData(prevData=>({...prevData, ...storeFilterData, momentGroupIds:selectedIds}))
            // dispatch(applyFilterData({...storeFilterData, momentGroupIds:selectedIds}))
        }
        setIsSelectAllGroup(!isSelectAllGroup);
    }

    const handleMomentGroup = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('Select All')) {
            selectAllMomentGroup();
        } 
        else {
            if(isSelectAllGroup){
                setMomentGroup([]);
                setFilterData(prevData=>({...prevData, ...storeFilterData, momentGroupIds:[]}))
                // dispatch(applyFilterData({...storeFilterData, momentGroupIds:[]}))
            }
            else{
                const newSelectedGroups = typeof value === 'string' ? value.split(',') : value;
                const filteredGroups = newSelectedGroups.filter(val => val.label !== 'Select All'); //this is to filter Select all which was appended by default when selecting first checkbox
                setMomentGroup(filteredGroups);
                let selectedMomentGroups = filteredGroups.map(val => options.find(opt => opt.value === val)?.label)
                setFilterData(prevData=>({...prevData, ...storeFilterData, momentGroupIds:filteredGroups}))
                // dispatch(applyFilterData({...storeFilterData, momentGroupIds:filteredGroups}))
            }
            
            // Update Select All status
            if (value.length === momentGroupData.length) {
                setIsSelectAllGroup(true);
            } else {
                setIsSelectAllGroup(false);
            }
        }  
    };

    const selectAllMoment = ()=>{
        if (isSelectAllMoment) {
            setMoment([]);
            setFilterData(prevData=>({...prevData, ...storeFilterData, momentIds:[]}))
            // dispatch(applyFilterData({...storeFilterData, momentIds:[]}))
        } else {
            setMoment(momentGroupData.map(option => option.value));
            let groups = momentGroupData.map(option => option.label)
            let selectedIds = momentGroupData.map(option => option.value)
            setFilterData(prevData=>({...prevData, ...storeFilterData, momentIds:selectedIds}))
            // dispatch(applyFilterData({...storeFilterData, momentIds:selectedIds}))
        }
        setIsSelectAllMoment(!isSelectAllMoment);
    }

    const handleMoment = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('Select All')) {
            selectAllMoment();
        } 
        else {
            if(isSelectAllMoment){
                setMoment([]);
                setFilterData(prevData=>({...prevData, ...storeFilterData, momentIds:[]}))
                // dispatch(applyFilterData({...storeFilterData, momentIds:[]}))
            }
            else{
                const newSelectedMoments = typeof value === 'string' ? value.split(',') : value;
                const filteredMoments = newSelectedMoments.filter(val => val.label !== 'Select All'); //this is to filter Select all which was appended by default when selecting first checkbox
                setMoment(filteredMoments);
                let selectedMomentGroups = filteredMoments.map(val => options.find(opt => opt.value === val)?.label)
                setFilterData(prevData=>({...prevData, ...storeFilterData, momentIds:filteredMoments}))
                // dispatch(applyFilterData({...storeFilterData, momentIds:filteredMoments}))
            }
            
            // Update Select All status
            if (value.length === momentData.length) {
                setIsSelectAllMoment(true);
            } else {
                setIsSelectAllMoment(false);
            }
        }  
    };

    const handleWordChange = (e) => {
        setWord(e.target.value); 
        setFilterData((prevData)=>({ ...prevData, ...storeFilterData, word: e.target.value }))
        // dispatch(applyFilterData({ ...storeFilterData, word: e.target.value }))
    }

    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
    };

    return(
        <>
        {userRole != 'AGENT' && momentBucketList?.length > 1 ?
            <div className="lh-1 mb-3">
                <p className="form-label me-1">Moment Bucket</p>
                <hr className="meeting-filter-hr"/>
                <Form.Select
                as="select"
                custom
                name="role"
                aria-label="Default select example"
                onChange={handleMomentSelect}
                value={momentBucket}
                className="form-control-sm py-1"
                >
                {momentBucketList ? momentBucketList.map((bucket) => {
                    return <option value={bucket.momentBucketId} key={bucket.momentBucketId}>{bucket.momentBucketName}</option>;
                }) : ''}
                </Form.Select>
            </div> : ''}
            <div className="lh-1 mb-3">
                <p className="form-label me-1">COE</p>
                <hr className="meeting-filter-hr"/>

                <Form.Select as="select" custom
                style={{ textTransform: "capitalize" }}
                name="role" aria-label="Default select example"
                onChange={handleSelectCoe}
                className="form-control-sm py-1"
                placeholder="Select COE"
                value={selectedCOE}
                >
                <option value={'Select All'}>Select All</option>
                {coeList?.length > 0 ? coeList.map((coe) => {
                    return <option value={coe.coeId} key={coe.coeId}>{coe.coeName}</option>;
                }) : ''}
                </Form.Select>
            </div>
            <div class="mb-2 insights-agent">
                {options && options.length>0 ?
                <FormControl sx={{width: 228 }}>
                    <label class="form-label border-bottom">Employee</label>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={optionsWithSelectAll}
                        value={selectedAgent}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                            handleAgentChange(event, newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => (
                            <Tooltip title={`Agent ID: ${option.value}`} arrow PopperProps={{ disablePortal: true }} >
                            <MenuItem
                                {...props}
                                key={`menuItem-${option.value}`}
                                value={option.value}
                            >
                                <Checkbox
                                    checked={
                                        option.value === 'Select All'
                                        ? selectedAgent.length === options.length // Select All is checked if all other options are selected
                                        : selectedAgent.indexOf(option.value) > -1
                                    }
                                    sx={{
                                        color: '#aee3ff', // Custom checkbox colors
                                        '&.Mui-checked': {
                                            color: '#aee3ff',
                                        },
                                    }}
                                />
                                <ListItemText primary={option?.label} />
                            </MenuItem>
                            </Tooltip>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'nowrap',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }
                                }}
                            />
                        )}
                        renderTags={(selected) => {
                            const selectedLabels = selected?.map(val => options.find(opt => opt.value === val)?.label).join(', ');
                            return (
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {selectedLabels}
                                </span>
                            );
                        }}
                    />
                </FormControl> :
                 <FormControl sx={{width: 228 }}>
                    <label class="form-label border-bottom">Employee</label>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={[]} // Empty options
                        value={[]}
                        disableCloseOnSelect
                        onChange={() => {}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="No options available"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'nowrap',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    },
                                }}
                            />
                        )}
                    />
                </FormControl>}
                {selectedAgent && selectedAgent.length==0 ? 
                <div>
                    <span className="error-message mt-2">Please select employees</span>
                </div> : ''}
            </div>
            <div class="mb-2 insights date-filter">
                <FormControl sx={{width: 228 }}>
                    <label className="form-label border-bottom">Date</label>
                    <Select value={date} id="inputRole" className=" p025 br025" name="saledateSelected" onChange={handleChange} sx={{width:200}}>
                        {dateOptions && dateOptions.length>0 && dateOptions?.map(item=>{return(<MenuItem  key={item.id} value={item.id}>{item.name}</MenuItem>)})}
                    </Select>
                    {customInput?<div className="call-fillter-date dasbard-comp-setting ms-1">
                        <div className="input-box form-group">
                            <label className="col-form-label mt-1">From:</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                className="mt-2"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                inputFormat={dateFormat}
                                value={startDate}
                                name="startDate"
                                onChange={handleStartDate}
                                disableOpenPicker
                                open={opendp}
                                onOpen={() => setOpendp(true)}
                                onClose={() => setOpendp(false)}
                                maxDate={endDate}
                                renderInput={(params) => (
                                    <TextField {...params} onClick={()=> setOpendp(true)} />
                                )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="input-box form-group">
                            <label className="col-form-label mx-1 mt-1">To:</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                className="mt-2"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                inputFormat={dateFormat}
                                value={endDate}
                                name="endDate"
                                onChange={handleEndDate}
                                disableOpenPicker
                                open={opendp1}
                                onOpen={() => setOpendp1(true)}
                                onClose={() => setOpendp1(false)}
                                minDate={startDate}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                    <TextField {...params} onClick={()=> setOpendp1(true)} />
                                )}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>: ''}
                </FormControl>
            </div>
            {
                (tabIndex === 1 || tabIndex === 2) ? (
                    <div className="align-items-baseline mt-2 insights">
                        <FormControl sx={{width: 228 }}>
                            <label className="form-label me-1 border-bottom">{tabIndex === 1 ? "Moment Group" : "Moment"}</label>
                            {tabIndex === 1 ? (
                                <Select 
                                    value={momentGroup} 
                                    id="inputRole" 
                                    className="date-select br025" 
                                    name="saledateSelected" 
                                    MenuProps={MenuProps}
                                    onChange={handleMomentGroup}
                                >
                                    {momentGroupData && momentGroupData.length>0 && momentGroupData?.map((opt,id) => (
                                        <MenuItem key={id} value={opt?.value}>
                                        <ListItemText primary={opt?.label.length > 20 ? `${opt?.label.substring(0, 20)}...` : opt?.label} title={opt?.label}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : ( 
                                <Select 
                                    value={moment} 
                                    id="inputRole" 
                                    className="date-select br025" 
                                    name="saledateSelected" 
                                    MenuProps={MenuProps}
                                    label="Select Moment" 
                                    onChange={handleMoment}
                                >
                                    {momentData && momentData.length>0 && momentData?.map((opt,id) => (
                                        <MenuItem key={id} value={opt?.value}>
                                        <ListItemText primary={opt?.label.length > 20 ? `${opt?.label.substring(0, 20)}...` : opt?.label} title={opt?.label}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            )
                            }
                        </FormControl>
                    </div>
                ) : null
            }

            {tabIndex === 3 && <div className="align-items-baseline justify-content-end mt-2 insights">
                <FormControl sx={{ width: 228 }}>
                    <label className="form-label me-1 border-bottom">Enter Word</label>
                    <TextField id="outlined-basic" variant="outlined"  value={word} onChange={handleWordChange} />
                </FormControl>
            </div>
            }

            {document.title==='Productivity - Odio' && 
            <div className="align-items-baseline justify-content-end mt-2 insights">
                <FormControl sx={{width: 228 }}>
                    <label className="form-label me-1 border-bottom">Productivity</label>
                    <Select value={productivity} id="inputRole" className="date-select br025" name="saledateSelected" onChange={handleProdChange}>
                        {optionsProductivity&&optionsProductivity.map((item)=>{
                            return <MenuItem value={item}>{item}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </div>}
            {document.title==='Comparative Analysis - Odio' && 
            <div className="align-items-baseline justify-content-end mt-2 insights">
                <FormControl sx={{width: 228 }}>
                    <label className="form-label me-1 border-bottom">Analysis</label>  
                    <Select value={analysis} id="inputRole" className="date-select br025" name="saledateSelected" onChange={handleAnalysis}>
                        {defaultAnalysis&&defaultAnalysis.map(item=>{
                            return <MenuItem value={item.value}>
                                   <ListItemText primary={item?.displayName?.length > 20 ? `${item?.displayName.substring(0, 20)}...` : item?.displayName} title={item?.displayName}/>
                                   </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>}
        </>
    )
}

export default InsightsFilter