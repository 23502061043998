import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography, IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './knowledgeBase.css';
import { Button, Input } from '@mui/joy';
import QuizIcon from '@mui/icons-material/Quiz';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import CreateQuestionBankModal from './CreateQuestionBankModal';
import {
  createQuestionBank,
  deleteQuestionBank,
  deleteQuestions,
  getQuestionBankList,
  getQuestionsList,
  updateQuestionBank,
} from '../../Services/questionBank.service';
import EditQuestionModal from './EditQuestionModal';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import toaster from '../../Toast/toaster';
import Loading from '../../Commons/Loading';
import DeleteConfirmationModal from '../../Commons/DeleteConfirmationModal';
import PaginationAlter from '../../Commons/Table/PaginationAlter';
import BubbleLoader from '../../Commons/BubbleLoader';
import { QUESTION_OPTIONS } from '../../../Constants/Enum';
import NoData from '../../../assets/images/coaching-images/NoData.png';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { USER_DETAIL } from '../../../Constants/constant';

const QuestionBank = ({ tabValue, searchQuery, handleSearchClose }) => {
  const [openQueModal, setOpenQueModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [questionBankList, setQuestionBankList] = useState([]);
  const [qBankId, setQBankId] = useState('');
  const [qId, setQId] = useState('');
  const [editedQuesBankId, setEditedQuesBankId] = useState(null);
  const [tempName, setTempName] = useState('');
  const [modalType, setModalType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [delQBank, setDelQBank] = useState(false);
  const [delQues, setDelQues] = useState(false);
  const [delQBankId, setDelQBankId] = useState('');
  const [delQId, setDelQId] = useState('');
  const [quesCreated, setQuesCreated] = useState(false);
  const [qBankUpdated, setQBankUpdated] = useState(false);
  const [delQuesType, setDelQuesType] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [hasMoreQustionSet, setHasMoreQustionSet] = useState(true);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const history = useHistory();
  const { canRead, canDelete, canWrite, canUpdate } = JSON.parse(localStorage.getItem(USER_DETAIL))?.userPermissions;
  const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isCreated, qBankUpdated, delQBank]);

  const handleCreateFolder = () => {
    setOpenQueModal(true);
  };

  const setOpenQueModalFalse = () => {
    setOpenQueModal(false);
  };

  const closeEditQues = () => {
    setOpen(false);
  };

  const handleEditClick = (id, qBankName) => {
    setEditedQuesBankId(id);
    setTempName(qBankName);
  };

  const questionSetCreated = () => {
    setIsCreated(true)
    setResetPage(true);
  };


  const handleDelete = (qbankId, typeOfQues, quesId) => {
    setOpenDeleteModal(true);
    setDelQuesType(typeOfQues);
    // if(typeOfQues=='deleteQBank'){
    setDelQBankId(qbankId);
    // }
    // else{
    setDelQId(quesId);
    // }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const qCreatedTrue = () => {
    setQuesCreated(true);
  };

  const loadMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setShouldFetch(true);
  };

  const handleSaveClick = () => {
    const trimmedTempName = tempName.trim();

    // Ensure name is within the 100-character limit
    if (trimmedTempName.length > 100) {
      toaster.error("The name cannot exceed 100 characters.");
      return;
    }

    // Check for duplicate names
    const isDuplicate = questionBankList.some(
      (questionBank) =>
        questionBank.id !== editedQuesBankId && // Exclude the current editing question set
        questionBank.name.toLowerCase() === trimmedTempName.toLowerCase()
    );

    if (isDuplicate) {
      toaster.error("A question set with this name already exists.");
      return;
    }

    const reqBody = {
      name: trimmedTempName,
      coeIds:
        JSON.parse(localStorage.getItem('AGENT')) &&
        JSON.parse(localStorage.getItem('AGENT')).selectedCoeRange,
    };

    updateQuestionBank(editedQuesBankId, reqBody).then((res) => {
      if (res && res?.data?.status === 0) {
        setResetPage(true);
        setQBankUpdated(true);
        toaster.success("Question set updated successfully");
      } else {
        toaster.error(res?.message || "Failed to update question bank.");
      }
    });
    setEditedQuesBankId(null);
  };


  const redirectToQuestions = (qbankId, qBankName) => {
    history.push(`/knowledge_base/questions?qbankId=${qbankId}`, { title: qBankName, qbankId: qbankId, tabValue: tabValue })
  }

  const resetFetchFlags = () => {
    setIsCreated(false);
    setQuesCreated(false);
    setQBankUpdated(false);
    setDelQBank(false);
    setDelQues(false);
  };

  useEffect(() => {
    if (resetPage) {
      setCurrentPage(1);
      setQuestionBankList([]);
      setShouldFetch(true);
      setResetPage(false);
    } else if (currentPage > 1) {
      setShouldFetch(true);
    }
    resetFetchFlags();
  }, [currentPage, resetPage, tabValue]);

  useEffect(() => {
    if (tabValue === 'qbank') {
      setLoader(currentPage === 1);
      if (searchQuery !== "") {
        handleSearchClose();
      }
      setResetPage(true);
    }
  }, [tabValue])

  useEffect(() => {
    setLoader(true);
    if (delQBank) {
      deleteQuestionBank(delQBankId).then((res) => {
        if (res && res.status == 200) {
          setResetPage(true);
          toaster.success(res?.data?.message);
        } else {
          toaster.error(res?.data?.message);
          setDelQBank(false);
        }
      });
    }
    if (delQues) {
      deleteQuestions(delQBankId, delQId).then((res) => {
        if (res && res.status == 200) {
          toaster.success(res?.data?.message);
        } else {
          toaster.error(res?.data?.message);
          setDelQues(false);
        }
      });
    }
  }, [delQBank, delQues]);

  useEffect(() => {
    if (shouldFetch && tabValue === 'qbank') {
      setLoader(currentPage === 1);
      fetchQuestionBankList();
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      const debounceTimeout = setTimeout(() => {
        setCurrentPage(1);
        setQuestionBankList([]);
        setShouldFetch(true);
      }, 800);

      return () => clearTimeout(debounceTimeout);
    } else if (searchQuery.trim().length === 0) {
      setCurrentPage(1);
      setQuestionBankList([]);
      setShouldFetch(true);
    }
  }, [searchQuery]);

  const fetchQuestionBankList = () => {
    setIsScrollLoading(currentPage > 1);
    getQuestionBankList(
      {
        coeIds: role === 'AGENT' ? [JSON.parse(localStorage.getItem('coeId'))] :
          JSON.parse(localStorage.getItem('AGENT')) &&
          JSON.parse(localStorage.getItem('AGENT')).selectedCoeRange,
        input: searchQuery.length > 0 ? searchQuery : undefined,
      },
      currentPage,
      9
    ).then((res) => {
      if (res && res.status === 200) {
        setQuestionBankList((prev) => currentPage === 1 ? res.data.data.data : [...prev, ...res.data.data.data]);
        setHasMoreQustionSet(currentPage < res.data.data.totalPages);
        setTotalCount(res.data.data.totalPages);
      } else {
        toast.error(res?.data?.message);
      }
    }).finally(() => {
      setLoader(false);
      setIsScrollLoading(false);
    });
  };

  return (
    <>
      {openQueModal && (
        <CreateQuestionBankModal
          openQueModal={openQueModal}
          setOpenQueModalFalse={setOpenQueModalFalse}
          questionSetCreated={questionSetCreated}
          setCurrentPage={setCurrentPage}
          setQuestionBankList={setQuestionBankList}
        />
      )}
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <section>
          {canWrite && <div className="search-input">
            <Button onClick={handleCreateFolder}>Create Question Set</Button>
          </div>}
          <InfiniteScroll
            dataLength={questionBankList.length}
            next={loadMoreData}
            hasMore={hasMoreQustionSet}
            loader={<div className="search-more" style={{ marginTop: '10px' }}>
              {isScrollLoading && <BubbleLoader />}
            </div>}
          >
            <div className="container" style={{ marginTop: '20px' }}>
              <div className="question-grid">
                {questionBankList && questionBankList.length > 0
                  ? questionBankList.map((quesBank) => (
                    <div className="question-child" key={quesBank.id}>
                      <div className="question-content">
                        <QuizIcon sx={{ color: '#0b6bcb', fontSize: '64px', marginBottom: '10px' }} onClick={() => redirectToQuestions(quesBank?.id, quesBank?.name)} />
                        {quesBank?.id === editedQuesBankId ? (
                          <div style={{ display: 'flex', alignItems: 'center', gap: '2px', justifyContent: 'center' }}>
                            <Input
                              type="text"
                              value={tempName}
                              onChange={(e) => setTempName(e.target.value)}
                              placeholder="Enter a name (max 100 characters)"
                              maxLength={100} // Prevents typing beyond 100 characters
                            />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '2px', justifyContent: 'center' }}>
                              {tempName.trim().length > 0 &&
                                tempName.trim().length <= 100 &&
                                questionBankList?.find((questionBank) => questionBank.id === editedQuesBankId)?.name.trim() !== tempName.trim() && (
                                  <Tooltip title="Save">
                                    <IconButton
                                      sx={{ p: 0, pl: 0.7 }}
                                      onClick={handleSaveClick}
                                    >
                                      <DoneIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              <Tooltip title="Discard">
                                <IconButton
                                  sx={{ p: 0, pl: 0.7 }}
                                  onClick={() => setEditedQuesBankId(null)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>

                        ) : (
                          <Typography onClick={() => redirectToQuestions(quesBank?.id, quesBank?.name)}>{quesBank?.name}</Typography>
                        )}
                      </div>
                      <div className="icon-container">
                        {canUpdate && <Tooltip title="Edit Question Set">
                          <IconButton
                            onClick={(e) =>
                              handleEditClick(quesBank?.id, quesBank?.name)
                            }
                            sx={{ p: 0 }}
                          >
                            <EditIcon sx={{ color: '#0B6BCB', pr: 0.7 }} />
                          </IconButton>
                        </Tooltip>}
                        {canDelete && <Tooltip title="Delete Question Set">
                          <IconButton sx={{ p: 0 }}>
                            <DeleteIcon
                              sx={{ color: '#0B6BCB', pr: 0.7 }}
                              onClick={(e) =>
                                handleDelete(quesBank?.id, 'deleteQBank')
                              }
                            />
                          </IconButton>
                        </Tooltip>}
                      </div>
                    </div>
                  ))
                  : ''}
              </div>
            </div>
          </InfiniteScroll>
        </section>
      )}
      {open && (
        <EditQuestionModal
          openEditQues={open}
          closeEditQues={closeEditQues}
          qBankId={qBankId}
          questionId={qId}
          type={modalType}
          qCreatedTrue={qCreatedTrue}
        />
      )}
      {openDeleteModal && (
        <DeleteConfirmationModal
          openDeleteModal={openDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          title={`Delete Question ${delQuesType == 'deleteQBank' ? 'Bank' : ''
            }`}
          delBody={`this Question ${delQuesType == 'deleteQBank' ? 'Bank' : ''
            }`}
          setDelState={(val) =>
            delQuesType == 'deleteQBank' ? setDelQBank(true) : setDelQues(true)
          }
          className={'delQBank'}
        />
      )}

    </>
  );
};

export default QuestionBank;