import React, { useEffect, useState } from "react";
import { addContacts } from "../../../redux-container/settings/company_settings/action";
import { Nav, Tab, Tabs, Row, Col, Sonnet } from "react-bootstrap";
import { connect } from "react-redux";
//import countryCodes from 'country-codes-list'
import CountrySelect from "../../Commons/CountrySelect";
import clientService from "../../Services/client.service";
import Loading from "../../Commons/Loading";
import toast from "../../Toast/toaster";

function Contact(props) {
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    countryCode: "",
    email: "",
  });

  const [updatedContact, setUpdatedContact] = useState({});

  const [formError, setFormErrors] = useState({});
  const [isHide, setIsHide] = useState(true);
  const [color, setColor] = useState("red");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  //const options = countryCodes.customList('countryCode', '{countryNameEn} +{countryCallingCode}');
  const errors = {};

  function handleChange(e) {
    //const { name, value } = e.target;
    //setInputs(inputs => ({ ...inputs, [name]: value }));
    //setUpdatedContact(updatedContact => ({...updatedContact, [name]: value}))
    if (e.target) {
      const { name, value } = e.target;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
      setUpdatedContact((updatedContact) => ({
        ...updatedContact,
        [name]: value,
      }));
      if (e.target.value != "") {
        setDisabled(false);
      } else setDisabled(true);
    } else {
      setInputs((inputs) => ({ ...inputs, countryCode: e.phone }));
      setUpdatedContact((updatedContact) => ({
        ...updatedContact,
        countryCode: e.phone,
      }));
    }
  }

  const validate = (values) => {
    if (!values.firstName) {
      setColor("red");
      errors.firstName = "First Name is required.";
    }
    if (!values.lastName) {
      setColor("red");
      errors.lastName = "Last Name is required.";
    }
    if (values.mobile) {
      if (!values.countryCode) {
        setColor("red");
        errors.countryCode = "Country code is required.";
      }
    }
    return errors;
  };

  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setMessage("");
    validate(inputs);
    setFormErrors(errors);
    if (
      Object.keys(updatedContact).length > 1 &&
      Object.keys(errors).length == 0
    ) {
      clientService
        .updateContact(updatedContact)

        .then((res) => {
          if (res) {
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    } else {
      toast.info("No Values To Update!");
      setLoader(false);
    }
  }

  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      contactId: props.contacts.contactId,
      firstName: props.contacts.firstName,
      lastName: props.contacts.lastName,
      phoneNumber: props.contacts.phoneNumber,
      countryCode: props.contacts.countryCode,
      email: props.contacts.email,
    }));

    setUpdatedContact((updatedContact) => ({
      ...updatedContact,
      contactId: props.contacts.contactId,
    }));
  }, [props.contacts]);

  // const onUpdateContact = () => {
  //   const data= {
  //       contactId: props.contactId,
  //       countryCode: props.contact.countryCode,
  //       email: props.contact.emailId,
  //       firstName: updatedFirstName? updatedFirstName: firstName,
  //       lastName: updatedLastName? updatedLastName: lastName,
  //       mobile: props.contact.mobile
  //   }

  // }

  return (
    <>
      {props.loading ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <div id="primaryContact" className="mt-2">
          <div className="view-team-card-details">
            <form className="row mt-0">
              <div className="col-md-6 mt-3">
                <label htmlFor="inputFirstName" className="form-label">
                  First Name*
                </label>
                <div className="input-group">
                  {" "}
                  <span className="input-group-text bg-transparent py-0">
                    <i className="bx bxs-user" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    style={{textTransform:"capitalize"}}
                    id="inputFirstName"
                    name="firstName"
                    placeholder="First Name"
                    value={inputs.firstName}
                    onChange={handleChange}
                  />
                </div>
                <p
                  class="my-1 mb-0 lh-1 font-12"
                  style={{ height: "18px", color: color }}
                >
                  <span>{formError.firstName}</span>
                </p>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="inputLastName" className="form-label">
                  Last Name*
                </label>
                <div className="input-group">
                  {" "}
                  <span className="input-group-text bg-transparent py-0">
                    <i className="bx bxs-user" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    style={{textTransform:"capitalize"}}
                    id="inputLastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={inputs.lastName}
                    onChange={handleChange}
                  />
                </div>
                <p
                  class="my-1 mb-0 lh-1 font-12"
                  style={{ height: "18px", color: color }}
                >
                  <span>{formError.lastName}</span>
                </p>
              </div>
              <div className="col-md-6 mt-0">
                <label htmlFor="inputPassword" className="form-label">
                  Mobile*
                </label>
                <div className="row g-3">
                  <div className="col-md-7">
                    <CountrySelect
                      value={inputs.countryCode}
                      name="countryCode"
                      handleChange={handleChange}
                    />
                    {/* <select id="inputCountry" className="form-select" onChange={handleChange} name='countryCode'>
                          <option selected value={inputs.countryCode}>Country Code</option>
                          {Object.entries(inputs).map(([key, val], i) => (
                              <option key={i} value={val}>
                                  {key}: {val}
                              </option>
                          ))}
                        </select> */}
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <span className="input-group-text bg-transparent">
                        <i className="lni lni-mobile" />
                      </span>
                      <input
                        type="num"
                        className="form-control"
                        placeholder="Mobile Number"
                        value={inputs.phoneNumber}
                        name="phoneNumber"
                        onChange={handleChange}
                      />
                    </div>

                    <p
                      class="my-1 mb-0 lh-1 font-12"
                      style={{ height: "18px", color: color }}
                    >
                      <span>{formError.countryCode}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-0">
                <label htmlFor="inputEmail" className="form-label">
                  Email*
                </label>
                <div className="input-group">
                  {" "}
                  <span className="input-group-text bg-transparent">
                    <i className="lni lni-envelope" />
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="example@user.com"
                    value={inputs.email}
                    name="email"
                    onChange={handleChange}
                  />
                </div>
                <p
                  class="my-1 mb-0 lh-1 font-12"
                  style={{ height: "18px", color: color }}
                >
                  <span>{formError.email}</span>
                </p>
              </div>
              <div className="col-12 text-sm-end mt-4">
                <button
                  type="submit"
                  className="btn px-5 update-btn lh-base"
                  disabled={disabled ? disabled : ""}
                  id="update-profile"
                  onClick={handleSubmit}
                >
                  {loader ? <Loading variant="light" /> : <> Update</>}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addContacts: (data) => {
      dispatch(addContacts(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(Contact);
