import React, { useState, useEffect } from "react";
//import SimpleTable from "../Commons/Table/SimpleTable";
import ReactTable from "../Commons/Table/ReactTable";
import UserDetailModal from "../Commons/Modal/UserDetailsModal";
import profileService from "../Services/profile.service";
import Loading from "../Commons/Loading";
import Filter from "../Filter/Filter";
import "./myTeam.css";
import { connect } from "react-redux";
import userService from "../Services/user.service";

function MyTeam(props) {
  const [lgShow, setLgShow] = useState(false);
  const [roles, setRoles] = useState([])
  const closeButton = () => setLgShow(false);
  const [managerData, setManagerData] = useState({
    id: "",
    employeeId:"",
    firstName: "",
    lastName: "",
    email: "",
    userPhoneNumbers: [],
    secEmail: [],
    managerId: "",
    manager: "",
    designation: "",
    role: "",
    caller: "",
    coe: [],
    status: "",
  });
  const [callScoreColor, setCallScoreColor] = useState("");
  const [pitchScoreColor, setPitchScoreColor] = useState("");

  const column = [
    {
      Header: "Employee Id",
      accessor: "employeeId",
    },
    {
      Header: "Employee",
      accessor: (d) => `${d.firstName} ${d.lastName}`,
      Cell: (e) => (
        <a
          type="button"
          className="del-coe-btn"
          onClick={() => getManagerData(e)}
          style={{textTransform:"capitalize"}}
        >
          {" "}
          {e.cell.row.original.firstName}{e.cell.row.original.lastName}
        </a>
      ),
    },
    {
      Header: "Designation",
      accessor: (d) => `${d.designation ? d.designation : "-"}`,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Mobile",
      accessor: (d) =>
        `${
          d.mobileNumber?d.mobileNumber:"-"
          // d.userPhoneNumbers
          //   ? d.userPhoneNumbers.length > 0
          //     ? d.userPhoneNumbers[1]
          //       ? d.userPhoneNumbers[1].mobileNumber
          //       : "-"
          //     : "-"
          //   : "-"
        }`,
        disableSortBy: true,
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Caller",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.caller && d.caller === "Yes" ? (
            <i className="lni lni-checkmark"></i>
          ) : (
            "-"
          )}
        </p>
      ),
      disableSortBy: true,
    },
  ];

  const getManagerData = (e) => {
    let managerId = e.cell?.row?.original?.id;
    profileService
      .managerProfile(managerId)
      .then((response) => {
        setLgShow(true);
        let res = response.data.data;
        setManagerData({
          id: res.id,
          employeeId:res.employeeId,
          firstName: response.data.data.firstName,
          lastName: res.lastName,
          email: res.email,
          userPhoneNumbers: [res.userPhoneNumbers],
          secEmail: [res.secondaryEmails],
          managerId: res.managerId,
          manager: res.managerName,
          designation: res.designation,
          role: res.role,
          caller: res.caller,
          coe: [res.clientCoes],
          status: response.data.status,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(()=>{
    userService.getRoleOptions().then(res=>{
      if(res)
        setRoles(res.data.data)
    })
  },[])

  return (
    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <div className="mt-3">
          <div className="d-flex top-fixed-box">
            <div className=" mb-2 text-sm-left myTeamLHeader">
              <h5 className="account-name account-id">My Team</h5>
            </div>
          </div>
          <div className="view-team-card-details">
            <div className="table-responsive dataTable-style react-table">
              <ReactTable
                columns={column}
                componentType="MyTEAM"
                data={props.teamList}
                isEdit={false}
                isDelete={false}
                isExcelPdfButton={true}
                handlePageIndex={(e)=>{console.log()}}
                isDisable={props.teamList.length<=0?true:false} 
              />
            </div>
          </div>
          <Filter
            componentType="FILTER TEAM"
            data={props.teamList}
            dropdownData={props.team}
            roles={roles}
          />

          <UserDetailModal
            lgShow={lgShow}
            closeButton={closeButton}
            setLgShow={setLgShow}
            managerRecords={managerData}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    teamList: state.manage_teams.teamList,
    loader: state.manage_teams.loader,
    team: state.manage_teams.team,
    filteredData: state.manage_teams.filteredData,
  };
};

export default connect(mapStateToProps)(MyTeam);

