import * as React from 'react'
import Loading from '../../Commons/Loading';
import NodataFound from '../../Commons/NoDataFound';
import ReactTable from '../../Commons/Table/ReactTable'

export default function ProductivityData(props){

  let storedData = JSON.parse(localStorage.getItem("AGENT"))?.productivity

    function secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);
      var hDisplay = h > 0 ? h + (h == 1 ? " Hr. " : " Hrs. ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Min. " : " Mins. ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " Sec." : " Secs.") : "";
      return hDisplay + mDisplay + sDisplay; 
    }

    const colorCode = (d)=>{
      // if(d<=100&&d>90){
      //   return(
      //     <span
      //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"
      //       } `}
      //     >
      //       {" "}
      //       {`${d}%`}{" "}
      //     </span>
      //   )
      // }
      // else if(d<=90&&d>80){
      //   return(
      //     <span
      //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"
      //       } `}
      //     >
      //       {" "}
      //       {`${d}%`}{" "}
      //     </span>
      //   )
      // }
      // else if(d<=80&&d>=0){
      //   return(
      //     <span
      //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"
      //       } `}
      //     >
      //       {" "}
      //       {`${d}%`}{" "}
      //     </span>
      //   )
      // }
      if(d?.row?.original?.callScoreColor == "Green"){
        return (
          <span
            className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"
            } `}
          >
            {" "}
            {`${d.value}%`}{" "}
          </span>
        )
      }
      else if(d?.row?.original?.callScoreColor == "Red"){
        return (
          <span
            className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"
            } `}
          >
            {" "}
            {`${d.value}%`}{" "}
          </span>
        )
      }
      else if(d?.row?.original?.callScoreColor == "Amber"){
          return (
            <span
              className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"
              } `}
            >
              {" "}
              {`${d.value}%`}{" "}
            </span>
          )
      }
      else{
        return (
          <span
            className={`rounded-pill btn fw-normal font-13 ${"btn-outline-secondary"
            } `}
          >
            {" "}
            {`${d.value}%`}{" "}
          </span>
        )
      }
    }
  
    const saleColumn = [
        {
          Header: "Employee Id",
          accessor: "userEmployeeid",
        },
        {
          Header: "Name",
          // accessor: (d) => (
          //   // <div style={{textTransform:"capitalize",cursor:"pointer"}} className="teamHover" >
          //   //   {`${d.agentName}`}
          //   // </div>
          //   d.agentName
          // ),
          accessor: "agentName",
            Cell: (cell) => (
                 <div style={{ textTransform: "capitalize", cursor: "pointer" }} className="teamHover">
                  {cell.value}
                  </div>
            ),
        },
    
        {
          Header: "Total Call",
          accessor: "totalCallVolume",
        },
        {
          Header: "Call Duration",
          accessor: (d)=>(
            // <>
            // secondsToHms(d.totalCallDuration)
            d.totalCallDuration
            // </>
          ),
        },
        {
          Header: storedData?storedData:"Call Score",
          accessor: (d) => (
            // <p className="text-center mb-0">
              storedData=='Call Score'&&d.callScore? 
                d.callScore
              :storedData=='Pitch Score'&&d.pitchScore ?
                d.pitchScore
              :storedData=='Conversion Probability'&&d.conversionProbability?
                d.conversionProbability
              :storedData=='opportunity'&& d.opportunity?
                d.opportunity
              :storedData=='Urgency'&&d.urgency?
                d.urgency
              :storedData=='Background Noise'&&d.backgroundNoise?
                d.backgroundNoise
              :storedData=='Customer Engagement'&&d.customerEngagement?
                d.customerEngagement
              :storedData=='Customer Satisfaction'&&d.customerSatisfaction?
                d.customerSatisfaction
              :
              ("0")),
              Cell: (d) => (
                <div className="text-center mb-0">
                  {d.value ?(
                    colorCode(d)
                  ) : (
                    colorCode(0)
                  )}
                </div>
                )
            // {/* </p> */}
        },
        // {
        //   Header: "Pitch Score",
        //   accessor: (d) => (
        //     <p className="text-center mb-0">
        //       {d.pitchScore ? (
        //         colorCode(d.pitchScore)
        //       ) : (
        //         "-"
        //       )}
        //     </p>
        //   ),
        // },
        {
          Header: "Rating",
          accessor: (d) => (
            <p className="text-center mb-0">
              {d.callScoreColor ? (
                <span
                  className={`badge rounded-pill btn fw-normal font-13 ${
                   d.callScoreColor=='Red'?"text-danger" :d.callScoreColor=='Amber'?"text-warning":d.callScoreColor=='Green'?"text-success":"text-info"
                  } `}
                >
                  {" "}
                  <i className="lni lni-star-filled"></i>{" "}
                </span>
              ) : 
              d.pitchScoreColor ? (
                <span
                  className={`badge rounded-pill btn fw-normal font-13 ${
                   d.pitchScoreColor=='Red'?"text-danger" :d.pitchScoreColor=='Amber'?"text-warning":d.pitchScoreColor=='Green'?"text-success":"text-info"
                  } `}
                >
                  {" "}
                  <i className="lni lni-star-filled"></i>{" "}
                </span>
              ) :
              (
                "-"
              )}
            </p>
          ),
        },
      ];
   
    return(
        <>
        {props.loader?(
            <div className='loader-container'>
                <Loading  variant="dark"/>
            </div>
        ):props?.data?.length>0?(
        <div className="table-responsive">
            <div className="table-columns-filter-btn">
                <div className="table-responsive dataTable-style react-table">
                    <ReactTable
                        columns={saleColumn}
                        componentType="PRODUCTIVITY"
                        data={props.data}
                        isExcelPdfButton={true}
                        isDisable={props.data.length==0||props.data==undefined?true:false}
                    />
                </div>
            </div>
        </div>):<NodataFound/>
        }
        </>
    )}