import React, { useEffect, useRef, useState } from "react";

const AudioStreamer = ({ socketUrl }) => {
  const audioRef = useRef(null);
  const [audioFileUrl, setAudioFileUrl] = useState(null);
  const [customParameters, setCustomParameters] = useState(null);
  const [channels, setChannels] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [socket, setSocket] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const audioSourceRef = useRef(null);
  const dataOffsetRef = useRef(0);
  const streamingRef = useRef(false);
  const timerIdRef = useRef(null);
  const leftChannelDataRef = useRef(null);
  const rightChannelDataRef = useRef(null);
  const chunkNumberRef = useRef(0);
  const sequenceNumberRef = useRef(0);
  const [totalChunks, setTotalChunks] = useState(0);
  const sampleRate = 8000;
  const [metaDataFetched,setMetaDataFetched] = useState()
  const fetchMetadata = async (callId) => {
    try {
      const response = await fetch(
        `https://livesttsocket.odioiq.com/metacall?call_id=${callId}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const metadata = await response.json();
      const { url, channels, details } = metadata.data;
      setMetaDataFetched(metadata?.data);
      setAudioFileUrl(url);
      setChannels(channels);
      setCustomParameters(details);
      setHasStarted(true);
      setErrorMessage(null);
      openWebSocket(url, channels, details);

      if (url) {
        return { url, channels, details };
      } else {
        throw new Error("Audio URL not found in the response");
      }
    } catch (error) {
      throw error;
    }
  };
const [uniqueSTreamId,setUniqueSTreamId] = useState('');
  const generateStreamSid = () => {
    const random32BitHex = [...Array(8)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
      setUniqueSTreamId(`EZ${random32BitHex}`);
    return `EZ${random32BitHex}`;
  };

  const openWebSocket = (url, channels, details) => {
    const ws = new WebSocket(socketUrl);

    ws.onopen = () => {
      const streamSid = generateStreamSid();
      const connectedPayload = {
        event: "connected",
        protocol: "Call",
        version: "1.0.0",
        streamSid,
      };
      ws.send(JSON.stringify(connectedPayload));

      sendStartPayload(ws, streamSid, url, channels, details);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onmessage = (message) => {
      if (message.data.includes("mediaEnded")) {
        handleMediaEnded(ws);
      }
    };

    setSocket(ws);
  };

  const sendStartPayload = (ws, streamSid, url, channels, details) => {
    if (!details || !channels) return;

    const startPayload = {
      event: "start",
      sequenceNumber: sequenceNumberRef.current.toString(),
      start: {
        accountSid: "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        streamSid: streamSid,
        callSid: "CAXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        tracks: ["inbound", "outbound"],
        mediaFormat: {
          encoding: "audio/x-mulaw",
          sampleRate: sampleRate,
          channels: 1,
        },
        customParameters: {
            "clientName": details?.client?.name,
            "clientExternalId": details?.client?.externalId,
            "coeName": details?.coe?.name,
            "coeExternalId": details?.coe?.externalId,
            "agentName": details?.agent?.name,
            "agentExternalId": details?.agent?.externalId,
            "customerName": details?.customer?.name,
            "customerPhoneNumber": details?.customer?.phoneNumber,
            'momentBucketId': details?.momentBucketId,
        },
      },
      streamSid: streamSid,
    };

    const jsonString = JSON.stringify(startPayload);

    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(jsonString);
    }
  };

 //    Send media payload to WebSocket
 const convertToBase64 = (float32Array) => {
    return btoa(Array.from(float32Array).join(','));


  };
  
  // Send media payload with Base64 encoding
  const sendMediaPayload = (chunkData, track, timestamp, ws) => {
    if (!ws || ws.readyState !== WebSocket.OPEN || audioRef.current.paused) return;
  
    const base64Payload = convertToBase64(chunkData); // Convert chunk data to Base64
   
    const mediaPayload = {
      event: "media",
      sequenceNumber: sequenceNumberRef.current.toString(),
      media: {
        payload: base64Payload, // Send Base64 payload
        track: track,
        timestamp: timestamp,
        chunk: chunkNumberRef.current,
        totalChunks: totalChunks,
      },
      streamSid: uniqueSTreamId,
    };
  
    ws.send(JSON.stringify(mediaPayload));
    sequenceNumberRef.current += 1; // Increment the sequence number
  };
  

  // Start streaming media payloads at intervals
    const startStreaming = (ws) => {
     if (
        !audioBuffer ||
        !leftChannelDataRef.current ||
        !rightChannelDataRef.current
      ) {
         return;
     }
    
     if (ws && ws.readyState === WebSocket.OPEN) {
      streamingRef.current = true;
      dataOffsetRef.current = 0;
      chunkNumberRef.current = 1;
      sequenceNumberRef.current = 0;

     const chunkSize = 1024;
     const totalSamples = audioBuffer.length;
     dataOffsetRef.current = 0;
     const interval = (chunkSize / audioBuffer.sampleRate) * 1000;
     
     const track=['inbound','outbound'];
     const sendData = () => {
        if (!streamingRef.current || dataOffsetRef.current >= totalSamples) {
        stopWebSocket(ws); // Stop when all chunks are sent
        return;
      }
      const leftChannelChunk = leftChannelDataRef.current.subarray(
        dataOffsetRef.current,
        dataOffsetRef.current + chunkSize
      );
      const rightChannelChunk = rightChannelDataRef.current.subarray(
        dataOffsetRef.current,
        dataOffsetRef.current + chunkSize
      );
      const timestamp = (audioRef.current.currentTime*1000);
      sendMediaPayload( leftChannelChunk, 'inbound', timestamp, ws);
      sendMediaPayload( rightChannelChunk, 'outbound', timestamp, ws);
      chunkNumberRef.current++;
      sequenceNumberRef.current++;
      dataOffsetRef.current += chunkSize;
     };

     timerIdRef.current = setInterval(sendData, interval);};

    }

 const stopWebSocket = (ws) => {
        streamingRef.current = false;
        clearInterval(timerIdRef.current);
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
  };
  


  const handleMediaEnded = (ws) => {
    if (ws.readyState === WebSocket.OPEN) {
      const stopPayload = {
        event: "stop",
        sequenceNumber: sequenceNumberRef.current,
        stop: {
          accountSid: "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
          callSid: "CAXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        },
        streamSid: uniqueSTreamId,
      };
      ws.send(JSON.stringify(stopPayload));
      ws.close();
    }
  };

  const handleStart = async () => {
    const callId = document.getElementById("callId").value;
    if (!callId) {
      setErrorMessage("Please enter a valid Call ID.");
      return;
    }

    try {
      const { url, channels, details } = await fetchMetadata(callId);
    } catch (error) {
      setErrorMessage("No data available for the provided Call ID.");
    }
  };

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      startStreaming(socket);
  };
}

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      
    }
    
  };

  const handleEnded = () => {
  };

  useEffect(() => {
    const fetchAndDecodeAudio = async () => {
      if (!audioFileUrl) {
        console.error("Audio file URL is not defined.");
        return;
      }

      try {
        const response = await fetch(audioFileUrl);
        if (!response.ok) {
          throw new Error("Error fetching audio file.");
        }

        const arrayBuffer = await response.arrayBuffer();
        const context = new AudioContext({ sampleRate });
        const decodedAudioData = await context.decodeAudioData(arrayBuffer);

        setAudioBuffer(decodedAudioData);
        setAudioContext(context);

        const leftChannelData = decodedAudioData.getChannelData(0);
        const rightChannelData =
          decodedAudioData.numberOfChannels > 1
            ? decodedAudioData.getChannelData(1)
            : new Float32Array(leftChannelData.length);

        leftChannelDataRef.current = leftChannelData;
        rightChannelDataRef.current = rightChannelData;
        setTotalChunks(Math.ceil(leftChannelData.length / 1024));

      } catch (error) {
        console.error("Error fetching or decoding audio:", error);
      }
    };

    if (audioFileUrl) {
      fetchAndDecodeAudio();
    }
  }, [audioFileUrl]);

  const buttonStyle = {
    border: "2px solid skyblue",
    borderRadius: "5px",
    width: "60px",
    height: "30px",
    backgroundColor: "#0dcaf0",
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "10px",
  };

  const StartButton = ({ onClick }) => (
    <button style={buttonStyle} onClick={onClick}>
      Start
    </button>
  );


  return (
    <div style={divStyle}>
      {!hasStarted && (
        <>
          <input
            className="form-control border-end-0"
            id="callId"
            placeholder="Enter Call ID here"
            name="Call Id"
            type="number"
          />
          <StartButton onClick={handleStart} />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </>
      )}
      {hasStarted && audioFileUrl && (
        <audio
            ref={audioRef}
            controls
            src={audioFileUrl}
            //crossOrigin="anonymous"
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
        />
      )}
    </div>
  );};

export default AudioStreamer;