import React, { Fragment, useEffect, useState } from "react"
import { deleteQuestions, getQuestionBankList, getQuestionsList } from "../../Services/questionBank.service";
import toaster from "../../Toast/toaster";
import Loading from "../../Commons/Loading";
import { Accordion} from "react-bootstrap";
import EditQuestionModal from "./EditQuestionModal";
import DeleteConfirmationModal from "../../Commons/DeleteConfirmationModal";
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NoData from '../../../assets/images/coaching-images/NoData.png'
import DocumentBanner from "./DocumentBanner";
import { Button} from '@mui/joy';
import { QUESTION_OPTIONS } from '../../../Constants/Enum';
import QuizIcon from '@mui/icons-material/Quiz';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const QuestionBankDetail = ()=>{

    const [questions, setQuestions] = useState([])
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(false);
    const [quesCreated, setQuesCreated] = useState(false)
    const [qBankId, setQBankId] = useState(history.location.state.qbankId)
    const [qId, setQId] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [delQuesType, setDelQuesType] = useState(false)
    const [delQBankId, setDelQBankId] = useState('')
    const [delQId, setDelQId] = useState('')
    const [delQBank, setDelQBank] = useState(false)
    const [delQues, setDelQues] = useState(false)
    const [bannerTabList, setBannerTabList] = useState([]);
    const [qbankName, setQbankName] = useState('')
    const queryParams = new URLSearchParams(window.location.search)
    // const qbankId = queryParams.get('qbankId'

    const handleOpen = (qBankId, qId, type) => {
        setOpen(true);
        // if(type=='edit'){
        setQBankId(qBankId)
        setQId(qId)
        setModalType(type)
    }

    useEffect(() => {
        let tabList = []
        getQuestionBankList({ "coeIds": JSON.parse(localStorage.getItem("AGENT")).selectedCoeRange }).then((res) => {
            tabList = res?.data?.data?.map(item => ({
                eventKey: item.id,
                title: item.name
            }))
            setBannerTabList(tabList);
            let fname = tabList?.find(tab => tab.eventKey === history.location.state?.qbankId);
            setQbankName(fname?.title);
        })
        .catch(err => console.log(err))

    }, [])

    const handleDelete = (qbankId, typeOfQues, quesId)=>{
        setOpenDeleteModal(true)
        setDelQuesType(typeOfQues)
        // if(typeOfQues=='deleteQBank'){
          setDelQBankId(qbankId)
        // }
        // else{
          setDelQId(quesId)
        // }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const qCreatedTrue = ()=>{
        setQuesCreated(true)
    }

    const closeEditQues = ()=>{
        setOpen(false)
    }

    const handleTabSelect = (eventKey) => {
        setQBankId(eventKey);
        const selectedFolder = bannerTabList.find(tab => tab.eventKey == eventKey);
        setQbankName(selectedFolder?.title || '');
    }

    const fetchQuestionsList = ()=>{
        getQuestionsList(qBankId).then(res=>{
            if(res && res?.status==200){
              setQuestions(res?.data?.data)
              setDelQues(false)
              setLoader(false)
            }
            else{
              toaster.error(res?.data?.message);
              setLoader(false)
            }
        })
    }

    const setLoaderTrue = ()=>{
        setLoader(true)
    }

    useEffect(()=>{
        if(delQues){
            setLoader(true)
            deleteQuestions(delQBankId, delQId).then(res=>{
                if(res && res.status==200){
                    toaster.success(res?.data?.message)
                    fetchQuestionsList()
                }
                else{
                    toaster.error(res?.data?.message)
                    setDelQues(false)
                    setLoader(false)
                }
            })
        }
    },[delQBank, delQues])

    useEffect(()=>{
        setLoader(true)
        fetchQuestionsList()
    },[qBankId])


    return(
        <div className="page-wrapper">
            <div className="page-content">
                <DocumentBanner bannerTabList={bannerTabList} handleTabSelect={handleTabSelect} folderID={qBankId} folderName={qbankName}/>
                {loader ? 
                    <div className="loader-container">
                        <Loading variant="dark" />
                    </div>
                : (
                    <div className='container'>
                        <div className="q-bank-heading">
                            <h5>
                                <QuizIcon sx={{ color: '#0b6bcb', fontSize: '30px', marginRight:'10px' }}/>
                                {qbankName}
                            </h5>
                            <div>
                                <Button onClick={(e)=>handleOpen(qBankId,'','Create')}>Create Question</Button>
                            </div>
                        </div>
                        <div style={{gap: '16px' }}>
                        {questions && questions.length>0 ? 
                            questions.map((question, i) => (
                                <Fragment key={question.id}>
                                    <Accordion defaultActiveKey={questions.map((_,i)=>i.toString())} className="q-bank">
                                        <Accordion.Item eventKey={i.toString()} key={question.id}>
                                        <Accordion.Header eventKey={i}>
                                            <div className="qbank-icon">
                                                <div>
                                                    <span style={{marginLeft:"30px"}}>
                                                        <span>{i + 1}. </span>
                                                        <span>{question.question}</span>
                                                        <caption className='caption-style'>{QUESTION_OPTIONS?.find(item=>item?.value === question?.questionType)?.label}</caption>
                                                    </span>
                                                </div>
                                                <div>
                                                    <Tooltip title="Update Question ">
                                                        <IconButton sx={{ p: 0 }}>
                                                            <EditIcon sx={{ color: '#0B6BCB', pr: 0.7 }} onClick={()=>handleOpen(qBankId, question?.id, 'Edit')}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Question">
                                                        <IconButton sx={{ p: 0 }}>
                                                            <DeleteIcon sx={{ color: '#0B6BCB', pr: 0.7 }} onClick={()=>handleDelete(qBankId, 'deleteQues',question?.id)}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body eventKey={i.toString()}>
                                            <div>
                                                {question.options.map((option, index) => (
                                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginLeft: '6%' }}>
                                                        <span>{String.fromCharCode(65 + index)}. &nbsp;</span>
                                                        <span>{option.option}</span>
                                                    </div>
                                                ))}
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-10" style={{display:"flex"}}>
                                                        <span style={{marginTop:"5px"}}>{`Correct ${question?.questionType=='OBJECTIVE_WITH_OPTIONS' ? 'Option' : 'Answer'}: `}</span>
                                                        <span style={{marginLeft:"9px", backgroundColor:"hsl(0, 0%, 90%)", borderRadius:"7px", padding:"4px"}}>
                                                            { question?.questionType=='OBJECTIVE_WITH_OPTIONS' ? 
                                                                question.options.filter((item,index)=>{
                                                                    if(item.isCorrect == true){
                                                                        item.id = index
                                                                        return true
                                                                    }
                                                                }).map((option, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {index > 0 && <br />}
                                                                        <span>{String.fromCharCode(65 + option.id)}. &nbsp;</span>
                                                                        <span>{option.option}</span>
                                                                    </React.Fragment>
                                                                ))
                                                                :
                                                                <React.Fragment>
                                                                    <span>{question?.answer}</span>
                                                                </React.Fragment>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Fragment>
                            ))
                        :
                        <div className='d-flex justify-content-center align-items-center' style={{marginTop:'75px'}}>
                            <img style={{ width: '8rem' }} src={NoData} />
                            <span style={{ fontSize: 'x-large', color: 'darkgray' }}>No questions available to show.</span>
                        </div>
                        }  
                        </div>
                    </div>
                )}
            </div>
            {open && <EditQuestionModal openEditQues={open} closeEditQues={closeEditQues} qBankId={qBankId} questionId={qId} type={modalType} qCreatedTrue={qCreatedTrue} fetchQuestionsList={fetchQuestionsList} setLoaderTrue={setLoaderTrue}/>}
            {openDeleteModal && <DeleteConfirmationModal openDeleteModal={openDeleteModal} handleCloseDeleteModal={handleCloseDeleteModal} title='Delete Question' delBody={`this Question`} 
                setDelState={(val) => setDelQues(true)} className={'delQBank'} fetchQuestionsList={fetchQuestionsList}/>
            }
        </div>
    )
}

export default QuestionBankDetail