import Accordion from "react-bootstrap/Accordion";
import { useState, useEffect } from "react";
import CommentOnModal from "../Modals/CommentOnModal";
import EmailTranscript from "../Modals/EmailTranscript";
import callsService from "../../../Services/calls.service";
import notificationsService from "../../../Services/notifications.service";
import toaster from "../../../Toast/toaster";

export default function All_Signals(props){
    const [likeButton, setLikeButton] = useState(false)
    const [dislikeButton, setDislikeButton] = useState(false)
    const [mailButton, setMailButton] = useState(false)
    const [commentButton, setCommentButton] = useState(false)
    const [emailShow, setEmailShow] = useState(false)
    const [commentShow, setCommentShow] = useState(false)
    const [signalId, setSignalId] = useState("")
    const [signals,setSignals] = useState("")
    const [speaker,setSpeaker] = useState("")
    const [likeId, setLikeId] = useState("")
    const [dislikeId, setDislikeId] = useState("")
    const [commentId, setCommentId] = useState("")
    const [commentData, setCommentData] = useState({})
    const [likeDislike, setLikeDislike] = useState([])

    const likeButtonHandler = (id,label)=>{
      setLikeButton(true)
      setLikeId(id)
      let value={
        "callId": props?.callId,
        "commentType": "SIGNALS",
        "dislike": null,
        "like": 1,
        "postId": id
      }
      callsService.addLikeDislike(value).then(res=>{
        if(res){
          callsService.getLikeDislike(props?.callId,"SIGNALS").then(res=>{
            if(res){
              setLikeDislike(res.data.data)
              setLikeButton(false)
            }
          })
        }
      })
      let notificationBody = [{
        "callId":props?.callId,
        "userExternalId":props?.agentExternalId,
        "mobileNumber":props?.phone,
        "notificationEvent":{
          "eventType":"LIKE",
          "tabName":"signals",
          "tabValue":label,
          "postId":id,
        }
      }]
      notificationsService.createNotifications(notificationBody).then(res=>{
        if(res.status==200){
          toaster.success(res.data.message)
        }
      })
    }

    const dislikeButtonHandler = (id,label)=>{
      setDislikeButton(true)
      setDislikeId(id)
      let value={
        "callId": props?.callId,
        "commentType": "SIGNALS",
        "dislike": 0,
        "like": null,
        "postId": id
      }
      callsService.addLikeDislike(value).then(res=>{
        if(res){
          callsService.getLikeDislike(props?.callId,"SIGNALS").then(res=>{
            if(res){
              setLikeDislike(res.data.data)
              setDislikeButton(false)
            }
          })
        }
      })
      let notificationBody = [{
        "callId":props?.callId,
        "userExternalId":props?.agentExternalId,
        "mobileNumber":props?.phone,
        "notificationEvent":{
          "eventType":"DISLIKE",
          "tabName":"signals",
          "tabValue":label,
          "postId":id,
        }
      }]
      notificationsService.createNotifications(notificationBody).then(res=>{
        if(res.status==200){
          toaster.success(res.data.message)
        }
      })
    }
    
    const commentButtonHandler = (id,trans,speaker)=>{
      // setCommentButton(true)
      setCommentShow(true)
      setSignalId(id)
      setSignals(trans)
      setCommentId(id)
      setSpeaker(speaker)
    }
    const updateCommentState = ()=>{
      setCommentButton(true)
      setCommentShow(true)
      // setId(id)
      // setStats(label)
    }
    const mailHandler = ()=>{ 
      setEmailShow(true)
      setMailButton(true)
    }
    const closeButton = ()=>{
      setCommentShow(false)
      setEmailShow(false)
    }

    const callback = (e)=>{
      setCommentData(e)
    }

    useEffect(()=>{
      if(props?.eventType=='COMMENT'&&props?.tabRedirected=='signals'){
        setCommentShow(true)
        setSignalId(props?.postId)
      }
    },[])

    const signalSeller = (heading,name,id)=>{

      const idsFunc = (value)=>{
        // let transNew = Object.entries(commentData?.postTypeComments&&Object.values(commentData?.postTypeComments).length>0?commentData?.postTypeComments:props?.callSignals?.postTypeComments).filter(val=>{
        //   return (val[1][0].postId==value.index)
        // })

        let transNew = [];

// Check if commentData and commentData.postTypeComments are defined and have length > 0
if (commentData?.postTypeComments && Object.values(commentData.postTypeComments).length > 0) {
  transNew = Object.entries(commentData.postTypeComments)
    .filter(val => val[1][0].postId === value.index); // Assuming `value` is defined elsewhere
}
// If commentData.postTypeComments is not valid, check if props and props.callSignals are defined
else if (props?.callSignals?.postTypeComments) {
  transNew = Object.entries(props.callSignals.postTypeComments)
    .filter(val => val[1][0].postId === value.index); // Assuming `value` is defined elsewhere
}

// transNew now contains filtered entries based on postId matching value.index

        let commentIds = transNew.length>0?transNew[0][1]:''
        return(
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {commentIds&&commentIds[0].postId==value.index||commentButton&&commentId==value.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(value.index,value.trans,"seller")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {commentIds&&commentIds[0].postId==value.index||commentButton&&commentId==value.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(value.index,value.trans,"seller")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        )
      }

      const likeFunc = (id, heading)=>{
        let likeIcon = likeDislike.length>0? likeDislike.filter(item=>{
          return(item.postId==id)
        }):
        props?.likeDislikeData.filter(item=>{
          return(item.postId==id)
        })
        return(
          <div className="row w-100 mx-1">
            <div className="col-6">
              <div className="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6 signal-hover">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id? '':
                <button className="btn list-like-before" onClick={()=>likeButtonHandler(id, heading)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-like"></i>
                  </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id, heading)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
              </div>
            </div>
          </div>
        )
      }
        return(
          <>
            <Accordion.Item eventKey={id} className={`${props?.postId==id&&props?.eventType=='LIKE'&&props?.tabRedirected=='signals'?'shadow':props?.postId==id&&props?.eventType=='DISLIKE'&&props?.tabRedirected=='signals'?'shadow':''}`}>
              <Accordion.Header>
                <div className="accordion-button-stats">
                  <h6 style={{"textTransform":"capitalize",color:`${name.length>0?'#32393f':'#757778'}`}} className="mb-0">
                    {heading}
                  </h6>
                </div>
                {name.length>0?likeFunc(id, heading):''}
              </Accordion.Header>
              {name?.map((value)=>{
                return(
                value.speaker===props?.transAgentSpeakerId?<Accordion.Body>
                  <div className="accordion-body">
                    <div className={`${props?.postId==value?.index&&props?.eventType=='COMMENT'&&props?.tabRedirected=='signals'?'shadow':""} list-group-item`}>
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-0 font-16">
                          <i className="bx bx-user-voice me-1" />
                          {value.speaker===props?.transAgentSpeakerId?props?.seller:""}
                          <span className="badge rounded-pill bg-light-blue text-dark f-normal">{`${value.speaker=== props?.transAgentSpeakerId?"seller":""}`}</span>
                        </h5>
                        <small className="text-muted">
                          Dec 16, 2020 13:14 Hrs
                        </small>
                      </div>
                      <p className="mb-1">
                        {value.trans}
                      </p>
                      {idsFunc(value)}
                      
                    </div>
                  </div>
                </Accordion.Body>:""
              )})}
            </Accordion.Item>
          </>
        )
    } 
    const signalCustomer = (heading,name,id)=>{
      const idsFunc=(value)=>{
        // let transNew = Object.entries(commentData?.postTypeComments&&Object.values(commentData?.postTypeComments).length>0?commentData?.postTypeComments:props?.callSignals?.postTypeComments).filter(val=>{
        //   return (val[1][0].postId==value.index)
        // })
        let transNew = [];

// Check if commentData and commentData.postTypeComments are defined and not empty
if (commentData?.postTypeComments && Object.values(commentData.postTypeComments).length > 0) {
  transNew = Object.entries(commentData.postTypeComments)
    .filter(val => val[1][0]?.postId === value.index); // Ensure val[1][0] is defined before accessing postId
}
// If commentData.postTypeComments is not valid, check if props and props.callSignals are defined
else if (props?.callSignals?.postTypeComments && Object.values(props.callSignals.postTypeComments).length > 0) {
  transNew = Object.entries(props.callSignals.postTypeComments)
    .filter(val => val[1][0]?.postId === value.index); // Ensure val[1][0] is defined before accessing postId
}

// transNew now contains filtered entries based on postId matching value.index

        let commentIds = transNew.length>0?transNew[0][1]:''
        return(
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {commentIds&&commentIds[0].postId==value.index||commentButton&&commentId==value.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(value.index,value.trans,"customer")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {commentIds&&commentIds[0].postId==value.index||commentButton&&commentId==value.index?'':  
                  <button className="btn list-comment-before switcher-btn-1" 
                  onClick={()=>commentButtonHandler(value.index,value.trans,"customer")}
                  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                    <span className="text-primary-blue">
                      <i className="lni lni-comments-alt"></i>
                    </span>
                  </button>
                }
              </div>
            </div>
          </div>
        )
      }
     
      const likeFunc=(id,heading)=>{
        let likeIcon = likeDislike.length>0? likeDislike.filter(item=>{
          return(item.postId==id)
        }):
        props?.likeDislikeData.filter(item=>{
          return(item.postId==id)
        })
        return(
          <div class="row w-100 mx-1">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {/* {mailButton?<button class="btn compose-mail-btn" onClick={mailHandler} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats"><span class="text-primary-blue"><i class="bx bx-mail-send"></i></span></button>:''} */}
              </div>
            </div>
            <div class="col-6 signal-hover">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id||dislikeButton&&dislikeId==id? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(id,heading)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id||likeButton&&likeId==id ? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id,heading)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        )
      }
        return(
            <>
            <Accordion.Item eventKey={id} className={`${props?.postId==id&&props?.eventType=='LIKE'&&props?.tabRedirected=='signals'?'shadow':props?.postId==id&&props?.eventType=='DISLIKE'&&props?.tabRedirected=='signals'?'shadow':''}`}>
            <Accordion.Header>
              <div className="accordion-button-stats">
                <h6 style={{"textTransform":"capitalize",color:`${name.length>0?'#32393f':'#757778'}`}} className="mb-0">
                  {heading}
                </h6>
              </div>
              {name.length>0?likeFunc(id,heading):''}
            </Accordion.Header>
            {name?.map((value)=>{
              return(
              value.speaker!==props?.transAgentSpeakerId?<Accordion.Body>
                <div className="accordion-body">
                  <div className={`${props?.postId==value?.index&&props?.eventType=='COMMENT'&&props?.tabRedirected=='signals'?'shadow':""} list-group-item`}>
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-0 font-16">
                        <i className="bx bx-user-voice me-1" />
                        {value.speaker!==props?.transAgentSpeakerId?props?.customer:""}
                        <span className="badge rounded-pill bg-light-blue text-dark f-normal">{`${value.speaker!==props?.transAgentSpeakerId?"customer":""}`}</span>
                      </h5>
                      <small className="text-muted">
                        Dec 16, 2020 13:14 Hrs
                      </small>
                    </div>
                    <p className="mb-1">
                      {value.trans}
                    </p>
                    {idsFunc(value)}
                  </div>
                </div>
              </Accordion.Body>:""
            )})}
          </Accordion.Item>
        </>
        )
    }

    const signalAll = (heading,name,id)=>{

      const idsFunc = (value)=>{
        // let transNew = Object.entries(commentData?.postTypeComments&&Object.values(commentData?.postTypeComments).length>0?commentData?.postTypeComments:props?.callSignals?.postTypeComments).filter(val=>{
        //   return (val[1][0].postId==value.index)
        // })
        let transNew = [];

if (commentData && commentData.postTypeComments && Object.values(commentData.postTypeComments).length > 0) {
  transNew = Object.entries(commentData.postTypeComments).filter(val => {
    return val[1][0].postId == value.index; // Assuming `value` is defined elsewhere
  });
} else if (props && props.callSignals && props.callSignals.postTypeComments) {
  transNew = Object.entries(props.callSignals.postTypeComments).filter(val => {
    return val[1][0].postId == value.index; // Assuming `value` is defined elsewhere
  });
}

// Use `transNew` array as needed here

        let commentIds = transNew.length>0?transNew[0][1]:''
        return(
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {commentIds&&commentIds[0].postId==value.index||commentButton&&commentId==value.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(value.index,value.trans,value.speaker===props?.transAgentSpeakerId?"customer":"seller")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {commentIds&&commentIds[0].postId==value.index||commentButton&&commentId==value.index?'':  
                  <button className="btn list-comment-before switcher-btn-1" 
                  onClick={()=>commentButtonHandler(value.index,value.trans,value.speaker===props?.transAgentSpeakerId?"customer":"seller")}
                  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                    <span className="text-primary-blue">
                      <i className="lni lni-comments-alt"></i>
                    </span>
                  </button>
                }
              </div>
            </div>
          </div>
        )
      }

      const likeFunc = (id)=>{
        let likeIcon = likeDislike.length>0?likeDislike.filter(item=>{
          return(item.postId==id)
        }):
        props?.likeDislikeData.filter(item=>{
          return(item.postId==id)
        })
        return(
        <div class="row w-100 mx-1">
          <div class="col-6"> 
            <div class="list-after-click-group">
              {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
              likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
            </div>
          </div>
          <div class="col-6 signal-hover">
            <div className="list-hover-input-group">
              {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id? ''
              :
              <button className="btn list-like-before" onClick={()=>likeButtonHandler(id,heading)}>
                <span className="text-primary-blue">
                  <i className="bx bx-like"></i>
                </span>
              </button>}
              {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id? ""
              :
              <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id,heading)}>
                <span className="text-primary-blue">
                  <i className="bx bx-dislike"></i>
                </span>
              </button>}
              {/* {commentButton&&signalId==id+1?'':  
              <button className="btn list-comment-before switcher-btn-1" 
              onClick={()=>commentButtonHandler(index,value.trans,value.speaker==='0'?"customer":"seller")}
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                <span className="text-primary-blue">
                  <i className="lni lni-comments-alt"></i>
                </span>
              </button>
              } */}
            </div>
          </div>
        </div>)
      }
      
        return(
            <>
            <Accordion.Item eventKey={id} className={`${props?.postId==id&&props?.eventType=='LIKE'&&props?.tabRedirected=='signals'?'shadow':props?.postId==id&&props?.eventType=='DISLIKE'&&props?.tabRedirected=='signals'?'shadow':''}`}>
            <Accordion.Header>
              <div className="accordion-button-stats">
                <h6 style={{"textTransform":"capitalize",color:`${name.length>0?'#32393f':'#757778'}`}} className="mb-0">
                  {props.type=='chat'?heading.replace('Call','Revert'):heading}
                </h6>
              </div>
              {name.length>0?likeFunc(id,heading):''}
            </Accordion.Header>
            {name?.map((value)=>{
              return(
              <Accordion.Body>
                {/* {value.map(data=>{
                return( */}
                  <div className="accordion-body">
                    <div className={`${props?.postId==value?.index&&props?.eventType=='COMMENT'&&props?.tabRedirected=='signals'?'shadow':""} list-group-item`}>
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-0 font-16">
                          <i className="bx bx-user-voice me-1" />
                          {value.speaker!== props?.transAgentSpeakerId?props?.customer:value.speaker=== props?.transAgentSpeakerId ?props?.seller:""}
                          <span className="badge rounded-pill bg-light-blue text-dark f-normal">{`${value.speaker!== props?.transAgentSpeakerId ?"customer":"seller"}`}</span>
                        </h5>
                        <small className="text-muted">
                          {`${props?.callSignals?.date}, ${value.startTime}`}
                          {/* Dec 16, 2020 13:14 Hrs */}
                        </small>
                      </div>
                      <p className="mb-1">
                        {value.trans}
                      </p>
                      {idsFunc(value)}
                    </div>
                  </div>
                {/* )})} */}
              </Accordion.Body>
            )})}
          </Accordion.Item>
        </>
        )
    }

    return(
        <Accordion defaultActiveKey="0">
        {props.callSignals&&Object.keys(props.callSignals).length>0?Object.entries(props.callSignals.signals).map((item,id)=>{
          return(
            props?.keyClicked==='seller'?
              signalSeller(item[0],item[1],id):
            props?.keyClicked==='customer'?
              signalCustomer(item[0],item[1],id):
            props?.keyClicked==='all'?
              signalAll(item[0],item[1],id):""
        )}) : null}
        {commentShow && signalId!=='' && <CommentOnModal updateState={updateCommentState}  commentShow={commentShow} closeButton={closeButton} tabSelected={props?.tabRedirected=='signals'?props?.tabRedirected:props?.tabSelected} callId={props?.callId} signalId={signalId} signals={signals} signalSpeaker={speaker} callback={callback} phone={props?.phone} agentExternalId={props?.agentExternalId}/>}
        <EmailTranscript show={emailShow} closeButton={closeButton} callSignals={props.callSignals} tabSelected={props.tabSelected} seller={props?.sellerName} customer={props?.customerName} callId={props?.callId} agentId={props?.agentId}/>
        </Accordion>
    )
}