import React from "react";
import slackLogoIcon from "../../../assets/images/slackLogoIcon.png";
import gmailLogoIcon from '../../../assets/images/gmail.png';
import zohoLogoIcon from '../../../assets/images/zoho.png';
import outlookLogoIcon from '../../../assets/images/outlook.png';

export default function Integrations({ urlSlack }) {
    let { sharedUrl, appName } = urlSlack;

    const handleClick = (serviceName) => {
        alert(`You clicked on Add ${serviceName} button`);
    };

    return (<>
        <div id="primaryIntegrations" className="mt-2">
            <div className="d-flex mt-4">
                {appName ? <>
                <h4>Your slack is already integrated with {appName} App.</h4>
                    
                </> : <>
                    <div className="ms-2 app-Integrations-content">
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-center">
                                <img src={slackLogoIcon} className="aap-ing-img" alt="user avatar" />
                                <div>
                                    <h4 className="mb-0 font-16">Slack</h4>
                                    <p className="mb-1 font-14">You will receive notifications on Slack.</p>
                                    <a className="btn bg-medium-blue text-white font-14 px-5 py-1 text-nowrap" href={sharedUrl} onClick={() => handleClick('Slack')}>Add Slack</a>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex align-items-center">
                                <img src={gmailLogoIcon} className="aap-ing-img" alt="gmail logo" style={{ marginRight: '10px' }} />
                                <div>
                                    <h4 className="mb-0 font-16">Gmail</h4>
                                    <p className="mb-1 font-14">You will receive notifications on Gmail</p>
                                    <a href="#" className="btn bg-medium-blue text-white font-14 px-5 py-1 text-nowrap"  onClick={() => handleClick('Gmail')}>Add Gmail</a>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex align-items-center">
                                <img src={zohoLogoIcon} className="aap-ing-img" alt="gmail logo" style={{ marginRight: '10px' }} />
                                <div>
                                    <h4 className="mb-0 font-16">Zoho</h4>
                                    <p className="mb-1 font-14">You will receive notifications on Zoho</p>
                                    <a href="#" className="btn bg-medium-blue text-white font-14 px-5 py-1 text-nowrap" onClick={() => handleClick('Zoho')}>Add Zoho</a>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex align-items-center">
                                <img src={outlookLogoIcon} className="aap-ing-img" alt="gmail logo" style={{ marginRight: '10px' }} />
                                <div>
                                    <h4 className="mb-0 font-16">Outlook</h4>
                                    <p className="mb-1 font-14">ou will receive notifications on Outlook</p>
                                    <a href="#" className="btn bg-medium-blue text-white font-14 px-5 py-1 text-nowrap"  onClick={() => handleClick('Outlook')}>Add Outlook</a>
                                </div>
                            </div>            
                        </div>
                    </div>
                </>}
            </div>
        </div >
    </>)
}