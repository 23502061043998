import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import odio_logo_blue from "../../assets/images/odio_logo_blue.png";
import logoIcon from "../../assets/images/logoIcon.png";
import automatIcon from "../../assets/images/automateazylogo.svg";
import az from "../../assets/images/az.svg";
import CollapselogoIcon from "../../assets/images/o-pic-new.png";
import "./commons.css";
import "./sideBar.css";
import { USER_PERMISSIONS, USER_DETAIL } from "../../Constants/constant";
import { NavLink,useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function SideBar({ hideToShow, sideBarHovered, sideBarUnhovered }) {
  //const [show, setShow] = useState(false);
  let details = localStorage.getItem(USER_DETAIL);
  const user = JSON.parse(localStorage.getItem("USER_DETAIL"))
  const azLogo = user?.userExternalId == 56686202
  const path = useLocation();
  const coe = useSelector(state => state.commonReducer.coe)
  const [activeKey, setActiveKey] = useState(path.pathname == "/voice" || path.pathname == "/chat" ? 'Conversations' : 'Dashboard')
  const [tabList, setTabList] = useState({
    Dashboard: [
      // {
      //   name: "Sales",
      //   icon: "lni lni-money-protection",
      //   code: "SALES_DASHBOARD",
      //   type: "SALES",
      //   api: "/sales_dashboard",
      //   groupName: "Dashboard",
      //   groupIcone: "bx bx-grid-alt",
      //   mainSequence: 1,
      //   groupSequence: 1,
      // },
    ],
    Conversations: [
      // {
      //   api: "/sales_conversations",
      //   code: "SALES_DASHBOARD",
      //   groupIcone: "bx bx-user-voice",
      //   groupName: "Conversations",
      //   groupSequence: 1,
      //   icon: "lni lni-money-protection",
      //   mainSequence: 2,
      //   name: "Sales",
      //   type: "SALES",
      // },
      // {
      //   api: "/support_conversations",
      //   code: "SUPPORT_DASHBOARD",
      //   groupIcone: "bx bx-user-voice",
      //   groupName: "Conversations",
      //   groupSequence: 2,
      //   icon: "lni lni-laptop-phone",
      //   mainSequence: 2,
      //   name: "Support",
      //   type: "SUPPORT",
      // },
      // {
      //   api: "/sales_dashboard",
      //   code: "VOICE",
      //   groupIcone: "bx bx-user-voice",
      //   groupName: "Conversations",
      //   groupSequence: 3,
      //   icon: "lni lni-mic",
      //   mainSequence: 2,
      //   name: "Voice",
      //   type: "BOTH",
      // },
      // {
      //   api: "/sales_dashboard",
      //   code: "CHAT",
      //   groupIcone: "bx bx-user-voice",
      //   groupName: "Conversations",
      //   groupSequence: 4,
      //   icon: "lni lni-wechat",
      //   mainSequence: 2,
      //   name: "Chat",
      //   type: "BOTH",
      // },
      // {
      //   api: "/sales_dashboard",
      //   code: "EMAIL",
      //   groupIcone: "bx bx-user-voice",
      //   groupName: "Conversations",
      //   groupSequence: 5,
      //   icon: "lni lni-envelope",
      //   mainSequence: 2,
      //   name: "Email",
      //   type: "BOTH",
      // },
    ],
    Coaching: [],
    Insights: [
      // {
      //   api: "/sales_dashboard",
      //   code: "ACTIVITY_LOG",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 1,
      //   icon: "lni lni-clipboard",
      //   mainSequence: 3,
      //   name: "Activity Log",
      //   type: "ACTIVITY_LOG",
      // },
      // {
      //   api: "/sales_dashboard",
      //   code: "PRODUCTIVITY",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 2,
      //   icon: "lni lni-graph",
      //   mainSequence: 3,
      //   name: "Productivity",
      //   type: "PRODUCTIVITY",
      // },
      /////////////////////////////////////////////////////
      // {
      //   api: "/sales_dashboard",
      //   code: "FOLLOW-UPS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 3,
      //   icon: "lni lni-spinner-arrow",
      //   mainSequence: 3,
      //   name: "Follow-ups",
      //   type: "FOLLOW_UPS",
      // },
      // {
      //   api: "sales_dashboard",
      //   code: "OBJECTIONS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 4,
      //   icon: "lni lni-unlink",
      //   mainSequence: 3,
      //   name: "Objections",
      //   type: "OBJECTIONS",
      // },
      /////////////////////////////////////////////////
      // {
      //   api: "/sales_dashboard",
      //   code: "COMPARATIVE-ANALYSIS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 5,
      //   icon: "bx bx-git-compare",
      //   mainSequence: 3,
      //   name: "Comparative analysis",
      //   type: "COMPARATIVE-ANALYSIS",
      // },
      // {
      //   api: "/duration_metrics",
      //   code: "DURATION-METRICS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 6,
      //   icon: "bx bx-time",
      //   mainSequence: 3,
      //   name: "Duration metrics",
      //   type: "DURATION-METRICS",
      // },
      // {
      //   api: "/conversation_metrics",
      //   code: "CONVERSATION_METRICS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 7,
      //   icon: "bx bx-conversation",
      //   mainSequence: 3,
      //   name: "Conversation metrics",
      //   type: "CONVERSATION_METRICS",
      // },
    ],
    Teams: [
      // {
      //   api: "/team",
      //   groupIcone: "lni lni-users",
      // },
    ],
    Notifications: [
      // {
      //   api: "/notifications",
      //   groupIcone: "bx bx-bell",
      // },
    ],
    Reports: [
      // {
      //   api: "/reports",
      //   groupIcone: "lni lni-database",
      // },
    ],
    KnowledgeAI: [],
    LiveAssist:[],
    // Support: [
    //   {
    //     api: "/faq",
    //     code: "FAQ",
    //     groupIcone: "bx bx-support",
    //     groupName: "Support",
    //     groupSequence: 1,
    //     icon: "bx bx-help-circle",
    //     mainSequence: 7,
    //     name: "FAQ",
    //     type: "FAQ",
    //   },
    //   {
    //     api: "/sales_dashboard",
    //     code: "USER_MANUAL",
    //     groupIcone: "bx bx-support",
    //     groupName: "Insights",
    //     groupSequence: 2,
    //     icon: "bx bx-file",
    //     mainSequence: 7,
    //     name: "User Manual",
    //     type: "USER_MANUAL",
    //   },
    //   {
    //     api: "/contact_support",
    //     code: "CONTACT_SUPPORT",
    //     groupIcone: "bx bx-support",
    //     groupName: "Support",
    //     groupSequence: 3,
    //     icon: "contact-support-link",
    //     mainSequence: 7,
    //     name: "Contact Support",
    //     type: "CONTACT_SUPPORT",
    //   },
    // ],
  });
  
  useEffect(() => {
    var p = localStorage.getItem(USER_PERMISSIONS);
    let data = JSON.parse(p);

    let dashboard = data.filter((a) => a["groupName"] === "Dashboard");
    let conversations = data.filter((a) => a["groupName"] === "Conversations");
    let coaching = data.filter((a) => a["groupName"] === "Coaching");
    let insights = data.filter((a) => a["groupName"] === "Insights");
    let team = data.filter((a) => a["groupName"] === "Team");
    let notification = data.filter( a => a['groupName'] === 'Notifications');
    let reports = data.filter((a) => a["groupName"] === "Reports");
    // let support = data.filter( a => a['groupName'] === 'Support');
    let knowledge = data.filter((a) => a["groupName"] === "KnowledgeAI");
    let liveassist = data.filter((a) => a["groupName"] === "LiveAssist");
    setTabList((tabList) => ({
      ...tabList,
      Dashboard: dashboard,
      Conversations: conversations,
      Coaching: coaching,
      Insights: insights,
      Teams: team,
      Reports: reports,
      KnowledgeAI: knowledge,
      LiveAssist: liveassist,
      Notifications: notification
    }));
  }, [coe]);

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  /* const handleTabChange = (e)=>{
    document.getElementsByClassName("")
  } */


  const displayTabs = (val,tab) => {
    val.sort((a, b) => a.groupSequence - b.groupSequence);
    return val.map((v) => (
      <div key={v.name}>
        <NavLink to={v.api} key={v.name} activeClassName="mm-active">
          <i className={v.icon}></i>
          {v.name}
        </NavLink>
      </div>
    ));
  };

  const isAccordionHeaderActive = (location, tabList, tab) => {
    // Check if any child link inside the Accordion.Body is active
    return tabList[tab].some((menu) => location.pathname.startsWith(menu.api));
  };
  

  var i = 0;
  var p;

  const [show, setShow] = useState(true)
  const [hovered, setHovered] = useState(false);
  const [lock,setLock] = useState(false)
  const clickHandler = ()=>{
    setShow(!show)
    setLock(!lock)
    hideToShow()
  }
  
  const checkRoute = (location,tab) => {
    switch (tab){
      case "Conversations":
        return tabList[tab].length > 1 ? tabList[tab].some((menu) => location.pathname.startsWith(menu.api)) :
         (location.pathname.startsWith(tabList[tab][0]["api"]) || location.pathname.includes('sales_call_detail'))
      
      case "Teams":
        return (location.pathname.startsWith(tabList[tab][0]["api"]) || location.pathname.includes('team_member_detail'))
      
      case "Insights":  
        return tabList[tab].some((menu) => location.pathname.startsWith(menu.api));

      // case "Coaching":
      //   return tabList[tab].some((menu) => )

      default:
        return location.pathname === tabList[tab][0]["api"]
    }
  }

  const handleSidebarHovered = () => {
    setHovered(true);
    sideBarHovered();
  };

  const handleSidebarUnhovered = () => {
    setHovered(false);
    sideBarUnhovered();
  };
  
  return (
    <>
    <div
      className="sidebar-wrapper"
      data-simplebar="true"
      onMouseEnter={handleSidebarHovered}
      onMouseLeave={lock?handleSidebarHovered:handleSidebarUnhovered}
    >
      <div className="sidebar-header">
      {hovered?<div>
          <h4 className="logo-text">
            <img src={ azLogo ? az : odio_logo_blue} className="logo-img az" alt="logo icon" />
          </h4>
        </div>: <div>
          {azLogo ?
          <img src={hovered ? az : automatIcon} className="logo-icon" alt="logo icon" /> 
          :
          <img src={hovered ? logoIcon : CollapselogoIcon} className="logo-icon" alt="logo icon" />
          }
        </div>}
        <div className="toggle-icon ms-auto">
          <i className={`bx bxs-chevrons-${show?'right':'left'} custom-icon-color`} onClick={clickHandler}></i>
        </div>
      </div>

      <ul className="metismenu side_nav_scroll" id="menu">
      <Accordion>
      {Object.keys(tabList).map((tab) => {
        const isHeaderActive = isAccordionHeaderActive(path, tabList, tab);  // Checks if any child is active
        return tabList[tab].length > 1 ? (
          <Accordion.Item
            eventKey={tab}
            key={tab}
            className="sidebar-custom-collapse"
          >
            {/* Accordion Header - should not have an active state on click */}
            <Accordion.Header className={`parent-icon ${isHeaderActive ? 'mm-active' : ''}`}>
              <span className={`d-flex align-items-center px-0 ${isHeaderActive ? 'text-white' : ''}`}>
                <i className={tabList[tab][0]["groupIcone"]}></i>
                <span className="menu-title">{tab}</span>
              </span>
            </Accordion.Header>
            {/* Accordion Body - Contains child NavLinks */}
            <Accordion.Body>{displayTabs(tabList[tab])}</Accordion.Body>
          </Accordion.Item>
        ) : tabList[tab].length != 0 ? (
          <Accordion.Item>
            <Accordion.Header className="parent-icon hide_icon">
              <NavLink
                to={tabList[tab][0]["api"]}
                className={`d-flex align-center px-0 ${tab}`}
                // activeClassName="mm-active"
                isActive={(match,location)=> checkRoute(location,tab)}
              >
                {" "}
                <i className={tabList[tab][0]["groupIcone"]}></i>
                <span className="menu-title">{tab}</span>
              </NavLink>
            </Accordion.Header>
          </Accordion.Item>
        ) : (
          ""
        )
      })}
      </Accordion>
      </ul>
    </div>
    </>
  );
}

export default SideBar;
