import { Button, Card, CardActions, CardContent, CardMedia, MenuItem, Select, Skeleton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import first from './1.png'
import '../Meeting/meeting.css'
import { motion } from "framer-motion"
import { useHistory } from 'react-router-dom'
import { getMeetingListing } from "../../Services/meeting.service"
import dashboardService from "../../Services/dashboard.service"
import UpdateMeetingDetailModal from './UpdateMeetingDetailModal'
import MeetingAdHocModal from './MeetingAdHocModal'
import NodataFound from '../../Commons/NoDataFound'
import FilterDrawer from '../../Commons/FilterDrawer'
import { useDispatch, useSelector } from "react-redux";
import { onResetFilter, setDateList, setResetState } from '../../../redux-container/meeting/action'
import { dateFormat, positionFormat } from '../../Commons/DateFormatter'

function Meeting() {
  const history = useHistory()
  const [meetingData, setMeetingData] = useState([])
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [optionsCard, setOptionsCard] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('test1');
  const [show, setShow] = useState(false)
  const [openAdHocModal, setOpenAdHocModal] = useState(false)
  const [noMeetingFound, setNoMeetingFound] = useState(false)
  const [dates, setDates] = useState([])
  const [apply, setApply] = useState(false)
  const [reset, setReset] = useState(false)
  const dispatch = useDispatch()
  let filteredData = useSelector(state => state?.meetings?.filterData)
  let localData = JSON.parse(localStorage.getItem("AGENT"))

  const getDateAcronym = () => {
    dashboardService.getDateAcronym().then(res => {
      let dateList = res ? res.data ? res.data.data.filter((e) => {
        return e.type == "CURRENT" || e.type == "GLOBAL"
      }) : '' : ''
      setDates(dateList);
      dispatch(setDateList(dateList))
      // let dd = dateList[4].fromDate.split("-")[2]
      // let mm = dateList[4].fromDate.split("-")[1]
      // let yyyy = dateList[4].fromDate.split("-")[0]
      // let dateF = `${dd}-${mm}-${yyyy}`
      // let dd_1 = dateList[4].toDate.split("-")[2]
      // let mm_1 = dateList[4].toDate.split("-")[1]
      // let yyyy_1 = dateList[4].toDate.split("-")[0]
      // let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
    })
  }

  const getMeetingData = () => {
    const val = {
      "usersExternalIds": [JSON.parse(localStorage.getItem("USER_DETAIL"))?.userExternalId],
      "coeExternalIds": localData?.coe,
      "filters": {
        "channelName": filteredData.channelName ? filteredData.channelName : "google_meet",
        "meetingDuration": filteredData.meetingDurationRange ? filteredData.meetingDurationRange : [0, 100],
        "meetingId": filteredData.meetingId ? filteredData.meetingId : ''
      },
      "fromD": filteredData.fromD ? filteredData.fromD : localData?.fromD,
      "toD": filteredData.toD ? filteredData.toD : localData?.toD,
      "conversationType": 1
    }
    getMeetingListing(val).then((res) => {
      if (res.data.status === 0) {
        setMeetingData(res.data.data)
        setNoMeetingFound(false)
      } else {
        setNoMeetingFound(true)
      }
      setLoading(false)
      setApply(false)
      setReset(false)
      dispatch(setResetState(false))
    }).catch(() => {
      setLoading(false)
      setApply(false)
      setReset(false)
      dispatch(setResetState(false))
    })
  }

  useEffect(() => {
    document.title = "Meetings - Odio";
    setLoading(true)
    getDateAcronym()
    getMeetingData()
  }, []);

  useEffect(() => {
    if (apply) {
      getMeetingData()
    }
    if (reset) {
      const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      dispatch(onResetFilter({ "productivity": "Call Score", "fromD": `${firstDay.getDate()}-${firstDay.getMonth() + 1}-${firstDay.getFullYear()}`, "toD": `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`, "selectedRange": 5, "selectedDate": "This Month" }))
      dispatch(setResetState(true))
      getMeetingData()
    }
  }, [apply, reset])

  const handleMoreOptionsClick = (id) => {
    if (optionsCard === id) {
      setOptionsCard(null);
    } else {
      setOptionsCard(id);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const showMeetingModal = () => {
    setShow(true)
  }

  const applyFilter = () => {
    setApply(true)
  }

  const resetFilter = () => {
    setReset(true)
  }

  const setLoaderTrue = () => {
    setLoading(true)
  }

  const formatChannelName = (channelName) => {
    if (!channelName) return '';  
    return channelName
      .replace(/_/g, ' ')        
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="page-wrapper">
      <div className="page-content" style={{ paddingTop: '61px', padding: '0' }}>
        <div className="d-inline meeting-title">
          <h2 className="meeting-headings d-inline-block">
            Meetings
          </h2>
          <>
            {filteredData && filteredData?.dateName ? 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                {`DATE: ${filteredData?.dateName === "Custom Date" ? `${positionFormat(filteredData?.fromD)} to ${positionFormat(filteredData?.toD)}`: filteredData?.dateName }`}
                <a href="javascript:;"></a>
              </span>
            : 
              <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                <span>DATE: </span>
                {`${localData?.selectedDate}`} 
                <a href="javascript:;"></a>
              </span>
            }
            {filteredData.channelName ? <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`Channel: ${formatChannelName(filteredData.channelName)}`}<a href="javascript:;"></a></span> : <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`Channel : Google Meet`}<a href="javascript:;"></a></span>}
            {filteredData.meetingDuration ? <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`Duration: ${filteredData.meetingDuration}`}<a href="javascript:;"></a></span> : <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`DURATION: [0, 100]`}<a href="javascript:;"></a></span>}
            {filteredData.meetingId ? <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`Meeting Id: ${filteredData.meetingId}`}<a href="javascript:;"></a></span> : ""}
          </>
        </div>
        <div className="">
          {loading ? (
            <div className="skeleton-cards-container">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
              <motion.div key={index} className='skeleton-card-item' whileHover={{ scale: 1.04 }}>
                <Card className='card-meeting'>
                  <Skeleton variant="rectangular" width="100%" height={140} />
                  <CardContent style={{ padding: '12px' }}>
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                    <Skeleton width="80%" />
                    <hr className='divider' />
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                    <hr className='divider' />
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                  </CardContent>
                  <CardActions className='card-buttons'>
                    <Skeleton variant="rectangular" width="30%" height={30} />
                    <Skeleton variant="rectangular" width="30%" height={30} />
                  </CardActions>
                </Card>
              </motion.div>
            ))}
            </div>
          ) : (
            noMeetingFound ? <div className="nodatafound-container"><NodataFound /></div> :
            <div className="cards-container">
              {meetingData.map((item, index) => (
                // <motion.div className='card-item' whileHover={{ scale: 1.04 }} key={index}>
                <div className='card-item'>
                  <Card className='card-meeting'>
                    <CardMedia
                      component="img"
                      height="140"
                      image={first}
                      alt="green iguana"
                      className='card-media'
                    />
                    <CardContent style={{ padding: '10px' }}>
                      <div className='card-recording-detail'>
                        <p className='card-recording-detail-name'>{item.hostName}</p>
                        <p className='card-recording-detail-time'>{`${item.date}`}</p>
                        <p className='card-recording-detail-recorder'>Recorded By: <span className='p-text-change'>{item.hostName} </span></p>
                      </div>
                      <hr className='divider' />
                      <div className='card-client'>
                        <span className='card-client-detail'>
                          <p className='card-client-detail-title'>Client</p>
                          <p>{item.clientName ? item.clientName : '--'}</p>
                        </span>
                        <span className='card-client-detail'>
                          <p className='card-client-detail-title'>Client ID</p>
                          <p>{item.clientExternalId ? item.clientExternalId : '--'}</p>
                        </span>
                      </div>
                      <hr className='divider' />
                      <div>
                        <span className='card-participants'>Participants</span>
                        {item?.participants && <Tooltip title={Object.values(item?.participants).join(', ')} arrow placement="top-start">
                          <p className='card-participants-text'>{Object.values(item?.participants).join(', ')}</p>
                        </Tooltip>}
                      </div>
                      <Card>
                        {optionsCard === item.id && (
                          <CardActions className='options-card'>
                            <Button size="small" className='options-button' onClick={() => setOpenModal(true)}>Update Details</Button>
                            <Button size="small" className='options-button'>Delete this record</Button>
                          </CardActions>
                        )}
                      </Card>
                    </CardContent>
                    <CardActions className='card-buttons'>
                      {/* <Button size="small" className='card-buttons-name' onClick={() => handleMoreOptionsClick(item.id)}>More Options</Button> */}
                      <Button size="small" className='card-buttons-name' onClick={() => { history.push(`/meeting_view_detail?meetingId=${item.uniqueId}`, { participants: item?.participants }) }}>View Details</Button>
                    </CardActions>
                  </Card>
                  </div>
                // </motion.div>
              ))}
              </div>
          )}
          <div className="meeting-upload-btn">
            <Button
              onClick={() => setOpenAdHocModal(true)}
            >
              <i className="lni lni-plus"></i>
            </Button>
          </div>
        </div>
        <UpdateMeetingDetailModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setOptionsCard={setOptionsCard}
          selectedOption={selectedOption}
          handleSelectChange={handleSelectChange}
        />
        <MeetingAdHocModal
          openAdHocModal={openAdHocModal}
          setOpenAdHocModal={setOpenAdHocModal}
        />
        <FilterDrawer filterType='MEETINGS' componentType='MEETINGS' applyFilter={applyFilter} setLoaderTrue={setLoaderTrue} resetFilter={resetFilter} />
      </div>
    </div>
  );
}

export default Meeting