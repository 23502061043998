import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../ManageUser/manageUser.css";
import clientService from '../../Services/client.service';
import toaster from '../../Toast/toaster';

function AddNewCompetitor(props) {
    const [loading, setLoading] = useState(false)
    const [competitor, setCompetitor] = useState({
        competitorData: [{
            competitorName: '',
            sector: '',
            competitorAliases: []
        }]
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompetitor(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                [name]: value
            }]
        }));
    };

    const handleAliasChange = (e, index) => {
        const { value } = e.target;
        setCompetitor(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                competitorAliases: prevState.competitorData[0].competitorAliases.map((alias, i) =>
                    i === index ? { aliasName: value } : alias
                )
            }]
        }));
    };

    const addAlias = () => {
        setCompetitor(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                competitorAliases: [...prevState.competitorData[0].competitorAliases, { aliasName: '' }]
            }]
        }));
    };

    const removeAlias = (index) => {
        setCompetitor(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                competitorAliases: prevState.competitorData[0].competitorAliases.filter((_, i) => i !== index)
            }]
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await clientService.createCompetitor(competitor).then(res => {
            if (res.data.status === 200) {
                props.getCompetitorsList()
                props.setIsOpen(false);
                toaster.success(res.data.message)
            } else {
                toaster.error(res.data.message)
            }
        })
        .catch(error => {
            props.setIsOpen(true);
        })
        .finally(() => {
            setLoading(false);
        });
        setCompetitor({
            competitorData: [{
                competitorName: '',
                sector: '',
                competitorAliases: []
            }]
        })
    }

    return (
        <>
            <div className="mt-3 addEmploy-form">
                <div className="d-flex align-items-center">
                    <div>
                        <i className="bx bxs-user me-1 font-22 edit-profile-icon" />
                    </div>
                    <h5 className="mb-0 edit-profile-header">Add New Competitor</h5>
                </div>
                <hr />
                <form className="row g-3" onSubmit={handleFormSubmit}>
                    <div className='col-md-6' style={{ marginRight: '50px' }}>
                        <div className="col-md-12 mb-3">
                            <label className="form-label">
                                Competitor Name*
                            </label>
                            <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i className="bx bxs-user" />
                                </span>
                                <input
                                    placeholder="Enter Competitor Name"
                                    className="form-control"
                                    name="competitorName"
                                    value={competitor.competitorData[0].competitorName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="form-label">
                                Competitor Sector*
                            </label>
                            <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i className="bx bxs-user" />
                                </span>
                                <input
                                    placeholder="Enter the sector"
                                    className="form-control"
                                    name="sector"
                                    value={competitor.competitorData[0].sector}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        {competitor.competitorData[0].competitorAliases.map((alias, index) => (
                            <div key={index} className="col-md-12 mb-3">
                                <label className="form-label">
                                    Alias Name
                                </label>
                                <div className='d-flex' style={{ gap: '15px' }}>
                                    <div className="input-group" style={{ flex: '6' }}>
                                        <span className="input-group-text bg-transparent">
                                            <i className="bx bxs-user" />
                                        </span>
                                        <input
                                            required
                                            placeholder={`Enter Alias Name`}
                                            className="form-control"
                                            name="aliasName"
                                            value={alias.aliasName}
                                            onChange={(e) => handleAliasChange(e, index)}
                                        />
                                    </div>
                                    <div className='' style={{ flex: '1' }}>
                                        <Button type="button" className='update-btn px-2 lh-base border-0' onClick={() => removeAlias(index)}>
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Button type="button" className={`update-btn px-2 lh-base border-0 ${competitor.competitorData[0].competitorAliases.length === 0 ? "add-alias-btn" : ""}`} onClick={addAlias}>
                            Add Alias
                        </Button>
                    </div>
                    <div className="col-12 text-sm-right cancelUpadte-btn text-end">
                        <Button
                            className="me-2 px-4 lh-base"
                            variant="light"
                            onClick={() => props.setIsOpen(false)}
                        >
                            <span>
                                <i className="bx bx-arrow-back me-1 blk_clr"></i>
                            </span>
                            Cancel
                        </Button>
                        <Button type="submit" className="update-btn px-5 lh-base border-0" disabled={loading}>
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddNewCompetitor;