import React, { useState } from 'react';
import './knowledgeBase.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function DocumentBanner(props) {
  const history = useHistory()
  const tabValue = history.location.state?.tabValue
  const [showAllTabs, setShowAllTabs] = useState(false);
  const visibleTabs = showAllTabs
    ? props?.bannerTabList
    : props?.bannerTabList?.slice(0, 10);

  return (
    <>
      <div className="header-banner">
        <div className="container">
          {props?.folderName && (
            <Breadcrumb className="custom-breadcrumb">
              <Breadcrumb.Item onClick={()=>history.push('/knowledge_base',{tabValue:tabValue})}>{tabValue==='resource'?'Knowledge base':'Question Bank'}</Breadcrumb.Item>
              <Breadcrumb.Item style={{color:'white'}} active>{props?.folderName}</Breadcrumb.Item>
            </Breadcrumb>
          )}
          <div className="text-layout">
            <div className="column-left">
              <h1 className="category-name"></h1>
            </div>
            <div
              className="column-left d-flex justify-content-between"
              style={{ width: '100%' }}
            >
              <h1 className="category-name">{props?.folderName}</h1>
              <div className="knowledge-base-banner-s-container">
                <input
                  id="search"
                  className="knowledge-base-banner-search"
                  type="text"
                  placeholder="How can we help?"
                  autocomplete="off"
                  value={props?.searchQuery} 
                  onChange={props?.handleSearchChange}
                />
                <i class="bx bx-search search"></i>
                <i class='bx bx-x close' onClick={props?.handleSearchClose}></i>
              </div>
            </div>
          </div>
          <div className="d-flex document-banner-tab-container">
            <div
              className="navigation-list"
              style={{ flex: '0 1 calc(100% - 80px)' }}
            >
              <Tabs
                defaultActiveKey={props?.folderID}
                id="uncontrolled-tab-example"
                className=""
                onSelect={(selectedKey) => props?.handleTabSelect(selectedKey)}
              >
                {visibleTabs?.map((tab) => (
                  <Tab
                    eventKey={tab.eventKey}
                    title={tab.title}
                    key={tab.eventKey}
                  />
                ))}
              </Tabs>
            </div>
            <span
              className="toggle-button document-banner-button"
              onClick={() => setShowAllTabs(!showAllTabs)}
              style={{ display: props?.bannerTabList?.length < 11 && 'none' }}
              dis
            >
              {showAllTabs ? '- Less' : '+ More'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentBanner;
