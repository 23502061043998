import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  ModalClose,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/joy";
import Button from "@mui/joy/Button";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import conversationService from "../../Services/conversation.service";
import { Autocomplete } from "@mui/material";
import toaster from "../../Toast/toaster";
import profileService from "../../Services/profile.service";
import sessionService from "../../Services/session.service";
import UpdateModules from "./UpdateModules";

function SessionUpdateModal(props) {
  const {
    openModal,
    setOpenModal,
    rowDataEditCase,
    setHasModalClosed,
    setSessionCreatedOrUpdated,
  } = props;

  const [moments, setMoments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [sessionData, setSessionData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedMoments, setSelectedMoments] = useState([]);

  const localData = JSON.parse(localStorage.getItem("AGENT"));
  const localUserData = JSON.parse(localStorage.getItem("USER_DETAIL"));

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      sessionName: "",
      userId: null,
      momentIds: [],
      comment: "",
    },
  });

  const getUsersList = async () => {
    const reqBody = {
      coeIds: localData?.selectedCoeRange,
      fromD: localData?.fromD,
      toD: localData?.toD,
      userId: localUserData?.userId,
    };
    try {
      const response = await profileService.getCoachingAgents(reqBody);
      if (response?.data?.status === 0) {
        const filterTeam = response.data?.data?.team?.filter(
          (team) => team?.role === "AGENT"
        );
        setUsersList(filterTeam);
      } else {
        toaster.error("Error while fetching the Users data, please try again");
      }
    } catch (error) {
      console.error("Api call error: ", error);
    }
  };

  const getMomentData = async () => {
    const momentBucketId =
      parseInt(localStorage.getItem("MOMENT_ID")) || undefined;
    try {
      const res = await conversationService.getCallListingMoments(
        "SALES",
        momentBucketId
      );
      if (res.data?.status === 0) {
        setMoments(res?.data?.data);
        setLoading(false);
      } else {
        toaster.error("Error while fetching the moments, please try again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionDetails = async () => {
    try {
      const response = await sessionService.getSessionData(rowDataEditCase?.id);
      if (response?.data?.status === 0) {
        setSessionData(response?.data.data);
        setSelectedUser(
          usersList.find((user) => user.id === response?.data?.data.agentId)
        );
        setSelectedMoments(response?.data?.data?.moments || []);
      } else {
        toaster.error(
          "Error while fetching the Session data, please try again"
        );
      }
    } catch (error) {
      console.error("Api call error: ", error);
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      name: data.sessionName,
      userId: selectedUser ? selectedUser.id : null,
      momentIds: selectedMoments.map((moment) => moment.momentId),
      comment: data.comment,
    };

    try {
      const res = await sessionService.updateSession(
        rowDataEditCase?.id,
        formData
      );
      if (res?.data?.status === 0) {
        setSessionCreatedOrUpdated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Promise.all([getMomentData(), getUsersList(), getSessionDetails()])
      .then(() => setLoading(false))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // After fetching sessionData and usersList, set initial form values
    if (sessionData && usersList.length > 0) {
      reset({
        sessionName: sessionData.name || "",
        comment: sessionData.comments || "",
      });
      setSelectedUser(
        usersList.find((user) => user.id === sessionData.agentId) || null
      );
      setSelectedMoments(sessionData.moments || []);
    }
  }, [sessionData, usersList, reset]);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        setHasModalClosed(true);
      }}
      sx={{
        backdropFilter: "blur(1px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "15px",
      }}
      disableEscapeKeyDown
    >
      <ModalDialog sx={{ width: "75%", height: "90vh", overflowY: "scroll" }}>
        {loading ? (
          <>Loading...</>
        ) : (
          <div>
            <ModalClose />
            <DialogTitle>Update Coaching</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack sx={{ flexDirection: "row", gap: "2rem" }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Session Name</FormLabel>
                  <Controller
                    name="sessionName"
                    control={control}
                    rules={{ required: "Please provide a Session Name" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Add session name"
                        error={!!errors.sessionName}
                        helperText={errors.sessionName?.message}
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Applicable Representatives</FormLabel>
                  <Autocomplete
                    options={usersList || []}
                    getOptionLabel={(option) =>
                      `${option.firstName || ""} ${
                        option.lastName || ""
                      }`.trim()
                    }
                    filterSelectedOptions
                    onChange={(event, value) => setSelectedUser(value)}
                    value={selectedUser}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select user"
                        error={!!errors.userId}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack sx={{ flexDirection: "row", gap: "2rem", mt: 2 }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Select moments</FormLabel>
                  <Autocomplete
                    multiple
                    options={moments}
                    getOptionLabel={(option) => option.momentName}
                    filterSelectedOptions
                    onChange={(event, value) => setSelectedMoments(value)}
                    value={selectedMoments}
                    isOptionEqualToValue={(option, value) =>
                      option.momentId === value.momentId
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Moments"
                        error={!!errors.moments}
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Comment</FormLabel>
                  <Controller
                    name="comment"
                    control={control}
                    rules={{ required: "Please provide a comment" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Add Comment"
                        error={!!errors.comment}
                        helperText={errors.comment?.message}
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack sx={{ flexDirection: "row-reverse", mt: 1 }}>
                <Button type="submit">Update session details</Button>
              </Stack>
            </form>
            <UpdateModules
              momentIds={selectedMoments.map((moment) => moment.momentId)}
              session={sessionData}
            />
          </div>
        )}
      </ModalDialog>
    </Modal>
  );
}
export default SessionUpdateModal;
