import React, { useEffect, useState } from 'react'
import { addLikeDislike, getLikeDislike, } from "../../../../Components/Services/meeting.service"
import notificationsService from "../../../../Components/Services/notifications.service"
import toaster from '../../../../Components/Toast/toaster'
import CommentOnModal from '../../../../Components/Conversations_Dashboard/Sales/Modals/CommentOnModal'
import { useHistory } from "react-router-dom";

function MeetingInnerTrans({callTranscript,keyClicked,meetingId,userExternalId,transcripts,postId,eventType,tabSelected}) {

    const [likeButton, setLikeButton] = useState(false)
    const [dislikeButton, setDislikeButton] = useState(false)
    const [commentButton, setCommentButton] = useState(false)
    
    const [commentShow, setCommentShow] = useState(false) 
    const [transId,setTransId] = useState("")
    const [trans,setTrans] = useState("")
    const [speaker,setSpeaker] = useState("")
    const [likeId, setLikeId] = useState("")
    const [dislikeId, setDislikeId] = useState("")
    const [commentData, setCommentData] = useState({})
    const [likeDislike, setLikeDislike] = useState([])

    const [toggle,setToggle] = useState(false)
    const history = useHistory()

    const likeButtonHandler = (id,label)=>{
        setLikeButton(true)
        setLikeId(id)
        let value={
          "callId": meetingId,
          "commentType": "TRANSCRIPT",
          "dislike": null,
          "like": 1,
          "postId": id
        }
        addLikeDislike(value).then(res=>{
          if(res){
            getLikeDislike(meetingId,"TRANSCRIPT").then(res=>{
              if(res){
                setLikeDislike(res?.data?.data)
                setLikeButton(false)
              }
            })
          }
        })

        let notificationBody = [{
                "callId":meetingId,
                "userExternalId":userExternalId,
                "notificationEvent":{
                    "eventType":"LIKE",
                    "tabName":"transcript",
                    "tabValue":label,
                    "postId":id,
                    "notificationModule": "MEETING"
                }
            }]
        notificationsService.createNotifications(notificationBody).then(res=>{
          if(res){
            toaster.success(res?.data?.message)
          }
        })
      }

    const dislikeButtonHandler = (id,label)=>{
        setDislikeButton(true)
        setDislikeId(id)
        let value={
            "callId": meetingId,
            "commentType": "TRANSCRIPT",
            "dislike": 0,
            "like": null,
            "postId": id
        }
        addLikeDislike(value).then(res=>{
            if(res){
            getLikeDislike(meetingId,"TRANSCRIPT").then(res=>{
                if(res){
                setLikeDislike(res?.data?.data)
                setDislikeButton(false)
                }
            })
            }
        })
    let notificationBody = [{
            "callId":meetingId,
            "userExternalId":userExternalId,
            "notificationEvent":{
                "eventType":"DISLIKE",
                "tabName":"transcript",
                "tabValue":label,
                "postId":id,
                "notificationModule": "MEETING"
            }
        }]
    notificationsService.createNotifications(notificationBody).then(res=>{
        if(res){
        toaster.success(res?.data?.message)
        }
    })
    }

    const commentButtonHandler = (id,trans,speaker)=>{
        setToggle(true)
        // setCommentButton(true)
        setCommentShow(!commentShow)
        setTransId(id)
        setTrans(trans)
        setSpeaker(speaker)
    }

    const updateCommentState = ()=>{
        setCommentButton(true)
        setCommentShow(true)
        // setId(id)
        // setStats(label)
    }

    const closeButton = ()=>{
        setCommentShow(false)
        // setEmailShow(false)
    }

    const callback = (e)=>{ 
        setCommentData(e)
    }

    useEffect(()=>{
        getLikeDislike(meetingId,"TRANSCRIPT").then(res=>{
            if(res){
            setLikeDislike(res?.data?.data)
            }
        })
    },[])

    useEffect(()=>{
        if(eventType=='COMMENT'&&tabSelected=='transcript'){
          setCommentShow(true)
          setTransId(postId)
          setTrans(history.location.state.tabValue)
        }
      },[])

      const filteredTranscript = callTranscript?.sequence?.filter(item => {
        if (keyClicked === 'all') return true;
        return item.speaker === keyClicked;
      });


    const TranscriptItem = ({ item, index }) =>{

        let transNew = [];

        // Check if commentData is defined, has postTypeComments, and is not an empty array
        if (commentData && commentData.postTypeComments && Object.values(commentData.postTypeComments).length > 0) {
        transNew = Object.entries(commentData.postTypeComments)
            .filter(([key, value]) => value[0]?.postId === index); // Assuming `index` is defined elsewhere
        } 
        // If commentData conditions are not met, check props and callTranscript
        // else if (transcripts && props.callTranscript.postTypeComments && Object.values(props.callTranscript.postTypeComments).length > 0) {
        // transNew = Object.entries(props.callTranscript.postTypeComments)
        //     .filter(([key, value]) => value[0]?.postId === index); // Assuming `index` is defined elsewhere
        // }

        let ids = transNew.length>0?transNew[0][1]:''

        let likeIcon = likeDislike.length>0 && likeDislike.filter(val=>{
            return(val.postId==index)
    })

        return  (
          <a className={`list-group-item list-group-item-action }`}>
              <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1 font-16">
                      <i className="bx bx-user-voice me-1"></i>
                        {item.speakerName}
                  </h5>
                  <small className="text-muted">
                      <i className="bx bx-time"></i>
                      {item.time}
                  </small>
              </div>
              <p className="mb-1">
                  {item.trans}
              </p>
              <div className="row">
                <div class="col-6">
                    <div class="list-after-click-group">
                        {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==index||likeButton&&likeId==index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                        likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==index||dislikeButton&&dislikeId==index?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                        {ids&&ids[0].postId==index||commentButton&&transId==index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(index,trans,speaker)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
                    </div>
                </div>
                <div class="col-6">
                    <div className="list-hover-input-group">
                        {/* like-dislike buttons */}
                        {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==index||likeButton&&likeId==index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(index,item.trans)}>
                            <span className="text-primary-blue">
                            <i className="bx bx-like"></i>
                            </span>
                        </button>}
                        {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==index||dislikeButton&&dislikeId==index? ""
                        :
                        <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(index,item.trans)}>
                        <span className="text-primary-blue">
                            <i className="bx bx-dislike"></i>
                        </span>
                        </button>}
                        {/* comment */}
                        {ids&&ids[0].postId==index||commentButton&&transId==index?'':  
                        <button className="btn list-comment-before switcher-btn-1" onClick={()=>commentButtonHandler(index,item.trans,item.speakerName)}
                        data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                            <span className="text-primary-blue">
                                <i className="lni lni-comments-alt"></i>
                            </span>
                        </button>
                        }
                    </div>
                </div>
              </div>
          </a>
      )};

  return (
    <div className="list-group moment-list-group-scroll mb-2" style={{ height: '350px', overflowY: 'auto' }}>
            {filteredTranscript?.map((item, index) => (
                <TranscriptItem key={item.index} item={item} index={index} />
            ))}
        {commentShow && transId!=='' &&
        <CommentOnModal 
        updateState={updateCommentState} 
        commentShow={commentShow}
        closeButton={closeButton} 
        tabSelected={tabSelected}
        callId={meetingId} 
        transId={transId} 
        trans={trans} 
        transSpeaker={speaker} 
        // callT={props.callT} 
        callback={callback} 
        // phone={props?.phone} 
        agentExternalId={userExternalId}
        module="MEETING"/>}
    </div>
  )
}

export default MeetingInnerTrans