import React, { useMemo } from 'react'
import i from "../../../assets/images/coaching-images/i.png"
import team1 from "../../../assets/images/coaching-images/team1.png"
import team2 from "../../../assets/images/coaching-images/team2.png"
import team3 from "../../../assets/images/coaching-images/team3.png"
import team4 from "../../../assets/images/coaching-images/team4.png"
import team5 from "../../../assets/images/coaching-images/team5.png"

function CoachingDashboardStats({ list }) {

    const sessionData = useMemo(() => {

         // Initialize counts
        let totalAssignedSessions = 0;
        let totalCompletedSessions = 0;
        let totalTeams = 0;

        if (!list || Object.keys(list).length === 0) {
            return { totalAssignedSessions, totalCompletedSessions, totalTeams };
        }

        // Iterate over each category and its sessions
        Object.values(list).forEach((sessions) => {
            totalTeams += 1; // Count each unique team (category)

            sessions.forEach((session) => {
                totalAssignedSessions += session.assignedSessions || 0;
                totalCompletedSessions += session.completedSessions || 0;
            });
        });

        return { totalAssignedSessions, totalCompletedSessions, totalTeams };
    }, [list]);

    return (
        <>
            <div className="">
                <div className="row">
                    <div className="parent">
                        <div className="img_section dashStats">
                            <span><img src={team1} alt="" /></span>
                            <div className="text_sign">
                                <p className="" title='Total no of teams'>NO OF TEAMS<span><img style={{ cursor: 'pointer' }} src={i} alt="" /></span></p>
                                <h2 className="">{sessionData?.totalTeams}</h2>
                            </div>
                        </div>
                        <div className="img_section dashStats">
                            <span><img src={team2} alt="" /></span>
                            <div className="text_sign">
                                <p className="" title='No of sessions completed'>SESSIONS COMPLETED<span><img src={i} alt="" /></span></p>
                                <h2 className="text-success">{sessionData?.totalCompletedSessions}</h2>
                            </div>
                        </div>
                        <div className="img_section dashStats">
                            <span><img src={team3} alt="" /></span>
                            <div className="text_sign">
                                <p className="text-danger" title='Total no of sessions'>TOTAL SESSIONS<span><img src={i} alt="" /></span></p>
                                <h2 className="text-danger">{sessionData?.totalAssignedSessions}</h2>
                            </div>
                        </div>
                        {/* <div className="img_section dashStats">
                            <span><img src={team5} alt="" /></span>
                            <div className="text_sign">
                                <p className="">NO OF TEA.. <span><img src={i} alt="" /></span></p>
                                <h2 className="text-danger">N/A</h2>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoachingDashboardStats