import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from '../../assets/images/odio_logo_blue.png'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import "./newFooter.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));
function NewFooter() {
  return (
    <>
    <footer className="page-new-footers">
        <div className='new-border-partition'></div>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item sm={4} xs={12} className='newfooter'>
                <Item sx={{background: 'transparent', boxShadow: 'inherit'}}>
                    {/* <img src= {Logo} /> */}
                    <h2>Clear Nexus</h2>
                </Item>
                </Grid>
                <Grid item sm={4} xs={12}>
                <Item className='footer-section-left' sx={{background: 'transparent', boxShadow: 'inherit'}}>
                <h2>Steve Beveridge</h2>
                <p>V.P. Sales and Business Development</p>
                    <p><PhoneAndroidIcon sx={{color: '#5367a0'}} /> 949-416-9167</p>
                    <p><EmailIcon sx={{color: '#5367a0'}} /> steve@clearnexus.com</p>

                </Item>
                </Grid>
                <Grid item sm={4} xs={12}>
                <Item className='footer-section-left' sx={{background: 'transparent', boxShadow: 'inherit'}}>
                    <LocationOnIcon  sx={{color: '#5367a0'}}/>
                    <p className="new-footer-para">
                  {" "}
                  USA Office,
                  <br /> 1317 Edgewater Dr #4688 <br /> Orlando, FL 32804 <br />
                  Outside USA: <br />
                  San José, Costa Rica
                </p>
                </Item>
                </Grid>
            </Grid>
        </Box>
        <div className='new-footer-dark'>
              <div className='social-icon'>
              <i className='bx bxl-facebook'></i>
              <i className='bx bxl-twitter' ></i>
            
              </div>
              <div className="new-ul-footer">
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About Clear Nexus</a>
                  </li>
                  <li>
                    <a href="#">Brand Engagements</a>
                  </li>
                  <li>
                    <a href="#">Clear Costa Rica</a>
                  </li>
                  <li>
                    <a href="#">Company History</a>
                  </li>
                  <li>
                    <a href="#">Media</a>
                  </li>
                  <li>
                    <a href="#">Mission Statement</a>
                  </li>
                  <li>
                    <a href="#">Clear BPO</a>
                  </li>
                  <li>
                    <a href="#">Clear Marketing</a>
                  </li>
                  <li>
                    <a href="#">Contact US</a>
                  </li>
                </ul>
             </div>
        </div>
      </footer>

    </>
  )
}

export default NewFooter