import React from "react";

const EmailContent = ({ subject,trans }) => {
  // Split the content into paragraphs
  const paragraphs = trans.split('\n');

  return (
    <div >
      {/* <p class="mb-4 font-18">{subject}</p> */}
      {paragraphs.map((paragraph, index) => (
        <div key={index} >
          {/* Split the paragraph into lines */}
          {paragraph.split('\n\n').map((line, i) => (
            <span key={i} style={{ margin: "20px" }}>{line}</span>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EmailContent;
