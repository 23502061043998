import React, { useState } from "react";
// import AudioStreamSender from "./AudioStreamSender";
import { useHistory } from "react-router-dom";
import { AudioStreamerPlay } from "./AudioStreamerPlay";
import "./PlayButton.css";

function PlayButton() {
  const history = useHistory();
  const [showAudioSender, setShowAudioSender] = useState(false);
  const [hover, setHover] = useState(false);

  const handlePlay = () => {
    setShowAudioSender(true);
    // history.push("/live_assist/play/audio_stream_sender");
    history.push("/live_assist/play/audio_streamer_play");
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div className="fabDiv">
      <button
        className="fabButton"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handlePlay}
      >
        {hover ? "Play Live Call" : <i className="bx bx-play-circle"></i>}
      </button>
      {showAudioSender && (
        <div>
          <AudioStreamerPlay /> {/* <AudioStreamSender />{" "} */}
          {/* Render the AudioStreamSender component when showAudioSender is true */}
        </div>
      )}
    </div>
  );
}

export default PlayButton;
