import { Card, Button, Tab, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Stats from "../Sales/Stats/Stats";
import Moments from "../Sales/Moments";
import Signals from "../../Conversations_Dashboard/Sales/Signals/Signals";
import Questions from "../Questions/Questions";
import Actions from "../Actions/Actions";
import Transcript from "../Transcript/Email_Transcript";
import Feedbacks from "../../Conversations_Common/Feedbacks/Feedbacks";
import MoreInfo from "../Sales/Modals/MoreInfo";
import FoulLanguage from "../FoulLanguage/FoulLanguage";
import "../Sales/SalesCallDetail.css";
import emailService from "../../Services/mails.service";
import callsService from "../../Services/calls.service";
import { apiCall } from "../../Services/Interceptor";
import Loading from "../../Commons/Loading";
import AudioPlayer from "../AudioPlayer";
import { useHistory } from "react-router-dom";
import NodataFound from "../../Commons/NoDataFound";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { groupMoment } from "./mock/email_group_moments";
import { singleMoment } from "./mock/email_single_moment";
import { callListingGroup } from "./mock/email_calllisting_group";
import Emails from "../Voice/emails/Emails";
import Summary from "../../Conversations_Common/Summary/Summary";
import EmailTodo from "./EmailTodo";

const EmailDetail = () => {
  const [key, setKey] = useState("stats");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [callStats, setCallStats] = useState([]);
  const [emailSummary, setEmailSummary] = useState([]);
  const [callQuestions, setCallQuestions] = useState([]);
  const [startTime, setStartTime] = useState();
  const [time, setTime] = useState("");
  const [callTime, setCallTime] = useState([]);
  const [foulLanguage, setFoulLanguage] = useState([]);
  const [callMoments, setCallMoments] = useState([]);
  const [callTranscript, setCallTranscript] = useState([]);
  const [callFeedback, setCallFeedback] = useState([]);
  const [callAction, setCallAction] = useState([]);
  const [callSignals, setCallSignals] = useState([]);
  const [emailDetail, setEmailDetail] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [fileName, setFileName] = useState("");
  const [likeDislikeData, setLikeDislikeData] = useState([]);
  const history = useHistory();
  const [historyObject, setHistoryObject] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = historyObject?.callId;
  const mobile = urlParams.get("no");
  const callid = urlParams.get("id");
  const notifPostId = urlParams?.get("postId");
  const talkDuration = urlParams.get("duration");
  // const recUrl = urlParams.get('url').split("/")
  const recUrl = [];

  let url = historyObject?.url;
  const date = urlParams.get("date");
  const sellerId = urlParams.get("id");
  const coeName = urlParams.get("coe");
  const coeIds = historyObject?.coeId;
  const seller = urlParams.get("seller");
  const customer = urlParams.get("customer");
  let fromDate = urlParams.get("fromDate")
  let toDate = urlParams.get("toDate")
  const typeOfCall = urlParams.get("name");
  const uniqueId = historyObject?.uniqueId;
  const typeId = historyObject?.typeId;
  const callId = typeOfCall=="GROUP" ? typeId : params;

  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig
    ?.data?.conversationListing;
  const coeExternalId = JSON.parse(localStorage.getItem("AGENT"))?.coe;  
  const storedData = JSON.parse(localStorage.getItem("AGENT"))
  let userExternalId = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userExternalId
  const clientExternalId = JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId

  const onClickHandler = () => {
    emailService.getEmailTime(typeId, typeOfCall).then(response => {
      if (response) {
        setCallTime(response.data.data)
        setFileName(response.data.data.recordingUrl)
        setLoader(false)
      }
    })  
    setShow(true);
  };
  const closeButton = () => setShow(false);

  useEffect(() => {
    setHistoryObject(history.location.state);

    if (key == "stats" && typeId) {
      setLoader(true);
      emailService.getEmailDetailStats(typeId, typeOfCall).then((res) => {
          setLoader(true);
          callsService.getLikeDislike(callId, 'STATS').then(res => {
            if (res) {
              setLikeDislikeData(res.data.data)
            }
          })
          if (res?.status == 200) {
            setCallStats(res?.data?.data);
            setLoader(false);
          }
      });  
    }
    if (key == "summary" && typeId) {
      setLoader(true);
      emailService.getEmailDetailSummary(typeId, typeOfCall).then((res) => {
          if (res?.status == 200) {
            setEmailSummary(res?.data?.data);
              setLoader(false);
          }
        });
      }
    //   if(key=='stats'){
    // if(typeOfCall=='GROUP'){
    // emailService.getCallDetailStats(mobile,typeOfCall).then((res) =>{
    // setCallStats(callListingGroup)
    // })}
    // else if(typeOfCall=="SINGLE_CALL"){
    //     emailService.getCallDetailStats(callid,typeOfCall).then((res) =>{
    //         setCallStats(res.data.data)
    // })}
    //       setLoader(true)
    //       if(res.status==200)
    //       {
    //         setCallStats(res.data.data)
    //         setLoader(false)
    //         emailService.getLikeDislike(params,"STATS").then(res=>{
    //           if(res){
    //             setLikeDislikeData(res.data.data)
    //           }
    //         })
    //       }
    //     })
    // if(typeOfCall=='SINGLE_CALL'){
    //       emailService.getCallTime(params).then(response=>{
    //           if(response){
    //             setCallTime(response.data)
    //             setFileName(response.data.recordingUrl)
    //             setLoader(false)
    //           }
    //       })
    //     }
      // }
  }, [key == "stats",key == "summary", typeId]);

  const handleMomentData = async (isLoader = true) => {
    isLoader && setLoader(true);
    const val = typeOfCall == "GROUP" ? {groupId:typeId,coeExternalId:coeExternalId} : {uniqueId, coeExternalId:coeExternalId}
    emailService
    .getEmailDetailMoments(val)
    .then((res) => {
      setLoader(true);
      if (res?.status == 200) {
        setCallMoments(res?.data?.data);
        setLoader(false);
      }
    });
  };

  // useEffect(() => {
  //   handleMomentData();
  // }, []);

  const handleSelect = async (keyVal) => {
    if (key !== keyVal) {
      setKey(keyVal);
      if (keyVal == "transcript") {
          setLoader(true)
          emailService.getEmailDetailTranscript(typeId, typeOfCall).then(response=>{
            if(response.status==200){
              setCallTranscript(response?.data?.data);
              setCustomerName(response?.data?.data?.customerName);
              setSellerName(response?.data?.data?.sellerName);
              setLoader(false)
             callsService.getLikeDislike(callId,"TRANSCRIPT").then(res=>{
             if(res){
              setLikeDislikeData(res.data.data)
             }
            });
          }
        })
       .catch(() => {
        setLoader(false);
        });
      }
      if(keyVal=='questions'){
        setLoader(true)
        emailService.getEmailDetailQuestions(typeId).then(response=>{
          if(response){
            setCallQuestions(response?.data?.data)
            setLoader(false)
            callsService.getLikeDislike(params,"QUESTIONS").then(res=>{
              if(res){
                setLikeDislikeData(res.data.data)
              }
            })
          }
        })
      }

      if(keyVal=='foulLanguage'){
        setLoader(true)
        emailService.getEmailDetailFoul(typeId).then(response=>{
          if(response){
            setFoulLanguage(response?.data?.data)
            setLoader(false)
            callsService.getLikeDislike(params,"FOUL_LANGUAGE").then(res=>{
              if(res){
                setLikeDislikeData(res?.data?.data)
              }
            })
          }
        })
      }

      if (keyVal === "callMoments") {
        // handleMomentData();
        const val = typeOfCall == "GROUP" ? {groupId:typeId,coeExternalId:coeExternalId} : {uniqueId, coeExternalId:coeExternalId}
        emailService
        .getEmailDetailMoments(val)
        .then((res) => {
          setLoader(true);
          if (res?.status == 200) {
            setCallMoments(res?.data?.data);
            setLoader(false);
          }
        });
      }

      if (keyVal == "feedback") {
          setLoader(true)
          callsService.getCallDetailFeedback(callId).then(res=>{
            if(res){
              setCallFeedback(res.data.data)
              setLoader(false)
            }
          })
      }

      if (keyVal == "actions" && typeId) {
          setLoader(true)
          emailService.getEmailTodo(typeId, typeOfCall).then(res=>{
            if(res){
              setCallAction(res?.data?.data[0]?.todo)
              setLoader(false)
              // emailService.getLikeDislike(params,"ACTIONS").then(res=>{
              //   if(res){
              //     setLikeDislikeData(res.data.data)
              //   }
              // })
            }
          })
      }

      if(keyVal=='signals'){
        setLoader(true)
        emailService.getEmailDetailSignals(typeId).then(res=>{
          if(res){
            setCallSignals(res?.data?.data)
            setLoader(false)
            callsService.getLikeDislike(params,"SIGNALS").then(res=>{
              if(res){
                setLikeDislikeData(res?.data?.data)
              }
            })
          }
        })
      }
      if(keyVal=='emails'){
        setLoader(true)
        let val ={  
          "page":parseInt(localStorage.getItem("selectedPage"))?parseInt(localStorage.getItem("selectedPage")):1,
          "pageSize":5,  
          "order" :"",
          "sort": "",
          "coeExternalIds": JSON.parse(localStorage.getItem("AGENT"))?.coe,
          "conversations_type": 2,
          "filters": {},
          "fromD": fromDate ? fromDate : storedData&&storedData.fromD?storedData.fromD:"", 
          "toD": toDate ? toDate : storedData&&storedData.toD?storedData.toD:"",
          "usersExternalIds": [userExternalId],
          "clientExternalId": clientExternalId,
          "uniqueId": typeId,
        }

        emailService.getSubEmails(val).then(res=>{
          if(res?.status==200){
            setLoader(false)
            setEmailDetail(res?.data?.data?.details)
          }
        })
      }
    }
  };

  const callBack = (e) => {
    setTime(e);
  };

  useEffect(() => {
    document.title = "Voice Details - Odio";
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between py-3">
                  <h2 className="dashboard-headings py-2">
                    {`Email Id #${callId}`}
                    <Button
                      className="btn-mdl py-1 ms-2"
                      onClick={onClickHandler}
                    >
                      More Info.
                    </Button>
                  </h2>
                  <div className="detail-top-head text-end">
                    {userDetail.includes("CALL SCORE") ? (
                      <span className="badge rounded-pill btn btn-outline-success px-2 my-1 py-2 font-14">
                        Email Score {callStats ? callStats?.emailScore?.value : "0"}
                        %
                      </span>
                    ) : (
                      ""
                    )}
                    {userDetail.includes("PITCH SCORE") ? (
                      <span className="badge rounded-pill btn btn-outline-danger px-2 my-1 py-2 font-14">
                        Pitch Score{" "}
                        {callStats
                          ? callStats[0]
                            ? callStats[0].pitchScore
                            : "0"
                          : "0"}
                        %
                      </span>
                    ) : (
                      ""
                    )}
                    {userDetail.includes("CONVERSION PROBABILITY") ? (
                      <span className="badge rounded-pill btn btn-outline-warning px-2 my-1 py-2 font-14">
                        Conv. Probability{" "}
                        {callStats
                          ? callStats[0]
                            ? callStats[0].convProbability
                            : "0"
                          : "0"}
                        %
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {typeOfCall=='SINGLE_CALL'?<div className="col-12">
              <AudioPlayer recUrl={historyObject?.url} startTime={startTime} callBack={callBack} callTranscript={callTranscript} key={key} talkDuration={callTime.duration} callTime={callTime}/>
            </div>:""} */}
          <div className="col-12">
            <Card>
              <Card.Body className="p-5">
                <div className="setting-section-nev">
                  <Tabs
                    id="controlled-tab-example"
                    activekey={key}
                    onSelect={handleSelect}
                  >
                       <Tab
                      eventKey="stats"
                      title="STATS"
                      className="voice-section"
                    >
                      {callStats ? (
                        Object.keys(callStats)?.length > 0 ? (
                          <Stats
                            callStats={callStats}
                            tabSelected={key}
                            loader={loader}
                            callId={callId}
                            fileName={historyObject?.url}
                            agentExternalId={historyObject?.agentExternalId}
                            phone={historyObject?.phone}
                            likeDislikeData={likeDislikeData}
                            typeOfCall={typeOfCall}
                            type='email'
                            module="EMAIL"
                          />
                        ) : (
                          <div className="loader-container">
                            <Loading variant="dark" />
                          </div>
                        )
                      ) : (
                        <NodataFound />
                      )}
                    </Tab>

                    {typeOfCall == "GROUP" || typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="summary" title="SUMMARY">
                        <Summary  loader={loader} emailSummary={emailSummary}/>
                      </Tab>
                    ) : (
                      ""
                    )}

                    {typeOfCall == "GROUP" || typeOfCall == "SINGLE_CALL" ?  (
                      <Tab eventKey="transcript" title="EMAIL TRAIL">
                        <Transcript
                          type={'email'}
                          callTranscript={callTranscript}
                          tabSelected={key}
                          loader={loader}
                          callId={callId}
                          url={url}
                          fileName={fileName}
                          agentExternalId={historyObject?.agentExternalId}
                          phone={historyObject?.phone}
                          likeDislikeData={likeDislikeData}
                          typeOfCall={typeOfCall}
                          index={historyObject?.index}
                        />
                      </Tab>
                    ) : (
                      ""
                    )}

                    {/* {typeOfCall == "GROUP" || typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="callMoments" title="MOMENTS">
                        <Moments
                          handleMomentData={handleMomentData}
                          callMoments={callMoments}
                          tabSelected={key}
                          loader={loader}
                          callId={callId}
                          fileName={fileName}
                          agentExternalId={historyObject?.agentExternalId}
                          likeDislikeData={likeDislikeData}
                          phone={historyObject?.phone}
                          typeOfCall={typeOfCall}
                          uniqueId={uniqueId}
                          type='email'
                        />
                      </Tab>
                    ) : (
                      ""
                    )} */}

                    {/* {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="questions" title="QUESTIONS">
                        <Questions
                          callQuestions={callQuestions}
                          tabSelected={key}
                          loader={loader}
                          callId={params}
                          customerName={customerName}
                          sellerName={sellerName}
                          fileName={fileName}
                          agentExternalId={historyObject?.agentExternalId}
                          phone={historyObject?.phone}
                          likeDislikeData={likeDislikeData}
                          typeOfCall={typeOfCall}
                          type='email'
                        />
                      </Tab>
                    ) : (
                      ""
                    )} */}

                    {/* {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="foulLanguage" title="FOUL LANGUAGE">
                        <FoulLanguage
                          foulLanguage={foulLanguage}
                          tabSelected={key}
                          loader={loader}
                          callId={params}
                          fileName={fileName}
                          agentExternalId={historyObject?.agentExternalId}
                          phone={historyObject?.phone}
                          likeDislikeData={likeDislikeData}
                          typeOfCall={typeOfCall}
                          type='email'
                        />
                      </Tab>
                    ) : (
                      ""
                    )} */}

                    {typeOfCall == "GROUP" || typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="actions" title="TO-DO">
                        {/* <Actions callAction={callAction} tabSelected={key} loader={loader} callId={params} customerName={customerName} sellerName={sellerName} fileName={fileName} agentExternalId={historyObject.agentExternalId} typeOfCall={typeOfCall} likeDislikeData={likeDislikeData} type='email'/> */}
                        <EmailTodo loader={loader} todos={callAction}/>
                      </Tab>
                    ) : (
                      ""
                    )}

                    {/* {typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="signals" title="SIGNALS">
                        <Signals
                          callSignals={callSignals}
                          tabSelected={key}
                          customerName={customerName}
                          sellerName={sellerName}
                          loader={loader}
                          callId={params}
                          fileName={fileName}
                          agentExternalId={historyObject?.agentExternalId}
                          likeDislikeData={likeDislikeData}
                          phone={historyObject?.phone}
                          typeOfCall={typeOfCall}
                          type='email'
                        />
                      </Tab>
                    ) : (
                      ""
                    )} */}

                    {typeOfCall == "GROUP" || typeOfCall == "SINGLE_CALL" ? (
                      <Tab eventKey="feedback" title="FEEDBACK">
                        <Feedbacks
                          callFeedback={callFeedback}
                          loader={loader}
                          agentExternalId={historyObject?.agentExternalId}
                        />
                      </Tab>
                    ) : (
                      ""
                    )}
           
                    {/* {typeOfCall == "GROUP" ? (
                      <Tab eventKey="emails" title="EMAILS">
                        <Emails loader={loader}  emailDetail={emailDetail}/>
                      </Tab>
                    ) : (
                      ""
                    )} */}
                  </Tabs>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {
        // customerName&&sellerName!==[]&&
        <MoreInfo
          show={show}
          setShow={setShow}
          closeButton={closeButton}
          customerName={callTime?.customerName}
          sellerName={callTime?.sellerName}
          processTime={historyObject?.processingTime ? historyObject?.processingTime : callTime?.processingTime}
          talkDuration={null}
          date={callTime?.date}
          url={null}
          sellerId={callTime?.sellerId}
          coeName={storedData?.selectedCOE}
          typeOfCall={typeOfCall}
          groupId={callTime?.groupId}
          module="EMAIL"
        />
      }
    </div>
  );
};

export default EmailDetail;
