import React from "react";
import AudioStreamer from "./AudioStreamer";

export const AudioStreamerPlay = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content" style={{minHeight:'auto'}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
          <AudioStreamer  
            audioFileUrl="https://demo-odio-test.s3.ap-south-1.amazonaws.com/9816762_2.wav"
            socketUrl="wss://livesttsocket.odioiq.com/media"
            // socketUrl='wss://6da3-122-160-137-134.ngrok-free.app/media'
            // socketUrl='wss://5be9-122-160-137-134.ngrok-free.app/media'
           />
           </div>
          </div>
        </div>
    </>
  );
};
