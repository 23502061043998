import React from "react"
import noData from "../../assets/images/Group 1.svg"

const NoNotificationFound = ()=>{
    return(
        <div style={{marginTop: "10px"}}>
                <div className="d-flex align-content-center justify-content-center">
                    <span>
                        <img src={noData} alt="noNotification"/>
                    </span>
                </div>
                <div className="no-data-found">
                    <h4>No Notification Available</h4>
                </div>
        </div>
    )
}

export default NoNotificationFound