import { SidebarComponent } from '@syncfusion/ej2-react-navigations'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { apiCall } from '../Services/Interceptor';
import Loading from '../Commons/Loading';
import { ListGroup } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

const LiveAssistSidebar = forwardRef((props,ref) => {
    const liveSidebarRef = useRef(null);
    const [callAssitData, setCallAssitData] = useState([])
    const [loader, setLoader] = useState(false)
    const {callData} = props;
    const history = useHistory()
    const storedData = JSON.parse(localStorage.getItem("AGENT"))

    const callSidebarData = async (callId)=>{
        if(callId !== ''){
        setLoader(true)
        await apiCall.get(`odio/api/call/live_assist/detail/call?callId=${callId}`).then(response => {
            setLoader(false)
            setCallAssitData(response?.data.data)
        }).catch(error => {
            setLoader(false);
        })}
    }

    useEffect(() => {
        if (liveSidebarRef.current && liveSidebarRef.current.element) {
          liveSidebarRef.current.element.style.visibility = '';
        }
    }, []);

    useEffect(()=>{
        if(callData?.callId != undefined){
        callSidebarData(callData?.callId)}
    },[callData?.callId])

    useImperativeHandle(ref, () => ({
        toggleSideBar() {
            if (liveSidebarRef.current) {
                liveSidebarRef.current.toggle();
            }
        }
    }));

    const goToCallHandler =(id,rec,duration,date,empId,seller,customer,coeId,coe,phone,externalId,name,filename)=>{
        localStorage.setItem("URL",rec)
        history.push(`voice_detail?callId=${id}&name=${name}`, {
            callId:id,
            url:rec,
            duration:duration,
            date:date,
            id:empId,
            seller:seller,
            customer:customer,
            coeId:coeId,
            coe:coe,
            phone:phone,
            agentExternalId:externalId,
            callName: name,
            fileName: filename
          })
    }
    

  return (
    <>
    <div className="">
    <div>
        <SidebarComponent
            enableGestures={false}
            id='liveSideBar'
            ref={liveSidebarRef}
            width='320px'
            type='Push'
            position='Right'
            style={{ visibility: "hidden" }}
        >
        </SidebarComponent>
        <div className="switcher-wrapper-liveAssist call-filters side-fillters-scroler">
            <div className="switcher-body">
            <div>
                <h5 className="mb-0 text-uppercase font-14">
                      {"Live Assist Call Details"}
                </h5>
            </div>
            <hr className="my-2" />
            <div className="filter_scroll px-1">
                {loader ? (
                    <div className="loader-container">
                        <Loading variant="dark" />
                    </div>
                ) : (
                    <>
                    <ListGroup>
                        {callAssitData && callAssitData.length>0 && Object.entries(callAssitData[0].audit_keys[0]).map(([key, value]) => (
                           <ListGroup.Item action variant={value == 1 ? 'success' : 'danger'}>{key}</ListGroup.Item>))   
                        }
                    </ListGroup>
                    {/* <ListGroup as='ol' numbered>
                        {callData && callData.length>0 && Object.entries(callData[0].audit_keys[0]).map(([key, value]) => (
                           <ListGroup.Item as='li' action variant={value == 1 ? 'success' : 'danger'}>{key}</ListGroup.Item>))   
                        }
                    </ListGroup> */}
                    </>
                )}</div>
                <div className="filters_btn bg-white py-2">
                  <button
                    type="button"
                    className="btn px-4 btn-light me-2 lh-base cancel-btn"
                    onClick={props.toggleSidebar}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn px-4 lh-base update-btn"
                    style={{width:'160px'}}
                    onClick={()=>{goToCallHandler(callData.callId,callData.recordingUrl,callData.talkDuration,callData.date,callData.employeeId,callData.sellerName,callData.customerName,storedData?.selectedCoeRange,storedData?.selectedCOE,callData.phone,callData.userExternalId,"SINGLE_CALL",`${callData.filename}.wav`)}}
                  >
                    View Call
                  </button>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
  )
})

export default LiveAssistSidebar