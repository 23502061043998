import { Tab, Nav } from "react-bootstrap";
import React,{ useState } from "react";
import { Col,Row } from "react-bootstrap";
import All_FoulLang from "./All_FoulLang";
import Loading from "../../Commons/Loading";
import EmailTranscript from "../Sales/Modals/EmailTranscript";

export default function FoulLanguage(props) {
  const [show, setShow] = useState(false)
  const closeButton=()=>setShow(false)
  const handleClick=()=>{
    setShow(true)
  }

  const setEmailFalse = ()=>{
    setShow(false)
  }

  const [keyClicked,setKeyClicked] = useState('all')

  const handleSelect = (keyClicked)=>{
    setKeyClicked(keyClicked)
  }

  return (

    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :

      <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primaryfoullanguage">
          <div className="d-flex">
            <div className="moment-filter-tab-box">
            <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked?keyClicked:"all"}>
                <Row>
                  <Col sm={10}>
                    <Nav variant="pills" className="flex" onSelect={handleSelect}>
                      <Nav.Item>
                      {<Nav.Link eventKey="all" >All</Nav.Link>}
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="customer">{props.foulLanguage[0]?.customerName}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="seller">{props.foulLanguage[0]?.sellerName}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={2} className="moments-email-btn mb-3 text-sm-right pe-0">
                    <a className="btn compose-mail-btn" onClick={handleClick}>Email Foul Language</a>
                  </Col>
                  <Col sm={12} className="px-0">
                    <Tab.Content>
                    {keyClicked=='all'||keyClicked=='customer'||keyClicked=='seller'?
                      <Tab.Pane eventKey={keyClicked}>
                        <All_FoulLang keyClicked={keyClicked?keyClicked:""} foulLanguage={props.foulLanguage&&props.foulLanguage} tabSelected={props.tabSelected} tabRedirected={props?.tabRedirected} callId={props?.callId} phone={props?.phone} agentExternalId={props.agentExternalId} likeDislikeData={props?.likeDislikeData} eventType={props?.eventType} postId={props?.postId} agentId={props?.agentId}/>
                      </Tab.Pane>
                    :""}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} type={props?.type} foulLanguage={props.foulLanguage&&props.foulLanguage} tabSelected={props.tabSelected} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId}/>
      </div>
    }
    </>
  );
}