import { apiCall } from "./Interceptor";

const getSubEmails = async (val) => {
  const GET_SUB_EMAIL_LISTS = `/odio/api/email/sublist`;
  const response = await apiCall.post(GET_SUB_EMAIL_LISTS, val);
  return response;
};

const getEmailDetailStats = async (uniqueId, typeOfEmail) => {
  const GET_EMAIL_STATS = `/odio/api/email/stats?${typeOfEmail === "GROUP" ? `groupId=${uniqueId}` : `uniqueId=${uniqueId}`}`;
  const response = await apiCall.post(GET_EMAIL_STATS);
  return response;
};

const getEmailDetailSummary = async (uniqueId, typeOfEmail) => {
  const GET_EMAIL_SUMMARY = `/odio/api/email/summary?${typeOfEmail === "GROUP" ? `groupId=${uniqueId}` : `uniqueId=${uniqueId}`}`;
  const response = await apiCall.get(GET_EMAIL_SUMMARY);
  return response;
};

const getEmailTodo = async (uniqueId, typeOfEmail) => {
  const GET_EMAIL_TODOS = `/odio/api/email/todo?${typeOfEmail === "GROUP" ? `groupId=${uniqueId}` : `uniqueId=${uniqueId}`}`;
  const response = await apiCall.post(GET_EMAIL_TODOS);
  return response;
}

const getEmailDetailMoments = async (val) => {
  const GET_EMAIL_MOMENTS = `/odio/api/email/moments`;
  const response = await apiCall.post(GET_EMAIL_MOMENTS, val);
  return response;
};

const getEmailDetailSignals = async (uniqueId) => {
  const GET_EMAIL_SIGNALS = `/odio/api/email/signals?uniqueId=${uniqueId}`;
  const response = await apiCall.post(GET_EMAIL_SIGNALS);
  return response;
};

const getEmailDetailQuestions = async (uniqueId) => {
  const GET_EMAIL_QUESTIONS = `/odio/api/email/question?uniqueId=${uniqueId}`;
  const response = await apiCall.post(GET_EMAIL_QUESTIONS);
  return response;
};

const getEmailDetailFoul = async (uniqueId) => {
  const GET_EMAIL_FOULS = `/odio/api/email/foul?uniqueId=${uniqueId}`;
  const response = await apiCall.post(GET_EMAIL_FOULS);
  return response;
};

const getEmailDetailTranscript = async (uniqueId, typeOfEmail) => {
  const GET_EMAIL_TRANSCRIPTS = `/odio/api/email/transcript?${typeOfEmail !== "SINGLE_CALL" ? `groupId=${uniqueId}` :`uniqueId=${uniqueId}`}`;
  const response = await apiCall.post(GET_EMAIL_TRANSCRIPTS);
  return response;
};

const getEmailTime = async (uniqueId, typeOfEmail) => {
  const GET_EMAIL_TIME = `/odio/api/email/time?${typeOfEmail !== "SINGLE_CALL" ? `groupId=${uniqueId}` :`uniqueId=${uniqueId}`}`;
  const response = await apiCall.post(GET_EMAIL_TIME)
  return response;
}

export default {
  getSubEmails,
  getEmailDetailStats,
  getEmailDetailSummary,
  getEmailTodo,
  getEmailDetailMoments,
  getEmailDetailSignals,
  getEmailDetailQuestions,
  getEmailDetailFoul,
  getEmailDetailTranscript,
  getEmailTime
};
