import { apiCall } from "./Interceptor";

const CREATE_COE_URL = "/odio/api/coe/create";
const GET_COE_LIST = "/odio/api/client/get-coe";
const ADD_USER_TO_COE_URL = "odio/api/coe/add-user-to-coe";
const UPDATE_COE_URL = "/odio/api/coe/update";
const DELETE_COE_USER = "odio/api/coe/remove-user-from-coe";

const addUserToCOE = async (data) => {
    const response = await apiCall.post(ADD_USER_TO_COE_URL, data);
    return response;
}

const deleteCOE = async (id) => {
  const DELETE_COE_URL = `/odio/api/coe/delete/${id}`;
    const response = await apiCall.delete(DELETE_COE_URL);
    return response;
}

const removeCoeUser = async (data) => {
    const response = await apiCall.put(DELETE_COE_USER, data);
    return response;
}

const getAllCOEList = async () => {
    const response = await apiCall.get(GET_COE_LIST);
    return response;
}  

const getCOEList = async () => {
    let id = JSON.parse(localStorage.getItem('MOMENT_ID'))
    const GET_MOMENT_COE_LIST = `/odio/api/coe/get/coes/?momentBucketId=${id}`;
    const response = await apiCall.get(GET_MOMENT_COE_LIST);
    return response;
}

const createCOE = async (val) => {
    const response = await apiCall.post(CREATE_COE_URL, val);
    return response;
}

const updateCOE = async (data) => {
    const response = await apiCall.put(UPDATE_COE_URL, data);
    return response;
}

const getCoeUsersList = async (id) => {
  const GET_COE_USERS_LIST = `/odio/api/coe/${id}`;
  const response = await apiCall.get(GET_COE_USERS_LIST);
  return response;
}

export default {
  addUserToCOE,
  deleteCOE,
  getCOEList,
  createCOE,
  updateCOE,
  removeCoeUser,
  getCoeUsersList,
  getAllCOEList
}
