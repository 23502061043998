import React from 'react'
import { Modal, Button } from "react-bootstrap";
import '../Coaching/KnowledgeBase/knowledgeBase.css'

const DeleteConfirmationModal = ({
    openDeleteModal,
    handleCloseDeleteModal,
    title,
    delBody,
    setDelState,
    className,
    deleteType
}) => {
    const confirmationMessage = "Are you sure you want to delete?";

    return (
        <Modal show={openDeleteModal} dialogClassName={className} onHide={handleCloseDeleteModal} aria-labelledby="manager-modal" centered>
            <Modal.Header className='grad1'>
                <Modal.Title id="manager-modal">
                    <h5 style={{ color: 'white' }}>
                        {title}
                    </h5>
                </Modal.Title>
                {/* <img src={closeBtn} width="15px" height="15px" alt="Hop Logo"  onClick={closeButton} style={{marginRight: '14px', marginBottom: '5px',cursor:'pointer'}}/> */}
            </Modal.Header>
            <Modal.Body>
                <div className="modalcard-body" style={{ fontSize: '15px' }}>
                    {/* Are you sure you want to delete {delBody}? */}
                    {confirmationMessage}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="px-5 delete-btn"
                    onClick={() => (setDelState(), handleCloseDeleteModal()
                    )}>
                    Yes, Delete
                </Button>
                <Button
                    variant="secondary"
                    className="px-5 cancel-btn"
                    onClick={handleCloseDeleteModal}>

                    No, Keep it
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmationModal