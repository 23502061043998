import React,{ useEffect, useState  } from "react";
import conversationService from "../../Services/conversation.service";
import Loading from "../../Commons/Loading";
import Filter from "../../Filter/Filter";
import "../Sales/SalesCallDetail.css"
import { Card, OverlayTrigger,Popover } from "react-bootstrap";
import PaginationAlter from "../../Commons/Table/PaginationAlter";
import phone from "../../../assets/images/callList-images/phone.svg"
import email from '../../../assets/images/callList-images/email.png'
import calender from "../../../assets/images/callList-images/calendar.svg"
import time from "../../../assets/images/callList-images/timer.svg"
import alarm from "../../../assets/images/callList-images/alarm.svg"
import bulb from "../../../assets/images/callList-images/bulb.svg"
import moment from "../../../assets/images/callList-images/moment.svg"
import seller from "../../../assets/images/callList-images/seller.svg"
import user from "../../../assets/images/callList-images/user.svg"
import { useHistory} from "react-router-dom";
import NodataFound from "../../Commons/NoDataFound";
import BubbleLoader from "../../Commons/BubbleLoader";
import { callListing } from "./mock/email_calllisting";
import { callListingGroup } from "./mock/email_calllisting_group";
import { clearCallList, clearCallScore } from "../../../redux-container/callList/actions";
import { useDispatch } from "react-redux";
import EmailInfo from "../Sales/Modals/EmailInfo";
import emailService from '../../Services/mails.service';

const EmailListing = (props)=>{
    const [callLists, setCallLists] = useState([])
    const [availablePages, setAvailablePages] = useState("")
    const [currentPage, setCurrentPage] = useState("")
    const [filterData,setFilterData] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [showCard, setShowCard] = useState(false)
    const [show, setShow] = useState(false);
    const [cardId, setCardId] = useState("")
    const [detail, setDetail] = useState([])
    const [detailLoader, setDetailLoader] = useState(false)
    const [flag, setFlag] = useState(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = urlParams.get('name')
    let redirected = urlParams.get('redirected')
    let fromDate = urlParams.get("fromDate")
    let toDate = urlParams.get("toDate")
    let tabRedirected = urlParams.get("tabRedirected")
    const callHealth = urlParams?.get('callHealth') == 'fatal' ? 1 : urlParams?.get('callHealth') == 'non-fatal' ? 0 : null
    const history = useHistory()
    const dispatchAction = useDispatch()
    const [sellerNameData, setSellerNameData] = useState("");
    const [customerNameData, setCustomerNameData] = useState("");
    const [uniqueId , setUniqueId] = useState("")
    
    let callRange = urlParams.get('callScore')
    let durationRange = urlParams.get('callDuration')
    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing
    
    let role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
  
    let userExternalId = JSON.parse(localStorage.getItem("selectedUserExternalId")) || JSON.parse(localStorage.getItem("USER_DETAIL"))?.userExternalId;
    const clientExternalId = JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId

    const callBack = (e) => {
      setCurrentPage(e)
      setDetail([]);
      setShowCard(false);
    }

    const onClickHandler = (seller,customer,uniqueId) => {
      setSellerNameData(seller);
      setCustomerNameData(customer);
      setUniqueId(uniqueId)
      setShow(true)
    }
    const closeButton = () => setShow(false)
  
    const defaultConvFilter = (val)=>{
      setDefaultData(val)
    }
  
    const filterCallList = (val)=>{
      setFilterData(val)
    }

  function callList(){
    filterData?.loader?props.loaderTrue():props.loaderTrue()
    let val ={  
    "fromD": fromDate ? fromDate : storedData&&storedData.fromD?storedData.fromD:defaultData.from, 
    "toD": toDate ? toDate : storedData&&storedData.toD?storedData.toD:defaultData.to,
    "clientExternalId": clientExternalId,
    // "usersExternalIds": [userExternalId],
    "conversations_type": 2,
    "coeExternalIds": JSON.parse(localStorage.getItem("AGENT"))?.coe,
    "words":storedData?.words?storedData?.words:filterData?.words,
    "email": storedData && storedData.email ? storedData.email : "",
    // "agentName":storedData?.emailAgentName ? storedData?.emailAgentName : filterData?.emailAgentName,
    "groupIds": props?.historyObject?.agentPhone ? [props?.historyObject?.agentPhone] : storedData?.customerMobile? storedData ?.customerMobile : (filterData?.customerMobile?.length > 0 ? filterData?.customerMobile : null),
    "emailScoreRange":storedData?.cScore?storedData?.cScore:callRange?callRange.split("-").map(Number):filterData?.callScore,
    "fatalCall":callHealth == null ? storedData?.callHealth==0?storedData?.callHealth:storedData?.callHealth==1?storedData?.callHealth:filterData?.callHealth : callHealth,
    "momentId":storedData?.momentId?storedData?.momentId:filterData?.moment,
    "fileName":storedData?.fileName?storedData?.fileName:filterData?.fileName,
    "coeName":filterData?.coeName?filterData?.coeName:storedData?.selectedCOE,
    "sort": props.sortedField?props.sortedField:"",
    "order" :props.orderId?props.orderId:"",
    "page":parseInt(localStorage.getItem("selectedPage"))?parseInt(localStorage.getItem("selectedPage")):1,
    "pageSize":5,
    'userIds': JSON.parse(localStorage.getItem("AGENT")).sellerName ? JSON.parse(localStorage.getItem("AGENT"))?.sellerName : filterData?.sellerName,
    }
 
    if(role === 'AGENT_ADMIN')
      val.coeExternalId = storedData?.coe[0];
      let newVal = tabRedirected === "reports" && props?.historyObject ? { ...val, ...props?.historyObject } : val;
      conversationService.getEmailListing(newVal).then(response => {
      setCallLists(response? response.data?.data?.response: []) 
      setAvailablePages(response?.data?.data?.availablePages)
      props.loaderFalse()
      flag && setFlag(false)
    }).catch(error => {
      setCallLists([]);
      props.loaderFalse();
      flag && setFlag(false)
    });
  }
  
  useEffect(()=>{
    callList()
  },[props.sortedField,props.orderId,filterData,currentPage])
  
  // useEffect(()=>{
  //   callList()
  // },[currentPage])

  useEffect(() => {
    return () => {
      dispatchAction(clearCallScore());
    };
  }, [dispatchAction]);
  
  useEffect(()=>{
    props.selectedData(filterData)
    props.defaultData(defaultData)
    props?.callListData(callLists)
  },[filterData,defaultData,callLists])
   

  const clickHandler = (uniqueId,callId,sellerName,customerName,employeeId,coeExternalId,email,duration,date,Id,externalId,name,index)=>{
    history.push(`email_detail?emailId=${email}&name=${name}`, {
      seller:sellerName,
      customer:customerName,
      id:employeeId,
      coeId:coeExternalId,
      emailId:email,
      duration:duration,
      date:date,
      typeId:Id,
      agentExternalId:externalId,
      callName: name,
      callId: callId,
      uniqueId: uniqueId,
      index:index
    })
  }
  
  const onClickCard = (id,groupId) => {
    if(detailLoader){
      return  // to prevent multiple API calls while the previous one is still in progress
    }
    else{
      if (showCard && cardId === id) {
        setShowCard(false); // Collapse the card if it is already open
      } 
      else {
        setDetailLoader(true)
        setShowCard(true);
        setCardId(id);
        let val ={  
          "page":parseInt(localStorage.getItem("selectedPage"))?parseInt(localStorage.getItem("selectedPage")):1,
          "pageSize":5,  
          "order" :props.orderId?props.orderId:"",
          "sort": props.sortedField?props.sortedField:"",
          "coeExternalIds": JSON.parse(localStorage.getItem("AGENT"))?.coe,
          "conversations_type": 2,
          "filters": {},
          "fromD": fromDate ? fromDate : storedData&&storedData.fromD?storedData.fromD:defaultData.from, 
          "toD": toDate ? toDate : storedData&&storedData.toD?storedData.toD:defaultData.to,
          "usersExternalIds": [userExternalId],
          "groupId": groupId,
        }

        emailService.getSubEmails(val).then(res=>{
          if(res?.status==200){
            setDetailLoader(false)
            setDetail(res?.data?.data?.details)
          }
        })
      }
    }
  }
  
  const popoverr =(alarms,name,rec,moment)=> (
    <Popover id="popover-trigger-hover-focus" title="right" style={{"padding":"4px"}}>
      <h3 className="popover-header">{name}</h3>
      <div className="popover-body">
        <div className="popover-body-call">
          {
            name==='Alarms'?alarms.map(val=>{
              return(
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1"/>
                  {val}
                </p>
              )
            }):
            name==='Recommendations'?rec.map(val=>{
              return(
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1"/>
                  {val}
                </p>
              )
            }):
            name==='Moments'?moment.map(val=>{
              return(
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1"/>
                  {val}
                </p>
              )
            })
            :
            ""
          }
        </div>
      </div>
    </Popover>
  )
  
    return (
      
      <>
      {props.loader ? (
          <div className="loader-container">
            <Loading variant="dark" />
          </div>
        ) : (
        <>
          {callLists!=''&&callLists?.length>0?callLists.map((item,id)=>{
            return(
          <>
          <div className="card-container" onClick={() => onClickCard(id, item?.groupId)}>
            <Card style={detail?.length>0&&showCard&&id==cardId?{cursor:"pointer",marginBottom:"0.5rem"}:{cursor:"pointer"}}>
              <Card.Body className="salesCallCard">
                <div className="user" >
                  <div className="caller" style={{width: '33%'}}>
                    <div className="name" >
                      <span>
                        <img src={user} alt=""/>
                      </span>
                      <span className="text">{item?.customerName}</span>
                    </div>
                    <div className="rank" style={{cursor:"pointer"}}>{`#${item.phone} ${`(${item?.totalCalls})`}`}</div>
                  </div>
                  <div className="callCount">
                    <div className="box">
                    {item.callAlarms?.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(item?.callAlarms,"Alarms","")} rootClose>
                      <button style={{"border":"none","backgroundColor":"white"}}>
                        <img src={alarm} alt=""/>
                        <span>{item?.callAlarms?.length}</span>
                      </button>
                    </OverlayTrigger>:
                    <>
                      <img src={alarm} alt=""/>
                      <span>{item?.callAlarms?.length}</span>
                    </>}
                      
                    </div>
                    <div className="box">
                      {item.callRec?.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Recommendations",item?.callRec)} rootClose>
                        <button style={{"border":"none","backgroundColor":"white"}}>
                          <img src={bulb} alt=""/>
                          <span>{item?.callRec?.length}</span>
                        </button>
                      </OverlayTrigger>:
                      <>
                        <img src={bulb} alt=""/>
                        <span>{item?.callRec?.length}</span>
                      </>}
                    </div>
                    <div className="box">
                    {item.moments?.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Moments","",item?.moments)} rootClose>
                        <button style={{"border":"none","backgroundColor":"white"}}>
                          <img src={moment} alt=""/>
                          <span>{item?.moments?.length}</span>
                        </button>
                      </OverlayTrigger>:                                                                                                                                                                                                                                                                                                                                                                                                                        
                      <>
                      <img src={moment} alt=""/>
                      <span>{item?.moments?.length}</span>
                      </>}
                    </div>
                  </div>
                  <div className="score" style={{width: '33%' , textAlign: 'right'}}>
                    {userDetail.includes("CALL SCORE")?<div className="scoreCommonStyle callScore">
                      <span className="text">EMAIL<br/>SCORE</span>
                      <span className="badge" style={{background:item.callColour=='Amber'?"#FFBF00":item.callColour==null?"Grey":item.callColour}}>{item?.callScore}%</span>
                    </div>:""}                                                                                                                                                                                                                                                                                                                                                                                                                        
                    {userDetail.includes("PITCH SCORE")?<div className="scoreCommonStyle pitchScore">
                      <span className="text">PITCH<br/>SCORE</span>
                      <span className="badge" style={{background:item.pitchColour=='Amber'?"#FFBF00":item.pitchColour==null?"Grey":item.pitchColour}}>{item.pitchScore}%</span>
                    </div>:""}
                  </div>
                </div>
                <hr/>
                <div className="seller">
                  <div className="name" style={{width: '25%'}}>
                    <span className="icon">
                      <img src={seller} alt=""/>
                    </span>
                    {`${item.agentName} (${item.createByEmail})`}
                  </div>
                  <div className="number" style={{width: '25%'}}>
                    <span className="icon" style={{height:'15px'}}>
                      {/* <img src={phone} alt=""/> */}
                      <img src={email} alt=""/>
                      {/* <i class="lni lni-inbox"></i> */}
                    </span>
                    {item?.createByEmail}
                  </div>
                  <div className="date" style={{width: '25%' , textAlign: "right"}}>
                    <span className="icon">
                      <img src={calender} alt=""/>
                    </span>
                    {item.date}
                  </div>
                  <div style={{width: '25%', textAlign: 'right'}}>
                    <button className="groupCall" onClick={(e)=>{e.stopPropagation();onClickHandler(item?.sellerName,item?.customerName, item?.groupId)}}>
                      Mail
                    </button>
                    <button className="groupCall" onClick={(e)=>{e.stopPropagation(); clickHandler(item?.uniqueId,item?.callId,item?.sellerName,item?.customerName,item?.employeeId,item?.coeExternalId,item.createByEmail,item.durationSeconds,item.date,item?.groupId,item.userExternalIds,"GROUP")}}>
                      View Chain
                    </button>
                  </div>
                  
                </div>
                {/* <div className='arrow-styling'>
                <i className={`bx bxs-chevron-${showCard&&id==cardId?'up':'down'}`} ></i>
                </div> */}
              </Card.Body>
            </Card>
            <div className={`card-arrow arrow-down`}>
        <i className={`bx bxs-chevron-${showCard&&id==cardId ? 'up' : 'down'} arrow-icon`} style={{fontSize:'26px'}}></i>
      </div>
            </div>
           {detailLoader&&cardId==id?
            <div className="calls-container">
              <BubbleLoader/>
            </div>:
            detail?.length>0&&detail.map((d,val)=>{
              return (showCard && cardId==id?
                <Card className={`${val==detail?.length-1?'group-detail-noMargin':'group-details'} second-card`}>
                  <Card.Body style={{cursor:"default"}} className="salesCallCard">
                    <div className="user">
                      <div className="caller" style={{width: '33%'}}>
                        <div className="name">
                          <span>
                            <img src={user} alt=""/>
                          </span>
                          <span className="text">{d?.customerName}</span>
                        </div>
                        <div className="rank" style={{cursor:"default"}}>{`#${d.callId}`}</div>
                      </div>
                      <div className="callCount">
                        <div className="box">
                        {d.callAlarms?.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(d?.callAlarms,"Alarms","")} rootClose>
                          <button style={{"border":"none","backgroundColor":"white"}}>
                            <img src={alarm} alt=""/>
                            <span>{d?.callAlarms?.length}</span>
                          </button>
                        </OverlayTrigger>:
                        <>
                          <img src={alarm} alt=""/>
                          <span>{d?.callAlarms?.length}</span>
                        </>}
                          
                        </div>
                        <div className="box">
                          {d.callRec?.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Recommendations",d?.callRec)} rootClose>
                            <button style={{"border":"none","backgroundColor":"white"}}>
                              <img src={bulb} alt=""/>
                              <span>{d?.callRec?.length}</span>
                            </button>
                          </OverlayTrigger>:
                          <>
                            <img src={bulb} alt=""/>
                            <span>{d?.callRec?.length}</span>
                          </>}
                        </div>
                        <div className="box">
                        {d.moments?.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Moments","",d?.moments)} rootClose>
                            <button style={{"border":"none","backgroundColor":"white"}}>
                              <img src={moment} alt=""/>
                              <span>{d?.moments?.length}</span>
                            </button>
                          </OverlayTrigger>:                                                                                                                                                                                                                                                                                                                                                                                                                        
                          <>
                          <img src={moment} alt=""/>
                          <span>{d?.moments?.length}</span>
                          </>}
                        </div>
                      </div>
                      <div className="score" style={{width: '33%' , textAlign: 'right'}}>
                        {userDetail.includes("CALL SCORE")?<div className="scoreCommonStyle callScore">
                          <span className="text">EMAIL<br/>SCORE</span>
                          <span className="badge" style={{background:d.callColour=='Amber'?"#FFBF00":d.callColour==null?"Grey":d.callColour}}>{d.callScore}%</span>
                        </div>:""}                                                                                                                                                                                                                                                                                                                                                                                                                        
                        {userDetail.includes("PITCH SCORE")?<div className="scoreCommonStyle pitchScore">
                          <span className="text">PITCH<br/>SCORE</span>
                          <span className="badge" style={{background:d.pitchColour=='Amber'?"#FFBF00":d.pitchColour==null?"Grey":d.pitchColour}}>{d.pitchScore}%</span>
                        </div>:""}
                      </div>
                    </div>
                    <hr/>
                    <div className="seller">
                      <div className="name" style={{width: '25%'}}>
                        <span className="icon">
                          <img src={seller} alt=""/>
                        </span>
                        {`${d.sellerName}`}
                      </div>
                      <div className="number" style={{width: '25%'}}>
                        <span className="icon">
                          <img src={email} alt=""/>
                        </span>
                        {d.createByEmail}
                      </div>
                      <div className="date" style={{width: '25%', textAlign: 'right'}}>
                        <span className="icon">
                          <img src={calender} alt=""/>
                        </span>
                        {d.date}
                      </div>
                      <div style={{width: '25%', textAlign: 'right'}}>
                        <button className="groupCall" onClick={()=>{clickHandler(d?.uniqueId,d?.callId,d?.sellerName,d?.customerName,item?.employeeId,item?.coeExternalId,d.createByEmail,d.durationSeconds,d.date,d?.uniqueId,d.userExternalIds,"SINGLE_CALL",val)}}>
                          View Mail
                        </button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>:""
              )
            })} 
          </>
            )
          }):<NodataFound id="salesColumn"/> 
          }
          
        </>
      )}
      <Filter
        componentType = "Conversation-Sales"
        isEmail = {true}
        filterCallList= {filterCallList}
        defaultConvFilter={defaultConvFilter}
        name={"SALES"}
        historyObject={props?.historyObject}
        setEmptyObject={props?.setEmptyObject}
      />
      {callLists?.length>0 && availablePages>0?
        <PaginationAlter
          callBack={callBack}
          availablePages = {availablePages}
          loaderTrue = {props.loaderTrue}
          loaderFalse = {props.loaderFalse}
        />:""
      }
      {show && <EmailInfo show={show} setShow={setShow} sellerName={sellerNameData} customerName={customerNameData} uniqueId={uniqueId} closeButton={closeButton}/> }
      </>
    );
}

export default EmailListing