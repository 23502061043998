import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import background from '../../assets/images/login-images/sign-in.png';
import odio_logo_blue from "../../assets/images/odio_logo_blue.png";
import logo from "../../assets/images/o-pic-new.png";
import userService from "../Services/user.service";
import Loading from "../Commons/Loading";
import ForgotPasswordEnterOTP from './ForgotPasswordEnterOTP';
import ResetPassword from './ResetPassword'; // Import ResetPassword component if needed
import './login.css';
import toaster from "../Toast/toaster";

export default function ForgotPassword() {
  const [inputs, setInputs] = useState({ email: "" });
  const [formError, setFormErrors] = useState({});
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true); // Control flag to switch between pages

  const btnRef = React.useRef();
  const errors = {};
  const { email } = inputs;

  useEffect(() => {
    document.title = "Forgot Password - Odio";
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, email: value }));
    btnRef.current.removeAttribute("disabled");
  }

  const validate = (values) => {
    if (!values.email) {
      errors.email = "Enter your login id (email).";
    }
    return errors;
  };

  function handleFocus() {
    formError.email = "";
  }

  function handleSubmit(e) {
    e?.preventDefault();
    setLoading(true);
    validate(inputs);
    setMessage("");
    setSuccessMessage("");
    setFormErrors(errors);
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    if (Object.keys(errors).length === 0) {
      userService.forgotPassword(inputs).then((response) => {
        if (response.data.status === 0) {
          setFlag(false); // Switch to Reset Password page
          setLoading(false);
          setSuccessMessage(response.data.message);
        } else {
          setLoading(false);
          setMessage(response.data.message);
        }
      }).catch((err)=>{
        toaster.error("User Not Registered")
        setLoading(false);
        setInputs((inputs) => ({ ...inputs, email: "" }))
      });
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      {flag ? (
        <div className="login-container">
          <div className="welcome-section">
            <div className="logo-container">
              <img src={logo} alt="log" className="logo" />
              <img src={odio_logo_blue} alt="Odio Logo" className="logo" />
            </div>
            <div className="welcome-text">
              <p className='welcome-message'>Nice to see you again</p>
              <h1 className='welcome-back'>WELCOME BACK</h1>
              <div className="slash"></div>
              <p className='welcome-tagline'>Transform Customer Interactions with our AI-Driven Conversational Intelligence.</p>
            </div>
            <div className="additional-links" style={{ position: "absolute", bottom: "20px", left: "20px" }}>
              {/* <div className='link-1'> */}
              <a href="https://www.odioiq.com" target="_blank" style={{ color: 'white' }}>Odio</a>
              <a href="https://www.odioiq.com/blog/" target="_blank" style={{ color: 'white' }}>Blogs</a>
              <a href="https://www.odioiq.com/privacy-policy-2/" target="_blank" style={{ color: 'white' }}>Privacy Policy</a>
              {/* </div> */}
              {/* <div className='link-2'> */}
              <a href="https://www.odioiq.com/product/integrations/" target="_blank" style={{ color: 'white' }}>Integrations</a>
              <a href="https://www.odioiq.com/product/faq-2/" target="_blank" style={{ color: 'white' }}>FAQs</a>
              {/* </div> */}
            </div>
          </div>
          <div className="login-section">
            <h2 className='login'>Forgot Password ?</h2>
            <p className="login" style={{ letterSpacing: 'normal' }}>Enter your registered email ID to reset the password</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group" style={{ width: '90%', marginLeft: '19px' }}>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  className="form-control form-control-sm"
                  placeholder="example@user.com"
                  required
                />
                <p style={{ color: "red", height: "18px" }} className='error-message'>
                  {message || formError.email}
                </p>
              </div>
              <div className="btn-container">
                <button type="submit" className="btn btn-submit" style={{ opacity: "1", marginTop: '1rem', width: '86%' }} ref={btnRef} disabled={!email}>
                  {loading ? <Loading variant="light" /> : 'Submit'}
              </button>
              </div>
              {/* <Link to="/login" className="btn btn-light btn-lg" style={{ color: '#0a58ca' }}>
                <i className="bx bx-arrow-back me-1" /> Back to Login
              </Link> */}
              <a href="/login" className="btn btn-light btn-md" style={{ color: '#479ef5', background: 'none', marginTop: '10px' }}>
                                <i className="bx bx-arrow-back me-1" />
                                {/* <Link to="./login">Back to Login</Link> */}
                                Back to Login
              </a>
            </form>
          </div>
        </div>
      ) : (
          <ForgotPasswordEnterOTP email={email} />
      )}
    </>
  );
}
