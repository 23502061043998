import React, { useState, useEffect } from 'react';

const Dump = (props) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetchEmailContent();
  }, []); 

  const fetchEmailContent = async () => {
    try {
      const response = await props?.callTranscript?.sequence?.[0]?.dump_trans;
      setHtmlContent(response);
    } catch (error) {
      console.error('Error fetching email content:', error);
    }
  };

  return (
    <div>
      <p class="mb-4 font-18">{props?.callTranscript?.sequence?.[0]?.subject}</p>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
    </div>
  );
};

export default Dump;
